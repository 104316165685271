/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCreateDeliveryNoteRequest
 */
export interface KvintaCreateDeliveryNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateDeliveryNoteRequest
     */
    locationId: string;
}

export function KvintaCreateDeliveryNoteRequestFromJSON(json: any): KvintaCreateDeliveryNoteRequest {
    return KvintaCreateDeliveryNoteRequestFromJSONTyped(json, false);
}

export function KvintaCreateDeliveryNoteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateDeliveryNoteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationId': json['locationId'],
    };
}

export function KvintaCreateDeliveryNoteRequestToJSON(value?: KvintaCreateDeliveryNoteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locationId': value.locationId,
    };
}


