/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import { inject, observer, PropTypes } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IICItems, ShippingStore, SHIPPING_STORE_ID, setDateToNoon } from './ShippingStore';
import InputMask from 'react-input-mask';
import { tableIcons } from 'kvinta/components';
import MaterialTable, { Column } from 'material-table';
import { format, parse } from 'date-fns';
import { productGroupIsWaterBeerBio } from '../../../../config';

interface DialogIntroduceIntoCirculationProps {
  shippingStore?: ShippingStore;
  intl: any;
}

function formatDate(rawDate): string {
  let date = '';
  try {
    date = format(new Date(rawDate), 'yyyy-MM-dd');
  } catch (e) {
    date = rawDate.toString();
  }
  return date;
}

class DialogIntroduceIntoCirculationImpl extends Component<DialogIntroduceIntoCirculationProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: DialogIntroduceIntoCirculationProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  onChange = (event: Event): void => {
    this.props.shippingStore.changeGtdOrder((event.target as any).value);
  };

  render() {
    const isOpen = this.props.shippingStore.introduceIntoCirculationDialogOpen;
    const iicData = this.props.shippingStore.iicData;
    const intl = this.props.intl;

    if (!isOpen) {
      return null;
    }

    const baseColumns = [
      {
        title: intl.formatMessage({ id: 'shipping.product-list.iic.gtin' }),
        field: 'gtin',
        cellStyle: {
          whiteSpace: 'nowrap',
        },
        width: '220px',
        render: (rowData) => {
          return <OutlinedInput fullWidth margin="dense" defaultValue={rowData.gtin} readOnly={true} />;
        },
      },
      {
        title: intl.formatMessage({ id: 'shipping.product-list.iic.prodBatch' }),
        field: 'prodBatch',
        defaultSort: 'desc',
        cellStyle: {
          whiteSpace: 'nowrap',
        },
        width: '160px',
        render: (rowData) => {
          return <OutlinedInput fullWidth margin="dense" defaultValue={rowData.prodBatch} readOnly={true} />;
        },
      },
    ];

    const vsdColumn = {
      title: intl.formatMessage({ id: 'shipping.product-list.iic.vsdNumber' }),
      field: 'vsdNumber',
      cellStyle: {
        whiteSpace: 'nowrap',
      },
      width: '450px',
      render: (rowData) => {
        return (
          <div>
            <OutlinedInput
              margin="dense"
              fullWidth
              defaultValue={rowData.vsdNumber}
              error={rowData.vsdNumberError !== undefined && rowData.vsdNumberError !== null}
              onChange={(event) => this.onChangeItemVSDNumber(rowData, event.target.value)}
            />
            <FormHelperText error={rowData.vsdNumberError !== undefined && rowData.vsdNumberError !== null}>
              {rowData.vsdNumberError}
            </FormHelperText>
          </div>
        );
      },
    };

    const productionDateColumn = {
      title: intl.formatMessage({ id: 'shipping.product-list.iic.productionDate' }),
      field: 'productionDate',
      cellStyle: {
        whiteSpace: 'nowrap',
      },
      render: (rowData) => {
        return (
          <OutlinedInput
            margin="dense"
            defaultValue={format(new Date(rowData.productionDate), 'yyyy-MM-dd')}
            type="date"
            onChange={(event) => this.onChangeItemProdDate(rowData, event.target.value)}
          />
        );
      },
    } as any;

    const waterDemoColumns = [
      {
        title: intl.formatMessage({ id: 'shipping.product-list.iic.code' }),
        field: 'code',
        cellStyle: {
          whiteSpace: 'nowrap',
        },
        render: (rowData) => {
          return (
            <FormControl variant="outlined">
              <Select id="code" value={'2201101100'} style={{ height: '40px' }}>
                {['2201101100'].map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        },
      },
      {
        title: intl.formatMessage({ id: 'shipping.product-list.iic.document' }),
        field: 'code',
        cellStyle: {
          whiteSpace: 'nowrap',
        },
        render: (rowData) => {
          return (
            <FormControl variant="outlined">
              <Select id="document" defaultValue={'declaration'} style={{ height: '40px' }}>
                {[
                  { id: 'certificate', value: 'shipping.product-list.iic.document.values.certificate' },
                  { id: 'declaration', value: 'shipping.product-list.iic.document.values.declaration' },
                ].map(({ id, value }) => (
                  <MenuItem key={id} value={id} style={{ height: '40px' }}>
                    <FormattedMessage id={value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        },
      },
      {
        title: intl.formatMessage({ id: 'shipping.product-list.iic.documentNumber' }),
        field: 'code',
        cellStyle: {
          whiteSpace: 'nowrap',
        },
        render: (rowData) => {
          return (
            <OutlinedInput
              margin="dense"
              type="text"
              // value=""
            />
          );
        },
      },
      {
        title: intl.formatMessage({ id: 'shipping.product-list.iic.documentDate' }),
        field: 'code',
        cellStyle: {
          whiteSpace: 'nowrap',
        },
        render: (rowData) => {
          return (
            <OutlinedInput
              margin="dense"
              defaultValue={format(new Date(rowData.productionDate), 'yyyy-MM-dd')}
              type="date"
            />
          );
        },
      },
    ];

    let columns = [];

    if (productGroupIsWaterBeerBio) {
      columns = [...baseColumns, ...waterDemoColumns];
    } else {
      columns = [...baseColumns, vsdColumn, productionDateColumn];
    }

    return (
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={isOpen}
        aria-labelledby="form-dialog-title"
        onClose={() => this.props.shippingStore.closeIntroduceIntoCirculationDialog()}
      >
        <DialogTitle>{intl.formatMessage({ id: 'shipping.product-list.iic.title' })}</DialogTitle>
        <DialogContent>
          <Container>
            <Box m={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="taxNumber">
                  {intl.formatMessage({ id: 'shipping.product-list.iic.taxNumber' })}
                </InputLabel>
                <OutlinedInput
                  id="taxNumber"
                  label={intl.formatMessage({ id: 'shipping.product-list.iic.taxNumber' })}
                  value={iicData.participantInn}
                  readOnly={true}
                />
              </FormControl>
            </Box>
            <Box m={2}>
              <FormControl variant="outlined" fullWidth>
                <InputMask mask="99999999/999999/9999999" value={iicData.gtd} onChange={this.onChange}>
                  {() => (
                    <TextField
                      id="gtdNumber"
                      variant="outlined"
                      label={intl.formatMessage({ id: 'shipping.product-list.iic.gtdNumber' })}
                      error={iicData.gtdError !== undefined && iicData.gtdError !== null}
                      helperText={iicData.gtdError}
                    />
                  )}
                </InputMask>
              </FormControl>
            </Box>
            <Box m={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="gtdDate">
                  {intl.formatMessage({ id: 'shipping.product-list.iic.gtdDate' })}
                </InputLabel>
                <OutlinedInput
                  id="gtdDate"
                  label={intl.formatMessage({ id: 'shipping.product-list.iic.gtdDate' })}
                  type="date"
                  value={formatDate(iicData.gtdDate)}
                  error={iicData.gtdDateError !== undefined}
                  onChange={(e) => {
                    this.props.shippingStore.changeGtdDate(e.target.value);
                  }}
                />
                <FormHelperText error={iicData.gtdDateError !== undefined}>{iicData.gtdDateError}</FormHelperText>
              </FormControl>
            </Box>
            {productGroupIsWaterBeerBio && (
              <>
                <Box m={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="customsCode">
                      {intl.formatMessage({ id: 'shipping.product-list.iic.customsCode' })}
                    </InputLabel>
                    <OutlinedInput
                      id="taxNumber"
                      label={intl.formatMessage({ id: 'shipping.product-list.iic.customsCode' })}
                    />
                  </FormControl>
                </Box>
                <Box m={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel style={{ backgroundColor: 'white' }} htmlFor="document">
                      {intl.formatMessage({ id: 'shipping.product-list.iic.customsDecisionCode' })}
                    </InputLabel>
                    <Select id="document" defaultValue={'10'} style={{ height: '59px' }}>
                      {[{ id: '10', value: 'shipping.product-list.iic.customsDecisionCode.value' }].map(
                        ({ id, value }) => (
                          <MenuItem key={id} value={id} style={{ height: '40px' }}>
                            <FormattedMessage id={value} />
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </>
            )}
            <div>
              <MaterialTable
                icons={tableIcons}
                tableRef={this.tableRef}
                columns={columns as Column<IICItems>[]}
                data={iicData.productsList}
                isLoading={false}
                options={{
                  actionsColumnIndex: -1,
                  paging: false,
                  sorting: false,
                  draggable: false,
                  search: false,
                  minBodyHeight: '400px',
                  maxBodyHeight: '400px',
                  emptyRowsWhenPaging: false,
                  thirdSortClick: false,
                  showTitle: false,
                }}
              />
            </div>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.shippingStore.closeIntroduceIntoCirculationDialog()}
            color="primary"
            variant="outlined"
          >
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <Button
            onClick={() => this.props.shippingStore.submitIntroduceIntoCirculationDialog()}
            color="primary"
            variant="contained"
            disabled={false}
            autoFocus
          >
            {intl.formatMessage({ id: 'button.submit' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  onChangeItemProdDate(rowData: IICItems, value: any) {
    this.props.shippingStore.onChangeItemProdDate(
      rowData,
      setDateToNoon(parse(value, 'yyyy-MM-dd', new Date())).getTime(),
    );
  }

  onChangeItemVSDNumber(rowData: IICItems, value: string): void {
    this.props.shippingStore.onChangeItemVSDNumber(rowData, value);
  }
}

export const DialogIntroduceIntoCirculation = injectIntl(
  inject(SHIPPING_STORE_ID)(observer(DialogIntroduceIntoCirculationImpl)),
);
