/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaNotificationGroup,
    KvintaNotificationGroupFromJSON,
    KvintaNotificationGroupFromJSONTyped,
    KvintaNotificationGroupToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetListResultNotificationGroup
 */
export interface KvintaGetListResultNotificationGroup {
    /**
     * 
     * @type {Array<KvintaNotificationGroup>}
     * @memberof KvintaGetListResultNotificationGroup
     */
    list?: Array<KvintaNotificationGroup> | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaGetListResultNotificationGroup
     */
    total?: number | null;
}

export function KvintaGetListResultNotificationGroupFromJSON(json: any): KvintaGetListResultNotificationGroup {
    return KvintaGetListResultNotificationGroupFromJSONTyped(json, false);
}

export function KvintaGetListResultNotificationGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetListResultNotificationGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': !exists(json, 'list') ? undefined : (json['list'] === null ? null : (json['list'] as Array<any>).map(KvintaNotificationGroupFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function KvintaGetListResultNotificationGroupToJSON(value?: KvintaGetListResultNotificationGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': value.list === undefined ? undefined : (value.list === null ? null : (value.list as Array<any>).map(KvintaNotificationGroupToJSON)),
        'total': value.total,
    };
}


