/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Capture Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaTimestamp
 */
export interface KvintaTimestamp {
    /**
     * 
     * @type {number}
     * @memberof KvintaTimestamp
     */
    epochMillis?: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaTimestamp
     */
    sequenceNumber?: number;
}

export function KvintaTimestampFromJSON(json: any): KvintaTimestamp {
    return KvintaTimestampFromJSONTyped(json, false);
}

export function KvintaTimestampFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTimestamp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epochMillis': !exists(json, 'epochMillis') ? undefined : json['epochMillis'],
        'sequenceNumber': !exists(json, 'sequenceNumber') ? undefined : json['sequenceNumber'],
    };
}

export function KvintaTimestampToJSON(value?: KvintaTimestamp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epochMillis': value.epochMillis,
        'sequenceNumber': value.sequenceNumber,
    };
}


