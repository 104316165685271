/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaNotificationType,
    KvintaNotificationTypeFromJSON,
    KvintaNotificationTypeFromJSONTyped,
    KvintaNotificationTypeToJSON,
    KvintaTemplateType,
    KvintaTemplateTypeFromJSON,
    KvintaTemplateTypeFromJSONTyped,
    KvintaTemplateTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaNotificationData
 */
export interface KvintaNotificationData {
    /**
     * 
     * @type {KvintaTemplateType}
     * @memberof KvintaNotificationData
     */
    templateType?: KvintaTemplateType;
    /**
     * 
     * @type {KvintaNotificationType}
     * @memberof KvintaNotificationData
     */
    notificationType?: KvintaNotificationType;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KvintaNotificationData
     */
    customFields?: { [key: string]: object; };
}

export function KvintaNotificationDataFromJSON(json: any): KvintaNotificationData {
    return KvintaNotificationDataFromJSONTyped(json, false);
}

export function KvintaNotificationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaNotificationData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateType': !exists(json, 'templateType') ? undefined : KvintaTemplateTypeFromJSON(json['templateType']),
        'notificationType': !exists(json, 'notificationType') ? undefined : KvintaNotificationTypeFromJSON(json['notificationType']),
        'customFields': !exists(json, 'customFields') ? undefined : json['customFields'],
    };
}

export function KvintaNotificationDataToJSON(value?: KvintaNotificationData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateType': KvintaTemplateTypeToJSON(value.templateType),
        'notificationType': KvintaNotificationTypeToJSON(value.notificationType),
        'customFields': value.customFields,
    };
}


