/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaRetryExecutionWithNewPayloadRequest
 */
export interface KvintaRetryExecutionWithNewPayloadRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaRetryExecutionWithNewPayloadRequest
     */
    b64content: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaRetryExecutionWithNewPayloadRequest
     */
    executionId: string;
}

export function KvintaRetryExecutionWithNewPayloadRequestFromJSON(json: any): KvintaRetryExecutionWithNewPayloadRequest {
    return KvintaRetryExecutionWithNewPayloadRequestFromJSONTyped(json, false);
}

export function KvintaRetryExecutionWithNewPayloadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaRetryExecutionWithNewPayloadRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'b64content': json['b64content'],
        'executionId': json['executionId'],
    };
}

export function KvintaRetryExecutionWithNewPayloadRequestToJSON(value?: KvintaRetryExecutionWithNewPayloadRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'b64content': value.b64content,
        'executionId': value.executionId,
    };
}


