import { action, makeObservable, observable, runInAction } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';
import {
  DefaultApi as MDDocumentApi,
  KvintaCompany,
  KvintaGetOneResponseLocation,
  KvintaLocation,
  KvintaRegulatorReportingSystem,
  KvintaSortBy,
} from 'kvinta/apis/kvinta-masterdata-service';
import { NotificationManager } from 'kvinta/modules/main';
import { KvintaOperationStatus } from 'kvinta/apis/kvinta-document-store/models';
import { SelectableStore } from 'kvinta/common';

export interface LocationFormDialogData {
  company: any;
  name: string;
  gln13: string;
  sgln: string;
  latitude: string;
  longitude: string;
  description: string;
  country: any;
  city: string;
  postalCode: string;
  address1: string;
  address2: string;
  address3: string;
  showError: boolean;
}

export type LocationReportingData = { [key in KvintaRegulatorReportingSystem]: boolean };

interface KvintaLocationExt extends KvintaLocation {
  companyData?: KvintaCompany;
}

// export type CompaniesRow extends KvintaCompany implements ISelectableRow
interface ILocationRow extends KvintaLocation, ISelectableRow {}

export class LocationsStore extends SelectableStore<ILocationRow> {
  private _config: TAppOptionsConfig;
  private _mdApi: MDDocumentApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;

  page: number;
  totalCount: number;
  pageSize: number;
  companies: KvintaCompany[];
  currentSort: KvintaSortBy;
  searchValue: string;

  createLocationData?: LocationFormDialogData;
  locationFormOpen: boolean;

  updateLocationData?: LocationFormDialogData;
  updateLocationFormOpen: boolean;

  reportingData?: LocationReportingData | null = null;

  currentLocation?: KvintaLocationExt | null = null;

  exportActive: boolean;
  exportData: ILocationRow[] | KvintaLocation[] | undefined;

  autofocusSearchInList: boolean;

  constructor(config: TAppOptionsConfig, notificationManager: NotificationManager, mdApi: MDDocumentApi) {
    super();
    makeObservable(this, {
      fetchPage: action.bound,
      fetchData: action.bound,
      fetchLocation: action.bound,
      updateSearch: action,
      unfocusSearchField: action,

      updateExported: action,
      exportAll: action.bound,
      exportSelected: action.bound,

      isLoading: observable,
      page: observable,
      pageSize: observable,
      searchValue: observable,
      createLocationData: observable,
      locationFormOpen: observable,
      currentLocation: observable,
      updateLocationData: observable,
      updateLocationFormOpen: observable,
      reportingData: observable,

      onChangeCreateLocationField: action.bound,
      onChangeUpdateLocationField: action.bound,
      submitCreateLocationForm: action.bound,
      submitUpdateLocation: action.bound,

      exportActive: observable,
    });

    this._config = config;
    this._mdApi = mdApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.searchValue = '';
    this.autofocusSearchInList = false;

    this.currentSort = {
      order: 'desc',
      field: 'name',
    };
  }

  async fetchPage(page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
    });
    this.fetchData();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    runInAction(() => {
      this.isLoading = true;
      const field = getField(orderBy);
      this.currentSort = {
        field: field,
        order: orderDirection,
      };
    });
    this.fetchData();
  }

  unfocusSearchField() {
    this.autofocusSearchInList = false;
  }

  updateSearch(value: string) {
    this.searchValue = value;
    this.isLoading = true;
    this.autofocusSearchInList = true;
    this.fetchData();
  }

  async fetchData() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.listData = [];
    newLocal.totalCount = 0;
    let filters = {} as { [key: string]: string } | null;

    for (const filter of searchedColumns) {
      filters = {
        ...filters,
        [filter]: this.searchValue,
      };
    }
    newLocal._mdApi
      .getLocationList({
        kvintaGetListRequest: {
          pagination: { page: newLocal.page, perPage: newLocal.pageSize },
          filter: filters,
        },
      })
      .then((result) => {
        runInAction(() => {
          newLocal.isLoading = false;
          if (result.operationStatus === KvintaOperationStatus.Error) {
            newLocal.listData = [];
          } else {
            const oldSelection = new Map();
            if (newLocal.listData) {
              newLocal.listData.forEach((row) => {
                oldSelection.set(row.id, row.isSelected);
              });
            }
            newLocal.listData = (result.data || []).map((comp) => {
              const selected = oldSelection.get(comp.id) || false;
              return { ...comp, isSelected: selected } as ILocationRow;
            });
            newLocal.totalCount = result.total || 0;
          }
        });
      })
      .catch((err: Error) => {
        if (!err.message.startsWith('Cannot read')) {
          this._notificationManager.sendError(err.message);
        }
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  async openCreateLocationForm() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal._mdApi
      .getCompanyList({
        kvintaGetListRequest: { pagination: { page: 0, perPage: 10000 } },
      })
      .then((result) => {
        runInAction(() => {
          if (result.operationStatus === KvintaOperationStatus.Error) {
            newLocal.companies = [];
          } else {
            newLocal.companies = result.data;
          }
          newLocal.createLocationData = {
            company: newLocal.companies[0].id,
            name: '',
            gln13: '',
            sgln: '',
            latitude: '',
            longitude: '',
            description: '',
            city: '',
            country: 'DE',
            postalCode: '',
            address1: '',
            address2: '',
            address3: '',
            showError: false,
          };
          newLocal.isLoading = false;
          newLocal.locationFormOpen = true;
        });
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(err.toString());
      });
  }

  closeCreateLocationForm() {
    runInAction(() => {
      this.locationFormOpen = false;
    });
  }

  onChangeCreateLocationField = (id: string, value: any) => {
    runInAction(() => {
      this.createLocationData[id] = value;
    });
  };

  submitCreateLocationForm = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    let hasError = false;
    for (const field of requiredFields) {
      if (errorRequired(field, this.createLocationData[field])) {
        hasError = true;
        break;
      }
    }
    this.createLocationData.showError = hasError;
    if (!hasError) {
      runInAction(() => {
        newLocal.locationFormOpen = false;
        newLocal.isLoading = true;
      });
      newLocal._mdApi
        .createLocation({
          kvintaCreateLocationRequest: {
            companyId: newLocal.createLocationData.company,
            name: newLocal.createLocationData.name,
            gln13: newLocal.createLocationData.gln13,
            sgln: newLocal.createLocationData.sgln,
            latitude: parseFloat(newLocal.createLocationData.latitude),
            longitude: parseFloat(newLocal.createLocationData.longitude),
            description: newLocal.createLocationData.description,
            city: newLocal.createLocationData.city,
            country: newLocal.createLocationData.country,
            postalCode: newLocal.createLocationData.postalCode,
            address1: newLocal.createLocationData.address1,
            address2: newLocal.createLocationData.address2,
            address3: newLocal.createLocationData.address3,
          },
        })
        .then((res) => {
          runInAction(() => {
            newLocal.isLoading = false;
          });
          newLocal.fetchData();
        })
        .catch((err: Error) => {
          this._notificationManager.sendError(err.toString());
        });
    }
  };

  async openUpdateLocationForm() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal._mdApi
      .getCompanyList({
        kvintaGetListRequest: { pagination: { page: 0, perPage: 10000 } },
      })
      .then((result) => {
        runInAction(() => {
          if (result.operationStatus === KvintaOperationStatus.Error) {
            newLocal.companies = [];
          } else {
            newLocal.companies = result.data;
          }
          newLocal.updateLocationData = {
            //company: this.currentLocation.companyData.name,
            company: newLocal.currentLocation.companyData.id,
            name: newLocal.currentLocation.name,
            gln13: newLocal.currentLocation.gln13,
            sgln: newLocal.currentLocation.sgln,
            latitude: newLocal.currentLocation.latitude.toString(),
            longitude: newLocal.currentLocation.longitude.toString(),
            description: newLocal.currentLocation.description,
            city: newLocal.currentLocation.city,
            country: newLocal.currentLocation.country,
            postalCode: newLocal.currentLocation.postalCode,
            address1: newLocal.currentLocation.address1,
            address2: newLocal.currentLocation.address2,
            address3: newLocal.currentLocation.address3,
            showError: false,
          };
          newLocal.isLoading = false;
          newLocal.updateLocationFormOpen = true;
        });
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(err.toString());
      });
  }

  closeUpdateLocationForm() {
    runInAction(() => {
      this.updateLocationFormOpen = false;
    });
  }

  onChangeUpdateLocationField = (id: string, value: any) => {
    runInAction(() => {
      this.updateLocationData[id] = value;
    });
  };

  openUpdateLocation = () => {
    runInAction(() => {
      console.log('Update Loc Data:', this.updateLocationData);
    });
  };

  submitUpdateLocation = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.updateLocationData.showError = false;
    let hasError = false;
    for (const field of requiredFields) {
      if (errorRequired(field, newLocal.updateLocationData[field])) {
        hasError = true;
        break;
      }
    }
    if (!hasError) {
      newLocal._mdApi
        .updateLocation({
          id: newLocal.currentLocation.id,
          kvintaLocation: {
            id: newLocal.currentLocation.id,
            companyId: newLocal.updateLocationData.company,
            name: newLocal.updateLocationData.name,
            gln13: newLocal.updateLocationData.gln13,
            sgln: newLocal.updateLocationData.sgln,
            latitude: parseFloat(newLocal.updateLocationData.latitude),
            longitude: parseFloat(newLocal.updateLocationData.longitude),
            description: newLocal.updateLocationData.description,
            city: newLocal.updateLocationData.city,
            country: newLocal.updateLocationData.country,
            postalCode: newLocal.updateLocationData.postalCode,
            address1: newLocal.updateLocationData.address1,
            address2: newLocal.updateLocationData.address2,
            address3: newLocal.updateLocationData.address3,
          },
        })
        .then((res) => {
          runInAction(() => {
            newLocal.isLoading = false;
          });
          newLocal._notificationManager.sendSuccess(`Successfully updated ${this.currentLocation.name}`);

          newLocal.fetchData();
        })
        .catch((err: Error) => {
          newLocal._notificationManager.sendError(err.toString());
        });
    } else {
      newLocal._notificationManager.sendError(
        'Some fields are empty or invalid \n Name and Company are required fields',
      );
      newLocal.updateLocationData.showError = true;
    }
  };

  async loadLocationReportingData(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.reportingData = null;

    async function getGln13() {
      return newLocal.currentLocation === null
        ? await newLocal._mdApi.getLocation({ id })
        : ({ data: { gln13: newLocal.currentLocation.gln13 } } as KvintaGetOneResponseLocation);
    }

    await getGln13()
      .then(({ data: { gln13 } }) => {
        return newLocal._mdApi.getLocationRegulatorReportingSystemByGln({ gln13 });
      })
      .then((result) => {
        runInAction(() => {
          if (result.operationStatus === KvintaOperationStatus.Ok) {
            const initialReportingData = Object.values(KvintaRegulatorReportingSystem).reduce((acc, val) => {
              acc[val] = false;
              return acc;
            }, {} as LocationReportingData);

            newLocal.reportingData = result.data.reduce((acc, r) => {
              acc[r.reportingSystem] = true;
              return acc;
            }, initialReportingData);
            newLocal.isLoading = false;
          }
        });
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(err.toString());
        newLocal.isLoading = false;
      });
  }

  submitLocationReportingForm = (reportingSystems: LocationReportingData) => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;

    newLocal._mdApi
      .updateLocationRegulatorReportingSystems({
        kvintaUpdateLocationReportingSystemsRequest: {
          reportingSystems: Object.entries(reportingSystems)
            .filter(([key, value]) => value === true)
            .map(([key]) => key) as KvintaRegulatorReportingSystem[],
          gln13: newLocal.currentLocation.gln13,
        },
      })
      .then((response) => {
        runInAction(() => {
          newLocal.isLoading = false;
          switch (response.operationStatus) {
            case KvintaOperationStatus.Ok:
              return this._notificationManager.sendSuccess('Location reporting updated');
            case KvintaOperationStatus.Error:
              return this._notificationManager.sendError(response.error);
          }
        });
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(err.toString());
      });
  };

  deleteLocation = (companyID: string) => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal._mdApi.deleteLocation({ id: companyID }).then((res) => {
      runInAction(() => {
        newLocal.isLoading = false;
      });
      newLocal.fetchData();
    });
  };

  /**
   * Export functionallity
   */
  updateExported() {
    this.exportData = undefined;
  }

  async exportSelected() {
    runInAction(() => {
      this.exportActive = false;
      this.exportData = undefined;
    });
    runInAction(() => {
      this.exportActive = true;
      this.exportData = this.listChecked;
    });
  }

  async exportAll() {
    runInAction(() => {
      this.exportActive = false;
      this.exportData = undefined;
    });
    try {
      const locationListResult = await this._mdApi.getLocationList({
        kvintaGetListRequest: {
          pagination: { page: this.page, perPage: this.pageSize },
        },
      });
      runInAction(() => {
        this.exportActive = true;
        this.exportData = locationListResult.data;
      });
    } catch (err) {
      this._notificationManager.sendError(JSON.stringify(err));
    }
  }

  /*
   * *********** Export functionality
   **/

  async fetchLocation(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.currentLocation = null;
    const locationResult = await newLocal._mdApi.getLocation({ id });
    if (locationResult.operationStatus !== KvintaOperationStatus.Ok) {
      newLocal._notificationManager.sendError(locationResult.error);
      newLocal.isLoading = false;
    } else if (!locationResult.data) {
      newLocal._notificationManager.sendError('location not found');
      newLocal.isLoading = false;
    } else {
      const companyResult = await newLocal._mdApi.getCompany({ id: locationResult.data.companyId });
      if (companyResult.operationStatus !== KvintaOperationStatus.Ok) {
        newLocal._notificationManager.sendError(companyResult.error);
      } else {
        runInAction(() => {
          newLocal._mdApi
            .getCompanyList({
              kvintaGetListRequest: { pagination: { page: 0, perPage: 10000 } },
            })
            .then((result) => {
              runInAction(() => {
                if (result.operationStatus === KvintaOperationStatus.Error) {
                  newLocal.companies = [];
                } else {
                  newLocal.companies = result.data;
                  newLocal.currentLocation = { ...locationResult.data, companyData: companyResult.data };
                  newLocal.updateLocationData = {
                    //company: this.currentLocation.companyData.name,
                    company: newLocal.currentLocation.companyId,
                    name: newLocal.currentLocation.name,
                    gln13: newLocal.currentLocation.gln13,
                    sgln: newLocal.currentLocation.sgln,
                    latitude: newLocal.currentLocation.latitude
                      ? newLocal.currentLocation.latitude.toString()
                      : undefined,
                    longitude: newLocal.currentLocation.longitude
                      ? newLocal.currentLocation.longitude.toString()
                      : undefined,
                    description: newLocal.currentLocation.description,
                    city: newLocal.currentLocation.city,
                    country: newLocal.currentLocation.country,
                    postalCode: newLocal.currentLocation.postalCode,
                    address1: newLocal.currentLocation.address1,
                    address2: newLocal.currentLocation.address2,
                    address3: newLocal.currentLocation.address3,
                    showError: false,
                  };
                  newLocal.isLoading = true;
                }
              });
            });
        });
      }
    }
  }
}
export const STORE_ID = 'locationsStore';

function getField(orderBy: number): string {
  switch (orderBy) {
    case 1:
      return 'name';
    case 2:
      return 'gln13';
    case 3:
      return 'sgln';
    case 4:
      return 'latitude';
    case 5:
      return 'longitude';
    case 6:
      return 'description';
    default:
      return 'name';
  }
}

const searchedColumns = ['name']; //, 'sgln', 'gln13', 'latitude', 'longitude', 'description']; // TODO: API is not adopted to search multiple columns

const requiredFields = ['company', 'name'];

export function errorRequired(id: string, value: any): boolean {
  if (requiredFields.includes(id) && (value === undefined || value === '')) {
    return true;
  }
  return false;
}
