import { makeObservable, observable } from 'mobx';
import { TAppOptionsConfig } from 'kvinta/common/Interfaces';
import { DefaultApi as StatuStoreApi, KvintaOperationStatus, KvintaResource } from 'kvinta/apis/kvinta-status-store';
import { DefaultApi as DocumentStoreApi, KvintaResourceTypeFromJSON } from 'kvinta/apis/kvinta-document-store';
import { NotificationManager } from 'kvinta/modules/main';
import { PageContentStore } from 'kvinta/components';

export class TechDocStore {
  private _config: TAppOptionsConfig;
  private _statusStoreApi: StatuStoreApi;
  private _documentStoreApi: DocumentStoreApi;
  private _notificationManager: NotificationManager;
  private pageContentStore: PageContentStore;

  isLoading: boolean;
  width: number | undefined;
  height: number | undefined;

  constructor(
    config: TAppOptionsConfig,
    notificationManager: NotificationManager,
    statusStoreApi: StatuStoreApi,
    documentStoreApi: DocumentStoreApi,
    pageContentStore: PageContentStore,
  ) {
    makeObservable(this, {
      isLoading: observable,
      width: observable,
      height: observable,
    });

    this._config = config;
    this._statusStoreApi = statusStoreApi;
    this._documentStoreApi = documentStoreApi;
    this._notificationManager = notificationManager;
    this.pageContentStore = pageContentStore;
  }

  triggerResize(width: number, height: number) {
    // if (this.currentHierarchyData) {
    this.width = width;
    this.height = height;
    // }
  }

  fetchResource = async (resource: KvintaResource): Promise<IResourceFile> => {
    try {
      const docStoreRes = await this._documentStoreApi.readResource({
        kvintaOperationRequestResource: {
          input: {
            id: resource.id,
            system: resource.system,
            type: KvintaResourceTypeFromJSON(resource.type),
            parentResourceId: resource.parentResourceId,
            sgln: resource.sgln,
            tenantId: resource.tenantId,
            userId: resource.id,
          },
        },
      });
      if (docStoreRes.status !== KvintaOperationStatus.Ok) {
        this._notificationManager.sendError(JSON.stringify(docStoreRes.error));
        return { fetched: false, filename: resource.id + '.txt', contents: JSON.stringify(resource) } as IResourceFile;
      } else {
        return { fetched: true, filename: resource.id + '.txt', contents: docStoreRes.data } as IResourceFile;
      }
    } catch (err) {
      this._notificationManager.sendError(JSON.stringify(err));
      return { fetched: false, filename: resource.id + '.txt', contents: JSON.stringify(resource) } as IResourceFile;
    }
  };
}

export const TECH_DOC_STORE_ID = 'techDocStore';

export interface IResourceFile {
  fetched: boolean;
  filename?: string;
  contents?: string;
}
