/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaOperationStatus,
    KvintaOperationStatusFromJSON,
    KvintaOperationStatusFromJSONTyped,
    KvintaOperationStatusToJSON,
    KvintaProduct,
    KvintaProductFromJSON,
    KvintaProductFromJSONTyped,
    KvintaProductToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaUpdateResponseProduct
 */
export interface KvintaUpdateResponseProduct {
    /**
     * 
     * @type {KvintaProduct}
     * @memberof KvintaUpdateResponseProduct
     */
    data?: KvintaProduct;
    /**
     * 
     * @type {string}
     * @memberof KvintaUpdateResponseProduct
     */
    error?: string | null;
    /**
     * 
     * @type {KvintaOperationStatus}
     * @memberof KvintaUpdateResponseProduct
     */
    operationStatus: KvintaOperationStatus;
}

export function KvintaUpdateResponseProductFromJSON(json: any): KvintaUpdateResponseProduct {
    return KvintaUpdateResponseProductFromJSONTyped(json, false);
}

export function KvintaUpdateResponseProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaUpdateResponseProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : KvintaProductFromJSON(json['data']),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'operationStatus': KvintaOperationStatusFromJSON(json['operationStatus']),
    };
}

export function KvintaUpdateResponseProductToJSON(value?: KvintaUpdateResponseProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': KvintaProductToJSON(value.data),
        'error': value.error,
        'operationStatus': KvintaOperationStatusToJSON(value.operationStatus),
    };
}


