import { Group } from '@visx/group';
import * as React from 'react';
import { hierarchy, Tree } from '@visx/hierarchy';
import { LinkHorizontal } from '@visx/shape';
import { useTooltip } from '@visx/tooltip';
import { ExecutionHierarchyNode } from './ExecutionHierarchyNode';
import { TTaskNode } from './types';
import { UncontrolledReactSVGPanZoom, TOOL_AUTO } from 'react-svg-pan-zoom';
import { Tooltip, TooltipBase, TooltipMenuItem, TooltipText } from 'kvinta/components';
import { useHistory } from 'react-router-dom';
import { ProcessesStatusStore } from '../ProcessesStatusStore';
import { DialogUploadRawPayload } from './DialogUploadRawPayload';
import { navigateExecutionStatusPath } from 'kvinta/modules/paths';
import { KvintaExecutionStatus, KvintaExecutionStatusType } from 'kvinta/apis/kvinta-status-store';

type HierarchyViewProps = {
  hierarchyData: TTaskNode;
  flatData: { id: string }[];
  width: number;
  height: number;
  margin: { top: number; right: number; bottom: number; left: number };
  processesStatusStore?: ProcessesStatusStore;
};

export const ExecutionHierarchyView: React.FunctionComponent<HierarchyViewProps> = ({
  hierarchyData,
  flatData,
  height,
  width,
  margin,
  processesStatusStore,
}: HierarchyViewProps) => {
  const history = useHistory();
  const Viewer = React.useRef(null);

  const root = React.useMemo(() => hierarchy(hierarchyData), []);
  const showUploadDialog = processesStatusStore.uploadRawResourceFormOpen;

  const hierarchyDimensions = root.descendants().reduce(
    (acc, node) => {
      return {
        maxDepth: node.depth + 1 > acc.maxDepth ? node.depth + 1 : acc.maxDepth,
        maxHeight: node.height + 1 > acc.maxHeight ? node.height + 1 : acc.maxHeight,
      };
    },
    { maxDepth: 0, maxHeight: 0 },
  );

  const maxNodeHeight = 60;
  const maxNodeWidth = 180;

  const svgWidth = hierarchyDimensions.maxDepth * maxNodeWidth;
  const divWidth = svgWidth + margin.left + margin.right + maxNodeWidth / 2;

  const minSvgHeight = hierarchyDimensions.maxHeight * maxNodeHeight;
  const verticalMargins = margin.top + margin.bottom;
  const svgHeightWithMargins = minSvgHeight + verticalMargins;
  const divHeight = svgHeightWithMargins > height ? svgHeightWithMargins : height;
  const svgHeight = minSvgHeight > height - verticalMargins ? minSvgHeight : height - verticalMargins;

  const [tooltipShouldDetectBounds, setTooltipShouldDetectBounds] = React.useState(true);
  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipLeft = 0,
    tooltipTop = 0,
    tooltipData,
  } = useTooltip<any>({
    tooltipOpen: false,
    tooltipLeft: width / 3,
    tooltipTop: height / 3,
    tooltipData: '',
  });

  const getRetriableStatuses = (nodeData: INodeStatuses): KvintaExecutionStatus[] => {
    return nodeData.statuses.filter((st) => st.status == KvintaExecutionStatusType.Started);
  };
  const isRetriable = (nodeData: INodeStatuses): boolean => {
    const startedStatuses = getRetriableStatuses(nodeData);
    if (
      startedStatuses &&
      startedStatuses.length > 0 &&
      startedStatuses[0].resource &&
      startedStatuses[0].resource.id
    ) {
      // We have resource
      return true;
    }
    return false;
  };

  const retryExecution = (executionId: string): void => {
    processesStatusStore.tiggerRetryExecution(executionId);
  };

  const retryExecutionWithDifferentResource = (executionId: string): void => {
    processesStatusStore.openUploadRawResourceForm(executionId);
  };

  const handleMouseClick = React.useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      const containerBounds = event.currentTarget.getBoundingClientRect();
      const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left;
      const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top;

      const nodeId = (event.target as Element).getAttribute('data-node-id');

      async function handleCopy(e, text) {
        e.stopPropagation();
        e.preventDefault();

        await navigator.clipboard
          .writeText(text)
          .then(hideTooltip)
          .catch((e) => console.log('handleCopy error', e));
      }

      async function copyResource(e, nodeData: INodeStatuses) {
        e.stopPropagation();
        e.preventDefault();

        const startedStatuses = getRetriableStatuses(nodeData);
        if (
          startedStatuses &&
          startedStatuses.length > 0 &&
          startedStatuses[0].resource &&
          startedStatuses[0].resource.id
        ) {
          await navigator.clipboard
            .writeText(JSON.stringify(startedStatuses[0].resource))
            .then(hideTooltip)
            .catch((e) => console.log('handleCopy error', e));
          processesStatusStore.notifyInfo('Resource json copied to clipboard.');
        }
      }

      async function copyContext(e, nodeData: INodeContext) {
        e.stopPropagation();
        e.preventDefault();

        await navigator.clipboard
          .writeText(nodeData.contextJSON)
          .then(hideTooltip)
          .catch((e) => console.log('copyContext error', e));
        processesStatusStore.notifyInfo('Context json copied to clipboard.');
      }

      async function downloadResource(e, nodeData: INodeStatuses) {
        e.stopPropagation();
        e.preventDefault();

        const startedStatuses = getRetriableStatuses(nodeData);
        if (
          startedStatuses &&
          startedStatuses.length > 0 &&
          startedStatuses[0].resource &&
          startedStatuses[0].resource.id
        ) {
          const fileData = await processesStatusStore.fetchResource(startedStatuses[0].resource);
          if (fileData.fetched) {
            download(fileData.filename, fileData.contents);
          }
        }
      }

      function goToLink(e, nodeId) {
        e.preventDefault();
        navigateExecutionStatusPath(history, nodeId);
      }

      if (nodeId !== null) {
        const nodeData = flatData[nodeId];
        showTooltip({
          tooltipLeft: containerX,
          tooltipTop: containerY,
          tooltipData: (
            <TooltipBase nodeId={nodeId}>
              <TooltipText text={`Function: ${nodeData.function}`} />
              <TooltipText text={`Service: ${nodeData.service}`} />
              {nodeData.message && <TooltipText text={`Message: ${nodeData.message}`} />}
              {nodeData.status === 'ERROR' && <TooltipText text={`Error: ${nodeData.error}`} />}
              <span className="divider" />
              <TooltipMenuItem onClick={handleCopy} text="Copy Execution Id to clipboard" nodeId={nodeId} />
              <TooltipMenuItem
                onClick={(e) => {
                  copyContext(e, nodeData);
                }}
                text="Copy Context to clipboard"
                nodeId={nodeId}
              />
              <TooltipMenuItem text="Show raw data" nodeId={nodeId} onClick={goToLink} />
              {isRetriable(nodeData) && (
                <>
                  <TooltipMenuItem text="Retry" nodeId={nodeId} onClick={() => retryExecution(nodeId)} />
                  <TooltipMenuItem
                    text="Retry with different resource"
                    nodeId={nodeId}
                    onClick={() => retryExecutionWithDifferentResource(nodeId)}
                  />
                  <TooltipMenuItem text="Copy resource" nodeId={nodeId} onClick={(e) => copyResource(e, nodeData)} />
                  <TooltipMenuItem
                    text="Download resource"
                    nodeId={nodeId}
                    onClick={(e) => downloadResource(e, nodeData)}
                  />
                </>
              )}
            </TooltipBase>
          ),
        });
      } else {
        hideTooltip();
      }
    },

    [showTooltip, tooltipShouldDetectBounds],
  );

  return width < 10 ? null : (
    <div onClick={handleMouseClick} style={{ boxShadow: 'none' }}>
      <UncontrolledReactSVGPanZoom
        ref={Viewer}
        width={width}
        height={height}
        customToolbar={() => null}
        customMiniature={() => null}
        background={'#ffffff'}
        tool={TOOL_AUTO}
        detectAutoPan={false}
      >
        <svg width={divWidth} height={divHeight}>
          <Tree<TTaskNode> root={root} size={[svgHeight, svgWidth]}>
            {(tree) => (
              <Group top={margin.top} left={margin.left}>
                {tree.links().map((link, i) => (
                  <LinkHorizontal key={`link-${i}`} data={link} stroke={'#AAABAD'} strokeWidth="1" fill="none" />
                ))}
                {tree.descendants().map((node, i) => (
                  <ExecutionHierarchyNode key={`node-${i}`} node={node} />
                ))}
              </Group>
            )}
          </Tree>
        </svg>
      </UncontrolledReactSVGPanZoom>
      {tooltipOpen ? <Tooltip tooltipLeft={tooltipLeft} tooltipTop={tooltipTop} tooltipData={tooltipData} /> : null}
      {showUploadDialog && <DialogUploadRawPayload />}
    </div>
  );
};

interface INodeStatuses {
  statuses: KvintaExecutionStatus[];
}

interface INodeContext {
  contextJSON: string;
}

function download(filename, text) {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
