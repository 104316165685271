/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaResourceStatusType,
    KvintaResourceStatusTypeFromJSON,
    KvintaResourceStatusTypeFromJSONTyped,
    KvintaResourceStatusTypeToJSON,
    KvintaResourceType,
    KvintaResourceTypeFromJSON,
    KvintaResourceTypeFromJSONTyped,
    KvintaResourceTypeToJSON,
    KvintaSoftwareSystem,
    KvintaSoftwareSystemFromJSON,
    KvintaSoftwareSystemFromJSONTyped,
    KvintaSoftwareSystemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaCloseStaleResourcesRequest
 */
export interface KvintaCloseStaleResourcesRequest {
    /**
     * 
     * @type {KvintaResourceStatusType}
     * @memberof KvintaCloseStaleResourcesRequest
     */
    errorStatus: KvintaResourceStatusType;
    /**
     * 
     * @type {KvintaResourceStatusType}
     * @memberof KvintaCloseStaleResourcesRequest
     */
    lastStatus: KvintaResourceStatusType;
    /**
     * 
     * @type {number}
     * @memberof KvintaCloseStaleResourcesRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaCloseStaleResourcesRequest
     */
    olderThanSec?: number;
    /**
     * 
     * @type {KvintaResourceType}
     * @memberof KvintaCloseStaleResourcesRequest
     */
    resourceType: KvintaResourceType;
    /**
     * 
     * @type {KvintaSoftwareSystem}
     * @memberof KvintaCloseStaleResourcesRequest
     */
    system: KvintaSoftwareSystem;
}

export function KvintaCloseStaleResourcesRequestFromJSON(json: any): KvintaCloseStaleResourcesRequest {
    return KvintaCloseStaleResourcesRequestFromJSONTyped(json, false);
}

export function KvintaCloseStaleResourcesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCloseStaleResourcesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errorStatus': KvintaResourceStatusTypeFromJSON(json['errorStatus']),
        'lastStatus': KvintaResourceStatusTypeFromJSON(json['lastStatus']),
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'olderThanSec': !exists(json, 'olderThanSec') ? undefined : json['olderThanSec'],
        'resourceType': KvintaResourceTypeFromJSON(json['resourceType']),
        'system': KvintaSoftwareSystemFromJSON(json['system']),
    };
}

export function KvintaCloseStaleResourcesRequestToJSON(value?: KvintaCloseStaleResourcesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errorStatus': KvintaResourceStatusTypeToJSON(value.errorStatus),
        'lastStatus': KvintaResourceStatusTypeToJSON(value.lastStatus),
        'limit': value.limit,
        'olderThanSec': value.olderThanSec,
        'resourceType': KvintaResourceTypeToJSON(value.resourceType),
        'system': KvintaSoftwareSystemToJSON(value.system),
    };
}


