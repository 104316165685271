import { Box, FormControl, Grid, InputLabel, OutlinedInput } from '@material-ui/core';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KvintaSerialNumberOrder } from 'kvinta/apis/kvinta-document-store';
import { SerialNumberOrdersStore, STORE_ID } from '../SerialNumberOrdersStore';
import { inject, observer } from 'mobx-react';

export interface SerialNumberOrderComponentProps {
  serialNumberOrdersStore?: SerialNumberOrdersStore;
  serialNumberOrder?: KvintaSerialNumberOrder;
  intl: any;
}
class SerialNumberOrderCompViewImpl extends Component<SerialNumberOrderComponentProps> {
  render() {
    const comp = this.props.serialNumberOrder;
    if (!comp) {
      return null;
    }
    const intl = this.props.intl;
    const formData = comp;

    const documentDate = new Date(formData.documentDate);
    const formattedDocumentDate =
      ('0' + documentDate.getDate()).slice(-2) +
      '.' +
      ('0' + (documentDate.getMonth() + 1)).slice(-2) +
      '.' +
      documentDate.getFullYear();
    const formattedCreatedDate = this.props.serialNumberOrdersStore.formatDateTime(formData.created);

    return (
      <Grid container>
        <Grid item xs={6}>
          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              {/*Background color used to fix an overlap bug between title and border */}
              <InputLabel htmlFor="orderid" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'serial-numbers.orderid' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="orderid"
                defaultValue={formData.id}
                label={intl.formatMessage({ id: 'serial-numbers.orderid' })}
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="documentNumber" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'serial-number-order-form.field.documentnumber' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="documentNumber"
                defaultValue={formData.documentNumber}
                label={intl.formatMessage({ id: 'serial-number-order-form.field.documentnumber' })}
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="documentDate" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'serial-number-order-form.field.documentdate' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="documentDate"
                defaultValue={formattedDocumentDate}
                label={intl.formatMessage({ id: 'serial-number-order-form.field.documentdate' })}
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="created" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'serial-number-order-form.field.created' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="created"
                defaultValue={formattedCreatedDate}
                label={intl.formatMessage({ id: 'serial-number-order-form.field.created' })}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="contactPerson" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'serial-number-order-form.field.contactperson' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="contactPerson"
                defaultValue={formData.contactPerson}
                label={intl.formatMessage({ id: 'serial-number-order-form.field.contactperson' })}
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl variant="outlined" key="Company Prefix" fullWidth>
              <InputLabel htmlFor="lastStatus" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'serial-number-order-form.field.documentstatus' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="lastStatus"
                defaultValue={formData.lastStatus}
                label={intl.formatMessage({ id: 'serial-number-order-form.field.documentstatus' })}
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl variant="outlined" key="Company Prefix" fullWidth>
              <InputLabel htmlFor="serialNumberOrderType" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'serial-number-order-form.field.ordertype' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="serialNumberOrderType"
                defaultValue={formData.serialNumberOrderType}
                label={intl.formatMessage({ id: 'serial-number-order-form.field.ordertype' })}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export const SerialNumberOrderCompView = injectIntl(inject(STORE_ID)(observer(SerialNumberOrderCompViewImpl)));
