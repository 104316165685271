import { Paper, Button, FormControl, InputLabel, OutlinedInput, Select, Grid, Box, MenuItem } from '@material-ui/core';
import GetApp from '@material-ui/icons/GetApp';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import { KSidebarStore, PageContentStore, PAGE_CONTENT_STORE_ID, SIDEBAR_STORE_ID } from 'kvinta/components';
import { TechDocStore, TECH_DOC_STORE_ID } from './TechDocStore';
import { KvintaResource, KvintaResourceType, KvintaResourceTypeFromJSON } from 'kvinta/apis/kvinta-status-store';
import { KvintaSoftwareSystem, KvintaSoftwareSystemFromJSON } from 'kvinta/apis/kvinta-document-store';
import { downloadFile } from '../../../service/fileExport';

interface ViewDownloadTechDocProps {
  techDocStore?: TechDocStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
}

interface ViewDownloadTechDocState {
  resourceId?: string;
  resourceType?: string;
  softwareSystem?: string;
  tenantId?: string;
}

class ViewDownloadTechDocImpl extends Component<ViewDownloadTechDocProps, ViewDownloadTechDocState> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ViewDownloadTechDocProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = { softwareSystem: KvintaSoftwareSystem.Kvinta };
  }

  componentDidMount() {
    // this.props.sidebarStore.clearMainTitle();
  }

  handleDownload = async () => {
    const resource = {
      id: this.state.resourceId,
      type: KvintaResourceTypeFromJSON(this.state.resourceType),
      system: KvintaSoftwareSystemFromJSON(this.state.softwareSystem),
      tenantId: this.state.tenantId,
    } as KvintaResource;
    const fetchResponse = await this.props.techDocStore.fetchResource(resource);
    if (fetchResponse.fetched) {
      downloadFile(fetchResponse.filename, 'text/application', fetchResponse.contents);
    }
  };

  render() {
    const resourceTypes = Object.values(KvintaResourceType)
      .map((value) => ({
        key: value.toString(),
        label: value.toString(),
      }))
      .sort((a, b) => (a.key < b.key ? -1 : 1));
    const softwareSystems = Object.values(KvintaSoftwareSystem)
      .map((value) => ({
        key: value.toString(),
        label: value.toString(),
      }))
      .sort((a, b) => (a.key < b.key ? -1 : 1));
    const intl = this.props.intl;
    const { isLoading } = this.props.techDocStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
    return (
      <Route
        render={({ history }) => (
          <div>
            <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
              <Grid container>
                <Grid item xs={6}>
                  <Box m={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel style={{ backgroundColor: 'white' }} htmlFor="resourceId">
                        {intl.formatMessage({ id: 'techdoc.download.field.resourceid' })}
                      </InputLabel>
                      <OutlinedInput
                        id="resourceId"
                        onChange={(e) => {
                          this.setState({
                            ...this.state,
                            resourceId: e.target.value,
                          });
                        }}
                        label="resource id label"
                      />
                    </FormControl>
                  </Box>
                  <Box m={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel style={{ backgroundColor: 'white' }} htmlFor="resourcetype">
                        {intl.formatMessage({ id: 'techdoc.download.field.resourcetype' })}
                      </InputLabel>
                      <Select
                        id="resourcetype"
                        onChange={(e) => {
                          this.setState({
                            ...this.state,
                            resourceType: e.target.value as string,
                          });
                        }}
                        label={intl.formatMessage({ id: 'techdoc.download.field.resourcetype' })}
                      >
                        {resourceTypes.map((resourceType) => (
                          <MenuItem key={resourceType.key} value={resourceType.label}>
                            {resourceType.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box m={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel style={{ backgroundColor: 'white' }} htmlFor="softwaresystem">
                        {intl.formatMessage({ id: 'techdoc.download.field.softwaresystem' })}
                      </InputLabel>
                      <Select
                        id="country"
                        defaultValue={KvintaSoftwareSystem.Kvinta}
                        onChange={(e) => {
                          this.setState({
                            ...this.state,
                            softwareSystem: e.target.value as string,
                          });
                        }}
                        label={intl.formatMessage({ id: 'techdoc.download.field.softwaresystem' })}
                      >
                        {softwareSystems.map((softwareSystem) => (
                          <MenuItem key={softwareSystem.key} value={softwareSystem.label}>
                            {softwareSystem.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box m={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel style={{ backgroundColor: 'white' }} htmlFor="tenantId">
                        {intl.formatMessage({ id: 'techdoc.download.field.tenantid' })}
                      </InputLabel>
                      <OutlinedInput
                        id="tenantId"
                        onChange={(e) => {
                          this.setState({
                            ...this.state,
                            tenantId: e.target.value,
                          });
                        }}
                        label="tenant id label"
                      />
                    </FormControl>
                  </Box>
                  <Box m={2}>
                    <div style={{ marginRight: '0px', marginTop: '0px', float: 'right' }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<GetApp color="primary" />}
                        onClick={(e) => {
                          this.handleDownload();
                        }}
                      >
                        {intl.formatMessage({ id: 'button.download' })}
                      </Button>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </div>
        )}
      />
    );
  }
}
export const ViewDownloadTechDoc = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, TECH_DOC_STORE_ID, SIDEBAR_STORE_ID)(observer(ViewDownloadTechDocImpl)),
);
