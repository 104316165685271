/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaCompany,
    KvintaCompanyFromJSON,
    KvintaCompanyToJSON,
    KvintaCreateCompanyRequest,
    KvintaCreateCompanyRequestFromJSON,
    KvintaCreateCompanyRequestToJSON,
    KvintaCreateCustomerRequest,
    KvintaCreateCustomerRequestFromJSON,
    KvintaCreateCustomerRequestToJSON,
    KvintaCreateLocationRegulatorReportingSystemRequest,
    KvintaCreateLocationRegulatorReportingSystemRequestFromJSON,
    KvintaCreateLocationRegulatorReportingSystemRequestToJSON,
    KvintaCreateLocationRequest,
    KvintaCreateLocationRequestFromJSON,
    KvintaCreateLocationRequestToJSON,
    KvintaCreateProductRequest,
    KvintaCreateProductRequestFromJSON,
    KvintaCreateProductRequestToJSON,
    KvintaCreateProductTradeItemRequest,
    KvintaCreateProductTradeItemRequestFromJSON,
    KvintaCreateProductTradeItemRequestToJSON,
    KvintaCreateResponseCompany,
    KvintaCreateResponseCompanyFromJSON,
    KvintaCreateResponseCompanyToJSON,
    KvintaCreateResponseCustomer,
    KvintaCreateResponseCustomerFromJSON,
    KvintaCreateResponseCustomerToJSON,
    KvintaCreateResponseLocation,
    KvintaCreateResponseLocationFromJSON,
    KvintaCreateResponseLocationToJSON,
    KvintaCreateResponseLocationRegulatorReportingSystem,
    KvintaCreateResponseLocationRegulatorReportingSystemFromJSON,
    KvintaCreateResponseLocationRegulatorReportingSystemToJSON,
    KvintaCreateResponseProduct,
    KvintaCreateResponseProductFromJSON,
    KvintaCreateResponseProductToJSON,
    KvintaCreateResponseProductTradeItem,
    KvintaCreateResponseProductTradeItemFromJSON,
    KvintaCreateResponseProductTradeItemToJSON,
    KvintaCreateResponseTradeItem,
    KvintaCreateResponseTradeItemFromJSON,
    KvintaCreateResponseTradeItemToJSON,
    KvintaCreateTradeItemRequest,
    KvintaCreateTradeItemRequestFromJSON,
    KvintaCreateTradeItemRequestToJSON,
    KvintaCustomer,
    KvintaCustomerFromJSON,
    KvintaCustomerToJSON,
    KvintaDeleteManyRequest,
    KvintaDeleteManyRequestFromJSON,
    KvintaDeleteManyRequestToJSON,
    KvintaDeleteManyResponse,
    KvintaDeleteManyResponseFromJSON,
    KvintaDeleteManyResponseToJSON,
    KvintaDeleteResponse,
    KvintaDeleteResponseFromJSON,
    KvintaDeleteResponseToJSON,
    KvintaFindProductBySkuRequest,
    KvintaFindProductBySkuRequestFromJSON,
    KvintaFindProductBySkuRequestToJSON,
    KvintaGetGtinInfoBatchRequest,
    KvintaGetGtinInfoBatchRequestFromJSON,
    KvintaGetGtinInfoBatchRequestToJSON,
    KvintaGetListRequest,
    KvintaGetListRequestFromJSON,
    KvintaGetListRequestToJSON,
    KvintaGetListResponseCompany,
    KvintaGetListResponseCompanyFromJSON,
    KvintaGetListResponseCompanyToJSON,
    KvintaGetListResponseCustomer,
    KvintaGetListResponseCustomerFromJSON,
    KvintaGetListResponseCustomerToJSON,
    KvintaGetListResponseGtinDescriptionSku,
    KvintaGetListResponseGtinDescriptionSkuFromJSON,
    KvintaGetListResponseGtinDescriptionSkuToJSON,
    KvintaGetListResponseGtinInfo,
    KvintaGetListResponseGtinInfoFromJSON,
    KvintaGetListResponseGtinInfoToJSON,
    KvintaGetListResponseLocation,
    KvintaGetListResponseLocationFromJSON,
    KvintaGetListResponseLocationToJSON,
    KvintaGetListResponseLocationRegulatorReportingSystem,
    KvintaGetListResponseLocationRegulatorReportingSystemFromJSON,
    KvintaGetListResponseLocationRegulatorReportingSystemToJSON,
    KvintaGetListResponseProduct,
    KvintaGetListResponseProductFromJSON,
    KvintaGetListResponseProductToJSON,
    KvintaGetListResponseTradeItem,
    KvintaGetListResponseTradeItemFromJSON,
    KvintaGetListResponseTradeItemToJSON,
    KvintaGetManyFilterString,
    KvintaGetManyFilterStringFromJSON,
    KvintaGetManyFilterStringToJSON,
    KvintaGetOneResponseCompany,
    KvintaGetOneResponseCompanyFromJSON,
    KvintaGetOneResponseCompanyToJSON,
    KvintaGetOneResponseCustomer,
    KvintaGetOneResponseCustomerFromJSON,
    KvintaGetOneResponseCustomerToJSON,
    KvintaGetOneResponseGtinInfo,
    KvintaGetOneResponseGtinInfoFromJSON,
    KvintaGetOneResponseGtinInfoToJSON,
    KvintaGetOneResponseLocation,
    KvintaGetOneResponseLocationFromJSON,
    KvintaGetOneResponseLocationToJSON,
    KvintaGetOneResponseLocationRegulatorReportingSystem,
    KvintaGetOneResponseLocationRegulatorReportingSystemFromJSON,
    KvintaGetOneResponseLocationRegulatorReportingSystemToJSON,
    KvintaGetOneResponseProduct,
    KvintaGetOneResponseProductFromJSON,
    KvintaGetOneResponseProductToJSON,
    KvintaGetOneResponseTradeItem,
    KvintaGetOneResponseTradeItemFromJSON,
    KvintaGetOneResponseTradeItemToJSON,
    KvintaGetOneResponseTradeItemWithProduct,
    KvintaGetOneResponseTradeItemWithProductFromJSON,
    KvintaGetOneResponseTradeItemWithProductToJSON,
    KvintaGetProductsByCompanyRequest,
    KvintaGetProductsByCompanyRequestFromJSON,
    KvintaGetProductsByCompanyRequestToJSON,
    KvintaGetTradeItemsByProductRequest,
    KvintaGetTradeItemsByProductRequestFromJSON,
    KvintaGetTradeItemsByProductRequestToJSON,
    KvintaLocation,
    KvintaLocationFromJSON,
    KvintaLocationToJSON,
    KvintaMasterData,
    KvintaMasterDataFromJSON,
    KvintaMasterDataToJSON,
    KvintaMasterDataDownloadRequest,
    KvintaMasterDataDownloadRequestFromJSON,
    KvintaMasterDataDownloadRequestToJSON,
    KvintaMasterDataUploadRequest,
    KvintaMasterDataUploadRequestFromJSON,
    KvintaMasterDataUploadRequestToJSON,
    KvintaMasterDataUploadResponse,
    KvintaMasterDataUploadResponseFromJSON,
    KvintaMasterDataUploadResponseToJSON,
    KvintaProduct,
    KvintaProductFromJSON,
    KvintaProductToJSON,
    KvintaSearchRequest,
    KvintaSearchRequestFromJSON,
    KvintaSearchRequestToJSON,
    KvintaTradeItem,
    KvintaTradeItemFromJSON,
    KvintaTradeItemToJSON,
    KvintaUpdateLocationReportingSystemsRequest,
    KvintaUpdateLocationReportingSystemsRequestFromJSON,
    KvintaUpdateLocationReportingSystemsRequestToJSON,
    KvintaUpdateResponseCompany,
    KvintaUpdateResponseCompanyFromJSON,
    KvintaUpdateResponseCompanyToJSON,
    KvintaUpdateResponseCustomer,
    KvintaUpdateResponseCustomerFromJSON,
    KvintaUpdateResponseCustomerToJSON,
    KvintaUpdateResponseLocation,
    KvintaUpdateResponseLocationFromJSON,
    KvintaUpdateResponseLocationToJSON,
    KvintaUpdateResponseProduct,
    KvintaUpdateResponseProductFromJSON,
    KvintaUpdateResponseProductToJSON,
    KvintaUpdateResponseString,
    KvintaUpdateResponseStringFromJSON,
    KvintaUpdateResponseStringToJSON,
    KvintaUpdateResponseTradeItem,
    KvintaUpdateResponseTradeItemFromJSON,
    KvintaUpdateResponseTradeItemToJSON,
} from '../models';

export interface CreateCompanyRequest {
    kvintaCreateCompanyRequest: KvintaCreateCompanyRequest;
}

export interface CreateCustomerRequest {
    kvintaCreateCustomerRequest: KvintaCreateCustomerRequest;
}

export interface CreateLocationRequest {
    kvintaCreateLocationRequest: KvintaCreateLocationRequest;
}

export interface CreateLocationRegulatorReportingSystemRequest {
    kvintaCreateLocationRegulatorReportingSystemRequest: KvintaCreateLocationRegulatorReportingSystemRequest;
}

export interface CreateProductRequest {
    kvintaCreateProductRequest: KvintaCreateProductRequest;
}

export interface CreateProductWithTradeItemRequest {
    kvintaCreateProductTradeItemRequest: KvintaCreateProductTradeItemRequest;
}

export interface CreateTradeItemRequest {
    kvintaCreateTradeItemRequest: KvintaCreateTradeItemRequest;
}

export interface DeleteCompanyRequest {
    id: string;
}

export interface DeleteCustomerRequest {
    id: string;
}

export interface DeleteLocationRequest {
    id: string;
}

export interface DeleteLocationRegulatorReportingSystemRequest {
    id: string;
}

export interface DeleteManyCompaniesRequest {
    kvintaDeleteManyRequest: KvintaDeleteManyRequest;
}

export interface DeleteManyCustomersRequest {
    kvintaDeleteManyRequest: KvintaDeleteManyRequest;
}

export interface DeleteManyLocationRegulatorReportingSystemsRequest {
    kvintaDeleteManyRequest: KvintaDeleteManyRequest;
}

export interface DeleteManyLocationsRequest {
    kvintaDeleteManyRequest: KvintaDeleteManyRequest;
}

export interface DeleteManyProductsRequest {
    kvintaDeleteManyRequest: KvintaDeleteManyRequest;
}

export interface DeleteManyTradeItemsRequest {
    kvintaDeleteManyRequest: KvintaDeleteManyRequest;
}

export interface DeleteProductRequest {
    id: string;
}

export interface DeleteTradeItemRequest {
    id: string;
}

export interface DownloadRequest {
    kvintaMasterDataDownloadRequest: KvintaMasterDataDownloadRequest;
}

export interface FindProductBySkuRequest {
    kvintaFindProductBySkuRequest: KvintaFindProductBySkuRequest;
}

export interface GetCompaniesManyRequest {
    kvintaGetManyFilterString: KvintaGetManyFilterString;
}

export interface GetCompanyRequest {
    id: string;
}

export interface GetCompanyListRequest {
    kvintaGetListRequest: KvintaGetListRequest;
}

export interface GetCustomerRequest {
    id: string;
}

export interface GetCustomerListRequest {
    kvintaGetListRequest: KvintaGetListRequest;
}

export interface GetCustomersManyRequest {
    kvintaGetManyFilterString: KvintaGetManyFilterString;
}

export interface GetGtinInfoBatchByGtinRequest {
    kvintaGetGtinInfoBatchRequest: KvintaGetGtinInfoBatchRequest;
}

export interface GetGtinInfoByGtinRequest {
    body: string;
}

export interface GetLocationRequest {
    id: string;
}

export interface GetLocationByGlnRequest {
    gln13: string;
}

export interface GetLocationBySglnRequest {
    sgln: string;
}

export interface GetLocationListRequest {
    kvintaGetListRequest: KvintaGetListRequest;
}

export interface GetLocationRegulatorReportingSystemRequest {
    id: string;
}

export interface GetLocationRegulatorReportingSystemByGlnRequest {
    gln13: string;
}

export interface GetLocationRegulatorReportingSystemsListRequest {
    kvintaGetListRequest: KvintaGetListRequest;
}

export interface GetLocationRegulatorReportingSystemsManyRequest {
    kvintaGetManyFilterString: KvintaGetManyFilterString;
}

export interface GetLocationsByGlnListRequest {
    requestBody: Array<string>;
}

export interface GetLocationsManyRequest {
    kvintaGetManyFilterString: KvintaGetManyFilterString;
}

export interface GetProductRequest {
    id: string;
}

export interface GetProductListRequest {
    kvintaGetListRequest: KvintaGetListRequest;
}

export interface GetProductsByCompanyRequest {
    kvintaGetProductsByCompanyRequest: KvintaGetProductsByCompanyRequest;
}

export interface GetProductsManyRequest {
    kvintaGetManyFilterString: KvintaGetManyFilterString;
}

export interface GetTradeItemRequest {
    id: string;
}

export interface GetTradeItemListRequest {
    kvintaGetListRequest: KvintaGetListRequest;
}

export interface GetTradeItemProductByGtinRequest {
    gtin: string;
}

export interface GetTradeItemsByProductRequest {
    kvintaGetTradeItemsByProductRequest: KvintaGetTradeItemsByProductRequest;
}

export interface GetTradeItemsManyRequest {
    kvintaGetManyFilterString: KvintaGetManyFilterString;
}

export interface ListGtinDescriptionSkusRequest {
    kvintaGetListRequest: KvintaGetListRequest;
}

export interface SearchCompaniesRequest {
    kvintaSearchRequest: KvintaSearchRequest;
}

export interface SearchCustomersRequest {
    kvintaSearchRequest: KvintaSearchRequest;
}

export interface SearchLocationRegulatorReportingSystemsRequest {
    kvintaSearchRequest: KvintaSearchRequest;
}

export interface SearchLocationsRequest {
    kvintaSearchRequest: KvintaSearchRequest;
}

export interface SearchProductsRequest {
    kvintaSearchRequest: KvintaSearchRequest;
}

export interface SearchTradeItemsRequest {
    kvintaSearchRequest: KvintaSearchRequest;
}

export interface UpdateCompanyRequest {
    id: string;
    kvintaCompany: KvintaCompany;
}

export interface UpdateCustomerRequest {
    id: string;
    kvintaCustomer: KvintaCustomer;
}

export interface UpdateLocationRequest {
    id: string;
    kvintaLocation: KvintaLocation;
}

export interface UpdateLocationRegulatorReportingSystemsRequest {
    kvintaUpdateLocationReportingSystemsRequest: KvintaUpdateLocationReportingSystemsRequest;
}

export interface UpdateProductRequest {
    id: string;
    kvintaProduct: KvintaProduct;
}

export interface UpdateTradeItemRequest {
    id: string;
    kvintaTradeItem: KvintaTradeItem;
}

export interface UploadRequest {
    kvintaMasterDataUploadRequest: KvintaMasterDataUploadRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async createCompanyRaw(requestParameters: CreateCompanyRequest): Promise<runtime.ApiResponse<KvintaCreateResponseCompany>> {
        if (requestParameters.kvintaCreateCompanyRequest === null || requestParameters.kvintaCreateCompanyRequest === undefined) {
            throw new runtime.RequiredError('kvintaCreateCompanyRequest','Required parameter requestParameters.kvintaCreateCompanyRequest was null or undefined when calling createCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/companies/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaCreateCompanyRequestToJSON(requestParameters.kvintaCreateCompanyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaCreateResponseCompanyFromJSON(jsonValue));
    }

    /**
     */
    async createCompany(requestParameters: CreateCompanyRequest): Promise<KvintaCreateResponseCompany> {
        const response = await this.createCompanyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createCustomerRaw(requestParameters: CreateCustomerRequest): Promise<runtime.ApiResponse<KvintaCreateResponseCustomer>> {
        if (requestParameters.kvintaCreateCustomerRequest === null || requestParameters.kvintaCreateCustomerRequest === undefined) {
            throw new runtime.RequiredError('kvintaCreateCustomerRequest','Required parameter requestParameters.kvintaCreateCustomerRequest was null or undefined when calling createCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/customers/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaCreateCustomerRequestToJSON(requestParameters.kvintaCreateCustomerRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaCreateResponseCustomerFromJSON(jsonValue));
    }

    /**
     */
    async createCustomer(requestParameters: CreateCustomerRequest): Promise<KvintaCreateResponseCustomer> {
        const response = await this.createCustomerRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createLocationRaw(requestParameters: CreateLocationRequest): Promise<runtime.ApiResponse<KvintaCreateResponseLocation>> {
        if (requestParameters.kvintaCreateLocationRequest === null || requestParameters.kvintaCreateLocationRequest === undefined) {
            throw new runtime.RequiredError('kvintaCreateLocationRequest','Required parameter requestParameters.kvintaCreateLocationRequest was null or undefined when calling createLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/locations/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaCreateLocationRequestToJSON(requestParameters.kvintaCreateLocationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaCreateResponseLocationFromJSON(jsonValue));
    }

    /**
     */
    async createLocation(requestParameters: CreateLocationRequest): Promise<KvintaCreateResponseLocation> {
        const response = await this.createLocationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createLocationRegulatorReportingSystemRaw(requestParameters: CreateLocationRegulatorReportingSystemRequest): Promise<runtime.ApiResponse<KvintaCreateResponseLocationRegulatorReportingSystem>> {
        if (requestParameters.kvintaCreateLocationRegulatorReportingSystemRequest === null || requestParameters.kvintaCreateLocationRegulatorReportingSystemRequest === undefined) {
            throw new runtime.RequiredError('kvintaCreateLocationRegulatorReportingSystemRequest','Required parameter requestParameters.kvintaCreateLocationRegulatorReportingSystemRequest was null or undefined when calling createLocationRegulatorReportingSystem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/location-regulator-reporting-systems/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaCreateLocationRegulatorReportingSystemRequestToJSON(requestParameters.kvintaCreateLocationRegulatorReportingSystemRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaCreateResponseLocationRegulatorReportingSystemFromJSON(jsonValue));
    }

    /**
     */
    async createLocationRegulatorReportingSystem(requestParameters: CreateLocationRegulatorReportingSystemRequest): Promise<KvintaCreateResponseLocationRegulatorReportingSystem> {
        const response = await this.createLocationRegulatorReportingSystemRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createProductRaw(requestParameters: CreateProductRequest): Promise<runtime.ApiResponse<KvintaCreateResponseProduct>> {
        if (requestParameters.kvintaCreateProductRequest === null || requestParameters.kvintaCreateProductRequest === undefined) {
            throw new runtime.RequiredError('kvintaCreateProductRequest','Required parameter requestParameters.kvintaCreateProductRequest was null or undefined when calling createProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/products/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaCreateProductRequestToJSON(requestParameters.kvintaCreateProductRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaCreateResponseProductFromJSON(jsonValue));
    }

    /**
     */
    async createProduct(requestParameters: CreateProductRequest): Promise<KvintaCreateResponseProduct> {
        const response = await this.createProductRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createProductWithTradeItemRaw(requestParameters: CreateProductWithTradeItemRequest): Promise<runtime.ApiResponse<KvintaCreateResponseProductTradeItem>> {
        if (requestParameters.kvintaCreateProductTradeItemRequest === null || requestParameters.kvintaCreateProductTradeItemRequest === undefined) {
            throw new runtime.RequiredError('kvintaCreateProductTradeItemRequest','Required parameter requestParameters.kvintaCreateProductTradeItemRequest was null or undefined when calling createProductWithTradeItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/product-trade-item/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaCreateProductTradeItemRequestToJSON(requestParameters.kvintaCreateProductTradeItemRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaCreateResponseProductTradeItemFromJSON(jsonValue));
    }

    /**
     */
    async createProductWithTradeItem(requestParameters: CreateProductWithTradeItemRequest): Promise<KvintaCreateResponseProductTradeItem> {
        const response = await this.createProductWithTradeItemRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createTradeItemRaw(requestParameters: CreateTradeItemRequest): Promise<runtime.ApiResponse<KvintaCreateResponseTradeItem>> {
        if (requestParameters.kvintaCreateTradeItemRequest === null || requestParameters.kvintaCreateTradeItemRequest === undefined) {
            throw new runtime.RequiredError('kvintaCreateTradeItemRequest','Required parameter requestParameters.kvintaCreateTradeItemRequest was null or undefined when calling createTradeItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/trade-items/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaCreateTradeItemRequestToJSON(requestParameters.kvintaCreateTradeItemRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaCreateResponseTradeItemFromJSON(jsonValue));
    }

    /**
     */
    async createTradeItem(requestParameters: CreateTradeItemRequest): Promise<KvintaCreateResponseTradeItem> {
        const response = await this.createTradeItemRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteCompanyRaw(requestParameters: DeleteCompanyRequest): Promise<runtime.ApiResponse<KvintaDeleteResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/companies/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaDeleteResponseFromJSON(jsonValue));
    }

    /**
     */
    async deleteCompany(requestParameters: DeleteCompanyRequest): Promise<KvintaDeleteResponse> {
        const response = await this.deleteCompanyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteCustomerRaw(requestParameters: DeleteCustomerRequest): Promise<runtime.ApiResponse<KvintaDeleteResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/customers/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaDeleteResponseFromJSON(jsonValue));
    }

    /**
     */
    async deleteCustomer(requestParameters: DeleteCustomerRequest): Promise<KvintaDeleteResponse> {
        const response = await this.deleteCustomerRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteLocationRaw(requestParameters: DeleteLocationRequest): Promise<runtime.ApiResponse<KvintaDeleteResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/locations/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaDeleteResponseFromJSON(jsonValue));
    }

    /**
     */
    async deleteLocation(requestParameters: DeleteLocationRequest): Promise<KvintaDeleteResponse> {
        const response = await this.deleteLocationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteLocationRegulatorReportingSystemRaw(requestParameters: DeleteLocationRegulatorReportingSystemRequest): Promise<runtime.ApiResponse<KvintaDeleteResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLocationRegulatorReportingSystem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/location-regulator-reporting-systems/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaDeleteResponseFromJSON(jsonValue));
    }

    /**
     */
    async deleteLocationRegulatorReportingSystem(requestParameters: DeleteLocationRegulatorReportingSystemRequest): Promise<KvintaDeleteResponse> {
        const response = await this.deleteLocationRegulatorReportingSystemRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteManyCompaniesRaw(requestParameters: DeleteManyCompaniesRequest): Promise<runtime.ApiResponse<KvintaDeleteManyResponse>> {
        if (requestParameters.kvintaDeleteManyRequest === null || requestParameters.kvintaDeleteManyRequest === undefined) {
            throw new runtime.RequiredError('kvintaDeleteManyRequest','Required parameter requestParameters.kvintaDeleteManyRequest was null or undefined when calling deleteManyCompanies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/companies/delete-many`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaDeleteManyRequestToJSON(requestParameters.kvintaDeleteManyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaDeleteManyResponseFromJSON(jsonValue));
    }

    /**
     */
    async deleteManyCompanies(requestParameters: DeleteManyCompaniesRequest): Promise<KvintaDeleteManyResponse> {
        const response = await this.deleteManyCompaniesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteManyCustomersRaw(requestParameters: DeleteManyCustomersRequest): Promise<runtime.ApiResponse<KvintaDeleteManyResponse>> {
        if (requestParameters.kvintaDeleteManyRequest === null || requestParameters.kvintaDeleteManyRequest === undefined) {
            throw new runtime.RequiredError('kvintaDeleteManyRequest','Required parameter requestParameters.kvintaDeleteManyRequest was null or undefined when calling deleteManyCustomers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/customers/delete-many`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaDeleteManyRequestToJSON(requestParameters.kvintaDeleteManyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaDeleteManyResponseFromJSON(jsonValue));
    }

    /**
     */
    async deleteManyCustomers(requestParameters: DeleteManyCustomersRequest): Promise<KvintaDeleteManyResponse> {
        const response = await this.deleteManyCustomersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteManyLocationRegulatorReportingSystemsRaw(requestParameters: DeleteManyLocationRegulatorReportingSystemsRequest): Promise<runtime.ApiResponse<KvintaDeleteManyResponse>> {
        if (requestParameters.kvintaDeleteManyRequest === null || requestParameters.kvintaDeleteManyRequest === undefined) {
            throw new runtime.RequiredError('kvintaDeleteManyRequest','Required parameter requestParameters.kvintaDeleteManyRequest was null or undefined when calling deleteManyLocationRegulatorReportingSystems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/location-regulator-reporting-systems/delete-many`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaDeleteManyRequestToJSON(requestParameters.kvintaDeleteManyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaDeleteManyResponseFromJSON(jsonValue));
    }

    /**
     */
    async deleteManyLocationRegulatorReportingSystems(requestParameters: DeleteManyLocationRegulatorReportingSystemsRequest): Promise<KvintaDeleteManyResponse> {
        const response = await this.deleteManyLocationRegulatorReportingSystemsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteManyLocationsRaw(requestParameters: DeleteManyLocationsRequest): Promise<runtime.ApiResponse<KvintaDeleteManyResponse>> {
        if (requestParameters.kvintaDeleteManyRequest === null || requestParameters.kvintaDeleteManyRequest === undefined) {
            throw new runtime.RequiredError('kvintaDeleteManyRequest','Required parameter requestParameters.kvintaDeleteManyRequest was null or undefined when calling deleteManyLocations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/locations/delete-many`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaDeleteManyRequestToJSON(requestParameters.kvintaDeleteManyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaDeleteManyResponseFromJSON(jsonValue));
    }

    /**
     */
    async deleteManyLocations(requestParameters: DeleteManyLocationsRequest): Promise<KvintaDeleteManyResponse> {
        const response = await this.deleteManyLocationsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteManyProductsRaw(requestParameters: DeleteManyProductsRequest): Promise<runtime.ApiResponse<KvintaDeleteManyResponse>> {
        if (requestParameters.kvintaDeleteManyRequest === null || requestParameters.kvintaDeleteManyRequest === undefined) {
            throw new runtime.RequiredError('kvintaDeleteManyRequest','Required parameter requestParameters.kvintaDeleteManyRequest was null or undefined when calling deleteManyProducts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/products/delete-many`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaDeleteManyRequestToJSON(requestParameters.kvintaDeleteManyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaDeleteManyResponseFromJSON(jsonValue));
    }

    /**
     */
    async deleteManyProducts(requestParameters: DeleteManyProductsRequest): Promise<KvintaDeleteManyResponse> {
        const response = await this.deleteManyProductsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteManyTradeItemsRaw(requestParameters: DeleteManyTradeItemsRequest): Promise<runtime.ApiResponse<KvintaDeleteManyResponse>> {
        if (requestParameters.kvintaDeleteManyRequest === null || requestParameters.kvintaDeleteManyRequest === undefined) {
            throw new runtime.RequiredError('kvintaDeleteManyRequest','Required parameter requestParameters.kvintaDeleteManyRequest was null or undefined when calling deleteManyTradeItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/trade-items/delete-many`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaDeleteManyRequestToJSON(requestParameters.kvintaDeleteManyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaDeleteManyResponseFromJSON(jsonValue));
    }

    /**
     */
    async deleteManyTradeItems(requestParameters: DeleteManyTradeItemsRequest): Promise<KvintaDeleteManyResponse> {
        const response = await this.deleteManyTradeItemsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteProductRaw(requestParameters: DeleteProductRequest): Promise<runtime.ApiResponse<KvintaDeleteResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaDeleteResponseFromJSON(jsonValue));
    }

    /**
     */
    async deleteProduct(requestParameters: DeleteProductRequest): Promise<KvintaDeleteResponse> {
        const response = await this.deleteProductRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteTradeItemRaw(requestParameters: DeleteTradeItemRequest): Promise<runtime.ApiResponse<KvintaDeleteResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTradeItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/trade-items/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaDeleteResponseFromJSON(jsonValue));
    }

    /**
     */
    async deleteTradeItem(requestParameters: DeleteTradeItemRequest): Promise<KvintaDeleteResponse> {
        const response = await this.deleteTradeItemRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async downloadRaw(requestParameters: DownloadRequest): Promise<runtime.ApiResponse<KvintaMasterData>> {
        if (requestParameters.kvintaMasterDataDownloadRequest === null || requestParameters.kvintaMasterDataDownloadRequest === undefined) {
            throw new runtime.RequiredError('kvintaMasterDataDownloadRequest','Required parameter requestParameters.kvintaMasterDataDownloadRequest was null or undefined when calling download.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/master-data/download`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaMasterDataDownloadRequestToJSON(requestParameters.kvintaMasterDataDownloadRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaMasterDataFromJSON(jsonValue));
    }

    /**
     */
    async download(requestParameters: DownloadRequest): Promise<KvintaMasterData> {
        const response = await this.downloadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async findProductBySkuRaw(requestParameters: FindProductBySkuRequest): Promise<runtime.ApiResponse<KvintaGetOneResponseProduct>> {
        if (requestParameters.kvintaFindProductBySkuRequest === null || requestParameters.kvintaFindProductBySkuRequest === undefined) {
            throw new runtime.RequiredError('kvintaFindProductBySkuRequest','Required parameter requestParameters.kvintaFindProductBySkuRequest was null or undefined when calling findProductBySku.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/products/find-by-sku`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaFindProductBySkuRequestToJSON(requestParameters.kvintaFindProductBySkuRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetOneResponseProductFromJSON(jsonValue));
    }

    /**
     */
    async findProductBySku(requestParameters: FindProductBySkuRequest): Promise<KvintaGetOneResponseProduct> {
        const response = await this.findProductBySkuRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCompaniesManyRaw(requestParameters: GetCompaniesManyRequest): Promise<runtime.ApiResponse<KvintaGetListResponseCompany>> {
        if (requestParameters.kvintaGetManyFilterString === null || requestParameters.kvintaGetManyFilterString === undefined) {
            throw new runtime.RequiredError('kvintaGetManyFilterString','Required parameter requestParameters.kvintaGetManyFilterString was null or undefined when calling getCompaniesMany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/companies/get-many`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetManyFilterStringToJSON(requestParameters.kvintaGetManyFilterString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseCompanyFromJSON(jsonValue));
    }

    /**
     */
    async getCompaniesMany(requestParameters: GetCompaniesManyRequest): Promise<KvintaGetListResponseCompany> {
        const response = await this.getCompaniesManyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCompanyRaw(requestParameters: GetCompanyRequest): Promise<runtime.ApiResponse<KvintaGetOneResponseCompany>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/companies/get-one/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetOneResponseCompanyFromJSON(jsonValue));
    }

    /**
     */
    async getCompany(requestParameters: GetCompanyRequest): Promise<KvintaGetOneResponseCompany> {
        const response = await this.getCompanyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCompanyListRaw(requestParameters: GetCompanyListRequest): Promise<runtime.ApiResponse<KvintaGetListResponseCompany>> {
        if (requestParameters.kvintaGetListRequest === null || requestParameters.kvintaGetListRequest === undefined) {
            throw new runtime.RequiredError('kvintaGetListRequest','Required parameter requestParameters.kvintaGetListRequest was null or undefined when calling getCompanyList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/companies/get-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetListRequestToJSON(requestParameters.kvintaGetListRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseCompanyFromJSON(jsonValue));
    }

    /**
     */
    async getCompanyList(requestParameters: GetCompanyListRequest): Promise<KvintaGetListResponseCompany> {
        const response = await this.getCompanyListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCustomerRaw(requestParameters: GetCustomerRequest): Promise<runtime.ApiResponse<KvintaGetOneResponseCustomer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/customers/get-one/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetOneResponseCustomerFromJSON(jsonValue));
    }

    /**
     */
    async getCustomer(requestParameters: GetCustomerRequest): Promise<KvintaGetOneResponseCustomer> {
        const response = await this.getCustomerRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCustomerListRaw(requestParameters: GetCustomerListRequest): Promise<runtime.ApiResponse<KvintaGetListResponseCustomer>> {
        if (requestParameters.kvintaGetListRequest === null || requestParameters.kvintaGetListRequest === undefined) {
            throw new runtime.RequiredError('kvintaGetListRequest','Required parameter requestParameters.kvintaGetListRequest was null or undefined when calling getCustomerList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/customers/get-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetListRequestToJSON(requestParameters.kvintaGetListRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseCustomerFromJSON(jsonValue));
    }

    /**
     */
    async getCustomerList(requestParameters: GetCustomerListRequest): Promise<KvintaGetListResponseCustomer> {
        const response = await this.getCustomerListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCustomersManyRaw(requestParameters: GetCustomersManyRequest): Promise<runtime.ApiResponse<KvintaGetListResponseCustomer>> {
        if (requestParameters.kvintaGetManyFilterString === null || requestParameters.kvintaGetManyFilterString === undefined) {
            throw new runtime.RequiredError('kvintaGetManyFilterString','Required parameter requestParameters.kvintaGetManyFilterString was null or undefined when calling getCustomersMany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/customers/get-many`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetManyFilterStringToJSON(requestParameters.kvintaGetManyFilterString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseCustomerFromJSON(jsonValue));
    }

    /**
     */
    async getCustomersMany(requestParameters: GetCustomersManyRequest): Promise<KvintaGetListResponseCustomer> {
        const response = await this.getCustomersManyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getDefaultLocationRegulatorReportingSystemRaw(): Promise<runtime.ApiResponse<KvintaGetListResponseLocationRegulatorReportingSystem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/location-regulator-reporting-systems/by-gln/DEFAULT`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseLocationRegulatorReportingSystemFromJSON(jsonValue));
    }

    /**
     */
    async getDefaultLocationRegulatorReportingSystem(): Promise<KvintaGetListResponseLocationRegulatorReportingSystem> {
        const response = await this.getDefaultLocationRegulatorReportingSystemRaw();
        return await response.value();
    }

    /**
     */
    async getGtinInfoBatchByGtinRaw(requestParameters: GetGtinInfoBatchByGtinRequest): Promise<runtime.ApiResponse<KvintaGetListResponseGtinInfo>> {
        if (requestParameters.kvintaGetGtinInfoBatchRequest === null || requestParameters.kvintaGetGtinInfoBatchRequest === undefined) {
            throw new runtime.RequiredError('kvintaGetGtinInfoBatchRequest','Required parameter requestParameters.kvintaGetGtinInfoBatchRequest was null or undefined when calling getGtinInfoBatchByGtin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/trade-items/get-gtin-info/batch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetGtinInfoBatchRequestToJSON(requestParameters.kvintaGetGtinInfoBatchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseGtinInfoFromJSON(jsonValue));
    }

    /**
     */
    async getGtinInfoBatchByGtin(requestParameters: GetGtinInfoBatchByGtinRequest): Promise<KvintaGetListResponseGtinInfo> {
        const response = await this.getGtinInfoBatchByGtinRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getGtinInfoByGtinRaw(requestParameters: GetGtinInfoByGtinRequest): Promise<runtime.ApiResponse<KvintaGetOneResponseGtinInfo>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getGtinInfoByGtin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/trade-items/get-gtin-info/one`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetOneResponseGtinInfoFromJSON(jsonValue));
    }

    /**
     */
    async getGtinInfoByGtin(requestParameters: GetGtinInfoByGtinRequest): Promise<KvintaGetOneResponseGtinInfo> {
        const response = await this.getGtinInfoByGtinRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLocationRaw(requestParameters: GetLocationRequest): Promise<runtime.ApiResponse<KvintaGetOneResponseLocation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/locations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetOneResponseLocationFromJSON(jsonValue));
    }

    /**
     */
    async getLocation(requestParameters: GetLocationRequest): Promise<KvintaGetOneResponseLocation> {
        const response = await this.getLocationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLocationByGlnRaw(requestParameters: GetLocationByGlnRequest): Promise<runtime.ApiResponse<KvintaGetOneResponseLocation>> {
        if (requestParameters.gln13 === null || requestParameters.gln13 === undefined) {
            throw new runtime.RequiredError('gln13','Required parameter requestParameters.gln13 was null or undefined when calling getLocationByGln.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/locations/by-gln/{gln13}`.replace(`{${"gln13"}}`, encodeURIComponent(String(requestParameters.gln13))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetOneResponseLocationFromJSON(jsonValue));
    }

    /**
     */
    async getLocationByGln(requestParameters: GetLocationByGlnRequest): Promise<KvintaGetOneResponseLocation> {
        const response = await this.getLocationByGlnRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLocationBySglnRaw(requestParameters: GetLocationBySglnRequest): Promise<runtime.ApiResponse<KvintaGetOneResponseLocation>> {
        if (requestParameters.sgln === null || requestParameters.sgln === undefined) {
            throw new runtime.RequiredError('sgln','Required parameter requestParameters.sgln was null or undefined when calling getLocationBySgln.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/locations/by-sgln/{sgln}`.replace(`{${"sgln"}}`, encodeURIComponent(String(requestParameters.sgln))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetOneResponseLocationFromJSON(jsonValue));
    }

    /**
     */
    async getLocationBySgln(requestParameters: GetLocationBySglnRequest): Promise<KvintaGetOneResponseLocation> {
        const response = await this.getLocationBySglnRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLocationListRaw(requestParameters: GetLocationListRequest): Promise<runtime.ApiResponse<KvintaGetListResponseLocation>> {
        if (requestParameters.kvintaGetListRequest === null || requestParameters.kvintaGetListRequest === undefined) {
            throw new runtime.RequiredError('kvintaGetListRequest','Required parameter requestParameters.kvintaGetListRequest was null or undefined when calling getLocationList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/locations/get-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetListRequestToJSON(requestParameters.kvintaGetListRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseLocationFromJSON(jsonValue));
    }

    /**
     */
    async getLocationList(requestParameters: GetLocationListRequest): Promise<KvintaGetListResponseLocation> {
        const response = await this.getLocationListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLocationRegulatorReportingSystemRaw(requestParameters: GetLocationRegulatorReportingSystemRequest): Promise<runtime.ApiResponse<KvintaGetOneResponseLocationRegulatorReportingSystem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getLocationRegulatorReportingSystem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/location-regulator-reporting-systems/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetOneResponseLocationRegulatorReportingSystemFromJSON(jsonValue));
    }

    /**
     */
    async getLocationRegulatorReportingSystem(requestParameters: GetLocationRegulatorReportingSystemRequest): Promise<KvintaGetOneResponseLocationRegulatorReportingSystem> {
        const response = await this.getLocationRegulatorReportingSystemRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLocationRegulatorReportingSystemByGlnRaw(requestParameters: GetLocationRegulatorReportingSystemByGlnRequest): Promise<runtime.ApiResponse<KvintaGetListResponseLocationRegulatorReportingSystem>> {
        if (requestParameters.gln13 === null || requestParameters.gln13 === undefined) {
            throw new runtime.RequiredError('gln13','Required parameter requestParameters.gln13 was null or undefined when calling getLocationRegulatorReportingSystemByGln.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/location-regulator-reporting-systems/by-gln/{gln13}`.replace(`{${"gln13"}}`, encodeURIComponent(String(requestParameters.gln13))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseLocationRegulatorReportingSystemFromJSON(jsonValue));
    }

    /**
     */
    async getLocationRegulatorReportingSystemByGln(requestParameters: GetLocationRegulatorReportingSystemByGlnRequest): Promise<KvintaGetListResponseLocationRegulatorReportingSystem> {
        const response = await this.getLocationRegulatorReportingSystemByGlnRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLocationRegulatorReportingSystemsListRaw(requestParameters: GetLocationRegulatorReportingSystemsListRequest): Promise<runtime.ApiResponse<KvintaGetListResponseLocationRegulatorReportingSystem>> {
        if (requestParameters.kvintaGetListRequest === null || requestParameters.kvintaGetListRequest === undefined) {
            throw new runtime.RequiredError('kvintaGetListRequest','Required parameter requestParameters.kvintaGetListRequest was null or undefined when calling getLocationRegulatorReportingSystemsList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/location-regulator-reporting-systems/get-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetListRequestToJSON(requestParameters.kvintaGetListRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseLocationRegulatorReportingSystemFromJSON(jsonValue));
    }

    /**
     */
    async getLocationRegulatorReportingSystemsList(requestParameters: GetLocationRegulatorReportingSystemsListRequest): Promise<KvintaGetListResponseLocationRegulatorReportingSystem> {
        const response = await this.getLocationRegulatorReportingSystemsListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLocationRegulatorReportingSystemsManyRaw(requestParameters: GetLocationRegulatorReportingSystemsManyRequest): Promise<runtime.ApiResponse<KvintaGetListResponseLocationRegulatorReportingSystem>> {
        if (requestParameters.kvintaGetManyFilterString === null || requestParameters.kvintaGetManyFilterString === undefined) {
            throw new runtime.RequiredError('kvintaGetManyFilterString','Required parameter requestParameters.kvintaGetManyFilterString was null or undefined when calling getLocationRegulatorReportingSystemsMany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/location-regulator-reporting-systems/get-many`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetManyFilterStringToJSON(requestParameters.kvintaGetManyFilterString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseLocationRegulatorReportingSystemFromJSON(jsonValue));
    }

    /**
     */
    async getLocationRegulatorReportingSystemsMany(requestParameters: GetLocationRegulatorReportingSystemsManyRequest): Promise<KvintaGetListResponseLocationRegulatorReportingSystem> {
        const response = await this.getLocationRegulatorReportingSystemsManyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLocationsByGlnListRaw(requestParameters: GetLocationsByGlnListRequest): Promise<runtime.ApiResponse<KvintaGetListResponseLocation>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling getLocationsByGlnList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/locations/by-gln-list/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseLocationFromJSON(jsonValue));
    }

    /**
     */
    async getLocationsByGlnList(requestParameters: GetLocationsByGlnListRequest): Promise<KvintaGetListResponseLocation> {
        const response = await this.getLocationsByGlnListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLocationsManyRaw(requestParameters: GetLocationsManyRequest): Promise<runtime.ApiResponse<KvintaGetListResponseLocation>> {
        if (requestParameters.kvintaGetManyFilterString === null || requestParameters.kvintaGetManyFilterString === undefined) {
            throw new runtime.RequiredError('kvintaGetManyFilterString','Required parameter requestParameters.kvintaGetManyFilterString was null or undefined when calling getLocationsMany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/locations/get-many`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetManyFilterStringToJSON(requestParameters.kvintaGetManyFilterString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseLocationFromJSON(jsonValue));
    }

    /**
     */
    async getLocationsMany(requestParameters: GetLocationsManyRequest): Promise<KvintaGetListResponseLocation> {
        const response = await this.getLocationsManyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getProductRaw(requestParameters: GetProductRequest): Promise<runtime.ApiResponse<KvintaGetOneResponseProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/get-one/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetOneResponseProductFromJSON(jsonValue));
    }

    /**
     */
    async getProduct(requestParameters: GetProductRequest): Promise<KvintaGetOneResponseProduct> {
        const response = await this.getProductRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getProductListRaw(requestParameters: GetProductListRequest): Promise<runtime.ApiResponse<KvintaGetListResponseProduct>> {
        if (requestParameters.kvintaGetListRequest === null || requestParameters.kvintaGetListRequest === undefined) {
            throw new runtime.RequiredError('kvintaGetListRequest','Required parameter requestParameters.kvintaGetListRequest was null or undefined when calling getProductList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/products/get-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetListRequestToJSON(requestParameters.kvintaGetListRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseProductFromJSON(jsonValue));
    }

    /**
     */
    async getProductList(requestParameters: GetProductListRequest): Promise<KvintaGetListResponseProduct> {
        const response = await this.getProductListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getProductsByCompanyRaw(requestParameters: GetProductsByCompanyRequest): Promise<runtime.ApiResponse<KvintaGetListResponseProduct>> {
        if (requestParameters.kvintaGetProductsByCompanyRequest === null || requestParameters.kvintaGetProductsByCompanyRequest === undefined) {
            throw new runtime.RequiredError('kvintaGetProductsByCompanyRequest','Required parameter requestParameters.kvintaGetProductsByCompanyRequest was null or undefined when calling getProductsByCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/products/get-by-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetProductsByCompanyRequestToJSON(requestParameters.kvintaGetProductsByCompanyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseProductFromJSON(jsonValue));
    }

    /**
     */
    async getProductsByCompany(requestParameters: GetProductsByCompanyRequest): Promise<KvintaGetListResponseProduct> {
        const response = await this.getProductsByCompanyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getProductsManyRaw(requestParameters: GetProductsManyRequest): Promise<runtime.ApiResponse<KvintaGetListResponseProduct>> {
        if (requestParameters.kvintaGetManyFilterString === null || requestParameters.kvintaGetManyFilterString === undefined) {
            throw new runtime.RequiredError('kvintaGetManyFilterString','Required parameter requestParameters.kvintaGetManyFilterString was null or undefined when calling getProductsMany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/products/get-many`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetManyFilterStringToJSON(requestParameters.kvintaGetManyFilterString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseProductFromJSON(jsonValue));
    }

    /**
     */
    async getProductsMany(requestParameters: GetProductsManyRequest): Promise<KvintaGetListResponseProduct> {
        const response = await this.getProductsManyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getTradeItemRaw(requestParameters: GetTradeItemRequest): Promise<runtime.ApiResponse<KvintaGetOneResponseTradeItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTradeItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/trade-items/get-one/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetOneResponseTradeItemFromJSON(jsonValue));
    }

    /**
     */
    async getTradeItem(requestParameters: GetTradeItemRequest): Promise<KvintaGetOneResponseTradeItem> {
        const response = await this.getTradeItemRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getTradeItemListRaw(requestParameters: GetTradeItemListRequest): Promise<runtime.ApiResponse<KvintaGetListResponseTradeItem>> {
        if (requestParameters.kvintaGetListRequest === null || requestParameters.kvintaGetListRequest === undefined) {
            throw new runtime.RequiredError('kvintaGetListRequest','Required parameter requestParameters.kvintaGetListRequest was null or undefined when calling getTradeItemList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/trade-items/get-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetListRequestToJSON(requestParameters.kvintaGetListRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseTradeItemFromJSON(jsonValue));
    }

    /**
     */
    async getTradeItemList(requestParameters: GetTradeItemListRequest): Promise<KvintaGetListResponseTradeItem> {
        const response = await this.getTradeItemListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getTradeItemProductByGtinRaw(requestParameters: GetTradeItemProductByGtinRequest): Promise<runtime.ApiResponse<KvintaGetOneResponseTradeItemWithProduct>> {
        if (requestParameters.gtin === null || requestParameters.gtin === undefined) {
            throw new runtime.RequiredError('gtin','Required parameter requestParameters.gtin was null or undefined when calling getTradeItemProductByGtin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/trade-items/get-tradeitem-product-by-gtin/{gtin}`.replace(`{${"gtin"}}`, encodeURIComponent(String(requestParameters.gtin))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetOneResponseTradeItemWithProductFromJSON(jsonValue));
    }

    /**
     */
    async getTradeItemProductByGtin(requestParameters: GetTradeItemProductByGtinRequest): Promise<KvintaGetOneResponseTradeItemWithProduct> {
        const response = await this.getTradeItemProductByGtinRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getTradeItemsByProductRaw(requestParameters: GetTradeItemsByProductRequest): Promise<runtime.ApiResponse<KvintaGetListResponseTradeItem>> {
        if (requestParameters.kvintaGetTradeItemsByProductRequest === null || requestParameters.kvintaGetTradeItemsByProductRequest === undefined) {
            throw new runtime.RequiredError('kvintaGetTradeItemsByProductRequest','Required parameter requestParameters.kvintaGetTradeItemsByProductRequest was null or undefined when calling getTradeItemsByProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/trade-items/get-by-product`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetTradeItemsByProductRequestToJSON(requestParameters.kvintaGetTradeItemsByProductRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseTradeItemFromJSON(jsonValue));
    }

    /**
     */
    async getTradeItemsByProduct(requestParameters: GetTradeItemsByProductRequest): Promise<KvintaGetListResponseTradeItem> {
        const response = await this.getTradeItemsByProductRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getTradeItemsManyRaw(requestParameters: GetTradeItemsManyRequest): Promise<runtime.ApiResponse<KvintaGetListResponseTradeItem>> {
        if (requestParameters.kvintaGetManyFilterString === null || requestParameters.kvintaGetManyFilterString === undefined) {
            throw new runtime.RequiredError('kvintaGetManyFilterString','Required parameter requestParameters.kvintaGetManyFilterString was null or undefined when calling getTradeItemsMany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/trade-items/get-many`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetManyFilterStringToJSON(requestParameters.kvintaGetManyFilterString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseTradeItemFromJSON(jsonValue));
    }

    /**
     */
    async getTradeItemsMany(requestParameters: GetTradeItemsManyRequest): Promise<KvintaGetListResponseTradeItem> {
        const response = await this.getTradeItemsManyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listGtinDescriptionSkusRaw(requestParameters: ListGtinDescriptionSkusRequest): Promise<runtime.ApiResponse<KvintaGetListResponseGtinDescriptionSku>> {
        if (requestParameters.kvintaGetListRequest === null || requestParameters.kvintaGetListRequest === undefined) {
            throw new runtime.RequiredError('kvintaGetListRequest','Required parameter requestParameters.kvintaGetListRequest was null or undefined when calling listGtinDescriptionSkus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/trade-items/list-gtin-description-skus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetListRequestToJSON(requestParameters.kvintaGetListRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseGtinDescriptionSkuFromJSON(jsonValue));
    }

    /**
     */
    async listGtinDescriptionSkus(requestParameters: ListGtinDescriptionSkusRequest): Promise<KvintaGetListResponseGtinDescriptionSku> {
        const response = await this.listGtinDescriptionSkusRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async searchCompaniesRaw(requestParameters: SearchCompaniesRequest): Promise<runtime.ApiResponse<KvintaGetListResponseCompany>> {
        if (requestParameters.kvintaSearchRequest === null || requestParameters.kvintaSearchRequest === undefined) {
            throw new runtime.RequiredError('kvintaSearchRequest','Required parameter requestParameters.kvintaSearchRequest was null or undefined when calling searchCompanies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/companies/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaSearchRequestToJSON(requestParameters.kvintaSearchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseCompanyFromJSON(jsonValue));
    }

    /**
     */
    async searchCompanies(requestParameters: SearchCompaniesRequest): Promise<KvintaGetListResponseCompany> {
        const response = await this.searchCompaniesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async searchCustomersRaw(requestParameters: SearchCustomersRequest): Promise<runtime.ApiResponse<KvintaGetListResponseCustomer>> {
        if (requestParameters.kvintaSearchRequest === null || requestParameters.kvintaSearchRequest === undefined) {
            throw new runtime.RequiredError('kvintaSearchRequest','Required parameter requestParameters.kvintaSearchRequest was null or undefined when calling searchCustomers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/customers/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaSearchRequestToJSON(requestParameters.kvintaSearchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseCustomerFromJSON(jsonValue));
    }

    /**
     */
    async searchCustomers(requestParameters: SearchCustomersRequest): Promise<KvintaGetListResponseCustomer> {
        const response = await this.searchCustomersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async searchLocationRegulatorReportingSystemsRaw(requestParameters: SearchLocationRegulatorReportingSystemsRequest): Promise<runtime.ApiResponse<KvintaGetListResponseLocationRegulatorReportingSystem>> {
        if (requestParameters.kvintaSearchRequest === null || requestParameters.kvintaSearchRequest === undefined) {
            throw new runtime.RequiredError('kvintaSearchRequest','Required parameter requestParameters.kvintaSearchRequest was null or undefined when calling searchLocationRegulatorReportingSystems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/location-regulator-reporting-systems/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaSearchRequestToJSON(requestParameters.kvintaSearchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseLocationRegulatorReportingSystemFromJSON(jsonValue));
    }

    /**
     */
    async searchLocationRegulatorReportingSystems(requestParameters: SearchLocationRegulatorReportingSystemsRequest): Promise<KvintaGetListResponseLocationRegulatorReportingSystem> {
        const response = await this.searchLocationRegulatorReportingSystemsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async searchLocationsRaw(requestParameters: SearchLocationsRequest): Promise<runtime.ApiResponse<KvintaGetListResponseLocation>> {
        if (requestParameters.kvintaSearchRequest === null || requestParameters.kvintaSearchRequest === undefined) {
            throw new runtime.RequiredError('kvintaSearchRequest','Required parameter requestParameters.kvintaSearchRequest was null or undefined when calling searchLocations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/locations/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaSearchRequestToJSON(requestParameters.kvintaSearchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseLocationFromJSON(jsonValue));
    }

    /**
     */
    async searchLocations(requestParameters: SearchLocationsRequest): Promise<KvintaGetListResponseLocation> {
        const response = await this.searchLocationsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async searchProductsRaw(requestParameters: SearchProductsRequest): Promise<runtime.ApiResponse<KvintaGetListResponseProduct>> {
        if (requestParameters.kvintaSearchRequest === null || requestParameters.kvintaSearchRequest === undefined) {
            throw new runtime.RequiredError('kvintaSearchRequest','Required parameter requestParameters.kvintaSearchRequest was null or undefined when calling searchProducts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/products/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaSearchRequestToJSON(requestParameters.kvintaSearchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseProductFromJSON(jsonValue));
    }

    /**
     */
    async searchProducts(requestParameters: SearchProductsRequest): Promise<KvintaGetListResponseProduct> {
        const response = await this.searchProductsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async searchTradeItemsRaw(requestParameters: SearchTradeItemsRequest): Promise<runtime.ApiResponse<KvintaGetListResponseTradeItem>> {
        if (requestParameters.kvintaSearchRequest === null || requestParameters.kvintaSearchRequest === undefined) {
            throw new runtime.RequiredError('kvintaSearchRequest','Required parameter requestParameters.kvintaSearchRequest was null or undefined when calling searchTradeItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/trade-items/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaSearchRequestToJSON(requestParameters.kvintaSearchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetListResponseTradeItemFromJSON(jsonValue));
    }

    /**
     */
    async searchTradeItems(requestParameters: SearchTradeItemsRequest): Promise<KvintaGetListResponseTradeItem> {
        const response = await this.searchTradeItemsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCompanyRaw(requestParameters: UpdateCompanyRequest): Promise<runtime.ApiResponse<KvintaUpdateResponseCompany>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompany.');
        }

        if (requestParameters.kvintaCompany === null || requestParameters.kvintaCompany === undefined) {
            throw new runtime.RequiredError('kvintaCompany','Required parameter requestParameters.kvintaCompany was null or undefined when calling updateCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/companies/update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaCompanyToJSON(requestParameters.kvintaCompany),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaUpdateResponseCompanyFromJSON(jsonValue));
    }

    /**
     */
    async updateCompany(requestParameters: UpdateCompanyRequest): Promise<KvintaUpdateResponseCompany> {
        const response = await this.updateCompanyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCustomerRaw(requestParameters: UpdateCustomerRequest): Promise<runtime.ApiResponse<KvintaUpdateResponseCustomer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCustomer.');
        }

        if (requestParameters.kvintaCustomer === null || requestParameters.kvintaCustomer === undefined) {
            throw new runtime.RequiredError('kvintaCustomer','Required parameter requestParameters.kvintaCustomer was null or undefined when calling updateCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/customers/update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaCustomerToJSON(requestParameters.kvintaCustomer),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaUpdateResponseCustomerFromJSON(jsonValue));
    }

    /**
     */
    async updateCustomer(requestParameters: UpdateCustomerRequest): Promise<KvintaUpdateResponseCustomer> {
        const response = await this.updateCustomerRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateLocationRaw(requestParameters: UpdateLocationRequest): Promise<runtime.ApiResponse<KvintaUpdateResponseLocation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateLocation.');
        }

        if (requestParameters.kvintaLocation === null || requestParameters.kvintaLocation === undefined) {
            throw new runtime.RequiredError('kvintaLocation','Required parameter requestParameters.kvintaLocation was null or undefined when calling updateLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/locations/update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaLocationToJSON(requestParameters.kvintaLocation),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaUpdateResponseLocationFromJSON(jsonValue));
    }

    /**
     */
    async updateLocation(requestParameters: UpdateLocationRequest): Promise<KvintaUpdateResponseLocation> {
        const response = await this.updateLocationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateLocationRegulatorReportingSystemsRaw(requestParameters: UpdateLocationRegulatorReportingSystemsRequest): Promise<runtime.ApiResponse<KvintaUpdateResponseString>> {
        if (requestParameters.kvintaUpdateLocationReportingSystemsRequest === null || requestParameters.kvintaUpdateLocationReportingSystemsRequest === undefined) {
            throw new runtime.RequiredError('kvintaUpdateLocationReportingSystemsRequest','Required parameter requestParameters.kvintaUpdateLocationReportingSystemsRequest was null or undefined when calling updateLocationRegulatorReportingSystems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/location-regulator-reporting-systems/updateLocationRegulatorReportingSystems`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaUpdateLocationReportingSystemsRequestToJSON(requestParameters.kvintaUpdateLocationReportingSystemsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaUpdateResponseStringFromJSON(jsonValue));
    }

    /**
     */
    async updateLocationRegulatorReportingSystems(requestParameters: UpdateLocationRegulatorReportingSystemsRequest): Promise<KvintaUpdateResponseString> {
        const response = await this.updateLocationRegulatorReportingSystemsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateProductRaw(requestParameters: UpdateProductRequest): Promise<runtime.ApiResponse<KvintaUpdateResponseProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProduct.');
        }

        if (requestParameters.kvintaProduct === null || requestParameters.kvintaProduct === undefined) {
            throw new runtime.RequiredError('kvintaProduct','Required parameter requestParameters.kvintaProduct was null or undefined when calling updateProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/products/update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaProductToJSON(requestParameters.kvintaProduct),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaUpdateResponseProductFromJSON(jsonValue));
    }

    /**
     */
    async updateProduct(requestParameters: UpdateProductRequest): Promise<KvintaUpdateResponseProduct> {
        const response = await this.updateProductRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateTradeItemRaw(requestParameters: UpdateTradeItemRequest): Promise<runtime.ApiResponse<KvintaUpdateResponseTradeItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTradeItem.');
        }

        if (requestParameters.kvintaTradeItem === null || requestParameters.kvintaTradeItem === undefined) {
            throw new runtime.RequiredError('kvintaTradeItem','Required parameter requestParameters.kvintaTradeItem was null or undefined when calling updateTradeItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/trade-items/update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaTradeItemToJSON(requestParameters.kvintaTradeItem),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaUpdateResponseTradeItemFromJSON(jsonValue));
    }

    /**
     */
    async updateTradeItem(requestParameters: UpdateTradeItemRequest): Promise<KvintaUpdateResponseTradeItem> {
        const response = await this.updateTradeItemRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async uploadRaw(requestParameters: UploadRequest): Promise<runtime.ApiResponse<KvintaMasterDataUploadResponse>> {
        if (requestParameters.kvintaMasterDataUploadRequest === null || requestParameters.kvintaMasterDataUploadRequest === undefined) {
            throw new runtime.RequiredError('kvintaMasterDataUploadRequest','Required parameter requestParameters.kvintaMasterDataUploadRequest was null or undefined when calling upload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/master-data/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaMasterDataUploadRequestToJSON(requestParameters.kvintaMasterDataUploadRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaMasterDataUploadResponseFromJSON(jsonValue));
    }

    /**
     */
    async upload(requestParameters: UploadRequest): Promise<KvintaMasterDataUploadResponse> {
        const response = await this.uploadRaw(requestParameters);
        return await response.value();
    }

}
