/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaEpcVerificationResult
 */
export interface KvintaEpcVerificationResult {
    /**
     * 
     * @type {boolean}
     * @memberof KvintaEpcVerificationResult
     */
    valid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcVerificationResult
     */
    epc?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcVerificationResult
     */
    error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcVerificationResult
     */
    parent?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcVerificationResult
     */
    childrenCount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcVerificationResult
     */
    status?: string | null;
}

export function KvintaEpcVerificationResultFromJSON(json: any): KvintaEpcVerificationResult {
    return KvintaEpcVerificationResultFromJSONTyped(json, false);
}

export function KvintaEpcVerificationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcVerificationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valid': !exists(json, 'valid') ? undefined : json['valid'],
        'epc': !exists(json, 'epc') ? undefined : json['epc'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'childrenCount': !exists(json, 'childrenCount') ? undefined : json['childrenCount'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function KvintaEpcVerificationResultToJSON(value?: KvintaEpcVerificationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valid': value.valid,
        'epc': value.epc,
        'error': value.error,
        'parent': value.parent,
        'childrenCount': value.childrenCount,
        'status': value.status,
    };
}


