/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaCreateSerialNumberRequest,
    KvintaCreateSerialNumberRequestFromJSON,
    KvintaCreateSerialNumberRequestFromJSONTyped,
    KvintaCreateSerialNumberRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaInsertSerialNumbersRequest
 */
export interface KvintaInsertSerialNumbersRequest {
    /**
     * 
     * @type {Array<KvintaCreateSerialNumberRequest>}
     * @memberof KvintaInsertSerialNumbersRequest
     */
    serialNumberRequests: Array<KvintaCreateSerialNumberRequest>;
}

export function KvintaInsertSerialNumbersRequestFromJSON(json: any): KvintaInsertSerialNumbersRequest {
    return KvintaInsertSerialNumbersRequestFromJSONTyped(json, false);
}

export function KvintaInsertSerialNumbersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaInsertSerialNumbersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serialNumberRequests': ((json['serialNumberRequests'] as Array<any>).map(KvintaCreateSerialNumberRequestFromJSON)),
    };
}

export function KvintaInsertSerialNumbersRequestToJSON(value?: KvintaInsertSerialNumbersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serialNumberRequests': ((value.serialNumberRequests as Array<any>).map(KvintaCreateSerialNumberRequestToJSON)),
    };
}


