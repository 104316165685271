/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCreateNotificationGroupRecipientRequest
 */
export interface KvintaCreateNotificationGroupRecipientRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateNotificationGroupRecipientRequest
     */
    notificationGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateNotificationGroupRecipientRequest
     */
    recipientId?: string;
}

export function KvintaCreateNotificationGroupRecipientRequestFromJSON(json: any): KvintaCreateNotificationGroupRecipientRequest {
    return KvintaCreateNotificationGroupRecipientRequestFromJSONTyped(json, false);
}

export function KvintaCreateNotificationGroupRecipientRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateNotificationGroupRecipientRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notificationGroupId': !exists(json, 'notificationGroupId') ? undefined : json['notificationGroupId'],
        'recipientId': !exists(json, 'recipientId') ? undefined : json['recipientId'],
    };
}

export function KvintaCreateNotificationGroupRecipientRequestToJSON(value?: KvintaCreateNotificationGroupRecipientRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notificationGroupId': value.notificationGroupId,
        'recipientId': value.recipientId,
    };
}


