import { action, makeObservable, observable, runInAction } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';
import {
  DefaultApi as MDDocumentApi,
  KvintaCompany,
  KvintaProduct,
  KvintaSortBy,
} from 'kvinta/apis/kvinta-masterdata-service';
import { NotificationManager } from 'kvinta/modules/main';
import { KvintaOperationStatus } from 'kvinta/apis/kvinta-document-store/models';
import { IFilter } from 'kvinta/components';
import { SelectableStore } from 'kvinta/common';

export interface ProductFormDialogData {
  company: any;
  name: string;
  description: string;
  baseUom: string;
  idInSourceSystem: string;
  sku: string;
  tnved: string;
  shelfLife: number | undefined;
  sourceSystem: string;
  showError: boolean;
}

interface KvintaProductExt extends KvintaProduct {
  companyData?: KvintaCompany;
}

// export type CompaniesRow extends KvintaCompany implements ISelectableRow
interface IProductRow extends KvintaProduct, ISelectableRow {}

export class ProductsStore extends SelectableStore<IProductRow> {
  private _config: TAppOptionsConfig;
  private _mdApi: MDDocumentApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;

  page: number;
  totalCount: number;
  pageSize: number;
  companies: KvintaCompany[];
  currentSort: KvintaSortBy;

  createProductData?: ProductFormDialogData;
  productFormOpen: boolean;

  updateProductData?: ProductFormDialogData;

  currentProduct?: KvintaProductExt;

  exportActive: boolean;
  exportData: IProductRow[] | KvintaProduct[] | undefined;
  autofocusSearchInList: boolean;

  searchValue: string;

  constructor(config: TAppOptionsConfig, notificationManager: NotificationManager, mdApi: MDDocumentApi) {
    super();
    makeObservable(this, {
      fetchPage: action.bound,
      fetchData: action.bound,
      closeCreateProductForm: action,
      fetchProduct: action,
      updateSearch: action,

      isLoading: observable,
      page: observable,
      pageSize: observable,
      createProductData: observable,
      productFormOpen: observable,
      updateProductData: observable,
      currentProduct: observable,
      onChangeCreateProductField: action.bound,
      onChangeUpdateProductField: action.bound,
      submitCreateProductForm: action.bound,
      submitUpdateProduct: action.bound,
      searchValue: observable,

      unfocusSearchField: action,
      exportActive: observable,
    });

    this._config = config;
    this._mdApi = mdApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.autofocusSearchInList = false;

    this.currentSort = {
      order: 'desc',
      field: 'name',
    };
  }

  async fetchPage(page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
    });
    this.fetchData();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    runInAction(() => {
      this.isLoading = true;
      const field = getField(orderBy);
      this.currentSort = {
        field: field,
        order: orderDirection,
      };
    });
    this.fetchData();
  }

  unfocusSearchField() {
    this.autofocusSearchInList = false;
  }

  updateSearch(value: string) {
    this.searchValue = value;
    this.isLoading = true;
    this.autofocusSearchInList = true;
    this.fetchData();
  }

  async fetchData() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.listData = [];
    newLocal.totalCount = 0;
    let filters = {} as { [key: string]: string } | null;

    for (const filter of searchedColumns) {
      filters = {
        ...filters,
        [filter]: this.searchValue,
      };
    }

    // In the API the pages start with 1, where in UI with 0
    newLocal._mdApi
      .getProductList({
        kvintaGetListRequest: {
          pagination: { page: newLocal.page + 1, perPage: newLocal.pageSize },
          filter: filters,
        },
      })
      .then((result) => {
        runInAction(() => {
          newLocal.isLoading = false;
          if (result.operationStatus === KvintaOperationStatus.Error) {
            newLocal.listData = [];
          } else {
            const oldSelection = new Map();
            if (newLocal.listData) {
              newLocal.listData.forEach((row) => {
                oldSelection.set(row.id, row.isSelected);
              });
            }
            newLocal.listData = (result.data || []).map((comp) => {
              const selected = oldSelection.get(comp.id) || false;
              return { ...comp, isSelected: selected } as IProductRow;
            });
            newLocal.totalCount = result.total || 0;
          }
        });
      })
      .catch((err: Error) => {
        if (!err.message.startsWith('Cannot read')) {
          this._notificationManager.sendError(err.message);
        }
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  async openCreateProductForm() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal._mdApi
      .getCompanyList({
        kvintaGetListRequest: { pagination: { page: 0, perPage: 10000 } },
      })
      .then((result) => {
        runInAction(() => {
          if (result.operationStatus === KvintaOperationStatus.Error) {
            newLocal.companies = [];
          } else {
            newLocal.companies = result.data;
          }
          newLocal.createProductData = {
            company: newLocal.companies[0].id,
            name: '',
            description: '',
            baseUom: '',
            idInSourceSystem: '',
            sku: '',
            sourceSystem: '',
            tnved: '',
            shelfLife: undefined,
            showError: false,
          };
          newLocal.isLoading = false;
          newLocal.productFormOpen = true;
        });
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(err.toString());
      });
  }

  closeCreateProductForm() {
    runInAction(() => {
      this.productFormOpen = false;
    });
  }

  onChangeCreateProductField = (id: string, value: any) => {
    runInAction(() => {
      this.createProductData[id] = value;
    });
  };

  submitCreateProductForm = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    let hasError = false;
    for (const field of requiredFields) {
      if (errorRequired(field, this.createProductData[field])) {
        hasError = true;
        break;
      }
    }
    this.createProductData.showError = hasError;
    if (!hasError) {
      runInAction(() => {
        newLocal.productFormOpen = false;
        newLocal.isLoading = true;
      });
      newLocal._mdApi
        .createProduct({
          kvintaCreateProductRequest: {
            companyId: newLocal.createProductData.company,
            name: newLocal.createProductData.name,
            description: newLocal.createProductData.description,
            baseUom: newLocal.createProductData.baseUom,
            idInSourceSystem: newLocal.createProductData.idInSourceSystem,
            sku: newLocal.createProductData.sku,
            tnved: newLocal.createProductData.tnved,
            shelfLife: newLocal.createProductData.shelfLife,
            sourceSystem: newLocal.createProductData.sourceSystem,
          },
        })
        .then((res) => {
          newLocal.fetchData();
        })
        .catch((err: Error) => {
          this._notificationManager.sendError(err.toString());
        })
        .finally(() => {
          newLocal.isLoading = false;
        });
    }
  };

  async openUpdateProduct() {
    runInAction(() => {
      console.log('Update Product Data:', this.updateProductData);
    });
  }

  onChangeUpdateProductField = (id: string, value: any) => {
    runInAction(() => {
      this.updateProductData[id] = value;
    });
  };

  submitUpdateProduct = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.updateProductData.showError = false;
    let hasError = false;
    for (const field of requiredFields) {
      if (errorRequired(field, newLocal.updateProductData[field])) {
        hasError = true;
        break;
      }
    }
    if (!hasError) {
      newLocal._mdApi
        .updateProduct({
          id: newLocal.currentProduct.id,
          kvintaProduct: {
            baseUom: newLocal.updateProductData.baseUom,
            companyId: newLocal.updateProductData.company,
            description: newLocal.updateProductData.description,
            id: newLocal.currentProduct.id,
            idInSourceSystem: newLocal.updateProductData.idInSourceSystem,
            name: newLocal.updateProductData.name,
            sku: newLocal.updateProductData.sku,
            tnved: newLocal.updateProductData.tnved,
            shelfLife: newLocal.updateProductData.shelfLife,
            sourceSystem: newLocal.updateProductData.sourceSystem,
          },
        })
        .then((res) => {
          runInAction(() => {
            newLocal.isLoading = false;
          });
          newLocal._notificationManager.sendSuccess(`Successfully updated ${newLocal.currentProduct.name}`);
          newLocal.fetchData();
        })
        .catch((err: Error) => {
          newLocal._notificationManager.sendError(err.toString());
        });
    } else {
      newLocal._notificationManager.sendError(
        'Some fields are empty or invalid \n Name, Company and TNVED are required fields',
      );
      newLocal.updateProductData.showError = true;
    }
  };

  deleteProduct = (companyID: string) => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal._mdApi
      .deleteProduct({ id: companyID })
      .then((res) => {
        runInAction(() => {
          newLocal.isLoading = false;
        });
        newLocal.fetchData();
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(err.toString());
      });
  };

  /**
   * Export functionallity
   */
  updateExported() {
    this.exportData = undefined;
  }

  async exportSelected() {
    runInAction(() => {
      this.exportActive = false;
      this.exportData = undefined;
    });
    runInAction(() => {
      this.exportActive = true;
      this.exportData = this.listChecked;
    });
  }

  async exportAll() {
    runInAction(() => {
      this.exportActive = false;
      this.exportData = undefined;
    });
    const filters = {} as { [key: string]: string } | null;

    try {
      const productsListResult = await this._mdApi.getProductList({
        kvintaGetListRequest: {
          pagination: { page: this.page, perPage: this.pageSize },
          filter: filters,
        },
      });
      runInAction(() => {
        this.exportActive = true;
        this.exportData = productsListResult.data;
      });
    } catch (err) {
      this._notificationManager.sendError(JSON.stringify(err));
    }
  }
  /*
   * *********** Export functionallity
   **/

  async fetchProduct(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.currentProduct = undefined;
    const prodResult = await newLocal._mdApi.getProduct({ id });
    if (prodResult.operationStatus !== KvintaOperationStatus.Ok) {
      newLocal._notificationManager.sendError(prodResult.error);
    } else if (!prodResult.data) {
      newLocal._notificationManager.sendError('product not found');
    } else {
      const companyRes = await newLocal._mdApi.getCompany({ id: prodResult.data.companyId });
      if (companyRes.operationStatus !== KvintaOperationStatus.Ok) {
        newLocal._notificationManager.sendError(companyRes.error);
      } else {
        runInAction(() => {
          newLocal.currentProduct = { ...prodResult.data, companyData: companyRes.data };
          newLocal._mdApi
            .getCompanyList({
              kvintaGetListRequest: { pagination: { page: 0, perPage: 10000 } },
            })
            .then((result) => {
              runInAction(() => {
                if (result.operationStatus === KvintaOperationStatus.Error) {
                  newLocal.companies = [];
                } else {
                  newLocal.companies = result.data;
                }
                newLocal.updateProductData = {
                  company: newLocal.currentProduct.companyData.id,
                  name: newLocal.currentProduct.name,
                  description: newLocal.currentProduct.description,
                  baseUom: newLocal.currentProduct.baseUom,
                  idInSourceSystem: newLocal.currentProduct.idInSourceSystem,
                  sku: newLocal.currentProduct.sku,
                  tnved: newLocal.currentProduct.tnved,
                  shelfLife: newLocal.currentProduct.shelfLife,
                  sourceSystem: newLocal.currentProduct.sourceSystem,
                  showError: true,
                };
                newLocal.isLoading = false;
              });
            })
            .catch((err: Error) => {
              this._notificationManager.sendError(err.toString());
            });
        });
      }
    }
  }
}

export const STORE_ID = 'productsStore';

function getField(orderBy: number): string {
  switch (orderBy) {
    case 1:
      return 'name';
    case 2:
      return 'description';
    case 3:
      return 'updated';
    case 4:
      return 'sku';
    case 5:
      return 'baseUom';
    default:
      return 'name';
  }
}

const searchedColumns = ['name']; //, 'description', 'updated', 'sku', 'baseUom']; // TODO: API is not adopted to search multiple columns

const requiredFields = ['name', 'company', 'tnved'];

export function errorRequired(id: string, value: any): boolean {
  if (requiredFields.includes(id) && (value === undefined || value === '')) {
    return true;
  }
  return false;
}
