export const TradeItemsPath = '/trade-items';
export const TradeItemPath = '/trade-item/:id';

export function navigateTradeItemsPath(history: any) {
  history.push(TradeItemsPath);
}

export function navigateTradeItemPath(history: any, id: string) {
  history.push(TradeItemPath.replaceAll(':id', id));
}
