/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaListEmailRecipientRequest,
    KvintaListEmailRecipientRequestFromJSON,
    KvintaListEmailRecipientRequestFromJSONTyped,
    KvintaListEmailRecipientRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestListEmailRecipientRequest
 */
export interface KvintaOperationRequestListEmailRecipientRequest {
    /**
     * 
     * @type {KvintaListEmailRecipientRequest}
     * @memberof KvintaOperationRequestListEmailRecipientRequest
     */
    input?: KvintaListEmailRecipientRequest;
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestListEmailRecipientRequest
     */
    context?: KvintaExecutionContext;
}

export function KvintaOperationRequestListEmailRecipientRequestFromJSON(json: any): KvintaOperationRequestListEmailRecipientRequest {
    return KvintaOperationRequestListEmailRecipientRequestFromJSONTyped(json, false);
}

export function KvintaOperationRequestListEmailRecipientRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestListEmailRecipientRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'input': !exists(json, 'input') ? undefined : KvintaListEmailRecipientRequestFromJSON(json['input']),
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
    };
}

export function KvintaOperationRequestListEmailRecipientRequestToJSON(value?: KvintaOperationRequestListEmailRecipientRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'input': KvintaListEmailRecipientRequestToJSON(value.input),
        'context': KvintaExecutionContextToJSON(value.context),
    };
}


