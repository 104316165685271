/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaUsedSerialNumber,
    KvintaUsedSerialNumberFromJSON,
    KvintaUsedSerialNumberFromJSONTyped,
    KvintaUsedSerialNumberToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaUpdateUsedSerialNumbersRequest
 */
export interface KvintaUpdateUsedSerialNumbersRequest {
    /**
     * 
     * @type {Array<KvintaUsedSerialNumber>}
     * @memberof KvintaUpdateUsedSerialNumbersRequest
     */
    usedSerialNumbers: Array<KvintaUsedSerialNumber>;
}

export function KvintaUpdateUsedSerialNumbersRequestFromJSON(json: any): KvintaUpdateUsedSerialNumbersRequest {
    return KvintaUpdateUsedSerialNumbersRequestFromJSONTyped(json, false);
}

export function KvintaUpdateUsedSerialNumbersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaUpdateUsedSerialNumbersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'usedSerialNumbers': ((json['usedSerialNumbers'] as Array<any>).map(KvintaUsedSerialNumberFromJSON)),
    };
}

export function KvintaUpdateUsedSerialNumbersRequestToJSON(value?: KvintaUpdateUsedSerialNumbersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'usedSerialNumbers': ((value.usedSerialNumbers as Array<any>).map(KvintaUsedSerialNumberToJSON)),
    };
}


