export const DevicesPath = '/devices';
export const DeviceSummaryPath = '/device/:id';
export const DeviceAssignmentsPath = '/device/:id/assignments';
export const DeviceAssignmentSummaryPath = '/device/:id/assignment/:assignId';

export function navigateDevicesPath(history: any) {
  history.push(DevicesPath);
}

export function navigateDeviceSummaryPath(history: any, id: string) {
  history.push(DeviceSummaryPath.replaceAll(':id', id));
}
export function linkDevicePath(history: any, id: string): string {
  return DeviceSummaryPath.replaceAll(':id', id);
}

export function navigateDeviceAssignmentsPath(history: any, id: string) {
  history.push(DeviceAssignmentsPath.replaceAll(':id', id));
}

export function navigateDeviceAssignmentSummaryPath(history: any, id: string, assignId: string) {
  history.push(DeviceAssignmentSummaryPath.replaceAll(':id', id).replaceAll(':assignId', assignId));
}
