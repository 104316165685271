import { Button, Paper } from '@material-ui/core';
import { IEditState } from 'kvinta/common';
import { PageContentStore, tableIcons, TabBar, PAGE_CONTENT_STORE_ID, FilterBar } from 'kvinta/components';
import {
  navigateDeviceAssignmentSummaryPath,
  navigateDevicesPath,
  navigateDeviceSummaryPath,
} from 'kvinta/modules/paths';
import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router';
import { DevicesStore, DEVICES_STORE_ID } from './DevicesStore';
import { DialogCreateAssignment } from './DialogCreateAssignment';
import { tabsSummary } from './tabs';
import AddIcon from '@material-ui/icons/Add';

interface DeviceAssignmentsViewProps {
  devicesStore?: DevicesStore;
  pageContentStore?: PageContentStore;
  intl: any;
  history?: any;
  id: string;
}

class DeviceAssignmentsViewImpl extends Component<DeviceAssignmentsViewProps, IEditState> {
  tableRef: React.RefObject<unknown>;

  constructor(props: DeviceAssignmentsViewProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  async componentDidMount() {
    this.props.devicesStore.fetchDeviceAssignments(this.props.id);
  }

  navigateAssignmentSummary = (AssignId: string) => {
    navigateDeviceAssignmentSummaryPath(this.props.history, this.props.id, AssignId);
  };

  render() {
    const intl = this.props.intl;
    const { createAssignmentFromOpen, currentDeviceAssignments, isLoading, page, pageSize, totalCount } =
      this.props.devicesStore;
    const listData = currentDeviceAssignments;
    if (!listData) {
      return null;
    }
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                <TabBar
                  onChange={(newValue: any) => {
                    if (newValue === '0') {
                      navigateDevicesPath(this.props.history);
                    }
                    if (newValue === '1') {
                      navigateDeviceSummaryPath(this.props.history, this.props.id);
                    }
                  }}
                  tabId={'2'}
                  tabs={tabsSummary}
                />
              </Paper>
              <MaterialTable
                icons={tableIcons}
                tableRef={this.tableRef}
                columns={[
                  {
                    title: intl.formatMessage({ id: 'device-assignment.appname' }),
                    field: 'appName',
                    defaultSort: 'desc',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'device-assignment.appversion' }),
                    field: 'appVersion',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'device-assignment.deviceid' }),
                    field: 'deviceId',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'device-assignment.email' }),
                    field: 'email',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  // {
                  //   title: intl.formatMessage({ id: 'device-assignment.id' }),
                  //   field: 'id',
                  //   cellStyle: {
                  //     whiteSpace: 'nowrap',
                  //   },
                  // },
                ]}
                data={listData}
                page={page}
                totalCount={totalCount}
                onChangePage={(page: number) => {
                  this.props.devicesStore.fetchAssignmentsPage(page, this.props.id);
                }}
                onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                  this.props.devicesStore.changeDeviceAssignmentsOrder(orderBy, orderDirection, this.props.id);
                }}
                isLoading={isLoading}
                options={{
                  actionsColumnIndex: 0,
                  paging: true,
                  sorting: true,
                  draggable: false,
                  search: false,
                  pageSize: pageSize,
                  pageSizeOptions: [pageSize],
                  minBodyHeight: bodyHeight,
                  maxBodyHeight: bodyHeight,
                  showTitle: false,
                  thirdSortClick: false,
                  emptyRowsWhenPaging: false,
                }}
                localization={{
                  header: {
                    actions: '',
                  },
                }}
                onRowClick={(event, rowData) => {
                  if (!(event.target instanceof HTMLInputElement)) {
                    this.navigateAssignmentSummary(rowData.id);
                  }
                }}
                components={{
                  Toolbar: (props) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: '20px',
                        marginRight: '20px',
                        marginLeft: '11px',
                      }}
                    >
                      {/* This div puts the button to the right */}
                      <div style={{ marginLeft: '5px' }}></div>
                      <div style={{ marginLeft: '5px' }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<AddIcon color="secondary" />}
                          onClick={(e) => {
                            this.props.devicesStore.openCreateAssignmentForm();
                          }}
                        >
                          {intl.formatMessage({ id: 'button.create' })}
                        </Button>
                      </div>
                      {createAssignmentFromOpen && <DialogCreateAssignment />}
                    </div>
                  ),
                }}
              />
            </div>
          </div>
        )}
      />
    );
  }
}
export const DeviceAssignmentsView = injectIntl(
  inject(DEVICES_STORE_ID, PAGE_CONTENT_STORE_ID)(observer(DeviceAssignmentsViewImpl)),
);
