/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaSerialNumberOrder,
    KvintaSerialNumberOrderFromJSON,
    KvintaSerialNumberOrderFromJSONTyped,
    KvintaSerialNumberOrderToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestSerialNumberOrder
 */
export interface KvintaOperationRequestSerialNumberOrder {
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestSerialNumberOrder
     */
    context?: KvintaExecutionContext;
    /**
     * 
     * @type {KvintaSerialNumberOrder}
     * @memberof KvintaOperationRequestSerialNumberOrder
     */
    input?: KvintaSerialNumberOrder;
}

export function KvintaOperationRequestSerialNumberOrderFromJSON(json: any): KvintaOperationRequestSerialNumberOrder {
    return KvintaOperationRequestSerialNumberOrderFromJSONTyped(json, false);
}

export function KvintaOperationRequestSerialNumberOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestSerialNumberOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
        'input': !exists(json, 'input') ? undefined : KvintaSerialNumberOrderFromJSON(json['input']),
    };
}

export function KvintaOperationRequestSerialNumberOrderToJSON(value?: KvintaOperationRequestSerialNumberOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': KvintaExecutionContextToJSON(value.context),
        'input': KvintaSerialNumberOrderToJSON(value.input),
    };
}


