/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KvintaInelMessageBizStep } from 'kvinta/apis/kvinta-arla-extensions';
import { tzInts } from 'kvinta/common/timezones';
import { ShippingStore, SHIPPING_STORE_ID } from './ShippingStore';

interface DialogCreateATKProps {
  shippingStore?: ShippingStore;
  intl: any;
}

class DialogCreateATKImpl extends Component<DialogCreateATKProps> {
  render() {
    const isOpen = this.props.shippingStore.createATKDialogOpen;
    const taxNumber = this.props.shippingStore.taxNumber;
    const intl = this.props.intl;

    if (!isOpen) {
      return null;
    }
    return (
      <Dialog fullWidth={true} maxWidth="sm" open={isOpen} onClose={() => this.props.shippingStore.closeATKDialog()}>
        <DialogTitle>{intl.formatMessage({ id: 'shipping-list.header.createatk' })}</DialogTitle>
        <DialogContent>
          <Container>
            <Box m={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="taxNumber">
                  {intl.formatMessage({ id: 'create-atk-from.field.taxNumber' })}
                </InputLabel>
                <OutlinedInput
                  id="taxNumber"
                  label={intl.formatMessage({ id: 'create-atk-from.field.taxNumber' })}
                  value={taxNumber}
                  defaultValue={taxNumber}
                  readOnly={true}
                />
              </FormControl>
            </Box>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.shippingStore.closeATKDialog()} color="primary" variant="outlined">
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <Button
            onClick={() => this.props.shippingStore.submitATKDialog()}
            color="primary"
            variant="contained"
            disabled={false}
            autoFocus
          >
            {intl.formatMessage({ id: 'button.submit' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const DialogCreateATK = injectIntl(inject(SHIPPING_STORE_ID)(observer(DialogCreateATKImpl)));
