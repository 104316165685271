/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDateTimeRange,
    KvintaDateTimeRangeFromJSON,
    KvintaDateTimeRangeFromJSONTyped,
    KvintaDateTimeRangeToJSON,
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingFromJSONTyped,
    KvintaPagingToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaListVerificationEventsRequest
 */
export interface KvintaListVerificationEventsRequest {
    /**
     * 
     * @type {KvintaDateTimeRange}
     * @memberof KvintaListVerificationEventsRequest
     */
    dateTimeRange?: KvintaDateTimeRange;
    /**
     * 
     * @type {string}
     * @memberof KvintaListVerificationEventsRequest
     */
    locationGln?: string | null;
    /**
     * 
     * @type {KvintaPaging}
     * @memberof KvintaListVerificationEventsRequest
     */
    paging?: KvintaPaging;
}

export function KvintaListVerificationEventsRequestFromJSON(json: any): KvintaListVerificationEventsRequest {
    return KvintaListVerificationEventsRequestFromJSONTyped(json, false);
}

export function KvintaListVerificationEventsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaListVerificationEventsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateTimeRange': !exists(json, 'dateTimeRange') ? undefined : KvintaDateTimeRangeFromJSON(json['dateTimeRange']),
        'locationGln': !exists(json, 'locationGln') ? undefined : json['locationGln'],
        'paging': !exists(json, 'paging') ? undefined : KvintaPagingFromJSON(json['paging']),
    };
}

export function KvintaListVerificationEventsRequestToJSON(value?: KvintaListVerificationEventsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateTimeRange': KvintaDateTimeRangeToJSON(value.dateTimeRange),
        'locationGln': value.locationGln,
        'paging': KvintaPagingToJSON(value.paging),
    };
}


