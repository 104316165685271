/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaCompany,
    KvintaCompanyFromJSON,
    KvintaCompanyFromJSONTyped,
    KvintaCompanyToJSON,
    KvintaOperationStatus,
    KvintaOperationStatusFromJSON,
    KvintaOperationStatusFromJSONTyped,
    KvintaOperationStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetOneResponseCompany
 */
export interface KvintaGetOneResponseCompany {
    /**
     * 
     * @type {KvintaCompany}
     * @memberof KvintaGetOneResponseCompany
     */
    data?: KvintaCompany;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetOneResponseCompany
     */
    error?: string | null;
    /**
     * 
     * @type {KvintaOperationStatus}
     * @memberof KvintaGetOneResponseCompany
     */
    operationStatus: KvintaOperationStatus;
}

export function KvintaGetOneResponseCompanyFromJSON(json: any): KvintaGetOneResponseCompany {
    return KvintaGetOneResponseCompanyFromJSONTyped(json, false);
}

export function KvintaGetOneResponseCompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetOneResponseCompany {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : KvintaCompanyFromJSON(json['data']),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'operationStatus': KvintaOperationStatusFromJSON(json['operationStatus']),
    };
}

export function KvintaGetOneResponseCompanyToJSON(value?: KvintaGetOneResponseCompany | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': KvintaCompanyToJSON(value.data),
        'error': value.error,
        'operationStatus': KvintaOperationStatusToJSON(value.operationStatus),
    };
}


