/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Query Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaOperationRequestFindSgtinListRequest,
    KvintaOperationRequestFindSgtinListRequestFromJSON,
    KvintaOperationRequestFindSgtinListRequestToJSON,
    KvintaOperationRequestGetEpcEventListRequest,
    KvintaOperationRequestGetEpcEventListRequestFromJSON,
    KvintaOperationRequestGetEpcEventListRequestToJSON,
    KvintaOperationRequestGetLastStateRequest,
    KvintaOperationRequestGetLastStateRequestFromJSON,
    KvintaOperationRequestGetLastStateRequestToJSON,
    KvintaOperationRequestGetRelativesRequest,
    KvintaOperationRequestGetRelativesRequestFromJSON,
    KvintaOperationRequestGetRelativesRequestToJSON,
    KvintaOperationRequestGetRelevantChangesRequest,
    KvintaOperationRequestGetRelevantChangesRequestFromJSON,
    KvintaOperationRequestGetRelevantChangesRequestToJSON,
    KvintaOperationRequestString,
    KvintaOperationRequestStringFromJSON,
    KvintaOperationRequestStringToJSON,
    KvintaOperationResponseEpcEvent,
    KvintaOperationResponseEpcEventFromJSON,
    KvintaOperationResponseEpcEventToJSON,
    KvintaOperationResponseGetRelevantChangesLocationsResponse,
    KvintaOperationResponseGetRelevantChangesLocationsResponseFromJSON,
    KvintaOperationResponseGetRelevantChangesLocationsResponseToJSON,
    KvintaOperationResponseHierarchyEvents,
    KvintaOperationResponseHierarchyEventsFromJSON,
    KvintaOperationResponseHierarchyEventsToJSON,
    KvintaOperationResponseListEpcEventsResponse,
    KvintaOperationResponseListEpcEventsResponseFromJSON,
    KvintaOperationResponseListEpcEventsResponseToJSON,
    KvintaOperationResponseListLastState,
    KvintaOperationResponseListLastStateFromJSON,
    KvintaOperationResponseListLastStateToJSON,
    KvintaOperationResponseListLong,
    KvintaOperationResponseListLongFromJSON,
    KvintaOperationResponseListLongToJSON,
    KvintaOperationResponseListString,
    KvintaOperationResponseListStringFromJSON,
    KvintaOperationResponseListStringToJSON,
    KvintaOperationResponseLocation,
    KvintaOperationResponseLocationFromJSON,
    KvintaOperationResponseLocationToJSON,
    KvintaStreamedFile,
    KvintaStreamedFileFromJSON,
    KvintaStreamedFileToJSON,
} from '../models';

export interface DownloadBarcodesPdfRequest {
    kvintaOperationRequestFindSgtinListRequest: KvintaOperationRequestFindSgtinListRequest;
}

export interface ListEpcEventsRequest {
    kvintaOperationRequestGetEpcEventListRequest: KvintaOperationRequestGetEpcEventListRequest;
}

export interface ReadChildrenRequest {
    kvintaOperationRequestGetRelativesRequest: KvintaOperationRequestGetRelativesRequest;
}

export interface ReadEpcEventByIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadHierarchyEventsRequest {
    kvintaOperationRequestGetRelevantChangesRequest: KvintaOperationRequestGetRelevantChangesRequest;
}

export interface ReadLastStateForEpcRequest {
    kvintaOperationRequestGetLastStateRequest: KvintaOperationRequestGetLastStateRequest;
}

export interface ReadLocationAtTimestampRequest {
    kvintaOperationRequestGetRelevantChangesRequest: KvintaOperationRequestGetRelevantChangesRequest;
}

export interface ReadLocationChangingTimestampsRequest {
    kvintaOperationRequestGetRelevantChangesRequest: KvintaOperationRequestGetRelevantChangesRequest;
}

export interface ReadRelevantChangesLocationsRequest {
    kvintaOperationRequestGetRelevantChangesRequest: KvintaOperationRequestGetRelevantChangesRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async downloadBarcodesPdfRaw(requestParameters: DownloadBarcodesPdfRequest): Promise<runtime.ApiResponse<KvintaStreamedFile>> {
        if (requestParameters.kvintaOperationRequestFindSgtinListRequest === null || requestParameters.kvintaOperationRequestFindSgtinListRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestFindSgtinListRequest','Required parameter requestParameters.kvintaOperationRequestFindSgtinListRequest was null or undefined when calling downloadBarcodesPdf.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/downloadBarcodesPdf`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestFindSgtinListRequestToJSON(requestParameters.kvintaOperationRequestFindSgtinListRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaStreamedFileFromJSON(jsonValue));
    }

    /**
     */
    async downloadBarcodesPdf(requestParameters: DownloadBarcodesPdfRequest): Promise<KvintaStreamedFile> {
        const response = await this.downloadBarcodesPdfRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listEpcEventsRaw(requestParameters: ListEpcEventsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListEpcEventsResponse>> {
        if (requestParameters.kvintaOperationRequestGetEpcEventListRequest === null || requestParameters.kvintaOperationRequestGetEpcEventListRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestGetEpcEventListRequest','Required parameter requestParameters.kvintaOperationRequestGetEpcEventListRequest was null or undefined when calling listEpcEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listEpcEvents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestGetEpcEventListRequestToJSON(requestParameters.kvintaOperationRequestGetEpcEventListRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListEpcEventsResponseFromJSON(jsonValue));
    }

    /**
     */
    async listEpcEvents(requestParameters: ListEpcEventsRequest): Promise<KvintaOperationResponseListEpcEventsResponse> {
        const response = await this.listEpcEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readChildrenRaw(requestParameters: ReadChildrenRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListString>> {
        if (requestParameters.kvintaOperationRequestGetRelativesRequest === null || requestParameters.kvintaOperationRequestGetRelativesRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestGetRelativesRequest','Required parameter requestParameters.kvintaOperationRequestGetRelativesRequest was null or undefined when calling readChildren.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readChildren`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestGetRelativesRequestToJSON(requestParameters.kvintaOperationRequestGetRelativesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListStringFromJSON(jsonValue));
    }

    /**
     */
    async readChildren(requestParameters: ReadChildrenRequest): Promise<KvintaOperationResponseListString> {
        const response = await this.readChildrenRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readEpcEventByIdRaw(requestParameters: ReadEpcEventByIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcEvent>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readEpcEventById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readEpcEventById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcEventFromJSON(jsonValue));
    }

    /**
     */
    async readEpcEventById(requestParameters: ReadEpcEventByIdRequest): Promise<KvintaOperationResponseEpcEvent> {
        const response = await this.readEpcEventByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readHierarchyEventsRaw(requestParameters: ReadHierarchyEventsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseHierarchyEvents>> {
        if (requestParameters.kvintaOperationRequestGetRelevantChangesRequest === null || requestParameters.kvintaOperationRequestGetRelevantChangesRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestGetRelevantChangesRequest','Required parameter requestParameters.kvintaOperationRequestGetRelevantChangesRequest was null or undefined when calling readHierarchyEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readHierarchyEvents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestGetRelevantChangesRequestToJSON(requestParameters.kvintaOperationRequestGetRelevantChangesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseHierarchyEventsFromJSON(jsonValue));
    }

    /**
     */
    async readHierarchyEvents(requestParameters: ReadHierarchyEventsRequest): Promise<KvintaOperationResponseHierarchyEvents> {
        const response = await this.readHierarchyEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readLastStateForEpcRaw(requestParameters: ReadLastStateForEpcRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListLastState>> {
        if (requestParameters.kvintaOperationRequestGetLastStateRequest === null || requestParameters.kvintaOperationRequestGetLastStateRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestGetLastStateRequest','Required parameter requestParameters.kvintaOperationRequestGetLastStateRequest was null or undefined when calling readLastStateForEpc.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readLastStateForEpc`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestGetLastStateRequestToJSON(requestParameters.kvintaOperationRequestGetLastStateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListLastStateFromJSON(jsonValue));
    }

    /**
     */
    async readLastStateForEpc(requestParameters: ReadLastStateForEpcRequest): Promise<KvintaOperationResponseListLastState> {
        const response = await this.readLastStateForEpcRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readLocationAtTimestampRaw(requestParameters: ReadLocationAtTimestampRequest): Promise<runtime.ApiResponse<KvintaOperationResponseLocation>> {
        if (requestParameters.kvintaOperationRequestGetRelevantChangesRequest === null || requestParameters.kvintaOperationRequestGetRelevantChangesRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestGetRelevantChangesRequest','Required parameter requestParameters.kvintaOperationRequestGetRelevantChangesRequest was null or undefined when calling readLocationAtTimestamp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readLocationAtTimestamp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestGetRelevantChangesRequestToJSON(requestParameters.kvintaOperationRequestGetRelevantChangesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseLocationFromJSON(jsonValue));
    }

    /**
     */
    async readLocationAtTimestamp(requestParameters: ReadLocationAtTimestampRequest): Promise<KvintaOperationResponseLocation> {
        const response = await this.readLocationAtTimestampRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readLocationChangingTimestampsRaw(requestParameters: ReadLocationChangingTimestampsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListLong>> {
        if (requestParameters.kvintaOperationRequestGetRelevantChangesRequest === null || requestParameters.kvintaOperationRequestGetRelevantChangesRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestGetRelevantChangesRequest','Required parameter requestParameters.kvintaOperationRequestGetRelevantChangesRequest was null or undefined when calling readLocationChangingTimestamps.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readLocationChangingTimestamps`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestGetRelevantChangesRequestToJSON(requestParameters.kvintaOperationRequestGetRelevantChangesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListLongFromJSON(jsonValue));
    }

    /**
     */
    async readLocationChangingTimestamps(requestParameters: ReadLocationChangingTimestampsRequest): Promise<KvintaOperationResponseListLong> {
        const response = await this.readLocationChangingTimestampsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readRelevantChangesLocationsRaw(requestParameters: ReadRelevantChangesLocationsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetRelevantChangesLocationsResponse>> {
        if (requestParameters.kvintaOperationRequestGetRelevantChangesRequest === null || requestParameters.kvintaOperationRequestGetRelevantChangesRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestGetRelevantChangesRequest','Required parameter requestParameters.kvintaOperationRequestGetRelevantChangesRequest was null or undefined when calling readRelevantChangesLocations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readRelevantChangesLocations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestGetRelevantChangesRequestToJSON(requestParameters.kvintaOperationRequestGetRelevantChangesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetRelevantChangesLocationsResponseFromJSON(jsonValue));
    }

    /**
     */
    async readRelevantChangesLocations(requestParameters: ReadRelevantChangesLocationsRequest): Promise<KvintaOperationResponseGetRelevantChangesLocationsResponse> {
        const response = await this.readRelevantChangesLocationsRaw(requestParameters);
        return await response.value();
    }

}
