/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaResourceType,
    KvintaResourceTypeFromJSON,
    KvintaResourceTypeFromJSONTyped,
    KvintaResourceTypeToJSON,
    KvintaSoftwareSystem,
    KvintaSoftwareSystemFromJSON,
    KvintaSoftwareSystemFromJSONTyped,
    KvintaSoftwareSystemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaFindLastResourceStatusRecordsRequest
 */
export interface KvintaFindLastResourceStatusRecordsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaFindLastResourceStatusRecordsRequest
     */
    resourceIds: Array<string>;
    /**
     * 
     * @type {KvintaResourceType}
     * @memberof KvintaFindLastResourceStatusRecordsRequest
     */
    resourceType: KvintaResourceType;
    /**
     * 
     * @type {KvintaSoftwareSystem}
     * @memberof KvintaFindLastResourceStatusRecordsRequest
     */
    system: KvintaSoftwareSystem;
}

export function KvintaFindLastResourceStatusRecordsRequestFromJSON(json: any): KvintaFindLastResourceStatusRecordsRequest {
    return KvintaFindLastResourceStatusRecordsRequestFromJSONTyped(json, false);
}

export function KvintaFindLastResourceStatusRecordsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaFindLastResourceStatusRecordsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resourceIds': json['resourceIds'],
        'resourceType': KvintaResourceTypeFromJSON(json['resourceType']),
        'system': KvintaSoftwareSystemFromJSON(json['system']),
    };
}

export function KvintaFindLastResourceStatusRecordsRequestToJSON(value?: KvintaFindLastResourceStatusRecordsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resourceIds': value.resourceIds,
        'resourceType': KvintaResourceTypeToJSON(value.resourceType),
        'system': KvintaSoftwareSystemToJSON(value.system),
    };
}


