/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisBizStep,
    KvintaEpcisBizStepFromJSON,
    KvintaEpcisBizStepFromJSONTyped,
    KvintaEpcisBizStepToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaSequenceSummary
 */
export interface KvintaSequenceSummary {
    /**
     * 
     * @type {number}
     * @memberof KvintaSequenceSummary
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaSequenceSummary
     */
    status?: number;
    /**
     * 
     * @type {KvintaEpcisBizStep}
     * @memberof KvintaSequenceSummary
     */
    step?: KvintaEpcisBizStep;
}

export function KvintaSequenceSummaryFromJSON(json: any): KvintaSequenceSummary {
    return KvintaSequenceSummaryFromJSONTyped(json, false);
}

export function KvintaSequenceSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSequenceSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'step': !exists(json, 'step') ? undefined : KvintaEpcisBizStepFromJSON(json['step']),
    };
}

export function KvintaSequenceSummaryToJSON(value?: KvintaSequenceSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'status': value.status,
        'step': KvintaEpcisBizStepToJSON(value.step),
    };
}


