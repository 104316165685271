/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCreateProductTradeItemRequest
 */
export interface KvintaCreateProductTradeItemRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateProductTradeItemRequest
     */
    baseUom?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaCreateProductTradeItemRequest
     */
    baseUomDenominator?: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaCreateProductTradeItemRequest
     */
    baseUomNominator?: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateProductTradeItemRequest
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateProductTradeItemRequest
     */
    companyPrefix: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateProductTradeItemRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateProductTradeItemRequest
     */
    gtin: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateProductTradeItemRequest
     */
    idInSourceSystem?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateProductTradeItemRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaCreateProductTradeItemRequest
     */
    shelfLife?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateProductTradeItemRequest
     */
    sku?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateProductTradeItemRequest
     */
    sourceSystem?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateProductTradeItemRequest
     */
    tnved?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateProductTradeItemRequest
     */
    uom?: string | null;
}

export function KvintaCreateProductTradeItemRequestFromJSON(json: any): KvintaCreateProductTradeItemRequest {
    return KvintaCreateProductTradeItemRequestFromJSONTyped(json, false);
}

export function KvintaCreateProductTradeItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateProductTradeItemRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'baseUom': !exists(json, 'baseUom') ? undefined : json['baseUom'],
        'baseUomDenominator': !exists(json, 'baseUomDenominator') ? undefined : json['baseUomDenominator'],
        'baseUomNominator': !exists(json, 'baseUomNominator') ? undefined : json['baseUomNominator'],
        'companyId': json['companyId'],
        'companyPrefix': json['companyPrefix'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'gtin': json['gtin'],
        'idInSourceSystem': !exists(json, 'idInSourceSystem') ? undefined : json['idInSourceSystem'],
        'name': json['name'],
        'shelfLife': !exists(json, 'shelfLife') ? undefined : json['shelfLife'],
        'sku': !exists(json, 'sku') ? undefined : json['sku'],
        'sourceSystem': !exists(json, 'sourceSystem') ? undefined : json['sourceSystem'],
        'tnved': !exists(json, 'tnved') ? undefined : json['tnved'],
        'uom': !exists(json, 'uom') ? undefined : json['uom'],
    };
}

export function KvintaCreateProductTradeItemRequestToJSON(value?: KvintaCreateProductTradeItemRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'baseUom': value.baseUom,
        'baseUomDenominator': value.baseUomDenominator,
        'baseUomNominator': value.baseUomNominator,
        'companyId': value.companyId,
        'companyPrefix': value.companyPrefix,
        'description': value.description,
        'gtin': value.gtin,
        'idInSourceSystem': value.idInSourceSystem,
        'name': value.name,
        'shelfLife': value.shelfLife,
        'sku': value.sku,
        'sourceSystem': value.sourceSystem,
        'tnved': value.tnved,
        'uom': value.uom,
    };
}


