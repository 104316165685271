/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAggregatedCustomsDocumentStatus,
    KvintaAggregatedCustomsDocumentStatusFromJSON,
    KvintaAggregatedCustomsDocumentStatusFromJSONTyped,
    KvintaAggregatedCustomsDocumentStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaAggregatedCustomsDocumentRecord
 */
export interface KvintaAggregatedCustomsDocumentRecord {
    /**
     * 
     * @type {number}
     * @memberof KvintaAggregatedCustomsDocumentRecord
     */
    created?: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaAggregatedCustomsDocumentRecord
     */
    epcisEventId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaAggregatedCustomsDocumentRecord
     */
    id: string;
    /**
     * 
     * @type {Array<KvintaAggregatedCustomsDocumentStatus>}
     * @memberof KvintaAggregatedCustomsDocumentRecord
     */
    statuses?: Array<KvintaAggregatedCustomsDocumentStatus> | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaAggregatedCustomsDocumentRecord
     */
    taxNumber: string;
}

export function KvintaAggregatedCustomsDocumentRecordFromJSON(json: any): KvintaAggregatedCustomsDocumentRecord {
    return KvintaAggregatedCustomsDocumentRecordFromJSONTyped(json, false);
}

export function KvintaAggregatedCustomsDocumentRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaAggregatedCustomsDocumentRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': !exists(json, 'created') ? undefined : json['created'],
        'epcisEventId': json['epcisEventId'],
        'id': json['id'],
        'statuses': !exists(json, 'statuses') ? undefined : (json['statuses'] === null ? null : (json['statuses'] as Array<any>).map(KvintaAggregatedCustomsDocumentStatusFromJSON)),
        'taxNumber': json['taxNumber'],
    };
}

export function KvintaAggregatedCustomsDocumentRecordToJSON(value?: KvintaAggregatedCustomsDocumentRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created,
        'epcisEventId': value.epcisEventId,
        'id': value.id,
        'statuses': value.statuses === undefined ? undefined : (value.statuses === null ? null : (value.statuses as Array<any>).map(KvintaAggregatedCustomsDocumentStatusToJSON)),
        'taxNumber': value.taxNumber,
    };
}


