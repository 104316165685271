/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaIssuerSystemSerialNumber
 */
export interface KvintaIssuerSystemSerialNumber {
    /**
     * 
     * @type {number}
     * @memberof KvintaIssuerSystemSerialNumber
     */
    expiredDate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaIssuerSystemSerialNumber
     */
    gtin: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaIssuerSystemSerialNumber
     */
    issuerSystemBlockId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaIssuerSystemSerialNumber
     */
    issuerSystemId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaIssuerSystemSerialNumber
     */
    issuerSystemOrderId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaIssuerSystemSerialNumber
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaIssuerSystemSerialNumber
     */
    raw: string;
}

export function KvintaIssuerSystemSerialNumberFromJSON(json: any): KvintaIssuerSystemSerialNumber {
    return KvintaIssuerSystemSerialNumberFromJSONTyped(json, false);
}

export function KvintaIssuerSystemSerialNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaIssuerSystemSerialNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expiredDate': !exists(json, 'expiredDate') ? undefined : json['expiredDate'],
        'gtin': json['gtin'],
        'issuerSystemBlockId': !exists(json, 'issuerSystemBlockId') ? undefined : json['issuerSystemBlockId'],
        'issuerSystemId': json['issuerSystemId'],
        'issuerSystemOrderId': json['issuerSystemOrderId'],
        'orderId': json['orderId'],
        'raw': json['raw'],
    };
}

export function KvintaIssuerSystemSerialNumberToJSON(value?: KvintaIssuerSystemSerialNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expiredDate': value.expiredDate,
        'gtin': value.gtin,
        'issuerSystemBlockId': value.issuerSystemBlockId,
        'issuerSystemId': value.issuerSystemId,
        'issuerSystemOrderId': value.issuerSystemOrderId,
        'orderId': value.orderId,
        'raw': value.raw,
    };
}


