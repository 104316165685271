import React from 'react';
import Root from './Root';
import { IntlProvider } from 'react-intl';
import localeRu from 'kvinta/locales/ru-ru.json';
import localeEn from 'kvinta/locales/en-en.json';
import { inject, observer } from 'mobx-react';
import { STORE_ID, UserStore } from 'kvinta/modules/main/UserStore';

const mergedTranslations = {
  en: {
    ...localeEn,
  },
  ru: {
    ...localeRu,
  },
};

interface AppProps {
  userStore?: UserStore;
}

class app extends React.Component<AppProps> {
  render() {
    const userInfo = this.props.userStore.userInfo;
    if (!userInfo) {
      return null;
    }
    const lang = userInfo.lang;
    return (
      <IntlProvider locale={lang} messages={mergedTranslations[lang]}>
        <Root />
      </IntlProvider>
    );
  }
}

export const App = inject(STORE_ID)(observer(app));
