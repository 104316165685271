/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaCreateEmailRecipientRequest,
    KvintaCreateEmailRecipientRequestFromJSON,
    KvintaCreateEmailRecipientRequestFromJSONTyped,
    KvintaCreateEmailRecipientRequestToJSON,
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestCreateEmailRecipientRequest
 */
export interface KvintaOperationRequestCreateEmailRecipientRequest {
    /**
     * 
     * @type {KvintaCreateEmailRecipientRequest}
     * @memberof KvintaOperationRequestCreateEmailRecipientRequest
     */
    input?: KvintaCreateEmailRecipientRequest;
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestCreateEmailRecipientRequest
     */
    context?: KvintaExecutionContext;
}

export function KvintaOperationRequestCreateEmailRecipientRequestFromJSON(json: any): KvintaOperationRequestCreateEmailRecipientRequest {
    return KvintaOperationRequestCreateEmailRecipientRequestFromJSONTyped(json, false);
}

export function KvintaOperationRequestCreateEmailRecipientRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestCreateEmailRecipientRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'input': !exists(json, 'input') ? undefined : KvintaCreateEmailRecipientRequestFromJSON(json['input']),
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
    };
}

export function KvintaOperationRequestCreateEmailRecipientRequestToJSON(value?: KvintaOperationRequestCreateEmailRecipientRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'input': KvintaCreateEmailRecipientRequestToJSON(value.input),
        'context': KvintaExecutionContextToJSON(value.context),
    };
}


