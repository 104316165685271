import { Box, FormControl, Grid, InputLabel, OutlinedInput } from '@material-ui/core';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KvintaSerialNumberOrderItem } from 'kvinta/apis/kvinta-document-store';

export interface SerialNumberOrderItemComponentProps {
  serialNumberOrderItem?: KvintaSerialNumberOrderItem;
  intl: any;
}
class SerialNumberOrderItemCompViewImpl extends Component<SerialNumberOrderItemComponentProps> {
  render() {
    const comp = this.props.serialNumberOrderItem;
    if (!comp) {
      return null;
    }
    const intl = this.props.intl;
    const formData = comp;

    return (
      <Grid container>
        <Grid item xs={6}>
          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              {/*Background color used to fix an overlap bug between title and border */}
              <InputLabel htmlFor="gtin" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'serial-number-order-item-form.field.gtin' })}
              </InputLabel>
              <OutlinedInput readOnly={true} id="gtin" defaultValue={formData.gtin} label="gtin label" />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="itemType" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'serial-number-order-item-form.field.itemtype' })}
              </InputLabel>
              <OutlinedInput readOnly={true} id="itemType" defaultValue={formData.itemType} label="itemType label" />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="quantity" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'serial-number-order-item-form.field.quantity' })}
              </InputLabel>
              <OutlinedInput readOnly={true} id="quantity" defaultValue={formData.quantity} label="quantity label" />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export const SerialNumberOrderItemCompView = injectIntl(SerialNumberOrderItemCompViewImpl);
