import { makeObservable, action, computed, observable, autorun } from 'mobx';
import { IMenuItem, IMenuItemClickable } from './Menus';

export type NavFunction = () => void;
export class KSidebarStore {
  isOpen = true;

  // Used in Main header
  mainTitle: string | undefined;
  hasNavigation = false;
  navigateFunc: NavFunction | undefined;

  menuItems: IMenuItem[] = [];
  // Used in Sidebar title
  currentTitle = '';

  constructor(menuItems: IMenuItem[]) {
    makeObservable(this, {
      isOpen: observable,
      currentTitle: observable,
      mainTitle: observable,
      hasNavigation: observable,

      toggleOpen: action,
      updateTitle: action.bound,
      updateMainTitle: action,
      clearMainTitle: action,
    });
    this.menuItems = menuItems;
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  updateTitle(title: string) {
    this.currentTitle = title;
  }

  updateMainTitle(title: string, navFunction: NavFunction) {
    this.mainTitle = title;
    this.hasNavigation = navFunction !== undefined;
    this.navigateFunc = navFunction;
  }
  clearMainTitle() {
    this.mainTitle = undefined;
    this.hasNavigation = false;
    this.navigateFunc = undefined;
  }
}

export const SIDEBAR_STORE_ID = 'sidebarStore';
