/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaResourceType {
    EpcisXmlMessage = 'EPCIS_XML_MESSAGE',
    EpcisJsonMessage = 'EPCIS_JSON_MESSAGE',
    EpcisDocument = 'EPCIS_DOCUMENT',
    EpcisEvent = 'EPCIS_EVENT',
    Epc = 'EPC',
    ProductionOrder = 'PRODUCTION_ORDER',
    SerialNumberOrder = 'SERIAL_NUMBER_ORDER',
    DeliveryNote = 'DELIVERY_NOTE',
    UtilisationReport = 'UTILISATION_REPORT',
    DropoutReport = 'DROPOUT_REPORT',
    Execution = 'EXECUTION',
    ExecutionStatus = 'EXECUTION_STATUS',
    ExecutionStatusRecord = 'EXECUTION_STATUS_RECORD',
    UtilisationReceipt = 'UTILISATION_RECEIPT',
    DropoutReceipt = 'DROPOUT_RECEIPT',
    AggregationDocument = 'AGGREGATION_DOCUMENT',
    DisaggregationDocument = 'DISAGGREGATION_DOCUMENT',
    AggregatedCustomsDocument = 'AGGREGATED_CUSTOMS_DOCUMENT',
    GismtAggregatedCustomsDocument = 'GISMT_AGGREGATED_CUSTOMS_DOCUMENT',
    CirculationReport = 'CIRCULATION_REPORT',
    GismtCirculationReport = 'GISMT_CIRCULATION_REPORT',
    CirculationAtkReport = 'CIRCULATION_ATK_REPORT',
    GismtCirculationAtkReport = 'GISMT_CIRCULATION_ATK_REPORT',
    Text = 'TEXT',
    OmsUtilisationReportRequest = 'OMS_UTILISATION_REPORT_REQUEST',
    HierarchyEventRequest = 'HIERARCHY_EVENT_REQUEST',
    UploadInelCommissioningMessageRequest = 'UPLOAD_INEL_COMMISSIONING_MESSAGE_REQUEST',
    InelCsvFile = 'INEL_CSV_FILE',
    InelCommissioningCsvFile = 'INEL_COMMISSIONING_CSV_FILE',
    InelShippingCsvFile = 'INEL_SHIPPING_CSV_FILE',
    InelMessage = 'INEL_MESSAGE',
    OmsCodes = 'OMS_CODES',
    SerialNumbersList = 'SERIAL_NUMBERS_LIST'
}

export function KvintaResourceTypeFromJSON(json: any): KvintaResourceType {
    return KvintaResourceTypeFromJSONTyped(json, false);
}

export function KvintaResourceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaResourceType {
    return json as KvintaResourceType;
}

export function KvintaResourceTypeToJSON(value?: KvintaResourceType | null): any {
    return value as any;
}

