/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDeliveryItem,
    KvintaDeliveryItemFromJSON,
    KvintaDeliveryItemFromJSONTyped,
    KvintaDeliveryItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetListResultDeliveryItem
 */
export interface KvintaGetListResultDeliveryItem {
    /**
     * 
     * @type {Array<KvintaDeliveryItem>}
     * @memberof KvintaGetListResultDeliveryItem
     */
    list?: Array<KvintaDeliveryItem> | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaGetListResultDeliveryItem
     */
    total?: number | null;
}

export function KvintaGetListResultDeliveryItemFromJSON(json: any): KvintaGetListResultDeliveryItem {
    return KvintaGetListResultDeliveryItemFromJSONTyped(json, false);
}

export function KvintaGetListResultDeliveryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetListResultDeliveryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': !exists(json, 'list') ? undefined : (json['list'] === null ? null : (json['list'] as Array<any>).map(KvintaDeliveryItemFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function KvintaGetListResultDeliveryItemToJSON(value?: KvintaGetListResultDeliveryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': value.list === undefined ? undefined : (value.list === null ? null : (value.list as Array<any>).map(KvintaDeliveryItemToJSON)),
        'total': value.total,
    };
}


