import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';
import {
  DefaultApi as MDDocumentApi,
  KvintaCompany,
  KvintaLocation,
  KvintaTradeItem,
  KvintaSortBy,
  KvintaProduct,
} from 'kvinta/apis/kvinta-masterdata-service';
import { NotificationManager } from 'kvinta/modules/main';
import { KvintaOperationStatus } from 'kvinta/apis/kvinta-document-store/models';
import { IFilter } from 'kvinta/components';
import { SelectableStore } from 'kvinta/common';

export interface TradeItemFormDialogData {
  baseUomDenominator: number;
  baseUomNominator: number;
  companyPrefix: string;
  gtin: string;
  productId: string;
  uom: string;
  showError: boolean;
}

// export type CompaniesRow extends KvintaCompany implements ISelectableRow
interface ITradeItemRow extends KvintaTradeItem, ISelectableRow {}

export class TradeItemsStore extends SelectableStore<ITradeItemRow> {
  private _config: TAppOptionsConfig;
  private _mdApi: MDDocumentApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;

  page: number;
  totalCount: number;
  pageSize: number;
  companies: KvintaCompany[];
  currentSort: KvintaSortBy;

  createTradeItemData?: TradeItemFormDialogData;
  updateTradeItemData?: TradeItemFormDialogData;
  tradeItemFormOpen: boolean;

  productId?: string;
  currentProduct?: KvintaProduct;
  currentTradeItem?: KvintaTradeItem;

  exportActive: boolean;
  exportData: ITradeItemRow[] | KvintaTradeItem[] | undefined;
  autofocusSearchInList: boolean;
  searchValue: string;

  constructor(config: TAppOptionsConfig, notificationManager: NotificationManager, mdApi: MDDocumentApi) {
    super();
    makeObservable(this, {
      fetchPage: action.bound,
      fetchData: action.bound,
      fetchTradeItem: action.bound,
      openUpdateTradeItem: action,
      openCreateTradeItemForm: action,
      closeCreateTradeItemForm: action,
      updateSearch: action,

      isLoading: observable,
      page: observable,
      pageSize: observable,
      createTradeItemData: observable,
      updateTradeItemData: observable,
      tradeItemFormOpen: observable,
      currentTradeItem: observable,
      productId: observable,
      currentProduct: observable,
      onChangeCreateTradeItemField: action.bound,
      onChangeUpdateTradeItemField: action.bound,
      submitCreateTradeItemForm: action.bound,
      submitUpdateTradeItem: action.bound,
      searchValue: observable,

      unfocusSearchField: action,
      exportActive: observable,
    });

    this._config = config;
    this._mdApi = mdApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.searchValue = '';
    this.autofocusSearchInList = false;

    this.currentSort = {
      order: 'desc',
      field: 'gtin',
    };
  }

  async fetchPage(page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
    });
    this.fetchData();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    runInAction(() => {
      this.isLoading = true;
      const field = getField(orderBy);
      this.currentSort = {
        field: field,
        order: orderDirection,
      };
    });
    this.fetchData();
  }

  unsetProductId() {
    this.productId = undefined;
  }

  unfocusSearchField() {
    this.autofocusSearchInList = false;
  }

  updateSearch(value: string) {
    this.searchValue = value;
    this.isLoading = true;
    this.autofocusSearchInList = true;
    this.fetchData();
  }

  // This does not trigger reloading
  resetFilter = () => {
    this.searchValue = '';
  };

  async fetchData() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.listData = [];
    newLocal.totalCount = 0;
    let filters = {} as { [key: string]: string } | null;

    for (const filter of searchedColumns) {
      filters = {
        ...filters,
        [filter]: this.searchValue,
      };
    }
    if (newLocal.productId) {
      filters = {
        ...filters,
        productId: newLocal.productId,
      };
    }
    // Pages in the api start from 1
    newLocal._mdApi
      .getTradeItemList({
        kvintaGetListRequest: {
          pagination: { page: newLocal.page + 1, perPage: newLocal.pageSize },
          filter: filters,
        },
      })
      .then((result) => {
        runInAction(() => {
          newLocal.isLoading = false;
          if (result.operationStatus === KvintaOperationStatus.Error) {
            newLocal.listData = [];
          } else {
            const oldSelection = new Map();
            if (newLocal.listData) {
              newLocal.listData.forEach((row) => {
                oldSelection.set(row.id, row.isSelected);
              });
            }
            newLocal.listData = (result.data || []).map((comp) => {
              const selected = oldSelection.get(comp.id) || false;
              return { ...comp, isSelected: selected } as ITradeItemRow;
            });

            newLocal.totalCount = result.total || 0;
          }
        });
      })
      .catch((err: Error) => {
        if (!err.message.startsWith('Cannot read')) {
          this._notificationManager.sendError(err.message);
        }
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  async fetchProductTradeItemData(id: string) {
    runInAction(() => {
      this.productId = id;
      this.fetchData();
    });
  }

  openCreateTradeItemForm() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;

    newLocal.createTradeItemData = {
      baseUomDenominator: undefined,
      baseUomNominator: undefined,
      companyPrefix: '',
      gtin: '',
      productId: newLocal.productId,
      uom: '',
      showError: false,
    };
    newLocal.isLoading = false;
    newLocal.tradeItemFormOpen = true;
  }

  closeCreateTradeItemForm() {
    runInAction(() => {
      this.tradeItemFormOpen = false;
    });
  }

  openUpdateTradeItem() {
    runInAction(() => {
      console.log('Update Product Data:', this.updateTradeItemData);
    });
  }

  onChangeCreateTradeItemField = (id: string, value: any) => {
    runInAction(() => {
      this.createTradeItemData[id] = value;
    });
  };

  onChangeUpdateTradeItemField = (id: string, value: any) => {
    runInAction(() => {
      this.updateTradeItemData[id] = value;
    });
  };

  submitCreateTradeItemForm = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    let hasError = false;
    for (const field of requiredFields) {
      if (errorRequired(field, this.createTradeItemData[field])) {
        hasError = true;
        break;
      }
    }
    this.createTradeItemData.showError = hasError;
    if (!hasError) {
      runInAction(() => {
        newLocal.tradeItemFormOpen = false;
        newLocal.isLoading = true;
      });
      newLocal._mdApi
        .createTradeItem({
          kvintaCreateTradeItemRequest: {
            baseUomDenominator: newLocal.createTradeItemData.baseUomDenominator,
            baseUomNominator: newLocal.createTradeItemData.baseUomNominator,
            companyPrefix: newLocal.createTradeItemData.companyPrefix,
            gtin: newLocal.createTradeItemData.gtin,
            productId: newLocal.createTradeItemData.productId,
            uom: newLocal.createTradeItemData.uom,
          },
        })
        .then((res) => {
          runInAction(() => {
            newLocal.isLoading = false;
          });
          newLocal.fetchData();
        })
        .catch((err: Error) => {
          this._notificationManager.sendError(err.toString());
        });
    }
  };

  submitUpdateTradeItem = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.updateTradeItemData.showError = false;
    let hasError = false;
    for (const field of requiredFields) {
      if (errorRequired(field, newLocal.updateTradeItemData[field])) {
        hasError = true;
        break;
      }
    }
    if (!hasError) {
      newLocal._mdApi
        .updateTradeItem({
          id: newLocal.currentTradeItem.id,
          kvintaTradeItem: {
            id: newLocal.currentTradeItem.id,
            baseUomDenominator: newLocal.updateTradeItemData.baseUomDenominator,
            baseUomNominator: newLocal.updateTradeItemData.baseUomNominator,
            companyPrefix: newLocal.updateTradeItemData.companyPrefix,
            gtin: newLocal.updateTradeItemData.gtin,
            productId: newLocal.currentTradeItem.productId,
            uom: newLocal.updateTradeItemData.uom,
          },
        })
        .then((res) => {
          runInAction(() => {
            newLocal.isLoading = false;
          });
          this._notificationManager.sendSuccess(`Successfully updated Trade Item ${this.currentTradeItem.gtin}`);
          newLocal.fetchTradeItem(newLocal.currentTradeItem.id);
        })
        .catch((err: Error) => {
          newLocal._notificationManager.sendError(err.toString());
        });
    } else {
      newLocal._notificationManager.sendError(
        'Some fields are empty or invalid \n Product ID, GTIN, UOM and Company Prefix are required fields',
      );
      newLocal.updateTradeItemData.showError = true;
    }
  };

  deleteTradeItem = (companyID: string) => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal._mdApi
      .deleteTradeItem({ id: companyID })
      .then((res) => {
        runInAction(() => {
          newLocal.isLoading = false;
        });
        newLocal.fetchData();
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(err.toString());
      });
  };

  /**
   * Export functionallity
   */
  updateExported() {
    runInAction(() => {
      this.exportData = undefined;
    });
  }

  async exportSelected() {
    runInAction(() => {
      this.exportActive = false;
      this.exportData = undefined;
    });
    runInAction(() => {
      this.exportActive = true;
      this.exportData = this.listChecked;
    });
  }

  async exportAll() {
    runInAction(() => {
      this.exportActive = false;
      this.exportData = undefined;
    });
    let filters = {} as { [key: string]: string } | null;

    if (this.productId) {
      filters = {
        ...filters,
        productId: this.productId,
      };
    }
    try {
      const tradeItemsResult = await this._mdApi.getTradeItemList({
        kvintaGetListRequest: {
          pagination: { page: this.page, perPage: this.pageSize },
          filter: filters,
        },
      });
      runInAction(() => {
        this.exportActive = true;
        this.exportData = tradeItemsResult.data;
      });
    } catch (err) {
      this._notificationManager.sendError(JSON.stringify(err));
    }
  }
  /*
   * *********** Export functionallity
   **/

  async fetchTradeItem(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.currentProduct = undefined;
    newLocal.isLoading = true;
    newLocal._mdApi
      .getTradeItem({ id })
      .then((result) => {
        if (result.operationStatus !== KvintaOperationStatus.Ok) {
          newLocal._notificationManager.sendError(result.error);
        } else if (!result.data) {
          newLocal._notificationManager.sendError('trade item not found');
        } else {
          newLocal._mdApi.getProduct({ id: result.data.productId }).then((resultProduct) => {
            runInAction(() => {
              newLocal.currentTradeItem = result.data;
              if (resultProduct.operationStatus !== KvintaOperationStatus.Ok) {
                newLocal._notificationManager.sendError(result.error);
              } else {
                newLocal.currentProduct = resultProduct.data;
                newLocal.updateTradeItemData = {
                  baseUomDenominator: newLocal.currentTradeItem.baseUomDenominator,
                  baseUomNominator: newLocal.currentTradeItem.baseUomNominator,
                  companyPrefix: newLocal.currentTradeItem.companyPrefix,
                  gtin: newLocal.currentTradeItem.gtin,
                  productId: newLocal.currentTradeItem.productId,
                  uom: newLocal.currentTradeItem.uom,
                  showError: true,
                };
                newLocal.isLoading = false;
              }
            });
          });
        }
      })
      .catch((err: Error) => {
        newLocal._notificationManager.sendError(err.toString());
      });
  }
}

export const STORE_ID = 'tradeItemsStore';

function getField(orderBy: number): string {
  switch (orderBy) {
    case 1:
      return 'gtin';
    case 2:
      return 'uom';
    case 3:
      return 'updated';
    default:
      return 'gtin';
  }
}

const searchedColumns = ['gtin']; //, 'uom', 'updated']; // TODO: API is not adopted to search multiple columns

const requiredFields = ['companyPrefix', 'gtin', 'uom', 'baseUomNominator', 'baseUomDenominator'];

export function errorRequired(id: string, value: any): boolean {
  if (requiredFields.includes(id) && (value === undefined || value === '')) {
    return true;
  }
  return false;
}
