/* eslint-disable @typescript-eslint/ban-types */
import { Button, Paper } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { TradeItemsStore, STORE_ID } from 'kvinta/modules/master-data/trade-items/TradeItemsStore';
import { TradeItemCompView } from 'kvinta/modules/master-data/trade-items/views/TradeItemCompView';
import { navigateProductPath, navigateProductsPath, navigateProductTradeItemsPath } from '../paths';
import { tabsTradeItemView } from './tabs';
import { UpdateTradeItemDialog } from './UpdateTradeItemDialog';
import EditIcon from '@material-ui/icons/Edit';
import { reaction } from 'mobx';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { IEditState } from 'kvinta/common';

interface ProductTradeItemSummaryViewProps {
  intl: any;
  tradeItemsStore?: TradeItemsStore;
  tradeId: string;
  id: string;
  history?: any;
  sidebarStore?: KSidebarStore;
}

class ProductTradeItemSummaryViewImpl extends Component<ProductTradeItemSummaryViewProps, IEditState> {
  disposer: any;

  constructor(props: ProductTradeItemSummaryViewProps) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.tradeItemsStore.fetchTradeItem(this.props.tradeId);
    this.disposer = reaction(
      () => this.props.tradeItemsStore.currentProduct,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            navigateProductsPath(this.props.history);
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  handleEditOpen = () => {
    this.setState({
      isEditing: true,
    });
    this.props.tradeItemsStore.openUpdateTradeItem();
  };

  handleEditSubmit = () => {
    this.props.tradeItemsStore.submitUpdateTradeItem();
    if (!this.props.tradeItemsStore.updateTradeItemData.showError) {
      this.setState({
        isEditing: false,
      });
    }
  };

  onEditClose = () => {
    this.setState({
      isEditing: false,
    });
    const tradeId = this.props.tradeId;
    this.props.tradeItemsStore.fetchTradeItem(tradeId);
  };

  render() {
    if (!this.props.tradeItemsStore) {
      return null;
    }
    const currentTradeItem = this.props.tradeItemsStore.currentTradeItem;
    if (!currentTradeItem) {
      return null;
    }
    const prodData = this.props.tradeItemsStore.currentProduct;
    const intl = this.props.intl;
    const tradeItemData = this.props.tradeItemsStore.updateTradeItemData;

    let buttonTitle;
    let buttonIcon;

    if (this.state.isEditing) {
      buttonTitle = 'button.confirm';
      buttonIcon = <DoneIcon color="secondary" />;
    } else {
      buttonTitle = 'button.edit';
      buttonIcon = <EditIcon color="secondary" />;
    }
    return (
      <div>
        <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          <TabBar
            onChange={(newValue: any) => {
              if (newValue === '0') {
                navigateProductsPath(this.props.history);
              }
              if (newValue === '1') {
                navigateProductPath(this.props.history, this.props.id);
              }
              if (newValue === '2') {
                navigateProductTradeItemsPath(this.props.history, this.props.id);
              }
            }}
            tabId={'3'}
            tabs={tabsTradeItemView}
          />
        </Paper>
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          <div style={{ marginRight: '15px', marginTop: '15px', float: 'right' }}>
            {this.state.isEditing ? (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon color="primary" />}
                style={{ marginRight: '10px' }}
                onClick={(e) => {
                  this.onEditClose();
                }}
              >
                {intl.formatMessage({ id: 'button.cancel' })}
              </Button>
            ) : null}
            <Button
              variant="contained"
              color="secondary"
              startIcon={buttonIcon}
              onClick={(e) => {
                if (this.state.isEditing) {
                  this.props.tradeItemsStore.fetchTradeItem(this.props.tradeId);

                  this.handleEditSubmit();
                } else {
                  this.handleEditOpen();
                }
              }}
            >
              {intl.formatMessage({ id: buttonTitle })}
            </Button>
          </div>
          <TradeItemCompView
            tradeItem={currentTradeItem}
            productData={prodData}
            history={history}
            isEditing={this.state.isEditing}
            tradeItemData={tradeItemData}
          />
        </Paper>
      </div>
    );
  }
}

export const ProductTradeItemSummaryView = injectIntl(
  inject(STORE_ID, SIDEBAR_STORE_ID)(observer(ProductTradeItemSummaryViewImpl)),
);
