import { action, makeObservable, observable, runInAction } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';
import {
  DefaultApi as MDDocumentApi,
  KvintaCompany,
  KvintaLocation,
  KvintaSortBy,
} from 'kvinta/apis/kvinta-masterdata-service';
import { NotificationManager } from 'kvinta/modules/main';
import { KvintaOperationStatus } from 'kvinta/apis/kvinta-document-store/models';
import { SelectableStore } from 'kvinta/common';

export interface CompanyFormDialogData {
  name: string;
  latitude: string;
  longitude: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  country: any;
  description: string;
  gln13: string;
  postalCode: string;
  showError: boolean;
}

// export type CompaniesRow extends KvintaCompany implements ISelectableRow
interface ICompanyRow extends KvintaCompany, ISelectableRow {}

export class CompaniesStore extends SelectableStore<ICompanyRow> {
  private _config: TAppOptionsConfig;
  private _mdApi: MDDocumentApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;

  page: number;
  totalCount: number;
  pageSize: number;
  locations: KvintaLocation[];
  currentSort: KvintaSortBy;

  createCompanyData?: CompanyFormDialogData;
  companyFormOpen: boolean;

  updateCompanyData?: CompanyFormDialogData;

  currentCompany?: KvintaCompany;

  exportActive: boolean;
  exportData: ICompanyRow[] | KvintaCompany[] | undefined;
  searchValue: string;
  autofocusSearchInList: boolean;

  constructor(config: TAppOptionsConfig, notificationManager: NotificationManager, mdApi: MDDocumentApi) {
    super();
    makeObservable(this, {
      fetchPage: action.bound,
      fetchData: action.bound,
      fetchCompany: action.bound,
      updateSearch: action,

      updateExported: action,
      exportAll: action.bound,
      exportSelected: action.bound,

      isLoading: observable,
      page: observable,
      pageSize: observable,
      companyFormOpen: observable,
      createCompanyData: observable,
      searchValue: observable,

      currentCompany: observable,
      updateCompanyData: observable,
      onChangeCreateCompanyField: action.bound,
      onChangeUpdateCompanyField: action.bound,
      submitCreateCompanyForm: action.bound,
      submitUpdateCompany: action.bound,

      exportActive: observable,
      unfocusSearchField: action,
    });

    this._config = config;
    this._mdApi = mdApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.searchValue = '';
    this.autofocusSearchInList = false;

    this.currentSort = {
      order: 'desc',
      field: 'name',
    };
  }

  async fetchPage(page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
    });
    this.fetchData();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    runInAction(() => {
      this.isLoading = true;
      const field = getField(orderBy);
      this.currentSort = {
        field: field,
        order: orderDirection,
      };
    });
    this.fetchData();
  }

  unfocusSearchField() {
    this.autofocusSearchInList = false;
  }

  updateSearch(value: string) {
    this.searchValue = value;
    this.isLoading = true;
    this.autofocusSearchInList = true;
    this.fetchData();
  }

  async fetchData() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.listData = [];
    newLocal.totalCount = 0;
    let filters = {} as { [key: string]: string } | null;

    for (const filter of searchedColumns) {
      filters = {
        ...filters,
        [filter]: this.searchValue,
      };
    }
    newLocal._mdApi
      .getCompanyList({
        kvintaGetListRequest: {
          pagination: { page: newLocal.page, perPage: newLocal.pageSize },
          filter: filters,
        },
      })
      .then((result) => {
        runInAction(() => {
          newLocal.isLoading = false;
          if (result.operationStatus === KvintaOperationStatus.Error) {
            newLocal.listData = [];
          } else {
            const oldSelection = new Map();
            if (newLocal.listData) {
              newLocal.listData.forEach((row) => {
                oldSelection.set(row.id, row.isSelected);
              });
              newLocal.listData = (result.data || []).map((comp) => {
                const selected = oldSelection.get(comp.id) || false;
                return { ...comp, isSelected: selected } as ICompanyRow;
              });
            }

            newLocal.totalCount = result.total || 0;
          }
        });
      })
      .catch((err: Error) => {
        if (!err.message.startsWith('Cannot read')) {
          this._notificationManager.sendError(err.message);
        }
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  async openCreateCompanyForm() {
    runInAction(() => {
      this.isLoading = true;
      this.createCompanyData = {
        name: '',
        latitude: '',
        longitude: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        country: 'DE',
        description: '',
        gln13: '',
        postalCode: '',
        showError: false,
      };
      this.isLoading = false;
      this.companyFormOpen = true;
    });
  }
  closeCreateCompanyForm() {
    runInAction(() => {
      this.companyFormOpen = false;
    });
  }
  onChangeCreateCompanyField = (id: string, value: any) => {
    runInAction(() => {
      this.createCompanyData[id] = value;
    });
  };

  submitCreateCompanyForm() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    let hasError = false;
    for (const field of requiredFields) {
      if (errorRequired(field, this.createCompanyData[field])) {
        hasError = true;
        break;
      }
    }
    this.createCompanyData.showError = hasError;
    if (!hasError) {
      runInAction(() => {
        newLocal.companyFormOpen = false;
        newLocal.isLoading = true;
      });

      newLocal._mdApi
        .createCompany({
          kvintaCreateCompanyRequest: {
            name: newLocal.createCompanyData.name,
            latitude: parseFloat(newLocal.createCompanyData.latitude),
            longitude: parseFloat(newLocal.createCompanyData.longitude),
            address1: newLocal.createCompanyData.address1,
            address2: newLocal.createCompanyData.address2,
            address3: newLocal.createCompanyData.address3,
            city: newLocal.createCompanyData.city,
            country: newLocal.createCompanyData.country,
            description: newLocal.createCompanyData.description,
            gln13: newLocal.createCompanyData.gln13,
            postalCode: newLocal.createCompanyData.postalCode,
          },
        })
        .then((res) => {
          runInAction(() => {
            newLocal.isLoading = false;
            newLocal.companyFormOpen = false;
            newLocal.isLoading = true;
          });
          newLocal.fetchData();
        })
        .catch((err: Error) => {
          this._notificationManager.sendError(err.toString());
          runInAction(() => {
            newLocal.companyFormOpen = false;
            newLocal.isLoading = true;
          });
        });
    }
  }

  async openUpdateCompany() {
    runInAction(() => {
      console.log('Update Company Data:', this.updateCompanyData);
    });
  }
  onChangeUpdateCompanyField = (id: string, value: any) => {
    runInAction(() => {
      this.updateCompanyData[id] = value;
    });
  };

  submitUpdateCompany = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.updateCompanyData.showError = false;
    let hasError = false;
    for (const field of requiredFields) {
      if (errorRequired(field, this.updateCompanyData[field])) {
        hasError = true;
        break;
      }
    }
    if (!hasError) {
      newLocal._mdApi
        .updateCompany({
          id: newLocal.currentCompany.id,
          kvintaCompany: {
            id: newLocal.currentCompany.id,
            name: newLocal.updateCompanyData.name,
            latitude: parseFloat(newLocal.updateCompanyData.latitude),
            longitude: parseFloat(newLocal.updateCompanyData.longitude),
            address1: newLocal.updateCompanyData.address1,
            address2: newLocal.updateCompanyData.address2,
            address3: newLocal.updateCompanyData.address3,
            city: newLocal.updateCompanyData.city,
            country: newLocal.updateCompanyData.country,
            description: newLocal.updateCompanyData.description,
            gln13: newLocal.updateCompanyData.gln13,
            postalCode: newLocal.updateCompanyData.postalCode,
          },
        })
        .then((res) => {
          runInAction(() => {
            newLocal.isLoading = false;
          });
          this._notificationManager.sendSuccess(`Successfully updated ${this.currentCompany.name}`);
          newLocal.fetchData();
        })
        .catch((err: Error) => {
          newLocal._notificationManager.sendError(err.toString());
        });
    } else {
      newLocal._notificationManager.sendError(
        'Some fields are empty or invalid \n Name, Latitude, Longitude, Address 1, City, Country, GLN13 and PostalCode are required fields',
      );
      newLocal.updateCompanyData.showError = true;
    }
  };

  deleteCompany = (companyID: string) => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal._mdApi
      .deleteCompany({ id: companyID })
      .then((res) => {
        runInAction(() => {
          newLocal.isLoading = false;
        });
        newLocal.fetchData();
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(err.toString());
      });
  };

  /**
   * Export functionallity
   */
  updateExported() {
    this.exportData = undefined;
  }

  async exportSelected() {
    runInAction(() => {
      this.exportActive = false;
      this.exportData = undefined;
    });
    runInAction(() => {
      this.exportActive = true;
      this.exportData = this.listChecked;
    });
  }

  async exportAll() {
    runInAction(() => {
      this.exportActive = false;
      this.exportData = undefined;
    });
    try {
      const companyListResult = await this._mdApi.getCompanyList({
        kvintaGetListRequest: {
          pagination: { page: 0, perPage: 10000 }, // Export max 10000
        },
      });
      runInAction(() => {
        this.exportActive = true;
        this.exportData = companyListResult.data;
      });
    } catch (err) {
      this._notificationManager.sendError(JSON.stringify(err));
    }
  }
  /*
   * *********** Export functionallity
   **/

  async fetchCompany(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.currentCompany = undefined;
    newLocal._mdApi.getCompany({ id }).then((result) => {
      runInAction(() => {
        if (result.operationStatus !== KvintaOperationStatus.Ok) {
          newLocal._notificationManager.sendError(result.error);
        } else if (!result.data) {
          newLocal._notificationManager.sendError('company not found');
        } else {
          newLocal.currentCompany = result.data;
          runInAction(() => {
            this.isLoading = true;
            this.updateCompanyData = {
              name: this.currentCompany.name,
              latitude: this.currentCompany.latitude ? this.currentCompany.latitude.toString() : undefined,
              longitude: this.currentCompany.longitude ? this.currentCompany.longitude.toString() : undefined,
              address1: this.currentCompany.address1,
              address2: this.currentCompany.address2,
              address3: this.currentCompany.address3,
              city: this.currentCompany.city,
              country: this.currentCompany.country,
              description: this.currentCompany.description,
              gln13: this.currentCompany.gln13,
              postalCode: this.currentCompany.postalCode,
              showError: false,
            };
            this.isLoading = false;
          });
        }
      });
    });
  }
}

export const STORE_ID = 'companiesStore';

function getField(orderBy: number): string {
  switch (orderBy) {
    case 1:
      return 'name';
    case 2:
      return 'description';
    case 3:
      return 'gln13';
    case 4:
      return 'id';
    default:
      return 'name';
  }
}

const searchedColumns = ['name']; //, 'description', 'gln13', 'id']; // TODO: API is not adopted to search multiple columns
const requiredFields = ['name', 'latitude', 'longitude', 'address1', 'city', 'country', 'gln13', 'postalCode'];

export function errorRequired(id: string, value: any): boolean {
  if (requiredFields.includes(id) && (value === undefined || value === '')) {
    return true;
  }
  return false;
}
