export const ShippingListPath = '/shipping-list';
export const ShippingPath = '/shipping/:id';
export const ShippingProductListPath = '/shipping/:id/products';
export const ShippingResultsPath = '/shipping/:id/results';
export const ShippingProductSummaryPath = '/shipping/:id/products/:productId';

export function navigateShippingListPath(history: any) {
  history.push(ShippingListPath);
}

export function navigateShippingPath(history: any, execId: string) {
  history.push(ShippingPath.replaceAll(':id', execId));
}

export function linkShippingPath(execId: string) {
  return ShippingPath.replaceAll(':id', execId);
}

export function navigateShippingProductsPath(history: any, execId: string) {
  history.push(ShippingProductListPath.replaceAll(':id', execId));
}

export function navigateShippingResultsPath(history: any, execId: string) {
  history.push(ShippingResultsPath.replaceAll(':id', execId));
}

export function navigateShippingProductSummaryPath(history: any, shippingId: string, productId: string) {
  history.push(ShippingProductSummaryPath.replaceAll(':id', shippingId).replaceAll(':productId', productId));
}
