/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestListString
 */
export interface KvintaOperationRequestListString {
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestListString
     */
    context?: KvintaExecutionContext;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaOperationRequestListString
     */
    input?: Array<string>;
}

export function KvintaOperationRequestListStringFromJSON(json: any): KvintaOperationRequestListString {
    return KvintaOperationRequestListStringFromJSONTyped(json, false);
}

export function KvintaOperationRequestListStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestListString {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
        'input': !exists(json, 'input') ? undefined : json['input'],
    };
}

export function KvintaOperationRequestListStringToJSON(value?: KvintaOperationRequestListString | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': KvintaExecutionContextToJSON(value.context),
        'input': value.input,
    };
}


