/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaResourceStatusType,
    KvintaResourceStatusTypeFromJSON,
    KvintaResourceStatusTypeFromJSONTyped,
    KvintaResourceStatusTypeToJSON,
    KvintaResourceType,
    KvintaResourceTypeFromJSON,
    KvintaResourceTypeFromJSONTyped,
    KvintaResourceTypeToJSON,
    KvintaSoftwareSystem,
    KvintaSoftwareSystemFromJSON,
    KvintaSoftwareSystemFromJSONTyped,
    KvintaSoftwareSystemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaDetectStaleResourcesRequest
 */
export interface KvintaDetectStaleResourcesRequest {
    /**
     * 
     * @type {number}
     * @memberof KvintaDetectStaleResourcesRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaDetectStaleResourcesRequest
     */
    olderThanSec?: number;
    /**
     * 
     * @type {KvintaResourceType}
     * @memberof KvintaDetectStaleResourcesRequest
     */
    resourceType: KvintaResourceType;
    /**
     * 
     * @type {KvintaResourceStatusType}
     * @memberof KvintaDetectStaleResourcesRequest
     */
    status: KvintaResourceStatusType;
    /**
     * 
     * @type {KvintaSoftwareSystem}
     * @memberof KvintaDetectStaleResourcesRequest
     */
    system: KvintaSoftwareSystem;
}

export function KvintaDetectStaleResourcesRequestFromJSON(json: any): KvintaDetectStaleResourcesRequest {
    return KvintaDetectStaleResourcesRequestFromJSONTyped(json, false);
}

export function KvintaDetectStaleResourcesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDetectStaleResourcesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'olderThanSec': !exists(json, 'olderThanSec') ? undefined : json['olderThanSec'],
        'resourceType': KvintaResourceTypeFromJSON(json['resourceType']),
        'status': KvintaResourceStatusTypeFromJSON(json['status']),
        'system': KvintaSoftwareSystemFromJSON(json['system']),
    };
}

export function KvintaDetectStaleResourcesRequestToJSON(value?: KvintaDetectStaleResourcesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'limit': value.limit,
        'olderThanSec': value.olderThanSec,
        'resourceType': KvintaResourceTypeToJSON(value.resourceType),
        'status': KvintaResourceStatusTypeToJSON(value.status),
        'system': KvintaSoftwareSystemToJSON(value.system),
    };
}


