/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaResource,
    KvintaResourceFromJSON,
    KvintaResourceFromJSONTyped,
    KvintaResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaTextDocumentExternalNumber
 */
export interface KvintaTextDocumentExternalNumber {
    /**
     * 
     * @type {string}
     * @memberof KvintaTextDocumentExternalNumber
     */
    externalNumber: string;
    /**
     * 
     * @type {KvintaResource}
     * @memberof KvintaTextDocumentExternalNumber
     */
    resource: KvintaResource;
}

export function KvintaTextDocumentExternalNumberFromJSON(json: any): KvintaTextDocumentExternalNumber {
    return KvintaTextDocumentExternalNumberFromJSONTyped(json, false);
}

export function KvintaTextDocumentExternalNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTextDocumentExternalNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalNumber': json['externalNumber'],
        'resource': KvintaResourceFromJSON(json['resource']),
    };
}

export function KvintaTextDocumentExternalNumberToJSON(value?: KvintaTextDocumentExternalNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalNumber': value.externalNumber,
        'resource': KvintaResourceToJSON(value.resource),
    };
}


