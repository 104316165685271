import { Paper } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  ExportButton,
  KSidebarStore,
  PageContentStore,
  PAGE_CONTENT_STORE_ID,
  SearchField,
  SIDEBAR_STORE_ID,
} from 'kvinta/components';
import { TradeItemsStore, STORE_ID } from '../TradeItemsStore';
import { navigateTradeItemPath } from '../paths';
import { CSVDownload } from 'react-csv';
import { ITableActionsState } from 'kvinta/common';
import { TradeItemsTable } from '../components/TradeItemsTable';

interface TradeItemsViewProps {
  tradeItemsStore?: TradeItemsStore;
  intl: any;
  history?: any;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
}

class TradeItemsViewImpl extends Component<TradeItemsViewProps, ITableActionsState> {
  tableRef: React.RefObject<unknown>;

  constructor(props: TradeItemsViewProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.tradeItemsStore.unsetProductId();
    this.props.tradeItemsStore.fetchData();
  }

  navigateTradeItemSummary = (id: string) => {
    navigateTradeItemPath(this.props.history, id);
  };

  render() {
    const intl = this.props.intl;
    const { tradeItemFormOpen, exportActive, exportData, searchValue, autofocusSearchInList } =
      this.props.tradeItemsStore;
    this.props.tradeItemsStore.updateExported();
    return (
      <Route
        render={({ history }) => (
          <div>
            <Paper square>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: '20px',
                  paddingRight: '20px',
                }}
              >
                <SearchField
                  intl={this.props.intl}
                  searchValue={searchValue}
                  onChange={(value: string) => {
                    this.props.tradeItemsStore.updateSearch(value);
                  }}
                  autofocus={autofocusSearchInList}
                />
                <div style={{ minWidth: '300px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <div style={{ marginLeft: '5px' }}>
                    <ExportButton
                      onExportAll={() => this.props.tradeItemsStore.exportAll()}
                      onExportSelected={() => this.props.tradeItemsStore.exportSelected()}
                      hasSelected={this.props.tradeItemsStore.checked > 0}
                    />
                  </div>
                </div>
              </div>
              <div>
                <TradeItemsTable
                  history={history}
                  tradeItemsStore={this.props.tradeItemsStore}
                  pageContentStore={this.props.pageContentStore}
                  sidebarStore={this.props.sidebarStore}
                />
              </div>
              {exportActive && exportData !== undefined && <CSVDownload data={exportData} target="_blank" />}
            </Paper>
          </div>
        )}
      />
    );
  }
}
export const TradeItemsView = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(TradeItemsViewImpl)),
);
