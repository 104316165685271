import { Button, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  ExportButton,
  KSidebarStore,
  PageContentStore,
  PAGE_CONTENT_STORE_ID,
  SIDEBAR_STORE_ID,
} from 'kvinta/components';
import { CompaniesStore, STORE_ID } from './CompaniesStore';
import { DialogCreateCompany } from './DialogCreateCompany';
import { navigateCompanyPath } from '../paths';
import { CSVDownload } from 'react-csv';
import { ITableActionsState } from 'kvinta/common';
import { SearchField } from 'kvinta/components';
import { CompaniesTable } from './components/CompaniesTable';

interface CompaniesViewProps {
  companiesStore?: CompaniesStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
}

class CompaniesViewImpl extends Component<CompaniesViewProps, ITableActionsState> {
  tableRef: React.RefObject<unknown>;

  constructor(props: CompaniesViewProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }
  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  componentDidMount() {
    this.props.companiesStore.fetchData();
    this.props.sidebarStore.clearMainTitle();
  }

  navigateCompanySummary = (id: string) => {
    navigateCompanyPath(this.props.history, id);
  };

  render() {
    const intl = this.props.intl;
    const { companyFormOpen, exportActive, exportData, searchValue, autofocusSearchInList } = this.props.companiesStore;
    this.props.companiesStore.updateExported();
    return (
      <Route
        render={({ history }) => (
          <Paper square>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingTop: '20px',
                paddingRight: '20px',
              }}
            >
              <SearchField
                intl={this.props.intl}
                searchValue={searchValue}
                onChange={(value: string) => {
                  this.props.companiesStore.updateSearch(value);
                }}
                onBlur={(ev) => {
                  this.props.companiesStore.unfocusSearchField();
                }}
                autofocus={autofocusSearchInList}
              />
              <div style={{ minWidth: '300px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <div style={{ marginLeft: '5px' }}>
                  <ExportButton
                    onExportAll={() => this.props.companiesStore.exportAll()}
                    onExportSelected={() => this.props.companiesStore.exportSelected()}
                    hasSelected={this.props.companiesStore.checked > 0}
                  />
                </div>
                <div style={{ marginLeft: '5px' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon color="secondary" />}
                    onClick={(e) => {
                      this.props.companiesStore.openCreateCompanyForm();
                    }}
                  >
                    {intl.formatMessage({ id: 'button.create' })}
                  </Button>
                </div>
              </div>
            </div>
            <CompaniesTable
              history={history}
              companiesStore={this.props.companiesStore}
              pageContentStore={this.props.pageContentStore}
              sidebarStore={this.props.sidebarStore}
            />
            {companyFormOpen && <DialogCreateCompany />}
            {exportActive && exportData !== undefined && <CSVDownload data={exportData} target="_blank" />}
          </Paper>
        )}
      />
    );
  }
}
export const CompaniesView = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(CompaniesViewImpl)),
);
