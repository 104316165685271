import { Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import { KvintaMobileAppAssignment } from 'kvinta/apis/kvinta-devices-store';

export interface DeviceComponentProps {
  assignment: KvintaMobileAppAssignment;
  isEditing: boolean;
  assignmentData: any;
  appNames: string[];
  appVersions: string[];
  history: any;
  intl: any;
}
class AssignmentCompViewImpl extends Component<DeviceComponentProps> {
  render() {
    const formData = this.props.assignment;
    if (!formData) {
      return null;
    }
    const intl = this.props.intl;
    const disabled = !this.props.isEditing;
    const newFormData = this.props.assignmentData;
    const appNames = this.props.appNames;
    const appVersions = this.props.appVersions;

    return (
      <Route
        render={({ history }) => (
          <Grid container>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="deviceId">
                    {intl.formatMessage({ id: 'assignment.deviceid' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!Boolean(formData.deviceId)}
                    id="deviceId"
                    defaultValue={formData.deviceId}
                    onChange={(e) => {
                      newFormData.deviceId = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'assignment.deviceid' })}
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="email">
                    {intl.formatMessage({ id: 'assignment.email' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!Boolean(formData.email)}
                    id="email"
                    defaultValue={formData.email}
                    onChange={(e) => {
                      newFormData.email = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'assignment.email' })}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="appName">
                    {intl.formatMessage({ id: 'assignment.appname' })}
                  </InputLabel>
                  {this.props.isEditing ? (
                    <Select
                      id="appName"
                      defaultValue={formData.appName}
                      onChange={(e) => {
                        newFormData.appName = e.target.value;
                      }}
                      label={intl.formatMessage({ id: 'assignment.appname' })}
                    >
                      {appNames.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <OutlinedInput
                      readOnly={disabled}
                      disabled={!Boolean(formData.appName)}
                      id="appName"
                      defaultValue={formData.appName}
                      onChange={(e) => {
                        newFormData.appName = e.target.value;
                      }}
                      label={intl.formatMessage({ id: 'assignment.appname' })}
                    />
                  )}
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="appVersion">
                    {intl.formatMessage({ id: 'assignment.appversion' })}
                  </InputLabel>
                  {this.props.isEditing ? (
                    <Select
                      id="appVersion"
                      defaultValue={formData.appVersion}
                      onChange={(e) => {
                        newFormData.appVersion = e.target.value;
                      }}
                      label={intl.formatMessage({ id: 'assignment.appversion' })}
                    >
                      {appVersions.map((version) => (
                        <MenuItem key={version} value={version}>
                          {version}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <OutlinedInput
                      readOnly={disabled}
                      disabled={!Boolean(formData.appVersion)}
                      id="appVersion"
                      defaultValue={formData.appVersion}
                      onChange={(e) => {
                        newFormData.appVersion = e.target.value;
                      }}
                      label={intl.formatMessage({ id: 'assignment.appversion' })}
                    />
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        )}
      />
    );
  }
}

export const AssignmentCompView = injectIntl(AssignmentCompViewImpl);
