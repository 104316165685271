/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaDeliveryNote
 */
export interface KvintaDeliveryNote {
    /**
     * 
     * @type {number}
     * @memberof KvintaDeliveryNote
     */
    created?: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryNote
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryNote
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryNote
     */
    name: string;
}

export function KvintaDeliveryNoteFromJSON(json: any): KvintaDeliveryNote {
    return KvintaDeliveryNoteFromJSONTyped(json, false);
}

export function KvintaDeliveryNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDeliveryNote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': !exists(json, 'created') ? undefined : json['created'],
        'id': json['id'],
        'locationId': json['locationId'],
        'name': json['name'],
    };
}

export function KvintaDeliveryNoteToJSON(value?: KvintaDeliveryNote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created,
        'id': value.id,
        'locationId': value.locationId,
        'name': value.name,
    };
}


