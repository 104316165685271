/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDeliveryNote,
    KvintaDeliveryNoteFromJSON,
    KvintaDeliveryNoteFromJSONTyped,
    KvintaDeliveryNoteToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetListResultDeliveryNote
 */
export interface KvintaGetListResultDeliveryNote {
    /**
     * 
     * @type {Array<KvintaDeliveryNote>}
     * @memberof KvintaGetListResultDeliveryNote
     */
    list?: Array<KvintaDeliveryNote> | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaGetListResultDeliveryNote
     */
    total?: number | null;
}

export function KvintaGetListResultDeliveryNoteFromJSON(json: any): KvintaGetListResultDeliveryNote {
    return KvintaGetListResultDeliveryNoteFromJSONTyped(json, false);
}

export function KvintaGetListResultDeliveryNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetListResultDeliveryNote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': !exists(json, 'list') ? undefined : (json['list'] === null ? null : (json['list'] as Array<any>).map(KvintaDeliveryNoteFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function KvintaGetListResultDeliveryNoteToJSON(value?: KvintaGetListResultDeliveryNote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': value.list === undefined ? undefined : (value.list === null ? null : (value.list as Array<any>).map(KvintaDeliveryNoteToJSON)),
        'total': value.total,
    };
}


