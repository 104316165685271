/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaNotificationType,
    KvintaNotificationTypeFromJSON,
    KvintaNotificationTypeFromJSONTyped,
    KvintaNotificationTypeToJSON,
    KvintaTemplateType,
    KvintaTemplateTypeFromJSON,
    KvintaTemplateTypeFromJSONTyped,
    KvintaTemplateTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaCreateEmailTemplateRequest
 */
export interface KvintaCreateEmailTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateEmailTemplateRequest
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateEmailTemplateRequest
     */
    template?: string;
    /**
     * 
     * @type {KvintaTemplateType}
     * @memberof KvintaCreateEmailTemplateRequest
     */
    templateType?: KvintaTemplateType;
    /**
     * 
     * @type {KvintaNotificationType}
     * @memberof KvintaCreateEmailTemplateRequest
     */
    notificationType?: KvintaNotificationType;
}

export function KvintaCreateEmailTemplateRequestFromJSON(json: any): KvintaCreateEmailTemplateRequest {
    return KvintaCreateEmailTemplateRequestFromJSONTyped(json, false);
}

export function KvintaCreateEmailTemplateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateEmailTemplateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'template': !exists(json, 'template') ? undefined : json['template'],
        'templateType': !exists(json, 'templateType') ? undefined : KvintaTemplateTypeFromJSON(json['templateType']),
        'notificationType': !exists(json, 'notificationType') ? undefined : KvintaNotificationTypeFromJSON(json['notificationType']),
    };
}

export function KvintaCreateEmailTemplateRequestToJSON(value?: KvintaCreateEmailTemplateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subject': value.subject,
        'template': value.template,
        'templateType': KvintaTemplateTypeToJSON(value.templateType),
        'notificationType': KvintaNotificationTypeToJSON(value.notificationType),
    };
}


