/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Query Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaGetRelativesRequest
 */
export interface KvintaGetRelativesRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaGetRelativesRequest
     */
    epc?: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaGetRelativesRequest
     */
    ts?: number;
}

export function KvintaGetRelativesRequestFromJSON(json: any): KvintaGetRelativesRequest {
    return KvintaGetRelativesRequestFromJSONTyped(json, false);
}

export function KvintaGetRelativesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetRelativesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epc': !exists(json, 'epc') ? undefined : json['epc'],
        'ts': !exists(json, 'ts') ? undefined : json['ts'],
    };
}

export function KvintaGetRelativesRequestToJSON(value?: KvintaGetRelativesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epc': value.epc,
        'ts': value.ts,
    };
}


