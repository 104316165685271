/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaListNotificationGroupRecipientRequest,
    KvintaListNotificationGroupRecipientRequestFromJSON,
    KvintaListNotificationGroupRecipientRequestFromJSONTyped,
    KvintaListNotificationGroupRecipientRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestListNotificationGroupRecipientRequest
 */
export interface KvintaOperationRequestListNotificationGroupRecipientRequest {
    /**
     * 
     * @type {KvintaListNotificationGroupRecipientRequest}
     * @memberof KvintaOperationRequestListNotificationGroupRecipientRequest
     */
    input?: KvintaListNotificationGroupRecipientRequest;
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestListNotificationGroupRecipientRequest
     */
    context?: KvintaExecutionContext;
}

export function KvintaOperationRequestListNotificationGroupRecipientRequestFromJSON(json: any): KvintaOperationRequestListNotificationGroupRecipientRequest {
    return KvintaOperationRequestListNotificationGroupRecipientRequestFromJSONTyped(json, false);
}

export function KvintaOperationRequestListNotificationGroupRecipientRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestListNotificationGroupRecipientRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'input': !exists(json, 'input') ? undefined : KvintaListNotificationGroupRecipientRequestFromJSON(json['input']),
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
    };
}

export function KvintaOperationRequestListNotificationGroupRecipientRequestToJSON(value?: KvintaOperationRequestListNotificationGroupRecipientRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'input': KvintaListNotificationGroupRecipientRequestToJSON(value.input),
        'context': KvintaExecutionContextToJSON(value.context),
    };
}


