/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaReportingDependency
 */
export interface KvintaReportingDependency {
    /**
     * 
     * @type {string}
     * @memberof KvintaReportingDependency
     */
    child: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaReportingDependency
     */
    parent: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaReportingDependency
     */
    tenantId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaReportingDependency
     */
    timestamp?: number;
}

export function KvintaReportingDependencyFromJSON(json: any): KvintaReportingDependency {
    return KvintaReportingDependencyFromJSONTyped(json, false);
}

export function KvintaReportingDependencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaReportingDependency {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'child': json['child'],
        'parent': json['parent'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
    };
}

export function KvintaReportingDependencyToJSON(value?: KvintaReportingDependency | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'child': value.child,
        'parent': value.parent,
        'tenantId': value.tenantId,
        'timestamp': value.timestamp,
    };
}


