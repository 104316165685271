/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisDocumentRecord,
    KvintaEpcisDocumentRecordFromJSON,
    KvintaEpcisDocumentRecordFromJSONTyped,
    KvintaEpcisDocumentRecordToJSON,
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestEpcisDocumentRecord
 */
export interface KvintaOperationRequestEpcisDocumentRecord {
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestEpcisDocumentRecord
     */
    context?: KvintaExecutionContext;
    /**
     * 
     * @type {KvintaEpcisDocumentRecord}
     * @memberof KvintaOperationRequestEpcisDocumentRecord
     */
    input?: KvintaEpcisDocumentRecord;
}

export function KvintaOperationRequestEpcisDocumentRecordFromJSON(json: any): KvintaOperationRequestEpcisDocumentRecord {
    return KvintaOperationRequestEpcisDocumentRecordFromJSONTyped(json, false);
}

export function KvintaOperationRequestEpcisDocumentRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestEpcisDocumentRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
        'input': !exists(json, 'input') ? undefined : KvintaEpcisDocumentRecordFromJSON(json['input']),
    };
}

export function KvintaOperationRequestEpcisDocumentRecordToJSON(value?: KvintaOperationRequestEpcisDocumentRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': KvintaExecutionContextToJSON(value.context),
        'input': KvintaEpcisDocumentRecordToJSON(value.input),
    };
}


