/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaNotificationGroupType,
    KvintaNotificationGroupTypeFromJSON,
    KvintaNotificationGroupTypeFromJSONTyped,
    KvintaNotificationGroupTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestNotificationGroupType
 */
export interface KvintaOperationRequestNotificationGroupType {
    /**
     * 
     * @type {KvintaNotificationGroupType}
     * @memberof KvintaOperationRequestNotificationGroupType
     */
    input?: KvintaNotificationGroupType;
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestNotificationGroupType
     */
    context?: KvintaExecutionContext;
}

export function KvintaOperationRequestNotificationGroupTypeFromJSON(json: any): KvintaOperationRequestNotificationGroupType {
    return KvintaOperationRequestNotificationGroupTypeFromJSONTyped(json, false);
}

export function KvintaOperationRequestNotificationGroupTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestNotificationGroupType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'input': !exists(json, 'input') ? undefined : KvintaNotificationGroupTypeFromJSON(json['input']),
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
    };
}

export function KvintaOperationRequestNotificationGroupTypeToJSON(value?: KvintaOperationRequestNotificationGroupType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'input': KvintaNotificationGroupTypeToJSON(value.input),
        'context': KvintaExecutionContextToJSON(value.context),
    };
}


