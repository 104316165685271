/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecution,
    KvintaExecutionFromJSON,
    KvintaExecutionFromJSONTyped,
    KvintaExecutionToJSON,
    KvintaExecutionStatus,
    KvintaExecutionStatusFromJSON,
    KvintaExecutionStatusFromJSONTyped,
    KvintaExecutionStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaExecutionStatusRecord
 */
export interface KvintaExecutionStatusRecord {
    /**
     * 
     * @type {KvintaExecution}
     * @memberof KvintaExecutionStatusRecord
     */
    execution: KvintaExecution;
    /**
     * 
     * @type {KvintaExecutionStatus}
     * @memberof KvintaExecutionStatusRecord
     */
    status: KvintaExecutionStatus;
}

export function KvintaExecutionStatusRecordFromJSON(json: any): KvintaExecutionStatusRecord {
    return KvintaExecutionStatusRecordFromJSONTyped(json, false);
}

export function KvintaExecutionStatusRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaExecutionStatusRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'execution': KvintaExecutionFromJSON(json['execution']),
        'status': KvintaExecutionStatusFromJSON(json['status']),
    };
}

export function KvintaExecutionStatusRecordToJSON(value?: KvintaExecutionStatusRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'execution': KvintaExecutionToJSON(value.execution),
        'status': KvintaExecutionStatusToJSON(value.status),
    };
}


