import { Button, Paper } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { tabsSummary } from './tabs';
import { reaction } from 'mobx';
import { IEditState } from 'kvinta/common';
import { DevicesStore, DEVICES_STORE_ID } from './DevicesStore';
import { navigateDeviceAssignmentsPath, navigateDevicesPath } from 'kvinta/modules/paths';
import { DeviceCompView } from './DeviceCompView';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

interface DeviceSummaryViewProps {
  intl: any;
  devicesStore?: DevicesStore;
  sidebarStore?: KSidebarStore;
  id: string;
  history?: any;
  currentDevice?: any;
}

class DeviceSummaryViewImpl extends Component<DeviceSummaryViewProps, IEditState> {
  disposer: any;

  constructor(props: DeviceSummaryViewProps) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  async componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.devicesStore.fetchDevice(this.props.id);
    this.disposer = reaction(
      () => this.props.devicesStore.currentDevice,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.id as any, () => {
            navigateDevicesPath(this.props.history);
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  handleEditOpen = () => {
    this.setState({
      isEditing: true,
    });
    this.props.devicesStore.openUpdateDeviceForm();
  };

  handleEditSubmit = () => {
    this.props.devicesStore.submitUpdateDevice();
    if (!this.props.devicesStore.updateDeviceData.showError) {
      this.setState({
        isEditing: false,
      });
    }
  };

  onEditClose = () => {
    this.setState({
      isEditing: false,
    });
    this.props.devicesStore.fetchDevice(this.props.id);
  };

  render() {
    if (!this.props.devicesStore) {
      return null;
    }
    const formData = this.props.devicesStore.currentDevice;
    if (!formData) {
      return null;
    }
    const intl = this.props.intl;
    const devicedata = this.props.devicesStore.updateDeviceData;

    let buttonTitle;
    let buttonIcon;

    if (this.state.isEditing) {
      buttonTitle = 'button.confirm';
      buttonIcon = <DoneIcon color="secondary" />;
    } else {
      buttonTitle = 'button.edit';
      buttonIcon = <EditIcon color="secondary" />;
    }

    return (
      <div>
        <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          <TabBar
            onChange={(newValue: any) => {
              if (newValue === '0') {
                navigateDevicesPath(this.props.history);
              }
              if (newValue === '2') {
                navigateDeviceAssignmentsPath(this.props.history, formData.id);
              }
            }}
            tabId={'1'}
            tabs={tabsSummary}
          />
        </Paper>
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          <div style={{ marginRight: '15px', marginTop: '15px', float: 'right' }}>
            {this.state.isEditing ? (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon color="primary" />}
                style={{ marginRight: '10px' }}
                onClick={(e) => {
                  this.onEditClose();
                }}
              >
                {intl.formatMessage({ id: 'button.cancel' })}
              </Button>
            ) : null}
            <Button
              variant="contained"
              color="secondary"
              startIcon={buttonIcon}
              onClick={(e) => {
                if (this.state.isEditing) {
                  this.handleEditSubmit();
                } else {
                  this.handleEditOpen();
                }
              }}
            >
              {intl.formatMessage({ id: buttonTitle })}
            </Button>
          </div>

          <DeviceCompView
            device={formData}
            deviceData={devicedata}
            history={this.props.history}
            isEditing={this.state.isEditing}
          />
        </Paper>
      </div>
    );
  }
}

export const DeviceSummaryView = injectIntl(
  inject(DEVICES_STORE_ID, SIDEBAR_STORE_ID)(observer(DeviceSummaryViewImpl)),
);
