/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaFindUnusedSerialNumbersRow
 */
export interface KvintaFindUnusedSerialNumbersRow {
    /**
     * 
     * @type {string}
     * @memberof KvintaFindUnusedSerialNumbersRow
     */
    cryptoTail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaFindUnusedSerialNumbersRow
     */
    gtin: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaFindUnusedSerialNumbersRow
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaFindUnusedSerialNumbersRow
     */
    raw: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaFindUnusedSerialNumbersRow
     */
    serial?: string | null;
}

export function KvintaFindUnusedSerialNumbersRowFromJSON(json: any): KvintaFindUnusedSerialNumbersRow {
    return KvintaFindUnusedSerialNumbersRowFromJSONTyped(json, false);
}

export function KvintaFindUnusedSerialNumbersRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaFindUnusedSerialNumbersRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cryptoTail': !exists(json, 'cryptoTail') ? undefined : json['cryptoTail'],
        'gtin': json['gtin'],
        'id': json['id'],
        'raw': json['raw'],
        'serial': !exists(json, 'serial') ? undefined : json['serial'],
    };
}

export function KvintaFindUnusedSerialNumbersRowToJSON(value?: KvintaFindUnusedSerialNumbersRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cryptoTail': value.cryptoTail,
        'gtin': value.gtin,
        'id': value.id,
        'raw': value.raw,
        'serial': value.serial,
    };
}


