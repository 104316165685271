import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { tabs } from './tabs';

import React, { Component } from 'react';
import { EpcisMessagesStore, EPCIS_MESSAGES_STORE_ID } from './EpcisMessagesStore';
import { navigateEpcisMessagesPath } from './paths';
import { KSidebarStore, PageContentStore, PAGE_CONTENT_STORE_ID, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { tabBarHeight } from 'kvinta/common';
import { reaction } from 'mobx';

interface ViewEpcisMessageSummaryProps {
  intl: any;
  history?: any;
  sidebarStore?: KSidebarStore;
  epcisMessagesStore: EpcisMessagesStore;
  pageContentStore: PageContentStore;
  id: string;
}
class ViewEpcisMessageSummaryImpl extends Component<ViewEpcisMessageSummaryProps> {
  disposer: any;
  componentDidMount() {
    this.props.epcisMessagesStore.onLoadJSON(this.props.id);
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.epcisMessagesStore.currentMessage,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.id, () => {
            navigateEpcisMessagesPath(this.props.history);
          });
        }
      },
    );
  }
  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const { jsonPayload } = this.props.epcisMessagesStore;
    if (!jsonPayload) {
      return null;
    }
    const { width, height } = this.props.pageContentStore;
    const newHeight = height - tabBarHeight;
    return (
      <div>
        <TabBar
          onChange={(newValue: any) => {
            // no other tabs
          }}
          tabId={'1'}
          tabs={tabs}
        />
        <AceEditor
          mode="json"
          theme="github"
          readOnly={true}
          style={{ width, height: newHeight }}
          setOptions={{ useWorker: false }}
          editorProps={{
            $blockScrolling: false,
            $highlightPending: false,
          }}
          enableBasicAutocompletion={false}
          enableLiveAutocompletion={false}
          value={jsonPayload}
          onChange={() => {
            // Nothing to do here
          }}
        />
      </div>
    );
  }
}
export const ViewEpcisMessageSummary = injectIntl(
  inject(EPCIS_MESSAGES_STORE_ID, PAGE_CONTENT_STORE_ID, SIDEBAR_STORE_ID)(observer(ViewEpcisMessageSummaryImpl)),
);
