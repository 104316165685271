/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Capture Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCaptureSensorMetaData
 */
export interface KvintaCaptureSensorMetaData {
    /**
     * 
     * @type {Date}
     * @memberof KvintaCaptureSensorMetaData
     */
    time?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof KvintaCaptureSensorMetaData
     */
    startTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof KvintaCaptureSensorMetaData
     */
    endTime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorMetaData
     */
    deviceID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorMetaData
     */
    deviceMetaData?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorMetaData
     */
    rawData?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorMetaData
     */
    bizRules?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorMetaData
     */
    dataProcessingMethod?: string | null;
}

export function KvintaCaptureSensorMetaDataFromJSON(json: any): KvintaCaptureSensorMetaData {
    return KvintaCaptureSensorMetaDataFromJSONTyped(json, false);
}

export function KvintaCaptureSensorMetaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCaptureSensorMetaData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': !exists(json, 'time') ? undefined : (json['time'] === null ? null : new Date(json['time'])),
        'startTime': !exists(json, 'startTime') ? undefined : (json['startTime'] === null ? null : new Date(json['startTime'])),
        'endTime': !exists(json, 'endTime') ? undefined : (json['endTime'] === null ? null : new Date(json['endTime'])),
        'deviceID': !exists(json, 'deviceID') ? undefined : json['deviceID'],
        'deviceMetaData': !exists(json, 'deviceMetaData') ? undefined : json['deviceMetaData'],
        'rawData': !exists(json, 'rawData') ? undefined : json['rawData'],
        'bizRules': !exists(json, 'bizRules') ? undefined : json['bizRules'],
        'dataProcessingMethod': !exists(json, 'dataProcessingMethod') ? undefined : json['dataProcessingMethod'],
    };
}

export function KvintaCaptureSensorMetaDataToJSON(value?: KvintaCaptureSensorMetaData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': value.time === undefined ? undefined : (value.time === null ? null : value.time.toISOString()),
        'startTime': value.startTime === undefined ? undefined : (value.startTime === null ? null : value.startTime.toISOString()),
        'endTime': value.endTime === undefined ? undefined : (value.endTime === null ? null : value.endTime.toISOString()),
        'deviceID': value.deviceID,
        'deviceMetaData': value.deviceMetaData,
        'rawData': value.rawData,
        'bizRules': value.bizRules,
        'dataProcessingMethod': value.dataProcessingMethod,
    };
}


