/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCreateCompanyRequest
 */
export interface KvintaCreateCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCompanyRequest
     */
    address1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCompanyRequest
     */
    address2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCompanyRequest
     */
    address3?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCompanyRequest
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCompanyRequest
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCompanyRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCompanyRequest
     */
    gln13?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCompanyRequest
     */
    id?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaCreateCompanyRequest
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaCreateCompanyRequest
     */
    longitude?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCompanyRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCompanyRequest
     */
    postalCode?: string | null;
}

export function KvintaCreateCompanyRequestFromJSON(json: any): KvintaCreateCompanyRequest {
    return KvintaCreateCompanyRequestFromJSONTyped(json, false);
}

export function KvintaCreateCompanyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateCompanyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address1': !exists(json, 'address1') ? undefined : json['address1'],
        'address2': !exists(json, 'address2') ? undefined : json['address2'],
        'address3': !exists(json, 'address3') ? undefined : json['address3'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'gln13': !exists(json, 'gln13') ? undefined : json['gln13'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'name': json['name'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
    };
}

export function KvintaCreateCompanyRequestToJSON(value?: KvintaCreateCompanyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address1': value.address1,
        'address2': value.address2,
        'address3': value.address3,
        'city': value.city,
        'country': value.country,
        'description': value.description,
        'gln13': value.gln13,
        'id': value.id,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'name': value.name,
        'postalCode': value.postalCode,
    };
}


