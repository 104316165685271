export const CompaniesPath = '/companies';
export const CompanyPath = '/company/:id';

export function navigateCompaniesPath(history: any) {
  history.push(CompaniesPath);
}

export function navigateCompanyPath(history: any, id: string) {
  history.push(CompanyPath.replaceAll(':id', id));
}
