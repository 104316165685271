import { Paper } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { PAGE_CONTENT_STORE_ID, TabBar } from 'kvinta/components';
import { EpcMessagesStore, EPC_MESSAGES_STORE_ID } from './EpcMessagesStore';
import { MovementMap } from './Map/MovementMap';
import { unescapeId } from './paths';
import { navigateEpcTab, tabs } from './tabs';

interface ViewEpcMapProps {
  intl: any;
  id: string;
  ts: number;
  index?: number;
  history: any;
  epcMessagesStore: EpcMessagesStore;
}
class ViewEpcMapImpl extends Component<ViewEpcMapProps> {
  componentDidMount() {
    this.props.epcMessagesStore.fetchMap(unescapeId(this.props.id));
  }
  render() {
    const movementMapStore = this.props.epcMessagesStore.movementMapStore;
    if (!movementMapStore) {
      return null;
    }
    return (
      <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        <TabBar
          onChange={(newValue: any) => {
            navigateEpcTab(
              this.props.history,
              newValue as string,
              unescapeId(this.props.id),
              this.props.ts,
              this.props.index,
            );
          }}
          tabId={'2'}
          tabs={tabs}
        />
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          <MovementMap movementMapStore={movementMapStore} />
        </Paper>
      </Paper>
    );
  }
}

export const ViewEpcMap = injectIntl(inject(EPC_MESSAGES_STORE_ID, PAGE_CONTENT_STORE_ID)(observer(ViewEpcMapImpl)));
