/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Query Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaHierarchyEvent,
    KvintaHierarchyEventFromJSON,
    KvintaHierarchyEventFromJSONTyped,
    KvintaHierarchyEventToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaHierarchyEvents
 */
export interface KvintaHierarchyEvents {
    /**
     * 
     * @type {string}
     * @memberof KvintaHierarchyEvents
     */
    epc?: string;
    /**
     * 
     * @type {Array<KvintaHierarchyEvent>}
     * @memberof KvintaHierarchyEvents
     */
    events?: Array<KvintaHierarchyEvent> | null;
}

export function KvintaHierarchyEventsFromJSON(json: any): KvintaHierarchyEvents {
    return KvintaHierarchyEventsFromJSONTyped(json, false);
}

export function KvintaHierarchyEventsFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaHierarchyEvents {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epc': !exists(json, 'epc') ? undefined : json['epc'],
        'events': !exists(json, 'events') ? undefined : (json['events'] === null ? null : (json['events'] as Array<any>).map(KvintaHierarchyEventFromJSON)),
    };
}

export function KvintaHierarchyEventsToJSON(value?: KvintaHierarchyEvents | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epc': value.epc,
        'events': value.events === undefined ? undefined : (value.events === null ? null : (value.events as Array<any>).map(KvintaHierarchyEventToJSON)),
    };
}


