/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaReadEpcisEventWithProductsRequest
 */
export interface KvintaReadEpcisEventWithProductsRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaReadEpcisEventWithProductsRequest
     */
    eventId?: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaReadEpcisEventWithProductsRequest
     */
    reportLevel?: number;
}

export function KvintaReadEpcisEventWithProductsRequestFromJSON(json: any): KvintaReadEpcisEventWithProductsRequest {
    return KvintaReadEpcisEventWithProductsRequestFromJSONTyped(json, false);
}

export function KvintaReadEpcisEventWithProductsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaReadEpcisEventWithProductsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'reportLevel': !exists(json, 'reportLevel') ? undefined : json['reportLevel'],
    };
}

export function KvintaReadEpcisEventWithProductsRequestToJSON(value?: KvintaReadEpcisEventWithProductsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventId': value.eventId,
        'reportLevel': value.reportLevel,
    };
}


