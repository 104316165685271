import { AuthService } from 'kvinta/service/auth';
import { TAppOptionsConfig } from 'kvinta/common/Interfaces';

import { Configuration as ArlaConf, DefaultApi as ArlaStoreApi } from './kvinta-arla-extensions';
import { Configuration as DevicesConf, DefaultApi as DevicesStoreApi } from './kvinta-devices-store';
import { Configuration as DocumentConf, DefaultApi as DocumentStoreApi } from './kvinta-document-store';
import { Configuration as ECaptureConf, DefaultApi as ECaptureStoreApi } from './kvinta-epcis-capture-functions';
import { Configuration as EDocStoreConf, DefaultApi as EDocStoreStoreApi } from './kvinta-epcis-document-store';
import { Configuration as EQueryConf, DefaultApi as EQueryStoreApi } from './kvinta-epcis-query-functions';
import { Configuration as MDConf, DefaultApi as MDStoreApi } from './kvinta-masterdata-service';
import { Configuration as StatusConf, DefaultApi as StatusStoreApi } from './kvinta-status-store';
import { Configuration as NotificationFunctionsConf, DefaultApi as NotificationFunctionsApi } from './kvinta-notification-functions';
import {
  devicesApiPath,
  documentsApiPath,
  epcisDocumentStoreApiPath,
  epcisQueryApiPath,
  masterdataApiPath, notificationFunctionsApiPath,
  shippingApiPath,
  statusStoreApiPath,
} from 'config';

export function createArlaStoreAPI(config: TAppOptionsConfig, authS: AuthService) {
  return new ArlaStoreApi(
    new ArlaConf({
      basePath: shippingApiPath,
      middleware: [authS.getAuthMiddleware()],
    }),
  );
}

export function createDevicesAPI(config: TAppOptionsConfig, authS: AuthService) {
  return new DevicesStoreApi(
    new DevicesConf({
      basePath: devicesApiPath, 
      middleware: [authS.getAuthMiddleware()],
    }),
  );
}

export function createDocumentsStoreAPI(config: TAppOptionsConfig, authS: AuthService) {
  return new DocumentStoreApi(
    new DocumentConf({
      basePath: documentsApiPath,
      middleware: [authS.getAuthMiddleware()],
    }),
  );
}

export function createEPCISCaptureAPI(config: TAppOptionsConfig, authS: AuthService) {
  return new ECaptureStoreApi(
    new ECaptureConf({
      basePath: epcisDocumentStoreApiPath, // TODO: This is wrong???
      middleware: [authS.getAuthMiddleware()],
    }),
  );
}

export function createEPCISDocStoreAPI(config: TAppOptionsConfig, authS: AuthService) {
  return new EDocStoreStoreApi(
    new EDocStoreConf({
      basePath: epcisDocumentStoreApiPath,
      middleware: [authS.getAuthMiddleware()],
    }),
  );
}

export function createEPCISQueryAPI(config: TAppOptionsConfig, authS: AuthService) {
  return new EQueryStoreApi(
    new EQueryConf({
      basePath: epcisQueryApiPath,
      middleware: [authS.getAuthMiddleware()],
    }),
  );
}

export function createMasterDataAPI(config: TAppOptionsConfig, authS: AuthService) {
  return new MDStoreApi(
    new MDConf({
      basePath: masterdataApiPath,
      middleware: [authS.getAuthMiddleware()],
    }),
  );
}

export function createStatusStoreAPI(config: TAppOptionsConfig, authS: AuthService) {
  return new StatusStoreApi(
    new StatusConf({
      basePath: statusStoreApiPath,
      middleware: [authS.getAuthMiddleware()],
    }),
  );
}

export function createNotificationFunctionsAPI(config: TAppOptionsConfig, authS: AuthService) {
  return new NotificationFunctionsApi(
    new NotificationFunctionsConf({
      basePath: notificationFunctionsApiPath,
      middleware: [authS.getAuthMiddleware()],
    }),
  );
}
