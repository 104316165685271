import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ISelectableRow, IValueLabel, TAppOptionsConfig } from 'kvinta/common/Interfaces';
import { DefaultApi as MDDocumentApi } from 'kvinta/apis/kvinta-masterdata-service';
import {
  KvintaOperationStatus,
  KvintaSerialNumber,
  KvintaSerialNumberOrder,
  KvintaSerialNumberOrderItem,
  KvintaSerialNumberOrderItemTypeFromJSON,
  KvintaSerialNumberOrderStatus,
  KvintaSerialNumberOrderTypeFromJSON,
  KvintaSortDirection,
  KvintaSortExpressions,
} from 'kvinta/apis/kvinta-document-store/models';
import { DefaultApi as DocumentApi } from 'kvinta/apis/kvinta-document-store';
import { IFilter, IFilterColumn } from 'kvinta/components';
import { SelectableStore } from 'kvinta/common';
import { NotificationManager, UserStore } from 'kvinta/modules/main';
import { format } from 'date-fns';
import { navigateSerialNumberOrdersPath } from './paths';

const MaxItemQuantity = 150000;

export interface SerialNumberOrderItemFormData {
  gtin: string;
  itemType: string;
  quantity: string;
  showError: boolean;
}

export interface SerialNumberOrderCreateFormData {
  contactPerson: string;
  documentNumber: string;
  documentDate: Date;
  orderType: string;
  paymentType: string;
  showError: boolean;
}

enum orderType {
  OMS_MILK = 'OMS_MILK',
  KVINTA_GENERATOR = 'KVINTA_GENERATOR',
  OMS_WATER = 'OMS_WATER',
  OMS_BEER = 'OMS_BEER',
  OMS_BIO = 'OMS_BIO',
}

const paymentTypes = [
  { value: 1, label: 'serial-number-order-form.field.payment.byemission' },
  { value: 2, label: 'serial-number-order-form.field.payment.byutilization' },
];

enum PaymentType {
  BY_EMISSION = '1',
  BY_UTILIZATION = '2',
}

interface ISerialNumberOrderRow
  extends KvintaSerialNumberOrderStatus,
    KvintaSerialNumber,
    KvintaSerialNumberOrder,
    ISelectableRow {}

export class SerialNumberOrdersStore extends SelectableStore<ISerialNumberOrderRow> {
  private _config: TAppOptionsConfig;
  private _docApi: DocumentApi;
  private _mdApi: MDDocumentApi;
  private _notificationManager: NotificationManager;
  private _userStore: UserStore;

  isLoading: boolean;

  page: number;
  totalCount: number;
  pageSize: number;
  currentSort: KvintaSortExpressions;
  // currentUser: UserStore;

  // createSerialNumberOrderData?: SerialNumberOrderFormData;
  // serialNumberOrderFormOpen: boolean;
  createSerialNumberOrderFormData?: SerialNumberOrderCreateFormData;
  currentDate: number;
  serialNumberOrderFormOpen: boolean;

  serialNumberOrderItemFormData?: SerialNumberOrderItemFormData;
  serialNumberOrderItemFormOpen: boolean;
  gtins: IValueLabel[];

  filter?: IFilter;

  currentSerialNumberOrder?: KvintaSerialNumberOrder;
  currentSerialNumberOrderItem?: KvintaSerialNumberOrderItem;
  currentSerialNumberOrderSerialNumber?: KvintaSerialNumber;
  currentSerialNumberDocument?: KvintaSerialNumberOrder;

  exportSNListActive: boolean;
  exportSNListData: KvintaSerialNumberOrder[] | undefined;

  exportSNItemActive: boolean;
  exportSNItemData: string | undefined;

  exportSNDocStatusesActive: boolean;
  exportSNDocStatusesData: KvintaSerialNumberOrderStatus[] | undefined;

  currentSerialNumberDocId: string | undefined;

  confirmDeleteDialog: boolean;

  constructor(
    config: TAppOptionsConfig,
    notificationManager: NotificationManager,
    mdApi: MDDocumentApi,
    docApi: DocumentApi,
    userStore: UserStore,
  ) {
    super();
    makeObservable(this, {
      fetchPage: action.bound,
      fetchData: action.bound,
      fetchItemsData: action.bound,
      fetchSerialNumbersData: action.bound,
      fetchSerialNumberOrder: action.bound,

      onChangeCreateSerialNumberOrderField: action,
      openCreateSerialNumberOrderForm: action,
      closeCreateSerialNumberOrderForm: action,

      openCreateSerialNumberOrderItemForm: action,
      onChangeCreateSerialNumberOrderItemField: action.bound,
      closeCreateSerialNumberOrderItemForm: action,
      submitCreateSerialNumberOrderItemForm: action.bound,

      setFilter: action,
      isLoading: observable,
      filter: observable,
      page: observable,
      pageSize: observable,
      confirmDeleteDialog: observable,

      serialNumberOrderFormOpen: observable,
      createSerialNumberOrderFormData: observable,
      serialNumberOrderItemFormData: observable,
      serialNumberOrderItemFormOpen: observable,

      currentSerialNumberOrder: observable,
      currentSerialNumberOrderItem: observable,
      currentSerialNumberOrderSerialNumber: observable,
      currentSerialNumberDocument: observable,
      currentSerialNumberDocId: observable,

      exportSNListActive: observable,
      exportSNListAll: action.bound,

      exportSNItemActive: observable,
      exportSNItemAll: action.bound,

      exportSNDocStatusesActive: observable,
      exportSNDocStatusesAll: action.bound,

      hasReleaseSNDoc: computed,
      hasDeleteSNDoc: computed,

      hideDeleteDialog: action,
      openDeleteDialog: action,
    });

    this._config = config;
    this._mdApi = mdApi;
    this._docApi = docApi;
    this._notificationManager = notificationManager;
    this._userStore = userStore;
    this.pageSize = 25;
    this.page = 0;
    this.currentDate = new Date().getDate();
    this.serialNumberOrderItemFormOpen = false;
    this.currentSort = {
      expressions: [
        {
          direction: KvintaSortDirection.Desc,
          property: 'created',
        },
      ],
    };
  }

  async fetchPage(page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
    });
    this.fetchData();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    runInAction(() => {
      this.isLoading = true;
      const field = getField(orderBy);
      this.currentSort = {
        expressions: [
          {
            property: field,
            direction: orderDirection === 'asc' ? KvintaSortDirection.Asc : KvintaSortDirection.Desc,
          },
        ],
      };
    });
    this.fetchData();
  }

  setFilter(columns: IFilterColumn[]) {
    this.filter = new IFilter(columns, this.doFilter);
  }

  doFilter = async () => {
    runInAction(() => {
      this.isLoading = true;
      this.page = 0;
    });
    this.fetchData();
  };

  async fetchData() {
    this.listData = [];
    this.isLoading = true;
    this.totalCount = 0;

    let filters = {} as { [key: string]: any } | null;

    const filterColumns = this.filter.visibleFilters;
    const documentDateRange = dateRange(filterColumns);
    filters = { documentDateRange };

    for (const filter of filterColumns) {
      if (filter.field !== 'rangeFrom' && filter.field !== 'rangeTo') {
        filters = {
          ...filters,
          [filter.field]: filter.value,
        };
      }
    }
    this._docApi
      .listSerialNumberOrders({
        kvintaOperationRequestListSerialNumberOrdersRequest: {
          input: { paging: { page: this.page, size: this.pageSize, sort: this.currentSort }, filter: filters },
        },
      })
      .then((result) => {
        runInAction(() => {
          this.listData = [];
          this.totalCount = 0;
          this.isLoading = false;
          if (result.status === KvintaOperationStatus.Error) {
            this.listData = [];
          } else {
            this.listData = (result.data.list || []).map((comp) => {
              return { ...comp } as ISerialNumberOrderRow;
            });
            this.totalCount = result.data.total || 0;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  openCreateSerialNumberOrderForm() {
    this.isLoading = true;
    this.createSerialNumberOrderFormData = {
      contactPerson: this._userStore.userInfo.username,
      documentNumber: '',
      documentDate: new Date(),
      orderType: orderType.OMS_MILK,
      paymentType: PaymentType.BY_UTILIZATION,
      showError: false,
    };
    this.isLoading = false;
    this.serialNumberOrderFormOpen = true;
  }

  closeCreateSerialNumberOrderForm() {
    runInAction(() => {
      this.serialNumberOrderFormOpen = false;
    });
  }

  onChangeCreateSerialNumberOrderField = (id: string, value: any) => {
    runInAction(() => {
      this.createSerialNumberOrderFormData[id] = value;
    });
  };

  async submitCreateSerialNumberOrderForm() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    let hasError = false;

    for (const field of requiredFieldsSerialNumberOrder) {
      if (errorRequired(field, requiredFieldsSerialNumberOrder, newLocal.createSerialNumberOrderFormData[field])) {
        hasError = true;
        break;
      }
    }
    newLocal.createSerialNumberOrderFormData.showError = hasError;

    if (!hasError) {
      runInAction(() => {
        newLocal.serialNumberOrderFormOpen = false;
        newLocal.isLoading = true;
      });
      const fd = newLocal.createSerialNumberOrderFormData;
      try {
        const createResult = await newLocal._docApi.createSerialNumberOrder({
          kvintaOperationRequestCreateSerialNumberOrderRequest: {
            input: {
              contactPerson: fd.contactPerson,
              documentNumber: fd.documentNumber,
              documentDate: fd.documentDate.getTime(),
              serialNumberOrderType: KvintaSerialNumberOrderTypeFromJSON(fd.orderType),
              paymentType: fd.paymentType,
            },
          },
        });
        if (createResult.status !== KvintaOperationStatus.Ok) {
          newLocal._notificationManager.sendError(createResult.error);
        } else {
          newLocal.fetchData();
        }
      } catch (err) {
        newLocal._notificationManager.sendError(JSON.stringify(err));
      }
      runInAction(() => {
        newLocal.serialNumberOrderFormOpen = false;
        newLocal.isLoading = false;
      });
    }
  }

  async openCreateSerialNumberOrderItemForm() {
    const gtins = await this._mdApi.listGtinDescriptionSkus({
      kvintaGetListRequest: {
        pagination: { page: 0, perPage: 10000 },
      },
    });
    if (gtins.operationStatus === KvintaOperationStatus.Ok) {
      runInAction(() => {
        this.serialNumberOrderItemFormData = {
          gtin: '',
          itemType: SerialNumberItemType.UNIT,
          quantity: '',
          showError: false,
        };

        this.serialNumberOrderItemFormOpen = true;
        this.gtins = gtins.data.map((g) => {
          return {
            value: g.gtin,
            label: g.gtin + ' - ' + g.description,
          };
        });
      });
    } else {
      this._notificationManager.sendError(gtins.error);
    }
  }

  onChangeCreateSerialNumberOrderItemField = (id: string, value: any) => {
    runInAction(() => {
      this.serialNumberOrderItemFormData[id] = value;
    });

    // this.serialNumberOrderItemFormData[id] = value;
    // let qty = undefined;
    // try {
    //   qty = parseInt(this.serialNumberOrderItemFormData.quantity);
    // } catch (err) {}
    // this.serialNumberOrderItemFormData.showError =
    //   this.serialNumberOrderItemFormData.gtin !== undefined &&
    //   this.serialNumberOrderItemFormData.gtin !== '' &&
    //   this.serialNumberOrderItemFormData.itemType !== undefined &&
    //   this.serialNumberOrderItemFormData.itemType !== '' &&
    //   qty !== undefined &&
    //   qty > 0 &&
    //   qty <= MaxItemQuantity;
  };

  closeCreateSerialNumberOrderItemForm() {
    runInAction(() => {
      this.serialNumberOrderItemFormOpen = false;
    });
  }

  submitCreateSerialNumberOrderItemForm = async () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    let hasError = false;
    for (const field of requiredFieldsSerialNumberOrderItem) {
      if (errorRequired(field, requiredFieldsSerialNumberOrderItem, newLocal.serialNumberOrderItemFormData[field])) {
        hasError = true;
        break;
      }
    }
    newLocal.serialNumberOrderItemFormData.showError = hasError;
    if (!hasError) {
      runInAction(() => {
        newLocal.serialNumberOrderFormOpen = false;
        newLocal.isLoading = true;
      });
      const createRes = await newLocal._docApi.createSerialNumberOrderItem({
        kvintaOperationRequestCreateSerialNumberOrderItemRequest: {
          input: {
            gtin: newLocal.serialNumberOrderItemFormData.gtin,
            itemType: KvintaSerialNumberOrderItemTypeFromJSON(newLocal.serialNumberOrderItemFormData.itemType),
            quantity: parseInt(newLocal.serialNumberOrderItemFormData.quantity),
            orderId: newLocal.currentSerialNumberDocId,
          },
        },
      });
      if (createRes.status === KvintaOperationStatus.Ok) {
        runInAction(() => {
          newLocal.serialNumberOrderItemFormOpen = false;
          newLocal.fetchItemsData(newLocal.currentSerialNumberDocId);
        });
      } else {
        newLocal._notificationManager.sendError(createRes.error);
      }
    }
  };

  async fetchSerialNumberOrder(id: string) {
    runInAction(() => {
      this.currentSerialNumberOrder = undefined;
    });
    this._docApi
      .readSerialNumberOrderById({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        runInAction(() => {
          if (result.status !== KvintaOperationStatus.Ok) {
            console.error(result.error);
            this._notificationManager.sendError(result.error);
          } else {
            this.currentSerialNumberOrder = result.data;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async fetchItemsPage(id: string, page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
    });
    this.fetchItemsData(id);
  }

  async fetchItemsData(id: string) {
    runInAction(() => {
      this.listData = [];
      this.totalCount = 0;
      this.isLoading = false;
    });
    this._docApi
      .listSerialNumberOrderItems({
        kvintaOperationRequestListSerialNumberOrderItemsRequest: {
          input: { orderId: id },
        },
      })
      .then((result) => {
        runInAction(() => {
          if (result.status === KvintaOperationStatus.Error) {
            this.listData = [];
          } else {
            if (result.data.list) {
              this.listData = result.data.list.map((comp) => {
                return { ...comp } as ISerialNumberOrderRow;
              });
            }
            this.totalCount = result.data.total;
            this.currentSerialNumberDocId = id;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async fetchSerialNumberOrderItem(id: string) {
    runInAction(() => {
      this.currentSerialNumberOrderItem = undefined;
    });
    this._docApi
      .readSerialNumberOrderItem({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        runInAction(() => {
          if (result.status !== KvintaOperationStatus.Ok) {
            console.error(result.error);
            this._notificationManager.sendError(result.error);
          } else {
            this.currentSerialNumberOrderItem = result.data;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async fetchSerialNumbersPage(id: string, page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
      // this.currentSerialNumberOrder.id = id;
    });
    this.fetchSerialNumbersData(id);
  }

  async fetchSerialNumbersData(id: string) {
    runInAction(() => {
      this.listData = [];
      this.totalCount = 0;
      this.isLoading = false;
    });
    this._docApi
      .listSerialNumbers({
        kvintaOperationRequestListSerialNumbersRequest: {
          input: { orderId: id, paging: { page: this.page, size: this.pageSize } },
        },
      })
      .then((result) => {
        runInAction(() => {
          if (result.status === KvintaOperationStatus.Error) {
            this.listData = [];
          } else {
            if (result.data.list) {
              this.listData = result.data.list.map((comp) => {
                return { ...comp } as ISerialNumberOrderRow;
              });
            }
            this.totalCount = result.data.total;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async fetchSerialNumberOrderSerialNumber(id: string) {
    runInAction(() => {
      this.currentSerialNumberOrderSerialNumber = undefined;
    });
    this._docApi
      .readSerialNumberById({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        runInAction(() => {
          this.currentSerialNumberOrderSerialNumber = undefined;
          if (result.status !== KvintaOperationStatus.Ok) {
            console.error(result.error);
            this._notificationManager.sendError(result.error);
          } else {
            this.currentSerialNumberOrderSerialNumber = result.data;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async fetchDocumentStatusesPage(id: string, page: number) {
    runInAction(() => {
      this.currentSerialNumberDocument = undefined;
      this.isLoading = true;
      this.page = page;
      // this.currentSerialNumberOrder.id = id;
    });
    this.fetchDocumentStatusesData(id);
  }

  async fetchDocumentStatusesData(id: string) {
    runInAction(() => {
      this.currentSerialNumberDocument = undefined;
    });
    this._docApi
      .readSerialNumberOrderById({
        kvintaOperationRequestString: { input: id },
      })
      .then((result) => {
        runInAction(() => {
          this.isLoading = false;
          if (result.status === KvintaOperationStatus.Error) {
          } else {
            this.currentSerialNumberDocument = result.data;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  formatDateTime(timestamp: number): string {
    return format(new Date(timestamp), this._config.defaultDateTimeFormatting);
  }

  /**
   * Export
   */
  updateSNListExported() {
    this.exportSNListData = undefined;
  }

  async exportSNListAll() {
    runInAction(() => {
      this.exportSNListActive = false;
      this.exportSNListData = undefined;
    });
    let filters = {} as { [key: string]: any } | null;

    const filterColumns = this.filter.visibleFilters;
    const documentDateRange = dateRange(filterColumns);
    filters = { documentDateRange };

    for (const filter of filterColumns) {
      if (filter.field !== 'rangeFrom' && filter.field !== 'rangeTo') {
        filters = {
          ...filters,
          [filter.field]: filter.value,
        };
      }
    }
    this._docApi
      .listSerialNumberOrders({
        kvintaOperationRequestListSerialNumberOrdersRequest: {
          input: { paging: { page: 0, size: 10000 }, filter: filters },
        },
      })
      .then((result) => {
        runInAction(() => {
          this.isLoading = false;
          if (result.status === KvintaOperationStatus.Error) {
            this._notificationManager.sendError(result.error);
          } else {
            if (result.data.list) {
              this.exportSNListData = result.data.list;
              this.exportSNListActive = true;
            }
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  updateSNItemExported() {
    this.exportSNItemData = undefined;
  }

  async exportSNItemAll(id: string) {
    runInAction(() => {
      this.exportSNItemActive = false;
      this.exportSNItemData = undefined;
    });
    this._docApi
      .downloadSerialNumbersForDirectPrinting({ orderId: id })
      .then((result) => {
        runInAction(() => {
          this.isLoading = false;
          this.exportSNItemData = result;
          this.exportSNItemActive = true;
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  updateSNDocStatusesExported() {
    this.exportSNDocStatusesData = undefined;
  }

  exportSNDocStatusesAll() {
    runInAction(() => {
      this.exportSNDocStatusesData = undefined;
      this.exportSNDocStatusesActive = false;
    });
    runInAction(() => {
      this.exportSNDocStatusesData = this.currentSerialNumberDocument.statuses;
      this.exportSNDocStatusesActive = true;
    });
  }

  get hasReleaseSNDoc() {
    if (!this.currentSerialNumberOrder) {
      return false;
    }
    const data = this.currentSerialNumberOrder;
    return (data.lastStatus === 'CREATED' || data.lastStatus === 'ERROR') && data.items?.length > 0;
  }

  get hasDeleteSNDoc() {
    if (!this.currentSerialNumberOrder) {
      return false;
    }
    const data = this.currentSerialNumberOrder;
    return data.lastStatus === 'CREATED';
  }

  hideDeleteDialog() {
    this.confirmDeleteDialog = false;
  }

  openDeleteDialog() {
    this.confirmDeleteDialog = true;
  }

  async deleteSNDocument(id: string, history: any) {
    this._docApi
      .deleteSerialNumberOrder({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((res) => {
        runInAction(() => {
          this.confirmDeleteDialog = false;
        });
        if (res.status !== KvintaOperationStatus.Ok) {
          this._notificationManager.sendError(res.error);
        } else {
          navigateSerialNumberOrdersPath(history);
        }
      })
      .catch((err) => {
        runInAction(() => {
          this.confirmDeleteDialog = false;
        });
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  releaseSNDocument(id: string) {
    this._docApi
      .releaseSerialNumberOrder({
        kvintaOperationRequestReleaseSerialNumberOrderRequest: {
          input: {
            orderId: id,
          },
        },
      })
      .then((res) => {
        if (res.status !== KvintaOperationStatus.Ok) {
          this._notificationManager.sendError(res.error);
        } else {
          this.fetchSerialNumberOrder(id);
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }
}

export const STORE_ID = 'serialNumberOrdersStore';

function getField(orderBy: number): string {
  switch (orderBy) {
    case 0:
      return 'created';
    case 1:
      return 'serialNumberOrderType';
    case 2:
      return 'documentNumber';
    case 3:
      return 'lastStatus';
    case 4:
      return 'documentDate';
    case 5:
      return 'contactPerson';
    case 6:
      return 'requested';
    case 7:
      return 'received';
    default:
      return 'created';
  }
}

interface IDateRange {
  from: number;
  to: number;
}

function dateRange(filterColumns: IFilterColumn[]): IDateRange | undefined {
  let from = undefined;
  let to = undefined;
  let dateRange: IDateRange | undefined;
  for (const filter of filterColumns) {
    if (filter.field === 'rangeFrom') {
      const dt = Date.parse(filter.value);
      from = dt;
    } else if (filter.field === 'rangeTo') {
      const dt = Date.parse(filter.value);
      to = dt;
    }
  }
  if (from || to) {
    dateRange = { from, to };
  }
  return dateRange;
}

export enum SerialNumberItemType {
  UNIT = 'UNIT',
  GROUP = 'GROUP',
}

export enum SerialNumberItemTypeWater {
  UNIT = 'UNIT',
}

export const requiredFieldsSerialNumberOrderItem = ['gtin', 'itemType', 'quantity'];
export const requiredFieldsSerialNumberOrder = [
  'contactPerson',
  'documentNumber',
  'documentDate',
  'documentNumber',
  'orderType',
];

export function errorRequired(id: string, fields: string[], value: any): boolean {
  if (fields.includes(id) && (value === undefined || value === '')) {
    return true;
  }
  return false;
}
