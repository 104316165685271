import { Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import React from 'react';
import { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { KvintaCompany } from 'kvinta/apis/kvinta-masterdata-service';
import { Route } from 'react-router-dom';
import { countries } from 'kvinta/common/countries';

export interface CompanyComponentProps {
  company?: KvintaCompany;
  intl: any;
  isEditing: boolean;
  companyData: any;
  history: any;
}
class CompanyCompViewImpl extends Component<CompanyComponentProps> {
  render() {
    const formData = this.props.company;
    if (!formData) {
      return null;
    }

    const country = countries.find((country) => {
      return country.shortCode === formData.country;
    }).countryName;

    const intl = this.props.intl;
    const disabled = !this.props.isEditing;
    const newFormData = this.props.companyData;

    return (
      <Route
        render={({ history }) => (
          <Grid container>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="name">
                    {intl.formatMessage({ id: 'company.name' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.name))}
                    id="name"
                    defaultValue={formData.name}
                    onChange={(e) => {
                      newFormData.name = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'company.name' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="longitude">
                    {intl.formatMessage({ id: 'company.longitude' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.longitude))}
                    id="longitude"
                    defaultValue={formData.longitude}
                    onChange={(e) => {
                      newFormData.longitude = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'company.longitude' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="address2">
                    {intl.formatMessage({ id: 'company.address2' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.address2))}
                    id="address2"
                    defaultValue={formData.address2}
                    onChange={(e) => {
                      newFormData.address2 = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'company.address2' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="city">
                    {intl.formatMessage({ id: 'company.city' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!Boolean(formData.city)}
                    id="city"
                    defaultValue={formData.city}
                    onChange={(e) => {
                      newFormData.city = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'company.city' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="description">
                    {intl.formatMessage({ id: 'company.description' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.description))}
                    id="description"
                    defaultValue={formData.description}
                    onChange={(e) => {
                      newFormData.description = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'company.description' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="postalCode">
                    {intl.formatMessage({ id: 'company-create-form.field.postalcode' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.postalCode))}
                    id="postalCode"
                    defaultValue={formData.postalCode}
                    onChange={(e) => {
                      newFormData.postalCode = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'company.postalcode' })}
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="latitude">
                    {intl.formatMessage({ id: 'company.latitude' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.latitude))}
                    id="latitude"
                    defaultValue={formData.latitude}
                    onChange={(e) => {
                      newFormData.latitude = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'company.latitude' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="address1">
                    {intl.formatMessage({ id: 'company.address1' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.address1))}
                    id="address1"
                    defaultValue={formData.address1}
                    onChange={(e) => {
                      newFormData.address1 = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'company.address1' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="address3">
                    {intl.formatMessage({ id: 'company.address3' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.address3))}
                    id="address3"
                    defaultValue={formData.address3}
                    onChange={(e) => {
                      newFormData.address3 = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'company.address3' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="gln13">
                    {intl.formatMessage({ id: 'company.gln13' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.gln13))}
                    id="gln13"
                    defaultValue={formData.gln13}
                    onChange={(e) => {
                      newFormData.gln13 = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'company.gln13' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="country">
                    {intl.formatMessage({ id: 'company.country' })}
                  </InputLabel>
                  {this.props.isEditing ? (
                    <Select
                      id="country"
                      defaultValue={formData.country}
                      onChange={(e) => {
                        newFormData.country = e.target.value;
                      }}
                      label={intl.formatMessage({ id: 'location-create-form.field.country' })}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.shortCode} value={country.shortCode}>
                          <FormattedMessage id={country.countryName} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <OutlinedInput
                      readOnly={disabled}
                      disabled={!(this.props.isEditing || Boolean(formData.country))}
                      id="country"
                      defaultValue={intl.formatMessage({ id: country })}
                      onChange={(e) => {
                        newFormData.country = e.target.value;
                      }}
                      label={intl.formatMessage({ id: 'company.country' })}
                    />
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        )}
      />
    );
  }
}

export const CompanyCompView = injectIntl(CompanyCompViewImpl);
