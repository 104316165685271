/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaPagination,
    KvintaPaginationFromJSON,
    KvintaPaginationFromJSONTyped,
    KvintaPaginationToJSON,
    KvintaSortBy,
    KvintaSortByFromJSON,
    KvintaSortByFromJSONTyped,
    KvintaSortByToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetListRequest
 */
export interface KvintaGetListRequest {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KvintaGetListRequest
     */
    filter?: { [key: string]: string; } | null;
    /**
     * 
     * @type {KvintaPagination}
     * @memberof KvintaGetListRequest
     */
    pagination: KvintaPagination;
    /**
     * 
     * @type {KvintaSortBy}
     * @memberof KvintaGetListRequest
     */
    sort?: KvintaSortBy;
}

export function KvintaGetListRequestFromJSON(json: any): KvintaGetListRequest {
    return KvintaGetListRequestFromJSONTyped(json, false);
}

export function KvintaGetListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetListRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
        'pagination': KvintaPaginationFromJSON(json['pagination']),
        'sort': !exists(json, 'sort') ? undefined : KvintaSortByFromJSON(json['sort']),
    };
}

export function KvintaGetListRequestToJSON(value?: KvintaGetListRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filter': value.filter,
        'pagination': KvintaPaginationToJSON(value.pagination),
        'sort': KvintaSortByToJSON(value.sort),
    };
}


