/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Query Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaGetRelativesRequest,
    KvintaGetRelativesRequestFromJSON,
    KvintaGetRelativesRequestFromJSONTyped,
    KvintaGetRelativesRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestGetRelativesRequest
 */
export interface KvintaOperationRequestGetRelativesRequest {
    /**
     * 
     * @type {KvintaGetRelativesRequest}
     * @memberof KvintaOperationRequestGetRelativesRequest
     */
    input?: KvintaGetRelativesRequest;
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestGetRelativesRequest
     */
    context?: KvintaExecutionContext;
}

export function KvintaOperationRequestGetRelativesRequestFromJSON(json: any): KvintaOperationRequestGetRelativesRequest {
    return KvintaOperationRequestGetRelativesRequestFromJSONTyped(json, false);
}

export function KvintaOperationRequestGetRelativesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestGetRelativesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'input': !exists(json, 'input') ? undefined : KvintaGetRelativesRequestFromJSON(json['input']),
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
    };
}

export function KvintaOperationRequestGetRelativesRequestToJSON(value?: KvintaOperationRequestGetRelativesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'input': KvintaGetRelativesRequestToJSON(value.input),
        'context': KvintaExecutionContextToJSON(value.context),
    };
}


