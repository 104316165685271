export const SerialNumberOrdersPath = '/serial-number-orders';
export const SerialNumberOrderPath = '/serial-number-order/:id';
export const SerialNumberOrderItemsPath = '/serial-number-order/:id/items';
export const SerialNumberOrderItemPath = '/serial-number-order/:id/item/:itemId';
export const SerialNumberOrderSerialNumbersPath = '/serial-number-order/:id/serial-numbers';
export const SerialNumberOrderSerialNumberPath = '/serial-number-order/:id/serial-number/:serialNumberId';
export const SerialNumberOrderDocumentStatusesPath = '/serial-number-order/:id/document-statuses';

export function navigateSerialNumberOrdersPath(history: any) {
  history.push(SerialNumberOrdersPath);
}

export function navigateSerialNumberOrderPath(history: any, id: string) {
  history.push(SerialNumberOrderPath.replaceAll(':id', id));
}

export function linkSerialNumberOrderPath(history: any, id: string): string {
  return SerialNumberOrderPath.replaceAll(':id', id);
}

export function navigateSerialNumberOrderItemsPath(history: any, id: string) {
  history.push(SerialNumberOrderItemsPath.replaceAll(':id', id));
}

export function navigateSerialNumberOrderItemPath(history: any, id: string, itemId: string) {
  history.push(SerialNumberOrderItemPath.replaceAll(':id', id).replaceAll(':itemId', itemId));
}

export function navigateSerialNumberOrderSerialNumbersPath(history: any, id: string) {
  history.push(SerialNumberOrderSerialNumbersPath.replaceAll(':id', id));
}

export function navigateSerialNumberOrderSerialNumberPath(history: any, id: string, serialNumberId: string) {
  history.push(SerialNumberOrderSerialNumberPath.replaceAll(':id', id).replaceAll(':serialNumberId', serialNumberId));
}

export function navigateSerialNumberOrderDocumentStatusesPath(history: any, id: string) {
  history.push(SerialNumberOrderDocumentStatusesPath.replaceAll(':id', id));
}
