/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaNotificationGroup
 */
export interface KvintaNotificationGroup {
    /**
     * 
     * @type {string}
     * @memberof KvintaNotificationGroup
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaNotificationGroup
     */
    groupName?: string;
}

export function KvintaNotificationGroupFromJSON(json: any): KvintaNotificationGroup {
    return KvintaNotificationGroupFromJSONTyped(json, false);
}

export function KvintaNotificationGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaNotificationGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'groupName': !exists(json, 'groupName') ? undefined : json['groupName'],
    };
}

export function KvintaNotificationGroupToJSON(value?: KvintaNotificationGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'groupName': value.groupName,
    };
}


