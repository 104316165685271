/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisDocumentRecord,
    KvintaEpcisDocumentRecordFromJSON,
    KvintaEpcisDocumentRecordFromJSONTyped,
    KvintaEpcisDocumentRecordToJSON,
    KvintaOperationStatus,
    KvintaOperationStatusFromJSON,
    KvintaOperationStatusFromJSONTyped,
    KvintaOperationStatusToJSON,
    KvintaQueueAction,
    KvintaQueueActionFromJSON,
    KvintaQueueActionFromJSONTyped,
    KvintaQueueActionToJSON,
    KvintaResource,
    KvintaResourceFromJSON,
    KvintaResourceFromJSONTyped,
    KvintaResourceToJSON,
    KvintaResourceChanges,
    KvintaResourceChangesFromJSON,
    KvintaResourceChangesFromJSONTyped,
    KvintaResourceChangesToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationResponseEpcisDocumentRecord
 */
export interface KvintaOperationResponseEpcisDocumentRecord {
    /**
     * 
     * @type {boolean}
     * @memberof KvintaOperationResponseEpcisDocumentRecord
     */
    continued?: boolean | null;
    /**
     * 
     * @type {KvintaEpcisDocumentRecord}
     * @memberof KvintaOperationResponseEpcisDocumentRecord
     */
    data?: KvintaEpcisDocumentRecord;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaOperationResponseEpcisDocumentRecord
     */
    duplicate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KvintaOperationResponseEpcisDocumentRecord
     */
    error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaOperationResponseEpcisDocumentRecord
     */
    executionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaOperationResponseEpcisDocumentRecord
     */
    message?: string | null;
    /**
     * 
     * @type {KvintaQueueAction}
     * @memberof KvintaOperationResponseEpcisDocumentRecord
     */
    queueAction?: KvintaQueueAction;
    /**
     * 
     * @type {KvintaResource}
     * @memberof KvintaOperationResponseEpcisDocumentRecord
     */
    resource?: KvintaResource;
    /**
     * 
     * @type {KvintaResourceChanges}
     * @memberof KvintaOperationResponseEpcisDocumentRecord
     */
    resourceChanges?: KvintaResourceChanges;
    /**
     * 
     * @type {string}
     * @memberof KvintaOperationResponseEpcisDocumentRecord
     */
    rootExecutionId?: string | null;
    /**
     * 
     * @type {KvintaOperationStatus}
     * @memberof KvintaOperationResponseEpcisDocumentRecord
     */
    status: KvintaOperationStatus;
}

export function KvintaOperationResponseEpcisDocumentRecordFromJSON(json: any): KvintaOperationResponseEpcisDocumentRecord {
    return KvintaOperationResponseEpcisDocumentRecordFromJSONTyped(json, false);
}

export function KvintaOperationResponseEpcisDocumentRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationResponseEpcisDocumentRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'continued': !exists(json, 'continued') ? undefined : json['continued'],
        'data': !exists(json, 'data') ? undefined : KvintaEpcisDocumentRecordFromJSON(json['data']),
        'duplicate': !exists(json, 'duplicate') ? undefined : json['duplicate'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'executionId': !exists(json, 'executionId') ? undefined : json['executionId'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'queueAction': !exists(json, 'queueAction') ? undefined : KvintaQueueActionFromJSON(json['queueAction']),
        'resource': !exists(json, 'resource') ? undefined : KvintaResourceFromJSON(json['resource']),
        'resourceChanges': !exists(json, 'resourceChanges') ? undefined : KvintaResourceChangesFromJSON(json['resourceChanges']),
        'rootExecutionId': !exists(json, 'rootExecutionId') ? undefined : json['rootExecutionId'],
        'status': KvintaOperationStatusFromJSON(json['status']),
    };
}

export function KvintaOperationResponseEpcisDocumentRecordToJSON(value?: KvintaOperationResponseEpcisDocumentRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'continued': value.continued,
        'data': KvintaEpcisDocumentRecordToJSON(value.data),
        'duplicate': value.duplicate,
        'error': value.error,
        'executionId': value.executionId,
        'message': value.message,
        'queueAction': KvintaQueueActionToJSON(value.queueAction),
        'resource': KvintaResourceToJSON(value.resource),
        'resourceChanges': KvintaResourceChangesToJSON(value.resourceChanges),
        'rootExecutionId': value.rootExecutionId,
        'status': KvintaOperationStatusToJSON(value.status),
    };
}


