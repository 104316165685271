import { action, makeObservable, observable, runInAction } from 'mobx';
import { TAppOptionsConfig } from 'kvinta/common';
import {
  DefaultApi as ArlaExtStoreApi,
  KvintaEpcisEventProduct,
  KvintaEpcisEventRecord,
  KvintaOperationResponseListEpcVerificationResult,
  KvintaOperationStatus,
  KvintaSortDirection,
  KvintaSortExpressions,
} from 'kvinta/apis/kvinta-arla-extensions';
import { NotificationManager } from 'kvinta/modules/main';
import { format } from 'date-fns';
import { DefaultApi as EDocumentApi } from 'kvinta/apis/kvinta-epcis-document-store';

export class VerificationStore {
  private _config: TAppOptionsConfig;
  private _arlaApi: ArlaExtStoreApi;
  private _eDocApi: EDocumentApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  verificationData: KvintaEpcisEventRecord[];
  currentVerificationProducts: KvintaEpcisEventProduct[];
  currentVerification: KvintaEpcisEventRecord;
  currentVerificationResult: KvintaOperationResponseListEpcVerificationResult;
  page: number;
  totalCount: number;
  pageSize: number;
  currentSort: KvintaSortExpressions;
  constructor(
    config: TAppOptionsConfig,
    notificationManager: NotificationManager,
    arlaApi: ArlaExtStoreApi,
    eDocApi: EDocumentApi,
  ) {
    makeObservable(this, {
      fetchPage: action.bound,
      currentVerificationProducts: observable,
      currentVerification: observable,
      currentVerificationResult: observable,
      isLoading: observable,
      page: observable,
      pageSize: observable,
    });

    this._config = config;
    this._arlaApi = arlaApi;
    this._eDocApi = eDocApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.isLoading = true;

    // TODO: Initial sort and order
    this.currentSort = {
      expressions: [
        {
          direction: KvintaSortDirection.Desc,
          property: 'recordTime', //! Set column
        },
      ],
    };
  }

  async fetchPage(page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
    });
    this.fetchData();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    runInAction(() => {
      const dir = getDirection(orderDirection);
      const fielistData = getField(orderBy);
      this.currentSort = {
        expressions: [
          {
            direction: dir,
            property: fielistData,
          },
        ],
      };
    });
    this.fetchData();
  }

  async fetchData() {
    runInAction(() => {
      this.isLoading = true;
      this.totalCount = 0;
      this.currentVerification = undefined;
    });
    this._arlaApi
      .listVerificationEvents({
        kvintaOperationRequestListVerificationEventsRequest: {
          input: { paging: { page: this.page, size: this.pageSize, sort: this.currentSort } },
          //! Date Range and location are options
        },
      })
      .then((result) => {
        runInAction(() => {
          this.verificationData = [];
          this.totalCount = 0;
          this.isLoading = false;
          if (result.status === KvintaOperationStatus.Error) {
            this.verificationData = [];
          } else {
            this.verificationData = (result.data.list || []).map((comp) => {
              return { ...comp } as KvintaEpcisEventRecord;
            });
            this.totalCount = result.data.total || 0;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  formatDateTime(timestamp: number): string {
    return format(new Date(timestamp), this._config.defaultDateTimeFormatting);
  }
  loadVerification = async (id: string) => {
    runInAction(() => {
      this.isLoading = true;
      this.currentVerification = undefined;
    });
    try {
      const result = await this._eDocApi.readEpcisEventRecordById({
        kvintaOperationRequestString: {
          input: id,
        },
      });
      if (result.data) {
        this.currentVerification = result.data;
      }
      this.isLoading = false;
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this._notificationManager.sendError(JSON.stringify(err));
      });
    }
  };

  loadVerificationProducts = async (id: string) => {
    runInAction(() => {
      this.isLoading = true;
      this.totalCount = 0;
      this.currentVerificationProducts = [];
    });
    try {
      const result = await this._arlaApi.readShipmentEventProducts({
        kvintaOperationRequestReadEpcisEventWithProductsRequest: {
          input: {
            reportLevel: 1,
            eventId: id,
          },
        },
      });

      if (result.data) {
        this.currentVerificationProducts = result.data.products;
        this.totalCount = result.data.products.length;
      }
      this.isLoading = false;
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.currentVerificationProducts = [];
        this._notificationManager.sendError(JSON.stringify(err));
      });
    }
  };

  async loadVerificationResults(id: string) {
    runInAction(() => {
      this.isLoading = true;
      this.totalCount = 0;
      this.currentVerificationResult = null;
    });

    this._arlaApi
      .readVerificationEventResult({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        runInAction(() => {
          if (result.data) {
            this.currentVerificationResult = result;
            this.totalCount = result.data.length;
          } else {
            this.currentVerificationResult = {
              data: [
                {
                  valid: true,
                },
              ],
            };
          }
          this.isLoading = false;
        });
      });
  }
}

export const VERIFICATION_STORE_ID = 'verificationStore';

function getDirection(orderDirection: string): KvintaSortDirection {
  if (orderDirection === 'asc') {
    return KvintaSortDirection.Asc;
  } else {
    return KvintaSortDirection.Desc;
  }
}

function getField(orderBy: number): string {
  switch (orderBy) {
    case 0:
      return 'eventTime';
    case 1:
      return 'recordTime';
    case 3:
      return 'bizLocation';
    case 4:
      return 'bizStep';
    case 5:
      return 'bizTransaction';
    case 6:
      return 'disposition';
    case 7:
      return 'documentId';
    default:
      return 'eventTime';
  }
}
