import { Box, FormControl, Grid, InputLabel, OutlinedInput, IconButton, InputAdornment } from '@material-ui/core';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KvintaProduct, KvintaTradeItem } from 'kvinta/apis/kvinta-masterdata-service';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { navigateProductPath } from '../../paths';
import { Route } from 'react-router-dom';

export interface TradeItemComponentProps {
  tradeItem?: KvintaTradeItem;
  intl: any;
  history: any;
  productData?: KvintaProduct;
  isEditing: boolean;
  tradeItemData: any;
}
class TradeItemCompViewImpl extends Component<TradeItemComponentProps> {
  render() {
    const comp = this.props.tradeItem;
    if (!comp) {
      return null;
    }
    const productData = this.props.productData;

    if (!productData) {
      return null;
    }

    const intl = this.props.intl;
    const formData = comp;
    const disabled = !this.props.isEditing;
    const newFormData = this.props.tradeItemData;

    return (
      <Route
        render={({ history }) => (
          <Grid container>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel htmlFor="trade_item_product" style={{ backgroundColor: 'white' }}>
                    {intl.formatMessage({ id: 'trade-item-list.header.productid' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={true}
                    id="trade_item_product"
                    defaultValue={productData.name}
                    label={intl.formatMessage({ id: 'trade-item-list.header.productid' })}
                    endAdornment={
                      productData.name && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              navigateProductPath(history, formData.productId);
                            }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel htmlFor="uom" style={{ backgroundColor: 'white' }}>
                    {intl.formatMessage({ id: 'trade-item-list.header.uom' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!Boolean(formData.uom)}
                    id="uom"
                    defaultValue={formData.uom}
                    onChange={(e) => {
                      newFormData.uom = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'trade-item-list.header.uom' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="baseuomdenominator" style={{ backgroundColor: 'white' }}>
                    {intl.formatMessage({ id: 'trade-item-list.header.baseuomdenominator' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!Boolean(formData.baseUomDenominator)}
                    id="baseUomDenominator"
                    defaultValue={formData.baseUomDenominator}
                    onChange={(e) => {
                      newFormData.baseUomDenominator = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'trade-item-list.header.baseuomdenominator' })}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="gtin" style={{ backgroundColor: 'white' }}>
                    {intl.formatMessage({ id: 'trade-item-list.header.gtin' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!Boolean(formData.gtin)}
                    id="gtin"
                    defaultValue={formData.gtin}
                    onChange={(e) => {
                      newFormData.gtin = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'trade-item-list.header.gtin' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="baseuomnominator" style={{ backgroundColor: 'white' }}>
                    {intl.formatMessage({ id: 'trade-item-list.header.baseuomnominator' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!Boolean(formData.baseUomNominator)}
                    id="baseUomNominator"
                    defaultValue={formData.baseUomNominator}
                    onChange={(e) => {
                      newFormData.baseUomNominator = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'trade-item-list.header.baseuomnominator' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" key="Company Prefix" fullWidth>
                  <InputLabel htmlFor="companyprefix" style={{ backgroundColor: 'white' }}>
                    {intl.formatMessage({ id: 'trade-item-list.header.companyprefix' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!Boolean(formData.companyPrefix)}
                    id="companyPrefix"
                    defaultValue={formData.companyPrefix}
                    onChange={(e) => {
                      newFormData.companyPrefix = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'trade-item-list.header.companyprefix' })}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        )}
      />
    );
  }
}

export const TradeItemCompView = injectIntl(TradeItemCompViewImpl);
