/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaLocationRegulatorReportingSystem,
    KvintaLocationRegulatorReportingSystemFromJSON,
    KvintaLocationRegulatorReportingSystemFromJSONTyped,
    KvintaLocationRegulatorReportingSystemToJSON,
    KvintaOperationStatus,
    KvintaOperationStatusFromJSON,
    KvintaOperationStatusFromJSONTyped,
    KvintaOperationStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetListResponseLocationRegulatorReportingSystem
 */
export interface KvintaGetListResponseLocationRegulatorReportingSystem {
    /**
     * 
     * @type {Array<KvintaLocationRegulatorReportingSystem>}
     * @memberof KvintaGetListResponseLocationRegulatorReportingSystem
     */
    data: Array<KvintaLocationRegulatorReportingSystem>;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetListResponseLocationRegulatorReportingSystem
     */
    error?: string | null;
    /**
     * 
     * @type {KvintaOperationStatus}
     * @memberof KvintaGetListResponseLocationRegulatorReportingSystem
     */
    operationStatus?: KvintaOperationStatus;
    /**
     * 
     * @type {number}
     * @memberof KvintaGetListResponseLocationRegulatorReportingSystem
     */
    total?: number | null;
}

export function KvintaGetListResponseLocationRegulatorReportingSystemFromJSON(json: any): KvintaGetListResponseLocationRegulatorReportingSystem {
    return KvintaGetListResponseLocationRegulatorReportingSystemFromJSONTyped(json, false);
}

export function KvintaGetListResponseLocationRegulatorReportingSystemFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetListResponseLocationRegulatorReportingSystem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(KvintaLocationRegulatorReportingSystemFromJSON)),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'operationStatus': !exists(json, 'operationStatus') ? undefined : KvintaOperationStatusFromJSON(json['operationStatus']),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function KvintaGetListResponseLocationRegulatorReportingSystemToJSON(value?: KvintaGetListResponseLocationRegulatorReportingSystem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(KvintaLocationRegulatorReportingSystemToJSON)),
        'error': value.error,
        'operationStatus': KvintaOperationStatusToJSON(value.operationStatus),
        'total': value.total,
    };
}


