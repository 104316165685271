/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaInelMessageBizStep,
    KvintaInelMessageBizStepFromJSON,
    KvintaInelMessageBizStepFromJSONTyped,
    KvintaInelMessageBizStepToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMessageFieldsRequest
 */
export interface KvintaMessageFieldsRequest {
    /**
     * 
     * @type {KvintaInelMessageBizStep}
     * @memberof KvintaMessageFieldsRequest
     */
    bizStep?: KvintaInelMessageBizStep;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageFieldsRequest
     */
    location?: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaMessageFieldsRequest
     */
    timestamp?: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageFieldsRequest
     */
    timezone?: string;
}

export function KvintaMessageFieldsRequestFromJSON(json: any): KvintaMessageFieldsRequest {
    return KvintaMessageFieldsRequestFromJSONTyped(json, false);
}

export function KvintaMessageFieldsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMessageFieldsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bizStep': !exists(json, 'bizStep') ? undefined : KvintaInelMessageBizStepFromJSON(json['bizStep']),
        'location': !exists(json, 'location') ? undefined : json['location'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
    };
}

export function KvintaMessageFieldsRequestToJSON(value?: KvintaMessageFieldsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bizStep': KvintaInelMessageBizStepToJSON(value.bizStep),
        'location': value.location,
        'timestamp': value.timestamp,
        'timezone': value.timezone,
    };
}


