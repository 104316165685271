import { Paper } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { PageContentStore, PAGE_CONTENT_STORE_ID, TabBar } from 'kvinta/components';
import { EpcMessagesStore, EPC_MESSAGES_STORE_ID } from './EpcMessagesStore';
import { HierarchyHistoryStore } from './Hierarchy/HierarchyHistoryStore';
import { Hierarchy } from './Hierarchy/HierarchyV2';
import { generateHierarchy, generateTimelinePoints } from './Hierarchy/utils';
import { escapeId, unescapeId } from './paths';
import { navigateEpcTab, tabs } from './tabs';

interface ViewEpcHierarchyProps {
  intl: any;
  id: string;
  ts: number;
  index?: number;
  history: any;
  epcMessagesStore: EpcMessagesStore;
  pageContentStore: PageContentStore;
}
class ViewEpcHierarchyImpl extends Component<ViewEpcHierarchyProps> {
  componentDidMount() {
    const { width, height } = this.props.pageContentStore;
    this.props.epcMessagesStore.loadHierarchy(unescapeId(this.props.id));
  }
  render() {
    const { width, height } = this.props.pageContentStore;
    const hierarchyHistoryData = this.props.epcMessagesStore.hierarchyHistory;
    if (!width || !height || !hierarchyHistoryData) {
      return null;
    }
    const hierarchyWidth = width - 40; // Paddings left and right
    const hierarchyHeight = height - 230; // remove timeline and Header

    const hierarchyHistory = generateHierarchy({
      width: hierarchyWidth,
      height: hierarchyHeight,
      hierarchyHistoryData: hierarchyHistoryData,
    });
    const timelinePoints = generateTimelinePoints(hierarchyHistoryData);
    if (!timelinePoints || timelinePoints.length < 1) {
      return <>No data available</>;
    }
    const activeTimelinePointId = timelinePoints[timelinePoints.length - 1].id;
    const hierarchyHistoryStore = new HierarchyHistoryStore(hierarchyHistory, timelinePoints, activeTimelinePointId);

    return (
      <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        <TabBar
          onChange={(newValue: any) => {
            navigateEpcTab(
              this.props.history,
              newValue as string,
              unescapeId(this.props.id),
              this.props.ts,
              this.props.index,
            );
          }}
          tabId={'3'}
          tabs={tabs}
        />
        <Hierarchy
          hierarchyHistoryStore={hierarchyHistoryStore}
          epcMessagesStore={this.props.epcMessagesStore}
          width={hierarchyWidth} // fix left padding
          height={hierarchyHeight}
          updateActiveTimelinePoint={(id) => {
            this.props.epcMessagesStore.loadHierarchy(id);
          }}
        />
      </Paper>
    );
  }
}

export const ViewEpcHierarchy = injectIntl(
  inject(EPC_MESSAGES_STORE_ID, PAGE_CONTENT_STORE_ID)(observer(ViewEpcHierarchyImpl)),
);
