/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaListSerialNumberOrderItemsRequest,
    KvintaListSerialNumberOrderItemsRequestFromJSON,
    KvintaListSerialNumberOrderItemsRequestFromJSONTyped,
    KvintaListSerialNumberOrderItemsRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestListSerialNumberOrderItemsRequest
 */
export interface KvintaOperationRequestListSerialNumberOrderItemsRequest {
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestListSerialNumberOrderItemsRequest
     */
    context?: KvintaExecutionContext;
    /**
     * 
     * @type {KvintaListSerialNumberOrderItemsRequest}
     * @memberof KvintaOperationRequestListSerialNumberOrderItemsRequest
     */
    input?: KvintaListSerialNumberOrderItemsRequest;
}

export function KvintaOperationRequestListSerialNumberOrderItemsRequestFromJSON(json: any): KvintaOperationRequestListSerialNumberOrderItemsRequest {
    return KvintaOperationRequestListSerialNumberOrderItemsRequestFromJSONTyped(json, false);
}

export function KvintaOperationRequestListSerialNumberOrderItemsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestListSerialNumberOrderItemsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
        'input': !exists(json, 'input') ? undefined : KvintaListSerialNumberOrderItemsRequestFromJSON(json['input']),
    };
}

export function KvintaOperationRequestListSerialNumberOrderItemsRequestToJSON(value?: KvintaOperationRequestListSerialNumberOrderItemsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': KvintaExecutionContextToJSON(value.context),
        'input': KvintaListSerialNumberOrderItemsRequestToJSON(value.input),
    };
}


