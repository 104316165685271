/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaTextDocumentRecord,
    KvintaTextDocumentRecordFromJSON,
    KvintaTextDocumentRecordFromJSONTyped,
    KvintaTextDocumentRecordToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaTextDocument
 */
export interface KvintaTextDocument {
    /**
     * 
     * @type {string}
     * @memberof KvintaTextDocument
     */
    content: string;
    /**
     * 
     * @type {KvintaTextDocumentRecord}
     * @memberof KvintaTextDocument
     */
    record: KvintaTextDocumentRecord;
}

export function KvintaTextDocumentFromJSON(json: any): KvintaTextDocument {
    return KvintaTextDocumentFromJSONTyped(json, false);
}

export function KvintaTextDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTextDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': json['content'],
        'record': KvintaTextDocumentRecordFromJSON(json['record']),
    };
}

export function KvintaTextDocumentToJSON(value?: KvintaTextDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'record': KvintaTextDocumentRecordToJSON(value.record),
    };
}


