import { FormControl, OutlinedInput } from '@material-ui/core';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import FilterInputLabel from './FilterBar/FilterInputLabel';

interface SearchFieldProps {
  intl?: any;
  onChange: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  searchValue: string;
  autofocus: boolean;
}

export class SearchField extends Component<SearchFieldProps> {
  constructor(props: SearchFieldProps) {
    super(props);
  }

  render() {
    const intl = this.props.intl;
    return (
      <div className={'content-filter'} style={{ paddingLeft: '16px' }}>
        <FormControl variant="outlined" key={'searchField'} style={{ height: '40px' }}>
          <FilterInputLabel htmlFor={'search-field'}>{intl.formatMessage({ id: 'filter.search' })}</FilterInputLabel>
          <OutlinedInput
            style={{ height: '40px' }}
            label={intl.formatMessage({ id: 'filter.search' })}
            placeholder={intl.formatMessage({ id: 'filter.search' })}
            value={this.props.searchValue}
            name="search-field"
            autoFocus={this.props.autofocus}
            id="search-field"
            onChange={(e) => {
              this.props.onChange(e.target.value);
            }}
            onBlur={(e) => {
              if (this.props.onBlur) this.props.onBlur(e);
            }}
          />
        </FormControl>
      </div>
    );
  }
}
