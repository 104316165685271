/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaTradeItem
 */
export interface KvintaTradeItem {
    /**
     * 
     * @type {number}
     * @memberof KvintaTradeItem
     */
    baseUomDenominator?: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaTradeItem
     */
    baseUomNominator?: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaTradeItem
     */
    companyPrefix: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaTradeItem
     */
    gtin: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaTradeItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaTradeItem
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaTradeItem
     */
    uom?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaTradeItem
     */
    updated?: number;
}

export function KvintaTradeItemFromJSON(json: any): KvintaTradeItem {
    return KvintaTradeItemFromJSONTyped(json, false);
}

export function KvintaTradeItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTradeItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'baseUomDenominator': !exists(json, 'baseUomDenominator') ? undefined : json['baseUomDenominator'],
        'baseUomNominator': !exists(json, 'baseUomNominator') ? undefined : json['baseUomNominator'],
        'companyPrefix': json['companyPrefix'],
        'gtin': json['gtin'],
        'id': json['id'],
        'productId': json['productId'],
        'uom': !exists(json, 'uom') ? undefined : json['uom'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function KvintaTradeItemToJSON(value?: KvintaTradeItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'baseUomDenominator': value.baseUomDenominator,
        'baseUomNominator': value.baseUomNominator,
        'companyPrefix': value.companyPrefix,
        'gtin': value.gtin,
        'id': value.id,
        'productId': value.productId,
        'uom': value.uom,
        'updated': value.updated,
    };
}


