import React, { Component } from 'react';
import { ShippingStore, SHIPPING_STORE_ID } from './ShippingStore';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Paper, Grid, Box, FormControl, InputLabel, OutlinedInput, Button } from '@material-ui/core';
import { IconButton, InputAdornment } from '@material-ui/core';
import { tabs } from './tabs';
import { navigateShippingListPath, navigateShippingProductsPath } from './paths';
import { navigateEpcisEventPath } from 'kvinta/modules/epcis/paths';
import { DialogCreateATK } from './DialogCreateATK';
import { navigateATKSummaryPath } from 'kvinta/modules/documents/paths';
import { reaction } from 'mobx';
import { navigateExecutionStatusPath, navigateShippingResultsPath } from 'kvinta/modules/paths';

interface ViewShippingListSummaryProps {
  intl: any;
  history?: any;
  sidebarStore?: KSidebarStore;
  shippingStore: ShippingStore;
  id: string;
}

class ViewShippingListSummaryImpl extends Component<ViewShippingListSummaryProps> {
  disposer: any;

  componentDidMount() {
    this.props.shippingStore.loadShippingEvent(this.props.id);
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.shippingStore.currentShipping,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.epcisEventRecord.eventID, () => {
            navigateShippingListPath(this.props.history);
          });
        }
      },
    );
  }
  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const intl = this.props.intl;
    const currentShipping = this.props.shippingStore.currentShipping;
    if (!currentShipping) {
      return null;
    }
    const hasCreateButton = this.props.shippingStore.hasCreateATKButton;
    const createATKDialogOpen = this.props.shippingStore.createATKDialogOpen;

    return (
      <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              navigateShippingListPath(this.props.history);
            }
            if (newValue === '2') {
              navigateShippingProductsPath(this.props.history, this.props.id);
            }
            if (newValue === '3') {
              navigateShippingResultsPath(this.props.history, this.props.id);
            }
          }}
          tabId={'1'}
          tabs={tabs}
        />
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          <div style={{ marginRight: '15px', marginTop: '15px', float: 'right' }}>
            {hasCreateButton && (
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => {
                  this.props.shippingStore.openCreateATKDialog();
                }}
              >
                {intl.formatMessage({ id: 'shipping-list.header.createatk' })}
              </Button>
            )}
          </div>
          <Grid container>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="eventId">
                    {intl.formatMessage({ id: 'shipping-list.header.eventid' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="eventId"
                    defaultValue={currentShipping.epcisEventRecord.eventID}
                    label="eventId label"
                    endAdornment={
                      currentShipping.epcisEventRecord.eventID && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              navigateEpcisEventPath(this.props.history, currentShipping.epcisEventRecord.eventID);
                            }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="action">
                    {intl.formatMessage({ id: 'shipping-list.header.action' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="action"
                    defaultValue={currentShipping.epcisEventRecord.action}
                    label="action label"
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="readpoint">
                    {intl.formatMessage({ id: 'shipping-list.header.readpoint' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="readpoint"
                    defaultValue={currentShipping.epcisEventRecord.readPoint}
                    label="readpoint label"
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="disposition">
                    {intl.formatMessage({ id: 'shipping-list.header.disposition' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="disposition"
                    defaultValue={currentShipping.epcisEventRecord.disposition}
                    label="city label"
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="createdbyrootexecutionid">
                    {intl.formatMessage({ id: 'shipping-list.header.createdbyrootexecutionid' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="createdbyrootexecutionid"
                    defaultValue={currentShipping.epcisEventRecord.createdByRootExecutionId}
                    label="createdbyrootexecutionid label"
                    endAdornment={
                      currentShipping.epcisEventRecord.createdByRootExecutionId && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              navigateExecutionStatusPath(
                                this.props.history,
                                currentShipping.epcisEventRecord.createdByRootExecutionId,
                              );
                            }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  />
                </FormControl>
              </Box>

              {!hasCreateButton && (
                <Box m={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel style={{ backgroundColor: 'white' }} htmlFor="acd">
                      {intl.formatMessage({ id: 'shipping-list.header.aggregatedcustomsdocument' })}
                    </InputLabel>
                    <OutlinedInput
                      readOnly={true}
                      id="acd"
                      defaultValue={currentShipping.aggregatedCustomsDocumentRecord.resource.id}
                      label="acd label"
                      endAdornment={
                        currentShipping.aggregatedCustomsDocumentRecord.resource.id && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(e) => {
                                navigateATKSummaryPath(
                                  this.props.history,
                                  currentShipping.aggregatedCustomsDocumentRecord.resource.id,
                                );
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    />
                  </FormControl>
                </Box>
              )}
            </Grid>

            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="eventtime">
                    {intl.formatMessage({ id: 'shipping-list.header.eventtime' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="eventtime"
                    defaultValue={
                      currentShipping.epcisEventRecord.eventTime !== null
                        ? new Date(currentShipping.epcisEventRecord.eventTime.epochMillis).toISOString()
                        : ''
                    }
                    label="eventId label"
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="bizstep">
                    {intl.formatMessage({ id: 'shipping-list.header.bizstep' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="bizstep"
                    defaultValue={currentShipping.epcisEventRecord.bizStep}
                    label="bizstep label"
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="bizlocation">
                    {intl.formatMessage({ id: 'shipping-list.header.bizlocation' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="bizlocation"
                    defaultValue={currentShipping.epcisEventRecord.bizLocation}
                    label="bizlocation label"
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="createdbyexecutionid">
                    {intl.formatMessage({ id: 'shipping-list.header.createdbyexecutionid' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="createdbyexecutionid"
                    defaultValue={currentShipping.epcisEventRecord.createdByExecutionId}
                    label="createdbyexecutionid label"
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="documentid">
                    {intl.formatMessage({ id: 'shipping-list.header.documentid' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="documentid"
                    defaultValue={currentShipping.epcisEventRecord.documentId}
                    label="documentid label"
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        {createATKDialogOpen && <DialogCreateATK />}
      </Paper>
    );
  }
}
export const ViewShippingListSummary = injectIntl(
  inject(SHIPPING_STORE_ID, SIDEBAR_STORE_ID)(observer(ViewShippingListSummaryImpl)),
);
