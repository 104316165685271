/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Capture Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaOperationRequestCaptureEpcisDocumentRequest,
    KvintaOperationRequestCaptureEpcisDocumentRequestFromJSON,
    KvintaOperationRequestCaptureEpcisDocumentRequestToJSON,
    KvintaOperationRequestEpcisDocumentRecord,
    KvintaOperationRequestEpcisDocumentRecordFromJSON,
    KvintaOperationRequestEpcisDocumentRecordToJSON,
    KvintaOperationRequestEpcisEvent,
    KvintaOperationRequestEpcisEventFromJSON,
    KvintaOperationRequestEpcisEventToJSON,
    KvintaOperationResponseEpcisDocumentRecord,
    KvintaOperationResponseEpcisDocumentRecordFromJSON,
    KvintaOperationResponseEpcisDocumentRecordToJSON,
    KvintaOperationResponseEpcisEventRecord,
    KvintaOperationResponseEpcisEventRecordFromJSON,
    KvintaOperationResponseEpcisEventRecordToJSON,
    KvintaOperationResponseSplitEpcisDocumentResult,
    KvintaOperationResponseSplitEpcisDocumentResultFromJSON,
    KvintaOperationResponseSplitEpcisDocumentResultToJSON,
    KvintaOperationResponseValidationResult,
    KvintaOperationResponseValidationResultFromJSON,
    KvintaOperationResponseValidationResultToJSON,
} from '../models';

export interface CaptureEpcisDocumentRequest {
    kvintaOperationRequestCaptureEpcisDocumentRequest: KvintaOperationRequestCaptureEpcisDocumentRequest;
}

export interface CaptureEpcisDocumentJsonRequest {
    body: string;
}

export interface CaptureEpcisEventRequest {
    kvintaOperationRequestEpcisEvent: KvintaOperationRequestEpcisEvent;
}

export interface SplitEpcisDocumentRequest {
    kvintaOperationRequestEpcisDocumentRecord: KvintaOperationRequestEpcisDocumentRecord;
}

export interface ValidateEpcisDocumentRequest {
    kvintaOperationRequestEpcisDocumentRecord: KvintaOperationRequestEpcisDocumentRecord;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Stores EPCIS message content in Kvinta cloud. Depending on cloud configuration,  EPCIS events inside this message may bew later reported to L5.   Please use the createdByTaskId field of the returned object to track the overall task status.  See Kvinta Status Store service for more details.   If the message cannot be parsed, or another technical validation fails,  the operationStatus field of the returned object will have value \"ERROR\"  and the error field will have the error description in the error field.
     * Stores EPCIS message content in Kvinta cloud. Depending on cloud configuration,  EPCIS events inside this message may bew later reported to L5.   Please use the createdByTaskId field of the returned object to track the overall task status.  See Kvinta Status Store service for more details.   If the message cannot be parsed, or another technical validation fails,  the operationStatus field of the returned object will have value \"ERROR\"  and the error field will have the error description in the error field.
     */
    async captureEpcisDocumentRaw(requestParameters: CaptureEpcisDocumentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestCaptureEpcisDocumentRequest === null || requestParameters.kvintaOperationRequestCaptureEpcisDocumentRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCaptureEpcisDocumentRequest','Required parameter requestParameters.kvintaOperationRequestCaptureEpcisDocumentRequest was null or undefined when calling captureEpcisDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/captureEpcisDocument`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCaptureEpcisDocumentRequestToJSON(requestParameters.kvintaOperationRequestCaptureEpcisDocumentRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisDocumentRecordFromJSON(jsonValue));
    }

    /**
     * Stores EPCIS message content in Kvinta cloud. Depending on cloud configuration,  EPCIS events inside this message may bew later reported to L5.   Please use the createdByTaskId field of the returned object to track the overall task status.  See Kvinta Status Store service for more details.   If the message cannot be parsed, or another technical validation fails,  the operationStatus field of the returned object will have value \"ERROR\"  and the error field will have the error description in the error field.
     * Stores EPCIS message content in Kvinta cloud. Depending on cloud configuration,  EPCIS events inside this message may bew later reported to L5.   Please use the createdByTaskId field of the returned object to track the overall task status.  See Kvinta Status Store service for more details.   If the message cannot be parsed, or another technical validation fails,  the operationStatus field of the returned object will have value \"ERROR\"  and the error field will have the error description in the error field.
     */
    async captureEpcisDocument(requestParameters: CaptureEpcisDocumentRequest): Promise<KvintaOperationResponseEpcisDocumentRecord> {
        const response = await this.captureEpcisDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Stores EPCIS message content in Kvinta cloud. Depending on cloud configuration,  EPCIS events inside this message may bew later reported to L5.   Please use the createdByTaskId field of the returned object to track the overall task status.  See Kvinta Status Store service for more details.   If the message cannot be parsed, or another technical validation fails,  the operationStatus field of the returned object will have value \"ERROR\"  and the error field will have the error description in the error field.
     * Stores EPCIS message content in Kvinta cloud. Depending on cloud configuration,  EPCIS events inside this message may bew later reported to L5.   Please use the createdByTaskId field of the returned object to track the overall task status.  See Kvinta Status Store service for more details.   If the message cannot be parsed, or another technical validation fails,  the operationStatus field of the returned object will have value \"ERROR\"  and the error field will have the error description in the error field.
     */
    async captureEpcisDocumentJsonRaw(requestParameters: CaptureEpcisDocumentJsonRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisDocumentRecord>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling captureEpcisDocumentJson.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/captureEpcisDocumentJson`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisDocumentRecordFromJSON(jsonValue));
    }

    /**
     * Stores EPCIS message content in Kvinta cloud. Depending on cloud configuration,  EPCIS events inside this message may bew later reported to L5.   Please use the createdByTaskId field of the returned object to track the overall task status.  See Kvinta Status Store service for more details.   If the message cannot be parsed, or another technical validation fails,  the operationStatus field of the returned object will have value \"ERROR\"  and the error field will have the error description in the error field.
     * Stores EPCIS message content in Kvinta cloud. Depending on cloud configuration,  EPCIS events inside this message may bew later reported to L5.   Please use the createdByTaskId field of the returned object to track the overall task status.  See Kvinta Status Store service for more details.   If the message cannot be parsed, or another technical validation fails,  the operationStatus field of the returned object will have value \"ERROR\"  and the error field will have the error description in the error field.
     */
    async captureEpcisDocumentJson(requestParameters: CaptureEpcisDocumentJsonRequest): Promise<KvintaOperationResponseEpcisDocumentRecord> {
        const response = await this.captureEpcisDocumentJsonRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async captureEpcisEventRaw(requestParameters: CaptureEpcisEventRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisEventRecord>> {
        if (requestParameters.kvintaOperationRequestEpcisEvent === null || requestParameters.kvintaOperationRequestEpcisEvent === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestEpcisEvent','Required parameter requestParameters.kvintaOperationRequestEpcisEvent was null or undefined when calling captureEpcisEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/captureEpcisEvent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestEpcisEventToJSON(requestParameters.kvintaOperationRequestEpcisEvent),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisEventRecordFromJSON(jsonValue));
    }

    /**
     */
    async captureEpcisEvent(requestParameters: CaptureEpcisEventRequest): Promise<KvintaOperationResponseEpcisEventRecord> {
        const response = await this.captureEpcisEventRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async splitEpcisDocumentRaw(requestParameters: SplitEpcisDocumentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseSplitEpcisDocumentResult>> {
        if (requestParameters.kvintaOperationRequestEpcisDocumentRecord === null || requestParameters.kvintaOperationRequestEpcisDocumentRecord === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestEpcisDocumentRecord','Required parameter requestParameters.kvintaOperationRequestEpcisDocumentRecord was null or undefined when calling splitEpcisDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/splitEpcisDocument`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestEpcisDocumentRecordToJSON(requestParameters.kvintaOperationRequestEpcisDocumentRecord),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseSplitEpcisDocumentResultFromJSON(jsonValue));
    }

    /**
     */
    async splitEpcisDocument(requestParameters: SplitEpcisDocumentRequest): Promise<KvintaOperationResponseSplitEpcisDocumentResult> {
        const response = await this.splitEpcisDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async validateEpcisDocumentRaw(requestParameters: ValidateEpcisDocumentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseValidationResult>> {
        if (requestParameters.kvintaOperationRequestEpcisDocumentRecord === null || requestParameters.kvintaOperationRequestEpcisDocumentRecord === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestEpcisDocumentRecord','Required parameter requestParameters.kvintaOperationRequestEpcisDocumentRecord was null or undefined when calling validateEpcisDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/validateEpcisDocument`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestEpcisDocumentRecordToJSON(requestParameters.kvintaOperationRequestEpcisDocumentRecord),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseValidationResultFromJSON(jsonValue));
    }

    /**
     */
    async validateEpcisDocument(requestParameters: ValidateEpcisDocumentRequest): Promise<KvintaOperationResponseValidationResult> {
        const response = await this.validateEpcisDocumentRaw(requestParameters);
        return await response.value();
    }

}
