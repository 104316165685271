/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaSystemServiceFunction,
    KvintaSystemServiceFunctionFromJSON,
    KvintaSystemServiceFunctionFromJSONTyped,
    KvintaSystemServiceFunctionToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaExecution
 */
export interface KvintaExecution {
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaExecution
     */
    context: KvintaExecutionContext;
    /**
     * 
     * @type {KvintaSystemServiceFunction}
     * @memberof KvintaExecution
     */
    _function: KvintaSystemServiceFunction;
}

export function KvintaExecutionFromJSON(json: any): KvintaExecution {
    return KvintaExecutionFromJSONTyped(json, false);
}

export function KvintaExecutionFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaExecution {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': KvintaExecutionContextFromJSON(json['context']),
        '_function': KvintaSystemServiceFunctionFromJSON(json['function']),
    };
}

export function KvintaExecutionToJSON(value?: KvintaExecution | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': KvintaExecutionContextToJSON(value.context),
        'function': KvintaSystemServiceFunctionToJSON(value._function),
    };
}


