import { Button, Link } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  PageContentStore,
  tableIcons,
  PAGE_CONTENT_STORE_ID,
  KSidebarStore,
  SIDEBAR_STORE_ID,
} from 'kvinta/components';
import { ATKListStore, STORE_ID } from '../ATKListStore';
import { linkATKSummaryPath, navigateATKSummaryPath } from '../paths';

interface ATKListViewProps {
  atkListStore?: ATKListStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
}

class ATKListViewImpl extends Component<ATKListViewProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ATKListViewProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.atkListStore.fetchData();
  }

  navigateATKSummary = (id: string) => {
    navigateATKSummaryPath(this.props.history, id);
  };

  render() {
    const intl = this.props.intl;
    const { listData, isLoading, page, totalCount, pageSize } = this.props.atkListStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightThin;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <MaterialTable
                icons={tableIcons}
                tableRef={this.tableRef}
                // title={intl.formatMessage({ id: 'menu.atk' })}
                columns={[
                  {
                    title: intl.formatMessage({ id: 'atk-list.header.time' }),
                    field: 'timestamp', // should be timestamp but currently crashes for some reason
                    defaultSort: 'desc',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '25%',
                    },
                    render: (data) => {
                      return (
                        <Link href={linkATKSummaryPath(history, data.resource.id)} onClick={preventDefault}>
                          {data.timestamp.epochMillis ? new Date(data.timestamp.epochMillis).toISOString() : ''}
                        </Link>
                      );
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'atk-list.header.user' }),
                    field: 'context.userId',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '25%',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'atk-list.header.last-status' }),
                    field: 'lastStatus',
                    sorting: false,
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '25%',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'atk-list.header.external-number' }),
                    field: 'externalNumber',
                    sorting: false,
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '25%',
                    },
                  },
                ]}
                data={listData}
                page={page}
                totalCount={totalCount}
                onChangePage={(page: number) => {
                  this.props.atkListStore.fetchPage(page);
                }}
                onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                  this.props.atkListStore.changeOrder(orderBy, orderDirection);
                }}
                isLoading={isLoading}
                options={{
                  actionsColumnIndex: 0,
                  paging: true,
                  sorting: true,
                  draggable: false,
                  search: false,
                  pageSize: pageSize,
                  pageSizeOptions: [pageSize],
                  minBodyHeight: bodyHeight,
                  maxBodyHeight: bodyHeight,
                  showTitle: false,
                  thirdSortClick: false,
                  emptyRowsWhenPaging: false,
                }}
                localization={{
                  header: {
                    actions: '',
                  },
                }}
                onRowClick={(event, rowData) => {
                  if (!(event.target instanceof HTMLInputElement)) {
                    this.navigateATKSummary(rowData.resource.id);
                  }
                }}
                components={{
                  Toolbar: () => null,
                }}
              />
            </div>
          </div>
        )}
      />
    );
  }
}
export const ATKListView = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(ATKListViewImpl)),
);
