import { Button, IconButton, InputAdornment, Link, MenuItem, Select } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CancelIcon from '@material-ui/icons/Cancel';
import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  KSidebarStore,
  PageContentStore,
  PAGE_CONTENT_STORE_ID,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import {
  ExtKvintaExecutionWithStatuses,
  ProcessesStatusStore,
  PROCESSES_STATUS_STORE_ID,
} from './ProcessesStatusStore';
import { FilterBar } from 'kvinta/components';
import { KvintaExecutionStatusType } from 'kvinta/apis/kvinta-status-store';
import {
  navigateExecutionStatusPath,
  navigateExecutionStatusHierarchyPath,
  linkExecutionStatusPath,
} from 'kvinta/modules/paths';

interface ViewListProcessesProps {
  processesStatusStore?: ProcessesStatusStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
}

const servicesList = [
  'kvinta-crpt-connector-functions',
  'kvinta-epcis-capture-functions',
  'kvinta-epcis-document-store',
  'kvinta-arla-extensions',
  'kvinta-serial-number-generator',
  'kvinta-document-store',
  'kvinta-epcis-capture-kafka-dispatcher',
];

class ViewListProcessesImpl extends Component<ViewListProcessesProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ViewListProcessesProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    const statuses = Object.values(KvintaExecutionStatusType).map((value) => ({ value, label: value }));
    const services = servicesList.map((value) => ({ value, label: value }));
    // Don't reset filter if it is initialized
    if (!this.props.processesStatusStore.filter) {
      this.props.processesStatusStore.setFilter([
        {
          field: 'status',
          label: 'execution.status', // TODO: List
          isActive: true,
          value: '',
          render: (props) => {
            return (
              <Select
                id={props.fieldId}
                style={{ height: '40px', minWidth: '200px' }}
                label={props.translatedLabel}
                value={props.value}
                onChange={(e) => {
                  props.store.onChangeValue(props.field, e.target.value as string);
                }}
                endAdornment={
                  props.isFieldEmpty && props.showRemoveButton ? (
                    <InputAdornment position="end">
                      <IconButton
                        // style={{ marginLeft: 6 }}
                        className="removeFilter"
                        onClick={(e) => {
                          props.store.hideFilter(props.field);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : props.isApplied ? (
                    <InputAdornment position="end">
                      <IconButton
                        className="clearFilter"
                        onClick={(e) => {
                          props.store.onClear(props.field);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      {props.showRemoveButton && (
                        <IconButton
                          className="removeFilter"
                          onClick={(e) => {
                            props.store.hideFilter(props.field);
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <IconButton
                        className="clearFilter"
                        onClick={(e) => {
                          props.store.onClear(props.field);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <Button
                        variant="contained"
                        color="primary"
                        className="applyFilter"
                        onClick={(e) => {
                          props.store.onFilter(props.field);
                        }}
                      >
                        {props.intl.formatMessage({ id: 'button.apply' })}
                      </Button>
                      {props.showRemoveButton && (
                        <IconButton
                          className="removeFilter"
                          onClick={(e) => {
                            props.store.hideFilter(props.field);
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  )
                }
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {statuses.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            );
          },
        },
        {
          field: 'service',
          label: 'execution.service',
          isActive: false,
          value: 'kvinta-epcis-capture-functions',
          render: (props) => {
            return (
              <Select
                id={props.fieldId}
                style={{ height: '40px', minWidth: '200px' }}
                label={props.translatedLabel}
                value={props.value}
                onChange={(e) => {
                  props.store.onChangeValue(props.field, e.target.value as string);
                }}
                endAdornment={
                  props.isFieldEmpty ? (
                    <InputAdornment position="end">
                      <IconButton
                        // style={{ marginLeft: 6 }}
                        className="removeFilter"
                        onClick={(e) => {
                          props.store.hideFilter(props.field);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : props.isApplied ? (
                    <InputAdornment position="end">
                      <IconButton
                        className="clearFilter"
                        onClick={(e) => {
                          props.store.onClear(props.field);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <IconButton
                        className="removeFilter"
                        onClick={(e) => {
                          props.store.hideFilter(props.field);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <IconButton
                        className="clearFilter"
                        onClick={(e) => {
                          props.store.onClear(props.field);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <Button
                        variant="contained"
                        color="primary"
                        className="applyFilter"
                        onClick={(e) => {
                          props.store.onFilter(props.field);
                        }}
                      >
                        {props.intl.formatMessage({ id: 'button.apply' })}
                      </Button>
                      <IconButton
                        className="removeFilter"
                        onClick={(e) => {
                          props.store.hideFilter(props.field);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {services.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            );
          },
        },
        {
          field: 'function',
          label: 'execution.function', // TODO: List
          isActive: false,
          value: '',
        },
        {
          field: 'executionId',
          label: 'execution.executionid', // TODO: List
          isActive: false,
          value: '',
        },
      ]);
    }
    this.props.processesStatusStore.fetchData();
  }

  navigateStatusSummary = (history: any, id: string) => {
    navigateExecutionStatusPath(history, id);
  };

  navigateStatusHierarchy = (history: any, id: string) => {
    navigateExecutionStatusHierarchyPath(history, id);
  };

  render() {
    const intl = this.props.intl;
    if (!this.props.processesStatusStore.filter) {
      return null;
    }
    const { listData, isLoading, page, totalCount, pageSize, filter } = this.props.processesStatusStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <MaterialTable
                icons={tableIcons}
                tableRef={this.tableRef}
                // title={intl.formatMessage({ id: 'menu.events' })}
                columns={[
                  {
                    title: intl.formatMessage({ id: 'execution.timestamp' }),
                    field: 'timestamp',
                    defaultSort: 'desc',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                    render: (data: ExtKvintaExecutionWithStatuses) => {
                      return (
                        <Link href={linkExecutionStatusPath(data.id)} onClick={preventDefault}>
                          {data.timestamp}
                        </Link>
                      );
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'execution.status' }),
                    field: 'status',
                    cellStyle: (e, rowData) => {
                      switch (rowData.status) {
                        case 'SUCCESS':
                          return { color: '#00C3A0', fontWeight: 'bold', whiteSpace: 'nowrap' };
                        case 'ERROR':
                          return { color: '#CD0843', fontWeight: 'bold', whiteSpace: 'nowrap' };
                        default:
                          return { color: '#293358', whiteSpace: 'nowrap' };
                      }
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'execution.service' }),
                    field: 'service',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'execution.function' }),
                    field: 'functionName',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'execution.executionid' }),
                    field: 'id',
                    sorting: false,
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                    render: (data: ExtKvintaExecutionWithStatuses) => {
                      return (
                        <Link href={linkExecutionStatusPath(data.id)} onClick={preventDefault}>
                          {data.id}
                        </Link>
                      );
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'execution.error' }),
                    field: 'error',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                ]}
                data={listData}
                page={page}
                totalCount={totalCount}
                onChangePage={(page: number) => {
                  this.props.processesStatusStore.fetchPage(page);
                }}
                onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                  this.props.processesStatusStore.changeOrder(orderBy, orderDirection);
                }}
                isLoading={isLoading}
                options={{
                  actionsColumnIndex: -1,
                  paging: true,
                  sorting: true,
                  draggable: false,
                  search: false,
                  pageSize: pageSize,
                  pageSizeOptions: [pageSize],
                  minBodyHeight: bodyHeight,
                  maxBodyHeight: bodyHeight,
                  emptyRowsWhenPaging: false,
                  rowStyle: (rowData) => ({
                    backgroundColor: rowData.index == 0 ? '#EEE' : '#FFF',
                  }),
                  showTitle: false,
                  thirdSortClick: false,
                }}
                onRowClick={(event, rowData) => {
                  this.navigateStatusSummary(history, rowData.id);
                }}
                components={{
                  Toolbar: (props) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: '20px',
                        marginRight: '20px',
                        marginLeft: '11px',
                      }}
                    >
                      <FilterBar filter={filter} />
                    </div>
                  ),
                }}
              />
            </div>
          </div>
        )}
      />
    );
  }
}
export const ViewListProcesses = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, PROCESSES_STATUS_STORE_ID, SIDEBAR_STORE_ID)(observer(ViewListProcessesImpl)),
);
