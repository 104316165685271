/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaNotificationGroupRecipient,
    KvintaNotificationGroupRecipientFromJSON,
    KvintaNotificationGroupRecipientFromJSONTyped,
    KvintaNotificationGroupRecipientToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetListResultNotificationGroupRecipient
 */
export interface KvintaGetListResultNotificationGroupRecipient {
    /**
     * 
     * @type {Array<KvintaNotificationGroupRecipient>}
     * @memberof KvintaGetListResultNotificationGroupRecipient
     */
    list?: Array<KvintaNotificationGroupRecipient> | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaGetListResultNotificationGroupRecipient
     */
    total?: number | null;
}

export function KvintaGetListResultNotificationGroupRecipientFromJSON(json: any): KvintaGetListResultNotificationGroupRecipient {
    return KvintaGetListResultNotificationGroupRecipientFromJSONTyped(json, false);
}

export function KvintaGetListResultNotificationGroupRecipientFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetListResultNotificationGroupRecipient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': !exists(json, 'list') ? undefined : (json['list'] === null ? null : (json['list'] as Array<any>).map(KvintaNotificationGroupRecipientFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function KvintaGetListResultNotificationGroupRecipientToJSON(value?: KvintaGetListResultNotificationGroupRecipient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': value.list === undefined ? undefined : (value.list === null ? null : (value.list as Array<any>).map(KvintaNotificationGroupRecipientToJSON)),
        'total': value.total,
    };
}


