/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Query Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaQueueAction {
    Ack = 'ACK',
    Retry = 'RETRY',
    Dlq = 'DLQ',
    Stop = 'STOP'
}

export function KvintaQueueActionFromJSON(json: any): KvintaQueueAction {
    return KvintaQueueActionFromJSONTyped(json, false);
}

export function KvintaQueueActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaQueueAction {
    return json as KvintaQueueAction;
}

export function KvintaQueueActionToJSON(value?: KvintaQueueAction | null): any {
    return value as any;
}

