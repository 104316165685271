/* eslint-disable @typescript-eslint/ban-types */
import { Button, Paper, Tab, Tabs } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { navigateCompaniesPath } from '../paths';
import { CompaniesStore, STORE_ID } from './CompaniesStore';
import { CompanyCompView } from './ViewCompanyComp';
import EditIcon from '@material-ui/icons/Edit';
import { KSidebarStore, SIDEBAR_STORE_ID } from 'kvinta/components';
import { reaction, runInAction } from 'mobx';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { IEditState } from 'kvinta/common';

interface CompanySummaryViewProps {
  intl: any;
  companiesStore?: CompaniesStore;
  sidebarStore?: KSidebarStore;
  id: string;
  history?: any;
}

class CompanySummaryViewImpl extends Component<CompanySummaryViewProps, IEditState> {
  disposer: any;
  constructor(props: CompanySummaryViewProps) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }
  async componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    await this.props.companiesStore.fetchCompany(this.props.id);
    this.disposer = reaction(
      () => this.props.companiesStore.currentCompany,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            navigateCompaniesPath(this.props.history);
          });
        }
      },
    );
  }
  componentWillUnmount() {
    this.disposer();
  }

  handleEditOpen = () => {
    this.setState({
      isEditing: true,
    });
    this.props.companiesStore.openUpdateCompany();
  };

  handleEditSubmit = () => {
    this.props.companiesStore.submitUpdateCompany();
    if (!this.props.companiesStore.updateCompanyData.showError) {
      this.setState({
        isEditing: false,
      });
    }
  };

  onEditClose = () => {
    this.setState({
      isEditing: false,
    });
    this.props.companiesStore.fetchCompany(this.props.id);
  };

  render() {
    const comp = this.props.companiesStore.currentCompany;
    if (!comp) {
      return null;
    }

    const intl = this.props.intl;
    const formData = comp;

    const companyData = this.props.companiesStore.updateCompanyData;
    let buttonTitle;
    let buttonIcon;

    if (this.state.isEditing) {
      buttonTitle = 'button.confirm';
      buttonIcon = <DoneIcon color="secondary" />;
    } else {
      buttonTitle = 'button.edit';
      buttonIcon = <EditIcon color="secondary" />;
    }
    return (
      <div>
        <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          <Tabs
            value={'1'}
            indicatorColor="secondary"
            textColor="primary"
            onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
              // Nothing to do - single tab
            }}
            aria-label="simple tabs example"
          >
            <Tab label={intl.formatMessage({ id: 'common.summary' })} value={'1'} id={'1'} />
          </Tabs>
        </Paper>
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          <div style={{ marginRight: '15px', marginTop: '15px', float: 'right' }}>
            {this.state.isEditing ? (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon color="primary" />}
                style={{ marginRight: '10px' }}
                onClick={(e) => {
                  this.onEditClose();
                }}
              >
                {intl.formatMessage({ id: 'button.cancel' })}
              </Button>
            ) : null}
            <Button
              variant="contained"
              color="secondary"
              startIcon={buttonIcon}
              onClick={(e) => {
                if (this.state.isEditing) {
                  this.handleEditSubmit();
                } else {
                  this.handleEditOpen();
                }
              }}
            >
              {intl.formatMessage({ id: buttonTitle })}
            </Button>
          </div>
          <CompanyCompView
            company={formData}
            history={this.props.history}
            isEditing={this.state.isEditing}
            companyData={companyData}
          />
        </Paper>
      </div>
    );
  }
}

export const CompanySummaryView = injectIntl(inject(STORE_ID, SIDEBAR_STORE_ID)(observer(CompanySummaryViewImpl)));
