/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { errorRequired, DevicesStore, DEVICES_STORE_ID, requiredAssignmentFields } from './DevicesStore';
import { KDialogTitle } from 'kvinta/components/KDialog';

interface DialogCreateAssignmentProps {
  devicesStore?: DevicesStore;
  intl: any;
}

class DialogCreateAssignmentImpl extends Component<DialogCreateAssignmentProps> {
  render() {
    const isOpen = this.props.devicesStore.createAssignmentFromOpen;
    const onChangeValue = this.props.devicesStore.onChangeCreateAssignmentField;
    const formData = this.props.devicesStore.createAssignmentData;
    const intl = this.props.intl;
    const showError = formData.showError;
    const apps = this.props.devicesStore.apps;
    const currentDevice = this.props.devicesStore.currentDevice;

    function unique(value, index, self) {
      return self.indexOf(value) === index;
    }
    const appNames = apps
      .map((app) => {
        return app.applicationName;
      })
      .filter(unique);

    const appVersions = apps
      .map((app) => {
        return app.applicationVersion;
      })
      .filter(unique);

    if (!isOpen) {
      return null;
    }

    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={isOpen}
        onClose={() => this.props.devicesStore.closeCreateAssignmentForm()}
      >
        <KDialogTitle id="customized-dialog-title" onClose={() => this.props.devicesStore.closeCreateAssignmentForm()}>
          {intl.formatMessage({ id: 'device-assignment-form.create' })}
        </KDialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="appName">
                    {intl.formatMessage({ id: 'device-assignment-form.appname' })}
                  </InputLabel>
                  <Select
                    id="appName"
                    value={formData.appName}
                    required
                    onChange={(e) => {
                      onChangeValue('appName', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'device-assignment-form.appname' })}
                  >
                    {appNames.map((name, index) => (
                      <MenuItem key={index} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="appVersion">
                    {intl.formatMessage({ id: 'device-assignment-form.appversion' })}
                  </InputLabel>
                  <Select
                    id="appVersion"
                    value={formData.appVersion}
                    required
                    onChange={(e) => {
                      onChangeValue('appVersion', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'device-assignment-form.appversion' })}
                  >
                    {appVersions.map((version, index) => (
                      <MenuItem key={index} value={version}>
                        {version}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="email"
                    required
                    helperText={
                      showError && errorRequired('email', formData.email, requiredAssignmentFields)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('email', formData.email, requiredAssignmentFields)}
                    value={formData.email}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('email', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'device-assignment-form.email' })}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.devicesStore.closeCreateAssignmentForm()}
            color="primary"
            variant="outlined"
          >
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <Button
            onClick={() => {
              this.props.devicesStore.submitCreateAssignmentForm();
            }}
            color="primary"
            variant="contained"
            autoFocus
          >
            {intl.formatMessage({ id: 'button.submit' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const DialogCreateAssignment = injectIntl(inject(DEVICES_STORE_ID)(observer(DialogCreateAssignmentImpl)));
