import MaterialTable, { MTableToolbar } from 'material-table';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  KSidebarStore,
  PageContentStore,
  PAGE_CONTENT_STORE_ID,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { EpcisEventsStore, EPCIS_EVENTS_STORE_ID } from './EpcisEventsStore';
import { FilterBar } from 'kvinta/components';
import { linkEpcisEventPath, navigateEpcisEventJsonPath, navigateEpcisEventPath } from './paths';
import { Link } from '@material-ui/core';

interface ViewListEpcisEventsProps {
  epcisEventsStore?: EpcisEventsStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
}

class ViewListEpcisEventsImpl extends Component<ViewListEpcisEventsProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ViewListEpcisEventsProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.epcisEventsStore.fetchData();
  }

  navigateMessageSummary = (history: any, id: string) => {
    navigateEpcisEventPath(history, id);
  };

  navigateMessageJson = (history: any, id: string) => {
    navigateEpcisEventJsonPath(history, id);
  };

  render() {
    const intl = this.props.intl;
    const { listData, isLoading, page, totalCount, pageSize, filter } = this.props.epcisEventsStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    return (
      <Route
        render={({ history }) => (
          <MaterialTable
            icons={tableIcons}
            tableRef={this.tableRef}
            // title={intl.formatMessage({ id: 'menu.events' })}
            columns={[
              {
                title: intl.formatMessage({ id: 'epcis-event.eventtime' }),
                field: 'eventTimeExt',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
                render: (data) => {
                  return (
                    <Link href={linkEpcisEventPath(history, data.id)} onClick={preventDefault}>
                      {data.eventTime.epochMillis ? new Date(data.eventTime.epochMillis).toISOString() : ''}
                    </Link>
                  );
                },
              },
              {
                title: intl.formatMessage({ id: 'epcis-event.recordtime' }),
                field: 'recordTimeExt',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'epcis-event.action' }),
                field: 'action',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'epcis-event.bizstep' }),
                field: 'bizStep',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'epcis-event.readpoint' }),
                field: 'readPoint',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'epcis-event.bizlocation' }),
                field: 'bizLocation',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'epcis-event.disposition' }),
                field: 'disposition',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'epcis-event.persistentdisposition' }),
                field: 'persistentDisposition',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'epcis-event.user-id' }),
                field: 'userId',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'epcis-event.epc-count' }),
                field: 'epcCount',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'epcis-event.biz-transaction' }),
                field: 'bizTransaction',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'epcis-event.prod-batch' }),
                field: 'prodBatch',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
            ]}
            data={listData}
            page={page}
            totalCount={totalCount}
            onChangePage={(page: number) => {
              this.props.epcisEventsStore.fetchPage(page);
            }}
            onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
              this.props.epcisEventsStore.changeOrder(orderBy, orderDirection);
            }}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: -1,
              paging: true,
              sorting: true,
              draggable: false,
              search: false,
              pageSize: pageSize,
              pageSizeOptions: [pageSize],
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              thirdSortClick: false,
              emptyRowsWhenPaging: false,
            }}
            onRowClick={(event, rowData) => {
              this.navigateMessageSummary(history, rowData.id);
            }}
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                    marginRight: '20px',
                    marginLeft: '11px',
                  }}
                >
                  <FilterBar filter={filter} />
                </div>
              ),
            }}
          />
        )}
      />
    );
  }
}
export const ViewListEpcisEvents = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, EPCIS_EVENTS_STORE_ID, SIDEBAR_STORE_ID)(observer(ViewListEpcisEventsImpl)),
);
