/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaResourceType,
    KvintaResourceTypeFromJSON,
    KvintaResourceTypeFromJSONTyped,
    KvintaResourceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaReadTextDocumentsExternalNumbersRequest
 */
export interface KvintaReadTextDocumentsExternalNumbersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaReadTextDocumentsExternalNumbersRequest
     */
    ids: Array<string>;
    /**
     * 
     * @type {KvintaResourceType}
     * @memberof KvintaReadTextDocumentsExternalNumbersRequest
     */
    type?: KvintaResourceType;
}

export function KvintaReadTextDocumentsExternalNumbersRequestFromJSON(json: any): KvintaReadTextDocumentsExternalNumbersRequest {
    return KvintaReadTextDocumentsExternalNumbersRequestFromJSONTyped(json, false);
}

export function KvintaReadTextDocumentsExternalNumbersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaReadTextDocumentsExternalNumbersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ids': json['ids'],
        'type': !exists(json, 'type') ? undefined : KvintaResourceTypeFromJSON(json['type']),
    };
}

export function KvintaReadTextDocumentsExternalNumbersRequestToJSON(value?: KvintaReadTextDocumentsExternalNumbersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ids': value.ids,
        'type': KvintaResourceTypeToJSON(value.type),
    };
}


