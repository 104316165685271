/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionsFilter,
    KvintaExecutionsFilterFromJSON,
    KvintaExecutionsFilterFromJSONTyped,
    KvintaExecutionsFilterToJSON,
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingFromJSONTyped,
    KvintaPagingToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaListExecutionsRequest
 */
export interface KvintaListExecutionsRequest {
    /**
     * 
     * @type {KvintaExecutionsFilter}
     * @memberof KvintaListExecutionsRequest
     */
    filter?: KvintaExecutionsFilter;
    /**
     * 
     * @type {KvintaPaging}
     * @memberof KvintaListExecutionsRequest
     */
    paging: KvintaPaging;
}

export function KvintaListExecutionsRequestFromJSON(json: any): KvintaListExecutionsRequest {
    return KvintaListExecutionsRequestFromJSONTyped(json, false);
}

export function KvintaListExecutionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaListExecutionsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filter': !exists(json, 'filter') ? undefined : KvintaExecutionsFilterFromJSON(json['filter']),
        'paging': KvintaPagingFromJSON(json['paging']),
    };
}

export function KvintaListExecutionsRequestToJSON(value?: KvintaListExecutionsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filter': KvintaExecutionsFilterToJSON(value.filter),
        'paging': KvintaPagingToJSON(value.paging),
    };
}


