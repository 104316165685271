import { format } from 'date-fns';
import { DefaultApi as ArlaApi, KvintaOperationStatus, KvintaGismtCisInfo } from 'kvinta/apis/kvinta-arla-extensions';
import { TAppOptionsConfig } from 'kvinta/common';
import { NotificationManager } from 'kvinta/modules/main';
import { makeObservable, action, observable, runInAction } from 'mobx';

export interface StinsDialogFormData {
  stins: string;
}

export class SerialNumberInfoStore {
  private _config: TAppOptionsConfig;
  private _arlaApi: ArlaApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  stinsList: string[];
  stinsData: KvintaGismtCisInfo[];

  stinsDialogFormOpen: boolean;
  stinsDialogFormData?: StinsDialogFormData;

  constructor(config: TAppOptionsConfig, notificationManager: NotificationManager, arlaApi: ArlaApi) {
    makeObservable(this, {
      isLoading: observable,
      stinsData: observable,

      stinsDialogFormData: observable,
      stinsDialogFormOpen: observable,
      onChangeStinsDialogFormData: action.bound,
    });

    this._config = config;
    this._arlaApi = arlaApi;
    this._notificationManager = notificationManager;
  }

  formatDateTime(timestamp: number): string {
    return format(new Date(timestamp), this._config.defaultDateTimeFormatting);
  }

  openStinsDialogForm = () => {
    runInAction(() => {
      this.isLoading = true;
      if (!this.stinsDialogFormData) {
        this.stinsDialogFormData = {
          stins: '',
        };
      }
      this.stinsDialogFormOpen = true;
      this.isLoading = false;
    });
  };

  closeStinsDialogForm = () => {
    runInAction(() => {
      this.stinsDialogFormOpen = false;
    });
  };

  onChangeStinsDialogFormData = (value: any) => {
    runInAction(() => {
      this.stinsDialogFormData = {
        stins: value,
      };
    });
  };

  fetchSTINsData = async () => {
    runInAction(() => {
      this.isLoading = true;
      this.stinsList = [];
      this.stinsDialogFormOpen = false;
    });
    const stinsArray = this.stinsDialogFormData.stins.split('\n');
    stinsArray.forEach((entry) => {
      if (entry != '') {
        this.stinsList = [...this.stinsList, entry.trim()];
      }
    });
    this._arlaApi
      .getCisesInfoResult({
        kvintaOperationRequestListString: {
          input: this.stinsList,
        },
      })
      .then((result) => {
        runInAction(() => {
          if (result.status === KvintaOperationStatus.Error) {
            runInAction(() => {
              this.isLoading = false;
              this.stinsList = [];
            });
            this._notificationManager.sendError(result.error);
          } else {
            const list = [] as KvintaGismtCisInfo[];
            for (const entry of result.data) {
              list.push(entry.cisInfo);
            }
            runInAction(() => {
              this.stinsData = list;
              this.isLoading = false;
            });
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };
}

export const SERIAL_NUMBER_INFO_STORE_ID = 'serialNumberInfoStore';
