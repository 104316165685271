/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaResourceType,
    KvintaResourceTypeFromJSON,
    KvintaResourceTypeFromJSONTyped,
    KvintaResourceTypeToJSON,
    KvintaTextDocumentRecord,
    KvintaTextDocumentRecordFromJSON,
    KvintaTextDocumentRecordFromJSONTyped,
    KvintaTextDocumentRecordToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaChildTextDocumentRecordsByParent
 */
export interface KvintaChildTextDocumentRecordsByParent {
    /**
     * 
     * @type {KvintaResourceType}
     * @memberof KvintaChildTextDocumentRecordsByParent
     */
    childType: KvintaResourceType;
    /**
     * 
     * @type {{ [key: string]: Array<KvintaTextDocumentRecord>; }}
     * @memberof KvintaChildTextDocumentRecordsByParent
     */
    map?: { [key: string]: Array<KvintaTextDocumentRecord>; } | null;
}

export function KvintaChildTextDocumentRecordsByParentFromJSON(json: any): KvintaChildTextDocumentRecordsByParent {
    return KvintaChildTextDocumentRecordsByParentFromJSONTyped(json, false);
}

export function KvintaChildTextDocumentRecordsByParentFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaChildTextDocumentRecordsByParent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'childType': KvintaResourceTypeFromJSON(json['childType']),
        'map': !exists(json, 'map') ? undefined : json['map'],
    };
}

export function KvintaChildTextDocumentRecordsByParentToJSON(value?: KvintaChildTextDocumentRecordsByParent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'childType': KvintaResourceTypeToJSON(value.childType),
        'map': value.map,
    };
}


