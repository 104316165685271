import { NotificationManager } from 'kvinta/modules/main';
import { KSidebarStore, SIDEBAR_STORE_ID as SIDEBAR_STORE_ID } from 'kvinta/components/Sidebar/KSidebarStore';
import { UserStore, STORE_ID as USER_STORE_ID } from 'kvinta/modules/main/UserStore';
import { getMenus } from './menu';
import { TAppOptionsConfig } from 'kvinta/common';
import { AuthService } from 'kvinta/service/auth';
import { PageContentStore, PAGE_CONTENT_STORE_ID } from 'kvinta/components';
import {
  createArlaStoreAPI,
  createDevicesAPI,
  createDocumentsStoreAPI,
  createEPCISCaptureAPI,
  createEPCISDocStoreAPI,
  createEPCISQueryAPI,
  createMasterDataAPI,
  createNotificationFunctionsAPI,
  createStatusStoreAPI,
} from 'kvinta/apis/apis';
import { EpcisMessagesStore, EPCIS_MESSAGES_STORE_ID } from 'kvinta/modules/epcis';
import { EpcMessagesStore, EPC_MESSAGES_STORE_ID } from 'kvinta/modules/epcis/epcs';
import { EpcisEventsStore, EPCIS_EVENTS_STORE_ID } from 'kvinta/modules/epcis/events';
import { ShippingStore, SHIPPING_STORE_ID } from 'kvinta/modules/shipping';
import { CompaniesStore, STORE_ID as COMPANIES_STORE_ID } from 'kvinta/modules/master-data/companies';
import { LocationsStore, STORE_ID as LOCATIONS_STORE_ID } from 'kvinta/modules/master-data/locations';
import { ProductsStore, STORE_ID as PRODUCTS_STORE_ID } from 'kvinta/modules/master-data/products';
import { TradeItemsStore, STORE_ID as TRADEITEMS_STORE_ID } from 'kvinta/modules/master-data/trade-items';
import {
  SerialNumberOrdersStore,
  STORE_ID as SERIAL_NUMBER_ORDERS_STORE_ID,
} from 'kvinta/modules/documents/serial-number-orders';
import { ATKListStore, STORE_ID as ATKLIST_STORE_ID } from 'kvinta/modules/documents/atk';
import { CirculationReportsStore, CIRCULATION_REPORTS_STORE_ID } from 'kvinta/modules/documents/circulation-reports';
import { NOTIFICATION_STORE_ID, NotificationStore } from './kvinta/modules/notification-settings/NotificationStore';
import { TechDocStore, TECH_DOC_STORE_ID } from 'kvinta/modules/techdoc';
import { ProcessesStatusStore, PROCESSES_STATUS_STORE_ID } from 'kvinta/modules/processes/execution-statuses';
import {
  SequenceStatusStore,
  SEQUENCE_STATUS_STORE_ID,
} from 'kvinta/modules/processes/sequence-summary/SequenceStatusStore';
import {
  SerialNumberInfoStore,
  SERIAL_NUMBER_INFO_STORE_ID,
} from 'kvinta/modules/processes/serial-number-info/SerialNumberInfoStore';
import { DevicesStore, DEVICES_STORE_ID } from 'kvinta/modules/devices/devices/DevicesStore';
import { VerificationStore, VERIFICATION_STORE_ID } from 'kvinta/modules/shipping/verification-list';

export enum STORES {
  NOTIFICATION_STORE = 'notificationStore',
}

export function createStores(config: TAppOptionsConfig, authS: AuthService) {
  // First create APIs
  const arlaStoreAPI = createArlaStoreAPI(config, authS);
  const devicesStoreAPI = createDevicesAPI(config, authS);
  const documentStoreAPI = createDocumentsStoreAPI(config, authS);
  const ecaptureAPI = createEPCISCaptureAPI(config, authS);
  const edocstoreAPI = createEPCISDocStoreAPI(config, authS);
  const equeryAPI = createEPCISQueryAPI(config, authS);
  const masterDataAPI = createMasterDataAPI(config, authS);
  const statusStoreAPI = createStatusStoreAPI(config, authS);
  const notificationFunctionsAPI = createNotificationFunctionsAPI(config, authS);

  // Create stores and pass their needed stores/APIs
  const notificationManager = new NotificationManager();
  const pageContentStore = new PageContentStore();
  const sidebarStore = new KSidebarStore(getMenus());
  const userStore = new UserStore(config, authS, notificationManager);
  const epcisMessagesStore = new EpcisMessagesStore(
    config,
    notificationManager,
    edocstoreAPI,
    masterDataAPI,
    arlaStoreAPI,
  );
  const epcMessagesStore = new EpcMessagesStore(config, notificationManager, equeryAPI, masterDataAPI);
  const epcisEventsStore = new EpcisEventsStore(config, notificationManager, edocstoreAPI);
  const companiesStore = new CompaniesStore(config, notificationManager, masterDataAPI);
  const locationsStore = new LocationsStore(config, notificationManager, masterDataAPI);
  const productsStore = new ProductsStore(config, notificationManager, masterDataAPI);
  const tradeItemsStore = new TradeItemsStore(config, notificationManager, masterDataAPI);
  const serialNumberOrdersStore = new SerialNumberOrdersStore(
    config,
    notificationManager,
    masterDataAPI,
    documentStoreAPI,
    userStore,
  );
  const atkListStore = new ATKListStore(config, notificationManager, masterDataAPI, documentStoreAPI, arlaStoreAPI);
  const circulationReportsStore = new CirculationReportsStore(
    config,
    notificationManager,
    masterDataAPI,
    documentStoreAPI,
    arlaStoreAPI,
  );
  const processesStatusStore = new ProcessesStatusStore(
    config,
    notificationManager,
    statusStoreAPI,
    documentStoreAPI,
    pageContentStore,
  );
  const sequenceStatusStore = new SequenceStatusStore(
    config,
    notificationManager,
    arlaStoreAPI,
    pageContentStore,
    edocstoreAPI,
  );
  const techDocStore = new TechDocStore(
    config,
    notificationManager,
    statusStoreAPI,
    documentStoreAPI,
    pageContentStore,
  );
  const devicesStore = new DevicesStore(config, notificationManager, devicesStoreAPI, pageContentStore);
  const shippingStore = new ShippingStore(config, notificationManager, arlaStoreAPI, masterDataAPI, pageContentStore);
  const verificationStore = new VerificationStore(config, notificationManager, arlaStoreAPI, edocstoreAPI);
  const serialNumberInfoStore = new SerialNumberInfoStore(config, notificationManager, arlaStoreAPI);
  const notificationStore = new NotificationStore(config, notificationManager, notificationFunctionsAPI);

  return {
    [SIDEBAR_STORE_ID]: sidebarStore,
    [USER_STORE_ID]: userStore,
    [EPC_MESSAGES_STORE_ID]: epcMessagesStore,
    [EPCIS_MESSAGES_STORE_ID]: epcisMessagesStore,
    [EPCIS_EVENTS_STORE_ID]: epcisEventsStore,
    [PROCESSES_STATUS_STORE_ID]: processesStatusStore,
    [SEQUENCE_STATUS_STORE_ID]: sequenceStatusStore,
    [DEVICES_STORE_ID]: devicesStore,
    [TECH_DOC_STORE_ID]: techDocStore,
    [COMPANIES_STORE_ID]: companiesStore,
    [LOCATIONS_STORE_ID]: locationsStore,
    [PRODUCTS_STORE_ID]: productsStore,
    [TRADEITEMS_STORE_ID]: tradeItemsStore,
    [PAGE_CONTENT_STORE_ID]: pageContentStore,
    [SERIAL_NUMBER_ORDERS_STORE_ID]: serialNumberOrdersStore,
    [ATKLIST_STORE_ID]: atkListStore,
    [CIRCULATION_REPORTS_STORE_ID]: circulationReportsStore,
    [SHIPPING_STORE_ID]: shippingStore,
    [VERIFICATION_STORE_ID]: verificationStore,
    [SERIAL_NUMBER_INFO_STORE_ID]: serialNumberInfoStore,
    [NOTIFICATION_STORE_ID]: notificationStore,
  };
}
