import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  ExportButton,
  KSidebarStore,
  PageContentStore,
  PAGE_CONTENT_STORE_ID,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { FilterBar } from 'kvinta/components';
import { DevicesStore, DEVICES_STORE_ID } from './DevicesStore';
import { navigateDeviceSummaryPath } from '../paths';
import { CSVDownload } from 'react-csv';
import { DialogCreateDevice } from './DialogCreateDevice';

interface ListDevicesViewProps {
  devicesStore?: DevicesStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
}

class ListDevicesViewImpl extends Component<ListDevicesViewProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ListDevicesViewProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    // Don't reset filter if it is initialized
    if (!this.props.devicesStore.filter) {
      this.props.devicesStore.setFilter([
        {
          field: 'id',
          label: 'devices-list.id', // TODO: List
          isActive: true,
          value: '',
        },
        {
          field: 'email',
          label: 'devices-list.email', // TODO: List
          isActive: false,
          value: '',
        },
        {
          field: 'locationGln13',
          label: 'devices-list.locationGln13', // TODO: List
          isActive: false,
          value: '',
        },
        {
          field: 'serialNumber',
          label: 'devices-list.serialNumber',
          isActive: false,
          value: '',
        },
      ]);
    }
    this.props.devicesStore.fetchData();
  }

  navigateDevice = (id: string) => {
    navigateDeviceSummaryPath(this.props.history, id);
  };

  render() {
    const intl = this.props.intl;
    if (!this.props.devicesStore.filter) {
      return null;
    }

    const { listData, isLoading, createDeviceFormOpen, exportActive, exportData, page, totalCount, pageSize, filter } =
      this.props.devicesStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <MaterialTable
                icons={tableIcons}
                tableRef={this.tableRef}
                columns={[
                  {
                    title: intl.formatMessage({ id: 'devices-list.id' }),
                    field: 'id',
                    defaultSort: 'desc',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'devices-list.email' }),
                    field: 'email',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'devices-list.locationGln13' }),
                    field: 'locationGln13',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'devices-list.serialNumber' }),
                    field: 'serialNumber',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                ]}
                data={listData}
                page={page}
                totalCount={totalCount}
                onChangePage={(page: number) => {
                  this.props.devicesStore.fetchPage(page);
                }}
                onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                  this.props.devicesStore.changeOrder(orderBy, orderDirection);
                }}
                isLoading={isLoading}
                options={{
                  actionsColumnIndex: 0,
                  paging: true,
                  sorting: true,
                  draggable: false,
                  search: false,
                  pageSize: pageSize,
                  pageSizeOptions: [pageSize],
                  minBodyHeight: bodyHeight,
                  maxBodyHeight: bodyHeight,
                  showTitle: false,
                  thirdSortClick: false,
                  emptyRowsWhenPaging: false,
                }}
                localization={{
                  header: {
                    actions: '',
                  },
                }}
                onRowClick={(event, rowData) => {
                  if (!(event.target instanceof HTMLInputElement)) {
                    this.navigateDevice(rowData.id);
                  }
                }}
                components={{
                  Toolbar: (props) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: '20px',
                        marginRight: '20px',
                        marginLeft: '11px',
                      }}
                    >
                      <FilterBar filter={filter} />
                      <div style={{ marginLeft: '5px' }}>
                        <ExportButton
                          onExportAll={() => this.props.devicesStore.exportAll()}
                          onExportSelected={() => this.props.devicesStore.exportSelected()}
                          hasSelected={this.props.devicesStore.checked > 0}
                        />
                      </div>
                      <div style={{ marginLeft: '5px' }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<AddIcon color="secondary" />}
                          onClick={(e) => {
                            this.props.devicesStore.openCreateDeviceForm();
                          }}
                        >
                          {intl.formatMessage({ id: 'button.create' })}
                        </Button>
                      </div>
                      {createDeviceFormOpen && <DialogCreateDevice />}
                      {exportActive && exportData !== undefined && <CSVDownload data={exportData} target="_blank" />}
                    </div>
                  ),
                }}
              />
            </div>
          </div>
        )}
      />
    );
  }
}
export const ListDevicesView = injectIntl(
  inject(DEVICES_STORE_ID, PAGE_CONTENT_STORE_ID, SIDEBAR_STORE_ID)(observer(ListDevicesViewImpl)),
);
