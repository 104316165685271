/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaCompany,
    KvintaCompanyFromJSON,
    KvintaCompanyFromJSONTyped,
    KvintaCompanyToJSON,
    KvintaCustomer,
    KvintaCustomerFromJSON,
    KvintaCustomerFromJSONTyped,
    KvintaCustomerToJSON,
    KvintaLocation,
    KvintaLocationFromJSON,
    KvintaLocationFromJSONTyped,
    KvintaLocationToJSON,
    KvintaLocationRegulatorReportingSystem,
    KvintaLocationRegulatorReportingSystemFromJSON,
    KvintaLocationRegulatorReportingSystemFromJSONTyped,
    KvintaLocationRegulatorReportingSystemToJSON,
    KvintaProduct,
    KvintaProductFromJSON,
    KvintaProductFromJSONTyped,
    KvintaProductToJSON,
    KvintaTradeItem,
    KvintaTradeItemFromJSON,
    KvintaTradeItemFromJSONTyped,
    KvintaTradeItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMasterData
 */
export interface KvintaMasterData {
    /**
     * 
     * @type {Array<KvintaCompany>}
     * @memberof KvintaMasterData
     */
    companies: Array<KvintaCompany>;
    /**
     * 
     * @type {Array<KvintaCustomer>}
     * @memberof KvintaMasterData
     */
    customers: Array<KvintaCustomer>;
    /**
     * 
     * @type {Array<KvintaLocationRegulatorReportingSystem>}
     * @memberof KvintaMasterData
     */
    locationRegulatorReportingSystems: Array<KvintaLocationRegulatorReportingSystem>;
    /**
     * 
     * @type {Array<KvintaLocation>}
     * @memberof KvintaMasterData
     */
    locations: Array<KvintaLocation>;
    /**
     * 
     * @type {Array<KvintaProduct>}
     * @memberof KvintaMasterData
     */
    products: Array<KvintaProduct>;
    /**
     * 
     * @type {Array<KvintaTradeItem>}
     * @memberof KvintaMasterData
     */
    tradeItems: Array<KvintaTradeItem>;
}

export function KvintaMasterDataFromJSON(json: any): KvintaMasterData {
    return KvintaMasterDataFromJSONTyped(json, false);
}

export function KvintaMasterDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMasterData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companies': ((json['companies'] as Array<any>).map(KvintaCompanyFromJSON)),
        'customers': ((json['customers'] as Array<any>).map(KvintaCustomerFromJSON)),
        'locationRegulatorReportingSystems': ((json['locationRegulatorReportingSystems'] as Array<any>).map(KvintaLocationRegulatorReportingSystemFromJSON)),
        'locations': ((json['locations'] as Array<any>).map(KvintaLocationFromJSON)),
        'products': ((json['products'] as Array<any>).map(KvintaProductFromJSON)),
        'tradeItems': ((json['tradeItems'] as Array<any>).map(KvintaTradeItemFromJSON)),
    };
}

export function KvintaMasterDataToJSON(value?: KvintaMasterData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companies': ((value.companies as Array<any>).map(KvintaCompanyToJSON)),
        'customers': ((value.customers as Array<any>).map(KvintaCustomerToJSON)),
        'locationRegulatorReportingSystems': ((value.locationRegulatorReportingSystems as Array<any>).map(KvintaLocationRegulatorReportingSystemToJSON)),
        'locations': ((value.locations as Array<any>).map(KvintaLocationToJSON)),
        'products': ((value.products as Array<any>).map(KvintaProductToJSON)),
        'tradeItems': ((value.tradeItems as Array<any>).map(KvintaTradeItemToJSON)),
    };
}


