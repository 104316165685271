/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecution,
    KvintaExecutionFromJSON,
    KvintaExecutionFromJSONTyped,
    KvintaExecutionToJSON,
    KvintaExecutionStatus,
    KvintaExecutionStatusFromJSON,
    KvintaExecutionStatusFromJSONTyped,
    KvintaExecutionStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaExecutionWithStatuses
 */
export interface KvintaExecutionWithStatuses {
    /**
     * 
     * @type {KvintaExecution}
     * @memberof KvintaExecutionWithStatuses
     */
    execution: KvintaExecution;
    /**
     * 
     * @type {Array<KvintaExecutionStatus>}
     * @memberof KvintaExecutionWithStatuses
     */
    statuses?: Array<KvintaExecutionStatus> | null;
}

export function KvintaExecutionWithStatusesFromJSON(json: any): KvintaExecutionWithStatuses {
    return KvintaExecutionWithStatusesFromJSONTyped(json, false);
}

export function KvintaExecutionWithStatusesFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaExecutionWithStatuses {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'execution': KvintaExecutionFromJSON(json['execution']),
        'statuses': !exists(json, 'statuses') ? undefined : (json['statuses'] === null ? null : (json['statuses'] as Array<any>).map(KvintaExecutionStatusFromJSON)),
    };
}

export function KvintaExecutionWithStatusesToJSON(value?: KvintaExecutionWithStatuses | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'execution': KvintaExecutionToJSON(value.execution),
        'statuses': value.statuses === undefined ? undefined : (value.statuses === null ? null : (value.statuses as Array<any>).map(KvintaExecutionStatusToJSON)),
    };
}


