/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaGismtSerialNumberStatusType {
    Emitted = 'EMITTED',
    Applied = 'APPLIED',
    Introduced = 'INTRODUCED',
    WrittenOff = 'WRITTEN_OFF',
    Retired = 'RETIRED',
    Withdrawn = 'WITHDRAWN',
    IntroducedReturned = 'INTRODUCED_RETURNED',
    Disaggregation = 'DISAGGREGATION',
    Disaggregated = 'DISAGGREGATED'
}

export function KvintaGismtSerialNumberStatusTypeFromJSON(json: any): KvintaGismtSerialNumberStatusType {
    return KvintaGismtSerialNumberStatusTypeFromJSONTyped(json, false);
}

export function KvintaGismtSerialNumberStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGismtSerialNumberStatusType {
    return json as KvintaGismtSerialNumberStatusType;
}

export function KvintaGismtSerialNumberStatusTypeToJSON(value?: KvintaGismtSerialNumberStatusType | null): any {
    return value as any;
}

