/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDateTimeRange,
    KvintaDateTimeRangeFromJSON,
    KvintaDateTimeRangeFromJSONTyped,
    KvintaDateTimeRangeToJSON,
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingFromJSONTyped,
    KvintaPagingToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaListAggregatedCustomsDocumentsRequest
 */
export interface KvintaListAggregatedCustomsDocumentsRequest {
    /**
     * 
     * @type {KvintaDateTimeRange}
     * @memberof KvintaListAggregatedCustomsDocumentsRequest
     */
    dateTimeRange?: KvintaDateTimeRange;
    /**
     * 
     * @type {KvintaPaging}
     * @memberof KvintaListAggregatedCustomsDocumentsRequest
     */
    paging: KvintaPaging;
}

export function KvintaListAggregatedCustomsDocumentsRequestFromJSON(json: any): KvintaListAggregatedCustomsDocumentsRequest {
    return KvintaListAggregatedCustomsDocumentsRequestFromJSONTyped(json, false);
}

export function KvintaListAggregatedCustomsDocumentsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaListAggregatedCustomsDocumentsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateTimeRange': !exists(json, 'dateTimeRange') ? undefined : KvintaDateTimeRangeFromJSON(json['dateTimeRange']),
        'paging': KvintaPagingFromJSON(json['paging']),
    };
}

export function KvintaListAggregatedCustomsDocumentsRequestToJSON(value?: KvintaListAggregatedCustomsDocumentsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateTimeRange': KvintaDateTimeRangeToJSON(value.dateTimeRange),
        'paging': KvintaPagingToJSON(value.paging),
    };
}


