import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

import React, { Component } from 'react';
import { CirculationReportsStore, CIRCULATION_REPORTS_STORE_ID } from './CirculationReportsStore';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { tabs } from './tabs';
import {
  navigateCirculationReportJsonPath,
  navigateCirculationReportsListPath,
  navigateCirculationReportStatusesPath,
} from './paths';
import { Paper, Grid, Box, FormControl, InputLabel, OutlinedInput, Button, CircularProgress } from '@material-ui/core';
import { IconButton, InputAdornment } from '@material-ui/core';
import { reaction } from 'mobx';
import { navigateExecutionStatusPath } from 'kvinta/modules/paths';

interface ViewCirculationReportSummmaryProps {
  intl: any;
  history?: any;
  sidebarStore?: KSidebarStore;
  circulationReportsStore: CirculationReportsStore;
  id: string;
}
class ViewCirculationReportSummmaryImpl extends Component<ViewCirculationReportSummmaryProps> {
  disposer: any;
  componentDidMount() {
    this.props.circulationReportsStore.fetchCirculationReport(this.props.id);
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.circulationReportsStore.currentCirculationReport,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.record.resource.id, () => {
            navigateCirculationReportsListPath(this.props.history);
          });
        }
      },
    );
  }
  componentWillUnmount() {
    this.disposer();
  }
  render() {
    const intl = this.props.intl;
    const currentCirculationRep = this.props.circulationReportsStore.currentCRExt;
    if (!currentCirculationRep) {
      return null;
    }
    const releaseButtonActive = this.props.circulationReportsStore.releaseButtonActive;
    const isLoading = this.props.circulationReportsStore.isLoading;

    return (
      <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '2') {
              navigateCirculationReportStatusesPath(this.props.history, this.props.id);
            }
            if (newValue === '3') {
              navigateCirculationReportJsonPath(this.props.history, this.props.id);
            }
          }}
          tabId={'1'}
          tabs={tabs}
        />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
            <div style={{ marginRight: '15px', marginTop: '15px', float: 'right' }}>
              <Button
                variant="contained"
                color="secondary"
                disabled={!releaseButtonActive}
                onClick={(e) => {
                  this.props.circulationReportsStore.releaseCurrentCirculationReport();
                }}
              >
                {intl.formatMessage({ id: 'shipping-list.header.release' })}
              </Button>
            </div>
            <Grid container>
              <Grid item xs={6}>
                <Box m={2}>
                  <FormControl variant="outlined" fullWidth>
                    {/*Background color used to fix an overlap bug between title and border */}
                    <InputLabel style={{ backgroundColor: 'white' }} htmlFor="id">
                      {intl.formatMessage({ id: 'circulation-report-form.field.id' })}
                    </InputLabel>
                    <OutlinedInput readOnly={true} id="id" defaultValue={this.props.id} label="eventId label" />
                  </FormControl>
                </Box>

                <Box m={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel style={{ backgroundColor: 'white' }} htmlFor="user">
                      {intl.formatMessage({ id: 'circulation-report-form.field.user' })}
                    </InputLabel>
                    <OutlinedInput
                      readOnly={true}
                      disabled={!Boolean(currentCirculationRep.record.context.userId)}
                      id="user"
                      defaultValue={currentCirculationRep.record.context.userId}
                      label="user label"
                    />
                  </FormControl>
                </Box>

                <Box m={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel style={{ backgroundColor: 'white' }} htmlFor="numberOfSerials">
                      {intl.formatMessage({ id: 'circulation-report-form.field.number-of-serials' })}
                    </InputLabel>
                    <OutlinedInput
                      readOnly={true}
                      disabled={!Boolean(currentCirculationRep.numberOfSerials)}
                      id="numberOfSerials"
                      defaultValue={currentCirculationRep.numberOfSerials}
                      label="numberOfSerials label"
                    />
                  </FormControl>
                </Box>

                <Box m={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel style={{ backgroundColor: 'white' }} htmlFor="declarationNumber">
                      {intl.formatMessage({ id: 'circulation-report-form.field.declaration-number' })}
                    </InputLabel>
                    <OutlinedInput
                      readOnly={true}
                      disabled={!Boolean(currentCirculationRep.declarationNumber)}
                      id="declarationNumber"
                      defaultValue={currentCirculationRep.declarationNumber}
                      label="declarationNumber label"
                    />
                  </FormControl>
                </Box>

                <Box m={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel style={{ backgroundColor: 'white' }} htmlFor="createdbyrootexecution">
                      {intl.formatMessage({ id: 'circulation-report-form.field.created-by-execution' })}
                    </InputLabel>
                    <OutlinedInput
                      readOnly={true}
                      disabled={!Boolean(currentCirculationRep.createdByExecutionId)}
                      id="createdbyrootexecution"
                      defaultValue={currentCirculationRep.createdByExecutionId}
                      label="createdbyrootexecutionid label"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              navigateExecutionStatusPath(
                                this.props.history,
                                currentCirculationRep.record.context.executionId,
                              );
                            }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box m={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel style={{ backgroundColor: 'white' }} htmlFor="timestamp">
                      {intl.formatMessage({ id: 'circulation-report-form.field.time' })}
                    </InputLabel>
                    <OutlinedInput
                      readOnly={true}
                      disabled={!Boolean(currentCirculationRep.timestamp)}
                      id="timestamp"
                      defaultValue={currentCirculationRep.timestamp}
                      label="timestamp label"
                    />
                  </FormControl>
                </Box>
                <Box m={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel style={{ backgroundColor: 'white' }} htmlFor="lastStatus">
                      {intl.formatMessage({ id: 'circulation-report-form.field.last-status' })}
                    </InputLabel>
                    <OutlinedInput
                      readOnly={true}
                      disabled={!Boolean(currentCirculationRep.record.lastStatus)}
                      id="lastStatus"
                      defaultValue={currentCirculationRep.record.lastStatus}
                      label="lastStatus label"
                    />
                  </FormControl>
                </Box>
                <Box m={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel style={{ backgroundColor: 'white' }} htmlFor="tradeParticipantInn">
                      {intl.formatMessage({ id: 'circulation-report-form.field.trade-participant-inn' })}
                    </InputLabel>
                    <OutlinedInput
                      readOnly={true}
                      disabled={!Boolean(currentCirculationRep.tradeParticipantInn)}
                      id="tradeParticipantInn"
                      defaultValue={currentCirculationRep.tradeParticipantInn}
                      label="tradeParticipantInn label"
                    />
                  </FormControl>
                </Box>
                <Box m={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel style={{ backgroundColor: 'white' }} htmlFor="declarationDate">
                      {intl.formatMessage({ id: 'circulation-report-form.field.declaration-date' })}
                    </InputLabel>
                    <OutlinedInput
                      readOnly={true}
                      disabled={!Boolean(currentCirculationRep.declarationDate)}
                      id="declarationDate"
                      defaultValue={currentCirculationRep.declarationDate}
                      label="declarationDate label"
                    />
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Paper>
    );
  }
}
export const ViewCirculationReportSummmary = injectIntl(
  inject(CIRCULATION_REPORTS_STORE_ID, SIDEBAR_STORE_ID)(observer(ViewCirculationReportSummmaryImpl)),
);
