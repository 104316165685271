import * as React from 'react';
import { ChildNode, RestNode, TrackedNode, ParentNode, Link } from './HierarchyNode';
import { ENodeType, THierarchyHistoryNodeParams } from './types';
import { useTooltip } from '@visx/tooltip';
import { Tooltip, TooltipBase, TooltipMenuItem } from 'kvinta/components';
import { useHistory } from 'react-router-dom';
import { navigateEpcMessageHierarchyPath } from '../paths';
import { HierarchyHistoryStore } from './HierarchyHistoryStore';
import { EpcMessagesStore } from '../EpcMessagesStore';

export type HierarchyViewProps = {
  data: THierarchyHistoryNodeParams;
  width: number;
  height: number;
  historyHierarchyStore: HierarchyHistoryStore;
  epcMessagesStore: EpcMessagesStore;
};

export const HierarchyView: React.FunctionComponent<HierarchyViewProps> = ({
  data,
  height,
  width,
  historyHierarchyStore,
  epcMessagesStore,
}: HierarchyViewProps) => {
  const history = useHistory();
  const [tooltipShouldDetectBounds, setTooltipShouldDetectBounds] = React.useState(true);
  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipLeft = 0,
    tooltipTop = 0,
    tooltipData,
  } = useTooltip<any>({
    tooltipOpen: false,
    tooltipLeft: width / 3,
    tooltipTop: height / 3,
    tooltipData: '',
  });

  async function handleCopy(e, text) {
    e.stopPropagation();
    e.preventDefault();

    await navigator.clipboard
      .writeText(text)
      .then(hideTooltip)
      .catch((e) => console.log('handleCopy error', e));
  }

  function goToEpc(e, epc: string) {
    hideTooltip();
    epcMessagesStore.loadHierarchy(epc);
    navigateEpcMessageHierarchyPath(history, epc, 0);
  }

  const handleMouseClick = React.useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      const tooltipLeft = 'clientX' in event ? event.clientX : 0;
      const tooltipTop = 'clientY' in event ? event.clientY : 0;
      const nodeId = (event.target as Element).getAttribute('data-node-id');
      const nodeType = (event.target as Element).getAttribute('data-node-type');
      console.log(
        'tooltipLeft',
        tooltipLeft,
        'tooltipTop',
        tooltipTop,
        'event.target',
        event.target,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (event as any).pageX!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (event as any).clientX!,
      );

      if (nodeId !== null) {
        showTooltip({
          tooltipLeft,
          tooltipTop,
          tooltipData: (
            <TooltipBase nodeId={nodeId}>
              <TooltipMenuItem onClick={handleCopy} text={'Copy Epc to clipboard'} nodeId={nodeId} />
              {nodeType !== ENodeType.TRACKED && (
                <TooltipMenuItem onClick={goToEpc} text={'Go to this Epc'} nodeId={nodeId} />
              )}
            </TooltipBase>
          ),
        });
      } else {
        hideTooltip();
      }
    },

    [showTooltip, tooltipShouldDetectBounds],
  );
  if (!data) {
    return null;
  }

  return width < 200 ? null : (
    <>
      <svg width={width} height={height} onClick={handleMouseClick}>
        {data.links.map((linkData) => {
          return <Link {...linkData} key={linkData.key} />;
        })}
        {data.parent && <ParentNode {...data.parent} />}
        {data.children &&
          data.children.map((childNodeParams) => {
            switch (childNodeParams.nodeType) {
              case ENodeType.TO_PREV_PAGE:
                return <RestNode onClick={() => historyHierarchyStore.goToPrevPage()} {...childNodeParams} />;
              case ENodeType.TO_NEXT_PAGE:
                return <RestNode onClick={() => historyHierarchyStore.goToNextPage()} {...childNodeParams} />;
              case ENodeType.CHILD:
              default:
                return <ChildNode {...childNodeParams} />;
            }
          })}
        <TrackedNode {...data.tracked} />
      </svg>
      {tooltipOpen ? <Tooltip tooltipLeft={tooltipLeft} tooltipTop={tooltipTop} tooltipData={tooltipData} /> : null}
    </>
  );
};
