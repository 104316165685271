/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { countries } from 'kvinta/common/countries';
import { errorRequired, LocationsStore, STORE_ID } from './LocationsStore';
import { KDialogTitle } from 'kvinta/components/KDialog';

interface DialogCreateLocationProps {
  locationsStore?: LocationsStore;
  intl: any;
}

class DialogCreateLocationImpl extends Component<DialogCreateLocationProps> {
  render() {
    const isOpen = this.props.locationsStore.locationFormOpen;
    const onChangeValue = this.props.locationsStore.onChangeCreateLocationField;
    const companies = this.props.locationsStore.companies;
    const formData = this.props.locationsStore.createLocationData;
    const intl = this.props.intl;
    const showError = formData.showError;

    if (!isOpen) {
      return null;
    }
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={isOpen}
        onClose={() => this.props.locationsStore.closeCreateLocationForm()}
      >
        <KDialogTitle id="customized-dialog-title" onClose={() => this.props.locationsStore.closeCreateLocationForm()}>
          {intl.formatMessage({ id: 'location-create-form.field.create' })}
        </KDialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="company">
                    {intl.formatMessage({ id: 'location-create-form.field.company' })}
                  </InputLabel>
                  <Select
                    id="company"
                    value={formData.company}
                    required
                    onChange={(e) => {
                      onChangeValue('company', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'location-create-form.field.company' })}
                  >
                    {companies.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="gln13"
                    // required
                    helperText={
                      showError && errorRequired('gln13', formData.gln13)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('gln13', formData.gln13)}
                    value={formData.gln13}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('gln13', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'location-create-form.field.gln13' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="latitude"
                    // required
                    helperText={
                      showError && errorRequired('latitude', formData.latitude)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('latitude', formData.latitude)}
                    value={formData.latitude}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('latitude', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'location-create-form.field.latitude' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="description"
                    // required
                    helperText={
                      showError && errorRequired('description', formData.description)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('description', formData.description)}
                    value={formData.description}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('description', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'location-create-form.field.description' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="city"
                    // required
                    helperText={
                      showError && errorRequired('city', formData.city)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('city', formData.city)}
                    value={formData.city}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('city', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'location-create-form.field.city' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="address1"
                    // required
                    helperText={
                      showError && errorRequired('address1', formData.address1)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('address1', formData.address1)}
                    value={formData.address1}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('address1', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'location-create-form.field.address1' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="address3"
                    // required
                    helperText={
                      showError && errorRequired('address3', formData.address3)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('address3', formData.address3)}
                    value={formData.address3}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('address3', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'location-create-form.field.address3' })}
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="name"
                    required
                    helperText={
                      showError && errorRequired('name', formData.name)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('name', formData.name)}
                    value={formData.name}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('name', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'location-create-form.field.name' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="sgln"
                    // required
                    helperText={
                      showError && errorRequired('sgln', formData.sgln)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('sgln', formData.sgln)}
                    value={formData.sgln}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('sgln', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'location-create-form.field.sgln' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="longitude"
                    // required
                    helperText={
                      showError && errorRequired('longitude', formData.longitude)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('longitude', formData.longitude)}
                    value={formData.longitude}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('longitude', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'location-create-form.field.longitude' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="country">
                    {intl.formatMessage({ id: 'location-create-form.field.country' })}
                  </InputLabel>
                  <Select
                    id="country"
                    value={formData.country}
                    onChange={(e) => {
                      onChangeValue('country', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'location-create-form.field.country' })}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.shortCode} value={country.shortCode}>
                        <FormattedMessage id={country.countryName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="postalCode"
                    // required
                    helperText={
                      showError && errorRequired('postalCode', formData.postalCode)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('postalCode', formData.postalCode)}
                    value={formData.postalCode}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('postalCode', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'location-create-form.field.postalcode' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="address2"
                    // required
                    helperText={
                      showError && errorRequired('address2', formData.address2)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('address2', formData.address2)}
                    value={formData.address2}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('address2', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'location-create-form.field.address2' })}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.locationsStore.closeCreateLocationForm()}
            color="primary"
            variant="outlined"
          >
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <Button
            onClick={() => {
              this.props.locationsStore.submitCreateLocationForm();
            }}
            color="primary"
            variant="contained"
            // disabled={!formData.isValid}
            autoFocus
          >
            {intl.formatMessage({ id: 'button.submit' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const DialogCreateLocation = injectIntl(inject(STORE_ID)(observer(DialogCreateLocationImpl)));
