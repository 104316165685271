/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaOperationRequestFindEmailTemplateRequest,
    KvintaOperationRequestFindEmailTemplateRequestFromJSON,
    KvintaOperationRequestFindEmailTemplateRequestFromJSONTyped,
    KvintaOperationRequestFindEmailTemplateRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaInlineObject1
 */
export interface KvintaInlineObject1 {
    /**
     * 
     * @type {KvintaOperationRequestFindEmailTemplateRequest}
     * @memberof KvintaInlineObject1
     */
    request?: KvintaOperationRequestFindEmailTemplateRequest;
}

export function KvintaInlineObject1FromJSON(json: any): KvintaInlineObject1 {
    return KvintaInlineObject1FromJSONTyped(json, false);
}

export function KvintaInlineObject1FromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaInlineObject1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request': !exists(json, 'request') ? undefined : KvintaOperationRequestFindEmailTemplateRequestFromJSON(json['request']),
    };
}

export function KvintaInlineObject1ToJSON(value?: KvintaInlineObject1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request': KvintaOperationRequestFindEmailTemplateRequestToJSON(value.request),
    };
}


