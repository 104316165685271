/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Capture Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisAction,
    KvintaEpcisActionFromJSON,
    KvintaEpcisActionFromJSONTyped,
    KvintaEpcisActionToJSON,
    KvintaEpcisBizStep,
    KvintaEpcisBizStepFromJSON,
    KvintaEpcisBizStepFromJSONTyped,
    KvintaEpcisBizStepToJSON,
    KvintaTimestamp,
    KvintaTimestampFromJSON,
    KvintaTimestampFromJSONTyped,
    KvintaTimestampToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcisEventRecord
 */
export interface KvintaEpcisEventRecord {
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    eventID?: string | null;
    /**
     * 
     * @type {KvintaTimestamp}
     * @memberof KvintaEpcisEventRecord
     */
    eventTime?: KvintaTimestamp;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    eventTimeZoneOffset?: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcisEventRecord
     */
    recordTime?: number | null;
    /**
     * 
     * @type {KvintaEpcisAction}
     * @memberof KvintaEpcisEventRecord
     */
    action?: KvintaEpcisAction;
    /**
     * 
     * @type {KvintaEpcisBizStep}
     * @memberof KvintaEpcisEventRecord
     */
    bizStep?: KvintaEpcisBizStep;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    readPoint?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    bizLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    disposition?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    persistentDisposition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    parentID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    transformationID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    messageId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    createdByTaskId?: string | null;
}

export function KvintaEpcisEventRecordFromJSON(json: any): KvintaEpcisEventRecord {
    return KvintaEpcisEventRecordFromJSONTyped(json, false);
}

export function KvintaEpcisEventRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisEventRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'eventID': !exists(json, 'eventID') ? undefined : json['eventID'],
        'eventTime': !exists(json, 'eventTime') ? undefined : KvintaTimestampFromJSON(json['eventTime']),
        'eventTimeZoneOffset': !exists(json, 'eventTimeZoneOffset') ? undefined : json['eventTimeZoneOffset'],
        'recordTime': !exists(json, 'recordTime') ? undefined : json['recordTime'],
        'action': !exists(json, 'action') ? undefined : KvintaEpcisActionFromJSON(json['action']),
        'bizStep': !exists(json, 'bizStep') ? undefined : KvintaEpcisBizStepFromJSON(json['bizStep']),
        'readPoint': !exists(json, 'readPoint') ? undefined : json['readPoint'],
        'bizLocation': !exists(json, 'bizLocation') ? undefined : json['bizLocation'],
        'disposition': !exists(json, 'disposition') ? undefined : json['disposition'],
        'persistentDisposition': !exists(json, 'persistentDisposition') ? undefined : json['persistentDisposition'],
        'parentID': !exists(json, 'parentID') ? undefined : json['parentID'],
        'transformationID': !exists(json, 'transformationID') ? undefined : json['transformationID'],
        'messageId': !exists(json, 'messageId') ? undefined : json['messageId'],
        'createdByTaskId': !exists(json, 'createdByTaskId') ? undefined : json['createdByTaskId'],
    };
}

export function KvintaEpcisEventRecordToJSON(value?: KvintaEpcisEventRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'eventID': value.eventID,
        'eventTime': KvintaTimestampToJSON(value.eventTime),
        'eventTimeZoneOffset': value.eventTimeZoneOffset,
        'recordTime': value.recordTime,
        'action': KvintaEpcisActionToJSON(value.action),
        'bizStep': KvintaEpcisBizStepToJSON(value.bizStep),
        'readPoint': value.readPoint,
        'bizLocation': value.bizLocation,
        'disposition': value.disposition,
        'persistentDisposition': value.persistentDisposition,
        'parentID': value.parentID,
        'transformationID': value.transformationID,
        'messageId': value.messageId,
        'createdByTaskId': value.createdByTaskId,
    };
}


