/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaCreateCompanyRequest,
    KvintaCreateCompanyRequestFromJSON,
    KvintaCreateCompanyRequestFromJSONTyped,
    KvintaCreateCompanyRequestToJSON,
    KvintaCreateCustomerRequest,
    KvintaCreateCustomerRequestFromJSON,
    KvintaCreateCustomerRequestFromJSONTyped,
    KvintaCreateCustomerRequestToJSON,
    KvintaCreateLocationRegulatorReportingSystemRequest,
    KvintaCreateLocationRegulatorReportingSystemRequestFromJSON,
    KvintaCreateLocationRegulatorReportingSystemRequestFromJSONTyped,
    KvintaCreateLocationRegulatorReportingSystemRequestToJSON,
    KvintaCreateLocationRequest,
    KvintaCreateLocationRequestFromJSON,
    KvintaCreateLocationRequestFromJSONTyped,
    KvintaCreateLocationRequestToJSON,
    KvintaCreateProductRequest,
    KvintaCreateProductRequestFromJSON,
    KvintaCreateProductRequestFromJSONTyped,
    KvintaCreateProductRequestToJSON,
    KvintaCreateTradeItemRequest,
    KvintaCreateTradeItemRequestFromJSON,
    KvintaCreateTradeItemRequestFromJSONTyped,
    KvintaCreateTradeItemRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMasterDataUploadRequest
 */
export interface KvintaMasterDataUploadRequest {
    /**
     * 
     * @type {Array<KvintaCreateCompanyRequest>}
     * @memberof KvintaMasterDataUploadRequest
     */
    companies: Array<KvintaCreateCompanyRequest>;
    /**
     * 
     * @type {Array<KvintaCreateCustomerRequest>}
     * @memberof KvintaMasterDataUploadRequest
     */
    customers: Array<KvintaCreateCustomerRequest>;
    /**
     * 
     * @type {Array<KvintaCreateLocationRegulatorReportingSystemRequest>}
     * @memberof KvintaMasterDataUploadRequest
     */
    locationRegulatorReportingSystems: Array<KvintaCreateLocationRegulatorReportingSystemRequest>;
    /**
     * 
     * @type {Array<KvintaCreateLocationRequest>}
     * @memberof KvintaMasterDataUploadRequest
     */
    locations: Array<KvintaCreateLocationRequest>;
    /**
     * 
     * @type {Array<KvintaCreateProductRequest>}
     * @memberof KvintaMasterDataUploadRequest
     */
    products: Array<KvintaCreateProductRequest>;
    /**
     * 
     * @type {Array<KvintaCreateTradeItemRequest>}
     * @memberof KvintaMasterDataUploadRequest
     */
    tradeItems: Array<KvintaCreateTradeItemRequest>;
}

export function KvintaMasterDataUploadRequestFromJSON(json: any): KvintaMasterDataUploadRequest {
    return KvintaMasterDataUploadRequestFromJSONTyped(json, false);
}

export function KvintaMasterDataUploadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMasterDataUploadRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companies': ((json['companies'] as Array<any>).map(KvintaCreateCompanyRequestFromJSON)),
        'customers': ((json['customers'] as Array<any>).map(KvintaCreateCustomerRequestFromJSON)),
        'locationRegulatorReportingSystems': ((json['locationRegulatorReportingSystems'] as Array<any>).map(KvintaCreateLocationRegulatorReportingSystemRequestFromJSON)),
        'locations': ((json['locations'] as Array<any>).map(KvintaCreateLocationRequestFromJSON)),
        'products': ((json['products'] as Array<any>).map(KvintaCreateProductRequestFromJSON)),
        'tradeItems': ((json['tradeItems'] as Array<any>).map(KvintaCreateTradeItemRequestFromJSON)),
    };
}

export function KvintaMasterDataUploadRequestToJSON(value?: KvintaMasterDataUploadRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companies': ((value.companies as Array<any>).map(KvintaCreateCompanyRequestToJSON)),
        'customers': ((value.customers as Array<any>).map(KvintaCreateCustomerRequestToJSON)),
        'locationRegulatorReportingSystems': ((value.locationRegulatorReportingSystems as Array<any>).map(KvintaCreateLocationRegulatorReportingSystemRequestToJSON)),
        'locations': ((value.locations as Array<any>).map(KvintaCreateLocationRequestToJSON)),
        'products': ((value.products as Array<any>).map(KvintaCreateProductRequestToJSON)),
        'tradeItems': ((value.tradeItems as Array<any>).map(KvintaCreateTradeItemRequestToJSON)),
    };
}


