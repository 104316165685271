/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaInlineObject,
    KvintaInlineObjectFromJSON,
    KvintaInlineObjectToJSON,
    KvintaOperationRequestAggregatedCustomsDocument,
    KvintaOperationRequestAggregatedCustomsDocumentFromJSON,
    KvintaOperationRequestAggregatedCustomsDocumentToJSON,
    KvintaOperationRequestCreateATKForShipmentEventRequest,
    KvintaOperationRequestCreateATKForShipmentEventRequestFromJSON,
    KvintaOperationRequestCreateATKForShipmentEventRequestToJSON,
    KvintaOperationRequestCreateCirculationReportForAtkRequest,
    KvintaOperationRequestCreateCirculationReportForAtkRequestFromJSON,
    KvintaOperationRequestCreateCirculationReportForAtkRequestToJSON,
    KvintaOperationRequestCreateCirculationReportForEpcMetadataListRequest,
    KvintaOperationRequestCreateCirculationReportForEpcMetadataListRequestFromJSON,
    KvintaOperationRequestCreateCirculationReportForEpcMetadataListRequestToJSON,
    KvintaOperationRequestListSequenceStatusesRequest,
    KvintaOperationRequestListSequenceStatusesRequestFromJSON,
    KvintaOperationRequestListSequenceStatusesRequestToJSON,
    KvintaOperationRequestListShipmentAggregatedCustomsDocumentsRequest,
    KvintaOperationRequestListShipmentAggregatedCustomsDocumentsRequestFromJSON,
    KvintaOperationRequestListShipmentAggregatedCustomsDocumentsRequestToJSON,
    KvintaOperationRequestListString,
    KvintaOperationRequestListStringFromJSON,
    KvintaOperationRequestListStringToJSON,
    KvintaOperationRequestListVerificationEventsRequest,
    KvintaOperationRequestListVerificationEventsRequestFromJSON,
    KvintaOperationRequestListVerificationEventsRequestToJSON,
    KvintaOperationRequestLong,
    KvintaOperationRequestLongFromJSON,
    KvintaOperationRequestLongToJSON,
    KvintaOperationRequestObject,
    KvintaOperationRequestObjectFromJSON,
    KvintaOperationRequestObjectToJSON,
    KvintaOperationRequestOmsUtilisationReportRequest,
    KvintaOperationRequestOmsUtilisationReportRequestFromJSON,
    KvintaOperationRequestOmsUtilisationReportRequestToJSON,
    KvintaOperationRequestReadEpcisEventWithProductsRequest,
    KvintaOperationRequestReadEpcisEventWithProductsRequestFromJSON,
    KvintaOperationRequestReadEpcisEventWithProductsRequestToJSON,
    KvintaOperationRequestReadShipmentAggregatedCustomsDocumentsRequest,
    KvintaOperationRequestReadShipmentAggregatedCustomsDocumentsRequestFromJSON,
    KvintaOperationRequestReadShipmentAggregatedCustomsDocumentsRequestToJSON,
    KvintaOperationRequestResource,
    KvintaOperationRequestResourceFromJSON,
    KvintaOperationRequestResourceToJSON,
    KvintaOperationRequestString,
    KvintaOperationRequestStringFromJSON,
    KvintaOperationRequestStringToJSON,
    KvintaOperationRequestUploadInelMessageRequest,
    KvintaOperationRequestUploadInelMessageRequestFromJSON,
    KvintaOperationRequestUploadInelMessageRequestToJSON,
    KvintaOperationResponseEpcisDocumentRecord,
    KvintaOperationResponseEpcisDocumentRecordFromJSON,
    KvintaOperationResponseEpcisDocumentRecordToJSON,
    KvintaOperationResponseEpcisEventWithProducts,
    KvintaOperationResponseEpcisEventWithProductsFromJSON,
    KvintaOperationResponseEpcisEventWithProductsToJSON,
    KvintaOperationResponseGetListResultEpcisEventRecord,
    KvintaOperationResponseGetListResultEpcisEventRecordFromJSON,
    KvintaOperationResponseGetListResultEpcisEventRecordToJSON,
    KvintaOperationResponseGetListResultSequenceStatusDependencyRecord,
    KvintaOperationResponseGetListResultSequenceStatusDependencyRecordFromJSON,
    KvintaOperationResponseGetListResultSequenceStatusDependencyRecordToJSON,
    KvintaOperationResponseGetListResultSequenceStatusRecord,
    KvintaOperationResponseGetListResultSequenceStatusRecordFromJSON,
    KvintaOperationResponseGetListResultSequenceStatusRecordToJSON,
    KvintaOperationResponseGetListResultSequenceSummary,
    KvintaOperationResponseGetListResultSequenceSummaryFromJSON,
    KvintaOperationResponseGetListResultSequenceSummaryToJSON,
    KvintaOperationResponseGetListResultShipmentAggregatedCustomsDocument,
    KvintaOperationResponseGetListResultShipmentAggregatedCustomsDocumentFromJSON,
    KvintaOperationResponseGetListResultShipmentAggregatedCustomsDocumentToJSON,
    KvintaOperationResponseGismtDocumentProcessingStatusType,
    KvintaOperationResponseGismtDocumentProcessingStatusTypeFromJSON,
    KvintaOperationResponseGismtDocumentProcessingStatusTypeToJSON,
    KvintaOperationResponseListEpcVerificationResult,
    KvintaOperationResponseListEpcVerificationResultFromJSON,
    KvintaOperationResponseListEpcVerificationResultToJSON,
    KvintaOperationResponseListGismtCisesInfoResult,
    KvintaOperationResponseListGismtCisesInfoResultFromJSON,
    KvintaOperationResponseListGismtCisesInfoResultToJSON,
    KvintaOperationResponseListGismtGetSerialNumberInfoResponse,
    KvintaOperationResponseListGismtGetSerialNumberInfoResponseFromJSON,
    KvintaOperationResponseListGismtGetSerialNumberInfoResponseToJSON,
    KvintaOperationResponseShipmentAggregatedCustomsDocument,
    KvintaOperationResponseShipmentAggregatedCustomsDocumentFromJSON,
    KvintaOperationResponseShipmentAggregatedCustomsDocumentToJSON,
    KvintaOperationResponseString,
    KvintaOperationResponseStringFromJSON,
    KvintaOperationResponseStringToJSON,
    KvintaOperationResponseTextDocumentRecord,
    KvintaOperationResponseTextDocumentRecordFromJSON,
    KvintaOperationResponseTextDocumentRecordToJSON,
} from '../models';

export interface CaptureOmsUtilisationRequest {
    kvintaOperationRequestOmsUtilisationReportRequest: KvintaOperationRequestOmsUtilisationReportRequest;
}

export interface CaptureOmsUtilisationRRequest {
    kvintaOperationRequestResource: KvintaOperationRequestResource;
}

export interface CreateATKForShipmentEventRequest {
    kvintaOperationRequestCreateATKForShipmentEventRequest: KvintaOperationRequestCreateATKForShipmentEventRequest;
}

export interface ExportEventProductsListToCsvRequest {
    kvintaOperationRequestReadEpcisEventWithProductsRequest: KvintaOperationRequestReadEpcisEventWithProductsRequest;
}

export interface GetCisesInfoResultRequest {
    kvintaOperationRequestListString: KvintaOperationRequestListString;
}

export interface GetSequenceStatusDependenciesRequest {
    kvintaOperationRequestLong: KvintaOperationRequestLong;
}

export interface GetSerialsNumbersInfoRequest {
    kvintaOperationRequestListString: KvintaOperationRequestListString;
}

export interface ListSequenceStatusesRequest {
    kvintaOperationRequestListSequenceStatusesRequest: KvintaOperationRequestListSequenceStatusesRequest;
}

export interface ListShipmentEventsWithAggregatedCustomsDocumentsRequest {
    kvintaOperationRequestListShipmentAggregatedCustomsDocumentsRequest: KvintaOperationRequestListShipmentAggregatedCustomsDocumentsRequest;
}

export interface ListVerificationEventsRequest {
    kvintaOperationRequestListVerificationEventsRequest: KvintaOperationRequestListVerificationEventsRequest;
}

export interface ReadShipmentEventProductsRequest {
    kvintaOperationRequestReadEpcisEventWithProductsRequest: KvintaOperationRequestReadEpcisEventWithProductsRequest;
}

export interface ReadShipmentEventWithAggregatedCustomsDocumentsRequest {
    kvintaOperationRequestReadShipmentAggregatedCustomsDocumentsRequest: KvintaOperationRequestReadShipmentAggregatedCustomsDocumentsRequest;
}

export interface ReadVerificationEventResultRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReleaseAggregatedCustomsDocumentRequest {
    kvintaOperationRequestAggregatedCustomsDocument: KvintaOperationRequestAggregatedCustomsDocument;
}

export interface ReleaseAggregatedCustomsDocumentRRequest {
    kvintaOperationRequestResource: KvintaOperationRequestResource;
}

export interface ReleaseIntroduceIntoCirculationDocumentRequest {
    kvintaInlineObject: KvintaInlineObject;
}

export interface ReleaseIntroduceIntoCirculationDocumentRRequest {
    kvintaOperationRequestResource: KvintaOperationRequestResource;
}

export interface RetrieveAggregatedCustomsDocumentStatusRequest {
    kvintaOperationRequestResource: KvintaOperationRequestResource;
}

export interface RetrieveCirculationReportStatusRequest {
    kvintaOperationRequestResource: KvintaOperationRequestResource;
}

export interface SequenceStatusSummaryRequest {
    kvintaOperationRequestObject: KvintaOperationRequestObject;
}

export interface TransformAtkToCirculationReportRequest {
    kvintaOperationRequestCreateCirculationReportForAtkRequest: KvintaOperationRequestCreateCirculationReportForAtkRequest;
}

export interface TransformEpcMetadataListToCirculationReportRequest {
    kvintaOperationRequestCreateCirculationReportForEpcMetadataListRequest: KvintaOperationRequestCreateCirculationReportForEpcMetadataListRequest;
}

export interface UploadCsvInelMessageRequest {
    kvintaOperationRequestUploadInelMessageRequest: KvintaOperationRequestUploadInelMessageRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async captureOmsUtilisationRaw(requestParameters: CaptureOmsUtilisationRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestOmsUtilisationReportRequest === null || requestParameters.kvintaOperationRequestOmsUtilisationReportRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestOmsUtilisationReportRequest','Required parameter requestParameters.kvintaOperationRequestOmsUtilisationReportRequest was null or undefined when calling captureOmsUtilisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/captureOmsUtilisation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestOmsUtilisationReportRequestToJSON(requestParameters.kvintaOperationRequestOmsUtilisationReportRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async captureOmsUtilisation(requestParameters: CaptureOmsUtilisationRequest): Promise<KvintaOperationResponseEpcisDocumentRecord> {
        const response = await this.captureOmsUtilisationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async captureOmsUtilisationRRaw(requestParameters: CaptureOmsUtilisationRRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestResource === null || requestParameters.kvintaOperationRequestResource === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestResource','Required parameter requestParameters.kvintaOperationRequestResource was null or undefined when calling captureOmsUtilisationR.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/captureOmsUtilisationR`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestResourceToJSON(requestParameters.kvintaOperationRequestResource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async captureOmsUtilisationR(requestParameters: CaptureOmsUtilisationRRequest): Promise<KvintaOperationResponseEpcisDocumentRecord> {
        const response = await this.captureOmsUtilisationRRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createATKForShipmentEventRaw(requestParameters: CreateATKForShipmentEventRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTextDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestCreateATKForShipmentEventRequest === null || requestParameters.kvintaOperationRequestCreateATKForShipmentEventRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCreateATKForShipmentEventRequest','Required parameter requestParameters.kvintaOperationRequestCreateATKForShipmentEventRequest was null or undefined when calling createATKForShipmentEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/createATKForShipmentEvent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCreateATKForShipmentEventRequestToJSON(requestParameters.kvintaOperationRequestCreateATKForShipmentEventRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTextDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async createATKForShipmentEvent(requestParameters: CreateATKForShipmentEventRequest): Promise<KvintaOperationResponseTextDocumentRecord> {
        const response = await this.createATKForShipmentEventRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async exportEventProductsListToCsvRaw(requestParameters: ExportEventProductsListToCsvRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.kvintaOperationRequestReadEpcisEventWithProductsRequest === null || requestParameters.kvintaOperationRequestReadEpcisEventWithProductsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestReadEpcisEventWithProductsRequest','Required parameter requestParameters.kvintaOperationRequestReadEpcisEventWithProductsRequest was null or undefined when calling exportEventProductsListToCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/exportEventProductsListToCsv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestReadEpcisEventWithProductsRequestToJSON(requestParameters.kvintaOperationRequestReadEpcisEventWithProductsRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async exportEventProductsListToCsv(requestParameters: ExportEventProductsListToCsvRequest): Promise<string> {
        const response = await this.exportEventProductsListToCsvRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCisesInfoResultRaw(requestParameters: GetCisesInfoResultRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListGismtCisesInfoResult>> {
        if (requestParameters.kvintaOperationRequestListString === null || requestParameters.kvintaOperationRequestListString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListString','Required parameter requestParameters.kvintaOperationRequestListString was null or undefined when calling getCisesInfoResult.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/getCisesInfoResult`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListStringToJSON(requestParameters.kvintaOperationRequestListString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListGismtCisesInfoResultFromJSON(jsonValue));
    }

    /**
     */
    async getCisesInfoResult(requestParameters: GetCisesInfoResultRequest): Promise<KvintaOperationResponseListGismtCisesInfoResult> {
        const response = await this.getCisesInfoResultRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getSequenceStatusDependenciesRaw(requestParameters: GetSequenceStatusDependenciesRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultSequenceStatusDependencyRecord>> {
        if (requestParameters.kvintaOperationRequestLong === null || requestParameters.kvintaOperationRequestLong === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestLong','Required parameter requestParameters.kvintaOperationRequestLong was null or undefined when calling getSequenceStatusDependencies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/getSequenceStatusDependencies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestLongToJSON(requestParameters.kvintaOperationRequestLong),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultSequenceStatusDependencyRecordFromJSON(jsonValue));
    }

    /**
     */
    async getSequenceStatusDependencies(requestParameters: GetSequenceStatusDependenciesRequest): Promise<KvintaOperationResponseGetListResultSequenceStatusDependencyRecord> {
        const response = await this.getSequenceStatusDependenciesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getSerialsNumbersInfoRaw(requestParameters: GetSerialsNumbersInfoRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListGismtGetSerialNumberInfoResponse>> {
        if (requestParameters.kvintaOperationRequestListString === null || requestParameters.kvintaOperationRequestListString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListString','Required parameter requestParameters.kvintaOperationRequestListString was null or undefined when calling getSerialsNumbersInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/getSerialsNumbersInfo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListStringToJSON(requestParameters.kvintaOperationRequestListString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListGismtGetSerialNumberInfoResponseFromJSON(jsonValue));
    }

    /**
     */
    async getSerialsNumbersInfo(requestParameters: GetSerialsNumbersInfoRequest): Promise<KvintaOperationResponseListGismtGetSerialNumberInfoResponse> {
        const response = await this.getSerialsNumbersInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listSequenceStatusesRaw(requestParameters: ListSequenceStatusesRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultSequenceStatusRecord>> {
        if (requestParameters.kvintaOperationRequestListSequenceStatusesRequest === null || requestParameters.kvintaOperationRequestListSequenceStatusesRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListSequenceStatusesRequest','Required parameter requestParameters.kvintaOperationRequestListSequenceStatusesRequest was null or undefined when calling listSequenceStatuses.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listSequenceStatuses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListSequenceStatusesRequestToJSON(requestParameters.kvintaOperationRequestListSequenceStatusesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultSequenceStatusRecordFromJSON(jsonValue));
    }

    /**
     */
    async listSequenceStatuses(requestParameters: ListSequenceStatusesRequest): Promise<KvintaOperationResponseGetListResultSequenceStatusRecord> {
        const response = await this.listSequenceStatusesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get list of shipment events with corresponding aggregated customs documents
     * Get list of shipment events with corresponding aggregated customs documents
     */
    async listShipmentEventsWithAggregatedCustomsDocumentsRaw(requestParameters: ListShipmentEventsWithAggregatedCustomsDocumentsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultShipmentAggregatedCustomsDocument>> {
        if (requestParameters.kvintaOperationRequestListShipmentAggregatedCustomsDocumentsRequest === null || requestParameters.kvintaOperationRequestListShipmentAggregatedCustomsDocumentsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListShipmentAggregatedCustomsDocumentsRequest','Required parameter requestParameters.kvintaOperationRequestListShipmentAggregatedCustomsDocumentsRequest was null or undefined when calling listShipmentEventsWithAggregatedCustomsDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listShipmentEventsWithAggregatedCustomsDocuments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListShipmentAggregatedCustomsDocumentsRequestToJSON(requestParameters.kvintaOperationRequestListShipmentAggregatedCustomsDocumentsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultShipmentAggregatedCustomsDocumentFromJSON(jsonValue));
    }

    /**
     * Get list of shipment events with corresponding aggregated customs documents
     * Get list of shipment events with corresponding aggregated customs documents
     */
    async listShipmentEventsWithAggregatedCustomsDocuments(requestParameters: ListShipmentEventsWithAggregatedCustomsDocumentsRequest): Promise<KvintaOperationResponseGetListResultShipmentAggregatedCustomsDocument> {
        const response = await this.listShipmentEventsWithAggregatedCustomsDocumentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get list of shipment events with corresponding aggregated customs documents
     * Get list of shipment events with corresponding aggregated customs documents
     */
    async listVerificationEventsRaw(requestParameters: ListVerificationEventsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultEpcisEventRecord>> {
        if (requestParameters.kvintaOperationRequestListVerificationEventsRequest === null || requestParameters.kvintaOperationRequestListVerificationEventsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListVerificationEventsRequest','Required parameter requestParameters.kvintaOperationRequestListVerificationEventsRequest was null or undefined when calling listVerificationEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listVerificationEvents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListVerificationEventsRequestToJSON(requestParameters.kvintaOperationRequestListVerificationEventsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultEpcisEventRecordFromJSON(jsonValue));
    }

    /**
     * Get list of shipment events with corresponding aggregated customs documents
     * Get list of shipment events with corresponding aggregated customs documents
     */
    async listVerificationEvents(requestParameters: ListVerificationEventsRequest): Promise<KvintaOperationResponseGetListResultEpcisEventRecord> {
        const response = await this.listVerificationEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     * reportLevel - in case of 3 levels:              0 - item              1 - tray              2 - pallet  max level of children extraction will depend from this property kvinta.arla-extensions.hierarchy.max-depth              0 - extract only children, e.g. pallet - trays              1 - extract children of children pallet - trays - items              etc
     * reportLevel - in case of 3 levels:              0 - item              1 - tray              2 - pallet  max level of children extraction will depend from this property kvinta.arla-extensions.hierarchy.max-depth              0 - extract only children, e.g. pallet - trays              1 - extract children of children pallet - trays - items              etc
     */
    async readShipmentEventProductsRaw(requestParameters: ReadShipmentEventProductsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisEventWithProducts>> {
        if (requestParameters.kvintaOperationRequestReadEpcisEventWithProductsRequest === null || requestParameters.kvintaOperationRequestReadEpcisEventWithProductsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestReadEpcisEventWithProductsRequest','Required parameter requestParameters.kvintaOperationRequestReadEpcisEventWithProductsRequest was null or undefined when calling readShipmentEventProducts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readShipmentEventProducts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestReadEpcisEventWithProductsRequestToJSON(requestParameters.kvintaOperationRequestReadEpcisEventWithProductsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisEventWithProductsFromJSON(jsonValue));
    }

    /**
     * reportLevel - in case of 3 levels:              0 - item              1 - tray              2 - pallet  max level of children extraction will depend from this property kvinta.arla-extensions.hierarchy.max-depth              0 - extract only children, e.g. pallet - trays              1 - extract children of children pallet - trays - items              etc
     * reportLevel - in case of 3 levels:              0 - item              1 - tray              2 - pallet  max level of children extraction will depend from this property kvinta.arla-extensions.hierarchy.max-depth              0 - extract only children, e.g. pallet - trays              1 - extract children of children pallet - trays - items              etc
     */
    async readShipmentEventProducts(requestParameters: ReadShipmentEventProductsRequest): Promise<KvintaOperationResponseEpcisEventWithProducts> {
        const response = await this.readShipmentEventProductsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get shipment event with corresponding aggregated customs documents
     * Get shipment event with corresponding aggregated customs documents
     */
    async readShipmentEventWithAggregatedCustomsDocumentsRaw(requestParameters: ReadShipmentEventWithAggregatedCustomsDocumentsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseShipmentAggregatedCustomsDocument>> {
        if (requestParameters.kvintaOperationRequestReadShipmentAggregatedCustomsDocumentsRequest === null || requestParameters.kvintaOperationRequestReadShipmentAggregatedCustomsDocumentsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestReadShipmentAggregatedCustomsDocumentsRequest','Required parameter requestParameters.kvintaOperationRequestReadShipmentAggregatedCustomsDocumentsRequest was null or undefined when calling readShipmentEventWithAggregatedCustomsDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readShipmentEventWithAggregatedCustomsDocuments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestReadShipmentAggregatedCustomsDocumentsRequestToJSON(requestParameters.kvintaOperationRequestReadShipmentAggregatedCustomsDocumentsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseShipmentAggregatedCustomsDocumentFromJSON(jsonValue));
    }

    /**
     * Get shipment event with corresponding aggregated customs documents
     * Get shipment event with corresponding aggregated customs documents
     */
    async readShipmentEventWithAggregatedCustomsDocuments(requestParameters: ReadShipmentEventWithAggregatedCustomsDocumentsRequest): Promise<KvintaOperationResponseShipmentAggregatedCustomsDocument> {
        const response = await this.readShipmentEventWithAggregatedCustomsDocumentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get list of shipment events with corresponding aggregated customs documents
     * Get list of shipment events with corresponding aggregated customs documents
     */
    async readVerificationEventResultRaw(requestParameters: ReadVerificationEventResultRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListEpcVerificationResult>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readVerificationEventResult.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readVerificationEventResult`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListEpcVerificationResultFromJSON(jsonValue));
    }

    /**
     * Get list of shipment events with corresponding aggregated customs documents
     * Get list of shipment events with corresponding aggregated customs documents
     */
    async readVerificationEventResult(requestParameters: ReadVerificationEventResultRequest): Promise<KvintaOperationResponseListEpcVerificationResult> {
        const response = await this.readVerificationEventResultRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async releaseAggregatedCustomsDocumentRaw(requestParameters: ReleaseAggregatedCustomsDocumentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseString>> {
        if (requestParameters.kvintaOperationRequestAggregatedCustomsDocument === null || requestParameters.kvintaOperationRequestAggregatedCustomsDocument === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestAggregatedCustomsDocument','Required parameter requestParameters.kvintaOperationRequestAggregatedCustomsDocument was null or undefined when calling releaseAggregatedCustomsDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/releaseAggregatedCustomsDocument`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestAggregatedCustomsDocumentToJSON(requestParameters.kvintaOperationRequestAggregatedCustomsDocument),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseStringFromJSON(jsonValue));
    }

    /**
     */
    async releaseAggregatedCustomsDocument(requestParameters: ReleaseAggregatedCustomsDocumentRequest): Promise<KvintaOperationResponseString> {
        const response = await this.releaseAggregatedCustomsDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async releaseAggregatedCustomsDocumentRRaw(requestParameters: ReleaseAggregatedCustomsDocumentRRequest): Promise<runtime.ApiResponse<KvintaOperationResponseString>> {
        if (requestParameters.kvintaOperationRequestResource === null || requestParameters.kvintaOperationRequestResource === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestResource','Required parameter requestParameters.kvintaOperationRequestResource was null or undefined when calling releaseAggregatedCustomsDocumentR.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/releaseAggregatedCustomsDocumentR`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestResourceToJSON(requestParameters.kvintaOperationRequestResource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseStringFromJSON(jsonValue));
    }

    /**
     */
    async releaseAggregatedCustomsDocumentR(requestParameters: ReleaseAggregatedCustomsDocumentRRequest): Promise<KvintaOperationResponseString> {
        const response = await this.releaseAggregatedCustomsDocumentRRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async releaseIntroduceIntoCirculationDocumentRaw(requestParameters: ReleaseIntroduceIntoCirculationDocumentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseString>> {
        if (requestParameters.kvintaInlineObject === null || requestParameters.kvintaInlineObject === undefined) {
            throw new runtime.RequiredError('kvintaInlineObject','Required parameter requestParameters.kvintaInlineObject was null or undefined when calling releaseIntroduceIntoCirculationDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/releaseIntroduceIntoCirculationDocument`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaInlineObjectToJSON(requestParameters.kvintaInlineObject),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseStringFromJSON(jsonValue));
    }

    /**
     */
    async releaseIntroduceIntoCirculationDocument(requestParameters: ReleaseIntroduceIntoCirculationDocumentRequest): Promise<KvintaOperationResponseString> {
        const response = await this.releaseIntroduceIntoCirculationDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async releaseIntroduceIntoCirculationDocumentRRaw(requestParameters: ReleaseIntroduceIntoCirculationDocumentRRequest): Promise<runtime.ApiResponse<KvintaOperationResponseString>> {
        if (requestParameters.kvintaOperationRequestResource === null || requestParameters.kvintaOperationRequestResource === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestResource','Required parameter requestParameters.kvintaOperationRequestResource was null or undefined when calling releaseIntroduceIntoCirculationDocumentR.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/releaseIntroduceIntoCirculationDocumentR`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestResourceToJSON(requestParameters.kvintaOperationRequestResource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseStringFromJSON(jsonValue));
    }

    /**
     */
    async releaseIntroduceIntoCirculationDocumentR(requestParameters: ReleaseIntroduceIntoCirculationDocumentRRequest): Promise<KvintaOperationResponseString> {
        const response = await this.releaseIntroduceIntoCirculationDocumentRRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async retrieveAggregatedCustomsDocumentStatusRaw(requestParameters: RetrieveAggregatedCustomsDocumentStatusRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGismtDocumentProcessingStatusType>> {
        if (requestParameters.kvintaOperationRequestResource === null || requestParameters.kvintaOperationRequestResource === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestResource','Required parameter requestParameters.kvintaOperationRequestResource was null or undefined when calling retrieveAggregatedCustomsDocumentStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/retrieveAggregatedCustomsDocumentStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestResourceToJSON(requestParameters.kvintaOperationRequestResource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGismtDocumentProcessingStatusTypeFromJSON(jsonValue));
    }

    /**
     */
    async retrieveAggregatedCustomsDocumentStatus(requestParameters: RetrieveAggregatedCustomsDocumentStatusRequest): Promise<KvintaOperationResponseGismtDocumentProcessingStatusType> {
        const response = await this.retrieveAggregatedCustomsDocumentStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async retrieveCirculationReportStatusRaw(requestParameters: RetrieveCirculationReportStatusRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGismtDocumentProcessingStatusType>> {
        if (requestParameters.kvintaOperationRequestResource === null || requestParameters.kvintaOperationRequestResource === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestResource','Required parameter requestParameters.kvintaOperationRequestResource was null or undefined when calling retrieveCirculationReportStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/retrieveCirculationReportStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestResourceToJSON(requestParameters.kvintaOperationRequestResource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGismtDocumentProcessingStatusTypeFromJSON(jsonValue));
    }

    /**
     */
    async retrieveCirculationReportStatus(requestParameters: RetrieveCirculationReportStatusRequest): Promise<KvintaOperationResponseGismtDocumentProcessingStatusType> {
        const response = await this.retrieveCirculationReportStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async sequenceStatusSummaryRaw(requestParameters: SequenceStatusSummaryRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultSequenceSummary>> {
        if (requestParameters.kvintaOperationRequestObject === null || requestParameters.kvintaOperationRequestObject === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestObject','Required parameter requestParameters.kvintaOperationRequestObject was null or undefined when calling sequenceStatusSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sequenceStatusSummary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestObjectToJSON(requestParameters.kvintaOperationRequestObject),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultSequenceSummaryFromJSON(jsonValue));
    }

    /**
     */
    async sequenceStatusSummary(requestParameters: SequenceStatusSummaryRequest): Promise<KvintaOperationResponseGetListResultSequenceSummary> {
        const response = await this.sequenceStatusSummaryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async transformAtkToCirculationReportRaw(requestParameters: TransformAtkToCirculationReportRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTextDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestCreateCirculationReportForAtkRequest === null || requestParameters.kvintaOperationRequestCreateCirculationReportForAtkRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCreateCirculationReportForAtkRequest','Required parameter requestParameters.kvintaOperationRequestCreateCirculationReportForAtkRequest was null or undefined when calling transformAtkToCirculationReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/transformAtkToCirculationReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCreateCirculationReportForAtkRequestToJSON(requestParameters.kvintaOperationRequestCreateCirculationReportForAtkRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTextDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async transformAtkToCirculationReport(requestParameters: TransformAtkToCirculationReportRequest): Promise<KvintaOperationResponseTextDocumentRecord> {
        const response = await this.transformAtkToCirculationReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async transformEpcMetadataListToCirculationReportRaw(requestParameters: TransformEpcMetadataListToCirculationReportRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTextDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestCreateCirculationReportForEpcMetadataListRequest === null || requestParameters.kvintaOperationRequestCreateCirculationReportForEpcMetadataListRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCreateCirculationReportForEpcMetadataListRequest','Required parameter requestParameters.kvintaOperationRequestCreateCirculationReportForEpcMetadataListRequest was null or undefined when calling transformEpcMetadataListToCirculationReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/transformEpcMetadataListToCirculationReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCreateCirculationReportForEpcMetadataListRequestToJSON(requestParameters.kvintaOperationRequestCreateCirculationReportForEpcMetadataListRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTextDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async transformEpcMetadataListToCirculationReport(requestParameters: TransformEpcMetadataListToCirculationReportRequest): Promise<KvintaOperationResponseTextDocumentRecord> {
        const response = await this.transformEpcMetadataListToCirculationReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async uploadCsvInelMessageRaw(requestParameters: UploadCsvInelMessageRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestUploadInelMessageRequest === null || requestParameters.kvintaOperationRequestUploadInelMessageRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestUploadInelMessageRequest','Required parameter requestParameters.kvintaOperationRequestUploadInelMessageRequest was null or undefined when calling uploadCsvInelMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/uploadCsvInelMessage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestUploadInelMessageRequestToJSON(requestParameters.kvintaOperationRequestUploadInelMessageRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async uploadCsvInelMessage(requestParameters: UploadCsvInelMessageRequest): Promise<KvintaOperationResponseEpcisDocumentRecord> {
        const response = await this.uploadCsvInelMessageRaw(requestParameters);
        return await response.value();
    }

}
