/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSerialNumberOrderType,
    KvintaSerialNumberOrderTypeFromJSON,
    KvintaSerialNumberOrderTypeFromJSONTyped,
    KvintaSerialNumberOrderTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaCreateSerialNumberOrderRequest
 */
export interface KvintaCreateSerialNumberOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberOrderRequest
     */
    companyPrefix?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberOrderRequest
     */
    contactPerson: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaCreateSerialNumberOrderRequest
     */
    documentDate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberOrderRequest
     */
    documentNumber: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberOrderRequest
     */
    paymentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberOrderRequest
     */
    profileId?: string | null;
    /**
     * 
     * @type {KvintaSerialNumberOrderType}
     * @memberof KvintaCreateSerialNumberOrderRequest
     */
    serialNumberOrderType: KvintaSerialNumberOrderType;
}

export function KvintaCreateSerialNumberOrderRequestFromJSON(json: any): KvintaCreateSerialNumberOrderRequest {
    return KvintaCreateSerialNumberOrderRequestFromJSONTyped(json, false);
}

export function KvintaCreateSerialNumberOrderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateSerialNumberOrderRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyPrefix': !exists(json, 'companyPrefix') ? undefined : json['companyPrefix'],
        'contactPerson': json['contactPerson'],
        'documentDate': !exists(json, 'documentDate') ? undefined : json['documentDate'],
        'documentNumber': json['documentNumber'],
        'paymentType': !exists(json, 'paymentType') ? undefined : json['paymentType'],
        'profileId': !exists(json, 'profileId') ? undefined : json['profileId'],
        'serialNumberOrderType': KvintaSerialNumberOrderTypeFromJSON(json['serialNumberOrderType']),
    };
}

export function KvintaCreateSerialNumberOrderRequestToJSON(value?: KvintaCreateSerialNumberOrderRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyPrefix': value.companyPrefix,
        'contactPerson': value.contactPerson,
        'documentDate': value.documentDate,
        'documentNumber': value.documentNumber,
        'paymentType': value.paymentType,
        'profileId': value.profileId,
        'serialNumberOrderType': KvintaSerialNumberOrderTypeToJSON(value.serialNumberOrderType),
    };
}


