/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaDeleteManyRequest
 */
export interface KvintaDeleteManyRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaDeleteManyRequest
     */
    ids: Array<string>;
}

export function KvintaDeleteManyRequestFromJSON(json: any): KvintaDeleteManyRequest {
    return KvintaDeleteManyRequestFromJSONTyped(json, false);
}

export function KvintaDeleteManyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDeleteManyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ids': json['ids'],
    };
}

export function KvintaDeleteManyRequestToJSON(value?: KvintaDeleteManyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ids': value.ids,
    };
}


