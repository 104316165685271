/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaNotificationGroupRecipient,
    KvintaNotificationGroupRecipientFromJSON,
    KvintaNotificationGroupRecipientFromJSONTyped,
    KvintaNotificationGroupRecipientToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestNotificationGroupRecipient
 */
export interface KvintaOperationRequestNotificationGroupRecipient {
    /**
     * 
     * @type {KvintaNotificationGroupRecipient}
     * @memberof KvintaOperationRequestNotificationGroupRecipient
     */
    input?: KvintaNotificationGroupRecipient;
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestNotificationGroupRecipient
     */
    context?: KvintaExecutionContext;
}

export function KvintaOperationRequestNotificationGroupRecipientFromJSON(json: any): KvintaOperationRequestNotificationGroupRecipient {
    return KvintaOperationRequestNotificationGroupRecipientFromJSONTyped(json, false);
}

export function KvintaOperationRequestNotificationGroupRecipientFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestNotificationGroupRecipient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'input': !exists(json, 'input') ? undefined : KvintaNotificationGroupRecipientFromJSON(json['input']),
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
    };
}

export function KvintaOperationRequestNotificationGroupRecipientToJSON(value?: KvintaOperationRequestNotificationGroupRecipient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'input': KvintaNotificationGroupRecipientToJSON(value.input),
        'context': KvintaExecutionContextToJSON(value.context),
    };
}


