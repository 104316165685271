import {
  AvTimerOutlined as EventsIcon,
  FolderOpenOutlined as DocumentsIcon,
  HomeOutlined,
  ListAltOutlined as MasterdataIcon,
  DescriptionOutlined as TechDocIcon,
  Devices as DevicesIcon,
} from '@material-ui/icons';
import ShippingIcon from '@material-ui/icons/DirectionsBoatOutlined';
import ConfigurationIcon from '@material-ui/icons/SettingsOutlined';
import ProcessesIcon from '@material-ui/icons/AccountTreeOutlined';
import { IMenuItem } from 'kvinta/components';
import { EpcisMessagePath, EpcisMessagesPath } from 'kvinta/modules/epcis';
import { MainPath } from 'kvinta/modules/main';
import { CompaniesPath, CompanyPath } from 'kvinta/modules/master-data/companies';
import { LocationPath, LocationsPath } from 'kvinta/modules/master-data/locations';
import {
  ProductPath,
  ProductsPath,
  ProductTradeItemPath,
  ProductTradeItemsPath,
} from 'kvinta/modules/master-data/products';
import { TradeItemPath, TradeItemsPath } from 'kvinta/modules/master-data/trade-items';
import { EpcMessagePath, EpcMessagesPath } from 'kvinta/modules/epcis/epcs';
import { SerialNumberOrderPath, SerialNumberOrdersPath } from 'kvinta/modules/documents/serial-number-orders';
import { ATKListPath } from 'kvinta/modules/documents/atk';
import { CirculationReportsListPath, CirculationReportSummaryPath } from 'kvinta/modules/documents/circulation-reports';
import { EpcisEventJsonPath, EpcisEventPath, EpcisEventsPath } from 'kvinta/modules/epcis/events';
import {
  ATKSummaryPath,
  DeviceAssignmentsPath,
  DevicesPath,
  DeviceSummaryPath,
  EpcMessageHierarchyPath,
  ExecutionStatusesPath,
  ExecutionStatusHierarchyPath,
  ExecutionStatusPath,
  SequenceStatusesPath,
  SequenceStatusPath,
  SequenceSummaryPath,
  SerialNumbersInfoPath,
  ShippingListPath,
  ShippingPath,
  ShippingProductListPath,
  ShippingResultsPath,
  VerificationResultsPath,
} from 'kvinta/modules/paths';
import {
  NotificationGroupListPath,
  NotificationRecipientsPath,
  NotificationTypesPath,
} from './kvinta/modules/notification-settings/paths';
import { TechDocDownloadPath } from 'kvinta/modules/techdoc';
import { showDevicesMenu } from 'config';
import {
  VerificationListPath,
  VerificationPath,
  VerificationProductsPath,
} from 'kvinta/modules/shipping/verification-list';

export function getMenus(): IMenuItem[] {
  const mainMenu = [
    {
      path: MainPath,
      label: 'menu.home',
      Icon: HomeOutlined,
      isClickable: true,
    },
    'divider',
    {
      label: 'menu.master-data',
      Icon: MasterdataIcon,
      isClickable: false,
      items: [
        {
          path: CompaniesPath,
          label: 'menu.companies',
          Icon: MasterdataIcon,
          isClickable: true,
          selectOnPaths: [CompanyPath],
        },
        {
          path: LocationsPath,
          label: 'menu.locations',
          Icon: MasterdataIcon,
          isClickable: true,
          selectOnPaths: [LocationPath],
        },
        {
          path: ProductsPath,
          label: 'menu.products',
          Icon: MasterdataIcon,
          isClickable: true,
          selectOnPaths: [ProductPath, ProductTradeItemsPath, ProductTradeItemPath],
        },
        {
          path: TradeItemsPath,
          label: 'menu.trade-items',
          Icon: MasterdataIcon,
          isClickable: true,
          selectOnPaths: [TradeItemPath],
        },
      ],
    },
    'divider',
    {
      label: 'menu.epcis',
      Icon: EventsIcon,
      isClickable: false,
      items: [
        {
          path: EpcMessagesPath,
          label: 'menu.epcs',
          Icon: EventsIcon,
          isClickable: true,
          selectOnPaths: [EpcMessagePath, EpcMessageHierarchyPath, EpcMessageHierarchyPath],
        },
        {
          path: EpcisMessagesPath,
          label: 'menu.messages',
          Icon: EventsIcon,
          isClickable: true,
          selectOnPaths: [EpcisMessagePath],
        },
        {
          path: EpcisEventsPath,
          label: 'menu.events',
          Icon: EventsIcon,
          isClickable: true,
          selectOnPaths: [EpcisEventPath, EpcisEventJsonPath],
        },
      ],
    },
    'divider',
    {
      label: 'menu.documents',
      Icon: DocumentsIcon,
      isClickable: false,
      items: [
        {
          path: SerialNumberOrdersPath,
          label: 'menu.serial-number-orders',
          Icon: DocumentsIcon,
          isClickable: true,
          selectOnPaths: [SerialNumberOrderPath],
        },
        {
          path: ATKListPath,
          label: 'menu.atk',
          Icon: DocumentsIcon,
          isClickable: true,
          selectOnPaths: [ATKSummaryPath],
        },
        {
          path: CirculationReportsListPath,
          label: 'menu.circulation-reports',
          Icon: DocumentsIcon,
          isClickable: true,
          selectOnPaths: [CirculationReportSummaryPath],
        },
      ],
    },
    'divider',
    {
      label: 'menu.processes',
      Icon: ProcessesIcon,
      isClickable: false,
      items: [
        {
          path: ExecutionStatusesPath,
          label: 'menu.execution-statuses',
          Icon: ProcessesIcon,
          isClickable: true,
          selectOnPaths: [ExecutionStatusPath, ExecutionStatusHierarchyPath],
        },
        {
          path: SequenceStatusesPath,
          label: 'menu.sequence-statuses',
          Icon: ProcessesIcon,
          isClickable: true,
          selectOnPaths: [SequenceStatusesPath, SequenceStatusPath],
        },
        {
          path: SequenceSummaryPath,
          label: 'menu.sequence-summary',
          Icon: ProcessesIcon,
          isClickable: true,
          selectOnPaths: [],
        },
        {
          path: SerialNumbersInfoPath,
          label: 'menu.serial-numbers-info',
          Icon: ProcessesIcon,
          isClickable: true,
          selectOnPaths: [],
        },
      ],
    },
    'divider',
    {
      label: 'menu.techdocs',
      Icon: TechDocIcon,
      isClickable: false,
      items: [
        {
          path: TechDocDownloadPath,
          label: 'menu.techdocs.download',
          Icon: TechDocIcon,
          isClickable: true,
          selectOnPaths: [],
        },
      ],
    },
    'divider',
    {
      label: 'menu.shipping',
      Icon: ShippingIcon,
      isClickable: false,
      items: [
        {
          path: ShippingListPath,
          label: 'menu.shipping-list',
          Icon: ShippingIcon,
          isClickable: true,
          selectOnPaths: [ShippingPath, ShippingProductListPath, ShippingResultsPath],
        },
        {
          path: VerificationListPath,
          label: 'menu.verification-list',
          Icon: ShippingIcon,
          isClickable: true,
          selectOnPaths: [VerificationPath, VerificationProductsPath, VerificationResultsPath],
        },
      ],
    },
    'divider',
    {
      label: 'menu.configuration',
      Icon: ConfigurationIcon,
      isClickable: false,
      items: [
        {
          path: NotificationGroupListPath,
          label: 'menu.notifications',
          Icon: ConfigurationIcon,
          isClickable: true,
          selectOnPaths: [NotificationGroupListPath, NotificationRecipientsPath, NotificationTypesPath],
        },
      ],
    },
  ];
  if (showDevicesMenu === 'true') {
    mainMenu.push('divider');
    mainMenu.push({
      label: 'menu.devices',
      Icon: DevicesIcon,
      isClickable: false,
      items: [
        {
          path: DevicesPath,
          label: 'menu.devices',
          Icon: DevicesIcon,
          isClickable: true,
          selectOnPaths: [DeviceSummaryPath, DeviceAssignmentsPath],
        },
      ],
    });
  }
  return mainMenu;
}
