/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaEmailRecipient
 */
export interface KvintaEmailRecipient {
    /**
     * 
     * @type {string}
     * @memberof KvintaEmailRecipient
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEmailRecipient
     */
    emailAddress?: string;
}

export function KvintaEmailRecipientFromJSON(json: any): KvintaEmailRecipient {
    return KvintaEmailRecipientFromJSONTyped(json, false);
}

export function KvintaEmailRecipientFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEmailRecipient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
    };
}

export function KvintaEmailRecipientToJSON(value?: KvintaEmailRecipient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'emailAddress': value.emailAddress,
    };
}


