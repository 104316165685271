/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Query Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaLocation,
    KvintaLocationFromJSON,
    KvintaLocationFromJSONTyped,
    KvintaLocationToJSON,
    KvintaOperationType,
    KvintaOperationTypeFromJSON,
    KvintaOperationTypeFromJSONTyped,
    KvintaOperationTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaRelevantLocationChange
 */
export interface KvintaRelevantLocationChange {
    /**
     * 
     * @type {number}
     * @memberof KvintaRelevantLocationChange
     */
    idx?: number | null;
    /**
     * 
     * @type {KvintaLocation}
     * @memberof KvintaRelevantLocationChange
     */
    location?: KvintaLocation;
    /**
     * 
     * @type {KvintaOperationType}
     * @memberof KvintaRelevantLocationChange
     */
    op?: KvintaOperationType;
    /**
     * 
     * @type {string}
     * @memberof KvintaRelevantLocationChange
     */
    shipDoc?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaRelevantLocationChange
     */
    ts?: number;
}

export function KvintaRelevantLocationChangeFromJSON(json: any): KvintaRelevantLocationChange {
    return KvintaRelevantLocationChangeFromJSONTyped(json, false);
}

export function KvintaRelevantLocationChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaRelevantLocationChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idx': !exists(json, 'idx') ? undefined : json['idx'],
        'location': !exists(json, 'location') ? undefined : KvintaLocationFromJSON(json['location']),
        'op': !exists(json, 'op') ? undefined : KvintaOperationTypeFromJSON(json['op']),
        'shipDoc': !exists(json, 'shipDoc') ? undefined : json['shipDoc'],
        'ts': !exists(json, 'ts') ? undefined : json['ts'],
    };
}

export function KvintaRelevantLocationChangeToJSON(value?: KvintaRelevantLocationChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idx': value.idx,
        'location': KvintaLocationToJSON(value.location),
        'op': KvintaOperationTypeToJSON(value.op),
        'shipDoc': value.shipDoc,
        'ts': value.ts,
    };
}


