import React, { Component } from 'react';
import clsx from 'clsx';
import { Observer, observer } from 'mobx-react';
import { makeObservable, action, computed, observable, autorun } from 'mobx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { Collapse, List, ListItem, Divider, Tooltip, SvgIcon } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import { matchPath, Route } from 'react-router-dom';
import { SvgIconComponent } from '@material-ui/icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IMenuItem, IMenuItemClickable, isSelectedMenu } from './Menus';

interface ISidebarItem {
  isExpanded: boolean;
  item: IMenuItemClickable;
  onClick(path, label);
  selectedItem: string;
  isWideDrawer: boolean;
  isExternal?: boolean;
  intl: any;
  noIcon?: boolean;
  paddingLeft: number;
}

interface ISidebarState {
  isExpanded: boolean;
}

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: 'rgba(255, 255, 255, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

class sidebarItem extends Component<ISidebarItem, ISidebarState> {
  expandIcon: any = null;

  constructor(props) {
    super(props);

    const isExpanded =
      this.props.item.isClickable === false
        ? isChildSelected(this.props.item.items, this.props.selectedItem)
        : this.props.isExpanded;
    this.state = { isExpanded };
  }

  toggleExpansion() {
    const isExpanded = !this.state.isExpanded;
    this.setState({ isExpanded });
  }

  onClick(e, history) {
    if (Array.isArray(this.props.item.items)) {
      this.toggleExpansion();
      // this.props.onClick(this.props.item.path, this.props.intl.formatMessage({ id: this.props.item.label }));
    } else if (this.props.item.isClickable) {
      this.props.onClick(this.props.item.path, this.props.intl.formatMessage({ id: this.props.item.label }));
      history.push(this.props.item.path);
    }
  }

  render() {
    const isExpanded = this.state.isExpanded;
    const classes = {
      colors: {
        color: '#fff',
      },
    };
    const hasChildren = Array.isArray(this.props.item.items) && this.props.item.items.length;
    if (hasChildren) {
      if (this.props.isWideDrawer) {
        this.expandIcon = isExpanded ? (
          <ExpandLessIcon className={'sidebar-item-expand-arrow sidebar-item-expand-arrow-expanded'} />
        ) : (
          <ExpandMoreIcon className="sidebar-item-expand-arrow" />
        );
      } else {
        this.expandIcon = null;
      }
    }
    const label = (
      <div className={clsx('sidebar-item-text', classes.colors)}>
        <FormattedMessage id={this.props.item.label} defaultMessage={this.props.item.label} />
      </div>
    );

    let iconWithTooltip = null;
    if (!this.props.noIcon) {
      let icon = null;
      if (typeof this.props.item.Icon === 'string') {
        icon = <img src={this.props.item.Icon} alt={this.props.item.Icon} />;
      } else {
        icon = this.props.item.Icon && <this.props.item.Icon className="sidebar-item-icon" fontSize="small" />;
      }
      iconWithTooltip = this.props.isWideDrawer ? (
        icon
      ) : (
        <LightTooltip title={this.props.intl.formatMessage({ id: this.props.item.label })} placement="right">
          {icon}
        </LightTooltip>
      );
    }

    const isExternal = this.props.isExternal;
    return (
      <>
        <Route
          render={({ history }) => (
            <ListItem
              button
              className={clsx('', {
                selected: isSelectedMenu(this.props.item, this.props.selectedItem),
              })}
              onClick={(e) => {
                isExternal ? (window.location.href = this.props.item.path) : this.onClick(e, history);
              }}
            >
              <div style={{ paddingLeft: this.props.paddingLeft }} className="sidebar-item-content">
                {iconWithTooltip}
                {label}
              </div>
              {this.expandIcon}
            </ListItem>
          )}
        />
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          {Array.isArray(this.props.item.items) ? (
            <List disablePadding>
              {this.props.item.items.map((subItem, index) => {
                if (subItem === 'divider') {
                  return (
                    <React.Fragment key={(subItem as string) + '-' + index}>
                      <Divider />
                    </React.Fragment>
                  );
                } else {
                  const si = subItem as IMenuItemClickable;
                  return (
                    <React.Fragment key={si.label}>
                      <SidebarItem
                        paddingLeft={30}
                        item={si}
                        onClick={(e) => {
                          this.props.onClick(si.path, this.props.intl.formatMessage({ id: si.label }));
                        }}
                        isExpanded={false}
                        selectedItem={this.props.selectedItem}
                        isWideDrawer={this.props.isWideDrawer}
                        noIcon={true}
                      />
                    </React.Fragment>
                  );
                }
              })}
            </List>
          ) : null}
        </Collapse>
      </>
    );
  }
}

export const SidebarItem = observer(injectIntl(sidebarItem));

function isChildSelected(items: IMenuItem[], selectedItem: string): boolean {
  for (const item of items) {
    if (typeof item !== 'string') {
      const i = item as IMenuItemClickable;
      if (matchPath(selectedItem, i.path)) {
        return true;
      }
      if (i.selectOnPaths) {
        for (const p of i.selectOnPaths) {
          if (matchPath(selectedItem, p)) {
            return true;
          }
        }
      }
    }
  }
  return false;
}
