import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KSidebarStore, PAGE_CONTENT_STORE_ID, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { navigateEpcisEventPath } from '../events';
import { EpcMessagesStore, EPC_MESSAGES_STORE_ID } from './EpcMessagesStore';
import { navigateEpcTab, tabs } from './tabs';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Route } from 'react-router-dom';
import { navigateEpcMessagesPath, unescapeId } from './paths';
import { reaction } from 'mobx';

interface ViewEpcSummaryProps {
  intl: any;
  id: string;
  ts: number;
  index?: number;
  history: any;
  sidebarStore?: KSidebarStore;
  epcMessagesStore: EpcMessagesStore;
}
class ViewEpcSummaryImpl extends Component<ViewEpcSummaryProps> {
  disposer: any;
  componentDidMount() {
    this.props.epcMessagesStore.fetchEpc(unescapeId(this.props.id), this.props.ts, this.props.index);
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.epcMessagesStore.currentEpc,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.id, () => {
            navigateEpcMessagesPath(this.props.history);
          });
        }
      },
    );
  }
  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const intl = this.props.intl;
    const currentEpc = this.props.epcMessagesStore.currentEpc;
    if (!currentEpc) {
      return null;
    }
    return (
      <Route
        render={({ history }) => (
          <div>
            <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
              <TabBar
                onChange={(newValue: any) => {
                  navigateEpcTab(
                    this.props.history,
                    newValue as string,
                    unescapeId(this.props.id),
                    this.props.ts,
                    this.props.index,
                  );
                }}
                tabId={'1'}
                tabs={tabs}
              />
            </Paper>
            <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
              <Grid container>
                <Grid item xs={6}>
                  <Box m={2}>
                    <FormControl variant="outlined" fullWidth>
                      {/*Background color used to fix an overlap bug between title and border */}
                      <InputLabel style={{ backgroundColor: 'white' }} htmlFor="epc">
                        {intl.formatMessage({ id: 'epc.epc' })}
                      </InputLabel>
                      <OutlinedInput readOnly={true} label=" " id="epc" defaultValue={currentEpc.epc} />
                    </FormControl>
                  </Box>
                  <Box m={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel style={{ backgroundColor: 'white' }} htmlFor="timestamp">
                        {intl.formatMessage({ id: 'epc.timestamp' })}
                      </InputLabel>
                      <OutlinedInput readOnly={true} label=" " id="timestamp" defaultValue={currentEpc.timestamp} />
                    </FormControl>
                  </Box>

                  <Box m={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel style={{ backgroundColor: 'white' }} htmlFor="disposition">
                        {intl.formatMessage({ id: 'epc.disposition' })}
                      </InputLabel>
                      <OutlinedInput readOnly={true} label=" " id="disposition" defaultValue={currentEpc.disposition} />
                    </FormControl>
                  </Box>

                  <Box m={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel style={{ backgroundColor: 'white' }} htmlFor="errordeclaration">
                        {intl.formatMessage({ id: 'epc.errordeclaration' })}
                      </InputLabel>
                      <OutlinedInput
                        readOnly={true}
                        label=" "
                        id="errordeclaration"
                        defaultValue={currentEpc.errorDeclaration}
                      />
                    </FormControl>
                  </Box>

                  <Box m={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel style={{ backgroundColor: 'white' }} htmlFor="eventid">
                        {intl.formatMessage({ id: 'epc.eventid' })}
                      </InputLabel>
                      <OutlinedInput
                        readOnly={true}
                        label=" "
                        id="eventid"
                        defaultValue={currentEpc.attrId}
                        endAdornment={
                          currentEpc.attrId && (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(e) => {
                                  navigateEpcisEventPath(history, currentEpc.attrId);
                                }}
                              >
                                <OpenInNewIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box m={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel style={{ backgroundColor: 'white' }} htmlFor="readpoint">
                        {intl.formatMessage({ id: 'epc.readpoint' })}
                      </InputLabel>
                      <OutlinedInput readOnly={true} label=" " id="readpoint" defaultValue={currentEpc.readPoint} />
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box m={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel style={{ backgroundColor: 'white' }} htmlFor="location">
                        {intl.formatMessage({ id: 'epc.location' })}
                      </InputLabel>
                      <OutlinedInput readOnly={true} label=" " id="location" defaultValue={currentEpc.location} />
                    </FormControl>
                  </Box>

                  <Box m={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel style={{ backgroundColor: 'white' }} htmlFor="operation">
                        {intl.formatMessage({ id: 'epc.operation' })}
                      </InputLabel>
                      <OutlinedInput readOnly={true} label=" " id="operation" defaultValue={currentEpc.operation} />
                    </FormControl>
                  </Box>

                  <Box m={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel style={{ backgroundColor: 'white' }} htmlFor="action">
                        {intl.formatMessage({ id: 'epc.action' })}
                      </InputLabel>
                      <OutlinedInput readOnly={true} label=" " id="action" defaultValue={currentEpc.action} />
                    </FormControl>
                  </Box>

                  <Box m={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel style={{ backgroundColor: 'white' }} htmlFor="createdbytaskid">
                        {intl.formatMessage({ id: 'epc.createdbytaskid' })}
                      </InputLabel>
                      <OutlinedInput
                        readOnly={true}
                        label=" "
                        id="createdbytaskid"
                        defaultValue={currentEpc.createdByTaskId}
                      />
                    </FormControl>
                  </Box>

                  <Box m={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel style={{ backgroundColor: 'white' }} htmlFor="messageid">
                        {intl.formatMessage({ id: 'epc.messageid' })}
                      </InputLabel>
                      <OutlinedInput readOnly={true} label=" " id="messageid" defaultValue={currentEpc.messageId} />
                    </FormControl>
                  </Box>
                  <Box m={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel style={{ backgroundColor: 'white' }} htmlFor="recordtime">
                        {intl.formatMessage({ id: 'epc.recordtime' })}
                      </InputLabel>
                      <OutlinedInput
                        readOnly={true}
                        label=" "
                        id="recordtime"
                        defaultValue={currentEpc.recordTime && new Date(parseInt(currentEpc.recordTime)).toISOString()}
                      />
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </div>
        )}
      />
    );
  }
}

export const ViewEpcSummary = injectIntl(
  inject(EPC_MESSAGES_STORE_ID, PAGE_CONTENT_STORE_ID, SIDEBAR_STORE_ID)(observer(ViewEpcSummaryImpl)),
);
