/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaSerialNumberOrderStatusType {
    Created = 'CREATED',
    Releasing = 'RELEASING',
    Released = 'RELEASED',
    AllSerialsReceived = 'ALL_SERIALS_RECEIVED',
    Error = 'ERROR'
}

export function KvintaSerialNumberOrderStatusTypeFromJSON(json: any): KvintaSerialNumberOrderStatusType {
    return KvintaSerialNumberOrderStatusTypeFromJSONTyped(json, false);
}

export function KvintaSerialNumberOrderStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSerialNumberOrderStatusType {
    return json as KvintaSerialNumberOrderStatusType;
}

export function KvintaSerialNumberOrderStatusTypeToJSON(value?: KvintaSerialNumberOrderStatusType | null): any {
    return value as any;
}

