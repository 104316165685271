/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaResourceStatusType {
    CreateQueued = 'CREATE_QUEUED',
    CreateOk = 'CREATE_OK',
    CreateError = 'CREATE_ERROR',
    ReleaseQueued = 'RELEASE_QUEUED',
    ReleaseOk = 'RELEASE_OK',
    ReleaseError = 'RELEASE_ERROR',
    Done = 'DONE',
    Rejected = 'REJECTED',
    DeleteQueued = 'DELETE_QUEUED',
    DeleteOk = 'DELETE_OK',
    DeleteError = 'DELETE_ERROR',
    UpdateQueued = 'UPDATE_QUEUED',
    UpdateOk = 'UPDATE_OK',
    UpdateError = 'UPDATE_ERROR'
}

export function KvintaResourceStatusTypeFromJSON(json: any): KvintaResourceStatusType {
    return KvintaResourceStatusTypeFromJSONTyped(json, false);
}

export function KvintaResourceStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaResourceStatusType {
    return json as KvintaResourceStatusType;
}

export function KvintaResourceStatusTypeToJSON(value?: KvintaResourceStatusType | null): any {
    return value as any;
}

