import { Button, Menu, MenuItem } from '@material-ui/core';
import MaterialTable from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  ExportButton,
  KSidebarStore,
  PageContentStore,
  PAGE_CONTENT_STORE_ID,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { TradeItemsStore, STORE_ID } from 'kvinta/modules/master-data/trade-items/TradeItemsStore';
import { CreateTradeItemDialog } from 'kvinta/modules/master-data/trade-items/views/CreateTradeItemDialog';
import { Checkbox } from '@material-ui/core';
import { KvintaTradeItem } from 'kvinta/apis/kvinta-masterdata-service';
import { navigateProductPath, navigateProductsPath, navigateProductTradeItemPath } from '../paths';
import { TabBar } from 'kvinta/components';
import { tabsSummary } from './tabs';
import { CSVDownload } from 'react-csv';
import { ProductsStore, STORE_ID as PRODUCT_STORE_ID } from '../ProductsStore';
import { reaction } from 'mobx';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ITableActionsState } from 'kvinta/common';

interface ProductTradeItemsViewProps {
  tradeItemsStore?: TradeItemsStore;
  intl: any;
  history?: any;
  id: string;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  productsStore?: ProductsStore;
}

class ProductTradeItemsViewImpl extends Component<ProductTradeItemsViewProps, ITableActionsState> {
  tableRef: React.RefObject<unknown>;
  disposer: any;

  constructor(props: ProductTradeItemsViewProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.productsStore.fetchProduct(this.props.id);
    this.props.tradeItemsStore.resetFilter();
    this.props.tradeItemsStore.fetchProductTradeItemData(this.props.id);
    this.disposer = reaction(
      () => this.props.productsStore.currentProduct,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            navigateProductsPath(this.props.history);
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const intl = this.props.intl;
    if (!this.props.tradeItemsStore.listData) {
      return null;
    }

    const {
      listData,
      isLoading,
      page,
      totalCount,
      pageSize,
      tradeItemFormOpen,
      checked,
      listChecked,
      exportActive,
      exportData,
    } = this.props.tradeItemsStore;
    this.props.tradeItemsStore.updateExported();
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <TabBar
                onChange={(newValue: any) => {
                  if (newValue === '0') {
                    navigateProductsPath(this.props.history);
                  }
                  if (newValue === '1') {
                    navigateProductPath(this.props.history, this.props.id);
                  }
                }}
                tabId={'2'}
                tabs={tabsSummary}
              />

              <MaterialTable
                icons={tableIcons}
                tableRef={this.tableRef}
                title={intl.formatMessage({ id: 'menu.trade-items' })}
                columns={[
                  {
                    title: (
                      <Checkbox
                        id={'check-all'}
                        checked={checked > 0}
                        onChange={() => {
                          this.props.tradeItemsStore.toggleAll();
                        }}
                        checkedIcon={checked == 2 ? <CheckBoxIcon /> : <IndeterminateCheckBoxIcon />}
                      />
                    ),
                    field: 'isSelected',
                    sorting: false,
                    width: '10px',
                    render: (rowData) => (
                      <Checkbox
                        id={rowData.id}
                        checked={rowData.isSelected}
                        onChange={() => {
                          this.props.tradeItemsStore.toggleRow(rowData.id);
                        }}
                      />
                    ),
                  },
                  {
                    title: intl.formatMessage({ id: 'trade-item-list.header.gtin' }),
                    field: 'gtin',
                    defaultSort: 'desc',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '50%',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'trade-item-list.header.uom' }),
                    field: 'uom',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '10%',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'trade-item-list.header.updated' }),
                    field: 'updated',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '40%',
                    },
                    render: (rowData) => {
                      const date = new Date(rowData.updated);
                      const formatedDate =
                        ('0' + date.getDate()).slice(-2) +
                        '.' +
                        ('0' + (date.getMonth() + 1)).slice(-2) +
                        '.' +
                        date.getFullYear() +
                        ' ' +
                        ('0' + date.getHours()).slice(-2) +
                        ':' +
                        ('0' + date.getMinutes()).slice(-2);
                      return <span>{formatedDate}</span>;
                    },
                  },
                ]}
                data={listData}
                page={page}
                totalCount={totalCount}
                onChangePage={(page: number) => {
                  this.props.tradeItemsStore.fetchPage(page);
                }}
                onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                  this.props.tradeItemsStore.changeOrder(orderBy, orderDirection);
                }}
                isLoading={isLoading}
                options={{
                  actionsColumnIndex: 0,
                  paging: true,
                  sorting: true,
                  draggable: false,
                  search: false,
                  pageSize: pageSize,
                  pageSizeOptions: [pageSize],
                  minBodyHeight: bodyHeight,
                  maxBodyHeight: bodyHeight,
                  showTitle: false,
                  emptyRowsWhenPaging: false,
                  thirdSortClick: false,
                }}
                localization={{
                  header: {
                    actions: '',
                  },
                }}
                onRowClick={(event, rowData) => {
                  if (!(event.target instanceof HTMLInputElement)) {
                    navigateProductTradeItemPath(this.props.history, this.props.id, rowData.id);
                  }
                }}
                actions={[
                  {
                    icon: MoreVertIcon,
                    tooltip: 'Actions',
                    isFreeAction: false,
                    onClick: (event, rowData) => {
                      this.handleActionsMenuOpen(event, rowData);
                    },
                  },
                ]}
                //<MenuItem onClick={handleClose}>Profile</MenuItem>
                components={{
                  Toolbar: (props) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: '20px',
                        marginRight: '20px',
                      }}
                    >
                      {/* This div moves the buttons to the right */}
                      <div></div>
                      <div
                        style={{ minWidth: '300px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
                      >
                        <div style={{ marginLeft: '5px' }}>
                          <ExportButton
                            onExportAll={() => this.props.tradeItemsStore.exportAll()}
                            onExportSelected={() => this.props.tradeItemsStore.exportSelected()}
                            hasSelected={this.props.tradeItemsStore.checked > 0}
                          />
                        </div>
                        <div style={{ marginLeft: '5px' }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon color="secondary" />}
                            onClick={(e) => {
                              this.props.tradeItemsStore.openCreateTradeItemForm();
                            }}
                          >
                            {intl.formatMessage({ id: 'button.create' })}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ),
                }}
              />
            </div>
            {tradeItemFormOpen && <CreateTradeItemDialog />}
            {exportActive && exportData !== undefined && <CSVDownload data={exportData} target="_blank" />}
            <>
              <Menu
                id="row-action-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={this.state.isMenuOpen}
                onClose={this.onMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    const shouldDelete = confirm(
                      'Do you want to delete ' + (this.state.rowData as KvintaTradeItem).gtin + '?',
                    );
                    if (shouldDelete) {
                      this.props.tradeItemsStore.deleteTradeItem((this.state.rowData as KvintaTradeItem).id);
                    }
                    this.onMenuClose();
                  }}
                >
                  {intl.formatMessage({ id: 'button.delete' })}
                </MenuItem>
              </Menu>
            </>
          </div>
        )}
      />
    );
  }
}
export const ProductTradeItemsView = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, STORE_ID, PRODUCT_STORE_ID, SIDEBAR_STORE_ID)(observer(ProductTradeItemsViewImpl)),
);
