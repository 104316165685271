/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaResource,
    KvintaResourceFromJSON,
    KvintaResourceFromJSONTyped,
    KvintaResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaWriteTextDocumentRequest
 */
export interface KvintaWriteTextDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaWriteTextDocumentRequest
     */
    content: string;
    /**
     * 
     * @type {KvintaResource}
     * @memberof KvintaWriteTextDocumentRequest
     */
    resource: KvintaResource;
    /**
     * 
     * @type {string}
     * @memberof KvintaWriteTextDocumentRequest
     */
    sha256: string;
}

export function KvintaWriteTextDocumentRequestFromJSON(json: any): KvintaWriteTextDocumentRequest {
    return KvintaWriteTextDocumentRequestFromJSONTyped(json, false);
}

export function KvintaWriteTextDocumentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaWriteTextDocumentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': json['content'],
        'resource': KvintaResourceFromJSON(json['resource']),
        'sha256': json['sha256'],
    };
}

export function KvintaWriteTextDocumentRequestToJSON(value?: KvintaWriteTextDocumentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'resource': KvintaResourceToJSON(value.resource),
        'sha256': value.sha256,
    };
}


