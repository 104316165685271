/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCreateNotificationGroupRequest
 */
export interface KvintaCreateNotificationGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateNotificationGroupRequest
     */
    groupName?: string;
}

export function KvintaCreateNotificationGroupRequestFromJSON(json: any): KvintaCreateNotificationGroupRequest {
    return KvintaCreateNotificationGroupRequestFromJSONTyped(json, false);
}

export function KvintaCreateNotificationGroupRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateNotificationGroupRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupName': !exists(json, 'groupName') ? undefined : json['groupName'],
    };
}

export function KvintaCreateNotificationGroupRequestToJSON(value?: KvintaCreateNotificationGroupRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groupName': value.groupName,
    };
}


