/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaCreateCirculationReportForAtkRequestFields,
    KvintaCreateCirculationReportForAtkRequestFieldsFromJSON,
    KvintaCreateCirculationReportForAtkRequestFieldsFromJSONTyped,
    KvintaCreateCirculationReportForAtkRequestFieldsToJSON,
    KvintaResource,
    KvintaResourceFromJSON,
    KvintaResourceFromJSONTyped,
    KvintaResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaCreateCirculationReportForAtkRequest
 */
export interface KvintaCreateCirculationReportForAtkRequest {
    /**
     * 
     * @type {KvintaResource}
     * @memberof KvintaCreateCirculationReportForAtkRequest
     */
    resource?: KvintaResource;
    /**
     * 
     * @type {KvintaCreateCirculationReportForAtkRequestFields}
     * @memberof KvintaCreateCirculationReportForAtkRequest
     */
    fields?: KvintaCreateCirculationReportForAtkRequestFields;
}

export function KvintaCreateCirculationReportForAtkRequestFromJSON(json: any): KvintaCreateCirculationReportForAtkRequest {
    return KvintaCreateCirculationReportForAtkRequestFromJSONTyped(json, false);
}

export function KvintaCreateCirculationReportForAtkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateCirculationReportForAtkRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resource': !exists(json, 'resource') ? undefined : KvintaResourceFromJSON(json['resource']),
        'fields': !exists(json, 'fields') ? undefined : KvintaCreateCirculationReportForAtkRequestFieldsFromJSON(json['fields']),
    };
}

export function KvintaCreateCirculationReportForAtkRequestToJSON(value?: KvintaCreateCirculationReportForAtkRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resource': KvintaResourceToJSON(value.resource),
        'fields': KvintaCreateCirculationReportForAtkRequestFieldsToJSON(value.fields),
    };
}


