import { Button, Paper } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import { PageContentStore, TabBar, tableIcons, PAGE_CONTENT_STORE_ID, DefaultTitleHeight } from 'kvinta/components';
import { ATKListStore, STORE_ID } from '../ATKListStore';
import {
  navigateATKCirculationReportsPath,
  navigateATKJsonPath,
  navigateATKListPath,
  navigateATKSerialNumbersPath,
  navigateATKStatusesPath,
  navigateATKSummaryPath,
} from '../paths';
import { KvintaTextDocument } from 'kvinta/apis/kvinta-document-store';
import { tabsSummary } from './tabs';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CSVDownload } from 'react-csv';

interface ATKSerialNumbersViewProps {
  atkListStore?: ATKListStore;
  pageContentStore?: PageContentStore;
  currentATK?: KvintaTextDocument;
  intl: any;
  history?: any;
  id: string;
}

class ATKSerialNumbersViewImpl extends Component<ATKSerialNumbersViewProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ATKSerialNumbersViewProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.atkListStore.fetchATK(this.props.id);
  }

  render() {
    const intl = this.props.intl;
    const { currentATK, isLoading, exportSNActive, exportSNData } = this.props.atkListStore;

    const formData = currentATK;

    if (!formData || !formData.serialNumbers) {
      return null;
    }
    this.props.atkListStore.updateSNExported();
    // const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    const bodyHeight = this.props.pageContentStore.tableContentHeight(DefaultTitleHeight); // Button export and tabs
    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                <TabBar
                  onChange={(newValue: any) => {
                    if (newValue === '0') {
                      navigateATKListPath(this.props.history);
                    }
                    if (newValue === '1') {
                      navigateATKSummaryPath(this.props.history, this.props.id);
                    }
                    if (newValue === '2') {
                      navigateATKSerialNumbersPath(this.props.history, this.props.id);
                    }
                    if (newValue === '3') {
                      navigateATKJsonPath(this.props.history, this.props.id);
                    }
                    if (newValue === '4') {
                      navigateATKStatusesPath(this.props.history, this.props.id);
                    }
                    if (newValue === '5') {
                      navigateATKCirculationReportsPath(this.props.history, this.props.id);
                    }
                  }}
                  tabId={'2'}
                  tabs={tabsSummary}
                />
              </Paper>
              <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
                <MaterialTable
                  icons={tableIcons}
                  tableRef={this.tableRef}
                  title={null}
                  columns={[
                    {
                      title: intl.formatMessage({ id: 'atk.header.serial-number' }),
                      field: 'id', // should be timestamp but currently crashes for some reason
                      defaultSort: 'desc',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '25%',
                      },
                    },
                  ]}
                  data={formData.serialNumbers}
                  onChangePage={(page: number) => {
                    this.props.atkListStore.fetchPage(page);
                  }}
                  onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                    this.props.atkListStore.changeOrder(orderBy, orderDirection);
                  }}
                  isLoading={isLoading}
                  options={{
                    actionsColumnIndex: 0,
                    paging: false,
                    sorting: true,
                    draggable: false,
                    search: false,
                    minBodyHeight: bodyHeight,
                    maxBodyHeight: bodyHeight,
                    emptyRowsWhenPaging: false,
                    thirdSortClick: false,
                  }}
                  localization={{
                    header: {
                      actions: '',
                    },
                  }}
                  components={{
                    Toolbar: (props) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          marginTop: '20px',
                          marginRight: '20px',
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<GetAppIcon color="primary" />}
                          onClick={(e) => {
                            this.props.atkListStore.exportSNAll();
                          }}
                        >
                          {intl.formatMessage({ id: 'button.export' })}
                        </Button>
                      </div>
                    ),
                  }}
                />
              </Paper>
            </div>
            {exportSNActive && exportSNData !== undefined && <CSVDownload data={exportSNData} target="_blank" />}
          </div>
        )}
      />
    );
  }
}

export const ATKSerialNumbersView = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, STORE_ID)(observer(ATKSerialNumbersViewImpl)),
);
