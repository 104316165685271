/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaProduct
 */
export interface KvintaProduct {
    /**
     * 
     * @type {string}
     * @memberof KvintaProduct
     */
    baseUom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaProduct
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaProduct
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaProduct
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaProduct
     */
    idInSourceSystem?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaProduct
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaProduct
     */
    shelfLife?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaProduct
     */
    sku?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaProduct
     */
    sourceSystem?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaProduct
     */
    tnved?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaProduct
     */
    updated?: number;
}

export function KvintaProductFromJSON(json: any): KvintaProduct {
    return KvintaProductFromJSONTyped(json, false);
}

export function KvintaProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'baseUom': !exists(json, 'baseUom') ? undefined : json['baseUom'],
        'companyId': json['companyId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'idInSourceSystem': !exists(json, 'idInSourceSystem') ? undefined : json['idInSourceSystem'],
        'name': json['name'],
        'shelfLife': !exists(json, 'shelfLife') ? undefined : json['shelfLife'],
        'sku': !exists(json, 'sku') ? undefined : json['sku'],
        'sourceSystem': !exists(json, 'sourceSystem') ? undefined : json['sourceSystem'],
        'tnved': !exists(json, 'tnved') ? undefined : json['tnved'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function KvintaProductToJSON(value?: KvintaProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'baseUom': value.baseUom,
        'companyId': value.companyId,
        'description': value.description,
        'id': value.id,
        'idInSourceSystem': value.idInSourceSystem,
        'name': value.name,
        'shelfLife': value.shelfLife,
        'sku': value.sku,
        'sourceSystem': value.sourceSystem,
        'tnved': value.tnved,
        'updated': value.updated,
    };
}


