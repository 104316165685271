import { Link } from '@material-ui/core';
import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  KSidebarStore,
  PageContentStore,
  PAGE_CONTENT_STORE_ID,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { linkVerificationPath, navigateVerificationPath, VerificationStore, VERIFICATION_STORE_ID } from '.';
import { KvintaEpcisEventRecord } from 'kvinta/apis/kvinta-arla-extensions';

interface ViewVerificationListProps {
  verificationStore?: VerificationStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
}

class ViewVerificationListImpl extends Component<ViewVerificationListProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ViewVerificationListProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  async componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.verificationStore.fetchData();
  }
  navigateVerificationSummary = (history: any, eventId: string) => {
    navigateVerificationPath(history, eventId);
  };

  render() {
    const intl = this.props.intl;
    const { verificationData, isLoading, page, totalCount, pageSize } = this.props.verificationStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightThin;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
    return (
      <Route
        render={({ history }) => (
          <div>
            <MaterialTable
              icons={tableIcons}
              tableRef={this.tableRef}
              columns={[
                {
                  title: intl.formatMessage({ id: 'shipping-list.header.eventtime' }),
                  field: 'eventTime',
                  cellStyle: {
                    whiteSpace: 'nowrap',
                  },
                  render: (data: KvintaEpcisEventRecord) => {
                    const formatedDate = new Date(data.eventTime.epochMillis).toISOString();
                    return (
                      <Link href={linkVerificationPath(data.eventID)} onClick={preventDefault}>
                        {formatedDate}
                      </Link>
                    );
                  },
                },
                {
                  title: intl.formatMessage({ id: 'shipping-list.header.recordtime' }),
                  field: 'recordTime',
                  defaultSort: 'desc',
                  cellStyle: {
                    whiteSpace: 'nowrap',
                  },
                  render: (data: KvintaEpcisEventRecord) => {
                    const formatedDate = new Date(data.recordTime).toISOString();
                    return (
                      <Link href={linkVerificationPath(data.eventID)} onClick={preventDefault}>
                        {formatedDate}
                      </Link>
                    );
                  },
                },
                {
                  title: intl.formatMessage({ id: 'verification-list.header.bizLocation' }),
                  field: 'bizLocation',
                  cellStyle: {
                    whiteSpace: 'nowrap',
                  },
                },
                {
                  title: intl.formatMessage({ id: 'verification-list.header.bizStep' }),
                  field: 'bizStep',
                  cellStyle: {
                    whiteSpace: 'nowrap',
                  },
                },
                {
                  title: intl.formatMessage({ id: 'verification-list.header.bizTransaction' }),
                  field: 'bizTransaction',
                  headerStyle: {
                    whiteSpace: 'nowrap',
                  },
                  cellStyle: {
                    whiteSpace: 'nowrap',
                  },
                },
                {
                  title: intl.formatMessage({ id: 'verification-list.header.disposition' }),
                  field: 'disposition',
                  cellStyle: {
                    whiteSpace: 'nowrap',
                  },
                },
                {
                  title: intl.formatMessage({ id: 'verification-list.header.documentId' }),
                  field: 'documentId',
                  cellStyle: {
                    whiteSpace: 'nowrap',
                  },
                },
              ]}
              data={verificationData}
              page={page}
              totalCount={totalCount}
              onChangePage={(page: number) => {
                this.props.verificationStore.fetchPage(page);
              }}
              onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                this.props.verificationStore.changeOrder(orderBy, orderDirection);
              }}
              isLoading={isLoading}
              options={{
                actionsColumnIndex: -1,
                paging: true,
                sorting: true,
                draggable: false,
                search: false,
                pageSize: pageSize,
                pageSizeOptions: [pageSize],
                minBodyHeight: bodyHeight,
                maxBodyHeight: bodyHeight,
                emptyRowsWhenPaging: false,
                thirdSortClick: false,
                rowStyle: (rowData) => ({
                  backgroundColor: rowData.index == 0 ? '#EEE' : '#FFF',
                }),
                showTitle: false,
              }}
              onRowClick={(event, rowData) => {
                this.navigateVerificationSummary(history, rowData.eventID);
              }}
              components={{
                Toolbar: () => null,
              }}
            />
          </div>
        )}
      />
    );
  }
}
export const ViewVerificationList = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, VERIFICATION_STORE_ID, SIDEBAR_STORE_ID)(observer(ViewVerificationListImpl)),
);
