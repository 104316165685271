/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecution,
    KvintaExecutionFromJSON,
    KvintaExecutionFromJSONTyped,
    KvintaExecutionToJSON,
    KvintaResource,
    KvintaResourceFromJSON,
    KvintaResourceFromJSONTyped,
    KvintaResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaScheduleExecutionRequest
 */
export interface KvintaScheduleExecutionRequest {
    /**
     * 
     * @type {KvintaExecution}
     * @memberof KvintaScheduleExecutionRequest
     */
    execution: KvintaExecution;
    /**
     * 
     * @type {KvintaResource}
     * @memberof KvintaScheduleExecutionRequest
     */
    input: KvintaResource;
}

export function KvintaScheduleExecutionRequestFromJSON(json: any): KvintaScheduleExecutionRequest {
    return KvintaScheduleExecutionRequestFromJSONTyped(json, false);
}

export function KvintaScheduleExecutionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaScheduleExecutionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'execution': KvintaExecutionFromJSON(json['execution']),
        'input': KvintaResourceFromJSON(json['input']),
    };
}

export function KvintaScheduleExecutionRequestToJSON(value?: KvintaScheduleExecutionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'execution': KvintaExecutionToJSON(value.execution),
        'input': KvintaResourceToJSON(value.input),
    };
}


