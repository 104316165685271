/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisEventRecord,
    KvintaEpcisEventRecordFromJSON,
    KvintaEpcisEventRecordFromJSONTyped,
    KvintaEpcisEventRecordToJSON,
    KvintaTextDocumentRecord,
    KvintaTextDocumentRecordFromJSON,
    KvintaTextDocumentRecordFromJSONTyped,
    KvintaTextDocumentRecordToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaShipmentAggregatedCustomsDocument
 */
export interface KvintaShipmentAggregatedCustomsDocument {
    /**
     * 
     * @type {KvintaEpcisEventRecord}
     * @memberof KvintaShipmentAggregatedCustomsDocument
     */
    epcisEventRecord?: KvintaEpcisEventRecord;
    /**
     * 
     * @type {KvintaTextDocumentRecord}
     * @memberof KvintaShipmentAggregatedCustomsDocument
     */
    aggregatedCustomsDocumentRecord?: KvintaTextDocumentRecord;
}

export function KvintaShipmentAggregatedCustomsDocumentFromJSON(json: any): KvintaShipmentAggregatedCustomsDocument {
    return KvintaShipmentAggregatedCustomsDocumentFromJSONTyped(json, false);
}

export function KvintaShipmentAggregatedCustomsDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaShipmentAggregatedCustomsDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epcisEventRecord': !exists(json, 'epcisEventRecord') ? undefined : KvintaEpcisEventRecordFromJSON(json['epcisEventRecord']),
        'aggregatedCustomsDocumentRecord': !exists(json, 'aggregatedCustomsDocumentRecord') ? undefined : KvintaTextDocumentRecordFromJSON(json['aggregatedCustomsDocumentRecord']),
    };
}

export function KvintaShipmentAggregatedCustomsDocumentToJSON(value?: KvintaShipmentAggregatedCustomsDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epcisEventRecord': KvintaEpcisEventRecordToJSON(value.epcisEventRecord),
        'aggregatedCustomsDocumentRecord': KvintaTextDocumentRecordToJSON(value.aggregatedCustomsDocumentRecord),
    };
}


