export const EpcMessagesPath = '/epc-list';
export const EpcMessagePath = '/epc-list/:id/:ts/:index';
export const EpcMessageHierarchyPath = '/epc-list/:id/:ts/:index/hierarchy';
export const EpcMessageMapPath = '/epc-list/:id/:ts/:index/map';

export function navigateEpcMessagesPath(history: any) {
  history.push(EpcMessagesPath);
}

export function navigateEpcMessagePath(history: any, msgId: string, ts: number, idx?: number) {
  history.push(epcRecordPath(EpcMessagePath, msgId, ts, idx));
}

export function linkEpcMessagePath(history: any, msgId: string, ts: number, idx?: number): string {
  return epcRecordPath(EpcMessagePath, msgId, ts, idx);
}

export function navigateEpcMessageHierarchyPath(history: any, msgId: string, ts: number, idx?: number) {
  history.push(epcRecordPath(EpcMessageHierarchyPath, msgId, ts, idx));
}

export function navigateEpcMessageMapPath(history: any, msgId: string, ts: number, idx?: number) {
  history.push(epcRecordPath(EpcMessageMapPath, msgId, ts, idx));
}

export function escapeId(id: string) {
  // console.log('Escape', id);
  return btoa(id);
}
export function unescapeId(id: string) {
  // console.log('Unescape', id);
  return atob(id);
  // return id.replaceAll('__', '\\').replaceAll('_-_', '%');
}

function epcRecordPath(path: string, id: string, ts: number, idx?: number) {
  return path
    .replaceAll(':id', escapeId(id))
    .replaceAll(':ts', ts.toString())
    .replaceAll(':index', idx ? idx.toString() : '0');
}
