import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { tabs } from './tabs';
import GetApp from '@material-ui/icons/GetApp';

import React, { Component } from 'react';
import { CirculationReportsStore, CIRCULATION_REPORTS_STORE_ID } from './CirculationReportsStore';
import { PageContentStore, PAGE_CONTENT_STORE_ID, TabBar } from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { Button, Paper } from '@material-ui/core';
import {
  navigateCirculationReportJsonPath,
  navigateCirculationReportSummaryPath,
  navigateCirculationReportsListPath,
} from './paths';
import { tabBarHeight } from 'kvinta/common';
import MaterialTable, { MTableToolbar } from 'material-table';
import { tableIcons } from 'kvinta/components';
import { CSVDownload } from 'react-csv';

interface ViewCirculationReportStatusesProps {
  intl: any;
  history?: any;
  circulationReportsStore: CirculationReportsStore;
  pageContentStore: PageContentStore;
  id: string;
}
class ViewCirculationReportStatusesImpl extends Component<ViewCirculationReportStatusesProps> {
  tableRef: React.RefObject<unknown>;

  componentDidMount() {
    this.props.circulationReportsStore.fetchCirculationReport(this.props.id);
    this.tableRef = React.createRef();
  }

  componentWillUnmount() {
    this.tableRef = undefined;
  }

  render() {
    const intl = this.props.intl;
    const currentCirculationReport = this.props.circulationReportsStore.currentCirculationReport;
    if (!currentCirculationReport) {
      return null;
    }
    const { width, height } = this.props.pageContentStore;
    const newHeight = height - tabBarHeight - 134; // toolbar
    const listData = this.props.circulationReportsStore.currentStatusList;
    const { isLoading, exportActive, exportData } = this.props.circulationReportsStore;
    this.props.circulationReportsStore.updateExported(); // Prevent further exports
    return (
      <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              navigateCirculationReportsListPath(this.props.history);
            }
            if (newValue === '1') {
              navigateCirculationReportSummaryPath(this.props.history, this.props.id);
            }
            if (newValue === '3') {
              navigateCirculationReportJsonPath(this.props.history, this.props.id);
            }
          }}
          tabId={'2'}
          tabs={tabs}
        />
        <MaterialTable
          icons={tableIcons}
          tableRef={this.tableRef}
          title={intl.formatMessage({ id: 'atk.header.status-list' })}
          columns={[
            {
              title: intl.formatMessage({ id: 'circulation-report-status-list.header.status' }),
              field: 'status', // should be timestamp but currently crashes for some reason
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: intl.formatMessage({ id: 'circulation-report-status-list.header.timestamp' }),
              field: 'timestampStr',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: intl.formatMessage({ id: 'circulation-report-status-list.header.executionid' }),
              field: 'executionId',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: intl.formatMessage({ id: 'circulation-report-status-list.header.error' }),
              field: 'error',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
          ]}
          data={listData}
          isLoading={isLoading}
          options={{
            actionsColumnIndex: 0,
            paging: true,
            sorting: true,
            draggable: false,
            search: false,
            pageSize: 10,
            pageSizeOptions: [10],
            minBodyHeight: newHeight,
            maxBodyHeight: newHeight,
            thirdSortClick: false,
            emptyRowsWhenPaging: false,
          }}
          localization={{
            header: {
              actions: '',
            },
          }}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: '20px',
                  marginRight: '20px',
                }}
              >
                <div style={{ minWidth: '180px' }}>
                  <MTableToolbar {...props} />
                </div>

                <div style={{ marginLeft: '5px' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<GetApp color="primary" />}
                    onClick={(e) => {
                      this.props.circulationReportsStore.exportCurrentStatuses();
                    }}
                  >
                    {intl.formatMessage({ id: 'button.export' })}
                  </Button>
                </div>
              </div>
            ),
          }}
        />
        {exportActive && exportData !== undefined && <CSVDownload data={exportData} target="_blank" />}
      </Paper>
    );
  }
}
export const ViewCirculationReportStatuses = injectIntl(
  inject(CIRCULATION_REPORTS_STORE_ID, PAGE_CONTENT_STORE_ID)(observer(ViewCirculationReportStatusesImpl)),
);
