/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDateTimeOpenRange,
    KvintaDateTimeOpenRangeFromJSON,
    KvintaDateTimeOpenRangeFromJSONTyped,
    KvintaDateTimeOpenRangeToJSON,
    KvintaSerialNumberOrderType,
    KvintaSerialNumberOrderTypeFromJSON,
    KvintaSerialNumberOrderTypeFromJSONTyped,
    KvintaSerialNumberOrderTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaSerialNumberOrderFilter
 */
export interface KvintaSerialNumberOrderFilter {
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrderFilter
     */
    contactPerson?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrderFilter
     */
    contactPersonLike?: string | null;
    /**
     * 
     * @type {KvintaDateTimeOpenRange}
     * @memberof KvintaSerialNumberOrderFilter
     */
    created?: KvintaDateTimeOpenRange;
    /**
     * 
     * @type {KvintaDateTimeOpenRange}
     * @memberof KvintaSerialNumberOrderFilter
     */
    documentDateRange?: KvintaDateTimeOpenRange;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrderFilter
     */
    documentNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrderFilter
     */
    documentNumberLike?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrderFilter
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrderFilter
     */
    profileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrderFilter
     */
    profileIdLike?: string | null;
    /**
     * 
     * @type {KvintaSerialNumberOrderType}
     * @memberof KvintaSerialNumberOrderFilter
     */
    serialNumberOrderType?: KvintaSerialNumberOrderType;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrderFilter
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrderFilter
     */
    tenantIdLike?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrderFilter
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrderFilter
     */
    userIdLike?: string | null;
}

export function KvintaSerialNumberOrderFilterFromJSON(json: any): KvintaSerialNumberOrderFilter {
    return KvintaSerialNumberOrderFilterFromJSONTyped(json, false);
}

export function KvintaSerialNumberOrderFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSerialNumberOrderFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactPerson': !exists(json, 'contactPerson') ? undefined : json['contactPerson'],
        'contactPersonLike': !exists(json, 'contactPersonLike') ? undefined : json['contactPersonLike'],
        'created': !exists(json, 'created') ? undefined : KvintaDateTimeOpenRangeFromJSON(json['created']),
        'documentDateRange': !exists(json, 'documentDateRange') ? undefined : KvintaDateTimeOpenRangeFromJSON(json['documentDateRange']),
        'documentNumber': !exists(json, 'documentNumber') ? undefined : json['documentNumber'],
        'documentNumberLike': !exists(json, 'documentNumberLike') ? undefined : json['documentNumberLike'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'profileId': !exists(json, 'profileId') ? undefined : json['profileId'],
        'profileIdLike': !exists(json, 'profileIdLike') ? undefined : json['profileIdLike'],
        'serialNumberOrderType': !exists(json, 'serialNumberOrderType') ? undefined : KvintaSerialNumberOrderTypeFromJSON(json['serialNumberOrderType']),
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'tenantIdLike': !exists(json, 'tenantIdLike') ? undefined : json['tenantIdLike'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userIdLike': !exists(json, 'userIdLike') ? undefined : json['userIdLike'],
    };
}

export function KvintaSerialNumberOrderFilterToJSON(value?: KvintaSerialNumberOrderFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactPerson': value.contactPerson,
        'contactPersonLike': value.contactPersonLike,
        'created': KvintaDateTimeOpenRangeToJSON(value.created),
        'documentDateRange': KvintaDateTimeOpenRangeToJSON(value.documentDateRange),
        'documentNumber': value.documentNumber,
        'documentNumberLike': value.documentNumberLike,
        'id': value.id,
        'profileId': value.profileId,
        'profileIdLike': value.profileIdLike,
        'serialNumberOrderType': KvintaSerialNumberOrderTypeToJSON(value.serialNumberOrderType),
        'tenantId': value.tenantId,
        'tenantIdLike': value.tenantIdLike,
        'userId': value.userId,
        'userIdLike': value.userIdLike,
    };
}


