import { TabSpec } from 'kvinta/components';

export const tabsSummary = [
  {
    label: 'common.summary',
    value: '1',
  },
  {
    label: 'menu.deviceAssignments',
    value: '2',
  },
] as TabSpec[];

export const tabsAssignmentSummaryView = [
  ...tabsSummary,
  {
    label: 'common.assignment-summary',
    value: '3',
  },
] as TabSpec[];
