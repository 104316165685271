/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaTimestamp,
    KvintaTimestampFromJSON,
    KvintaTimestampFromJSONTyped,
    KvintaTimestampToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaUsedSerialNumber
 */
export interface KvintaUsedSerialNumber {
    /**
     * 
     * @type {string}
     * @memberof KvintaUsedSerialNumber
     */
    gtin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaUsedSerialNumber
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaUsedSerialNumber
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaUsedSerialNumber
     */
    raw: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaUsedSerialNumber
     */
    requestId: string;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaUsedSerialNumber
     */
    returned?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KvintaUsedSerialNumber
     */
    serialId: string;
    /**
     * 
     * @type {KvintaTimestamp}
     * @memberof KvintaUsedSerialNumber
     */
    timestamp: KvintaTimestamp;
}

export function KvintaUsedSerialNumberFromJSON(json: any): KvintaUsedSerialNumber {
    return KvintaUsedSerialNumberFromJSONTyped(json, false);
}

export function KvintaUsedSerialNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaUsedSerialNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gtin': !exists(json, 'gtin') ? undefined : json['gtin'],
        'id': json['id'],
        'location': json['location'],
        'raw': json['raw'],
        'requestId': json['requestId'],
        'returned': !exists(json, 'returned') ? undefined : json['returned'],
        'serialId': json['serialId'],
        'timestamp': KvintaTimestampFromJSON(json['timestamp']),
    };
}

export function KvintaUsedSerialNumberToJSON(value?: KvintaUsedSerialNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gtin': value.gtin,
        'id': value.id,
        'location': value.location,
        'raw': value.raw,
        'requestId': value.requestId,
        'returned': value.returned,
        'serialId': value.serialId,
        'timestamp': KvintaTimestampToJSON(value.timestamp),
    };
}


