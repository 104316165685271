import { Button, IconButton, InputAdornment, MenuItem, Select } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CancelIcon from '@material-ui/icons/Cancel';
import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  KSidebarStore,
  PageContentStore,
  PAGE_CONTENT_STORE_ID,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { FilterBar } from 'kvinta/components';
import { navigateSequenceStatusPath } from 'kvinta/modules/paths';
import { SequenceStatusStore, SEQUENCE_STATUS_STORE_ID, statusesList } from './SequenceStatusStore';

interface ListSequenceStatusesViewProps {
  sequenceStatusStore?: SequenceStatusStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
}
export const bizstepList = [
  'urn:epcglobal:cbv:bizstep:commissioning',
  'urn:epcglobal:cbv:bizstep:decommissioning',
  'urn:epcglobal:cbv:bizstep:packing',
  'urn:epcglobal:cbv:bizstep:unpacking',
  'urn:epcglobal:cbv:bizstep:receiving',
  'urn:epcglobal:cbv:bizstep:shipping',
];
class ListSequenceStatusesViewImpl extends Component<ListSequenceStatusesViewProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ListSequenceStatusesViewProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    const statuses = statusesList.map((value, index) => ({ index, label: value }));
    const bizSteps = bizstepList.map((value) => ({ value, label: value }));
    // const bizSteps = Object.values(KvintaEpcisBizStep).map((value) => ({ value, label: value }));
    // const statuses = Object.values(KvintaSequenceStatus).map((value) => ({ value, label: value }));
    // Don't reset filter if it is initialized
    if (!this.props.sequenceStatusStore.filter) {
      this.props.sequenceStatusStore.setFilter([
        {
          field: 'eventId',
          label: 'sequence-status-list.eventId', // TODO: List
          isActive: true,
          value: '',
        },
        {
          field: 'epc',
          label: 'sequence-status-list.epc', // TODO: List
          isActive: false,
          value: '',
        },
        {
          field: 'bizStep',
          label: 'sequence-status-list.bizStep', // TODO: List
          isActive: false,
          value: '',
          render: (props) => {
            return (
              <Select
                id={props.fieldId}
                style={{ height: '40px', minWidth: '200px' }}
                label={props.translatedLabel}
                value={props.value}
                onChange={(e) => {
                  props.store.onChangeValue(props.field, e.target.value as string);
                }}
                endAdornment={
                  props.isFieldEmpty ? (
                    <InputAdornment position="end">
                      <IconButton
                        // style={{ marginLeft: 6 }}
                        className="removeFilter"
                        onClick={(e) => {
                          props.store.hideFilter(props.field);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : props.isApplied ? (
                    <InputAdornment position="end">
                      <IconButton
                        className="clearFilter"
                        onClick={(e) => {
                          props.store.onClear(props.field);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <IconButton
                        className="removeFilter"
                        onClick={(e) => {
                          props.store.hideFilter(props.field);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <IconButton
                        className="clearFilter"
                        onClick={(e) => {
                          props.store.onClear(props.field);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <Button
                        variant="contained"
                        color="primary"
                        className="applyFilter"
                        onClick={(e) => {
                          props.store.onFilter(props.field);
                        }}
                      >
                        {props.intl.formatMessage({ id: 'button.apply' })}
                      </Button>
                      <IconButton
                        className="removeFilter"
                        onClick={(e) => {
                          props.store.hideFilter(props.field);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {bizSteps.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            );
          },
        },
        {
          field: 'status',
          label: 'sequence-status-list.status',
          isActive: false,
          value: '',
          render: (props) => {
            return (
              <Select
                id={props.fieldId}
                style={{ height: '40px', minWidth: '200px' }}
                label={props.translatedLabel}
                value={props.value}
                onChange={(e) => {
                  props.store.onChangeValue(props.field, e.target.value as string);
                }}
                endAdornment={
                  props.isFieldEmpty ? (
                    <InputAdornment position="end">
                      <IconButton
                        // style={{ marginLeft: 6 }}
                        className="removeFilter"
                        onClick={(e) => {
                          props.store.hideFilter(props.field);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : props.isApplied ? (
                    <InputAdornment position="end">
                      <IconButton
                        className="clearFilter"
                        onClick={(e) => {
                          props.store.onClear(props.field);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <IconButton
                        className="removeFilter"
                        onClick={(e) => {
                          props.store.hideFilter(props.field);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <IconButton
                        className="clearFilter"
                        onClick={(e) => {
                          props.store.onClear(props.field);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <Button
                        variant="contained"
                        color="primary"
                        className="applyFilter"
                        onClick={(e) => {
                          props.store.onFilter(props.field);
                        }}
                      >
                        {props.intl.formatMessage({ id: 'button.apply' })}
                      </Button>
                      <IconButton
                        className="removeFilter"
                        onClick={(e) => {
                          props.store.hideFilter(props.field);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {statuses.map((item) => (
                  <MenuItem key={item.index} value={item.index}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            );
          },
        },
        {
          field: 'dependsOnEpc',
          label: 'sequence-status-list.dependencies', // TODO: List
          isActive: false,
          value: '',
        },
      ]);
    }
    if (
      this.props.sequenceStatusStore &&
      this.props.sequenceStatusStore.navigationFilter &&
      this.props.sequenceStatusStore.navigationFilter.length > 0
    ) {
      for (const fc of this.props.sequenceStatusStore.navigationFilter) {
        this.props.sequenceStatusStore.filter.showFilter(fc.field);
        this.props.sequenceStatusStore.filter.onChangeValue(fc.field, fc.value);
      }
      this.props.sequenceStatusStore.navigationFilter = [];
    }
    this.props.sequenceStatusStore.fetchData();
  }

  navigateSequenceStatus = (id: number) => {
    navigateSequenceStatusPath(this.props.history, id);
  };

  render() {
    const intl = this.props.intl;
    if (!this.props.sequenceStatusStore.filter) {
      return null;
    }

    const { listData, isLoading, page, totalCount, pageSize, filter } = this.props.sequenceStatusStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <MaterialTable
                icons={tableIcons}
                tableRef={this.tableRef}
                // title={intl.formatMessage({ id: 'menu.circulation-reports' })}
                columns={[
                  {
                    title: intl.formatMessage({ id: 'sequence-status-list.repStatusId' }),
                    field: 'repStatusId',
                    defaultSort: 'desc',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'sequence-status-list.status' }),
                    field: 'status',
                    render: (rowData) => {
                      let newStatus;
                      statusesList.map((value, index) => {
                        if (index == (rowData.status as any)) {
                          newStatus = value;
                        }
                      });
                      switch (newStatus) {
                        case 'STATUS_INITIAL':
                        case 'STATUS_CREATED':
                          return <span style={{ color: '#737D8F', whiteSpace: 'nowrap' }}>{newStatus}</span>;
                        case 'STATUS_ERROR':
                          return (
                            <span style={{ color: '#CD0843', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                              {newStatus}
                            </span>
                          );
                        default:
                          return (
                            <span style={{ color: '#00C3A0', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                              {newStatus}
                            </span>
                          );
                      }
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'sequence-status-list.eventId' }),
                    field: 'eventId',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'sequence-status-list.epc' }),
                    field: 'epc',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'sequence-status-list.bizStep' }),
                    field: 'bizStep',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },

                  {
                    title: intl.formatMessage({ id: 'sequence-status-list.createTimestamp' }),
                    field: 'createTimestamp',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                    render: (rowData) => {
                      const formatedDate = new Date(rowData.createTimestamp).toISOString();
                      return <span>{formatedDate}</span>;
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'sequence-status-list.updateTimestamp' }),
                    field: 'updateTimestamp',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                    render: (rowData) => {
                      const formatedDate = new Date(rowData.updateTimestamp).toISOString();
                      return <span>{formatedDate}</span>;
                    },
                  },
                  // {
                  //   title: intl.formatMessage({ id: 'sequence-status-list.dependencies' }),
                  //   field: 'dependencies',
                  //   cellStyle: {
                  //     whiteSpace: 'nowrap',
                  //   },
                  // },
                ]}
                data={listData}
                page={page}
                totalCount={totalCount}
                onChangePage={(page: number) => {
                  this.props.sequenceStatusStore.fetchPage(page);
                }}
                onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                  this.props.sequenceStatusStore.changeOrder(orderBy, orderDirection);
                }}
                isLoading={isLoading}
                options={{
                  actionsColumnIndex: 0,
                  paging: true,
                  sorting: true,
                  draggable: false,
                  search: false,
                  pageSize: pageSize,
                  pageSizeOptions: [pageSize],
                  minBodyHeight: bodyHeight,
                  maxBodyHeight: bodyHeight,
                  showTitle: false,
                  thirdSortClick: false,
                  emptyRowsWhenPaging: false,
                }}
                localization={{
                  header: {
                    actions: '',
                  },
                }}
                onRowClick={(event, rowData) => {
                  if (!(event.target instanceof HTMLInputElement)) {
                    this.props.sequenceStatusStore.currentRepStatusId = rowData.repStatusId;
                    this.navigateSequenceStatus(rowData.repStatusId);
                  }
                }}
                components={{
                  Toolbar: (props) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: '20px',
                        marginRight: '20px',
                        marginLeft: '11px',
                      }}
                    >
                      <FilterBar filter={filter} />
                    </div>
                  ),
                }}
              />
            </div>
          </div>
        )}
      />
    );
  }
}
export const ListSequenceStatusesView = injectIntl(
  inject(SEQUENCE_STATUS_STORE_ID, PAGE_CONTENT_STORE_ID, SIDEBAR_STORE_ID)(observer(ListSequenceStatusesViewImpl)),
);
