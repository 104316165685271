import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';
import { DefaultApi as MDDocumentApi, KvintaSortBy } from 'kvinta/apis/kvinta-masterdata-service';
import { NotificationManager } from 'kvinta/modules/main';
import {
  KvintaOperationStatus,
  KvintaResourceType,
  KvintaSoftwareSystem,
  KvintaTextDocumentRecord,
  KvintaTextDocument,
  KvintaResourceStatus,
  KvintaSortExpressions,
  KvintaSortDirection,
} from 'kvinta/apis/kvinta-document-store/models';
import { DefaultApi as DocumentApi } from 'kvinta/apis/kvinta-document-store';
import {
  DefaultApi as ArlaStoreApi,
  KvintaResourceType as ArlaKvintaResourceType,
} from 'kvinta/apis/kvinta-arla-extensions';
import { IFilter } from 'kvinta/components';
import { SelectableStore } from 'kvinta/common';
import { format } from 'date-fns';

interface ICirculationReportsRow extends KvintaTextDocumentRecord, ISelectableRow {}

interface ExtKvintaResourceStatus extends KvintaResourceStatus {
  timestampStr: string;
}

interface ExtKvintaTextDocument extends KvintaTextDocument {
  createdByExecutionId: string;
  tradeParticipantInn: string;
  declarationNumber: string;
  declarationDate: string;
  numberOfSerials: number;
}
export class CirculationReportsStore extends SelectableStore<ICirculationReportsRow> {
  private _config: TAppOptionsConfig;
  private _docApi: DocumentApi;
  private _mdApi: MDDocumentApi;
  private _arlaApi: ArlaStoreApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  page: number;
  totalCount: number;
  pageSize: number;
  currentSort: KvintaSortExpressions;
  filter: IFilter;
  currentCirculationReport?: KvintaTextDocument;
  currentCirculationReportId?: string;

  exportActive: boolean;
  exportData: KvintaResourceStatus[] | null;

  constructor(
    config: TAppOptionsConfig,
    notificationManager: NotificationManager,
    mdApi: MDDocumentApi,
    docApi: DocumentApi,
    arlaApi: ArlaStoreApi,
  ) {
    super();
    makeObservable(this, {
      fetchPage: action.bound,
      fetchData: action.bound,
      fetchCirculationReport: action.bound,

      releaseCurrentCirculationReport: action.bound,

      updateExported: action,

      isLoading: observable,
      page: observable,
      pageSize: observable,
      currentCirculationReport: observable,
      currentCirculationReportId: observable,
      totalCount: observable,

      jsonPayload: computed,

      releaseButtonActive: computed,

      exportActive: observable,

      currentStatusList: computed,
    });

    this._config = config;
    this._mdApi = mdApi;
    this._docApi = docApi;
    this._arlaApi = arlaApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;

    this.currentSort = {
      expressions: [
        {
          direction: KvintaSortDirection.Desc,
          property: 'timestamp',
        },
      ],
    };

    this.filter = new IFilter(
      [
        {
          field: 'timestamp',
          label: 'atk-list.header.time',
          isActive: true,
          value: '',
        },
        {
          field: 'userId',
          label: 'atk-list.header.user',
          isActive: false,
          value: '',
        },
        {
          field: 'lastStatus',
          label: 'atk-list.header.last-status',
          isActive: false,
          value: '',
        },
      ],
      this.doFilter,
    );
  }

  async fetchPage(page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
    });
    this.fetchData();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    runInAction(() => {
      this.isLoading = true;
      const field = getField(orderBy);
      this.currentSort = {
        expressions: [
          {
            property: field,
            direction: orderDirection === 'asc' ? KvintaSortDirection.Asc : KvintaSortDirection.Desc,
          },
        ],
      };
    });
    this.fetchData();
  }

  doFilter = async () => {
    runInAction(() => {
      this.isLoading = true;
      this.page = 0;
    });
    this.fetchData();
  };

  async fetchData() {
    this.listData = [];
    this.totalCount = 0;
    let filters = { type: 'CIRCULATION_ATK_REPORT' } as { [key: string]: string } | null;

    for (const filter of this.filter.visibleFilters) {
      filters = {
        ...filters,
        [filter.field]: filter.value,
      };
    }
    this._docApi
      .listTextDocuments({
        kvintaOperationRequestListTextDocumentsRequest: {
          input: { paging: { page: this.page, size: this.pageSize, sort: this.currentSort }, filter: filters },
        },
      })
      .then((result) => {
        runInAction(() => {
          this.isLoading = false;
          if (result.status === KvintaOperationStatus.Error) {
            this.listData = [];
            this._notificationManager.sendError(result.error);
          } else {
            this.listData = (result.data.list || []).map((comp) => {
              return { ...comp } as ICirculationReportsRow;
            });
            this.totalCount = result.data.total || 0;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async fetchCirculationReport(id: string) {
    runInAction(() => {
      this.currentCirculationReport = undefined;
      this.isLoading = true;
      this.currentCirculationReportId = undefined;
    });
    try {
      const fetchRep = await this._docApi.readTextDocumentWithStatuses({
        kvintaOperationRequestResource: {
          input: {
            system: KvintaSoftwareSystem.Kvinta,
            id: id,
            type: KvintaResourceType.CirculationAtkReport,
          },
        },
      });
      if (fetchRep.status === KvintaOperationStatus.Ok) {
        runInAction(() => {
          this.currentCirculationReport = fetchRep.data;
          this.currentCirculationReportId = id;
          this.isLoading = false;
        });
      } else {
        this._notificationManager.sendError(fetchRep.error);
        runInAction(() => {
          this.isLoading = false;
        });
      }
    } catch (err) {
      this._notificationManager.sendError(JSON.stringify(err));
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  get jsonPayload() {
    if (this.currentCirculationReport) {
      const crWithContent = {
        ...this.currentCirculationReport,
        content: JSON.parse(this.currentCirculationReport.content),
      };
      return JSON.stringify(crWithContent, null, 2);
    } else {
      return 'No data available';
    }
  }

  get currentCRExt() {
    if (!this.currentCirculationReport) {
      return undefined;
    }
    const docContent = JSON.parse(this.currentCirculationReport.content);
    const timestamp = this.currentCirculationReport.record.timestamp?.epochMillis
      ? this.formatDateTime(this.currentCirculationReport.record.timestamp?.epochMillis)
      : '';
    return {
      ...this.currentCirculationReport,
      timestamp: timestamp,
      createdByExecutionId: this.currentCirculationReport.record.context.executionId,
      tradeParticipantInn: docContent.tradeParticipantInn,
      declarationNumber: docContent.declarationNumber,
      declarationDate: docContent.declarationDate,
      numberOfSerials: docContent.productsList === undefined ? 0 : docContent.productsList.length,
    };
  }

  get currentStatusList(): ExtKvintaResourceStatus[] {
    if (this.currentCirculationReport && this.currentCirculationReport.record.statuses) {
      return this.currentCirculationReport.record.statuses.map((rec) => {
        return { ...rec, timestampStr: new Date(rec.timestamp.epochMillis).toISOString() };
      });
    } else {
      return [];
    }
  }

  get releaseButtonActive() {
    return true; // Always active
  }

  releaseCurrentCirculationReport = async () => {
    runInAction(() => {
      this.isLoading = true;
    });
    try {
      const result = await this._arlaApi.releaseIntroduceIntoCirculationDocumentR({
        kvintaOperationRequestResource: {
          input: {
            system: KvintaSoftwareSystem.Kvinta,
            type: ArlaKvintaResourceType.CirculationAtkReport,
            id: this.currentCirculationReportId,
          },
        },
      });
      if (result.status == KvintaOperationStatus.Ok) {
        this._notificationManager.sendSuccess('Report has been released - ' + result.message + ' - ' + result.data);
      } else {
        this._notificationManager.sendError('Error releasing the report - ' + result.error);
      }
      runInAction(() => {
        this.isLoading = false;
      });
    } catch (err) {
      this._notificationManager.sendError(JSON.stringify(err));
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  formatDateTime(timestamp: number): string {
    return format(new Date(timestamp), this._config.defaultDateTimeFormatting);
  }

  exportCurrentStatuses() {
    runInAction(() => {
      this.exportActive = false;
      this.exportData = undefined;
    });
    runInAction(() => {
      this.exportActive = true;
      this.exportData = this.currentCirculationReport.record.statuses;
    });
  }

  updateExported() {
    this.exportData = undefined;
  }
}

export const CIRCULATION_REPORTS_STORE_ID = 'circulationReportsStore';

function getField(orderBy: number): string {
  switch (orderBy) {
    case 1:
      return 'timestamp';
    case 2:
      return 'userId';
    case 3:
      return 'lastStatus';
    default:
      return 'timestamp';
  }
}
