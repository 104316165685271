/* eslint-disable @typescript-eslint/ban-types */
import { Button, Paper } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { ATKListStore, STORE_ID } from '../ATKListStore';
import { ATKSummaryCompView } from './ATKSummaryCompView';
import { tabsSummary } from './tabs';
import {
  navigateATKListPath,
  navigateATKSummaryPath,
  navigateATKSerialNumbersPath,
  navigateATKJsonPath,
  navigateATKStatusesPath,
  navigateATKCirculationReportsPath,
} from '../paths';
import { KSidebarStore, TabBar, SIDEBAR_STORE_ID } from 'kvinta/components';
import { KvintaResourceStatusType } from 'kvinta/apis/kvinta-document-store';
import { reaction } from 'mobx';

interface ATKSummaryViewProps {
  intl: any;
  sidebarStore?: KSidebarStore;
  atkListStore?: ATKListStore;
  id: string;
  history?: any;
}

class ATKSummaryViewImpl extends Component<ATKSummaryViewProps> {
  disposer: any;
  componentDidMount() {
    this.props.atkListStore.fetchATK(this.props.id);
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.atkListStore.currentATK,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.record.resource.id, () => {
            navigateATKListPath(this.props.history);
          });
        }
      },
    );
  }
  componentWillUnmount() {
    this.disposer();
  }

  render() {
    if (!this.props.atkListStore) {
      return null;
    }
    const comp = this.props.atkListStore.currentATK;
    if (!comp) {
      return null;
    }
    // const { updateATKFormOpen } = this.props.atkListStore;
    let releaseButtonDisabled = false;
    switch (comp.record.lastStatus) {
      case KvintaResourceStatusType.ReleaseQueued:
        releaseButtonDisabled = true;
        break;
      case KvintaResourceStatusType.ReleaseOk:
        releaseButtonDisabled = true;
        break;
      default:
        releaseButtonDisabled = false;
        break;
    }
    const intl = this.props.intl;
    const formData = comp;
    return (
      <div>
        <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          <TabBar
            onChange={(newValue: any) => {
              if (newValue === '1') {
                navigateATKSummaryPath(this.props.history, this.props.id);
              }
              if (newValue === '2') {
                navigateATKSerialNumbersPath(this.props.history, this.props.id);
              }
              if (newValue === '3') {
                navigateATKJsonPath(this.props.history, this.props.id);
              }
              if (newValue === '4') {
                navigateATKStatusesPath(this.props.history, this.props.id);
              }
              if (newValue === '5') {
                navigateATKCirculationReportsPath(this.props.history, this.props.id);
              }
            }}
            tabId={'1'}
            tabs={tabsSummary}
          />
        </Paper>
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          <div style={{ marginRight: '15px', marginTop: '15px', float: 'right' }}>
            <Button
              variant={releaseButtonDisabled ? 'outlined' : 'contained'}
              color={releaseButtonDisabled ? 'primary' : 'secondary'}
              disabled={releaseButtonDisabled}
              onClick={(e) => {
                this.props.atkListStore.releaseATKDocument(formData.record.resource.id);
              }}
            >
              {intl.formatMessage({ id: 'button.release' })}
            </Button>
          </div>
          <ATKSummaryCompView atk={formData} history={this.props.history} />
          {/* {updateATKFormOpen && <UpdateATKDialog />} */}
        </Paper>
      </div>
    );
  }
}

export const ATKSummaryView = injectIntl(inject(STORE_ID, SIDEBAR_STORE_ID)(observer(ATKSummaryViewImpl)));
