/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Capture Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaCaptureEpcisBody,
    KvintaCaptureEpcisBodyFromJSON,
    KvintaCaptureEpcisBodyFromJSONTyped,
    KvintaCaptureEpcisBodyToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaCaptureEpcisDocumentRequest
 */
export interface KvintaCaptureEpcisDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureEpcisDocumentRequest
     */
    id?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof KvintaCaptureEpcisDocumentRequest
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {KvintaCaptureEpcisBody}
     * @memberof KvintaCaptureEpcisDocumentRequest
     */
    epcisBody?: KvintaCaptureEpcisBody;
    /**
     * 
     * @type {number}
     * @memberof KvintaCaptureEpcisDocumentRequest
     */
    schemaVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureEpcisDocumentRequest
     */
    rootTaskId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureEpcisDocumentRequest
     */
    createdByTaskId?: string | null;
}

export function KvintaCaptureEpcisDocumentRequestFromJSON(json: any): KvintaCaptureEpcisDocumentRequest {
    return KvintaCaptureEpcisDocumentRequestFromJSONTyped(json, false);
}

export function KvintaCaptureEpcisDocumentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCaptureEpcisDocumentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'epcisBody': !exists(json, 'epcisBody') ? undefined : KvintaCaptureEpcisBodyFromJSON(json['epcisBody']),
        'schemaVersion': !exists(json, 'schemaVersion') ? undefined : json['schemaVersion'],
        'rootTaskId': !exists(json, 'rootTaskId') ? undefined : json['rootTaskId'],
        'createdByTaskId': !exists(json, 'createdByTaskId') ? undefined : json['createdByTaskId'],
    };
}

export function KvintaCaptureEpcisDocumentRequestToJSON(value?: KvintaCaptureEpcisDocumentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'epcisBody': KvintaCaptureEpcisBodyToJSON(value.epcisBody),
        'schemaVersion': value.schemaVersion,
        'rootTaskId': value.rootTaskId,
        'createdByTaskId': value.createdByTaskId,
    };
}


