/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaOperationStatus,
    KvintaOperationStatusFromJSON,
    KvintaOperationStatusFromJSONTyped,
    KvintaOperationStatusToJSON,
    KvintaTradeItem,
    KvintaTradeItemFromJSON,
    KvintaTradeItemFromJSONTyped,
    KvintaTradeItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetOneResponseTradeItem
 */
export interface KvintaGetOneResponseTradeItem {
    /**
     * 
     * @type {KvintaTradeItem}
     * @memberof KvintaGetOneResponseTradeItem
     */
    data?: KvintaTradeItem;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetOneResponseTradeItem
     */
    error?: string | null;
    /**
     * 
     * @type {KvintaOperationStatus}
     * @memberof KvintaGetOneResponseTradeItem
     */
    operationStatus: KvintaOperationStatus;
}

export function KvintaGetOneResponseTradeItemFromJSON(json: any): KvintaGetOneResponseTradeItem {
    return KvintaGetOneResponseTradeItemFromJSONTyped(json, false);
}

export function KvintaGetOneResponseTradeItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetOneResponseTradeItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : KvintaTradeItemFromJSON(json['data']),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'operationStatus': KvintaOperationStatusFromJSON(json['operationStatus']),
    };
}

export function KvintaGetOneResponseTradeItemToJSON(value?: KvintaGetOneResponseTradeItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': KvintaTradeItemToJSON(value.data),
        'error': value.error,
        'operationStatus': KvintaOperationStatusToJSON(value.operationStatus),
    };
}


