function getEnv(environmentVariableName: string) {
  const env = (window as any)._env_;
  if (env === undefined) {
    console.warn('_env_ is not defined, will be using the dev. cloud defaults');
    return undefined;
  }
  const value = env[environmentVariableName];
  console.info(`Environment '${environmentVariableName}' = '${value}'`);

  if (value === undefined || value === '') {
    console.error(`Environment '${environmentVariableName}' NOT FOUND`);
  }
  return value;
}

export const version = process.env.VERSION || 'xxx';
console.log('config:version', version);
export const baseDomain = getEnv('BASE_DOMAIN') || 'dev.kvinta.kvinta.io';
export const loggerEndpoint = getEnv('LOGGER_ENDPOINT');

export const masterdataApiPath = getEnv('KVINTA_MASTERDATA_URL') || `https://masterdata.${baseDomain}`;
export const statusApiPath = getEnv('KVINTA_STATUS_STORE_URL') || `https://status.${baseDomain}`;
export const documentsApiPath = getEnv('KVINTA_DOCUMENT_STORE_URL') || `https://documents.${baseDomain}`;
export const epcisDocumentStoreApiPath =
  getEnv('KVINTA_EPCIS_DOCUMENT_STORE_URL') || `https://epcis-document-store.${baseDomain}`;
export const epcisQueryApiPath = getEnv('KVINTA_EPCIS_QUERY_URL') || `https://epcis-query.${baseDomain}`;
export const messageStoreApiPath = getEnv('KVINTA_MESSAGES_URL') || `https://messages.${baseDomain}`;
export const statusStoreApiPath = getEnv('KVINTA_STATUS_STORE_URL') || `https://status.${baseDomain}`;
export const notificationFunctionsApiPath =
  getEnv('KVINTA_NOTIFICATION_FUNCTIONS_URL') || `https://notification-functions.${baseDomain}`;
export const serialsApiPath = getEnv('KVINTA_SERIALS_URL') || `https://serials.${baseDomain}`;
export const taskManagerApiPath = getEnv('KVINTA_TASKS_URL') || `https://tasks.${baseDomain}`;
export const documentStatusStoreApiPath =
  getEnv('KVINTA_DOCUMENT_STATUS_STORE_URL') || `https://document-status-store.${baseDomain}`;
export const tobaccoExtensionsApiPath = getEnv('KVINTA_TOBACCO_URL') || `https://tobacco.${baseDomain}`;
export const executionStatusStoreApiPath =
  getEnv('EXECUTION_STATUS_STORE_URL') || `https://execution-status-store.${baseDomain}`;
export const shippingApiPath = getEnv('KVINTA_ARLA_EXTENSIONS_URL') || `https://arla-extensions.${baseDomain}`;
export const defaultTaxNumber = getEnv('DEFAULT_TAX_NUMBER') || ``;

export const keycloakApiPath = getEnv('KVINTA_ID_URL') || `https://id.${baseDomain}/auth`;

export const showDevicesMenu = getEnv('KVINTA_SHOW_DEVICES') || 'false';
export const devicesApiPath = getEnv('KVINTA_DEVICES_STORE_URL') || `https://api.${baseDomain}/devices-store/v1`;

export const productGroupIsWaterBeerBio = ['beer','bio','water'].includes(getEnv('PRODUCT_GROUP'));
export const productGroupIsBio = ['bio'].includes(getEnv('PRODUCT_GROUP'));

export const keycloakConfig = {
  realm: 'kvinta',
  url: keycloakApiPath,
  clientId: 'portal',
};

export const KEYCLOAK_INIT_CONFIG = {
  onLoad: 'login-required' as const,
  checkLoginIframe: false,
  enableCors: true,
  flow: 'standard' as const,
  enableLogging: true,
  pkceMethod: 'S256' as const,
  // tokenCookiePath: '/',
};

export const cookieName = 'kvinta_auth';
export const localStorageAuthKey = 'AUTH';

export const defaultDateTimeFormatting = 'dd.MM.yyyy HH:mm';
export const defaultDateFormatting = 'dd.MM.yyyy';

export const build = 'app-kvinta-dev';
export const availableLanguages = ['en', 'ru'];
