/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionStatusType,
    KvintaExecutionStatusTypeFromJSON,
    KvintaExecutionStatusTypeFromJSONTyped,
    KvintaExecutionStatusTypeToJSON,
    KvintaResourceType,
    KvintaResourceTypeFromJSON,
    KvintaResourceTypeFromJSONTyped,
    KvintaResourceTypeToJSON,
    KvintaSoftwareSystem,
    KvintaSoftwareSystemFromJSON,
    KvintaSoftwareSystemFromJSONTyped,
    KvintaSoftwareSystemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaExecutionStatusRecordFlat
 */
export interface KvintaExecutionStatusRecordFlat {
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatusRecordFlat
     */
    error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatusRecordFlat
     */
    executionId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatusRecordFlat
     */
    _function: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatusRecordFlat
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatusRecordFlat
     */
    parentExecutionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatusRecordFlat
     */
    parentResourceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatusRecordFlat
     */
    resourceId?: string | null;
    /**
     * 
     * @type {KvintaSoftwareSystem}
     * @memberof KvintaExecutionStatusRecordFlat
     */
    resourceSystem?: KvintaSoftwareSystem;
    /**
     * 
     * @type {KvintaResourceType}
     * @memberof KvintaExecutionStatusRecordFlat
     */
    resourceType?: KvintaResourceType;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatusRecordFlat
     */
    rootExecutionId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatusRecordFlat
     */
    service: string;
    /**
     * 
     * @type {KvintaExecutionStatusType}
     * @memberof KvintaExecutionStatusRecordFlat
     */
    status: KvintaExecutionStatusType;
    /**
     * 
     * @type {KvintaSoftwareSystem}
     * @memberof KvintaExecutionStatusRecordFlat
     */
    system: KvintaSoftwareSystem;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatusRecordFlat
     */
    tenantId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaExecutionStatusRecordFlat
     */
    timestamp?: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatusRecordFlat
     */
    userId?: string | null;
}

export function KvintaExecutionStatusRecordFlatFromJSON(json: any): KvintaExecutionStatusRecordFlat {
    return KvintaExecutionStatusRecordFlatFromJSONTyped(json, false);
}

export function KvintaExecutionStatusRecordFlatFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaExecutionStatusRecordFlat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'executionId': json['executionId'],
        '_function': json['function'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'parentExecutionId': !exists(json, 'parentExecutionId') ? undefined : json['parentExecutionId'],
        'parentResourceId': !exists(json, 'parentResourceId') ? undefined : json['parentResourceId'],
        'resourceId': !exists(json, 'resourceId') ? undefined : json['resourceId'],
        'resourceSystem': !exists(json, 'resourceSystem') ? undefined : KvintaSoftwareSystemFromJSON(json['resourceSystem']),
        'resourceType': !exists(json, 'resourceType') ? undefined : KvintaResourceTypeFromJSON(json['resourceType']),
        'rootExecutionId': json['rootExecutionId'],
        'service': json['service'],
        'status': KvintaExecutionStatusTypeFromJSON(json['status']),
        'system': KvintaSoftwareSystemFromJSON(json['system']),
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function KvintaExecutionStatusRecordFlatToJSON(value?: KvintaExecutionStatusRecordFlat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'executionId': value.executionId,
        'function': value._function,
        'message': value.message,
        'parentExecutionId': value.parentExecutionId,
        'parentResourceId': value.parentResourceId,
        'resourceId': value.resourceId,
        'resourceSystem': KvintaSoftwareSystemToJSON(value.resourceSystem),
        'resourceType': KvintaResourceTypeToJSON(value.resourceType),
        'rootExecutionId': value.rootExecutionId,
        'service': value.service,
        'status': KvintaExecutionStatusTypeToJSON(value.status),
        'system': KvintaSoftwareSystemToJSON(value.system),
        'tenantId': value.tenantId,
        'timestamp': value.timestamp,
        'userId': value.userId,
    };
}


