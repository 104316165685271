/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCreateEmailRecipientRequest
 */
export interface KvintaCreateEmailRecipientRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateEmailRecipientRequest
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateEmailRecipientRequest
     */
    emailAddress?: string;
}

export function KvintaCreateEmailRecipientRequestFromJSON(json: any): KvintaCreateEmailRecipientRequest {
    return KvintaCreateEmailRecipientRequestFromJSONTyped(json, false);
}

export function KvintaCreateEmailRecipientRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateEmailRecipientRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
    };
}

export function KvintaCreateEmailRecipientRequestToJSON(value?: KvintaCreateEmailRecipientRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'emailAddress': value.emailAddress,
    };
}


