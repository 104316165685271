/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSerialNumberGenerationProfile,
    KvintaSerialNumberGenerationProfileFromJSON,
    KvintaSerialNumberGenerationProfileFromJSONTyped,
    KvintaSerialNumberGenerationProfileToJSON,
    KvintaSerialNumberOrderItem,
    KvintaSerialNumberOrderItemFromJSON,
    KvintaSerialNumberOrderItemFromJSONTyped,
    KvintaSerialNumberOrderItemToJSON,
    KvintaSerialNumberOrderStatus,
    KvintaSerialNumberOrderStatusFromJSON,
    KvintaSerialNumberOrderStatusFromJSONTyped,
    KvintaSerialNumberOrderStatusToJSON,
    KvintaSerialNumberOrderStatusType,
    KvintaSerialNumberOrderStatusTypeFromJSON,
    KvintaSerialNumberOrderStatusTypeFromJSONTyped,
    KvintaSerialNumberOrderStatusTypeToJSON,
    KvintaSerialNumberOrderType,
    KvintaSerialNumberOrderTypeFromJSON,
    KvintaSerialNumberOrderTypeFromJSONTyped,
    KvintaSerialNumberOrderTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaSerialNumberOrder
 */
export interface KvintaSerialNumberOrder {
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrder
     */
    companyPrefix?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrder
     */
    contactPerson: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaSerialNumberOrder
     */
    created?: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaSerialNumberOrder
     */
    documentDate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrder
     */
    documentNumber: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrder
     */
    id: string;
    /**
     * 
     * @type {Array<KvintaSerialNumberOrderItem>}
     * @memberof KvintaSerialNumberOrder
     */
    items?: Array<KvintaSerialNumberOrderItem> | null;
    /**
     * 
     * @type {KvintaSerialNumberOrderStatusType}
     * @memberof KvintaSerialNumberOrder
     */
    lastStatus?: KvintaSerialNumberOrderStatusType;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrder
     */
    paymentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrder
     */
    paymentTypeLabel?: string | null;
    /**
     * 
     * @type {KvintaSerialNumberGenerationProfile}
     * @memberof KvintaSerialNumberOrder
     */
    profile?: KvintaSerialNumberGenerationProfile;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrder
     */
    profileId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaSerialNumberOrder
     */
    received?: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaSerialNumberOrder
     */
    requested?: number;
    /**
     * 
     * @type {KvintaSerialNumberOrderType}
     * @memberof KvintaSerialNumberOrder
     */
    serialNumberOrderType: KvintaSerialNumberOrderType;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrder
     */
    serviceProviderId?: string | null;
    /**
     * 
     * @type {Array<KvintaSerialNumberOrderStatus>}
     * @memberof KvintaSerialNumberOrder
     */
    statuses?: Array<KvintaSerialNumberOrderStatus> | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrder
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrder
     */
    userId?: string | null;
}

export function KvintaSerialNumberOrderFromJSON(json: any): KvintaSerialNumberOrder {
    return KvintaSerialNumberOrderFromJSONTyped(json, false);
}

export function KvintaSerialNumberOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSerialNumberOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyPrefix': !exists(json, 'companyPrefix') ? undefined : json['companyPrefix'],
        'contactPerson': json['contactPerson'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'documentDate': !exists(json, 'documentDate') ? undefined : json['documentDate'],
        'documentNumber': json['documentNumber'],
        'id': json['id'],
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(KvintaSerialNumberOrderItemFromJSON)),
        'lastStatus': !exists(json, 'lastStatus') ? undefined : KvintaSerialNumberOrderStatusTypeFromJSON(json['lastStatus']),
        'paymentType': !exists(json, 'paymentType') ? undefined : json['paymentType'],
        'paymentTypeLabel': !exists(json, 'paymentTypeLabel') ? undefined : json['paymentTypeLabel'],
        'profile': !exists(json, 'profile') ? undefined : KvintaSerialNumberGenerationProfileFromJSON(json['profile']),
        'profileId': !exists(json, 'profileId') ? undefined : json['profileId'],
        'received': !exists(json, 'received') ? undefined : json['received'],
        'requested': !exists(json, 'requested') ? undefined : json['requested'],
        'serialNumberOrderType': KvintaSerialNumberOrderTypeFromJSON(json['serialNumberOrderType']),
        'serviceProviderId': !exists(json, 'serviceProviderId') ? undefined : json['serviceProviderId'],
        'statuses': !exists(json, 'statuses') ? undefined : (json['statuses'] === null ? null : (json['statuses'] as Array<any>).map(KvintaSerialNumberOrderStatusFromJSON)),
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function KvintaSerialNumberOrderToJSON(value?: KvintaSerialNumberOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyPrefix': value.companyPrefix,
        'contactPerson': value.contactPerson,
        'created': value.created,
        'documentDate': value.documentDate,
        'documentNumber': value.documentNumber,
        'id': value.id,
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(KvintaSerialNumberOrderItemToJSON)),
        'lastStatus': KvintaSerialNumberOrderStatusTypeToJSON(value.lastStatus),
        'paymentType': value.paymentType,
        'paymentTypeLabel': value.paymentTypeLabel,
        'profile': KvintaSerialNumberGenerationProfileToJSON(value.profile),
        'profileId': value.profileId,
        'received': value.received,
        'requested': value.requested,
        'serialNumberOrderType': KvintaSerialNumberOrderTypeToJSON(value.serialNumberOrderType),
        'serviceProviderId': value.serviceProviderId,
        'statuses': value.statuses === undefined ? undefined : (value.statuses === null ? null : (value.statuses as Array<any>).map(KvintaSerialNumberOrderStatusToJSON)),
        'tenantId': value.tenantId,
        'userId': value.userId,
    };
}


