/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaResource,
    KvintaResourceFromJSON,
    KvintaResourceFromJSONTyped,
    KvintaResourceToJSON,
    KvintaResourceStatus,
    KvintaResourceStatusFromJSON,
    KvintaResourceStatusFromJSONTyped,
    KvintaResourceStatusToJSON,
    KvintaResourceStatusType,
    KvintaResourceStatusTypeFromJSON,
    KvintaResourceStatusTypeFromJSONTyped,
    KvintaResourceStatusTypeToJSON,
    KvintaTimestamp,
    KvintaTimestampFromJSON,
    KvintaTimestampFromJSONTyped,
    KvintaTimestampToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaTextDocumentRecord
 */
export interface KvintaTextDocumentRecord {
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaTextDocumentRecord
     */
    context: KvintaExecutionContext;
    /**
     * 
     * @type {string}
     * @memberof KvintaTextDocumentRecord
     */
    externalNumber?: string | null;
    /**
     * 
     * @type {KvintaResourceStatusType}
     * @memberof KvintaTextDocumentRecord
     */
    lastStatus?: KvintaResourceStatusType;
    /**
     * 
     * @type {KvintaResource}
     * @memberof KvintaTextDocumentRecord
     */
    resource: KvintaResource;
    /**
     * 
     * @type {string}
     * @memberof KvintaTextDocumentRecord
     */
    sha256: string;
    /**
     * 
     * @type {Array<KvintaResourceStatus>}
     * @memberof KvintaTextDocumentRecord
     */
    statuses?: Array<KvintaResourceStatus> | null;
    /**
     * 
     * @type {KvintaTimestamp}
     * @memberof KvintaTextDocumentRecord
     */
    timestamp: KvintaTimestamp;
}

export function KvintaTextDocumentRecordFromJSON(json: any): KvintaTextDocumentRecord {
    return KvintaTextDocumentRecordFromJSONTyped(json, false);
}

export function KvintaTextDocumentRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTextDocumentRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': KvintaExecutionContextFromJSON(json['context']),
        'externalNumber': !exists(json, 'externalNumber') ? undefined : json['externalNumber'],
        'lastStatus': !exists(json, 'lastStatus') ? undefined : KvintaResourceStatusTypeFromJSON(json['lastStatus']),
        'resource': KvintaResourceFromJSON(json['resource']),
        'sha256': json['sha256'],
        'statuses': !exists(json, 'statuses') ? undefined : (json['statuses'] === null ? null : (json['statuses'] as Array<any>).map(KvintaResourceStatusFromJSON)),
        'timestamp': KvintaTimestampFromJSON(json['timestamp']),
    };
}

export function KvintaTextDocumentRecordToJSON(value?: KvintaTextDocumentRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': KvintaExecutionContextToJSON(value.context),
        'externalNumber': value.externalNumber,
        'lastStatus': KvintaResourceStatusTypeToJSON(value.lastStatus),
        'resource': KvintaResourceToJSON(value.resource),
        'sha256': value.sha256,
        'statuses': value.statuses === undefined ? undefined : (value.statuses === null ? null : (value.statuses as Array<any>).map(KvintaResourceStatusToJSON)),
        'timestamp': KvintaTimestampToJSON(value.timestamp),
    };
}


