/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaCreateCirculationReportForEpcMetadataListRequest,
    KvintaCreateCirculationReportForEpcMetadataListRequestFromJSON,
    KvintaCreateCirculationReportForEpcMetadataListRequestFromJSONTyped,
    KvintaCreateCirculationReportForEpcMetadataListRequestToJSON,
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestCreateCirculationReportForEpcMetadataListRequest
 */
export interface KvintaOperationRequestCreateCirculationReportForEpcMetadataListRequest {
    /**
     * 
     * @type {KvintaCreateCirculationReportForEpcMetadataListRequest}
     * @memberof KvintaOperationRequestCreateCirculationReportForEpcMetadataListRequest
     */
    input?: KvintaCreateCirculationReportForEpcMetadataListRequest;
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestCreateCirculationReportForEpcMetadataListRequest
     */
    context?: KvintaExecutionContext;
}

export function KvintaOperationRequestCreateCirculationReportForEpcMetadataListRequestFromJSON(json: any): KvintaOperationRequestCreateCirculationReportForEpcMetadataListRequest {
    return KvintaOperationRequestCreateCirculationReportForEpcMetadataListRequestFromJSONTyped(json, false);
}

export function KvintaOperationRequestCreateCirculationReportForEpcMetadataListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestCreateCirculationReportForEpcMetadataListRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'input': !exists(json, 'input') ? undefined : KvintaCreateCirculationReportForEpcMetadataListRequestFromJSON(json['input']),
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
    };
}

export function KvintaOperationRequestCreateCirculationReportForEpcMetadataListRequestToJSON(value?: KvintaOperationRequestCreateCirculationReportForEpcMetadataListRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'input': KvintaCreateCirculationReportForEpcMetadataListRequestToJSON(value.input),
        'context': KvintaExecutionContextToJSON(value.context),
    };
}


