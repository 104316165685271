/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaMasterDataCompressionType,
    KvintaMasterDataCompressionTypeFromJSON,
    KvintaMasterDataCompressionTypeFromJSONTyped,
    KvintaMasterDataCompressionTypeToJSON,
    KvintaMasterDataExportFormat,
    KvintaMasterDataExportFormatFromJSON,
    KvintaMasterDataExportFormatFromJSONTyped,
    KvintaMasterDataExportFormatToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMasterDataDownloadRequest
 */
export interface KvintaMasterDataDownloadRequest {
    /**
     * 
     * @type {KvintaMasterDataCompressionType}
     * @memberof KvintaMasterDataDownloadRequest
     */
    compression: KvintaMasterDataCompressionType;
    /**
     * 
     * @type {KvintaMasterDataExportFormat}
     * @memberof KvintaMasterDataDownloadRequest
     */
    format: KvintaMasterDataExportFormat;
}

export function KvintaMasterDataDownloadRequestFromJSON(json: any): KvintaMasterDataDownloadRequest {
    return KvintaMasterDataDownloadRequestFromJSONTyped(json, false);
}

export function KvintaMasterDataDownloadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMasterDataDownloadRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'compression': KvintaMasterDataCompressionTypeFromJSON(json['compression']),
        'format': KvintaMasterDataExportFormatFromJSON(json['format']),
    };
}

export function KvintaMasterDataDownloadRequestToJSON(value?: KvintaMasterDataDownloadRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'compression': KvintaMasterDataCompressionTypeToJSON(value.compression),
        'format': KvintaMasterDataExportFormatToJSON(value.format),
    };
}


