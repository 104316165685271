/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaListSerialNumberGenerationProfilesRequest,
    KvintaListSerialNumberGenerationProfilesRequestFromJSON,
    KvintaListSerialNumberGenerationProfilesRequestFromJSONTyped,
    KvintaListSerialNumberGenerationProfilesRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestListSerialNumberGenerationProfilesRequest
 */
export interface KvintaOperationRequestListSerialNumberGenerationProfilesRequest {
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestListSerialNumberGenerationProfilesRequest
     */
    context?: KvintaExecutionContext;
    /**
     * 
     * @type {KvintaListSerialNumberGenerationProfilesRequest}
     * @memberof KvintaOperationRequestListSerialNumberGenerationProfilesRequest
     */
    input?: KvintaListSerialNumberGenerationProfilesRequest;
}

export function KvintaOperationRequestListSerialNumberGenerationProfilesRequestFromJSON(json: any): KvintaOperationRequestListSerialNumberGenerationProfilesRequest {
    return KvintaOperationRequestListSerialNumberGenerationProfilesRequestFromJSONTyped(json, false);
}

export function KvintaOperationRequestListSerialNumberGenerationProfilesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestListSerialNumberGenerationProfilesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
        'input': !exists(json, 'input') ? undefined : KvintaListSerialNumberGenerationProfilesRequestFromJSON(json['input']),
    };
}

export function KvintaOperationRequestListSerialNumberGenerationProfilesRequestToJSON(value?: KvintaOperationRequestListSerialNumberGenerationProfilesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': KvintaExecutionContextToJSON(value.context),
        'input': KvintaListSerialNumberGenerationProfilesRequestToJSON(value.input),
    };
}


