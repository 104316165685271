/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaNotificationGroupBatchCreateRequest,
    KvintaNotificationGroupBatchCreateRequestFromJSON,
    KvintaNotificationGroupBatchCreateRequestFromJSONTyped,
    KvintaNotificationGroupBatchCreateRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestNotificationGroupBatchCreateRequest
 */
export interface KvintaOperationRequestNotificationGroupBatchCreateRequest {
    /**
     * 
     * @type {KvintaNotificationGroupBatchCreateRequest}
     * @memberof KvintaOperationRequestNotificationGroupBatchCreateRequest
     */
    input?: KvintaNotificationGroupBatchCreateRequest;
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestNotificationGroupBatchCreateRequest
     */
    context?: KvintaExecutionContext;
}

export function KvintaOperationRequestNotificationGroupBatchCreateRequestFromJSON(json: any): KvintaOperationRequestNotificationGroupBatchCreateRequest {
    return KvintaOperationRequestNotificationGroupBatchCreateRequestFromJSONTyped(json, false);
}

export function KvintaOperationRequestNotificationGroupBatchCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestNotificationGroupBatchCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'input': !exists(json, 'input') ? undefined : KvintaNotificationGroupBatchCreateRequestFromJSON(json['input']),
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
    };
}

export function KvintaOperationRequestNotificationGroupBatchCreateRequestToJSON(value?: KvintaOperationRequestNotificationGroupBatchCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'input': KvintaNotificationGroupBatchCreateRequestToJSON(value.input),
        'context': KvintaExecutionContextToJSON(value.context),
    };
}


