import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  PageContentStore,
  tableIcons,
  PAGE_CONTENT_STORE_ID,
  KSidebarStore,
  SIDEBAR_STORE_ID,
} from 'kvinta/components';
import { SequenceStatusStore, SEQUENCE_STATUS_STORE_ID, statusesList } from './SequenceStatusStore';
import { navigateSequenceStatusesPath } from '../paths';

interface SequenceSummaryViewProps {
  sequenceStatusStore?: SequenceStatusStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
}

class SequenceSummaryViewImpl extends Component<SequenceSummaryViewProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: SequenceSummaryViewProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.sequenceStatusStore.fetchSequenceSummary();
  }

  navigateSequenceStatusFiltered = (history: any, status: number, bizStep: string) => {
    this.props.sequenceStatusStore.updateFilterStatusBizStep(status, bizStep);
    navigateSequenceStatusesPath(history);
  };

  render() {
    const intl = this.props.intl;
    const { summaryData, isLoading } = this.props.sequenceStatusStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightThin + '10vh'; // Missing padding on bottom
    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <MaterialTable
                icons={tableIcons}
                tableRef={this.tableRef}
                columns={[
                  {
                    title: intl.formatMessage({ id: 'sequence-summary.count' }),
                    field: 'count',
                    defaultSort: 'desc',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'sequence-summary.status' }),
                    field: 'status',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                    render: (rowData) => {
                      let newStatus;
                      statusesList.map((value, index) => {
                        if (index == (rowData.status as any)) {
                          newStatus = value;
                        }
                      });
                      return <span>{newStatus}</span>;
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'sequence-summary.bizStep' }),
                    field: 'step',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                ]}
                data={summaryData}
                onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                  this.props.sequenceStatusStore.changeOrder(orderBy, orderDirection);
                }}
                isLoading={isLoading}
                options={{
                  actionsColumnIndex: 0,
                  paging: false,
                  sorting: true,
                  draggable: false,
                  search: false,
                  minBodyHeight: bodyHeight,
                  maxBodyHeight: bodyHeight,
                  showTitle: false,
                  thirdSortClick: false,
                  emptyRowsWhenPaging: false,
                }}
                localization={{
                  header: {
                    actions: '',
                  },
                }}
                onRowClick={(event, rowData) => {
                  this.navigateSequenceStatusFiltered(history, rowData.status, rowData.step);
                }}
                components={{
                  Toolbar: () => null,
                }}
              />
            </div>
          </div>
        )}
      />
    );
  }
}
export const SequenceSummaryView = injectIntl(
  inject(SEQUENCE_STATUS_STORE_ID, PAGE_CONTENT_STORE_ID, SIDEBAR_STORE_ID)(observer(SequenceSummaryViewImpl)),
);
