import { Button, IconButton, InputAdornment, Link, OutlinedInput } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  FilterBar,
  PageContentStore,
  tableIcons,
  PAGE_CONTENT_STORE_ID,
  KSidebarStore,
  SIDEBAR_STORE_ID,
} from 'kvinta/components';
import { SerialNumberOrdersStore, STORE_ID } from '../SerialNumberOrdersStore';
import { CreateSerialNumberOrderDialog } from './CreateSerialNumberOrderDialog';
import { linkSerialNumberOrderPath, navigateSerialNumberOrderPath } from '../paths';
import ClearIcon from '@material-ui/icons/Clear';
import CancelIcon from '@material-ui/icons/Cancel';
import { CSVDownload } from 'react-csv';
import { format } from 'date-fns';

interface SerialNumberOrdersViewProps {
  serialNumberOrdersStore?: SerialNumberOrdersStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
  pageContentStore?: PageContentStore;
}

class SerialNumberOrdersViewImpl extends Component<SerialNumberOrdersViewProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: SerialNumberOrdersViewProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    if (!this.props.serialNumberOrdersStore.filter) {
      this.props.serialNumberOrdersStore.setFilter([
        {
          field: 'documentNumber',
          label: 'serial-number-order-form.field.documentnumber',
          isActive: true,
          value: '',
        },
        {
          field: 'rangeFrom',
          label: 'dateRange.field-from',
          isActive: false,
          value: new Date().toISOString().slice(0, 16),
          render: (props) => {
            return (
              <OutlinedInput
                id={props.fieldId}
                style={{ height: '40px' }}
                label={props.translatedLabel}
                type="datetime-local"
                inputProps={{ step: 1 }}
                value={props.value || new Date().toISOString().slice(0, 16)}
                defaultValue={new Date().toISOString().slice(0, 16)}
                onChange={(e) => {
                  props.store.onChangeValue(props.field, e.target.value);
                }}
                endAdornment={
                  props.isFieldEmpty ? (
                    <InputAdornment position="end">
                      <IconButton
                        // style={{ marginLeft: 6 }}
                        className="removeFilter"
                        onClick={(e) => {
                          props.store.hideFilter(props.field);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : props.isApplied ? (
                    <InputAdornment position="end">
                      <IconButton
                        className="clearFilter"
                        onClick={(e) => {
                          props.store.onClear(props.field);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <IconButton
                        className="removeFilter"
                        onClick={(e) => {
                          props.store.hideFilter(props.field);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <IconButton
                        className="clearFilter"
                        onClick={(e) => {
                          props.store.onClear(props.field);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <Button
                        variant="contained"
                        color="primary"
                        className="applyFilter"
                        onClick={(e) => {
                          props.store.onFilter(props.field);
                        }}
                      >
                        {props.intl.formatMessage({ id: 'button.apply' })}
                      </Button>
                      <IconButton
                        className="removeFilter"
                        onClick={(e) => {
                          props.store.hideFilter(props.field);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            );
          },
        },
        {
          field: 'rangeTo',
          label: 'dateRange.field-to',
          isActive: false,
          value: new Date().toISOString().slice(0, 16),
          render: (props) => {
            return (
              <OutlinedInput
                id={props.fieldId}
                style={{ height: '40px' }}
                label={props.translatedLabel}
                type="datetime-local"
                inputProps={{ step: 1 }}
                value={props.value || new Date().toISOString().slice(0, 16)}
                defaultValue={new Date().toISOString().slice(0, 16)}
                onChange={(e) => {
                  props.store.onChangeValue(props.field, e.target.value);
                }}
                endAdornment={
                  props.isFieldEmpty ? (
                    <InputAdornment position="end">
                      <IconButton
                        // style={{ marginLeft: 6 }}
                        className="removeFilter"
                        onClick={(e) => {
                          props.store.hideFilter(props.field);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : props.isApplied ? (
                    <InputAdornment position="end">
                      <IconButton
                        className="clearFilter"
                        onClick={(e) => {
                          props.store.onClear(props.field);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <IconButton
                        className="removeFilter"
                        onClick={(e) => {
                          props.store.hideFilter(props.field);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <IconButton
                        className="clearFilter"
                        onClick={(e) => {
                          props.store.onClear(props.field);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <Button
                        variant="contained"
                        color="primary"
                        className="applyFilter"
                        onClick={(e) => {
                          props.store.onFilter(props.field);
                        }}
                      >
                        {props.intl.formatMessage({ id: 'button.apply' })}
                      </Button>
                      <IconButton
                        className="removeFilter"
                        onClick={(e) => {
                          props.store.hideFilter(props.field);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            );
          },
        },
        {
          field: 'contactPerson',
          label: 'serial-number-order-form.field.contactperson',
          isActive: false,
          value: '',
        },
        {
          field: 'orderType',
          label: 'serial-number-order-form.field.ordertype',
          isActive: false,
          value: '',
        },
      ]);
    }
    this.props.serialNumberOrdersStore.fetchData();
  }

  navigateSerialNumberOrderSummary = (id: string) => {
    navigateSerialNumberOrderPath(this.props.history, id);
  };

  render() {
    const intl = this.props.intl;
    if (!this.props.serialNumberOrdersStore.filter) {
      return null;
    }
    const {
      listData,
      isLoading,
      page,
      totalCount,
      pageSize,
      serialNumberOrderFormOpen,
      filter,
      exportSNListActive,
      exportSNListData,
    } = this.props.serialNumberOrdersStore;
    this.props.serialNumberOrdersStore.updateSNListExported();
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <MaterialTable
                icons={tableIcons}
                tableRef={this.tableRef}
                // title={intl.formatMessage({ id: 'menu.serial-number-orders' })}
                columns={[
                  {
                    title: intl.formatMessage({ id: 'serial-number-order-form.field.created' }),
                    field: 'created',
                    defaultSort: 'desc',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '20%',
                    },
                    render: (data) => {
                      return (
                        <Link href={linkSerialNumberOrderPath(history, data.id)} onClick={preventDefault}>
                          {data.created ? new Date(data.created).toISOString() : ''}
                        </Link>
                      );
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'serial-number-order-form.field.ordertype' }),
                    field: 'serialNumberOrderType',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '20%',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'serial-number-order-form.field.documentnumber' }),
                    field: 'documentNumber',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '20%',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'serial-number-order-form.field.documentstatus' }),
                    field: 'lastStatus',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '20%',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'serial-number-order-form.field.documentdate' }),
                    field: 'documentDate',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '20%',
                    },
                    render: (rowData) => {
                      return (
                        <span>{rowData.documentDate ? format(new Date(rowData.documentDate), 'yyyy-MM-dd') : ''}</span>
                      );
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'serial-number-order-form.field.contactperson' }),
                    field: 'contactPerson',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '20%',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'serial-number-order-form.field.serialsrequested' }),
                    field: 'requested',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '20%',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'serial-number-order-form.field.serialsreceived' }),
                    field: 'received',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '20%',
                    },
                  },
                ]}
                data={listData}
                page={page}
                totalCount={totalCount}
                onChangePage={(page: number) => {
                  this.props.serialNumberOrdersStore.fetchPage(page);
                }}
                onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                  this.props.serialNumberOrdersStore.changeOrder(orderBy, orderDirection);
                }}
                isLoading={isLoading}
                options={{
                  actionsColumnIndex: 0,
                  paging: true,
                  sorting: true,
                  draggable: false,
                  search: false,
                  pageSize: pageSize,
                  pageSizeOptions: [pageSize],
                  minBodyHeight: bodyHeight,
                  maxBodyHeight: bodyHeight,
                  showTitle: false,
                  thirdSortClick: false,
                  emptyRowsWhenPaging: false,
                }}
                localization={{
                  header: {
                    actions: '',
                  },
                }}
                onRowClick={(event, rowData) => {
                  if (!(event.target instanceof HTMLInputElement)) {
                    this.navigateSerialNumberOrderSummary(rowData.id);
                  }
                }}
                components={{
                  Toolbar: (props) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: '20px',
                        marginRight: '20px',
                      }}
                    >
                      <FilterBar filter={filter} />
                      <div style={{ marginLeft: '5px' }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<GetAppIcon color="secondary" />}
                          onClick={(e) => {
                            this.props.serialNumberOrdersStore.exportSNListAll();
                          }}
                        >
                          {intl.formatMessage({ id: 'button.export' })}
                        </Button>
                      </div>
                      <div style={{ marginLeft: '5px' }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<AddIcon color="secondary" />}
                          onClick={(e) => {
                            this.props.serialNumberOrdersStore.openCreateSerialNumberOrderForm();
                          }}
                        >
                          {intl.formatMessage({ id: 'button.create' })}
                        </Button>
                      </div>
                    </div>
                  ),
                }}
              />
            </div>
            {serialNumberOrderFormOpen && <CreateSerialNumberOrderDialog />}
            {exportSNListActive && exportSNListData !== undefined && (
              <CSVDownload data={exportSNListData} target="_blank" />
            )}
          </div>
        )}
      />
    );
  }
}
export const SerialNumberOrdersView = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(SerialNumberOrdersViewImpl)),
);
