/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import { KDialogTitle } from 'kvinta/components/KDialog';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ProductsStore, errorRequired, STORE_ID } from '../ProductsStore';

interface CreateProductDialogProps {
  productsStore?: ProductsStore;
  intl: any;
}

class CreateProductDialogImpl extends Component<CreateProductDialogProps> {
  render() {
    const isOpen = this.props.productsStore.productFormOpen;
    const onChangeValue = this.props.productsStore.onChangeCreateProductField;
    const companies = this.props.productsStore.companies;
    const formData = this.props.productsStore.createProductData;
    const intl = this.props.intl;
    const showError = formData.showError;

    if (!isOpen) {
      return null;
    }
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={isOpen}
        onClose={() => this.props.productsStore.closeCreateProductForm()}
      >
        <KDialogTitle id="customized-dialog-title" onClose={() => this.props.productsStore.closeCreateProductForm()}>
          {intl.formatMessage({ id: 'product-form.field.create' })}
        </KDialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="name"
                    required
                    helperText={
                      showError && errorRequired('name', formData.name)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('name', formData.name)}
                    value={formData.name}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('name', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'product-form.field.name' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="sku"
                    // required
                    helperText={
                      showError && errorRequired('sku', formData.sku)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('sku', formData.sku)}
                    value={formData.sku}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('sku', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'product-form.field.sku' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="idInSourceSystem"
                    // required
                    helperText={
                      showError && errorRequired('idInSourceSystem', formData.idInSourceSystem)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('idInSourceSystem', formData.idInSourceSystem)}
                    value={formData.idInSourceSystem}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('idInSourceSystem', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'product-form.field.idinsourcesystem' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="company">
                    {intl.formatMessage({ id: 'product-form.field.company' })}
                  </InputLabel>
                  <Select
                    id="company"
                    required
                    error={showError && errorRequired('company', formData.company)}
                    value={formData.company}
                    onChange={(e) => {
                      onChangeValue('company', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'product-form.field.company' })}
                  >
                    {companies.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="shelfLife"
                    // required
                    helperText={
                      showError && errorRequired('shelfLife', formData.shelfLife)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('shelfLife', formData.shelfLife)}
                    value={formData.shelfLife}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('shelfLife', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'product-form.field.shelfLife' })}
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="description"
                    // required
                    helperText={
                      showError && errorRequired('description', formData.description)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('description', formData.description)}
                    value={formData.description}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('description', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'product-form.field.description' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="sourceSystem"
                    // required
                    helperText={
                      showError && errorRequired('sourceSystem', formData.sourceSystem)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('sourceSystem', formData.sourceSystem)}
                    value={formData.sourceSystem}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('sourceSystem', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'product-form.field.sourcesystem' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="baseUom"
                    // required
                    helperText={
                      showError && errorRequired('baseUom', formData.baseUom)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('baseUom', formData.baseUom)}
                    value={formData.baseUom}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('baseUom', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'product-form.field.baseuom' })}
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="tnved"
                    required
                    helperText={
                      showError && errorRequired('tnved', formData.tnved)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('tnved', formData.tnved)}
                    value={formData.tnved}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('tnved', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'product-form.field.tnved' })}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.productsStore.closeCreateProductForm()} color="primary" variant="outlined">
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <Button
            onClick={() => this.props.productsStore.submitCreateProductForm()}
            color="primary"
            variant="contained"
            // disabled={!formData.isValid}
            autoFocus
          >
            {intl.formatMessage({ id: 'button.submit' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const CreateProductDialog = injectIntl(inject(STORE_ID)(observer(CreateProductDialogImpl)));
