export const LocationsPath = '/locations';
export const LocationPath = '/location/:id';
export const LocationReportingPath = '/location/:id/reporting';
export const LocationMapPath = '/location/:id/map';

export function navigateLocationsPath(history: any) {
  history.push(LocationsPath);
}

export function navigateLocationPath(history: any, id: string) {
  history.push(LocationPath.replaceAll(':id', id));
}

export function navigateReportingPath(history: any, id: string) {
  history.push(LocationReportingPath.replaceAll(':id', id));
}

export function navigateLocationMapPath(history: any, id: string) {
  history.push(LocationMapPath.replaceAll(':id', id));
}
