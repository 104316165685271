import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { TAppOptionsConfig } from 'kvinta/common/Interfaces';
import {
  DefaultApi as EDocumentApi,
  KvintaEpcisBizStepFromJSON,
  KvintaEpcisEvent,
  KvintaEpcisEventFilters,
  KvintaEpcisEventRecord,
  KvintaSortDirection,
  KvintaSortExpressions,
} from 'kvinta/apis/kvinta-epcis-document-store';
import { NotificationManager } from 'kvinta/modules/main';
import { KvintaOperationStatus } from 'kvinta/apis/kvinta-document-store/models';
import { IFilter } from 'kvinta/components';
import { format } from 'date-fns';

interface ExtKvintaEpcisEventRecord extends KvintaEpcisEventRecord {
  recordTimeExt: string;
  eventTimeExt: string;
}

export class EpcisEventsStore {
  private _config: TAppOptionsConfig;
  private _epcisDocumentApi: EDocumentApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  listData: ExtKvintaEpcisEventRecord[];
  page: number;
  totalCount: number;
  pageSize: number;
  currentSort: KvintaSortExpressions;

  filter: IFilter;

  // json
  currentEvent?: KvintaEpcisEvent;
  currentExecutionId?: string;

  constructor(config: TAppOptionsConfig, notificationManager: NotificationManager, epcisDocumentApi: EDocumentApi) {
    makeObservable(this, {
      fetchPage: action.bound,
      loadEvent: action.bound,

      isLoading: observable,
      listData: observable,
      page: observable,
      pageSize: observable,
      filter: observable,

      jsonPayload: computed,
      currentEvent: observable,
      currentExecutionId: observable,
    });

    this._config = config;
    this._epcisDocumentApi = epcisDocumentApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;

    // TODO: Initial sort and order
    this.currentSort = {
      expressions: [
        {
          direction: KvintaSortDirection.Desc,
          property: 'recordTime',
        },
      ],
    };
    this.filter = new IFilter(
      [
        {
          field: 'id',
          label: 'epcis-event.id',
          isActive: true,
          value: '',
        },
        {
          field: 'bizLocation',
          label: 'epcis-event.bizlocation',
          isActive: false,
          value: '',
        },
        {
          field: 'bizStep',
          label: 'epcis-event.bizstep',
          isActive: false,
          value: '',
        },
      ],
      this.doFilter,
    );
  }

  doFilter = async () => {
    runInAction(() => {
      this.isLoading = true;
      this.page = 0;
    });
    this.fetchData();
  };

  async fetchPage(page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
    });
    this.fetchData();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    runInAction(() => {
      this.isLoading = true;
      const dir = getDirection(orderDirection);
      const field = getField(orderBy);
      this.currentSort = {
        expressions: [
          {
            direction: dir,
            property: field,
          },
        ],
      };
    });
    this.fetchData();
  }

  async fetchData() {
    let filters = {} as KvintaEpcisEventFilters;

    for (const filter of this.filter.visibleFilters) {
      if (filter.field === 'id') {
        if (filter.value != '') {
          return this.fetchDataById(filter.value);
        }
      }
      if (filter.field === 'bizLocation') {
        filters = {
          ...filters,
          bizLocation: filter.value,
        };
      }
      if (filter.field === 'bizStep') {
        filters = {
          ...filters,
          bizStep: KvintaEpcisBizStepFromJSON(filter.value),
        };
      }
    }

    this._epcisDocumentApi
      .listEpcisEvents({
        kvintaOperationRequestListEpcisEventsRequest: {
          input: { paging: { page: this.page, size: this.pageSize, sort: this.currentSort }, filters: filters },
        },
      })
      .then((result) => {
        runInAction(() => {
          this.isLoading = false;
          if (result.status === KvintaOperationStatus.Error) {
            this.listData = [];
          } else {
            this.listData = kepcEventRecToView(result.data.list);
            this.totalCount = result.data.total;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async fetchDataById(id: string) {
    this._epcisDocumentApi
      .readEpcisEventById({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        runInAction(() => {
          this.isLoading = false;
          if (result.status === KvintaOperationStatus.Error) {
            this.listData = [];
          } else {
            this.listData = kepcEventRecToView([result.data as ExtKvintaEpcisEventRecord]);
            this.totalCount = 1;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  formatDateTime(timestamp: number): string {
    return format(new Date(timestamp), this._config.defaultDateTimeFormatting);
  }

  async loadEvent(id: string) {
    this.currentEvent = undefined;
    this.currentExecutionId = undefined;
    this._epcisDocumentApi
      .readFrontendEpcisEventById({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        runInAction(() => {
          if (result.status === KvintaOperationStatus.Ok) {
            this.currentEvent = result.data;
            this.currentExecutionId = result.executionId;
          } else {
            this._notificationManager.sendError(result.error);
          }
        });
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(err.toString());
      });
  }

  get jsonPayload() {
    if (this.currentEvent) {
      return JSON.stringify(this.currentEvent, null, 2);
    } else {
      return 'No data available'; // TODO: Maybe translate
    }
  }
}

function kepcEventRecToView(list: KvintaEpcisEventRecord[]): ExtKvintaEpcisEventRecord[] {
  const viewItems = list.map<ExtKvintaEpcisEventRecord>((doc) => {
    return {
      ...doc,
      eventTimeExt: new Date(doc.eventTime.epochMillis).toISOString(),
      recordTimeExt: new Date(doc.recordTime).toISOString(),
    };
  });
  return viewItems;
}

export const EPCIS_EVENTS_STORE_ID = 'epcisEventsStore';

function getDirection(orderDirection: string): KvintaSortDirection {
  if (orderDirection === 'asc') {
    return KvintaSortDirection.Asc;
  } else {
    return KvintaSortDirection.Desc;
  }
}

function getField(orderBy: number): string {
  switch (orderBy) {
    case 0:
      return 'eventTime';
    case 1:
      return 'recordTime';
    case 2:
      return 'action';
    case 3:
      return 'bizStep';
    case 4:
      return 'readPoint';
    case 5:
      return 'bizLocation';
    case 6:
      return 'disposition';
    case 7:
      return 'persistentDisposition';
    default:
      return 'eventTime';
  }
}
