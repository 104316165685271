/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaIntroduceIntoCirculationProduct
 */
export interface KvintaIntroduceIntoCirculationProduct {
    /**
     * 
     * @type {string}
     * @memberof KvintaIntroduceIntoCirculationProduct
     */
    ki?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaIntroduceIntoCirculationProduct
     */
    kitu?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaIntroduceIntoCirculationProduct
     */
    atk?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaIntroduceIntoCirculationProduct
     */
    productionDate?: string | null;
    /**
     * 
     * @type {Array<KvintaIntroduceIntoCirculationProduct>}
     * @memberof KvintaIntroduceIntoCirculationProduct
     */
    children?: Array<KvintaIntroduceIntoCirculationProduct> | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaIntroduceIntoCirculationProduct
     */
    vsdNumber?: string | null;
}

export function KvintaIntroduceIntoCirculationProductFromJSON(json: any): KvintaIntroduceIntoCirculationProduct {
    return KvintaIntroduceIntoCirculationProductFromJSONTyped(json, false);
}

export function KvintaIntroduceIntoCirculationProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaIntroduceIntoCirculationProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ki': !exists(json, 'ki') ? undefined : json['ki'],
        'kitu': !exists(json, 'kitu') ? undefined : json['kitu'],
        'atk': !exists(json, 'atk') ? undefined : json['atk'],
        'productionDate': !exists(json, 'productionDate') ? undefined : json['productionDate'],
        'children': !exists(json, 'children') ? undefined : (json['children'] === null ? null : (json['children'] as Array<any>).map(KvintaIntroduceIntoCirculationProductFromJSON)),
        'vsdNumber': !exists(json, 'vsdNumber') ? undefined : json['vsdNumber'],
    };
}

export function KvintaIntroduceIntoCirculationProductToJSON(value?: KvintaIntroduceIntoCirculationProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ki': value.ki,
        'kitu': value.kitu,
        'atk': value.atk,
        'productionDate': value.productionDate,
        'children': value.children === undefined ? undefined : (value.children === null ? null : (value.children as Array<any>).map(KvintaIntroduceIntoCirculationProductToJSON)),
        'vsdNumber': value.vsdNumber,
    };
}


