/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaCertDoc,
    KvintaCertDocFromJSON,
    KvintaCertDocFromJSONTyped,
    KvintaCertDocToJSON,
    KvintaGismtPackType,
    KvintaGismtPackTypeFromJSON,
    KvintaGismtPackTypeFromJSONTyped,
    KvintaGismtPackTypeToJSON,
    KvintaGismtSerialNumberEmissionStatusType,
    KvintaGismtSerialNumberEmissionStatusTypeFromJSON,
    KvintaGismtSerialNumberEmissionStatusTypeFromJSONTyped,
    KvintaGismtSerialNumberEmissionStatusTypeToJSON,
    KvintaGismtSerialNumberStatusType,
    KvintaGismtSerialNumberStatusTypeFromJSON,
    KvintaGismtSerialNumberStatusTypeFromJSONTyped,
    KvintaGismtSerialNumberStatusTypeToJSON,
    KvintaPartialSaleInfo,
    KvintaPartialSaleInfoFromJSON,
    KvintaPartialSaleInfoFromJSONTyped,
    KvintaPartialSaleInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGismtCisInfo
 */
export interface KvintaGismtCisInfo {
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    requestedCis?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    cis?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    gtin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    tnVedEaes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    tnVedEaesGroup?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    productName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaGismtCisInfo
     */
    productGroupId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    productGroup?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    brand?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    producedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    emissionDate?: string | null;
    /**
     * 
     * @type {KvintaGismtSerialNumberEmissionStatusType}
     * @memberof KvintaGismtCisInfo
     */
    emissionType?: KvintaGismtSerialNumberEmissionStatusType;
    /**
     * 
     * @type {KvintaGismtPackType}
     * @memberof KvintaGismtCisInfo
     */
    packType?: KvintaGismtPackType;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    ownerInn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    ownerName?: string | null;
    /**
     * 
     * @type {KvintaGismtSerialNumberStatusType}
     * @memberof KvintaGismtCisInfo
     */
    status?: KvintaGismtSerialNumberStatusType;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    statusEx?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaGismtCisInfo
     */
    child?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    parent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    producerInn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    producerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    prVetDocument?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    exporterName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    agentInn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    markWithdraw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    withdrawReason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    withdrawReasonOther?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    expirationDate?: string | null;
    /**
     * 
     * @type {KvintaPartialSaleInfo}
     * @memberof KvintaGismtCisInfo
     */
    partialSaleInfo?: KvintaPartialSaleInfo;
    /**
     * 
     * @type {Array<KvintaCertDoc>}
     * @memberof KvintaGismtCisInfo
     */
    certDoc?: Array<KvintaCertDoc> | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisInfo
     */
    cisTrackingType?: string | null;
}

export function KvintaGismtCisInfoFromJSON(json: any): KvintaGismtCisInfo {
    return KvintaGismtCisInfoFromJSONTyped(json, false);
}

export function KvintaGismtCisInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGismtCisInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestedCis': !exists(json, 'requestedCis') ? undefined : json['requestedCis'],
        'cis': !exists(json, 'cis') ? undefined : json['cis'],
        'gtin': !exists(json, 'gtin') ? undefined : json['gtin'],
        'tnVedEaes': !exists(json, 'tnVedEaes') ? undefined : json['tnVedEaes'],
        'tnVedEaesGroup': !exists(json, 'tnVedEaesGroup') ? undefined : json['tnVedEaesGroup'],
        'productName': !exists(json, 'productName') ? undefined : json['productName'],
        'productGroupId': !exists(json, 'productGroupId') ? undefined : json['productGroupId'],
        'productGroup': !exists(json, 'productGroup') ? undefined : json['productGroup'],
        'brand': !exists(json, 'brand') ? undefined : json['brand'],
        'producedDate': !exists(json, 'producedDate') ? undefined : json['producedDate'],
        'emissionDate': !exists(json, 'emissionDate') ? undefined : json['emissionDate'],
        'emissionType': !exists(json, 'emissionType') ? undefined : KvintaGismtSerialNumberEmissionStatusTypeFromJSON(json['emissionType']),
        'packType': !exists(json, 'packType') ? undefined : KvintaGismtPackTypeFromJSON(json['packType']),
        'ownerInn': !exists(json, 'ownerInn') ? undefined : json['ownerInn'],
        'ownerName': !exists(json, 'ownerName') ? undefined : json['ownerName'],
        'status': !exists(json, 'status') ? undefined : KvintaGismtSerialNumberStatusTypeFromJSON(json['status']),
        'statusEx': !exists(json, 'statusEx') ? undefined : json['statusEx'],
        'child': !exists(json, 'child') ? undefined : json['child'],
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'producerInn': !exists(json, 'producerInn') ? undefined : json['producerInn'],
        'producerName': !exists(json, 'producerName') ? undefined : json['producerName'],
        'prVetDocument': !exists(json, 'prVetDocument') ? undefined : json['prVetDocument'],
        'exporterName': !exists(json, 'exporterName') ? undefined : json['exporterName'],
        'agentInn': !exists(json, 'agentInn') ? undefined : json['agentInn'],
        'markWithdraw': !exists(json, 'markWithdraw') ? undefined : json['markWithdraw'],
        'withdrawReason': !exists(json, 'withdrawReason') ? undefined : json['withdrawReason'],
        'withdrawReasonOther': !exists(json, 'withdrawReasonOther') ? undefined : json['withdrawReasonOther'],
        'expirationDate': !exists(json, 'expirationDate') ? undefined : json['expirationDate'],
        'partialSaleInfo': !exists(json, 'partialSaleInfo') ? undefined : KvintaPartialSaleInfoFromJSON(json['partialSaleInfo']),
        'certDoc': !exists(json, 'certDoc') ? undefined : (json['certDoc'] === null ? null : (json['certDoc'] as Array<any>).map(KvintaCertDocFromJSON)),
        'cisTrackingType': !exists(json, 'cisTrackingType') ? undefined : json['cisTrackingType'],
    };
}

export function KvintaGismtCisInfoToJSON(value?: KvintaGismtCisInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requestedCis': value.requestedCis,
        'cis': value.cis,
        'gtin': value.gtin,
        'tnVedEaes': value.tnVedEaes,
        'tnVedEaesGroup': value.tnVedEaesGroup,
        'productName': value.productName,
        'productGroupId': value.productGroupId,
        'productGroup': value.productGroup,
        'brand': value.brand,
        'producedDate': value.producedDate,
        'emissionDate': value.emissionDate,
        'emissionType': KvintaGismtSerialNumberEmissionStatusTypeToJSON(value.emissionType),
        'packType': KvintaGismtPackTypeToJSON(value.packType),
        'ownerInn': value.ownerInn,
        'ownerName': value.ownerName,
        'status': KvintaGismtSerialNumberStatusTypeToJSON(value.status),
        'statusEx': value.statusEx,
        'child': value.child,
        'parent': value.parent,
        'producerInn': value.producerInn,
        'producerName': value.producerName,
        'prVetDocument': value.prVetDocument,
        'exporterName': value.exporterName,
        'agentInn': value.agentInn,
        'markWithdraw': value.markWithdraw,
        'withdrawReason': value.withdrawReason,
        'withdrawReasonOther': value.withdrawReasonOther,
        'expirationDate': value.expirationDate,
        'partialSaleInfo': KvintaPartialSaleInfoToJSON(value.partialSaleInfo),
        'certDoc': value.certDoc === undefined ? undefined : (value.certDoc === null ? null : (value.certDoc as Array<any>).map(KvintaCertDocToJSON)),
        'cisTrackingType': value.cisTrackingType,
    };
}


