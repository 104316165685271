/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCreateCirculationReportForAtkRequestFields
 */
export interface KvintaCreateCirculationReportForAtkRequestFields {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCirculationReportForAtkRequestFields
     */
    atk?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCirculationReportForAtkRequestFields
     */
    vsdNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCirculationReportForAtkRequestFields
     */
    tradeParticipantInn?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCirculationReportForAtkRequestFields
     */
    declarationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCirculationReportForAtkRequestFields
     */
    declarationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCirculationReportForAtkRequestFields
     */
    productionDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaCreateCirculationReportForAtkRequestFields
     */
    circulationWithoutAtk?: boolean | null;
}

export function KvintaCreateCirculationReportForAtkRequestFieldsFromJSON(json: any): KvintaCreateCirculationReportForAtkRequestFields {
    return KvintaCreateCirculationReportForAtkRequestFieldsFromJSONTyped(json, false);
}

export function KvintaCreateCirculationReportForAtkRequestFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateCirculationReportForAtkRequestFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'atk': !exists(json, 'atk') ? undefined : json['atk'],
        'vsdNumber': !exists(json, 'vsd_number') ? undefined : json['vsd_number'],
        'tradeParticipantInn': !exists(json, 'trade_participant_inn') ? undefined : json['trade_participant_inn'],
        'declarationNumber': !exists(json, 'declaration_number') ? undefined : json['declaration_number'],
        'declarationDate': !exists(json, 'declaration_date') ? undefined : json['declaration_date'],
        'productionDate': !exists(json, 'production_date') ? undefined : json['production_date'],
        'circulationWithoutAtk': !exists(json, 'circulationWithoutAtk') ? undefined : json['circulationWithoutAtk'],
    };
}

export function KvintaCreateCirculationReportForAtkRequestFieldsToJSON(value?: KvintaCreateCirculationReportForAtkRequestFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'atk': value.atk,
        'vsd_number': value.vsdNumber,
        'trade_participant_inn': value.tradeParticipantInn,
        'declaration_number': value.declarationNumber,
        'declaration_date': value.declarationDate,
        'production_date': value.productionDate,
        'circulationWithoutAtk': value.circulationWithoutAtk,
    };
}


