import React from 'react';
import { Route } from 'react-router-dom';
import { Menu, MenuItem, IconButton, Tooltip } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { inject, observer } from 'mobx-react';
import { STORE_ID, UserStore } from 'kvinta/modules/main/UserStore';
import { FormattedMessage, injectIntl } from 'react-intl';

interface UserMenuProps {
  userStore?: UserStore;
  intl?: any;
}
interface userMenuState {
  intl?: any;
  isMenuOpen: boolean;
  anchorEl: null | HTMLElement;
}
class userMenuImpl extends React.Component<UserMenuProps, userMenuState> {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
    };
  }

  handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
    });
  };

  onProfileClicked = (history) => {
    //userStore.navigateTo(Pages.Profile);
    // history.push(Pages.Profile);
    this.onMenuClose();
  };

  onLogoutClicked = () => {
    this.props.userStore.logout();
    // const userInfo = this.props.userStore.userInfo;
    // if (userInfo.logoutUrl && userInfo.logoutUrl !== '') {
    //   window.location.href = userInfo.logoutUrl;
    // } else {
    //   window.location.href = '/console/auth/logout';
    // }
    this.onMenuClose();
  };

  renderMenu = () => {
    const menuId = 'primary-search-account-menu';
    return (
      <Menu
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={this.state.isMenuOpen}
        onClose={this.onMenuClose}
      >
        <MenuItem disabled={true}>
          Email: <br />
          {this.props.userStore.userInfo.email}
        </MenuItem>
        <Route
          render={({ history }) => {
            return (
              <MenuItem onClick={() => this.onProfileClicked(history)}>
                <FormattedMessage id={'user.menu.user-profile'}></FormattedMessage>
              </MenuItem>
            );
          }}
        ></Route>

        <MenuItem onClick={this.onLogoutClicked}>
          <FormattedMessage id={'user.menu.logout'} />
        </MenuItem>
      </Menu>
    );
  };
  render() {
    const userInfo = this.props.userStore.userInfo;

    if (typeof userInfo === 'undefined') {
      return null; //<div>No valid user session</div>;
    }
    const menuId = 'primary-search-account-menu';
    return (
      <div className="api-menu">
        <Tooltip title="Account">
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={this.handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Tooltip>
        {this.renderMenu()}
      </div>
    );
  }
}

export const UserMenu = inject(STORE_ID)(observer(userMenuImpl));
