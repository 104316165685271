/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaFindSgtinListRequest
 */
export interface KvintaFindSgtinListRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaFindSgtinListRequest
     */
    gtin: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaFindSgtinListRequest
     */
    location: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaFindSgtinListRequest
     */
    quantity?: number;
}

export function KvintaFindSgtinListRequestFromJSON(json: any): KvintaFindSgtinListRequest {
    return KvintaFindSgtinListRequestFromJSONTyped(json, false);
}

export function KvintaFindSgtinListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaFindSgtinListRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gtin': json['gtin'],
        'location': json['location'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
    };
}

export function KvintaFindSgtinListRequestToJSON(value?: KvintaFindSgtinListRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gtin': value.gtin,
        'location': value.location,
        'quantity': value.quantity,
    };
}


