/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaNotificationType,
    KvintaNotificationTypeFromJSON,
    KvintaNotificationTypeFromJSONTyped,
    KvintaNotificationTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaCreateNotificationGroupTypeRequest
 */
export interface KvintaCreateNotificationGroupTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateNotificationGroupTypeRequest
     */
    notificationGroupId?: string;
    /**
     * 
     * @type {KvintaNotificationType}
     * @memberof KvintaCreateNotificationGroupTypeRequest
     */
    notificationType?: KvintaNotificationType;
}

export function KvintaCreateNotificationGroupTypeRequestFromJSON(json: any): KvintaCreateNotificationGroupTypeRequest {
    return KvintaCreateNotificationGroupTypeRequestFromJSONTyped(json, false);
}

export function KvintaCreateNotificationGroupTypeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateNotificationGroupTypeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notificationGroupId': !exists(json, 'notificationGroupId') ? undefined : json['notificationGroupId'],
        'notificationType': !exists(json, 'notificationType') ? undefined : KvintaNotificationTypeFromJSON(json['notificationType']),
    };
}

export function KvintaCreateNotificationGroupTypeRequestToJSON(value?: KvintaCreateNotificationGroupTypeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notificationGroupId': value.notificationGroupId,
        'notificationType': KvintaNotificationTypeToJSON(value.notificationType),
    };
}


