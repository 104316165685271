import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import { PageContentStore, PAGE_CONTENT_STORE_ID, tableIcons } from 'kvinta/components';
import { SerialNumberInfoStore, SERIAL_NUMBER_INFO_STORE_ID } from '../SerialNumberInfoStore';

interface SerialNumbersInfoTableProps {
  serialNumberInfoStore?: SerialNumberInfoStore;
  pageContentStore?: PageContentStore;
  intl: any;
  history?: any;
}

class SerialNumbersInfoTableImpl extends Component<SerialNumbersInfoTableProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: SerialNumbersInfoTableProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  render() {
    const intl = this.props.intl;
    const { stinsData, isLoading } = this.props.serialNumberInfoStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightThin;
    return (
      <Route
        render={({ history }) => (
          <MaterialTable
            icons={tableIcons}
            tableRef={this.tableRef}
            columns={[
              {
                title: intl.formatMessage({ id: 'serial-number-info.cis' }),
                field: 'cis',
                defaultSort: 'desc',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'serial-number-info.countChildren' }),
                field: 'countChildren',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
                render: (rowData) => {
                  if (rowData.child) {
                    return <span>{rowData.child.length}</span>;
                  } else return <span>0</span>;
                },
              },
              {
                title: intl.formatMessage({ id: 'serial-number-info.emissionDate' }),
                field: 'emissionDate',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'serial-number-info.ownerInn' }),
                field: 'ownerInn',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'serial-number-info.ownerName' }),
                field: 'ownerName',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'serial-number-info.packType' }),
                field: 'packType',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'serial-number-info.status' }),
                field: 'status',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'serial-number-info.emissionType' }),
                field: 'emissionType',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'serial-number-info.productGroup' }),
                field: 'productGroup',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
            ]}
            data={stinsData}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: 0,
              paging: false,
              sorting: true,
              draggable: false,
              search: false,
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            components={{
              Toolbar: (props) => null,
            }}
          />
        )}
      />
    );
  }
}

export const SerialNumbersInfoTable = injectIntl(
  inject(SERIAL_NUMBER_INFO_STORE_ID, PAGE_CONTENT_STORE_ID)(observer(SerialNumbersInfoTableImpl)),
);
