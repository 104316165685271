import { Box, FormControl, Grid, InputLabel, OutlinedInput } from '@material-ui/core';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KvintaSerialNumber } from 'kvinta/apis/kvinta-document-store';

export interface SerialNumberOrderSerialNumberComponentProps {
  serialNumberOrderSerialNumber?: KvintaSerialNumber;
  intl: any;
}
class SerialNumberOrderSerialNumberCompViewImpl extends Component<SerialNumberOrderSerialNumberComponentProps> {
  render() {
    const comp = this.props.serialNumberOrderSerialNumber;
    if (!comp) {
      return null;
    }
    const intl = this.props.intl;
    const formData = comp;

    return (
      <Grid container>
        <Grid item xs={6}>
          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              {/*Background color used to fix an overlap bug between title and border */}
              <InputLabel htmlFor="serialNumber" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'serial-numbers.serialnumber' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="serialNumber"
                defaultValue={formData.raw}
                label={intl.formatMessage({ id: 'serial-numbers.serialnumber' })}
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="issuingSystemOrderId" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'serial-numbers.issuingsystemorderid' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="issuingSystemOrderId"
                defaultValue={formData.issuingSystemOrderId}
                label={intl.formatMessage({ id: 'serial-numbers.issuingsystemorderid' })}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="issuingSystemId" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'serial-numbers.issuingsystem' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="issuingSystemId"
                defaultValue={formData.issuingSystemId}
                label={intl.formatMessage({ id: 'serial-numbers.issuingsystem' })}
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="orderId" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'serial-numbers.orderid' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="orderId"
                defaultValue={formData.orderId}
                label={intl.formatMessage({ id: 'serial-numbers.orderid' })}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export const SerialNumberOrderSerialNumberCompView = injectIntl(SerialNumberOrderSerialNumberCompViewImpl);
