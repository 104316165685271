/* eslint-disable @typescript-eslint/ban-types */
import { Button, Paper } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { SerialNumberOrdersStore, STORE_ID } from '../SerialNumberOrdersStore';
import { SerialNumberOrderCompView } from './SerialNumberOrderCompView';
import { tabsSummary } from './tabs';
import {
  navigateSerialNumberOrderDocumentStatusesPath,
  navigateSerialNumberOrderItemsPath,
  navigateSerialNumberOrderPath,
  navigateSerialNumberOrderSerialNumbersPath,
  navigateSerialNumberOrdersPath,
} from '../paths';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import KConfirmationDialog from 'kvinta/components/KConfirmationDialog';
import { reaction } from 'mobx';

interface SerialNumberOrderSummaryViewProps {
  intl: any;
  sidebarStore?: KSidebarStore;
  serialNumberOrdersStore?: SerialNumberOrdersStore;
  id: string;
  history?: any;
}

class SerialNumberOrderSummaryViewImpl extends Component<SerialNumberOrderSummaryViewProps> {
  disposer: any;
  componentDidMount() {
    this.props.serialNumberOrdersStore.fetchSerialNumberOrder(this.props.id);
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.serialNumberOrdersStore.currentSerialNumberOrder,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.id, () => {
            navigateSerialNumberOrdersPath(this.props.history);
          });
        }
      },
    );
  }
  componentWillUnmount() {
    this.disposer();
  }

  render() {
    if (!this.props.serialNumberOrdersStore) {
      return null;
    }
    const comp = this.props.serialNumberOrdersStore.currentSerialNumberOrder;
    if (!comp) {
      return null;
    }
    // const { updateSerialNumberOrderFormOpen } = this.props.serialNumberOrdersStore;

    const { hasReleaseSNDoc, hasDeleteSNDoc, confirmDeleteDialog } = this.props.serialNumberOrdersStore;

    const intl = this.props.intl;
    const formData = comp;
    return (
      <div>
        <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          <TabBar
            onChange={(newValue: any) => {
              if (newValue === '0') {
                navigateSerialNumberOrdersPath(this.props.history);
              }
              if (newValue === '1') {
                navigateSerialNumberOrderPath(this.props.history, this.props.id);
              }
              if (newValue === '2') {
                navigateSerialNumberOrderItemsPath(this.props.history, this.props.id);
              }
              if (newValue === '3') {
                navigateSerialNumberOrderSerialNumbersPath(this.props.history, this.props.id);
              }
              if (newValue === '4') {
                navigateSerialNumberOrderDocumentStatusesPath(this.props.history, this.props.id);
              }
            }}
            tabId={'1'}
            tabs={tabsSummary}
          />
        </Paper>
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          {hasReleaseSNDoc && (
            <div style={{ marginRight: '15px', marginTop: '15px', float: 'right' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => {
                  this.props.serialNumberOrdersStore.releaseSNDocument(this.props.id);
                }}
              >
                {intl.formatMessage({ id: 'button.release' })}
              </Button>
            </div>
          )}
          {hasDeleteSNDoc && (
            <div style={{ marginRight: '15px', marginTop: '15px', float: 'right' }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={(e) => {
                  this.props.serialNumberOrdersStore.openDeleteDialog();
                }}
              >
                {intl.formatMessage({ id: 'button.delete' })}
              </Button>
            </div>
          )}
          <SerialNumberOrderCompView serialNumberOrder={formData} />
          {confirmDeleteDialog && (
            <KConfirmationDialog
              isOpen={confirmDeleteDialog}
              title={intl.formatMessage({ id: 'dialog.confim.delete.title' })}
              text={intl.formatMessage({ id: 'dialog.confim.delete.text' })}
              confirmLabel={intl.formatMessage({ id: 'dialog.confim.delete.btn-confirm' })}
              cancelLabel={intl.formatMessage({ id: 'dialog.confim.delete.btn-cancel' })}
              onCancel={() => {
                this.props.serialNumberOrdersStore.hideDeleteDialog();
              }}
              onConfirm={() => {
                this.props.serialNumberOrdersStore.deleteSNDocument(this.props.id, this.props.history);
              }}
            />
          )}
        </Paper>
      </div>
    );
  }
}

export const SerialNumberOrderSummaryView = injectIntl(
  inject(STORE_ID, SIDEBAR_STORE_ID)(observer(SerialNumberOrderSummaryViewImpl)),
);
