/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDateTimeRange,
    KvintaDateTimeRangeFromJSON,
    KvintaDateTimeRangeFromJSONTyped,
    KvintaDateTimeRangeToJSON,
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingFromJSONTyped,
    KvintaPagingToJSON,
    KvintaResourceStatusType,
    KvintaResourceStatusTypeFromJSON,
    KvintaResourceStatusTypeFromJSONTyped,
    KvintaResourceStatusTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaListShipmentAggregatedCustomsDocumentsRequest
 */
export interface KvintaListShipmentAggregatedCustomsDocumentsRequest {
    /**
     * 
     * @type {KvintaDateTimeRange}
     * @memberof KvintaListShipmentAggregatedCustomsDocumentsRequest
     */
    dateTimeRange?: KvintaDateTimeRange;
    /**
     * 
     * @type {string}
     * @memberof KvintaListShipmentAggregatedCustomsDocumentsRequest
     */
    locationGln?: string | null;
    /**
     * 
     * @type {KvintaPaging}
     * @memberof KvintaListShipmentAggregatedCustomsDocumentsRequest
     */
    paging?: KvintaPaging;
    /**
     * 
     * @type {KvintaResourceStatusType}
     * @memberof KvintaListShipmentAggregatedCustomsDocumentsRequest
     */
    aggregatedCustomsDocumentStatus?: KvintaResourceStatusType;
}

export function KvintaListShipmentAggregatedCustomsDocumentsRequestFromJSON(json: any): KvintaListShipmentAggregatedCustomsDocumentsRequest {
    return KvintaListShipmentAggregatedCustomsDocumentsRequestFromJSONTyped(json, false);
}

export function KvintaListShipmentAggregatedCustomsDocumentsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaListShipmentAggregatedCustomsDocumentsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateTimeRange': !exists(json, 'dateTimeRange') ? undefined : KvintaDateTimeRangeFromJSON(json['dateTimeRange']),
        'locationGln': !exists(json, 'locationGln') ? undefined : json['locationGln'],
        'paging': !exists(json, 'paging') ? undefined : KvintaPagingFromJSON(json['paging']),
        'aggregatedCustomsDocumentStatus': !exists(json, 'aggregatedCustomsDocumentStatus') ? undefined : KvintaResourceStatusTypeFromJSON(json['aggregatedCustomsDocumentStatus']),
    };
}

export function KvintaListShipmentAggregatedCustomsDocumentsRequestToJSON(value?: KvintaListShipmentAggregatedCustomsDocumentsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateTimeRange': KvintaDateTimeRangeToJSON(value.dateTimeRange),
        'locationGln': value.locationGln,
        'paging': KvintaPagingToJSON(value.paging),
        'aggregatedCustomsDocumentStatus': KvintaResourceStatusTypeToJSON(value.aggregatedCustomsDocumentStatus),
    };
}


