/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaGismtDocumentProcessingStatusType {
    InternalError = 'INTERNAL_ERROR',
    Accepted = 'ACCEPTED',
    Cancelled = 'CANCELLED',
    CheckedOk = 'CHECKED_OK',
    CheckedNotOk = 'CHECKED_NOT_OK',
    InProgress = 'IN_PROGRESS',
    ParseError = 'PARSE_ERROR',
    ProcessingError = 'PROCESSING_ERROR',
    WaitAcceptance = 'WAIT_ACCEPTANCE',
    WaitForContinuation = 'WAIT_FOR_CONTINUATION',
    WaitParticipantRegistration = 'WAIT_PARTICIPANT_REGISTRATION',
    Partially = 'PARTIALLY',
    Failed = 'FAILED',
    Success = 'SUCCESS'
}

export function KvintaGismtDocumentProcessingStatusTypeFromJSON(json: any): KvintaGismtDocumentProcessingStatusType {
    return KvintaGismtDocumentProcessingStatusTypeFromJSONTyped(json, false);
}

export function KvintaGismtDocumentProcessingStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGismtDocumentProcessingStatusType {
    return json as KvintaGismtDocumentProcessingStatusType;
}

export function KvintaGismtDocumentProcessingStatusTypeToJSON(value?: KvintaGismtDocumentProcessingStatusType | null): any {
    return value as any;
}

