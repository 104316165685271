/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaCustomer,
    KvintaCustomerFromJSON,
    KvintaCustomerFromJSONTyped,
    KvintaCustomerToJSON,
    KvintaOperationStatus,
    KvintaOperationStatusFromJSON,
    KvintaOperationStatusFromJSONTyped,
    KvintaOperationStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetOneResponseCustomer
 */
export interface KvintaGetOneResponseCustomer {
    /**
     * 
     * @type {KvintaCustomer}
     * @memberof KvintaGetOneResponseCustomer
     */
    data?: KvintaCustomer;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetOneResponseCustomer
     */
    error?: string | null;
    /**
     * 
     * @type {KvintaOperationStatus}
     * @memberof KvintaGetOneResponseCustomer
     */
    operationStatus: KvintaOperationStatus;
}

export function KvintaGetOneResponseCustomerFromJSON(json: any): KvintaGetOneResponseCustomer {
    return KvintaGetOneResponseCustomerFromJSONTyped(json, false);
}

export function KvintaGetOneResponseCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetOneResponseCustomer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : KvintaCustomerFromJSON(json['data']),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'operationStatus': KvintaOperationStatusFromJSON(json['operationStatus']),
    };
}

export function KvintaGetOneResponseCustomerToJSON(value?: KvintaGetOneResponseCustomer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': KvintaCustomerToJSON(value.data),
        'error': value.error,
        'operationStatus': KvintaOperationStatusToJSON(value.operationStatus),
    };
}


