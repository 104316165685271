/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Capture Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSensorMetaData,
    KvintaSensorMetaDataFromJSON,
    KvintaSensorMetaDataFromJSONTyped,
    KvintaSensorMetaDataToJSON,
    KvintaSensorReportElement,
    KvintaSensorReportElementFromJSON,
    KvintaSensorReportElementFromJSONTyped,
    KvintaSensorReportElementToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaSensorElement
 */
export interface KvintaSensorElement {
    /**
     * 
     * @type {KvintaSensorMetaData}
     * @memberof KvintaSensorElement
     */
    sensorMetaData?: KvintaSensorMetaData;
    /**
     * 
     * @type {Array<KvintaSensorReportElement>}
     * @memberof KvintaSensorElement
     */
    sensorReport?: Array<KvintaSensorReportElement>;
}

export function KvintaSensorElementFromJSON(json: any): KvintaSensorElement {
    return KvintaSensorElementFromJSONTyped(json, false);
}

export function KvintaSensorElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSensorElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sensorMetaData': !exists(json, 'sensorMetaData') ? undefined : KvintaSensorMetaDataFromJSON(json['sensorMetaData']),
        'sensorReport': !exists(json, 'sensorReport') ? undefined : ((json['sensorReport'] as Array<any>).map(KvintaSensorReportElementFromJSON)),
    };
}

export function KvintaSensorElementToJSON(value?: KvintaSensorElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sensorMetaData': KvintaSensorMetaDataToJSON(value.sensorMetaData),
        'sensorReport': value.sensorReport === undefined ? undefined : ((value.sensorReport as Array<any>).map(KvintaSensorReportElementToJSON)),
    };
}


