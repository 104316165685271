/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaPartialSaleInfo
 */
export interface KvintaPartialSaleInfo {
    /**
     * 
     * @type {number}
     * @memberof KvintaPartialSaleInfo
     */
    innerUnitCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaPartialSaleInfo
     */
    soldUnitCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaPartialSaleInfo
     */
    rest?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaPartialSaleInfo
     */
    correctRest?: boolean | null;
}

export function KvintaPartialSaleInfoFromJSON(json: any): KvintaPartialSaleInfo {
    return KvintaPartialSaleInfoFromJSONTyped(json, false);
}

export function KvintaPartialSaleInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaPartialSaleInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'innerUnitCount': !exists(json, 'innerUnitCount') ? undefined : json['innerUnitCount'],
        'soldUnitCount': !exists(json, 'soldUnitCount') ? undefined : json['soldUnitCount'],
        'rest': !exists(json, 'rest') ? undefined : json['rest'],
        'correctRest': !exists(json, 'correctRest') ? undefined : json['correctRest'],
    };
}

export function KvintaPartialSaleInfoToJSON(value?: KvintaPartialSaleInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'innerUnitCount': value.innerUnitCount,
        'soldUnitCount': value.soldUnitCount,
        'rest': value.rest,
        'correctRest': value.correctRest,
    };
}


