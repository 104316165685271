/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaDateTimeOpenRange
 */
export interface KvintaDateTimeOpenRange {
    /**
     * 
     * @type {number}
     * @memberof KvintaDateTimeOpenRange
     */
    from?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaDateTimeOpenRange
     */
    to?: number | null;
}

export function KvintaDateTimeOpenRangeFromJSON(json: any): KvintaDateTimeOpenRange {
    return KvintaDateTimeOpenRangeFromJSONTyped(json, false);
}

export function KvintaDateTimeOpenRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDateTimeOpenRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
    };
}

export function KvintaDateTimeOpenRangeToJSON(value?: KvintaDateTimeOpenRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from,
        'to': value.to,
    };
}


