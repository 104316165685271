import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { tabs } from './tabs';

import React, { Component } from 'react';
import { CirculationReportsStore, CIRCULATION_REPORTS_STORE_ID } from './CirculationReportsStore';
import { PageContentStore, PAGE_CONTENT_STORE_ID, TabBar } from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { Paper } from '@material-ui/core';
import {
  navigateCirculationReportSummaryPath,
  navigateCirculationReportsListPath,
  navigateCirculationReportStatusesPath,
} from './paths';
import { tabBarHeight } from 'kvinta/common';

interface ViewCirculationReportJsonProps {
  intl: any;
  history?: any;
  circulationReportsStore: CirculationReportsStore;
  pageContentStore: PageContentStore;
  id: string;
}
class ViewCirculationReportJsonImpl extends Component<ViewCirculationReportJsonProps> {
  componentDidMount() {
    this.props.circulationReportsStore.fetchCirculationReport(this.props.id);
  }

  render() {
    const currentCirculationReport = this.props.circulationReportsStore.currentCirculationReport;
    if (!currentCirculationReport) {
      return null;
    }
    const { width, height } = this.props.pageContentStore;
    const newHeight = height - tabBarHeight;
    const jsonPayload = this.props.circulationReportsStore.jsonPayload;
    return (
      <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              navigateCirculationReportsListPath(this.props.history);
            }
            if (newValue === '1') {
              navigateCirculationReportSummaryPath(this.props.history, this.props.id);
            }
            if (newValue === '2') {
              navigateCirculationReportStatusesPath(this.props.history, this.props.id);
            }
          }}
          tabId={'3'}
          tabs={tabs}
        />
        <AceEditor
          mode="json"
          style={{ width, height: newHeight }}
          theme="github"
          readOnly={true}
          setOptions={{ useWorker: false }}
          editorProps={{
            $blockScrolling: false,
            $highlightPending: false,
          }}
          enableBasicAutocompletion={false}
          enableLiveAutocompletion={false}
          value={jsonPayload}
          onChange={() => {
            // Nothing to do here
          }}
        />
      </Paper>
    );
  }
}
export const ViewCirculationReportJson = injectIntl(
  inject(CIRCULATION_REPORTS_STORE_ID, PAGE_CONTENT_STORE_ID)(observer(ViewCirculationReportJsonImpl)),
);
