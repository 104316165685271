/* eslint-disable @typescript-eslint/ban-types */
import { Paper, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';

export interface TabSpec {
  label: string;
  value: any;
}

interface TabBarProps {
  intl: any;
  history?: any;
  tabId: any;
  tabs: TabSpec[];
  onChange: (newValue: any) => any;
}

class TabBarImpl extends Component<TabBarProps> {
  render() {
    const { tabId, tabs, intl } = this.props;
    if (!tabId || !tabs) {
      return null;
    }
    return (
      <Paper square>
        <Tabs
          value={tabId}
          indicatorColor="secondary"
          textColor="primary"
          onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
            this.props.onChange(newValue);
          }}
        >
          {tabs.map((tab) => {
            return (
              <Tab
                label={intl.formatMessage({ id: tab.label })}
                value={tab.value}
                id={`tab-${tab.value}`}
                key={`tab-${tab.value}`}
              />
            );
          })}
        </Tabs>
      </Paper>
    );
  }
}

export const TabBar = injectIntl(TabBarImpl);
