import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { tabs } from './tabs';
import React, { Component } from 'react';
import { ProcessesStatusStore, PROCESSES_STATUS_STORE_ID } from './ProcessesStatusStore';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { Paper } from '@material-ui/core';
import { tabBarHeight } from 'kvinta/common';
import { reaction } from 'mobx';
import { navigateExecutionStatusesPath, navigateExecutionStatusHierarchyPath } from 'kvinta/modules/paths';

interface ViewProcessStatusJsonProps {
  intl: any;
  history?: any;
  sidebarStore?: KSidebarStore;
  processesStatusStore: ProcessesStatusStore;
  id: string;
}
class ViewProcessStatusJsonImpl extends Component<ViewProcessStatusJsonProps> {
  disposer: any;
  componentDidMount() {
    this.props.processesStatusStore.loadEvent(this.props.id);
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.processesStatusStore.currentExecution,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.execution.context.executionId, () => {
            navigateExecutionStatusesPath(this.props.history);
          });
        }
      },
    );
  }
  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const currentExecution = this.props.processesStatusStore.currentExecution;
    if (!currentExecution) {
      return null;
    }
    const { width, height } = this.props.processesStatusStore.pageContentStore;
    const newHeight = height - tabBarHeight;
    const jsonPayload = this.props.processesStatusStore.jsonPayload;
    return (
      <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '2') {
              navigateExecutionStatusHierarchyPath(this.props.history, this.props.id);
            }
          }}
          tabId={'1'}
          tabs={tabs}
        />
        <AceEditor
          mode="json"
          style={{ width, height: newHeight }}
          theme="github"
          readOnly={true}
          setOptions={{ useWorker: false }}
          editorProps={{
            $blockScrolling: false,
            $highlightPending: false,
          }}
          enableBasicAutocompletion={false}
          enableLiveAutocompletion={false}
          value={jsonPayload}
          onChange={() => {
            // Nothing to do here
          }}
        />
      </Paper>
    );
  }
}
export const ViewProcessStatusJson = injectIntl(
  inject(PROCESSES_STATUS_STORE_ID, SIDEBAR_STORE_ID)(observer(ViewProcessStatusJsonImpl)),
);
