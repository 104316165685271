/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaIntroduceIntoCirculationProduct,
    KvintaIntroduceIntoCirculationProductFromJSON,
    KvintaIntroduceIntoCirculationProductFromJSONTyped,
    KvintaIntroduceIntoCirculationProductToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaIntroduceIntoCirculation
 */
export interface KvintaIntroduceIntoCirculation {
    /**
     * 
     * @type {string}
     * @memberof KvintaIntroduceIntoCirculation
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaIntroduceIntoCirculation
     */
    tradeParticipantInn?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaIntroduceIntoCirculation
     */
    declarationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaIntroduceIntoCirculation
     */
    declarationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaIntroduceIntoCirculation
     */
    productionDate?: string | null;
    /**
     * 
     * @type {Array<KvintaIntroduceIntoCirculationProduct>}
     * @memberof KvintaIntroduceIntoCirculation
     */
    productsList?: Array<KvintaIntroduceIntoCirculationProduct>;
}

export function KvintaIntroduceIntoCirculationFromJSON(json: any): KvintaIntroduceIntoCirculation {
    return KvintaIntroduceIntoCirculationFromJSONTyped(json, false);
}

export function KvintaIntroduceIntoCirculationFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaIntroduceIntoCirculation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tradeParticipantInn': !exists(json, 'tradeParticipantInn') ? undefined : json['tradeParticipantInn'],
        'declarationNumber': !exists(json, 'declarationNumber') ? undefined : json['declarationNumber'],
        'declarationDate': !exists(json, 'declarationDate') ? undefined : json['declarationDate'],
        'productionDate': !exists(json, 'productionDate') ? undefined : json['productionDate'],
        'productsList': !exists(json, 'productsList') ? undefined : ((json['productsList'] as Array<any>).map(KvintaIntroduceIntoCirculationProductFromJSON)),
    };
}

export function KvintaIntroduceIntoCirculationToJSON(value?: KvintaIntroduceIntoCirculation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tradeParticipantInn': value.tradeParticipantInn,
        'declarationNumber': value.declarationNumber,
        'declarationDate': value.declarationDate,
        'productionDate': value.productionDate,
        'productsList': value.productsList === undefined ? undefined : ((value.productsList as Array<any>).map(KvintaIntroduceIntoCirculationProductToJSON)),
    };
}


