import { TabSpec } from 'kvinta/components';
import { escapeId } from 'kvinta/modules/paths';
import {
  navigateEpcMessageHierarchyPath,
  navigateEpcMessageMapPath,
  navigateEpcMessagePath,
  navigateEpcMessagesPath,
} from './paths';

export const tabs = [
  {
    label: 'common.summary',
    value: '1',
  },
  {
    label: 'common.map',
    value: '2',
  },
  {
    label: 'epc.hierarchy',
    value: '3',
  },
] as TabSpec[];

export function navigateEpcTab(
  history: any,
  tabId: string,
  currentEpcId: string,
  currentTs: number,
  currentIdx?: number,
) {
  const newValue = parseInt(tabId);
  switch (newValue) {
    case 0: {
      navigateEpcMessagesPath(history);
      break;
    }
    case 1: {
      navigateEpcMessagePath(history, currentEpcId, currentTs, currentIdx);
      break;
    }
    case 2: {
      navigateEpcMessageMapPath(history, currentEpcId, currentTs, currentIdx);
      break;
    }
    case 3: {
      navigateEpcMessageHierarchyPath(history, currentEpcId, currentTs, currentIdx);
      break;
    }
    default:
  }
}
