/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaQuantityElement
 */
export interface KvintaQuantityElement {
    /**
     * 
     * @type {string}
     * @memberof KvintaQuantityElement
     */
    epcClass: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaQuantityElement
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaQuantityElement
     */
    uom?: string | null;
}

export function KvintaQuantityElementFromJSON(json: any): KvintaQuantityElement {
    return KvintaQuantityElementFromJSONTyped(json, false);
}

export function KvintaQuantityElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaQuantityElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epcClass': json['epcClass'],
        'quantity': json['quantity'],
        'uom': !exists(json, 'uom') ? undefined : json['uom'],
    };
}

export function KvintaQuantityElementToJSON(value?: KvintaQuantityElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epcClass': value.epcClass,
        'quantity': value.quantity,
        'uom': value.uom,
    };
}


