import React, { Component, Suspense } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'assets/css/App.scss';
import 'react-toastify/dist/ReactToastify.css';

import { DynamicAlert, PageContent } from 'kvinta/components';

import KSidebar from 'kvinta/components/Sidebar/KSidebar';
import { routes } from './routes';
import { NotificationDetail } from 'kvinta/modules/main';

interface ISidebar {
  userStore?: any;
}

export default class Root extends Component<ISidebar> {
  theme = createMuiTheme({
    palette: {
      type: 'light',
      primary: { main: '#333382' }, // Purple and green play nicely together.
      secondary: { main: '#00C3A0' }, // This is just green.A700 as hex.
    },
    typography: {
      fontFamily: [
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontWeightMedium: 500,
      button: {
        textTransform: 'none',
        fontWeight: 'normal',
        fontSize: '1em',
      },
    },
  });

  constructor(props: any) {
    super(props);

    document.addEventListener('notification', (e) => this.onNotificationReceived(e as CustomEvent<NotificationDetail>));
  }

  onNotificationReceived(e: CustomEvent<NotificationDetail>): void {
    const msg = e.detail.message;
    const type = e.detail.type;
    this.showNotification(msg, type);
  }

  showNotification(msg: string, type: any): void {
    const notification = <DynamicAlert severity={type} msg={msg}></DynamicAlert>;
    toast(notification);
  }

  routes() {
    return (
      <Suspense fallback={<p>Loading...</p>}>
        <Switch>
          {routes.map((r, index) => {
            return (
              <Route
                path={r.path}
                key={index}
                render={({ match, history }) => {
                  const passPars = { ...match.params, history: history };
                  return <r.comp {...passPars} />;
                }}
                exact={true}
              />
            );
          })}
        </Switch>
      </Suspense>
    );
  }

  render() {
    return (
      <Router>
        <MuiThemeProvider theme={this.theme}>
          <div id="app-wrapper">
            <Switch>
              {routes.map((r, index) => {
                return (
                  <Route path={r.path} key={index} exact={true}>
                    <KSidebar />
                  </Route>
                );
              })}
            </Switch>

            <PageContent>{this.routes()}</PageContent>
            <Suspense fallback={<div>Loading ...</div>}>
              <ToastContainer
                hideProgressBar={true}
                newestOnTop={true}
                closeButton={false}
                position="top-right"
                toastClassName="toast-notification-wrap"
              />
            </Suspense>
          </div>
        </MuiThemeProvider>
      </Router>
    );
  }
}

// export const Root = observer(root);
