/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisBizStep,
    KvintaEpcisBizStepFromJSON,
    KvintaEpcisBizStepFromJSONTyped,
    KvintaEpcisBizStepToJSON,
    KvintaSequenceStatus,
    KvintaSequenceStatusFromJSON,
    KvintaSequenceStatusFromJSONTyped,
    KvintaSequenceStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaSequenceStatusRecord
 */
export interface KvintaSequenceStatusRecord {
    /**
     * 
     * @type {number}
     * @memberof KvintaSequenceStatusRecord
     */
    repStatusId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSequenceStatusRecord
     */
    eventId?: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaSequenceStatusRecord
     */
    eventTime?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSequenceStatusRecord
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSequenceStatusRecord
     */
    epc?: string;
    /**
     * 
     * @type {KvintaEpcisBizStep}
     * @memberof KvintaSequenceStatusRecord
     */
    bizStep?: KvintaEpcisBizStep;
    /**
     * 
     * @type {KvintaSequenceStatus}
     * @memberof KvintaSequenceStatusRecord
     */
    status?: KvintaSequenceStatus;
    /**
     * 
     * @type {number}
     * @memberof KvintaSequenceStatusRecord
     */
    createTimestamp?: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaSequenceStatusRecord
     */
    updateTimestamp?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaSequenceStatusRecord
     */
    dependencies?: Array<string> | null;
}

export function KvintaSequenceStatusRecordFromJSON(json: any): KvintaSequenceStatusRecord {
    return KvintaSequenceStatusRecordFromJSONTyped(json, false);
}

export function KvintaSequenceStatusRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSequenceStatusRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'repStatusId': !exists(json, 'repStatusId') ? undefined : json['repStatusId'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'eventTime': !exists(json, 'eventTime') ? undefined : json['eventTime'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'epc': !exists(json, 'epc') ? undefined : json['epc'],
        'bizStep': !exists(json, 'bizStep') ? undefined : KvintaEpcisBizStepFromJSON(json['bizStep']),
        'status': !exists(json, 'status') ? undefined : KvintaSequenceStatusFromJSON(json['status']),
        'createTimestamp': !exists(json, 'createTimestamp') ? undefined : json['createTimestamp'],
        'updateTimestamp': !exists(json, 'updateTimestamp') ? undefined : json['updateTimestamp'],
        'dependencies': !exists(json, 'dependencies') ? undefined : json['dependencies'],
    };
}

export function KvintaSequenceStatusRecordToJSON(value?: KvintaSequenceStatusRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'repStatusId': value.repStatusId,
        'eventId': value.eventId,
        'eventTime': value.eventTime,
        'tenantId': value.tenantId,
        'epc': value.epc,
        'bizStep': KvintaEpcisBizStepToJSON(value.bizStep),
        'status': KvintaSequenceStatusToJSON(value.status),
        'createTimestamp': value.createTimestamp,
        'updateTimestamp': value.updateTimestamp,
        'dependencies': value.dependencies,
    };
}


