/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingFromJSONTyped,
    KvintaPagingToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaListSerialNumberGenerationProfilesRequest
 */
export interface KvintaListSerialNumberGenerationProfilesRequest {
    /**
     * 
     * @type {KvintaPaging}
     * @memberof KvintaListSerialNumberGenerationProfilesRequest
     */
    paging: KvintaPaging;
}

export function KvintaListSerialNumberGenerationProfilesRequestFromJSON(json: any): KvintaListSerialNumberGenerationProfilesRequest {
    return KvintaListSerialNumberGenerationProfilesRequestFromJSONTyped(json, false);
}

export function KvintaListSerialNumberGenerationProfilesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaListSerialNumberGenerationProfilesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paging': KvintaPagingFromJSON(json['paging']),
    };
}

export function KvintaListSerialNumberGenerationProfilesRequestToJSON(value?: KvintaListSerialNumberGenerationProfilesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paging': KvintaPagingToJSON(value.paging),
    };
}


