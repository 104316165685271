/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCreateSerialNumberRequest
 */
export interface KvintaCreateSerialNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberRequest
     */
    cryptoTail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaCreateSerialNumberRequest
     */
    expiredDate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberRequest
     */
    gtin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberRequest
     */
    issuingSystemId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberRequest
     */
    issuingSystemOrderId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberRequest
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberRequest
     */
    raw: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberRequest
     */
    serial?: string | null;
}

export function KvintaCreateSerialNumberRequestFromJSON(json: any): KvintaCreateSerialNumberRequest {
    return KvintaCreateSerialNumberRequestFromJSONTyped(json, false);
}

export function KvintaCreateSerialNumberRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateSerialNumberRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cryptoTail': !exists(json, 'cryptoTail') ? undefined : json['cryptoTail'],
        'expiredDate': !exists(json, 'expiredDate') ? undefined : json['expiredDate'],
        'gtin': !exists(json, 'gtin') ? undefined : json['gtin'],
        'issuingSystemId': json['issuingSystemId'],
        'issuingSystemOrderId': json['issuingSystemOrderId'],
        'orderId': json['orderId'],
        'raw': json['raw'],
        'serial': !exists(json, 'serial') ? undefined : json['serial'],
    };
}

export function KvintaCreateSerialNumberRequestToJSON(value?: KvintaCreateSerialNumberRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cryptoTail': value.cryptoTail,
        'expiredDate': value.expiredDate,
        'gtin': value.gtin,
        'issuingSystemId': value.issuingSystemId,
        'issuingSystemOrderId': value.issuingSystemOrderId,
        'orderId': value.orderId,
        'raw': value.raw,
        'serial': value.serial,
    };
}


