export const VerificationListPath = '/verification-list';
export const VerificationPath = '/verification/:id';
export const VerificationProductsPath = '/verification/:id/products';
export const VerificationResultsPath = '/verification/:id/results';

export function navigateVerificationListPath(history: any) {
  history.push(VerificationListPath);
}

export function navigateVerificationPath(history: any, eventId: string) {
  history.push(VerificationPath.replaceAll(':id', eventId));
}

export function linkVerificationPath(eventId: string) {
  return VerificationPath.replaceAll(':id', eventId);
}

export function navigateVerificationProductsPath(history: any, eventId: string) {
  history.push(VerificationProductsPath.replaceAll(':id', eventId));
}

export function navigateVerificationResultsPath(history: any, eventId: string) {
  history.push(VerificationResultsPath.replaceAll(':id', eventId));
}
