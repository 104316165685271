export const EpcisMessagesPath = '/epcis-documents';
export const EpcisMessagePath = '/epcis-document/:id';

export function navigateEpcisMessagesPath(history: any) {
  history.push(EpcisMessagesPath);
}

export function navigateEpcisMessagePath(history: any, msgId: string) {
  history.push(EpcisMessagePath.replaceAll(':id', msgId));
}

export function linkEpcisMessagePath(history: any, msgId: string): string {
  return EpcisMessagePath.replaceAll(':id', msgId);
}
