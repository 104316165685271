/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaResourceStatusType,
    KvintaResourceStatusTypeFromJSON,
    KvintaResourceStatusTypeFromJSONTyped,
    KvintaResourceStatusTypeToJSON,
    KvintaResourceType,
    KvintaResourceTypeFromJSON,
    KvintaResourceTypeFromJSONTyped,
    KvintaResourceTypeToJSON,
    KvintaSoftwareSystem,
    KvintaSoftwareSystemFromJSON,
    KvintaSoftwareSystemFromJSONTyped,
    KvintaSoftwareSystemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaResourceStatusRecordFlat
 */
export interface KvintaResourceStatusRecordFlat {
    /**
     * 
     * @type {string}
     * @memberof KvintaResourceStatusRecordFlat
     */
    error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaResourceStatusRecordFlat
     */
    executionId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaResourceStatusRecordFlat
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaResourceStatusRecordFlat
     */
    parentResourceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaResourceStatusRecordFlat
     */
    resourceId: string;
    /**
     * 
     * @type {KvintaSoftwareSystem}
     * @memberof KvintaResourceStatusRecordFlat
     */
    resourceSystem: KvintaSoftwareSystem;
    /**
     * 
     * @type {KvintaResourceType}
     * @memberof KvintaResourceStatusRecordFlat
     */
    resourceType: KvintaResourceType;
    /**
     * 
     * @type {string}
     * @memberof KvintaResourceStatusRecordFlat
     */
    rootExecutionId: string;
    /**
     * 
     * @type {KvintaResourceStatusType}
     * @memberof KvintaResourceStatusRecordFlat
     */
    status: KvintaResourceStatusType;
    /**
     * 
     * @type {string}
     * @memberof KvintaResourceStatusRecordFlat
     */
    systemStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaResourceStatusRecordFlat
     */
    tenantId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaResourceStatusRecordFlat
     */
    timestamp?: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaResourceStatusRecordFlat
     */
    userId?: string | null;
}

export function KvintaResourceStatusRecordFlatFromJSON(json: any): KvintaResourceStatusRecordFlat {
    return KvintaResourceStatusRecordFlatFromJSONTyped(json, false);
}

export function KvintaResourceStatusRecordFlatFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaResourceStatusRecordFlat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'executionId': json['executionId'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'parentResourceId': !exists(json, 'parentResourceId') ? undefined : json['parentResourceId'],
        'resourceId': json['resourceId'],
        'resourceSystem': KvintaSoftwareSystemFromJSON(json['resourceSystem']),
        'resourceType': KvintaResourceTypeFromJSON(json['resourceType']),
        'rootExecutionId': json['rootExecutionId'],
        'status': KvintaResourceStatusTypeFromJSON(json['status']),
        'systemStatus': !exists(json, 'systemStatus') ? undefined : json['systemStatus'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function KvintaResourceStatusRecordFlatToJSON(value?: KvintaResourceStatusRecordFlat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'executionId': value.executionId,
        'message': value.message,
        'parentResourceId': value.parentResourceId,
        'resourceId': value.resourceId,
        'resourceSystem': KvintaSoftwareSystemToJSON(value.resourceSystem),
        'resourceType': KvintaResourceTypeToJSON(value.resourceType),
        'rootExecutionId': value.rootExecutionId,
        'status': KvintaResourceStatusTypeToJSON(value.status),
        'systemStatus': value.systemStatus,
        'tenantId': value.tenantId,
        'timestamp': value.timestamp,
        'userId': value.userId,
    };
}


