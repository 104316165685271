/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcType,
    KvintaEpcTypeFromJSON,
    KvintaEpcTypeFromJSONTyped,
    KvintaEpcTypeToJSON,
    KvintaSerialNumberGenerationProfileCompanyPrefix,
    KvintaSerialNumberGenerationProfileCompanyPrefixFromJSON,
    KvintaSerialNumberGenerationProfileCompanyPrefixFromJSONTyped,
    KvintaSerialNumberGenerationProfileCompanyPrefixToJSON,
    KvintaSerialNumberRange,
    KvintaSerialNumberRangeFromJSON,
    KvintaSerialNumberRangeFromJSONTyped,
    KvintaSerialNumberRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaSerialNumberGenerationProfile
 */
export interface KvintaSerialNumberGenerationProfile {
    /**
     * 
     * @type {number}
     * @memberof KvintaSerialNumberGenerationProfile
     */
    alertThreshold?: number;
    /**
     * 
     * @type {Array<KvintaSerialNumberGenerationProfileCompanyPrefix>}
     * @memberof KvintaSerialNumberGenerationProfile
     */
    companyPrefixes?: Array<KvintaSerialNumberGenerationProfileCompanyPrefix> | null;
    /**
     * 
     * @type {KvintaEpcType}
     * @memberof KvintaSerialNumberGenerationProfile
     */
    epcType: KvintaEpcType;
    /**
     * 
     * @type {number}
     * @memberof KvintaSerialNumberGenerationProfile
     */
    extensionDigit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaSerialNumberGenerationProfile
     */
    globallyUnique?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberGenerationProfile
     */
    id: string;
    /**
     * 
     * @type {Array<KvintaSerialNumberRange>}
     * @memberof KvintaSerialNumberGenerationProfile
     */
    ranges?: Array<KvintaSerialNumberRange> | null;
}

export function KvintaSerialNumberGenerationProfileFromJSON(json: any): KvintaSerialNumberGenerationProfile {
    return KvintaSerialNumberGenerationProfileFromJSONTyped(json, false);
}

export function KvintaSerialNumberGenerationProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSerialNumberGenerationProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alertThreshold': !exists(json, 'alertThreshold') ? undefined : json['alertThreshold'],
        'companyPrefixes': !exists(json, 'companyPrefixes') ? undefined : (json['companyPrefixes'] === null ? null : (json['companyPrefixes'] as Array<any>).map(KvintaSerialNumberGenerationProfileCompanyPrefixFromJSON)),
        'epcType': KvintaEpcTypeFromJSON(json['epcType']),
        'extensionDigit': !exists(json, 'extensionDigit') ? undefined : json['extensionDigit'],
        'globallyUnique': !exists(json, 'globallyUnique') ? undefined : json['globallyUnique'],
        'id': json['id'],
        'ranges': !exists(json, 'ranges') ? undefined : (json['ranges'] === null ? null : (json['ranges'] as Array<any>).map(KvintaSerialNumberRangeFromJSON)),
    };
}

export function KvintaSerialNumberGenerationProfileToJSON(value?: KvintaSerialNumberGenerationProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alertThreshold': value.alertThreshold,
        'companyPrefixes': value.companyPrefixes === undefined ? undefined : (value.companyPrefixes === null ? null : (value.companyPrefixes as Array<any>).map(KvintaSerialNumberGenerationProfileCompanyPrefixToJSON)),
        'epcType': KvintaEpcTypeToJSON(value.epcType),
        'extensionDigit': value.extensionDigit,
        'globallyUnique': value.globallyUnique,
        'id': value.id,
        'ranges': value.ranges === undefined ? undefined : (value.ranges === null ? null : (value.ranges as Array<any>).map(KvintaSerialNumberRangeToJSON)),
    };
}


