/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaSerialNumberOrderType {
    OmsMilk = 'OMS_MILK',
    OmsPharma = 'OMS_PHARMA',
    OmsLight = 'OMS_LIGHT',
    OmsShoes = 'OMS_SHOES',
    OmsTires = 'OMS_TIRES',
    KvintaGenerator = 'KVINTA_GENERATOR'
}

export function KvintaSerialNumberOrderTypeFromJSON(json: any): KvintaSerialNumberOrderType {
    return KvintaSerialNumberOrderTypeFromJSONTyped(json, false);
}

export function KvintaSerialNumberOrderTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSerialNumberOrderType {
    return json as KvintaSerialNumberOrderType;
}

export function KvintaSerialNumberOrderTypeToJSON(value?: KvintaSerialNumberOrderType | null): any {
    return value as any;
}

