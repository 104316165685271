/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Query Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDateTimeRange,
    KvintaDateTimeRangeFromJSON,
    KvintaDateTimeRangeFromJSONTyped,
    KvintaDateTimeRangeToJSON,
    KvintaEventListFilter,
    KvintaEventListFilterFromJSON,
    KvintaEventListFilterFromJSONTyped,
    KvintaEventListFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetEpcEventListRequest
 */
export interface KvintaGetEpcEventListRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcEventListRequest
     */
    page?: string | null;
    /**
     * 
     * @type {KvintaDateTimeRange}
     * @memberof KvintaGetEpcEventListRequest
     */
    range?: KvintaDateTimeRange;
    /**
     * 
     * @type {KvintaEventListFilter}
     * @memberof KvintaGetEpcEventListRequest
     */
    filter?: KvintaEventListFilter;
}

export function KvintaGetEpcEventListRequestFromJSON(json: any): KvintaGetEpcEventListRequest {
    return KvintaGetEpcEventListRequestFromJSONTyped(json, false);
}

export function KvintaGetEpcEventListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetEpcEventListRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'range': !exists(json, 'range') ? undefined : KvintaDateTimeRangeFromJSON(json['range']),
        'filter': !exists(json, 'filter') ? undefined : KvintaEventListFilterFromJSON(json['filter']),
    };
}

export function KvintaGetEpcEventListRequestToJSON(value?: KvintaGetEpcEventListRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'range': KvintaDateTimeRangeToJSON(value.range),
        'filter': KvintaEventListFilterToJSON(value.filter),
    };
}


