/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaResourceStatusType,
    KvintaResourceStatusTypeFromJSON,
    KvintaResourceStatusTypeFromJSONTyped,
    KvintaResourceStatusTypeToJSON,
    KvintaTimestamp,
    KvintaTimestampFromJSON,
    KvintaTimestampFromJSONTyped,
    KvintaTimestampToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaResourceStatus
 */
export interface KvintaResourceStatus {
    /**
     * 
     * @type {string}
     * @memberof KvintaResourceStatus
     */
    error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaResourceStatus
     */
    executionId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaResourceStatus
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaResourceStatus
     */
    rootExecutionId: string;
    /**
     * 
     * @type {KvintaResourceStatusType}
     * @memberof KvintaResourceStatus
     */
    status: KvintaResourceStatusType;
    /**
     * 
     * @type {string}
     * @memberof KvintaResourceStatus
     */
    systemStatus?: string | null;
    /**
     * 
     * @type {KvintaTimestamp}
     * @memberof KvintaResourceStatus
     */
    timestamp: KvintaTimestamp;
}

export function KvintaResourceStatusFromJSON(json: any): KvintaResourceStatus {
    return KvintaResourceStatusFromJSONTyped(json, false);
}

export function KvintaResourceStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaResourceStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'executionId': json['executionId'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'rootExecutionId': json['rootExecutionId'],
        'status': KvintaResourceStatusTypeFromJSON(json['status']),
        'systemStatus': !exists(json, 'systemStatus') ? undefined : json['systemStatus'],
        'timestamp': KvintaTimestampFromJSON(json['timestamp']),
    };
}

export function KvintaResourceStatusToJSON(value?: KvintaResourceStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'executionId': value.executionId,
        'message': value.message,
        'rootExecutionId': value.rootExecutionId,
        'status': KvintaResourceStatusTypeToJSON(value.status),
        'systemStatus': value.systemStatus,
        'timestamp': KvintaTimestampToJSON(value.timestamp),
    };
}


