import { Box, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { KvintaTextDocument } from 'kvinta/apis/kvinta-document-store';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { navigateEpcisEventJsonPath, navigateExecutionStatusPath } from 'kvinta/modules/paths';

export interface ATKSummaryComponentProps {
  atk?: KvintaTextDocument;
  intl: any;
  history: any;
}
class ATKSummaryCompViewImpl extends Component<ATKSummaryComponentProps> {
  render() {
    const comp = this.props.atk;
    if (!comp) {
      return null;
    }
    const intl = this.props.intl;
    const formData = comp;
    const formDataContent = JSON.parse(formData.content);
    const createdDate = new Date(formData.record.timestamp.epochMillis);
    const formattedCreatedDate =
      ('0' + createdDate.getDate()).slice(-2) +
      '.' +
      ('0' + (createdDate.getMonth() + 1)).slice(-2) +
      '.' +
      createdDate.getFullYear() +
      ' ' +
      ('0' + createdDate.getHours()).slice(-2) +
      ':' +
      ('0' + createdDate.getMinutes()).slice(-2);

    return (
      <Grid container>
        <Grid item xs={6}>
          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              {/*Background color used to fix an overlap bug between title and border */}
              <InputLabel htmlFor="atkId" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'atk-list.header.id' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="atkId"
                defaultValue={formData.record.resource.id}
                label="atkId label"
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="userId" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'atk-list.header.user' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="userId"
                defaultValue={formData.record.resource.userId}
                label="userId label"
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="taxNumber" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'atk.header.tax-number' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="taxNumber"
                defaultValue={formDataContent['taxNumber']}
                label="taxNumber label"
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="parentResourceId" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'atk.header.created-by-execution' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="parentResourceId"
                defaultValue={formData.record.context.executionId}
                label="parentResourceId label"
                endAdornment={
                  formData.record.context.executionId && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) => {
                          navigateExecutionStatusPath(this.props.history, formData.record.context.executionId);
                        }}
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="externalNumber" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'atk.header.external-number' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="externalNumber"
                defaultValue={formData.record.externalNumber}
                label="externalNumber label"
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box m={2}>
            <FormControl variant="outlined" key="Company Prefix" fullWidth>
              <InputLabel htmlFor="time" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'atk.header.time' })}
              </InputLabel>
              <OutlinedInput readOnly={true} id="time" defaultValue={formattedCreatedDate} label="time label" />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl variant="outlined" key="Company Prefix" fullWidth>
              <InputLabel htmlFor="lastStatus" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'atk.header.last-status' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="lastStatus"
                defaultValue={formData.record.lastStatus}
                label="lastStatus label"
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl variant="outlined" key="Company Prefix" fullWidth>
              <InputLabel htmlFor="numberOfSerials" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'atk.header.number-of-serials' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="numberOfSerials"
                defaultValue={formDataContent['serialNumbers'].length}
                label="numberOfSerials label"
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl variant="outlined" key="Company Prefix" fullWidth>
              <InputLabel htmlFor="shippingEvent" style={{ backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'atk.header.shipping-event' })}
              </InputLabel>
              <OutlinedInput
                readOnly={true}
                id="shippingEvent"
                defaultValue={formData.record.resource.parentResourceId}
                label="shippingEvent label"
                endAdornment={
                  formData.record.resource.parentResourceId && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) => {
                          navigateEpcisEventJsonPath(this.props.history, formData.record.resource.parentResourceId);
                        }}
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export const ATKSummaryCompView = injectIntl(ATKSummaryCompViewImpl);
