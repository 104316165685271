import { Button, Paper } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { tabsAssignmentSummaryView } from './tabs';
import { IEditState } from 'kvinta/common';
import { DevicesStore, DEVICES_STORE_ID } from './DevicesStore';
import { navigateDeviceAssignmentsPath, navigateDevicesPath, navigateDeviceSummaryPath } from 'kvinta/modules/paths';
import { AssignmentCompView } from './AssignmentCompView';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import MailIcon from '@material-ui/icons/Mail';

interface AssignmentSummaryViewProps {
  intl: any;
  devicesStore?: DevicesStore;
  sidebarStore?: KSidebarStore;
  id: string;
  assignId: string;
  history?: any;
  currentAssignment?: any;
}

class AssignmentSummaryViewImpl extends Component<AssignmentSummaryViewProps, IEditState> {
  constructor(props: AssignmentSummaryViewProps) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  async componentDidMount() {
    this.props.devicesStore.fetchAssignment(this.props.assignId);
  }

  handleEditOpen = () => {
    this.setState({
      isEditing: true,
    });
    this.props.devicesStore.openUpdateAssignmentForm();
  };

  handleEditSubmit = () => {
    this.props.devicesStore.submitUpdateAssignment();
    if (!this.props.devicesStore.updateAssignmentData.showError) {
      this.onEditClose();
    }
  };

  onEditClose = () => {
    this.setState({
      isEditing: false,
    });
    this.props.devicesStore.fetchAssignment(this.props.assignId);
  };

  render() {
    if (!this.props.devicesStore) {
      return null;
    }
    const formData = this.props.devicesStore.currentAssignment;
    if (!formData) {
      return null;
    }
    const intl = this.props.intl;
    const assignmentData = this.props.devicesStore.updateAssignmentData;
    const apps = this.props.devicesStore.apps;

    function unique(value, index, self) {
      return self.indexOf(value) === index;
    }

    const appNames = apps
      .map((app) => {
        return app.applicationName;
      })
      .filter(unique);

    const appVersions = apps
      .map((app) => {
        return app.applicationVersion;
      })
      .filter(unique);

    let buttonTitle;
    let buttonIcon;

    if (this.state.isEditing) {
      buttonTitle = 'button.confirm';
      buttonIcon = <DoneIcon color="secondary" />;
    } else {
      buttonTitle = 'button.edit';
      buttonIcon = <EditIcon color="secondary" />;
    }

    return (
      <div>
        <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          <TabBar
            onChange={(newValue: any) => {
              if (newValue === '0') {
                navigateDevicesPath(this.props.history);
              }
              if (newValue === '1') {
                navigateDeviceSummaryPath(this.props.history, this.props.id);
              }
              if (newValue === '2') {
                navigateDeviceAssignmentsPath(this.props.history, this.props.id);
              }
            }}
            tabId={'3'}
            tabs={tabsAssignmentSummaryView}
          />
        </Paper>
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          <div style={{ marginRight: '15px', marginTop: '15px', float: 'right' }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<MailIcon color="secondary" />}
              onClick={(e) => {
                this.props.devicesStore.sendInvitation();
              }}
            >
              {intl.formatMessage({ id: 'button.assign-device' })}
            </Button>
            &nbsp;
            {this.state.isEditing ? (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon color="primary" />}
                style={{ marginRight: '10px' }}
                onClick={(e) => {
                  this.onEditClose();
                }}
              >
                {intl.formatMessage({ id: 'button.cancel' })}
              </Button>
            ) : null}
            <Button
              variant="contained"
              color="secondary"
              startIcon={buttonIcon}
              onClick={(e) => {
                if (this.state.isEditing) {
                  this.handleEditSubmit();
                } else {
                  this.handleEditOpen();
                }
              }}
            >
              {intl.formatMessage({ id: buttonTitle })}
            </Button>
          </div>

          <AssignmentCompView
            assignment={formData}
            assignmentData={assignmentData}
            appNames={appNames}
            appVersions={appVersions}
            history={this.props.history}
            isEditing={this.state.isEditing}
          />
        </Paper>
      </div>
    );
  }
}

export const AssignmentSummaryView = injectIntl(
  inject(DEVICES_STORE_ID, SIDEBAR_STORE_ID)(observer(AssignmentSummaryViewImpl)),
);
