/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaOperationRequestIntroduceIntoCirculation,
    KvintaOperationRequestIntroduceIntoCirculationFromJSON,
    KvintaOperationRequestIntroduceIntoCirculationFromJSONTyped,
    KvintaOperationRequestIntroduceIntoCirculationToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaInlineObject
 */
export interface KvintaInlineObject {
    /**
     * 
     * @type {KvintaOperationRequestIntroduceIntoCirculation}
     * @memberof KvintaInlineObject
     */
    request?: KvintaOperationRequestIntroduceIntoCirculation;
}

export function KvintaInlineObjectFromJSON(json: any): KvintaInlineObject {
    return KvintaInlineObjectFromJSONTyped(json, false);
}

export function KvintaInlineObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaInlineObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request': !exists(json, 'request') ? undefined : KvintaOperationRequestIntroduceIntoCirculationFromJSON(json['request']),
    };
}

export function KvintaInlineObjectToJSON(value?: KvintaInlineObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request': KvintaOperationRequestIntroduceIntoCirculationToJSON(value.request),
    };
}


