import { MenuItem, Select, Link } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  KSidebarStore,
  PageContentStore,
  PAGE_CONTENT_STORE_ID,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { ListRowRecord, ShippingStore, SHIPPING_STORE_ID } from './ShippingStore';
import { FilterBar } from 'kvinta/components';
import { navigateShippingPath } from './paths';
import { linkShippingPath } from 'kvinta/modules/paths';

interface ViewShippingListProps {
  shippingStore?: ShippingStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
}

class ViewShippingListImpl extends Component<ViewShippingListProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ViewShippingListProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  async componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.shippingStore.fetchData();
    const locations = await this.props.shippingStore.fetchLocations();
    if (!this.props.shippingStore.filter) {
      this.props.shippingStore.setFilter([
        {
          field: 'locationSGln',
          label: 'shipping-list.filter.location',
          isActive: true,
          value: '',
          render: (props) => {
            return (
              <Select
                id={props.fieldId}
                style={{ height: '40px', minWidth: '200px' }}
                label={props.translatedLabel}
                value={props.value}
                onChange={(e) => {
                  props.store.onChangeValue(props.field, e.target.value as string);
                  this.props.shippingStore.fetchData();
                }}
              >
                <MenuItem value="">
                  <em>select a location</em>
                </MenuItem>
                {locations.map((item) => (
                  <MenuItem key={item.id} value={item.sgln}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            );
          },
        },
      ]);
    }
  }

  render() {
    const intl = this.props.intl;
    if (!this.props.shippingStore.filter) {
      return null;
    }
    const { shippingsData, isLoading, page, totalCount, pageSize, filter } = this.props.shippingStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <MaterialTable
                icons={tableIcons}
                tableRef={this.tableRef}
                // title={intl.formatMessage({ id: 'menu.events' })}
                columns={[
                  {
                    title: intl.formatMessage({ id: 'shipping-list.header.eventtime' }),
                    field: 'eventTime',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                    render: (data: ListRowRecord) => {
                      return (
                        <Link href={linkShippingPath(data.id)} onClick={preventDefault}>
                          {data.eventTime}
                        </Link>
                      );
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'shipping-list.header.recordtime' }),
                    field: 'recordTime',
                    defaultSort: 'desc',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'shipping-list.header.bizlocation' }),
                    field: 'bizLocation',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'shipping-list.header.biztransaction' }),
                    field: 'bizTransaction',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'shipping-list.header.aggregatedcustomsdocument' }),
                    field: 'aggregatedCustomsDocument',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                ]}
                data={shippingsData}
                page={page}
                totalCount={totalCount}
                onChangePage={(page: number) => {
                  this.props.shippingStore.fetchPage(page);
                }}
                onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                  this.props.shippingStore.changeOrder(orderBy, orderDirection);
                }}
                isLoading={isLoading}
                options={{
                  actionsColumnIndex: -1,
                  paging: true,
                  sorting: true,
                  draggable: false,
                  search: false,
                  pageSize: pageSize,
                  pageSizeOptions: [pageSize],
                  minBodyHeight: bodyHeight,
                  maxBodyHeight: bodyHeight,
                  emptyRowsWhenPaging: false,
                  thirdSortClick: false,
                  rowStyle: (rowData) => ({
                    backgroundColor: rowData.index == 0 ? '#EEE' : '#FFF',
                  }),
                  showTitle: false,
                }}
                onRowClick={(event, rowData) => {
                  navigateShippingPath(history, rowData.id);
                }}
                components={{
                  Toolbar: (props) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: '20px',
                        marginRight: '20px',
                      }}
                    >
                      <FilterBar filter={filter} />
                    </div>
                  ),
                }}
              />
            </div>
          </div>
        )}
      />
    );
  }
}
export const ViewShippingList = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, SHIPPING_STORE_ID, SIDEBAR_STORE_ID)(observer(ViewShippingListImpl)),
);
