import { Button, Paper } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import { PageContentStore, TabBar, tableIcons, PAGE_CONTENT_STORE_ID } from 'kvinta/components';
import {
  navigateSerialNumberOrderDocumentStatusesPath,
  navigateSerialNumberOrderSerialNumbersPath,
  navigateSerialNumberOrderPath,
  navigateSerialNumberOrderSerialNumberPath,
  navigateSerialNumberOrderItemsPath,
} from '../paths';
import { STORE_ID, SerialNumberOrdersStore } from '../SerialNumberOrdersStore';
import { tabsSummary } from './tabs';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CSVDownload } from 'react-csv';

interface serialNumberOrderSerialNumbersStoreViewProps {
  serialNumberOrdersStore?: SerialNumberOrdersStore;
  pageContentStore?: PageContentStore;
  intl: any;
  history?: any;
  id: string;
  serialNumberId: string;
}

class serialNumberOrderSerialNumbersStoreViewImpl extends Component<serialNumberOrderSerialNumbersStoreViewProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: serialNumberOrderSerialNumbersStoreViewProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.serialNumberOrdersStore.fetchSerialNumbersData(this.props.id);
  }

  navigateSerialNumberOrderSerialNumberSummary = (serialNumberId: string, id: string) => {
    navigateSerialNumberOrderSerialNumberPath(this.props.history, id, serialNumberId);
  };

  render() {
    if (!this.props.serialNumberOrdersStore) {
      return null;
    }

    const intl = this.props.intl;
    const { listData, isLoading, page, totalCount, pageSize, exportSNItemActive, exportSNItemData } =
      this.props.serialNumberOrdersStore;
    this.props.serialNumberOrdersStore.updateSNItemExported();

    if (!listData) {
      return null;
    }
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                <TabBar
                  onChange={(newValue: any) => {
                    if (newValue === '1') {
                      navigateSerialNumberOrderPath(this.props.history, this.props.id);
                    }
                    if (newValue === '2') {
                      navigateSerialNumberOrderItemsPath(this.props.history, this.props.id);
                    }
                    if (newValue === '3') {
                      navigateSerialNumberOrderSerialNumbersPath(this.props.history, this.props.id);
                    }
                    if (newValue === '4') {
                      navigateSerialNumberOrderDocumentStatusesPath(this.props.history, this.props.id);
                    }
                  }}
                  tabId={'3'}
                  tabs={tabsSummary}
                />
              </Paper>
              <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
                <MaterialTable
                  icons={tableIcons}
                  tableRef={this.tableRef}
                  // title={null}
                  columns={[
                    {
                      title: intl.formatMessage({ id: 'serial-numbers.header.serial' }),
                      field: 'raw',
                      defaultSort: 'desc',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '30%',
                      },
                    },
                    {
                      title: intl.formatMessage({ id: 'serial-numbers.header.issuer' }),
                      field: 'issuingSystemId',
                      defaultSort: 'desc',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '20%',
                      },
                    },
                    {
                      title: intl.formatMessage({ id: 'serial-numbers.header.issuerorder' }),
                      field: 'issuingSystemOrderId',
                      defaultSort: 'desc',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '30%',
                      },
                    },
                    // ???No expiration date
                    {
                      title: intl.formatMessage({ id: 'serial-numbers.header.expired-date' }),
                      field: 'expiredDate',
                      defaultSort: 'desc',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '20%',
                      },
                      render: (rowData) => {
                        if (rowData.expiredDate !== undefined) {
                          const date = new Date(rowData.expiredDate);

                          const formatedDate =
                            ('0' + date.getDate()).slice(-2) +
                            '.' +
                            ('0' + (date.getMonth() + 1)).slice(-2) +
                            '.' +
                            date.getFullYear() +
                            ' ' +
                            ('0' + date.getHours()).slice(-2) +
                            ':' +
                            ('0' + date.getMinutes()).slice(-2);
                          return <span>{formatedDate}</span>;
                        } else {
                          return <span>{null}</span>;
                        }
                      },
                    },
                  ]}
                  data={listData}
                  page={page}
                  totalCount={totalCount}
                  onChangePage={(page: number) => {
                    this.props.serialNumberOrdersStore.fetchSerialNumbersPage(this.props.id, page);
                  }}
                  onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                    this.props.serialNumberOrdersStore.changeOrder(orderBy, orderDirection);
                  }}
                  isLoading={isLoading}
                  options={{
                    actionsColumnIndex: 0,
                    paging: true,
                    sorting: true,
                    draggable: false,
                    search: false,
                    pageSize: pageSize,
                    pageSizeOptions: [pageSize],
                    minBodyHeight: bodyHeight,
                    maxBodyHeight: bodyHeight,
                    showTitle: false,
                    emptyRowsWhenPaging: false,
                    thirdSortClick: false,
                  }}
                  localization={{
                    header: {
                      actions: '',
                    },
                  }}
                  onRowClick={(event, rowData) => {
                    if (!(event.target instanceof HTMLInputElement)) {
                      // id = itemId --- orderId = id
                      this.navigateSerialNumberOrderSerialNumberSummary(rowData.id, rowData.orderId);
                    }
                  }}
                  components={{
                    Toolbar: (props) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginTop: '20px',
                          marginRight: '20px',
                        }}
                      >
                        <div style={{ minWidth: '300px' }}>
                          <MTableToolbar {...props} />
                        </div>
                        <div style={{ marginLeft: '5px' }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<GetAppIcon color="secondary" />}
                            onClick={(e) => {
                              this.props.serialNumberOrdersStore.exportSNItemAll(this.props.id);
                            }}
                          >
                            {intl.formatMessage({ id: 'button.export' })}
                          </Button>
                        </div>
                      </div>
                    ),
                  }}
                />
              </Paper>
            </div>
            {/* {serialNumberOrderItemFormOpen && <CreateSerialNumberOrderItemDialog />} */}
            {exportSNItemActive && exportSNItemData !== undefined && (
              <CSVDownload data={exportSNItemData} target="_blank" />
            )}
          </div>
        )}
      />
    );
  }
}
export const SerialNumberOrderSerialNumbersView = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, STORE_ID)(observer(serialNumberOrderSerialNumbersStoreViewImpl)),
);
