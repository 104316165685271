/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaRegulatorReportingSystem,
    KvintaRegulatorReportingSystemFromJSON,
    KvintaRegulatorReportingSystemFromJSONTyped,
    KvintaRegulatorReportingSystemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaCreateLocationRegulatorReportingSystemRequest
 */
export interface KvintaCreateLocationRegulatorReportingSystemRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateLocationRegulatorReportingSystemRequest
     */
    gln13: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateLocationRegulatorReportingSystemRequest
     */
    id?: string | null;
    /**
     * 
     * @type {KvintaRegulatorReportingSystem}
     * @memberof KvintaCreateLocationRegulatorReportingSystemRequest
     */
    reportingSystem: KvintaRegulatorReportingSystem;
}

export function KvintaCreateLocationRegulatorReportingSystemRequestFromJSON(json: any): KvintaCreateLocationRegulatorReportingSystemRequest {
    return KvintaCreateLocationRegulatorReportingSystemRequestFromJSONTyped(json, false);
}

export function KvintaCreateLocationRegulatorReportingSystemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateLocationRegulatorReportingSystemRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gln13': json['gln13'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'reportingSystem': KvintaRegulatorReportingSystemFromJSON(json['reportingSystem']),
    };
}

export function KvintaCreateLocationRegulatorReportingSystemRequestToJSON(value?: KvintaCreateLocationRegulatorReportingSystemRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gln13': value.gln13,
        'id': value.id,
        'reportingSystem': KvintaRegulatorReportingSystemToJSON(value.reportingSystem),
    };
}


