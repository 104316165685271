import { Menu, MenuItem, Paper } from '@material-ui/core';
import MaterialTable from 'material-table';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  KSidebarStore,
  PageContentStore,
  PAGE_CONTENT_STORE_ID,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { ProductsStore, STORE_ID } from '../ProductsStore';
import { Checkbox } from '@material-ui/core';
import { KvintaProduct } from 'kvinta/apis/kvinta-masterdata-service';
import { navigateProductPath } from '../paths';
import { CSVDownload } from 'react-csv';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ITableActionsState } from 'kvinta/common';
import { CreateProductDialog } from '../views/CreateProductDialog';

interface ProductsTableProps {
  productsStore?: ProductsStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
  pageContentStore?: PageContentStore;
}

class ProductsTableImpl extends Component<ProductsTableProps, ITableActionsState> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ProductsTableProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  componentDidMount() {
    this.props.productsStore.fetchData();
    this.props.sidebarStore.clearMainTitle();
  }

  navigateProductSummary = (id: string) => {
    navigateProductPath(this.props.history, id);
  };

  render() {
    const intl = this.props.intl;
    const { listData, isLoading, page, totalCount, pageSize, productFormOpen, checked, exportActive, exportData } =
      this.props.productsStore;
    this.props.productsStore.updateExported();
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;

    //sorry mobx. can't get a class component to update after a deeply nested prop has changed.
    const selected =
      listData &&
      listData.reduce((acc, row) => {
        acc[row.id] = row.isSelected;
        return acc;
      }, {});

    return (
      <Route
        render={({ history }) => (
          <div>
            <MaterialTable
              icons={tableIcons}
              tableRef={this.tableRef}
              title={intl.formatMessage({ id: 'menu.products' })}
              columns={[
                {
                  title: (
                    <Checkbox
                      id={'check-all'}
                      checked={checked > 0}
                      onChange={() => {
                        this.props.productsStore.toggleAll();
                      }}
                      checkedIcon={checked == 2 ? <CheckBoxIcon /> : <IndeterminateCheckBoxIcon />}
                    />
                  ),
                  field: 'isSelected',
                  sorting: false,
                  width: '10px',
                  render: (rowData) => (
                    <Checkbox
                      id={rowData.id}
                      checked={selected[rowData.id]}
                      onChange={() => {
                        this.props.productsStore.toggleRow(rowData.id);
                      }}
                    />
                  ),
                },
                {
                  title: intl.formatMessage({ id: 'product-form.field.name' }),
                  field: 'name',
                  defaultSort: 'desc',
                  cellStyle: {
                    whiteSpace: 'nowrap',
                    width: '30%',
                  },
                },
                {
                  title: intl.formatMessage({ id: 'product-form.field.description' }),
                  field: 'description',
                  cellStyle: {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    width: '45%',
                  },
                },
                {
                  title: intl.formatMessage({ id: 'product-form.field.updated' }),
                  field: 'updated',
                  cellStyle: {
                    whiteSpace: 'nowrap',
                    width: '15%',
                  },
                  render: (rowData) => {
                    const date = new Date(rowData.updated);
                    const formatedDate =
                      ('0' + date.getDate()).slice(-2) +
                      '.' +
                      ('0' + (date.getMonth() + 1)).slice(-2) +
                      '.' +
                      date.getFullYear() +
                      ' ' +
                      ('0' + date.getHours()).slice(-2) +
                      ':' +
                      ('0' + date.getMinutes()).slice(-2);
                    return <span>{formatedDate}</span>;
                  },
                },
                {
                  title: intl.formatMessage({ id: 'product-form.field.sku' }),
                  field: 'sku',
                  cellStyle: {
                    whiteSpace: 'nowrap',
                    width: '5%',
                  },
                },
                {
                  title: intl.formatMessage({ id: 'product-form.field.baseuom' }),
                  field: 'baseUom',
                  cellStyle: {
                    whiteSpace: 'nowrap',
                    width: '5%',
                  },
                },
              ]}
              data={listData}
              page={page}
              totalCount={totalCount}
              onChangePage={(page: number) => {
                this.props.productsStore.fetchPage(page);
              }}
              onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                this.props.productsStore.changeOrder(orderBy, orderDirection);
              }}
              isLoading={isLoading}
              options={{
                actionsColumnIndex: 0,
                paging: true,
                sorting: true,
                draggable: false,
                search: false,
                pageSize: pageSize,
                pageSizeOptions: [pageSize],
                minBodyHeight: bodyHeight,
                maxBodyHeight: bodyHeight,
                showTitle: false,
                emptyRowsWhenPaging: false,
                thirdSortClick: false,
              }}
              localization={{
                header: {
                  actions: '',
                },
              }}
              onRowClick={(event, rowData) => {
                if (!(event.target instanceof HTMLInputElement)) {
                  this.navigateProductSummary(rowData.id);
                }
              }}
              actions={[
                {
                  icon: MoreVertIcon,
                  tooltip: 'Actions',
                  isFreeAction: false,
                  onClick: (event, rowData) => {
                    this.handleActionsMenuOpen(event, rowData);
                  },
                },
              ]}
              //<MenuItem onClick={handleClose}>Profile</MenuItem>
              components={{
                Toolbar: (props) => <></>,
              }}
            />
            {productFormOpen && <CreateProductDialog />}
            {exportActive && exportData !== undefined && <CSVDownload data={exportData} target="_blank" />}
            <>
              <Menu
                id="row-action-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={this.state.isMenuOpen}
                onClose={this.onMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    const shouldDelete = confirm(
                      'Do you want to delete ' + (this.state.rowData as KvintaProduct).name + '?',
                    );
                    if (shouldDelete) {
                      this.props.productsStore.deleteProduct((this.state.rowData as KvintaProduct).id);
                    }
                    this.onMenuClose();
                  }}
                >
                  {intl.formatMessage({ id: 'button.delete' })}
                </MenuItem>
              </Menu>
            </>
          </div>
        )}
      />
    );
  }
}

export const ProductsTable = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(ProductsTableImpl)),
);
