/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCreateSerialNumberRangeRequest
 */
export interface KvintaCreateSerialNumberRangeRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberRangeRequest
     */
    max: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberRangeRequest
     */
    min: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberRangeRequest
     */
    profileId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaCreateSerialNumberRangeRequest
     */
    serialNumberLength?: number;
}

export function KvintaCreateSerialNumberRangeRequestFromJSON(json: any): KvintaCreateSerialNumberRangeRequest {
    return KvintaCreateSerialNumberRangeRequestFromJSONTyped(json, false);
}

export function KvintaCreateSerialNumberRangeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateSerialNumberRangeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'max': json['max'],
        'min': json['min'],
        'profileId': !exists(json, 'profileId') ? undefined : json['profileId'],
        'serialNumberLength': !exists(json, 'serialNumberLength') ? undefined : json['serialNumberLength'],
    };
}

export function KvintaCreateSerialNumberRangeRequestToJSON(value?: KvintaCreateSerialNumberRangeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'max': value.max,
        'min': value.min,
        'profileId': value.profileId,
        'serialNumberLength': value.serialNumberLength,
    };
}


