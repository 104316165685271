/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaMessageFieldsRequest,
    KvintaMessageFieldsRequestFromJSON,
    KvintaMessageFieldsRequestFromJSONTyped,
    KvintaMessageFieldsRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaUploadInelMessageRequest
 */
export interface KvintaUploadInelMessageRequest {
    /**
     * 
     * @type {KvintaMessageFieldsRequest}
     * @memberof KvintaUploadInelMessageRequest
     */
    messageFields?: KvintaMessageFieldsRequest;
    /**
     * 
     * @type {string}
     * @memberof KvintaUploadInelMessageRequest
     */
    encodedFile?: string;
}

export function KvintaUploadInelMessageRequestFromJSON(json: any): KvintaUploadInelMessageRequest {
    return KvintaUploadInelMessageRequestFromJSONTyped(json, false);
}

export function KvintaUploadInelMessageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaUploadInelMessageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageFields': !exists(json, 'messageFields') ? undefined : KvintaMessageFieldsRequestFromJSON(json['messageFields']),
        'encodedFile': !exists(json, 'encodedFile') ? undefined : json['encodedFile'],
    };
}

export function KvintaUploadInelMessageRequestToJSON(value?: KvintaUploadInelMessageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'messageFields': KvintaMessageFieldsRequestToJSON(value.messageFields),
        'encodedFile': value.encodedFile,
    };
}


