/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KvintaInelMessageBizStep } from 'kvinta/apis/kvinta-arla-extensions';
import { tzInts } from 'kvinta/common/timezones';
import { EpcisMessagesStore, errorRequired, EPCIS_MESSAGES_STORE_ID } from './EpcisMessagesStore';
import { KDialogTitle } from 'kvinta/components/KDialog';

interface DialogUploadCSVProps {
  epcisMessagesStore?: EpcisMessagesStore;
  intl: any;
}

const bizSteps = [
  { label: KvintaInelMessageBizStep.Commissioning, value: KvintaInelMessageBizStep.Commissioning },
  { label: KvintaInelMessageBizStep.Shipping, value: KvintaInelMessageBizStep.Shipping },
];

class DialogUploadCSVImpl extends Component<DialogUploadCSVProps> {
  render() {
    const isOpen = this.props.epcisMessagesStore.csvFormOpen;
    const onChangeValue = this.props.epcisMessagesStore.onChangeCSVField;
    const formData = this.props.epcisMessagesStore.csvUploadData;
    const locations = this.props.epcisMessagesStore.locations;
    const intl = this.props.intl;
    const showError = formData.showError;

    if (!isOpen || !locations) {
      return null;
    }
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isOpen}
        onClose={() => this.props.epcisMessagesStore.closeUploadCSVForm()}
      >
        <KDialogTitle id="customized-dialog-title" onClose={() => this.props.epcisMessagesStore.closeUploadCSVForm()}>
          {intl.formatMessage({ id: 'epc.upload.csv.title' })}
        </KDialogTitle>
        <DialogContent>
          <Container>
            <Box m={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="location"> {intl.formatMessage({ id: 'epc.upload.csv.location' })} </InputLabel>
                <Select
                  id="location"
                  value={formData.location}
                  error={showError && errorRequired('location', formData.location)}
                  required
                  onChange={(e) => {
                    onChangeValue('location', e.target.value);
                  }}
                  label="location label"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {locations.map((loc) => (
                    <MenuItem key={loc.gln13} value={loc.sgln}>
                      {loc.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box m={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="bizStep">{intl.formatMessage({ id: 'epc.upload.csv.bizstep' })}</InputLabel>
                <Select
                  id="bizStep"
                  value={formData.bizStep}
                  required
                  error={showError && errorRequired('bizStep', formData.bizStep)}
                  onChange={(e) => {
                    onChangeValue('bizStep', e.target.value);
                  }}
                  label="bizStep label"
                >
                  {bizSteps.map((bs) => (
                    <MenuItem key={bs.value} value={bs.value}>
                      {bs.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box m={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="timezone">{intl.formatMessage({ id: 'epc.upload.csv.timezone' })}</InputLabel>
                <Select
                  id="timezone"
                  value={formData.timezone}
                  error={showError && errorRequired('timezone', formData.timezone)}
                  onChange={(e) => {
                    onChangeValue('timezone', e.target.value);
                  }}
                  label="timezone label"
                >
                  {tzInts.map((tz) => (
                    <MenuItem key={tz.value} value={tz.value}>
                      {tz.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box m={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="timestamp">{intl.formatMessage({ id: 'epc.upload.csv.date' })}</InputLabel>
                <OutlinedInput
                  id="timestamp"
                  label="Timestamp"
                  type="datetime-local"
                  required
                  inputProps={{ step: 1 }}
                  value={formData.timestamp}
                  error={showError && errorRequired('timestamp', formData.timestamp)}
                  onChange={(e) => {
                    onChangeValue('timestamp', e.target.value);
                  }}
                  // defaultValue={new Date().toISOString()}
                />
              </FormControl>
            </Box>
            <Box m={2}>
              <FormControl variant="outlined" fullWidth>
                <input
                  accept="text/csv"
                  id="csv"
                  multiple
                  type="file"
                  style={{ display: 'none' }}
                  // error={showError && errorRequired('selectedFile', formData.selectedFile)}
                  onChange={(e) => onChangeValue('selectedFile', e.target.files[0])}
                />
                <label htmlFor="csv">
                  <Button variant="contained" color="primary" component="span">
                    {intl.formatMessage({ id: 'epc.upload.csv.file' })}
                    {formData.selectedFile ? ' - ' + formData.selectedFile.name : ''}
                  </Button>
                </label>
              </FormControl>
            </Box>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.epcisMessagesStore.closeUploadCSVForm()} color="primary" variant="outlined">
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <Button
            onClick={() => this.props.epcisMessagesStore.submitUploadCSVForm()}
            color="primary"
            variant="contained"
            // disabled={!formData.isValid}
            autoFocus
          >
            {intl.formatMessage({ id: 'epc.upload.csv.file' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const DialogUploadCSV = injectIntl(inject(EPCIS_MESSAGES_STORE_ID)(observer(DialogUploadCSVImpl)));
