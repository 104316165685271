export const ExecutionStatusesPath = '/executions';
export const ExecutionStatusPath = '/executions/:id';
export const ExecutionStatusHierarchyPath = '/executions/:id/hierarchy';

export const SequenceStatusesPath = '/sequence-statuses';
export const SequenceStatusPath = '/sequence-status/:repStatusId';
export const SequenceSummaryDependsPath = '/sequence-status/:repStatusId/depends';
export const SequenceSummaryPath = '/sequence-summary';

export const SerialNumbersInfoPath = '/serial-numbers-info';

export function navigateExecutionStatusesPath(history: any) {
  history.push(ExecutionStatusesPath);
}

export function navigateExecutionStatusPath(history: any, execId: string) {
  history.push(linkExecutionStatusPath(execId));
}

export function linkExecutionStatusPath(execId: string) {
  return ExecutionStatusPath.replaceAll(':id', execId);
}

export function navigateExecutionStatusHierarchyPath(history: any, execId: string) {
  history.push(ExecutionStatusHierarchyPath.replaceAll(':id', execId));
}

export function navigateSequenceStatusPath(history: any, repStatusId: number) {
  history.push(linkSequenceStatusPath(repStatusId));
}

export function linkSequenceStatusPath(repStatusId: number) {
  return SequenceStatusPath.replaceAll(':repStatusId', repStatusId.toString());
}

export function navigateSequenceStatusesPath(history: any) {
  history.push(SequenceStatusesPath);
}

export function navigateSequenceStatusDependsPath(history: any, repStatusId: number) {
  history.push(SequenceSummaryDependsPath.replaceAll(':repStatusId', repStatusId.toString()));
}

export function navigateSequenceSummaryPath(history: any) {
  history.push(SequenceSummaryPath);
}

export function navigateSerialNumbersInfoPath(history: any) {
  history.push(SerialNumbersInfoPath);
}
