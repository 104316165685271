import * as React from 'react';
import classNames from 'classnames';
import { TimelineStore } from './TimelineStore';

type TimelinePointId = string;

export type TTimelinePoint = { id: TimelinePointId; date: string };

export type TimelineProps = {
  data: TTimelinePoint[];
  activeTimelinePointId: TimelinePointId;
  updateActiveTimelinePoint: (id: string) => void;
};

export const Timeline: React.ComponentType<TimelineProps> = ({
  data,
  activeTimelinePointId,
  updateActiveTimelinePoint,
}: TimelineProps) => {
  if (!data) {
    return null;
  }
  return (
    <div className="timeline-wrapper">
      <div className="timeline-base">
        <div className="timeline">
          <div className="timeline-slider">
            <div className="timeline-line" />
            <div className="timeline-points">
              {data.map((timelinePoint, i) => {
                return (
                  <div className="timeline-point-wrapper" key={timelinePoint.id}>
                    <div
                      className={classNames('timeline-point', {
                        ['active']: activeTimelinePointId === timelinePoint.id,
                      })}
                      onClick={() => updateActiveTimelinePoint(timelinePoint.id)}
                    >
                      {i + 1}
                    </div>
                    <div className="timeline-point-label">{timelinePoint.date}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
