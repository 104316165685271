/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaSortDirection {
    Asc = 'ASC',
    Desc = 'DESC'
}

export function KvintaSortDirectionFromJSON(json: any): KvintaSortDirection {
    return KvintaSortDirectionFromJSONTyped(json, false);
}

export function KvintaSortDirectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSortDirection {
    return json as KvintaSortDirection;
}

export function KvintaSortDirectionToJSON(value?: KvintaSortDirection | null): any {
    return value as any;
}

