/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaRegulatorReportingSystem,
    KvintaRegulatorReportingSystemFromJSON,
    KvintaRegulatorReportingSystemFromJSONTyped,
    KvintaRegulatorReportingSystemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaLocationRegulatorReportingSystem
 */
export interface KvintaLocationRegulatorReportingSystem {
    /**
     * 
     * @type {string}
     * @memberof KvintaLocationRegulatorReportingSystem
     */
    gln13: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocationRegulatorReportingSystem
     */
    id: string;
    /**
     * 
     * @type {KvintaRegulatorReportingSystem}
     * @memberof KvintaLocationRegulatorReportingSystem
     */
    reportingSystem: KvintaRegulatorReportingSystem;
}

export function KvintaLocationRegulatorReportingSystemFromJSON(json: any): KvintaLocationRegulatorReportingSystem {
    return KvintaLocationRegulatorReportingSystemFromJSONTyped(json, false);
}

export function KvintaLocationRegulatorReportingSystemFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaLocationRegulatorReportingSystem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gln13': json['gln13'],
        'id': json['id'],
        'reportingSystem': KvintaRegulatorReportingSystemFromJSON(json['reportingSystem']),
    };
}

export function KvintaLocationRegulatorReportingSystemToJSON(value?: KvintaLocationRegulatorReportingSystem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gln13': value.gln13,
        'id': value.id,
        'reportingSystem': KvintaRegulatorReportingSystemToJSON(value.reportingSystem),
    };
}


