import { Link, Paper } from '@material-ui/core';
import { PageContentStore, tableIcons, PAGE_CONTENT_STORE_ID, TabBar } from 'kvinta/components';
import { tabsSummary } from './tabs';
import {
  navigateSequenceStatusesPath,
  navigateSequenceStatusPath,
  navigateEpcMessagePath,
  linkEpcMessagePath,
  linkEpcisEventPath,
} from 'kvinta/modules/paths';
import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import { SequenceStatusStore, SEQUENCE_STATUS_STORE_ID, statusesList } from './SequenceStatusStore';
import { formatRFC3339 } from 'date-fns';
import { KvintaSequenceStatusDependencyRecord } from 'kvinta/apis/kvinta-arla-extensions';

interface SequenceStatusSummaryDependsViewProps {
  sequenceStatusStore?: SequenceStatusStore;
  pageContentStore?: PageContentStore;
  intl: any;
  history?: any;
  repStatusId: number;
}

class SequenceStatusSummaryDependsViewImpl extends Component<SequenceStatusSummaryDependsViewProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: SequenceStatusSummaryDependsViewProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sequenceStatusStore.fetchSequenceStatus(this.props.repStatusId);
  }

  navigateEpcMessage(epc: string) {
    navigateEpcMessagePath(this.props.history, epc, 0);
  }
  navigateSequenceStatusFiltered = (history: any, epc: string) => {
    this.props.sequenceStatusStore.updateFilterEpc(epc);
    navigateSequenceStatusesPath(history);
  };

  navigateEpcEventFiltered = (history: any, epc: string, eventId: string) => {
    this.props.sequenceStatusStore.updateFilterEpcEvent(epc, eventId);
    navigateSequenceStatusesPath(history);
  };

  render() {
    const intl = this.props.intl;
    const { currentSequenceStatus, isLoading, extendedDependencies } = this.props.sequenceStatusStore;
    if (!currentSequenceStatus || !extendedDependencies) {
      return null;
    }
    const depEpcs = currentSequenceStatus.dependantEPCObjects;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
    console.log(
      'Dependencies',
      depEpcs.map((value) => value.epc),
    );
    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                <TabBar
                  onChange={(newValue: any) => {
                    if (newValue === '0') {
                      navigateSequenceStatusesPath(this.props.history);
                    }
                    if (newValue === '1') {
                      navigateSequenceStatusPath(this.props.history, currentSequenceStatus.repStatusId as any);
                    }
                  }}
                  tabId={'2'}
                  tabs={tabsSummary}
                />
              </Paper>
              <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
                <MaterialTable
                  icons={tableIcons}
                  tableRef={this.tableRef}
                  // title={null}
                  columns={[
                    {
                      title: intl.formatMessage({ id: 'sequence-status-list.epc' }),
                      field: 'epc',
                      render: (data: KvintaSequenceStatusDependencyRecord) => {
                        return <Link href={linkEpcMessagePath(history, data.epc, 0)}>{data.epc}</Link>;
                      },
                    },
                    {
                      title: intl.formatMessage({ id: 'sequence-status-list.eventId' }),
                      field: 'eventId',
                      render: (data: KvintaSequenceStatusDependencyRecord) => {
                        if (data.eventId) {
                          return <Link href={linkEpcisEventPath(history, data.eventId)}>{data.eventId}</Link>;
                        } else {
                          return <span>&nbsp;</span>;
                        }
                      },
                    },
                    {
                      title: intl.formatMessage({ id: 'sequence-status-list.eventTime' }),
                      field: 'eventTime',
                      render: (rowData) => {
                        if (rowData.eventTime) {
                          return <span>{formatRFC3339(rowData.eventTime)}</span>;
                        } else {
                          return <span>&nbsp;</span>;
                        }
                      },
                    },
                    {
                      title: intl.formatMessage({ id: 'sequence-status-list.status' }),
                      field: 'status',
                      render: (rowData) => {
                        let newStatus;
                        statusesList.map((value, index) => {
                          if (index == (rowData.status as any)) {
                            newStatus = value;
                          }
                        });
                        return <span>{newStatus}</span>;
                      },
                    },
                    {
                      title: intl.formatMessage({ id: 'sequence-status-list.bizStep' }),
                      field: 'bizStep',
                    },
                  ]}
                  data={extendedDependencies}
                  isLoading={isLoading}
                  options={{
                    actionsColumnIndex: 0,
                    paging: false,
                    sorting: true,
                    draggable: false,
                    search: false,
                    minBodyHeight: bodyHeight,
                    maxBodyHeight: bodyHeight,
                    showTitle: false,
                    thirdSortClick: false,
                    emptyRowsWhenPaging: false,
                    rowStyle: (rowData) => ({
                      color: rowData.status && rowData.status == 1 ? '#03C3A0' : '#333381',
                    }),
                  }}
                  localization={{
                    header: {
                      actions: '',
                    },
                  }}
                  onRowClick={(event, rowData) => {
                    if (!(event.target instanceof HTMLLinkElement)) {
                      if (rowData.epc && rowData.eventId) {
                        this.navigateEpcEventFiltered(history, rowData.epc, rowData.eventId);
                      }
                    }
                  }}
                  components={{
                    Toolbar: () => null,
                  }}
                />
              </Paper>
            </div>
          </div>
        )}
      />
    );
  }
}
export const SequenceStatusSummaryDependsView = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, SEQUENCE_STATUS_STORE_ID)(observer(SequenceStatusSummaryDependsViewImpl)),
);
