/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaLgtinProduct
 */
export interface KvintaLgtinProduct {
    /**
     * 
     * @type {string}
     * @memberof KvintaLgtinProduct
     */
    gtin?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaLgtinProduct
     */
    prodBatch?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaLgtinProduct
     */
    vsdNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaLgtinProduct
     */
    productionDate?: number;
}

export function KvintaLgtinProductFromJSON(json: any): KvintaLgtinProduct {
    return KvintaLgtinProductFromJSONTyped(json, false);
}

export function KvintaLgtinProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaLgtinProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gtin': !exists(json, 'gtin') ? undefined : json['gtin'],
        'prodBatch': !exists(json, 'prodBatch') ? undefined : json['prodBatch'],
        'vsdNumber': !exists(json, 'vsdNumber') ? undefined : json['vsdNumber'],
        'productionDate': !exists(json, 'productionDate') ? undefined : json['productionDate'],
    };
}

export function KvintaLgtinProductToJSON(value?: KvintaLgtinProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gtin': value.gtin,
        'prodBatch': value.prodBatch,
        'vsdNumber': value.vsdNumber,
        'productionDate': value.productionDate,
    };
}


