import React, { Component } from 'react';
import { ShippingStore, SHIPPING_STORE_ID } from './ShippingStore';
import {
  KSidebarStore,
  PageContentStore,
  PAGE_CONTENT_STORE_ID,
  SIDEBAR_STORE_ID,
  TabBar,
  tableIcons,
} from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { Paper } from '@material-ui/core';
import { tabs } from './tabs';
import { navigateShippingListPath, navigateShippingPath, navigateShippingProductsPath } from './paths';
import { reaction } from 'mobx';
import MaterialTable from 'material-table';

interface ViewShippingResultsProps {
  intl: any;
  history?: any;
  sidebarStore?: KSidebarStore;
  pageContentStore?: PageContentStore;
  shippingStore: ShippingStore;
  id: string;
}

class ViewShippingResultsImpl extends Component<ViewShippingResultsProps> {
  disposer: any;
  tableRef: React.RefObject<unknown>;

  constructor(props: ViewShippingResultsProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.shippingStore.currentVerificationResult,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(this.props.id, () => {
            navigateShippingListPath(this.props.history);
          });
        }
      },
    );
    this.props.shippingStore.loadVerificationResults(this.props.id);
  }
  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const intl = this.props.intl;
    const { currentVerificationResult, isLoading, page, totalCount, pageSize } = this.props.shippingStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightThin;
    if (!currentVerificationResult) {
      return null;
    }
    let listData = [];
    if (currentVerificationResult.data[0].valid !== true) {
      listData = currentVerificationResult.data;
    }
    return (
      <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              navigateShippingListPath(this.props.history);
            }
            if (newValue === '1') {
              navigateShippingPath(this.props.history, this.props.id);
            }
            if (newValue === '2') {
              navigateShippingProductsPath(this.props.history, this.props.id);
            }
          }}
          tabId={'3'}
          tabs={tabs}
        />
        <div>
          <MaterialTable
            icons={tableIcons}
            tableRef={this.tableRef}
            columns={[
              {
                title: intl.formatMessage({ id: 'shipping-list.results.epc' }),
                field: 'epc',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'shipping-list.results.status' }),
                field: 'status',
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontWeight: 'bold',
                },
                render: (rowData) => {
                  return rowData.error === undefined ? (
                    <span style={{ color: '#00C3A0' }}>{'OK'}</span>
                  ) : (
                    <span style={{ color: '#CD0843' }}>{'Error'}</span>
                  );
                },
              },
              {
                title: intl.formatMessage({ id: 'shipping-list.results.error' }),
                field: 'error',
                width: '50%',
                // cellStyle: {
                //   whiteSpace: 'nowrap',
                // },
              },
              {
                title: intl.formatMessage({ id: 'shipping-list.results.parent' }),
                field: 'parent',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
            ]}
            data={listData}
            page={page}
            totalCount={totalCount}
            onChangePage={(page: number) => {
              this.props.shippingStore.fetchPage(page);
            }}
            onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
              this.props.shippingStore.changeOrder(orderBy, orderDirection);
            }}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: -1,
              paging: false,
              sorting: true,
              draggable: false,
              search: false,
              // pageSize: pageSize,
              // pageSizeOptions: [pageSize],
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
              rowStyle: (rowData) => ({
                backgroundColor: rowData.index === 0 ? '#EEE' : '#FFF',
              }),
              showTitle: false,
            }}
            localization={{
              header: {
                actions: '',
              },
              body: {
                emptyDataSourceMessage: (
                  <span style={{ color: '#00C3A0', fontWeight: 'bold' }}>
                    {intl.formatMessage({ id: 'shipping-list.results.valid' })}
                  </span>
                ),
              },
            }}
            // onRowClick={(event, rowData) => {
            //   this.navigateShippingResults(history, rowData.eventID);
            // }}
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                    marginRight: '20px',
                  }}
                ></div>
              ),
            }}
          />
        </div>
      </Paper>
    );
  }
}
export const ViewShippingResults = injectIntl(
  inject(SHIPPING_STORE_ID, SIDEBAR_STORE_ID, PAGE_CONTENT_STORE_ID)(observer(ViewShippingResultsImpl)),
);
