export const NotificationGroupListPath = '/notification-group-list';
export const NotificationRecipientsPath = '/notification-group/:id/recipients';
export const NotificationTypesPath = '/notification-group/:id/notification-types';

export const navigateToNotificationGroupList = (history: any) => {
  history.push(NotificationGroupListPath);
};

export const navigateToNotificationGroupRecipients = (history: any, id: string) => {
  history.push(NotificationRecipientsPath.replaceAll(':id', id));
};
export const navigateToNotificationGroupNotificationTypes = (history: any, id: string) => {
  history.push(NotificationTypesPath.replaceAll(':id', id));
};
