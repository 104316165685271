/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Capture Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaResource,
    KvintaResourceFromJSON,
    KvintaResourceFromJSONTyped,
    KvintaResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaResourceChanges
 */
export interface KvintaResourceChanges {
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    createQueued?: Array<KvintaResource>;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    createOk?: Array<KvintaResource>;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    createError?: Array<KvintaResource>;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    deleteQueued?: Array<KvintaResource>;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    deleteOk?: Array<KvintaResource>;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    deleteError?: Array<KvintaResource>;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    updateQueued?: Array<KvintaResource>;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    updateOk?: Array<KvintaResource>;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    updateError?: Array<KvintaResource>;
}

export function KvintaResourceChangesFromJSON(json: any): KvintaResourceChanges {
    return KvintaResourceChangesFromJSONTyped(json, false);
}

export function KvintaResourceChangesFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaResourceChanges {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createQueued': !exists(json, 'createQueued') ? undefined : ((json['createQueued'] as Array<any>).map(KvintaResourceFromJSON)),
        'createOk': !exists(json, 'createOk') ? undefined : ((json['createOk'] as Array<any>).map(KvintaResourceFromJSON)),
        'createError': !exists(json, 'createError') ? undefined : ((json['createError'] as Array<any>).map(KvintaResourceFromJSON)),
        'deleteQueued': !exists(json, 'deleteQueued') ? undefined : ((json['deleteQueued'] as Array<any>).map(KvintaResourceFromJSON)),
        'deleteOk': !exists(json, 'deleteOk') ? undefined : ((json['deleteOk'] as Array<any>).map(KvintaResourceFromJSON)),
        'deleteError': !exists(json, 'deleteError') ? undefined : ((json['deleteError'] as Array<any>).map(KvintaResourceFromJSON)),
        'updateQueued': !exists(json, 'updateQueued') ? undefined : ((json['updateQueued'] as Array<any>).map(KvintaResourceFromJSON)),
        'updateOk': !exists(json, 'updateOk') ? undefined : ((json['updateOk'] as Array<any>).map(KvintaResourceFromJSON)),
        'updateError': !exists(json, 'updateError') ? undefined : ((json['updateError'] as Array<any>).map(KvintaResourceFromJSON)),
    };
}

export function KvintaResourceChangesToJSON(value?: KvintaResourceChanges | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createQueued': value.createQueued === undefined ? undefined : ((value.createQueued as Array<any>).map(KvintaResourceToJSON)),
        'createOk': value.createOk === undefined ? undefined : ((value.createOk as Array<any>).map(KvintaResourceToJSON)),
        'createError': value.createError === undefined ? undefined : ((value.createError as Array<any>).map(KvintaResourceToJSON)),
        'deleteQueued': value.deleteQueued === undefined ? undefined : ((value.deleteQueued as Array<any>).map(KvintaResourceToJSON)),
        'deleteOk': value.deleteOk === undefined ? undefined : ((value.deleteOk as Array<any>).map(KvintaResourceToJSON)),
        'deleteError': value.deleteError === undefined ? undefined : ((value.deleteError as Array<any>).map(KvintaResourceToJSON)),
        'updateQueued': value.updateQueued === undefined ? undefined : ((value.updateQueued as Array<any>).map(KvintaResourceToJSON)),
        'updateOk': value.updateOk === undefined ? undefined : ((value.updateOk as Array<any>).map(KvintaResourceToJSON)),
        'updateError': value.updateError === undefined ? undefined : ((value.updateError as Array<any>).map(KvintaResourceToJSON)),
    };
}


