/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaNotificationType,
    KvintaNotificationTypeFromJSON,
    KvintaNotificationTypeFromJSONTyped,
    KvintaNotificationTypeToJSON,
    KvintaTemplateType,
    KvintaTemplateTypeFromJSON,
    KvintaTemplateTypeFromJSONTyped,
    KvintaTemplateTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaFindEmailTemplateRequest
 */
export interface KvintaFindEmailTemplateRequest {
    /**
     * 
     * @type {KvintaNotificationType}
     * @memberof KvintaFindEmailTemplateRequest
     */
    notificationType?: KvintaNotificationType;
    /**
     * 
     * @type {KvintaTemplateType}
     * @memberof KvintaFindEmailTemplateRequest
     */
    templateType?: KvintaTemplateType;
}

export function KvintaFindEmailTemplateRequestFromJSON(json: any): KvintaFindEmailTemplateRequest {
    return KvintaFindEmailTemplateRequestFromJSONTyped(json, false);
}

export function KvintaFindEmailTemplateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaFindEmailTemplateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notificationType': !exists(json, 'notificationType') ? undefined : KvintaNotificationTypeFromJSON(json['notificationType']),
        'templateType': !exists(json, 'templateType') ? undefined : KvintaTemplateTypeFromJSON(json['templateType']),
    };
}

export function KvintaFindEmailTemplateRequestToJSON(value?: KvintaFindEmailTemplateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notificationType': KvintaNotificationTypeToJSON(value.notificationType),
        'templateType': KvintaTemplateTypeToJSON(value.templateType),
    };
}


