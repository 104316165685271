/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionWithStatuses,
    KvintaExecutionWithStatusesFromJSON,
    KvintaExecutionWithStatusesFromJSONTyped,
    KvintaExecutionWithStatusesToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaExecutionTree
 */
export interface KvintaExecutionTree {
    /**
     * 
     * @type {Array<KvintaExecutionTree>}
     * @memberof KvintaExecutionTree
     */
    followUps?: Array<KvintaExecutionTree> | null;
    /**
     * 
     * @type {KvintaExecutionWithStatuses}
     * @memberof KvintaExecutionTree
     */
    rootExecution: KvintaExecutionWithStatuses;
}

export function KvintaExecutionTreeFromJSON(json: any): KvintaExecutionTree {
    return KvintaExecutionTreeFromJSONTyped(json, false);
}

export function KvintaExecutionTreeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaExecutionTree {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'followUps': !exists(json, 'followUps') ? undefined : (json['followUps'] === null ? null : (json['followUps'] as Array<any>).map(KvintaExecutionTreeFromJSON)),
        'rootExecution': KvintaExecutionWithStatusesFromJSON(json['rootExecution']),
    };
}

export function KvintaExecutionTreeToJSON(value?: KvintaExecutionTree | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'followUps': value.followUps === undefined ? undefined : (value.followUps === null ? null : (value.followUps as Array<any>).map(KvintaExecutionTreeToJSON)),
        'rootExecution': KvintaExecutionWithStatusesToJSON(value.rootExecution),
    };
}


