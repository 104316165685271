import {
  Paper,
  Grid,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import {
  navigateEpcisEventPath,
  navigateExecutionStatusPath,
  navigateVerificationResultsPath,
} from 'kvinta/modules/paths';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  VerificationStore,
  navigateVerificationListPath,
  navigateVerificationProductsPath,
  VERIFICATION_STORE_ID,
} from '.';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { tabs } from './tabs';

interface ViewVerificationSummaryProps {
  intl: any;
  history?: any;
  sidebarStore?: KSidebarStore;
  verificationStore: VerificationStore;
  id: string;
}

class ViewVerificationSummaryImpl extends Component<ViewVerificationSummaryProps> {
  disposer: any;

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.verificationStore.currentVerification,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.eventID, () => {
            navigateVerificationListPath(this.props.history);
          });
        }
      },
    );
    this.props.verificationStore.loadVerification(this.props.id);
  }
  componentWillUnmount() {
    if (this.disposer) {
      this.disposer();
    }
  }

  render() {
    const intl = this.props.intl;
    const currentVerification = this.props.verificationStore.currentVerification;
    if (!currentVerification) {
      return null;
    }
    return (
      <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              navigateVerificationListPath(this.props.history);
            }
            if (newValue === '2') {
              navigateVerificationProductsPath(this.props.history, this.props.id);
            }
            if (newValue === '3') {
              navigateVerificationResultsPath(this.props.history, this.props.id);
            }
          }}
          tabId={'1'}
          tabs={tabs}
        />
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          <Grid container>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="eventId">
                    {intl.formatMessage({ id: 'verification-list.summary.eventid' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="eventId"
                    defaultValue={currentVerification.eventID}
                    label="eventId label"
                    endAdornment={
                      currentVerification.eventID && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              navigateEpcisEventPath(this.props.history, currentVerification.eventID);
                            }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="createdByExecutionId">
                    {intl.formatMessage({ id: 'verification-list.summary.createdByExecutionId' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="createdByExecutionId"
                    defaultValue={currentVerification.createdByExecutionId}
                    label="createdByExecutionId label"
                    // endAdornment={
                    //   currentVerification.createdByExecutionId && (
                    //     <InputAdornment position="end">
                    //       <IconButton
                    //         onClick={(e) => {
                    //           navigateExecutionStatusPath(this.props.history, currentVerification.createdByExecutionId);
                    //         }}
                    //       >
                    //         <OpenInNewIcon />
                    //       </IconButton>
                    //     </InputAdornment>
                    //   )
                    // }
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="createdByRootExecutionId">
                    {intl.formatMessage({ id: 'verification-list.summary.createdByRootExecutionId' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="createdByRootExecutionId"
                    defaultValue={currentVerification.createdByRootExecutionId}
                    label="createdByRootExecutionId label"
                    endAdornment={
                      currentVerification.createdByExecutionId && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              navigateExecutionStatusPath(this.props.history, currentVerification.createdByExecutionId);
                            }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="documentId">
                    {intl.formatMessage({ id: 'verification-list.summary.documentId' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="documentId"
                    defaultValue={currentVerification.documentId}
                    label="documentId label"
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="action">
                    {intl.formatMessage({ id: 'verification-list.summary.action' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="action"
                    defaultValue={currentVerification.action}
                    label="action label"
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="disposition">
                    {intl.formatMessage({ id: 'verification-list.summary.disposition' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="disposition"
                    defaultValue={currentVerification.disposition}
                    label="disposition label"
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="eventTime">
                    {intl.formatMessage({ id: 'verification-list.summary.eventTime' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="eventTime"
                    defaultValue={this.props.verificationStore.formatDateTime(
                      currentVerification.eventTime.epochMillis,
                    )}
                    label="eventTime label"
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="eventTimeZoneOffset">
                    {intl.formatMessage({ id: 'verification-list.summary.eventTimeZoneOffset' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="eventTimeZoneOffset"
                    defaultValue={currentVerification.eventTimeZoneOffset}
                    label="eventTimeZoneOffset label"
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="epcCount">
                    {intl.formatMessage({ id: 'verification-list.summary.epcCount' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="epcCount"
                    defaultValue={currentVerification.epcCount}
                    label="epcCount label"
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="bizLocation">
                    {intl.formatMessage({ id: 'verification-list.summary.bizLocation' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="bizLocation"
                    defaultValue={currentVerification.bizLocation}
                    label="bizLocation label"
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="bizStep">
                    {intl.formatMessage({ id: 'verification-list.summary.bizStep' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="bizStep"
                    defaultValue={currentVerification.bizStep}
                    label="bizStep label"
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="bizTransaction">
                    {intl.formatMessage({ id: 'verification-list.summary.bizTransaction' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="bizTransaction"
                    defaultValue={currentVerification.bizTransaction}
                    label="bizTransaction label"
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="userId">
                    {intl.formatMessage({ id: 'verification-list.summary.userId' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="userId"
                    defaultValue={currentVerification.userId}
                    label="userId label"
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="tenantId">
                    {intl.formatMessage({ id: 'verification-list.summary.tenantId' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="tenantId"
                    defaultValue={currentVerification.tenantId}
                    label="tenantId label"
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="recordTime">
                    {intl.formatMessage({ id: 'verification-list.summary.recordTime' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="recordTime"
                    defaultValue={this.props.verificationStore.formatDateTime(currentVerification.recordTime)}
                    label="recordTime label"
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="sampleCode">
                    {intl.formatMessage({ id: 'verification-list.summary.sampleCode' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    id="sampleCode"
                    defaultValue={currentVerification.sampleCode}
                    label="sampleCode label"
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Paper>
    );
  }
}
export const ViewVerificationSummary = injectIntl(
  inject(VERIFICATION_STORE_ID, SIDEBAR_STORE_ID)(observer(ViewVerificationSummaryImpl)),
);
