/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaSerialNumberGenerationProfileCompanyPrefix
 */
export interface KvintaSerialNumberGenerationProfileCompanyPrefix {
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberGenerationProfileCompanyPrefix
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberGenerationProfileCompanyPrefix
     */
    profileId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberGenerationProfileCompanyPrefix
     */
    value: string;
}

export function KvintaSerialNumberGenerationProfileCompanyPrefixFromJSON(json: any): KvintaSerialNumberGenerationProfileCompanyPrefix {
    return KvintaSerialNumberGenerationProfileCompanyPrefixFromJSONTyped(json, false);
}

export function KvintaSerialNumberGenerationProfileCompanyPrefixFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSerialNumberGenerationProfileCompanyPrefix {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'profileId': json['profileId'],
        'value': json['value'],
    };
}

export function KvintaSerialNumberGenerationProfileCompanyPrefixToJSON(value?: KvintaSerialNumberGenerationProfileCompanyPrefix | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'profileId': value.profileId,
        'value': value.value,
    };
}


