/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaFindProductBySkuRequest
 */
export interface KvintaFindProductBySkuRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaFindProductBySkuRequest
     */
    sku: string;
}

export function KvintaFindProductBySkuRequestFromJSON(json: any): KvintaFindProductBySkuRequest {
    return KvintaFindProductBySkuRequestFromJSONTyped(json, false);
}

export function KvintaFindProductBySkuRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaFindProductBySkuRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sku': json['sku'],
    };
}

export function KvintaFindProductBySkuRequestToJSON(value?: KvintaFindProductBySkuRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sku': value.sku,
    };
}


