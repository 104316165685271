import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { InputLabel } from '@material-ui/core';
import { IFilter } from './FilterBar';

const styles = (theme) => ({
  outlined: {
    zIndex: 1,
    transform: 'translate(14px, 10px) scale(1)',
    pointerEvents: null,
  },
});

interface IFilterInputLabelProps {
  classes: any;
  htmlFor: string;
}

class FilterInputLabel extends React.Component<IFilterInputLabelProps> {
  render() {
    const { classes, children, htmlFor } = this.props;
    return (
      <InputLabel htmlFor={htmlFor} classes={{ outlined: classes.outlined }} style={{ whiteSpace: 'nowrap' }}>
        {children}
      </InputLabel>
    );
  }
}

export default withStyles(styles)(FilterInputLabel);
