/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaResourceStatusRecordFlat,
    KvintaResourceStatusRecordFlatFromJSON,
    KvintaResourceStatusRecordFlatFromJSONTyped,
    KvintaResourceStatusRecordFlatToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestListResourceStatusRecordFlat
 */
export interface KvintaOperationRequestListResourceStatusRecordFlat {
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestListResourceStatusRecordFlat
     */
    context?: KvintaExecutionContext;
    /**
     * 
     * @type {Array<KvintaResourceStatusRecordFlat>}
     * @memberof KvintaOperationRequestListResourceStatusRecordFlat
     */
    input?: Array<KvintaResourceStatusRecordFlat>;
}

export function KvintaOperationRequestListResourceStatusRecordFlatFromJSON(json: any): KvintaOperationRequestListResourceStatusRecordFlat {
    return KvintaOperationRequestListResourceStatusRecordFlatFromJSONTyped(json, false);
}

export function KvintaOperationRequestListResourceStatusRecordFlatFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestListResourceStatusRecordFlat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
        'input': !exists(json, 'input') ? undefined : ((json['input'] as Array<any>).map(KvintaResourceStatusRecordFlatFromJSON)),
    };
}

export function KvintaOperationRequestListResourceStatusRecordFlatToJSON(value?: KvintaOperationRequestListResourceStatusRecordFlat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': KvintaExecutionContextToJSON(value.context),
        'input': value.input === undefined ? undefined : ((value.input as Array<any>).map(KvintaResourceStatusRecordFlatToJSON)),
    };
}


