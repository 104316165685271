/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionStatusType,
    KvintaExecutionStatusTypeFromJSON,
    KvintaExecutionStatusTypeFromJSONTyped,
    KvintaExecutionStatusTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaExecutionStatusRecordsFilter
 */
export interface KvintaExecutionStatusRecordsFilter {
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatusRecordsFilter
     */
    _function?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatusRecordsFilter
     */
    functionLike?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatusRecordsFilter
     */
    rootExecutionId?: string | null;
    /**
     * 
     * @type {KvintaExecutionStatusType}
     * @memberof KvintaExecutionStatusRecordsFilter
     */
    status?: KvintaExecutionStatusType;
}

export function KvintaExecutionStatusRecordsFilterFromJSON(json: any): KvintaExecutionStatusRecordsFilter {
    return KvintaExecutionStatusRecordsFilterFromJSONTyped(json, false);
}

export function KvintaExecutionStatusRecordsFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaExecutionStatusRecordsFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_function': !exists(json, 'function') ? undefined : json['function'],
        'functionLike': !exists(json, 'functionLike') ? undefined : json['functionLike'],
        'rootExecutionId': !exists(json, 'rootExecutionId') ? undefined : json['rootExecutionId'],
        'status': !exists(json, 'status') ? undefined : KvintaExecutionStatusTypeFromJSON(json['status']),
    };
}

export function KvintaExecutionStatusRecordsFilterToJSON(value?: KvintaExecutionStatusRecordsFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'function': value._function,
        'functionLike': value.functionLike,
        'rootExecutionId': value.rootExecutionId,
        'status': KvintaExecutionStatusTypeToJSON(value.status),
    };
}


