/* eslint-disable @typescript-eslint/ban-types */
import { Button, Paper, Tab, Tabs } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { navigateTradeItemsPath } from '../paths';
import { TradeItemsStore, STORE_ID } from '../TradeItemsStore';
import EditIcon from '@material-ui/icons/Edit';
import { UpdateTradeItemDialog } from 'kvinta/modules/master-data/products/views/UpdateTradeItemDialog';
import { TradeItemCompView } from './TradeItemCompView';
import { KSidebarStore, SIDEBAR_STORE_ID } from 'kvinta/components';
import { reaction } from 'mobx';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { IEditState } from 'kvinta/common';

interface TradeItemSummaryViewProps {
  intl: any;
  tradeItemsStore?: TradeItemsStore;
  sidebarStore?: KSidebarStore;
  id: string;
  history?: any;
}

class TradeItemSummaryViewImpl extends Component<TradeItemSummaryViewProps, IEditState> {
  disposer: any;

  constructor(props: TradeItemSummaryViewProps) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.tradeItemsStore.fetchTradeItem(this.props.id);
    this.disposer = reaction(
      () => this.props.tradeItemsStore.currentTradeItem,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.gtin, () => {
            navigateTradeItemsPath(this.props.history);
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  handleEditOpen = () => {
    this.setState({
      isEditing: true,
    });
    this.props.tradeItemsStore.openUpdateTradeItem();
  };

  handleEditSubmit = () => {
    this.props.tradeItemsStore.submitUpdateTradeItem();
    if (!this.props.tradeItemsStore.updateTradeItemData.showError) {
      this.setState({
        isEditing: false,
      });
    }
  };

  onEditClose = () => {
    this.setState({
      isEditing: false,
    });
    const tradeId = this.props.id;
    this.props.tradeItemsStore.fetchTradeItem(tradeId);
  };

  render() {
    if (!this.props.tradeItemsStore) {
      return null;
    }
    const comp = this.props.tradeItemsStore.currentTradeItem;
    if (!comp) {
      return null;
    }
    const productData = this.props.tradeItemsStore.currentProduct;
    const tradeItemData = this.props.tradeItemsStore.updateTradeItemData;

    const intl = this.props.intl;
    const formData = comp;

    let buttonTitle;
    let buttonIcon;

    if (this.state.isEditing) {
      buttonTitle = 'button.confirm';
      buttonIcon = <DoneIcon color="secondary" />;
    } else {
      buttonTitle = 'button.edit';
      buttonIcon = <EditIcon color="secondary" />;
    }
    return (
      <div>
        <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          <Tabs
            value={'1'}
            indicatorColor="secondary"
            textColor="primary"
            onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
              if (newValue == 0) {
                navigateTradeItemsPath(this.props.history);
              }
            }}
            aria-label="simple tabs example"
          >
            <Tab label={intl.formatMessage({ id: 'common.summary' })} value={'1'} id={'1'} />
          </Tabs>
        </Paper>
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          <div style={{ marginRight: '15px', marginTop: '15px', float: 'right' }}>
            {this.state.isEditing ? (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon color="primary" />}
                style={{ marginRight: '10px' }}
                onClick={(e) => {
                  this.onEditClose();
                }}
              >
                {intl.formatMessage({ id: 'button.cancel' })}
              </Button>
            ) : null}
            <Button
              variant="contained"
              color="secondary"
              startIcon={buttonIcon}
              onClick={(e) => {
                if (this.state.isEditing) {
                  this.handleEditSubmit();
                } else {
                  this.handleEditOpen();
                }
              }}
            >
              {intl.formatMessage({ id: buttonTitle })}
            </Button>
          </div>
          <TradeItemCompView
            tradeItem={formData}
            productData={productData}
            history={history}
            isEditing={this.state.isEditing}
            tradeItemData={tradeItemData}
          />
        </Paper>
      </div>
    );
  }
}

export const TradeItemSummaryView = injectIntl(inject(STORE_ID, SIDEBAR_STORE_ID)(observer(TradeItemSummaryViewImpl)));
