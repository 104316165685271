/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCreateATKForShipmentEventRequest
 */
export interface KvintaCreateATKForShipmentEventRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateATKForShipmentEventRequest
     */
    eventId?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateATKForShipmentEventRequest
     */
    taxNumber?: string;
}

export function KvintaCreateATKForShipmentEventRequestFromJSON(json: any): KvintaCreateATKForShipmentEventRequest {
    return KvintaCreateATKForShipmentEventRequestFromJSONTyped(json, false);
}

export function KvintaCreateATKForShipmentEventRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateATKForShipmentEventRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'taxNumber': !exists(json, 'taxNumber') ? undefined : json['taxNumber'],
    };
}

export function KvintaCreateATKForShipmentEventRequestToJSON(value?: KvintaCreateATKForShipmentEventRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventId': value.eventId,
        'taxNumber': value.taxNumber,
    };
}


