import { Button, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import { KSidebarStore, PageContentStore, PAGE_CONTENT_STORE_ID, SIDEBAR_STORE_ID } from 'kvinta/components';
import { SerialNumbersInfoTable } from './component/SerialNumbersInfoTable';
import { DialogEnterSTINs } from './DialogEnterSTINs';
import { SerialNumberInfoStore, SERIAL_NUMBER_INFO_STORE_ID } from './SerialNumberInfoStore';

interface ListSerialNumbersInfoViewProps {
  serialNumberInfoStore?: SerialNumberInfoStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
}

class ListSerialNumbersInfoViewImpl extends Component<ListSerialNumbersInfoViewProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ListSerialNumbersInfoViewProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
  }

  render() {
    const intl = this.props.intl;
    const { stinsDialogFormOpen } = this.props.serialNumberInfoStore;

    return (
      <Route
        render={({ history }) => (
          <Paper square>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                paddingTop: '20px',
                paddingRight: '20px',
              }}
            >
              <div style={{ marginRight: '5px' }}>
                {this.props.serialNumberInfoStore.stinsDialogFormData ? (
                  this.props.serialNumberInfoStore.stinsDialogFormData.stins != '' ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<RefreshIcon color="secondary" />}
                      onClick={(e) => {
                        this.props.serialNumberInfoStore.fetchSTINsData();
                      }}
                    >
                      {intl.formatMessage({ id: 'button.refresh' })}
                    </Button>
                  ) : null
                ) : null}
              </div>
              <div style={{ marginRight: '5px' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<AddIcon color="secondary" />}
                  onClick={(e) => {
                    this.props.serialNumberInfoStore.openStinsDialogForm();
                  }}
                >
                  {intl.formatMessage({ id: 'button.get-info' })}
                </Button>
              </div>
            </div>
            <SerialNumbersInfoTable />
            {stinsDialogFormOpen && <DialogEnterSTINs />}
          </Paper>
        )}
      />
    );
  }
}

export const ListSerialNumbersInfoView = injectIntl(
  inject(SIDEBAR_STORE_ID, SERIAL_NUMBER_INFO_STORE_ID, PAGE_CONTENT_STORE_ID)(observer(ListSerialNumbersInfoViewImpl)),
);
