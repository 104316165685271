/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionStatusType,
    KvintaExecutionStatusTypeFromJSON,
    KvintaExecutionStatusTypeFromJSONTyped,
    KvintaExecutionStatusTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaExecutionsFilter
 */
export interface KvintaExecutionsFilter {
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionsFilter
     */
    _function?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionsFilter
     */
    functionLike?: string | null;
    /**
     * 
     * @type {KvintaExecutionStatusType}
     * @memberof KvintaExecutionsFilter
     */
    lastStatus?: KvintaExecutionStatusType;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionsFilter
     */
    service?: string | null;
}

export function KvintaExecutionsFilterFromJSON(json: any): KvintaExecutionsFilter {
    return KvintaExecutionsFilterFromJSONTyped(json, false);
}

export function KvintaExecutionsFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaExecutionsFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_function': !exists(json, 'function') ? undefined : json['function'],
        'functionLike': !exists(json, 'functionLike') ? undefined : json['functionLike'],
        'lastStatus': !exists(json, 'lastStatus') ? undefined : KvintaExecutionStatusTypeFromJSON(json['lastStatus']),
        'service': !exists(json, 'service') ? undefined : json['service'],
    };
}

export function KvintaExecutionsFilterToJSON(value?: KvintaExecutionsFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'function': value._function,
        'functionLike': value.functionLike,
        'lastStatus': KvintaExecutionStatusTypeToJSON(value.lastStatus),
        'service': value.service,
    };
}


