import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { KSidebarStore, PAGE_CONTENT_STORE_ID, PageContentStore, SIDEBAR_STORE_ID, tableIcons } from '../../components';
import { NOTIFICATION_STORE_ID, NotificationStore } from './NotificationStore';
import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Button, Menu, MenuItem, Paper, Tab, Tabs } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { navigateToNotificationGroupList, navigateToNotificationGroupNotificationTypes } from './paths';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MoreVertIcon from '@material-ui/icons/MoreVert';

type NotificationGroupRecipientsListProps = {
  notificationStore?: NotificationStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history: any;
  id: string;
};

type DeleteModalState = { isOpen: true; emailAddress: string } | { isOpen: false };

type EmailFormProps = { isOpen: true; value: string; submitButtonActive: boolean; error: boolean } | { isOpen: false };

type NotificationGroupRecipientsListState = {
  modal: DeleteModalState;
  newEmailForm: EmailFormProps;
  isMenuOpen: boolean;
  anchorEl: null | HTMLElement;
  rowData: any;
};

type NewEmailFormProps = {
  data: EmailFormProps;
  closeModal: () => void;
  handleSubmit: () => void;
  handleBlur: () => void;
  handleFocus: () => void;
  handleChange: (newValue: string) => void;
  intl: any;
};

type DeleteModalProps = {
  data: DeleteModalState;
  closeModal: () => void;
  handleDelete: () => void;
  intl: any;
};

const NewEmailForm: React.FunctionComponent<NewEmailFormProps> = ({
  closeModal,
  handleSubmit,
  handleChange,
  handleBlur,
  handleFocus,
  intl,
  data,
}) => {
  const isOpen = data.isOpen;
  const value = data.isOpen && data.value;
  const submitButtonActive = data.isOpen && data.submitButtonActive;
  const error = data.isOpen && data.error;

  return (
    <Dialog open={isOpen} onClose={closeModal} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
      <DialogTitle id="form-dialog-title">
        {intl.formatMessage({ id: 'notification-recipients-view.new-email-form.add-email' })}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="emailAddress"
          label="Email Address"
          fullWidth
          onChange={(e) => handleChange(e.target.value)}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
          error={error}
          variant="outlined"
        />
        {error && (
          <div style={{ width: '100%', textAlign: 'right', color: 'red', fontSize: '12px' }}>
            {intl.formatMessage({ id: 'notification-recipients-view.new-email-form.add-email-validation-error' })}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary" disabled={!submitButtonActive}>
          {intl.formatMessage({ id: 'button.submit' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteRequestModal: React.FunctionComponent<DeleteModalProps> = ({ data, handleDelete, closeModal, intl }) => {
  const isOpen = data.isOpen;
  const email = data.isOpen ? data.emailAddress : '';

  return (
    <Dialog open={isOpen} onClose={closeModal} fullWidth={true} maxWidth="sm">
      <DialogTitle id="alert-dialog-title">
        {intl.formatMessage({ id: 'notification-recipients-view.new-email-form.remove-email' }, { email })}
      </DialogTitle>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          {intl.formatMessage({ id: 'button.cancel' })}
        </Button>
        <Button onClick={handleDelete} color="primary" autoFocus>
          {intl.formatMessage({ id: 'button.delete' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

class NotificationGroupRecipientsListBase extends Component<
  NotificationGroupRecipientsListProps,
  NotificationGroupRecipientsListState
> {
  tableRef: React.RefObject<unknown>;

  constructor(props: NotificationGroupRecipientsListProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
      modal: {
        isOpen: false,
      },
      newEmailForm: {
        isOpen: false,
      },
    } as const;
  }

  componentDidMount() {
    this.props.notificationStore.getNotificationGroupRecipientsData(this.props.id);
    this.props.sidebarStore.clearMainTitle();
  }

  componentWillUnmount() {
    this.props.notificationStore.cleanupCurrentNotificationGroupRecipientsData();
  }

  handleDeleteRequest(emailAddress: string) {
    this.setState({
      modal: { isOpen: true, emailAddress },
    });
  }

  closeModal = () => {
    this.setState({
      modal: { isOpen: false },
    });
  };

  handleDelete = () => {
    this.setState({
      modal: { isOpen: false },
    });
    this.state.modal.isOpen && this.props.notificationStore.deleteEmail(this.props.id, this.state.modal.emailAddress);
  };

  openNewEmailForm() {
    this.setState({
      newEmailForm: {
        isOpen: true,
        submitButtonActive: false,
        value: '',
        error: false,
      },
    });
  }

  handleFormChange = (newValue: string) => {
    if (this.state.newEmailForm.isOpen) {
      const trimmedValue = newValue.trim();
      const valueIsValidEmail = this.isValidEmailAddress(trimmedValue);

      this.setState({
        newEmailForm: {
          ...this.state.newEmailForm,
          value: newValue,
          submitButtonActive: valueIsValidEmail,
        },
      });
    }
  };

  handleBlur = () => {
    if (this.state.newEmailForm.isOpen) {
      const trimmedValue = this.state.newEmailForm.value.trim();
      const valueIsValidEmail = this.isValidEmailAddress(trimmedValue);
      const error = !valueIsValidEmail && trimmedValue !== '';

      this.setState({
        newEmailForm: {
          ...this.state.newEmailForm,
          value: trimmedValue,
          submitButtonActive: valueIsValidEmail,
          error,
        },
      });
    }
  };

  handleFocus = () => {
    if (this.state.newEmailForm.isOpen) {
      this.setState({
        newEmailForm: {
          ...this.state.newEmailForm,
          error: false,
        },
      });
    }
  };

  isValidEmailAddress = (value: string) => {
    //a really simplistic email validation
    const regex = new RegExp('^\\S+@[0-9a-z.\\-_]+\\.[a-z]+$');

    return regex.test(value);
  };

  handleSubmit = () => {
    this.state.newEmailForm.isOpen &&
      this.props.notificationStore.addEmail(this.props.id, this.state.newEmailForm.value);
    this.setState({
      newEmailForm: { isOpen: false },
    });
  };

  closeNewEmailForm = () => {
    this.setState({
      newEmailForm: { isOpen: false },
    });
  };

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  render() {
    const intl = this.props.intl;
    const { currentGroupRecipients, isLoading } = this.props.notificationStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;

    return (
      <div style={{ position: 'relative' }}>
        <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          <div style={{ display: 'grid', gridTemplate: '1fr/1fr auto', paddingTop: '20px' }}>
            <Tabs
              value={'1'}
              indicatorColor="secondary"
              textColor="primary"
              onChange={(event: React.ChangeEvent<any>, newValue: number) => {
                if (newValue == 0) {
                  navigateToNotificationGroupList(this.props.history);
                } else if (newValue == 2) {
                  navigateToNotificationGroupNotificationTypes(this.props.history, this.props.id);
                }
              }}
              aria-label="simple tabs example"
            >
              <Tab label={intl.formatMessage({ id: 'notification-group-view.tab.recipients' })} value={'1'} id={'1'} />
              <Tab
                label={intl.formatMessage({ id: 'notification-group-view.tab.notification-types' })}
                value={'2'}
                id={'2'}
              />
            </Tabs>
            <div style={{ padding: '0 20px 10px' }}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon color="secondary" />}
                disabled={this.state && (this.state.newEmailForm.isOpen || this.state.modal.isOpen)}
                onClick={(e) => {
                  this.openNewEmailForm();
                }}
              >
                {intl.formatMessage({ id: 'button.create' })}
              </Button>
            </div>
          </div>
        </Paper>
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          <MaterialTable
            icons={tableIcons}
            tableRef={this.tableRef}
            columns={[
              {
                title: intl.formatMessage({ id: 'notification-group-view.field.email' }),
                field: 'emailAddress',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
            ]}
            actions={[
              {
                icon: MoreVertIcon,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, rowData) => {
                  this.handleActionsMenuOpen(event, rowData);
                },
              },
            ]}
            data={currentGroupRecipients}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: 0,
              paging: false,
              sorting: false,
              draggable: false,
              search: false,
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              header: false,
            }}
            // onRowClick={(event, rowData) => {
            //   this.navigateToNotificationGroupRecipients(rowData.id);
            // }}

            components={{
              Toolbar: (props) => null,
            }}
          />
          <Menu
            id="row-action-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={this.state.isMenuOpen}
            onClose={this.onMenuClose}
          >
            <MenuItem
              onClick={(event) => {
                this.handleDeleteRequest((this.state.rowData as { emailAddress: string }).emailAddress);
                this.onMenuClose();
              }}
            >
              {intl.formatMessage({ id: 'button.delete' })}
            </MenuItem>
          </Menu>
        </Paper>
        {this.state && (
          <DeleteRequestModal
            data={this.state.modal}
            handleDelete={this.handleDelete}
            closeModal={this.closeModal}
            intl={intl}
          />
        )}
        {this.state && this.state.newEmailForm && (
          <NewEmailForm
            data={this.state.newEmailForm}
            closeModal={this.closeNewEmailForm}
            handleSubmit={this.handleSubmit}
            handleChange={this.handleFormChange}
            handleBlur={this.handleBlur}
            handleFocus={this.handleFocus}
            intl={intl}
          />
        )}
      </div>
    );
  }
}

export const NotificationGroupRecipientsList = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, NOTIFICATION_STORE_ID, SIDEBAR_STORE_ID)(observer(NotificationGroupRecipientsListBase)),
);
