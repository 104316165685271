/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaOperationRequestCloseStaleResourcesRequest,
    KvintaOperationRequestCloseStaleResourcesRequestFromJSON,
    KvintaOperationRequestCloseStaleResourcesRequestToJSON,
    KvintaOperationRequestDetectStaleResourcesRequest,
    KvintaOperationRequestDetectStaleResourcesRequestFromJSON,
    KvintaOperationRequestDetectStaleResourcesRequestToJSON,
    KvintaOperationRequestFindLastResourceStatusRecordsRequest,
    KvintaOperationRequestFindLastResourceStatusRecordsRequestFromJSON,
    KvintaOperationRequestFindLastResourceStatusRecordsRequestToJSON,
    KvintaOperationRequestListExecutionStatusRecordFlat,
    KvintaOperationRequestListExecutionStatusRecordFlatFromJSON,
    KvintaOperationRequestListExecutionStatusRecordFlatToJSON,
    KvintaOperationRequestListExecutionStatusRecordsRequest,
    KvintaOperationRequestListExecutionStatusRecordsRequestFromJSON,
    KvintaOperationRequestListExecutionStatusRecordsRequestToJSON,
    KvintaOperationRequestListExecutionsRequest,
    KvintaOperationRequestListExecutionsRequestFromJSON,
    KvintaOperationRequestListExecutionsRequestToJSON,
    KvintaOperationRequestListResourceStatusRecordFlat,
    KvintaOperationRequestListResourceStatusRecordFlatFromJSON,
    KvintaOperationRequestListResourceStatusRecordFlatToJSON,
    KvintaOperationRequestListResourceStatusRecordsRequest,
    KvintaOperationRequestListResourceStatusRecordsRequestFromJSON,
    KvintaOperationRequestListResourceStatusRecordsRequestToJSON,
    KvintaOperationRequestListString,
    KvintaOperationRequestListStringFromJSON,
    KvintaOperationRequestListStringToJSON,
    KvintaOperationRequestRetryExecutionWithNewPayloadRequest,
    KvintaOperationRequestRetryExecutionWithNewPayloadRequestFromJSON,
    KvintaOperationRequestRetryExecutionWithNewPayloadRequestToJSON,
    KvintaOperationRequestScheduleExecutionRequest,
    KvintaOperationRequestScheduleExecutionRequestFromJSON,
    KvintaOperationRequestScheduleExecutionRequestToJSON,
    KvintaOperationRequestString,
    KvintaOperationRequestStringFromJSON,
    KvintaOperationRequestStringToJSON,
    KvintaOperationResponseExecutionTree,
    KvintaOperationResponseExecutionTreeFromJSON,
    KvintaOperationResponseExecutionTreeToJSON,
    KvintaOperationResponseExecutionWithStatuses,
    KvintaOperationResponseExecutionWithStatusesFromJSON,
    KvintaOperationResponseExecutionWithStatusesToJSON,
    KvintaOperationResponseGetListResultExecutionStatusRecord,
    KvintaOperationResponseGetListResultExecutionStatusRecordFromJSON,
    KvintaOperationResponseGetListResultExecutionStatusRecordToJSON,
    KvintaOperationResponseGetListResultExecutionTree,
    KvintaOperationResponseGetListResultExecutionTreeFromJSON,
    KvintaOperationResponseGetListResultExecutionTreeToJSON,
    KvintaOperationResponseGetListResultExecutionWithStatuses,
    KvintaOperationResponseGetListResultExecutionWithStatusesFromJSON,
    KvintaOperationResponseGetListResultExecutionWithStatusesToJSON,
    KvintaOperationResponseGetListResultResourceStatusRecord,
    KvintaOperationResponseGetListResultResourceStatusRecordFromJSON,
    KvintaOperationResponseGetListResultResourceStatusRecordToJSON,
    KvintaOperationResponseListResourceStatusRecord,
    KvintaOperationResponseListResourceStatusRecordFromJSON,
    KvintaOperationResponseListResourceStatusRecordToJSON,
    KvintaOperationResponseListTimestamp,
    KvintaOperationResponseListTimestampFromJSON,
    KvintaOperationResponseListTimestampToJSON,
    KvintaOperationResponseScheduleExecutionRequest,
    KvintaOperationResponseScheduleExecutionRequestFromJSON,
    KvintaOperationResponseScheduleExecutionRequestToJSON,
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingToJSON,
} from '../models';

export interface BatchSaveExecutionStatusRecordsRequest {
    kvintaOperationRequestListExecutionStatusRecordFlat: KvintaOperationRequestListExecutionStatusRecordFlat;
}

export interface BatchSaveResourceStatusRecordsRequest {
    kvintaOperationRequestListResourceStatusRecordFlat: KvintaOperationRequestListResourceStatusRecordFlat;
}

export interface CloseStaleResourcesHandlerRequest {
    kvintaOperationRequestCloseStaleResourcesRequest: KvintaOperationRequestCloseStaleResourcesRequest;
}

export interface DetectStaleResourcesRequest {
    kvintaOperationRequestDetectStaleResourcesRequest: KvintaOperationRequestDetectStaleResourcesRequest;
}

export interface FindLastResourceStatusRecordsRequest {
    kvintaOperationRequestFindLastResourceStatusRecordsRequest: KvintaOperationRequestFindLastResourceStatusRecordsRequest;
}

export interface FindResourceStatusRecordsRequest {
    kvintaOperationRequestListString: KvintaOperationRequestListString;
}

export interface ListExecutionHierarchiesRequest {
    kvintaPaging: KvintaPaging;
}

export interface ListExecutionStatusRecordsRequest {
    kvintaOperationRequestListExecutionStatusRecordsRequest: KvintaOperationRequestListExecutionStatusRecordsRequest;
}

export interface ListExecutionsRequest {
    kvintaOperationRequestListExecutionsRequest: KvintaOperationRequestListExecutionsRequest;
}

export interface ListResourceStatusRecordsRequest {
    kvintaOperationRequestListResourceStatusRecordsRequest: KvintaOperationRequestListResourceStatusRecordsRequest;
}

export interface ReadExecutionWithStatusesRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadHierarchyByRootExecutionIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface RetryExecutionRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface RetryExecutionPayloadRequest {
    kvintaOperationRequestRetryExecutionWithNewPayloadRequest: KvintaOperationRequestRetryExecutionWithNewPayloadRequest;
}

export interface ScheduleExecutionRequest {
    kvintaOperationRequestScheduleExecutionRequest: KvintaOperationRequestScheduleExecutionRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async batchSaveExecutionStatusRecordsRaw(requestParameters: BatchSaveExecutionStatusRecordsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListTimestamp>> {
        if (requestParameters.kvintaOperationRequestListExecutionStatusRecordFlat === null || requestParameters.kvintaOperationRequestListExecutionStatusRecordFlat === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListExecutionStatusRecordFlat','Required parameter requestParameters.kvintaOperationRequestListExecutionStatusRecordFlat was null or undefined when calling batchSaveExecutionStatusRecords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/batchSaveExecutionStatusRecords`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListExecutionStatusRecordFlatToJSON(requestParameters.kvintaOperationRequestListExecutionStatusRecordFlat),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListTimestampFromJSON(jsonValue));
    }

    /**
     */
    async batchSaveExecutionStatusRecords(requestParameters: BatchSaveExecutionStatusRecordsRequest): Promise<KvintaOperationResponseListTimestamp> {
        const response = await this.batchSaveExecutionStatusRecordsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async batchSaveResourceStatusRecordsRaw(requestParameters: BatchSaveResourceStatusRecordsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListTimestamp>> {
        if (requestParameters.kvintaOperationRequestListResourceStatusRecordFlat === null || requestParameters.kvintaOperationRequestListResourceStatusRecordFlat === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListResourceStatusRecordFlat','Required parameter requestParameters.kvintaOperationRequestListResourceStatusRecordFlat was null or undefined when calling batchSaveResourceStatusRecords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/batchSaveResourceStatusRecords`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListResourceStatusRecordFlatToJSON(requestParameters.kvintaOperationRequestListResourceStatusRecordFlat),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListTimestampFromJSON(jsonValue));
    }

    /**
     */
    async batchSaveResourceStatusRecords(requestParameters: BatchSaveResourceStatusRecordsRequest): Promise<KvintaOperationResponseListTimestamp> {
        const response = await this.batchSaveResourceStatusRecordsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Stale resources are resources, which are stuck for long with last status CREATE_QUEUED or RELEASE_QUEUED.  This API closes those resources by streaming error status records, e.g. CREATE_ERROR or RELEASE_ERROR.
     * Stale resources are resources, which are stuck for long with last status CREATE_QUEUED or RELEASE_QUEUED.  This API closes those resources by streaming error status records, e.g. CREATE_ERROR or RELEASE_ERROR.
     */
    async closeStaleResourcesHandlerRaw(requestParameters: CloseStaleResourcesHandlerRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListResourceStatusRecord>> {
        if (requestParameters.kvintaOperationRequestCloseStaleResourcesRequest === null || requestParameters.kvintaOperationRequestCloseStaleResourcesRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCloseStaleResourcesRequest','Required parameter requestParameters.kvintaOperationRequestCloseStaleResourcesRequest was null or undefined when calling closeStaleResourcesHandler.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/closeStaleResources`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCloseStaleResourcesRequestToJSON(requestParameters.kvintaOperationRequestCloseStaleResourcesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListResourceStatusRecordFromJSON(jsonValue));
    }

    /**
     * Stale resources are resources, which are stuck for long with last status CREATE_QUEUED or RELEASE_QUEUED.  This API closes those resources by streaming error status records, e.g. CREATE_ERROR or RELEASE_ERROR.
     * Stale resources are resources, which are stuck for long with last status CREATE_QUEUED or RELEASE_QUEUED.  This API closes those resources by streaming error status records, e.g. CREATE_ERROR or RELEASE_ERROR.
     */
    async closeStaleResourcesHandler(requestParameters: CloseStaleResourcesHandlerRequest): Promise<KvintaOperationResponseListResourceStatusRecord> {
        const response = await this.closeStaleResourcesHandlerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Detects stale resources, i.e. resources, which are for long in last status CREATE_QUEUED or RELEASE_QUEUED
     * Detects stale resources, i.e. resources, which are for long in last status CREATE_QUEUED or RELEASE_QUEUED
     */
    async detectStaleResourcesRaw(requestParameters: DetectStaleResourcesRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListResourceStatusRecord>> {
        if (requestParameters.kvintaOperationRequestDetectStaleResourcesRequest === null || requestParameters.kvintaOperationRequestDetectStaleResourcesRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestDetectStaleResourcesRequest','Required parameter requestParameters.kvintaOperationRequestDetectStaleResourcesRequest was null or undefined when calling detectStaleResources.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/detectStaleResources`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestDetectStaleResourcesRequestToJSON(requestParameters.kvintaOperationRequestDetectStaleResourcesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListResourceStatusRecordFromJSON(jsonValue));
    }

    /**
     * Detects stale resources, i.e. resources, which are for long in last status CREATE_QUEUED or RELEASE_QUEUED
     * Detects stale resources, i.e. resources, which are for long in last status CREATE_QUEUED or RELEASE_QUEUED
     */
    async detectStaleResources(requestParameters: DetectStaleResourcesRequest): Promise<KvintaOperationResponseListResourceStatusRecord> {
        const response = await this.detectStaleResourcesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the list of  execution, along with their last statuses
     * Returns the list of  execution, along with their last statuses
     */
    async findLastResourceStatusRecordsRaw(requestParameters: FindLastResourceStatusRecordsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListResourceStatusRecord>> {
        if (requestParameters.kvintaOperationRequestFindLastResourceStatusRecordsRequest === null || requestParameters.kvintaOperationRequestFindLastResourceStatusRecordsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestFindLastResourceStatusRecordsRequest','Required parameter requestParameters.kvintaOperationRequestFindLastResourceStatusRecordsRequest was null or undefined when calling findLastResourceStatusRecords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/findLastResourceStatusRecords`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestFindLastResourceStatusRecordsRequestToJSON(requestParameters.kvintaOperationRequestFindLastResourceStatusRecordsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListResourceStatusRecordFromJSON(jsonValue));
    }

    /**
     * Returns the list of  execution, along with their last statuses
     * Returns the list of  execution, along with their last statuses
     */
    async findLastResourceStatusRecords(requestParameters: FindLastResourceStatusRecordsRequest): Promise<KvintaOperationResponseListResourceStatusRecord> {
        const response = await this.findLastResourceStatusRecordsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the list of  execution, along with their last statuses
     * Returns the list of  execution, along with their last statuses
     */
    async findResourceStatusRecordsRaw(requestParameters: FindResourceStatusRecordsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListResourceStatusRecord>> {
        if (requestParameters.kvintaOperationRequestListString === null || requestParameters.kvintaOperationRequestListString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListString','Required parameter requestParameters.kvintaOperationRequestListString was null or undefined when calling findResourceStatusRecords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/findResourceStatusRecords`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListStringToJSON(requestParameters.kvintaOperationRequestListString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListResourceStatusRecordFromJSON(jsonValue));
    }

    /**
     * Returns the list of  execution, along with their last statuses
     * Returns the list of  execution, along with their last statuses
     */
    async findResourceStatusRecords(requestParameters: FindResourceStatusRecordsRequest): Promise<KvintaOperationResponseListResourceStatusRecord> {
        const response = await this.findResourceStatusRecordsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the list of executions, along with their statuses
     * Returns the list of executions, along with their statuses
     */
    async listExecutionHierarchiesRaw(requestParameters: ListExecutionHierarchiesRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultExecutionTree>> {
        if (requestParameters.kvintaPaging === null || requestParameters.kvintaPaging === undefined) {
            throw new runtime.RequiredError('kvintaPaging','Required parameter requestParameters.kvintaPaging was null or undefined when calling listExecutionHierarchies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listExecutionHierarchies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaPagingToJSON(requestParameters.kvintaPaging),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultExecutionTreeFromJSON(jsonValue));
    }

    /**
     * Returns the list of executions, along with their statuses
     * Returns the list of executions, along with their statuses
     */
    async listExecutionHierarchies(requestParameters: ListExecutionHierarchiesRequest): Promise<KvintaOperationResponseGetListResultExecutionTree> {
        const response = await this.listExecutionHierarchiesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the list of  execution, along with their last statuses
     * Returns the list of  execution, along with their last statuses
     */
    async listExecutionStatusRecordsRaw(requestParameters: ListExecutionStatusRecordsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultExecutionStatusRecord>> {
        if (requestParameters.kvintaOperationRequestListExecutionStatusRecordsRequest === null || requestParameters.kvintaOperationRequestListExecutionStatusRecordsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListExecutionStatusRecordsRequest','Required parameter requestParameters.kvintaOperationRequestListExecutionStatusRecordsRequest was null or undefined when calling listExecutionStatusRecords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/execution/status/listExecutionStatuses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListExecutionStatusRecordsRequestToJSON(requestParameters.kvintaOperationRequestListExecutionStatusRecordsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultExecutionStatusRecordFromJSON(jsonValue));
    }

    /**
     * Returns the list of  execution, along with their last statuses
     * Returns the list of  execution, along with their last statuses
     */
    async listExecutionStatusRecords(requestParameters: ListExecutionStatusRecordsRequest): Promise<KvintaOperationResponseGetListResultExecutionStatusRecord> {
        const response = await this.listExecutionStatusRecordsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the list of executions, along with their statuses
     * Returns the list of executions, along with their statuses
     */
    async listExecutionsRaw(requestParameters: ListExecutionsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultExecutionWithStatuses>> {
        if (requestParameters.kvintaOperationRequestListExecutionsRequest === null || requestParameters.kvintaOperationRequestListExecutionsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListExecutionsRequest','Required parameter requestParameters.kvintaOperationRequestListExecutionsRequest was null or undefined when calling listExecutions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listExecutions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListExecutionsRequestToJSON(requestParameters.kvintaOperationRequestListExecutionsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultExecutionWithStatusesFromJSON(jsonValue));
    }

    /**
     * Returns the list of executions, along with their statuses
     * Returns the list of executions, along with their statuses
     */
    async listExecutions(requestParameters: ListExecutionsRequest): Promise<KvintaOperationResponseGetListResultExecutionWithStatuses> {
        const response = await this.listExecutionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the list of  execution, along with their last statuses
     * Returns the list of  execution, along with their last statuses
     */
    async listResourceStatusRecordsRaw(requestParameters: ListResourceStatusRecordsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultResourceStatusRecord>> {
        if (requestParameters.kvintaOperationRequestListResourceStatusRecordsRequest === null || requestParameters.kvintaOperationRequestListResourceStatusRecordsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListResourceStatusRecordsRequest','Required parameter requestParameters.kvintaOperationRequestListResourceStatusRecordsRequest was null or undefined when calling listResourceStatusRecords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listResourceStatuses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListResourceStatusRecordsRequestToJSON(requestParameters.kvintaOperationRequestListResourceStatusRecordsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultResourceStatusRecordFromJSON(jsonValue));
    }

    /**
     * Returns the list of  execution, along with their last statuses
     * Returns the list of  execution, along with their last statuses
     */
    async listResourceStatusRecords(requestParameters: ListResourceStatusRecordsRequest): Promise<KvintaOperationResponseGetListResultResourceStatusRecord> {
        const response = await this.listResourceStatusRecordsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the execution, if found   If not found, returns OperationResponse(status = OK, data = null, message = \"Not found\")
     * Returns the execution, if found   If not found, returns OperationResponse(status = OK, data = null, message = \"Not found\")
     */
    async readExecutionWithStatusesRaw(requestParameters: ReadExecutionWithStatusesRequest): Promise<runtime.ApiResponse<KvintaOperationResponseExecutionWithStatuses>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readExecutionWithStatuses.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readExecution`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseExecutionWithStatusesFromJSON(jsonValue));
    }

    /**
     * Returns the execution, if found   If not found, returns OperationResponse(status = OK, data = null, message = \"Not found\")
     * Returns the execution, if found   If not found, returns OperationResponse(status = OK, data = null, message = \"Not found\")
     */
    async readExecutionWithStatuses(requestParameters: ReadExecutionWithStatusesRequest): Promise<KvintaOperationResponseExecutionWithStatuses> {
        const response = await this.readExecutionWithStatusesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the hierarchy by root execution id, if found   If not found, returns OperationResponse(status = OK, data = null, message = \"Not found\")
     * Returns the hierarchy by root execution id, if found   If not found, returns OperationResponse(status = OK, data = null, message = \"Not found\")
     */
    async readHierarchyByRootExecutionIdRaw(requestParameters: ReadHierarchyByRootExecutionIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseExecutionTree>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readHierarchyByRootExecutionId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readHierarchyByRootExecutionId`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseExecutionTreeFromJSON(jsonValue));
    }

    /**
     * Returns the hierarchy by root execution id, if found   If not found, returns OperationResponse(status = OK, data = null, message = \"Not found\")
     * Returns the hierarchy by root execution id, if found   If not found, returns OperationResponse(status = OK, data = null, message = \"Not found\")
     */
    async readHierarchyByRootExecutionId(requestParameters: ReadHierarchyByRootExecutionIdRequest): Promise<KvintaOperationResponseExecutionTree> {
        const response = await this.readHierarchyByRootExecutionIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async retryExecutionRaw(requestParameters: RetryExecutionRequest): Promise<runtime.ApiResponse<KvintaOperationResponseScheduleExecutionRequest>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling retryExecution.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/retryExecution`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseScheduleExecutionRequestFromJSON(jsonValue));
    }

    /**
     */
    async retryExecution(requestParameters: RetryExecutionRequest): Promise<KvintaOperationResponseScheduleExecutionRequest> {
        const response = await this.retryExecutionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async retryExecutionPayloadRaw(requestParameters: RetryExecutionPayloadRequest): Promise<runtime.ApiResponse<KvintaOperationResponseScheduleExecutionRequest>> {
        if (requestParameters.kvintaOperationRequestRetryExecutionWithNewPayloadRequest === null || requestParameters.kvintaOperationRequestRetryExecutionWithNewPayloadRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestRetryExecutionWithNewPayloadRequest','Required parameter requestParameters.kvintaOperationRequestRetryExecutionWithNewPayloadRequest was null or undefined when calling retryExecutionPayload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/retryExecutionPayload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestRetryExecutionWithNewPayloadRequestToJSON(requestParameters.kvintaOperationRequestRetryExecutionWithNewPayloadRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseScheduleExecutionRequestFromJSON(jsonValue));
    }

    /**
     */
    async retryExecutionPayload(requestParameters: RetryExecutionPayloadRequest): Promise<KvintaOperationResponseScheduleExecutionRequest> {
        const response = await this.retryExecutionPayloadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async scheduleExecutionRaw(requestParameters: ScheduleExecutionRequest): Promise<runtime.ApiResponse<KvintaOperationResponseScheduleExecutionRequest>> {
        if (requestParameters.kvintaOperationRequestScheduleExecutionRequest === null || requestParameters.kvintaOperationRequestScheduleExecutionRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestScheduleExecutionRequest','Required parameter requestParameters.kvintaOperationRequestScheduleExecutionRequest was null or undefined when calling scheduleExecution.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/scheduleExecution`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestScheduleExecutionRequestToJSON(requestParameters.kvintaOperationRequestScheduleExecutionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseScheduleExecutionRequestFromJSON(jsonValue));
    }

    /**
     */
    async scheduleExecution(requestParameters: ScheduleExecutionRequest): Promise<KvintaOperationResponseScheduleExecutionRequest> {
        const response = await this.scheduleExecutionRaw(requestParameters);
        return await response.value();
    }

}
