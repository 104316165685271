/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAggregatedCustomsDocumentStatusType,
    KvintaAggregatedCustomsDocumentStatusTypeFromJSON,
    KvintaAggregatedCustomsDocumentStatusTypeFromJSONTyped,
    KvintaAggregatedCustomsDocumentStatusTypeToJSON,
    KvintaTimestamp,
    KvintaTimestampFromJSON,
    KvintaTimestampFromJSONTyped,
    KvintaTimestampToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaAggregatedCustomsDocumentStatus
 */
export interface KvintaAggregatedCustomsDocumentStatus {
    /**
     * 
     * @type {string}
     * @memberof KvintaAggregatedCustomsDocumentStatus
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaAggregatedCustomsDocumentStatus
     */
    error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaAggregatedCustomsDocumentStatus
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaAggregatedCustomsDocumentStatus
     */
    message?: string | null;
    /**
     * 
     * @type {KvintaAggregatedCustomsDocumentStatusType}
     * @memberof KvintaAggregatedCustomsDocumentStatus
     */
    status: KvintaAggregatedCustomsDocumentStatusType;
    /**
     * 
     * @type {KvintaTimestamp}
     * @memberof KvintaAggregatedCustomsDocumentStatus
     */
    timestamp: KvintaTimestamp;
}

export function KvintaAggregatedCustomsDocumentStatusFromJSON(json: any): KvintaAggregatedCustomsDocumentStatus {
    return KvintaAggregatedCustomsDocumentStatusFromJSONTyped(json, false);
}

export function KvintaAggregatedCustomsDocumentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaAggregatedCustomsDocumentStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentId': json['documentId'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'id': json['id'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'status': KvintaAggregatedCustomsDocumentStatusTypeFromJSON(json['status']),
        'timestamp': KvintaTimestampFromJSON(json['timestamp']),
    };
}

export function KvintaAggregatedCustomsDocumentStatusToJSON(value?: KvintaAggregatedCustomsDocumentStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentId': value.documentId,
        'error': value.error,
        'id': value.id,
        'message': value.message,
        'status': KvintaAggregatedCustomsDocumentStatusTypeToJSON(value.status),
        'timestamp': KvintaTimestampToJSON(value.timestamp),
    };
}


