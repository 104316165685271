/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaEpcType {
    Sscc = 'SSCC',
    Sgtin = 'SGTIN'
}

export function KvintaEpcTypeFromJSON(json: any): KvintaEpcType {
    return KvintaEpcTypeFromJSONTyped(json, false);
}

export function KvintaEpcTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcType {
    return json as KvintaEpcType;
}

export function KvintaEpcTypeToJSON(value?: KvintaEpcType | null): any {
    return value as any;
}

