/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaOmsUsageType {
    UsedForProduction = 'USED_FOR_PRODUCTION',
    Verified = 'VERIFIED'
}

export function KvintaOmsUsageTypeFromJSON(json: any): KvintaOmsUsageType {
    return KvintaOmsUsageTypeFromJSONTyped(json, false);
}

export function KvintaOmsUsageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOmsUsageType {
    return json as KvintaOmsUsageType;
}

export function KvintaOmsUsageTypeToJSON(value?: KvintaOmsUsageType | null): any {
    return value as any;
}

