import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { tabs } from './tabs';
import React, { Component } from 'react';
import { ProcessesStatusStore, PROCESSES_STATUS_STORE_ID } from './ProcessesStatusStore';
import { PAGE_CONTENT_STORE_ID, ResizeEvent, TabBar } from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { Paper } from '@material-ui/core';
import { navigateExecutionStatusesPath, navigateExecutionStatusPath } from '../paths';
import { ExecutionHierarchyView } from './Hierarchy/ExecutionHierarchyView';
import { flattenHierarchy, resultToData, TTaskData } from './Hierarchy/utils';
import { tabBarHeight } from 'kvinta/common';

interface ViewProcessHierarchyProps {
  intl: any;
  history?: any;
  processesStatusStore: ProcessesStatusStore;
  id: string;
}
class ViewProcessHierarchyImpl extends Component<ViewProcessHierarchyProps> {
  constructor(props) {
    super(props);
  }

  onResize = (ev: any): void => {
    const e = ev as CustomEvent<ResizeEvent>;
    this.props.processesStatusStore.triggerResize(e.detail.width, e.detail.height);
  };
  componentDidMount() {
    document.addEventListener('page-resize', this.onResize);
    this.props.processesStatusStore.loadExecutionHierarchy(this.props.id);
  }

  componentWillUnmount() {
    document.removeEventListener('pare-resize', this.onResize);
  }

  render() {
    const { width, height } = this.props.processesStatusStore;
    const svgWidth = width || 400;
    const svgHeight = height ? height - tabBarHeight : 400;
    const currentHierarchyData = this.props.processesStatusStore.currentHierarchyData;
    const currentExecutionId = this.props.processesStatusStore.currentExecutionId;
    if (!currentHierarchyData) {
      return null;
    }

    // const intl = this.props.intl;

    //http://localhost:3000/executions/laughing-rubin-c491f759-2715-452a-9d69-4f77b50d3df4/hierarchy

    const hierarchyViewMargin = 20;
    const hierarchyData = resultToData(currentHierarchyData);
    const dimensions = {
      width: svgWidth - hierarchyViewMargin,
      height: svgHeight - 2 * hierarchyViewMargin,
    };
    const flatData = flattenHierarchy([hierarchyData] as TTaskData[], {});

    return (
      <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              navigateExecutionStatusesPath(this.props.history);
            }
            if (newValue === '1') {
              navigateExecutionStatusPath(this.props.history, this.props.id);
            }
          }}
          tabId={'2'}
          tabs={tabs}
        />
        <Paper square style={{ height: svgHeight, boxShadow: 'none' }}>
          <ExecutionHierarchyView
            hierarchyData={hierarchyData}
            flatData={flatData}
            height={svgHeight}
            width={svgWidth}
            margin={defaultMargin}
            processesStatusStore={this.props.processesStatusStore}
          />
        </Paper>
      </Paper>
    );
  }
}
export const ViewProcessHierarchy = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, PROCESSES_STATUS_STORE_ID)(observer(ViewProcessHierarchyImpl)),
);

export const defaultMargin = { top: 10, left: 100, right: 100, bottom: 10 };
