import { Box, Button, Dialog, DialogActions, DialogContent, FormControl, TextField } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KDialogTitle } from 'kvinta/components/KDialog';
import { SerialNumberInfoStore, SERIAL_NUMBER_INFO_STORE_ID } from './SerialNumberInfoStore';

interface DialogEnterSTINsProps {
  serialNumberInfoStore?: SerialNumberInfoStore;
  intl: any;
}
class DialogEnterSTINsImpl extends Component<DialogEnterSTINsProps> {
  render() {
    const isOpen = this.props.serialNumberInfoStore.stinsDialogFormOpen;
    const onChangeValue = this.props.serialNumberInfoStore.onChangeStinsDialogFormData;
    const formData = this.props.serialNumberInfoStore.stinsDialogFormData.stins;
    const intl = this.props.intl;

    if (!isOpen) {
      return null;
    }
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={isOpen}
        onClose={() => this.props.serialNumberInfoStore.closeStinsDialogForm()}
      >
        <KDialogTitle
          id="customized-dialog-title"
          onClose={() => this.props.serialNumberInfoStore.closeStinsDialogForm()}
        >
          {intl.formatMessage({ id: 'dialog.enter-stins.title' })}
        </KDialogTitle>
        <DialogContent>
          <Box m={2} maxHeight={'60vh'}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                id="stins"
                required
                multiline
                rowsMax={Infinity}
                rows={10} // Initialized with 10 rows
                variant="outlined"
                value={formData}
                onChange={(e) => {
                  onChangeValue(e.target.value);
                }}
                label={intl.formatMessage({ id: 'dialog.enter-stins.line' })}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.serialNumberInfoStore.closeStinsDialogForm()}
            color="primary"
            variant="outlined"
          >
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <Button
            onClick={() => this.props.serialNumberInfoStore.fetchSTINsData()}
            color="primary"
            variant="contained"
            autoFocus
          >
            {intl.formatMessage({ id: 'button.ok' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const DialogEnterSTINs = injectIntl(inject(SERIAL_NUMBER_INFO_STORE_ID)(observer(DialogEnterSTINsImpl)));
