import { Box, FormControl, Grid, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { LocationReportingData, LocationsStore, STORE_ID } from './LocationsStore';
import { KvintaRegulatorReportingSystem } from '../../../apis/kvinta-masterdata-service';
import { autorun } from 'mobx';

export interface LocationComponentProps {
  locationsStore?: LocationsStore;
  intl: any;
  history: any;
  id: string;
}

type LocationComponentState =
  | {
      NO_REPORTING: boolean;
      RU_OMS: boolean;
      RU_GISMT: boolean;
    }
  | undefined;

class ViewLocationReportingCompImpl extends Component<LocationComponentProps, LocationComponentState> {
  dispose = () => undefined;

  componentDidMount() {
    this.dispose = autorun(() => {
      const { reportingData } = this.props.locationsStore;
      if (this.state === null && reportingData !== null) {
        this.setState(
          Object.entries(this.props.locationsStore.reportingData).reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {}),
        );
      }
    });
    this.props.locationsStore.loadLocationReportingData(this.props.id);
  }

  componentWillUnmount() {
    this.dispose();
  }

  handleChange(id: string) {
    let newState = {};

    if (id === KvintaRegulatorReportingSystem.NoReporting) {
      newState = Object.keys(this.state).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
      newState[KvintaRegulatorReportingSystem.NoReporting] = !this.state[KvintaRegulatorReportingSystem.NoReporting];
    } else {
      newState[id] = !this.state[id];
      newState[KvintaRegulatorReportingSystem.NoReporting] = false;
    }
    this.setState(newState as LocationComponentState);
  }

  render() {
    if (!this.state) {
      return <span style={{ padding: '20px' }}>loading...</span>;
    }

    const intl = this.props.intl;
    return (
      <Route
        render={({ history }) => (
          <Grid container style={{ paddingLeft: '20px' }}>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <FormControlLabel
                    style={{ backgroundColor: 'white' }}
                    htmlFor="no-reporting"
                    control={
                      <Checkbox
                        id="no-reporting"
                        checked={this.state.NO_REPORTING}
                        onChange={() => this.handleChange('NO_REPORTING')}
                      />
                    }
                    label={intl.formatMessage({ id: 'location-reporting-view.field.no-reporting' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <FormControlLabel
                    style={{ backgroundColor: 'white' }}
                    htmlFor="ru-oms"
                    control={
                      <Checkbox id="ru-oms" checked={this.state.RU_OMS} onChange={() => this.handleChange('RU_OMS')} />
                    }
                    label={intl.formatMessage({ id: 'location-reporting-view.field.ru-oms' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <FormControlLabel
                    style={{ backgroundColor: 'white' }}
                    htmlFor="ru-gismt"
                    control={
                      <Checkbox
                        id="ru-gismt"
                        checked={this.state.RU_GISMT}
                        onChange={() => this.handleChange('RU_GISMT')}
                      />
                    }
                    label={intl.formatMessage({ id: 'location-reporting-view.field.ru-gismt' })}
                  />
                </FormControl>
              </Box>

              <Button
                onClick={() =>
                  this.props.locationsStore.submitLocationReportingForm(this.state as LocationReportingData)
                }
                color="primary"
                variant="contained"
                disabled={this.state === null}
                autoFocus
              >
                {intl.formatMessage({ id: 'button.submit' })}
              </Button>
            </Grid>
          </Grid>
        )}
      />
    );
  }
}

export const ViewLocationReportingComp = injectIntl(inject(STORE_ID)(observer(ViewLocationReportingCompImpl)));
