/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaLgtinProduct,
    KvintaLgtinProductFromJSON,
    KvintaLgtinProductFromJSONTyped,
    KvintaLgtinProductToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaCreateCirculationReportForEpcMetadataListFields
 */
export interface KvintaCreateCirculationReportForEpcMetadataListFields {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCirculationReportForEpcMetadataListFields
     */
    participantInn?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateCirculationReportForEpcMetadataListFields
     */
    gtd?: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaCreateCirculationReportForEpcMetadataListFields
     */
    gtdDate?: number;
    /**
     * 
     * @type {Array<KvintaLgtinProduct>}
     * @memberof KvintaCreateCirculationReportForEpcMetadataListFields
     */
    productsList?: Array<KvintaLgtinProduct>;
}

export function KvintaCreateCirculationReportForEpcMetadataListFieldsFromJSON(json: any): KvintaCreateCirculationReportForEpcMetadataListFields {
    return KvintaCreateCirculationReportForEpcMetadataListFieldsFromJSONTyped(json, false);
}

export function KvintaCreateCirculationReportForEpcMetadataListFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateCirculationReportForEpcMetadataListFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'participantInn': !exists(json, 'participantInn') ? undefined : json['participantInn'],
        'gtd': !exists(json, 'gtd') ? undefined : json['gtd'],
        'gtdDate': !exists(json, 'gtdDate') ? undefined : json['gtdDate'],
        'productsList': !exists(json, 'productsList') ? undefined : ((json['productsList'] as Array<any>).map(KvintaLgtinProductFromJSON)),
    };
}

export function KvintaCreateCirculationReportForEpcMetadataListFieldsToJSON(value?: KvintaCreateCirculationReportForEpcMetadataListFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'participantInn': value.participantInn,
        'gtd': value.gtd,
        'gtdDate': value.gtdDate,
        'productsList': value.productsList === undefined ? undefined : ((value.productsList as Array<any>).map(KvintaLgtinProductToJSON)),
    };
}


