/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaResource,
    KvintaResourceFromJSON,
    KvintaResourceFromJSONTyped,
    KvintaResourceToJSON,
    KvintaResourceStatus,
    KvintaResourceStatusFromJSON,
    KvintaResourceStatusFromJSONTyped,
    KvintaResourceStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaResourceStatusRecord
 */
export interface KvintaResourceStatusRecord {
    /**
     * 
     * @type {KvintaResource}
     * @memberof KvintaResourceStatusRecord
     */
    resource: KvintaResource;
    /**
     * 
     * @type {KvintaResourceStatus}
     * @memberof KvintaResourceStatusRecord
     */
    status: KvintaResourceStatus;
}

export function KvintaResourceStatusRecordFromJSON(json: any): KvintaResourceStatusRecord {
    return KvintaResourceStatusRecordFromJSONTyped(json, false);
}

export function KvintaResourceStatusRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaResourceStatusRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resource': KvintaResourceFromJSON(json['resource']),
        'status': KvintaResourceStatusFromJSON(json['status']),
    };
}

export function KvintaResourceStatusRecordToJSON(value?: KvintaResourceStatusRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resource': KvintaResourceToJSON(value.resource),
        'status': KvintaResourceStatusToJSON(value.status),
    };
}


