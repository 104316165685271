/* eslint-disable @typescript-eslint/ban-types */
import { Button, Paper, Tab, Tabs } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { navigateLocationMapPath, navigateLocationsPath, navigateReportingPath } from '../paths';
import { LocationsStore, STORE_ID } from './LocationsStore';
import { ViewLocationComp } from './ViewLocationComp';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { KSidebarStore, SIDEBAR_STORE_ID } from 'kvinta/components';
import { reaction } from 'mobx';
import { IEditState } from 'kvinta/common';

interface ViewLocationSummaryProps {
  intl: any;
  locationsStore?: LocationsStore;
  sidebarStore?: KSidebarStore;
  id: string;
  history?: any;
}

class ViewLocationSummaryImpl extends Component<ViewLocationSummaryProps, IEditState> {
  disposer: any;
  constructor(props: ViewLocationSummaryProps) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }
  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.locationsStore.fetchLocation(this.props.id);
    this.disposer = reaction(
      () => this.props.locationsStore.currentLocation,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            navigateLocationsPath(this.props.history);
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  handleEditOpen = () => {
    this.setState({
      isEditing: true,
    });
    this.props.locationsStore.openUpdateLocation();
  };

  handleEditSubmit = () => {
    this.props.locationsStore.submitUpdateLocation();
    if (!this.props.locationsStore.updateLocationData.showError) {
      this.setState({
        isEditing: false,
      });
    }
  };

  handleEditCancel = () => {
    this.setState({
      isEditing: false,
    });
    this.props.locationsStore.fetchLocation(this.props.id);
  };

  render() {
    if (!this.props.locationsStore.currentLocation) {
      return null;
    }
    const formData = this.props.locationsStore.currentLocation;
    const locationData = this.props.locationsStore.updateLocationData;
    const companies = this.props.locationsStore.companies;
    const intl = this.props.intl;

    let buttonTitle;
    let buttonIcon;

    if (this.state.isEditing) {
      buttonTitle = 'button.confirm';
      buttonIcon = <DoneIcon color="secondary" />;
    } else {
      buttonTitle = 'button.edit';
      buttonIcon = <EditIcon color="secondary" />;
    }

    return (
      <div>
        <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          <Tabs
            value={'1'}
            indicatorColor="secondary"
            textColor="primary"
            onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
              if (newValue == 0) {
                navigateLocationsPath(this.props.history);
              } else if (newValue == 2) {
                navigateReportingPath(this.props.history, this.props.id);
              } else if (newValue == 3) {
                navigateLocationMapPath(this.props.history, this.props.id);
              }
            }}
            aria-label="simple tabs example"
          >
            <Tab label={intl.formatMessage({ id: 'common.summary' })} value={'1'} id={'1'} />
            <Tab label={intl.formatMessage({ id: 'common.reporting' })} value={'2'} id={'2'} />
            <Tab label={intl.formatMessage({ id: 'common.map' })} value={'3'} id={'3'} />
          </Tabs>
        </Paper>
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          <div style={{ marginRight: '15px', marginTop: '15px', float: 'right' }}>
            {this.state.isEditing ? (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon color="primary" />}
                style={{ marginRight: '10px' }}
                onClick={(e) => {
                  this.handleEditCancel();
                }}
              >
                {intl.formatMessage({ id: 'button.cancel' })}
              </Button>
            ) : null}
            <Button
              variant="contained"
              color="secondary"
              startIcon={buttonIcon}
              onClick={(e) => {
                if (this.state.isEditing) {
                  this.handleEditSubmit();
                } else {
                  this.handleEditOpen();
                }
              }}
            >
              {intl.formatMessage({ id: buttonTitle })}
            </Button>
          </div>
          <ViewLocationComp
            location={formData}
            companyData={formData.companyData}
            history={this.props.history}
            isEditing={this.state.isEditing}
            companies={companies}
            locationData={locationData}
          />
        </Paper>
      </div>
    );
  }
}

export const ViewLocationSummary = injectIntl(inject(STORE_ID, SIDEBAR_STORE_ID)(observer(ViewLocationSummaryImpl)));
