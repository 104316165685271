/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaSerialNumberOrderItem,
    KvintaSerialNumberOrderItemFromJSON,
    KvintaSerialNumberOrderItemFromJSONTyped,
    KvintaSerialNumberOrderItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestSerialNumberOrderItem
 */
export interface KvintaOperationRequestSerialNumberOrderItem {
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestSerialNumberOrderItem
     */
    context?: KvintaExecutionContext;
    /**
     * 
     * @type {KvintaSerialNumberOrderItem}
     * @memberof KvintaOperationRequestSerialNumberOrderItem
     */
    input?: KvintaSerialNumberOrderItem;
}

export function KvintaOperationRequestSerialNumberOrderItemFromJSON(json: any): KvintaOperationRequestSerialNumberOrderItem {
    return KvintaOperationRequestSerialNumberOrderItemFromJSONTyped(json, false);
}

export function KvintaOperationRequestSerialNumberOrderItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestSerialNumberOrderItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
        'input': !exists(json, 'input') ? undefined : KvintaSerialNumberOrderItemFromJSON(json['input']),
    };
}

export function KvintaOperationRequestSerialNumberOrderItemToJSON(value?: KvintaOperationRequestSerialNumberOrderItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': KvintaExecutionContextToJSON(value.context),
        'input': KvintaSerialNumberOrderItemToJSON(value.input),
    };
}


