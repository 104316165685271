/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCreateSerialNumberGenerationProfileCompanyPrefixRequest
 */
export interface KvintaCreateSerialNumberGenerationProfileCompanyPrefixRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberGenerationProfileCompanyPrefixRequest
     */
    profileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberGenerationProfileCompanyPrefixRequest
     */
    value: string;
}

export function KvintaCreateSerialNumberGenerationProfileCompanyPrefixRequestFromJSON(json: any): KvintaCreateSerialNumberGenerationProfileCompanyPrefixRequest {
    return KvintaCreateSerialNumberGenerationProfileCompanyPrefixRequestFromJSONTyped(json, false);
}

export function KvintaCreateSerialNumberGenerationProfileCompanyPrefixRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateSerialNumberGenerationProfileCompanyPrefixRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profileId': !exists(json, 'profileId') ? undefined : json['profileId'],
        'value': json['value'],
    };
}

export function KvintaCreateSerialNumberGenerationProfileCompanyPrefixRequestToJSON(value?: KvintaCreateSerialNumberGenerationProfileCompanyPrefixRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profileId': value.profileId,
        'value': value.value,
    };
}


