import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { TAppOptionsConfig } from 'kvinta/common/Interfaces';
import {
  DefaultApi as StatusStoreApi,
  KvintaExecutionsFilter,
  KvintaExecutionStatusTypeFromJSON,
  KvintaExecutionTree,
  KvintaExecutionWithStatuses,
  KvintaOperationStatus,
  KvintaResource,
  KvintaSortDirection,
  KvintaSortExpressions,
} from 'kvinta/apis/kvinta-status-store';

import { DefaultApi as DocumentStoreApi, KvintaResourceTypeFromJSON } from 'kvinta/apis/kvinta-document-store';
import { NotificationManager } from 'kvinta/modules/main';
import { IFilter, IFilterColumn, PageContentStore } from 'kvinta/components';
import { format } from 'date-fns';

export interface UploadFormDialogData {
  selectedFile: any;
  showError: boolean;
}

export interface ExtKvintaExecutionWithStatuses {
  id: string;
  service: string;
  functionName: string;
  timestamp: string | undefined;
  status: string;
  timestampRaw: number;
  index: number;
  error: string;
}

export class ProcessesStatusStore {
  private _config: TAppOptionsConfig;
  private _statusStoreApi: StatusStoreApi;
  private _documentStoreApi: DocumentStoreApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  listData: ExtKvintaExecutionWithStatuses[];
  page: number;
  totalCount: number;
  pageSize: number;
  currentSort: KvintaSortExpressions;

  filter: IFilter;

  // json
  currentExecution?: KvintaExecutionWithStatuses;
  currentExecutionId?: string;
  currentHierarchyData?: KvintaExecutionTree;

  pageContentStore: PageContentStore;
  width: number | undefined;
  height: number | undefined;

  // Upload dialog handling
  uploadRawResourceFormOpen: boolean;
  uploadRawData: UploadFormDialogData;

  constructor(
    config: TAppOptionsConfig,
    notificationManager: NotificationManager,
    statusStoreApi: StatusStoreApi,
    documentStoreApi: DocumentStoreApi,
    pageContentStore: PageContentStore,
  ) {
    makeObservable(this, {
      fetchPage: action.bound,
      loadEvent: action.bound,
      setFilter: action,
      loadExecutionHierarchy: action.bound,
      triggerResize: action,

      isLoading: observable,
      listData: observable,
      page: observable,
      pageSize: observable,
      filter: observable,

      jsonPayload: computed,
      width: observable,
      height: observable,
      currentExecution: observable,
      currentExecutionId: observable,

      onChangeRawResourceField: action,
      closeUploadRawResourceForm: action,
      openUploadRawResourceForm: action,
      submitUploadRawResourceForm: action,
      uploadRawResourceFormOpen: observable,
      uploadRawData: observable,
    });

    this._config = config;
    this._statusStoreApi = statusStoreApi;
    this._documentStoreApi = documentStoreApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.pageContentStore = pageContentStore;

    // TODO: Initial sort and order
    this.currentSort = {
      expressions: [
        {
          direction: KvintaSortDirection.Desc,
          property: 'timestamp',
        },
      ],
    };
  }

  triggerResize(width: number, height: number) {
    if (this.currentHierarchyData) {
      this.width = width;
      this.height = height;
    }
  }

  doFilter = async () => {
    runInAction(() => {
      this.isLoading = true;
      this.page = 0;
    });

    this.fetchData();
  };

  setFilter(columns: IFilterColumn[]) {
    this.filter = new IFilter(columns, this.doFilter);
  }

  async fetchPage(page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
    });
    this.fetchData();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    runInAction(() => {
      this.isLoading = true;
      const dir = getDirection(orderDirection);
      const field = getField(orderBy);
      this.currentSort = {
        expressions: [
          {
            direction: dir,
            property: field,
          },
        ],
      };
    });
    this.fetchData();
  }

  async fetchData() {
    this.isLoading = true;

    runInAction(() => {
      this.listData = [];
      this.totalCount = 0;
    });
    let filters = {} as KvintaExecutionsFilter;

    for (const filter of this.filter.visibleFilters) {
      if (filter.field === 'executionId' && filter.value != '') {
        return this.fetchDataById(filter.value);
      }
      if (filter.field === 'function' && filter.value !== '') {
        filters = {
          ...filters,
          _function: filter.value,
        };
      }
      if (filter.field === 'status' && filter.value !== '') {
        filters = {
          ...filters,
          lastStatus: KvintaExecutionStatusTypeFromJSON(filter.value),
        };
      }
      if (filter.field === 'service' && filter.value !== '') {
        filters = {
          ...filters,
          service: filter.value,
        };
      }
    }
    const asc =
      (this.currentSort.expressions[0].property === 'timestamp' &&
        this.currentSort.expressions[0].direction === KvintaSortDirection.Asc) ||
      this.currentSort.expressions[0].property !== 'timestamp';
    this._statusStoreApi
      .listExecutions({
        kvintaOperationRequestListExecutionsRequest: {
          input: { paging: { page: this.page, size: this.pageSize, sort: this.currentSort }, filter: filters },
        },
      })
      .then((result) => {
        runInAction(() => {
          this.isLoading = false;
          if (result.status === KvintaOperationStatus.Error) {
            this.listData = [];
            this._notificationManager.sendError(result.error);
          } else {
            this.listData = kepcExecutionRecToView(result.data.list || [], asc);
            this.totalCount = result.data.total || 0;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async fetchDataById(id: string) {
    this.isLoading = true;
    runInAction(() => {
      this.listData = [];
      this.totalCount = 0;
    });
    const asc =
      (this.currentSort.expressions[0].property === 'timestamp' &&
        this.currentSort.expressions[0].direction === KvintaSortDirection.Asc) ||
      this.currentSort.expressions[0].property !== 'timestamp';
    this._statusStoreApi
      .readExecutionWithStatuses({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        runInAction(() => {
          this.isLoading = false;
          if (result.status === KvintaOperationStatus.Error) {
            this.listData = [];
            this._notificationManager.sendError(result.error);
          } else {
            this.listData = kepcExecutionRecToView([result.data] || [], asc);
            this.totalCount = result.data.statuses.length || 0;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  formatDateTime(timestamp: number): string {
    return format(new Date(timestamp), this._config.defaultDateTimeFormatting);
  }

  async loadEvent(id: string) {
    this.currentExecution = undefined;
    this.currentExecutionId = undefined;
    this._statusStoreApi
      .readExecutionWithStatuses({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        runInAction(() => {
          if (result.status === KvintaOperationStatus.Ok) {
            this.currentExecution = result.data;
            this.currentExecutionId = result.executionId;
          } else {
            this._notificationManager.sendError(result.error);
          }
        });
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(err.toString());
      });
  }

  async loadExecutionHierarchy(id: string) {
    this.currentExecution = undefined;
    this.currentExecutionId = undefined;
    this.currentHierarchyData = undefined;
    this.width = undefined;
    this.height = undefined;
    try {
      const result = await this._statusStoreApi.readExecutionWithStatuses({
        kvintaOperationRequestString: {
          input: id,
        },
      });
      if (result.status === KvintaOperationStatus.Ok) {
        const hResult = await this._statusStoreApi.readHierarchyByRootExecutionId({
          kvintaOperationRequestString: {
            input: result.data.execution.context.rootExecutionId,
          },
        });
        if (hResult.status === KvintaOperationStatus.Ok) {
          runInAction(() => {
            this.currentExecution = result.data;
            this.currentExecutionId = result.executionId;

            this.currentHierarchyData = hResult.data as KvintaExecutionTree;
            this.width = this.pageContentStore.width;
            this.height = this.pageContentStore.height;
          });
        } else {
          this._notificationManager.sendError(result.error);
        }
      } else {
        this._notificationManager.sendError(result.error);
      }
    } catch (err) {
      this._notificationManager.sendError(err.toString());
    }
  }

  get jsonPayload() {
    if (this.currentExecution) {
      return JSON.stringify(this.currentExecution, null, 2);
    } else {
      return 'No data available'; // TODO: Maybe translate
    }
  }

  async tiggerRetryExecution(executionId: string): Promise<void> {
    try {
      const retryResponse = await this._statusStoreApi.retryExecution({
        kvintaOperationRequestString: { input: executionId },
      });
      if (retryResponse && retryResponse.status == KvintaOperationStatus.Ok) {
        this._notificationManager.sendSuccess(
          `Operation successfuly retried with ${retryResponse.data.execution.context.executionId}`,
        );
      } else {
        this._notificationManager.sendError(retryResponse.error);
      }
    } catch (err) {
      this._notificationManager.sendError(JSON.stringify(err));
    }
  }

  notifyInfo(msg: string) {
    this._notificationManager.sendInformation(msg);
  }

  fetchResource = async (resource: KvintaResource): Promise<IResourceFile> => {
    try {
      const docStoreRes = await this._documentStoreApi.readResource({
        kvintaOperationRequestResource: {
          input: {
            id: resource.id,
            system: resource.system,
            type: KvintaResourceTypeFromJSON(resource.type),
            parentResourceId: resource.parentResourceId,
            sgln: resource.sgln,
            tenantId: resource.tenantId,
            userId: resource.id,
          },
        },
      });
      if (docStoreRes.status !== KvintaOperationStatus.Ok) {
        return { fetched: false, filename: resource.id + '.json', contents: JSON.stringify(resource) } as IResourceFile;
      } else {
        return { fetched: true, filename: resource.id + '.json', contents: docStoreRes.data } as IResourceFile;
      }
    } catch (err) {
      this._notificationManager.sendError(JSON.stringify(err));
      return { fetched: false, filename: resource.id + '.json', contents: JSON.stringify(resource) } as IResourceFile;
    }
  };

  // Upload dialog handling
  onChangeRawResourceField = (id: string, value: any) => {
    this.uploadRawData[id] = value;
    this.uploadRawData.showError = this.uploadRawData.selectedFile !== undefined;
  };

  submitUploadRawResourceForm = async (): Promise<void> => {
    try {
      const rawData = this.uploadRawData.selectedFile;
      if (rawData === undefined) {
        return;
      }
      const encodedContent = await blobToBase64(rawData);
      console.log('Upload: ', rawData, ' encoded:', encodedContent);
      const retryResponse = await this._statusStoreApi.retryExecutionPayload({
        kvintaOperationRequestRetryExecutionWithNewPayloadRequest: {
          input: {
            executionId: this.currentExecutionId,
            b64content: encodedContent,
          },
        },
      });
      if (retryResponse.status !== KvintaOperationStatus.Ok) {
        this._notificationManager.sendError(retryResponse.error);
      } else {
        this._notificationManager.sendSuccess('Execution ' + this.currentExecutionId + ' successfully retried');
      }
    } catch (err) {
      this._notificationManager.sendError(JSON.stringify(err));
    }
    runInAction(() => {
      this.uploadRawResourceFormOpen = false;
      this.currentExecutionId = null;
    });
  };

  closeUploadRawResourceForm = (): void => {
    this.uploadRawResourceFormOpen = false;
  };

  openUploadRawResourceForm = (executionId: string): void => {
    this.uploadRawResourceFormOpen = true;
    this.uploadRawData = { showError: false, selectedFile: undefined };
    this.currentExecutionId = executionId;
  };
}

function kepcExecutionRecToView(
  list: KvintaExecutionWithStatuses[],
  timestampAsc: boolean,
): ExtKvintaExecutionWithStatuses[] {
  const viewItems: ExtKvintaExecutionWithStatuses[] = [];
  for (const record of list) {
    const fn = record.execution._function;
    const id = record.execution.context.executionId;
    const statuses = record.statuses;
    let index = 0;
    for (const status of statuses) {
      viewItems.push({
        id,
        service: fn.service,
        functionName: fn._function,
        timestamp: status.timestamp ? new Date(status.timestamp.epochMillis).toISOString() : undefined,
        status: status.status,
        timestampRaw: status.timestamp.epochMillis,
        index: index,
        error: getErrorMessage(status.error),
      });
      index++;
      // For just first record
      // break;
    }
  }
  if (timestampAsc) {
    return viewItems.sort((a: ExtKvintaExecutionWithStatuses, b: ExtKvintaExecutionWithStatuses) => {
      return a.timestampRaw - b.timestampRaw;
    });
  } else {
    return viewItems.sort((a: ExtKvintaExecutionWithStatuses, b: ExtKvintaExecutionWithStatuses) => {
      return b.timestampRaw - a.timestampRaw;
    });
  }
}

export const PROCESSES_STATUS_STORE_ID = 'processesStatusStore';

interface IResponseError {
  error_message: string;
}

function getErrorMessage(err?: string) {
  if (err && err.startsWith('{')) {
    const errObj = JSON.parse(err) as IResponseError;
    return errObj.error_message;
  }
  if (err) {
    return err;
  }
  return '';
}

function getDirection(orderDirection: string): KvintaSortDirection {
  if (orderDirection === 'asc') {
    return KvintaSortDirection.Asc;
  } else {
    return KvintaSortDirection.Desc;
  }
}

function getField(orderBy: number): string {
  switch (orderBy) {
    case 1:
      return 'service';
    case 2:
      return 'function';
    case 3:
      return 'timestamp';
    case 4:
      return 'status';
    default:
      return 'timestamp';
  }
}

export interface IResourceFile {
  fetched: boolean;
  filename?: string;
  contents?: string;
}

const blobToBase64 = async (blob): Promise<string> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      const b64s = reader.result as string;
      // remote the prefix like: data:application/json;base64,ewogICJpZCI6ICJ0ZXN0LWNyYW...
      const idxB64 = b64s.lastIndexOf(',') + 1;
      if (idxB64 > 0) {
        resolve(b64s.substring(idxB64));
      } else {
        resolve(b64s);
      }
    };
  });
};
