import { Link } from '@material-ui/core';
import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React, { Fragment } from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  getTableHeight,
  KSidebarStore,
  PageContentStore,
  PAGE_CONTENT_STORE_ID,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { EpcMessagesStore, EPC_MESSAGES_STORE_ID } from './EpcMessagesStore';
import { FilterBar } from 'kvinta/components';
import { linkEpcMessagePath, navigateEpcMessagePath } from './paths';
import { Waypoint } from 'react-waypoint';

interface ViewListEpcMessagesProps {
  epcMessagesStore?: EpcMessagesStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
}

class ViewListEpcMessagesImpl extends Component<ViewListEpcMessagesProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ViewListEpcMessagesProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.epcMessagesStore.fetchInitialData();
  }

  navigateMessageSummary = (history: any, id: string, ts: number, idx?: number) => {
    navigateEpcMessagePath(history, id, ts, idx);
  };

  render() {
    const intl = this.props.intl;
    const { listData, isLoading, totalCount, filter } = this.props.epcMessagesStore;
    const { height } = this.props.pageContentStore;
    if (!height || height === NaN || height === 0) {
      return null;
    }
    const bodyHeight = getTableHeight(height, 8);
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <MaterialTable
                icons={tableIcons}
                tableRef={this.tableRef}
                // title={intl.formatMessage({ id: 'menu.messages' })}
                columns={[
                  {
                    title: intl.formatMessage({ id: 'epc-list.code' }),
                    field: 'id',
                    render: (data) => {
                      const rowIndex = data.idx;
                      if (rowIndex === totalCount - 10) {
                        return (
                          <Fragment>
                            <Waypoint
                              onEnter={() => {
                                this.props.epcMessagesStore.fetchData();
                              }}
                            />
                            <Link
                              href={linkEpcMessagePath(history, data.id, data.ts, data.tsIdx)}
                              onClick={preventDefault}
                            >
                              {data.id}
                            </Link>
                          </Fragment>
                        );
                      } else {
                        return (
                          <Fragment>
                            <Link
                              href={linkEpcMessagePath(history, data.id, data.ts, data.tsIdx)}
                              onClick={preventDefault}
                            >
                              {data.id}
                            </Link>
                          </Fragment>
                        );
                      }
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'epc-list.location' }),
                    field: 'location',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'epc-list.operation' }),
                    field: 'op',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                ]}
                data={listData}
                totalCount={totalCount}
                onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                  this.props.epcMessagesStore.changeOrder(orderBy, orderDirection);
                }}
                isLoading={isLoading}
                options={{
                  actionsColumnIndex: -1,
                  paging: false,
                  sorting: true,
                  draggable: false,
                  search: false,
                  minBodyHeight: bodyHeight,
                  maxBodyHeight: bodyHeight,
                  loadingType: 'overlay',
                  showTitle: false,
                }}
                onRowClick={(event, rowData) => {
                  this.navigateMessageSummary(history, rowData.id, rowData.ts, rowData.tsIdx);
                }}
                components={{
                  Toolbar: (props) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: '20px',
                        marginRight: '20px',
                        marginLeft: '11px',
                      }}
                    >
                      <FilterBar filter={filter} />
                    </div>
                  ),
                }}
              />
            </div>
          </div>
        )}
      />
    );
  }
}
export const ViewListEpcMessages = injectIntl(
  inject(EPC_MESSAGES_STORE_ID, PAGE_CONTENT_STORE_ID, SIDEBAR_STORE_ID)(observer(ViewListEpcMessagesImpl)),
);
