/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCreateTradeItemRequest
 */
export interface KvintaCreateTradeItemRequest {
    /**
     * 
     * @type {number}
     * @memberof KvintaCreateTradeItemRequest
     */
    baseUomDenominator?: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaCreateTradeItemRequest
     */
    baseUomNominator?: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateTradeItemRequest
     */
    companyPrefix: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateTradeItemRequest
     */
    gtin: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateTradeItemRequest
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateTradeItemRequest
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateTradeItemRequest
     */
    uom: string;
}

export function KvintaCreateTradeItemRequestFromJSON(json: any): KvintaCreateTradeItemRequest {
    return KvintaCreateTradeItemRequestFromJSONTyped(json, false);
}

export function KvintaCreateTradeItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateTradeItemRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'baseUomDenominator': !exists(json, 'baseUomDenominator') ? undefined : json['baseUomDenominator'],
        'baseUomNominator': !exists(json, 'baseUomNominator') ? undefined : json['baseUomNominator'],
        'companyPrefix': json['companyPrefix'],
        'gtin': json['gtin'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'productId': json['productId'],
        'uom': json['uom'],
    };
}

export function KvintaCreateTradeItemRequestToJSON(value?: KvintaCreateTradeItemRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'baseUomDenominator': value.baseUomDenominator,
        'baseUomNominator': value.baseUomNominator,
        'companyPrefix': value.companyPrefix,
        'gtin': value.gtin,
        'id': value.id,
        'productId': value.productId,
        'uom': value.uom,
    };
}


