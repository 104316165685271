import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { KSidebarStore, PAGE_CONTENT_STORE_ID, PageContentStore, SIDEBAR_STORE_ID, tableIcons } from '../../components';
import { NOTIFICATION_STORE_ID, NotificationStore } from './NotificationStore';
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Button, Checkbox, Paper, Tab, Tabs } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { KvintaEmailRecipient } from '../../apis/kvinta-notification-functions';
import {
  LocationReportingData,
  navigateLocationMapPath,
  navigateLocationsPath,
  navigateReportingPath,
} from '../master-data/locations';
import {
  navigateToNotificationGroupList,
  navigateToNotificationGroupNotificationTypes,
  navigateToNotificationGroupRecipients,
} from './paths';
import { autorun } from 'mobx';

type NotificationGroupNotificationTypesListProps = {
  notificationStore?: NotificationStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history: any;
  id: string;
};

type NotificationGroupNotificationTypesListState = {
  notificationTypes: { type: string; isActive: boolean }[];
  saveButtonIsActive: boolean;
} | null;

class NotificationGroupNotificationTypesListBase extends Component<
  NotificationGroupNotificationTypesListProps,
  NotificationGroupNotificationTypesListState
> {
  dispose = () => undefined;

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.dispose = autorun(() => {
      const { currentGroupNotificationTypes } = this.props.notificationStore;
      if (this.state === null && currentGroupNotificationTypes && currentGroupNotificationTypes.length > 0) {
        this.setState({
          notificationTypes: currentGroupNotificationTypes,
          saveButtonIsActive: false,
        });
      }
    });

    this.props.notificationStore.getGroupsNotificationTypesData(this.props.id);
  }

  componentWillUnmount() {
    this.dispose();
    this.props.notificationStore.cleanupGroupsNotificationTypesData();
  }

  handleChange(id: string) {
    const newNotificationState = this.state.notificationTypes.map((notificationType) => {
      if (notificationType.type === id) {
        return { ...notificationType, isActive: !notificationType.isActive };
      } else {
        return notificationType;
      }
    });
    const saveButtonIsActive = newNotificationState.reduce((acc, newNotificationTypeState) => {
      if (acc === true) {
        return true;
      } else {
        const oldNotificationTypeState = this.props.notificationStore.currentGroupNotificationTypes.find(
          (n) => n.type === newNotificationTypeState.type,
        );
        return oldNotificationTypeState.isActive !== newNotificationTypeState.isActive;
      }
    }, false);

    this.setState({
      notificationTypes: newNotificationState,
      saveButtonIsActive,
    });
  }

  handleSubmit() {
    this.setState({
      saveButtonIsActive: false,
    });
    this.props.notificationStore.submitNotificationTypes(this.props.id, this.state.notificationTypes);
  }

  render() {
    if (!this.state || !this.state.notificationTypes) {
      return <span style={{ padding: '20px' }}>loading...</span>;
    }

    const intl = this.props.intl;
    return (
      <div>
        <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          <div style={{ display: 'grid', gridTemplate: '1fr/1fr auto', paddingTop: '20px' }}>
            <Tabs
              value={'2'}
              indicatorColor="secondary"
              textColor="primary"
              onChange={(event: React.ChangeEvent<any>, newValue: number) => {
                if (newValue == 0) {
                  navigateToNotificationGroupList(this.props.history);
                } else if (newValue == 1) {
                  navigateToNotificationGroupRecipients(this.props.history, this.props.id);
                }
              }}
              aria-label="simple tabs example"
            >
              <Tab label={intl.formatMessage({ id: 'notification-group-view.tab.recipients' })} value={'1'} id={'1'} />
              <Tab
                label={intl.formatMessage({ id: 'notification-group-view.tab.notification-types' })}
                value={'2'}
                id={'2'}
              />
            </Tabs>
            <div style={{ padding: '0 20px 10px' }}>
              <Button
                onClick={() => this.handleSubmit()}
                color="primary"
                variant="contained"
                disabled={!this.state.saveButtonIsActive}
                autoFocus
              >
                {intl.formatMessage({ id: 'button.submit' })}
              </Button>
            </div>
          </div>
        </Paper>
        <Paper square style={{ height: '100vh', boxShadow: 'none', paddingLeft: '20px', paddingTop: '20px' }}>
          {this.state.notificationTypes.map((notificationType) => {
            return (
              <div key={notificationType.type as string}>
                <Checkbox
                  checked={notificationType.isActive}
                  onChange={() => this.handleChange(notificationType.type)}
                />
                {notificationType.type}
              </div>
            );
          })}
        </Paper>
      </div>
    );
  }
}

export const NotificationGroupNotificationTypesList = injectIntl(
  inject(
    PAGE_CONTENT_STORE_ID,
    NOTIFICATION_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(NotificationGroupNotificationTypesListBase)),
);
