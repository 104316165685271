/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEmailTemplate,
    KvintaEmailTemplateFromJSON,
    KvintaEmailTemplateFromJSONTyped,
    KvintaEmailTemplateToJSON,
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestEmailTemplate
 */
export interface KvintaOperationRequestEmailTemplate {
    /**
     * 
     * @type {KvintaEmailTemplate}
     * @memberof KvintaOperationRequestEmailTemplate
     */
    input?: KvintaEmailTemplate;
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestEmailTemplate
     */
    context?: KvintaExecutionContext;
}

export function KvintaOperationRequestEmailTemplateFromJSON(json: any): KvintaOperationRequestEmailTemplate {
    return KvintaOperationRequestEmailTemplateFromJSONTyped(json, false);
}

export function KvintaOperationRequestEmailTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestEmailTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'input': !exists(json, 'input') ? undefined : KvintaEmailTemplateFromJSON(json['input']),
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
    };
}

export function KvintaOperationRequestEmailTemplateToJSON(value?: KvintaOperationRequestEmailTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'input': KvintaEmailTemplateToJSON(value.input),
        'context': KvintaExecutionContextToJSON(value.context),
    };
}


