/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaSortBy
 */
export interface KvintaSortBy {
    /**
     * 
     * @type {string}
     * @memberof KvintaSortBy
     */
    field: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaSortBy
     */
    order: string;
}

export function KvintaSortByFromJSON(json: any): KvintaSortBy {
    return KvintaSortByFromJSONTyped(json, false);
}

export function KvintaSortByFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSortBy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': json['field'],
        'order': json['order'],
    };
}

export function KvintaSortByToJSON(value?: KvintaSortBy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'order': value.order,
    };
}


