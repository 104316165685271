/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaExecutionStatusType {
    Queued = 'QUEUED',
    Started = 'STARTED',
    Success = 'SUCCESS',
    Error = 'ERROR',
    Retried = 'RETRIED'
}

export function KvintaExecutionStatusTypeFromJSON(json: any): KvintaExecutionStatusType {
    return KvintaExecutionStatusTypeFromJSONTyped(json, false);
}

export function KvintaExecutionStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaExecutionStatusType {
    return json as KvintaExecutionStatusType;
}

export function KvintaExecutionStatusTypeToJSON(value?: KvintaExecutionStatusType | null): any {
    return value as any;
}

