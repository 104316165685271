/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Capture Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaCaptureSensorMetaData,
    KvintaCaptureSensorMetaDataFromJSON,
    KvintaCaptureSensorMetaDataFromJSONTyped,
    KvintaCaptureSensorMetaDataToJSON,
    KvintaCaptureSensorReportElement,
    KvintaCaptureSensorReportElementFromJSON,
    KvintaCaptureSensorReportElementFromJSONTyped,
    KvintaCaptureSensorReportElementToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaCaptureSensorElement
 */
export interface KvintaCaptureSensorElement {
    /**
     * 
     * @type {KvintaCaptureSensorMetaData}
     * @memberof KvintaCaptureSensorElement
     */
    sensorMetaData?: KvintaCaptureSensorMetaData;
    /**
     * 
     * @type {Array<KvintaCaptureSensorReportElement>}
     * @memberof KvintaCaptureSensorElement
     */
    sensorReport?: Array<KvintaCaptureSensorReportElement>;
}

export function KvintaCaptureSensorElementFromJSON(json: any): KvintaCaptureSensorElement {
    return KvintaCaptureSensorElementFromJSONTyped(json, false);
}

export function KvintaCaptureSensorElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCaptureSensorElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sensorMetaData': !exists(json, 'sensorMetaData') ? undefined : KvintaCaptureSensorMetaDataFromJSON(json['sensorMetaData']),
        'sensorReport': !exists(json, 'sensorReport') ? undefined : ((json['sensorReport'] as Array<any>).map(KvintaCaptureSensorReportElementFromJSON)),
    };
}

export function KvintaCaptureSensorElementToJSON(value?: KvintaCaptureSensorElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sensorMetaData': KvintaCaptureSensorMetaDataToJSON(value.sensorMetaData),
        'sensorReport': value.sensorReport === undefined ? undefined : ((value.sensorReport as Array<any>).map(KvintaCaptureSensorReportElementToJSON)),
    };
}


