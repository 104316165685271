/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisBizStep,
    KvintaEpcisBizStepFromJSON,
    KvintaEpcisBizStepFromJSONTyped,
    KvintaEpcisBizStepToJSON,
    KvintaSequenceStatus,
    KvintaSequenceStatusFromJSON,
    KvintaSequenceStatusFromJSONTyped,
    KvintaSequenceStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaSequenceStatusesFilter
 */
export interface KvintaSequenceStatusesFilter {
    /**
     * 
     * @type {number}
     * @memberof KvintaSequenceStatusesFilter
     */
    repStatusId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSequenceStatusesFilter
     */
    eventId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSequenceStatusesFilter
     */
    epc?: string | null;
    /**
     * 
     * @type {KvintaEpcisBizStep}
     * @memberof KvintaSequenceStatusesFilter
     */
    bizStep?: KvintaEpcisBizStep;
    /**
     * 
     * @type {KvintaSequenceStatus}
     * @memberof KvintaSequenceStatusesFilter
     */
    status?: KvintaSequenceStatus;
    /**
     * 
     * @type {string}
     * @memberof KvintaSequenceStatusesFilter
     */
    dependsOnEpc?: string | null;
}

export function KvintaSequenceStatusesFilterFromJSON(json: any): KvintaSequenceStatusesFilter {
    return KvintaSequenceStatusesFilterFromJSONTyped(json, false);
}

export function KvintaSequenceStatusesFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSequenceStatusesFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'repStatusId': !exists(json, 'repStatusId') ? undefined : json['repStatusId'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'epc': !exists(json, 'epc') ? undefined : json['epc'],
        'bizStep': !exists(json, 'bizStep') ? undefined : KvintaEpcisBizStepFromJSON(json['bizStep']),
        'status': !exists(json, 'status') ? undefined : KvintaSequenceStatusFromJSON(json['status']),
        'dependsOnEpc': !exists(json, 'dependsOnEpc') ? undefined : json['dependsOnEpc'],
    };
}

export function KvintaSequenceStatusesFilterToJSON(value?: KvintaSequenceStatusesFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'repStatusId': value.repStatusId,
        'eventId': value.eventId,
        'epc': value.epc,
        'bizStep': KvintaEpcisBizStepToJSON(value.bizStep),
        'status': KvintaSequenceStatusToJSON(value.status),
        'dependsOnEpc': value.dependsOnEpc,
    };
}


