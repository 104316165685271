import { TabSpec } from 'kvinta/components';

export const tabsSummary = [
  {
    label: 'common.summary',
    value: '1',
  },
  {
    label: 'serial-numbers.serial-numbers',
    value: '2',
  },
  {
    label: 'common.json',
    value: '3',
  },
  {
    label: 'atk.header.status-list',
    value: '4',
  },
  {
    label: 'atk.header.linked-document-list',
    value: '5',
  },
] as TabSpec[];
