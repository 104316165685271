/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { format } from 'date-fns';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { UserStore } from '../../../main';
import {
  errorRequired,
  requiredFieldsSerialNumberOrder,
  SerialNumberOrdersStore,
  STORE_ID,
} from '../SerialNumberOrdersStore';
import { KDialogTitle } from 'kvinta/components/KDialog';

interface CreateSerialNumberOrderDialogProps {
  serialNumberOrdersStore?: SerialNumberOrdersStore;
  userStore?: UserStore;
  intl: any;
}

class CreateSerialNumberOrderDialogImpl extends Component<CreateSerialNumberOrderDialogProps> {
  render() {
    const isOpen = this.props.serialNumberOrdersStore.serialNumberOrderFormOpen;
    const onChangeValue = this.props.serialNumberOrdersStore.onChangeCreateSerialNumberOrderField;
    const formData = this.props.serialNumberOrdersStore.createSerialNumberOrderFormData;
    const intl = this.props.intl;
    const showError = formData.showError;

    enum OrderType {
      OMS_MILK = 'OMS_MILK',
      KVINTA_GENERATOR = 'KVINTA_GENERATOR',
      OMS_WATER = 'OMS_WATER',
      OMS_BEER = 'OMS_BEER',
      OMS_BIO = 'OMS_BIO',
    }

    const OrderTypesList = Object.values(OrderType);

    const paymentTypes = [
      { value: '1', label: 'serial-number-order-form.field.payment.byemission' },
      { value: '2', label: 'serial-number-order-form.field.payment.byutilization' },
    ];

    if (!isOpen) {
      return null;
    }
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={isOpen}
        onClose={() => this.props.serialNumberOrdersStore.closeCreateSerialNumberOrderForm()}
      >
        <KDialogTitle
          id="customized-dialog-title"
          onClose={() => this.props.serialNumberOrdersStore.closeCreateSerialNumberOrderForm()}
        >
          {intl.formatMessage({ id: 'serial-number-order-form.field.create' })}
        </KDialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="contactPerson"
                    required
                    helperText={
                      showError &&
                      errorRequired('contactPerson', requiredFieldsSerialNumberOrder, formData.contactPerson)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={
                      showError &&
                      errorRequired('contactPerson', requiredFieldsSerialNumberOrder, formData.contactPerson)
                    }
                    value={formData.contactPerson}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('contactPerson', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'serial-number-order-form.field.contactperson' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="documentDate"
                    // required
                    helperText={
                      showError && errorRequired('documentDate', requiredFieldsSerialNumberOrder, formData.documentDate)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={
                      showError && errorRequired('documentDate', requiredFieldsSerialNumberOrder, formData.documentDate)
                    }
                    value={format(formData.documentDate, 'yyyy-MM-dd')}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('documentDate', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'serial-number-order-form.field.documentdate' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="paymentType">
                    {intl.formatMessage({ id: 'serial-number-order-form.field.paymenttype' })}
                  </InputLabel>
                  <Select
                    id="paymentType"
                    value={formData.paymentType}
                    onChange={(e) => {
                      onChangeValue('paymentType', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'serial-number-order-form.field.paymenttype' })}
                  >
                    {paymentTypes.map((paymentType) => (
                      <MenuItem key={'payment_type_' + paymentType.value} value={paymentType.value}>
                        <FormattedMessage id={paymentType.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="documentNumber"
                    required
                    helperText={
                      showError &&
                      errorRequired('documentNumber', requiredFieldsSerialNumberOrder, formData.documentNumber)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={
                      showError &&
                      errorRequired('documentNumber', requiredFieldsSerialNumberOrder, formData.documentNumber)
                    }
                    value={formData.documentNumber}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('documentNumber', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'serial-number-order-form.field.documentnumber' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="orderType">
                    {intl.formatMessage({ id: 'serial-number-order-form.field.ordertype' })}
                  </InputLabel>
                  <Select
                    id="orderType"
                    value={formData.orderType}
                    required
                    error={showError && errorRequired('orderType', requiredFieldsSerialNumberOrder, formData.orderType)}
                    onChange={(e) => {
                      onChangeValue('orderType', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'serial-number-order-form.field.ordertype' })}
                  >
                    {OrderTypesList.map((orderType) => (
                      <MenuItem key={orderType} value={orderType}>
                        {orderType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.serialNumberOrdersStore.closeCreateSerialNumberOrderForm()}
            color="primary"
            variant="outlined"
          >
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <Button
            onClick={() => this.props.serialNumberOrdersStore.submitCreateSerialNumberOrderForm()}
            color="primary"
            variant="contained"
            // disabled={!formData.isValid}
            autoFocus
          >
            {intl.formatMessage({ id: 'button.submit' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const CreateSerialNumberOrderDialog = injectIntl(inject(STORE_ID)(observer(CreateSerialNumberOrderDialogImpl)));
