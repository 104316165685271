/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaResourceType,
    KvintaResourceTypeFromJSON,
    KvintaResourceTypeFromJSONTyped,
    KvintaResourceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaBatchFindChildTextDocumentsRecordsRequest
 */
export interface KvintaBatchFindChildTextDocumentsRecordsRequest {
    /**
     * 
     * @type {KvintaResourceType}
     * @memberof KvintaBatchFindChildTextDocumentsRecordsRequest
     */
    childType: KvintaResourceType;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaBatchFindChildTextDocumentsRecordsRequest
     */
    parentResourceIds: Array<string>;
}

export function KvintaBatchFindChildTextDocumentsRecordsRequestFromJSON(json: any): KvintaBatchFindChildTextDocumentsRecordsRequest {
    return KvintaBatchFindChildTextDocumentsRecordsRequestFromJSONTyped(json, false);
}

export function KvintaBatchFindChildTextDocumentsRecordsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaBatchFindChildTextDocumentsRecordsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'childType': KvintaResourceTypeFromJSON(json['childType']),
        'parentResourceIds': json['parentResourceIds'],
    };
}

export function KvintaBatchFindChildTextDocumentsRecordsRequestToJSON(value?: KvintaBatchFindChildTextDocumentsRecordsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'childType': KvintaResourceTypeToJSON(value.childType),
        'parentResourceIds': value.parentResourceIds,
    };
}


