import { Button, Paper } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  PageContentStore,
  TabBar,
  tableIcons,
  PAGE_CONTENT_STORE_ID,
  DefaultTitleHeight,
  FormTextField,
  FormDateField,
} from 'kvinta/components';
import { ATKListStore, STORE_ID } from '../ATKListStore';
import {
  navigateATKCirculationReportsPath,
  navigateATKJsonPath,
  navigateATKListPath,
  navigateATKSerialNumbersPath,
  navigateATKStatusesPath,
  navigateATKSummaryPath,
} from '../paths';
import { tabsSummary } from './tabs';
import { navigateCirculationReportSummaryPath } from '../../circulation-reports/paths';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { format } from 'date-fns';
import { productGroupIsWaterBeerBio } from '../../../../../config';

interface ATKCirculationReportsViewProps {
  atkListStore?: ATKListStore;
  pageContentStore?: PageContentStore;
  intl: any;
  history?: any;
  id: string;
}

type CreateCirculationReportFormData =
  | { isOpen: false }
  | {
      isOpen: true;
      values: {
        vsdNumber: string;
        tradeParticipantInn: string;
        declarationNumber: string;
        declarationDate: string;
        productionDate: string;
      };
      errors: any;
      touched: any;
      submitButtonActive: boolean;
    };

type ATKCirculationReportsViewState = {
  createCirculationReportForm: CreateCirculationReportFormData;
};

type CreateCirculationReportFormProps = {
  data: CreateCirculationReportFormData;

  handleChange: any;
  handleBlur: (id: string) => void;
  handleFocus: (id: string) => void;
  handleSubmit: () => void;
  closeModal: () => void;
  intl: any;
};

function nonEmptyString(input: string) {
  return input.length > 0 ? undefined : 'circulation-report-form.error.field-empty';
}

function optionalFieldAndIsGuid(input: string) {
  return input.length > 0 ? isGuid(input) : undefined;
}

function isCustomsDeclarationNumber(input) {
  // 10228010/110821/0365893
  return new RegExp('^\\d{8}\\/\\d{6}\\/\\d{7}$').test(input)
    ? undefined
    : 'circulation-report-form.error.declaration-number-invalid';
}

function isGuid(input) {
  // 0ec16bf8-1bfb-49b2-ba9b-784d70923a8d
  return new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$').test(input)
    ? undefined
    : 'circulation-report-form.error.vsd-number-invalid';
}

class ATKCirculationReportsViewImpl extends Component<ATKCirculationReportsViewProps, ATKCirculationReportsViewState> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ATKCirculationReportsViewProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      createCirculationReportForm: {
        isOpen: false,
      },
    };
  }

  componentDidMount() {
    this.props.atkListStore.fetchATKCirculationReports(this.props.id);
  }

  navigateCirculationReportSummary = (id: string) => {
    navigateCirculationReportSummaryPath(this.props.history, id);
  };

  openCirculationReportForm = () => {
    const initialValues: { [key: string]: string } = {
      tradeParticipantInn: '',
      declarationNumber: '',
      declarationDate: format(new Date(), 'yyyy-MM-dd'),
      productionDate: format(new Date(), 'yyyy-MM-dd'),
    };

    if (!productGroupIsWaterBeerBio) {
      initialValues.vsdNumber = '';
    }

    this.setState({
      createCirculationReportForm: {
        isOpen: true,
        submitButtonActive: false,
        values: initialValues as any,
        errors: {},
        touched: {},
      },
    });
  };

  closeModal = () => {
    this.setState({
      createCirculationReportForm: {
        isOpen: false,
      },
    });
  };

  validateForm = (values) => {
    const spec: { [key: string]: ((input: string) => string | undefined)[] } = {
      tradeParticipantInn: [nonEmptyString],
      declarationNumber: [nonEmptyString, isCustomsDeclarationNumber],
    };

    if (!productGroupIsWaterBeerBio) {
      spec.vsdNumber = [optionalFieldAndIsGuid];
    }

    if (this.state.createCirculationReportForm.isOpen) {
      return Object.keys(spec).reduce((formValidationResult, fieldId) => {
        const fieldResult = spec[fieldId].reduce((fieldValidationResult, fn) => {
          const error = fn(values[fieldId] ? values[fieldId].trim() : '');
          error && (fieldValidationResult[fieldId] = error);
          return fieldValidationResult;
        }, {});

        return {
          ...formValidationResult,
          ...fieldResult,
        };
      }, {});
    } else {
      return {};
    }
  };

  handleChange = (id: string, newValue: string) => {
    if (this.state.createCirculationReportForm.isOpen) {
      const nextValues = {
        ...this.state.createCirculationReportForm.values,
        [id]: newValue,
      };

      const errors = this.validateForm(nextValues);
      const submitButtonActive = Object.keys(errors).length === 0;

      this.setState({
        createCirculationReportForm: {
          ...this.state.createCirculationReportForm,
          values: nextValues,
          errors,
          submitButtonActive,
        },
      });
    }
  };

  handleBlur = (id: string) => {
    if (this.state.createCirculationReportForm.isOpen) {
      this.setState({
        createCirculationReportForm: {
          ...this.state.createCirculationReportForm,
          touched: {
            ...this.state.createCirculationReportForm.touched,
            [id]: true,
          },
        },
      });
    }
  };

  handleFocus = (id: string) => {
    if (this.state.createCirculationReportForm.isOpen) {
      this.setState({
        createCirculationReportForm: {
          ...this.state.createCirculationReportForm,
          touched: {
            ...this.state.createCirculationReportForm.touched,
            [id]: false,
          },
        },
      });
    }
  };

  handleSubmit = () => {
    if (this.state.createCirculationReportForm.isOpen) {
      this.props.atkListStore.createCirculationReport({
        id: this.props.id,
        ...this.state.createCirculationReportForm.values,
      });
      this.setState({
        createCirculationReportForm: {
          isOpen: false,
        },
      });
    }
  };

  render() {
    const intl = this.props.intl;
    const { currentATKCirculationReports, isLoading, pageSize } = this.props.atkListStore;
    const formData = currentATKCirculationReports;
    const bodyHeight = this.props.pageContentStore.tableContentHeightThin;
    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                <TabBar
                  onChange={(newValue: any) => {
                    if (newValue === '0') {
                      navigateATKListPath(this.props.history);
                    }
                    if (newValue === '1') {
                      navigateATKSummaryPath(this.props.history, this.props.id);
                    }
                    if (newValue === '2') {
                      navigateATKSerialNumbersPath(this.props.history, this.props.id);
                    }
                    if (newValue === '3') {
                      navigateATKJsonPath(this.props.history, this.props.id);
                    }
                    if (newValue === '4') {
                      navigateATKStatusesPath(this.props.history, this.props.id);
                    }
                    if (newValue === '5') {
                      navigateATKCirculationReportsPath(this.props.history, this.props.id);
                    }
                  }}
                  tabId={'5'}
                  tabs={tabsSummary}
                />
              </Paper>
              <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
                <MaterialTable
                  icons={tableIcons}
                  tableRef={this.tableRef}
                  title={null}
                  columns={[
                    {
                      title: intl.formatMessage({ id: 'atk-list.header.id' }),
                      field: 'resource.id',
                      defaultSort: 'desc',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '30%',
                      },
                    },
                    {
                      title: intl.formatMessage({ id: 'atk-list.header.time' }),
                      field: 'timestamp',
                      defaultSort: 'desc',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '30%',
                      },
                      render: (rowData) => {
                        return <span>{new Date(rowData.timestamp.epochMillis).toISOString()}</span>;
                      },
                    },
                    {
                      title: intl.formatMessage({ id: 'atk-list.header.user' }),
                      field: 'resource.userId',
                      defaultSort: 'desc',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '20%',
                      },
                    },
                    {
                      title: intl.formatMessage({ id: 'atk-list.header.last-status' }),
                      field: 'lastStatus',
                      defaultSort: 'desc',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '20%',
                      },
                    },
                  ]}
                  data={formData}
                  onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                    this.props.atkListStore.changeOrder(orderBy, orderDirection);
                  }}
                  isLoading={isLoading}
                  options={{
                    actionsColumnIndex: 0,
                    paging: true,
                    sorting: true,
                    draggable: false,
                    search: false,
                    pageSize: pageSize,
                    pageSizeOptions: [pageSize],
                    minBodyHeight: bodyHeight,
                    maxBodyHeight: bodyHeight,
                    thirdSortClick: false,
                  }}
                  localization={{
                    header: {
                      actions: '',
                    },
                  }}
                  onRowClick={(event, rowData) => {
                    if (!(event.target instanceof HTMLInputElement)) {
                      this.navigateCirculationReportSummary(rowData.resource.id);
                    }
                  }}
                  components={{
                    Toolbar: (props) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginTop: '20px',
                          marginRight: '20px',
                        }}
                      >
                        <div style={{ minWidth: '300px' }}>
                          <MTableToolbar {...props} />
                        </div>
                        <div style={{ marginRight: '15px', marginTop: '15px', float: 'right' }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon color="secondary" />}
                            disabled={this.state.createCirculationReportForm.isOpen}
                            onClick={() => this.openCirculationReportForm()}
                          >
                            {intl.formatMessage({ id: 'button.create' })}
                          </Button>
                        </div>
                      </div>
                    ),
                  }}
                />
              </Paper>
            </div>
            {this.state.createCirculationReportForm && (
              <CreateCirculationReportForm
                intl={intl}
                closeModal={this.closeModal}
                handleChange={this.handleChange}
                handleBlur={this.handleBlur}
                handleFocus={this.handleFocus}
                handleSubmit={this.handleSubmit}
                data={this.state.createCirculationReportForm}
              />
            )}
          </div>
        )}
      />
    );
  }
}

const CreateCirculationReportForm: React.FunctionComponent<CreateCirculationReportFormProps> = ({
  data,
  handleChange,
  handleBlur,
  handleFocus,
  handleSubmit,
  closeModal,
  intl,
}) => {
  const isOpen = data.isOpen;
  const values = data.isOpen && data.values;
  const errors = data.isOpen && data.errors;
  const touched = data.isOpen && data.touched;
  const submitButtonActive = data.isOpen && data.submitButtonActive;

  return (
    <Dialog open={isOpen} onClose={closeModal} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md">
      <DialogTitle id="form-dialog-title">{intl.formatMessage({ id: 'circulation-report-form.title' })}</DialogTitle>
      <DialogContent
        style={{
          display: 'grid',
          gridTemplate: 'auto / 1fr 1fr',
          gridColumnGap: '20px',
          gridAutoRows: 'auto / 1fr 1fr',
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.id, e.target.value)}
        onBlur={(e) => handleBlur(e.target.id)}
        onFocus={(e) => handleFocus(e.target.id)}
      >
        {!productGroupIsWaterBeerBio && (
          <FormTextField id="vsdNumber" label="VSD Number" values={values} errors={errors} touched={touched} />
        )}
        <FormTextField id="tradeParticipantInn" label="Tax Number" values={values} errors={errors} touched={touched} />
        <FormTextField
          id="declarationNumber"
          values={values}
          errors={errors}
          touched={touched}
          label="Declaration Number"
        />
        <FormDateField
          id="declarationDate"
          label="Declaration date"
          values={values}
          errors={errors}
          touched={touched}
        />
        <FormDateField id="productionDate" label="Production date" values={values} errors={errors} touched={touched} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          {intl.formatMessage({ id: 'button.cancel' })}
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={!submitButtonActive}>
          {intl.formatMessage({ id: 'button.submit' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ATKCirculationReportsView = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, STORE_ID)(observer(ATKCirculationReportsViewImpl)),
);
