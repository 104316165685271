/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAggregatedCustomsDocument,
    KvintaAggregatedCustomsDocumentFromJSON,
    KvintaAggregatedCustomsDocumentFromJSONTyped,
    KvintaAggregatedCustomsDocumentToJSON,
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestAggregatedCustomsDocument
 */
export interface KvintaOperationRequestAggregatedCustomsDocument {
    /**
     * 
     * @type {KvintaAggregatedCustomsDocument}
     * @memberof KvintaOperationRequestAggregatedCustomsDocument
     */
    input?: KvintaAggregatedCustomsDocument;
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestAggregatedCustomsDocument
     */
    context?: KvintaExecutionContext;
}

export function KvintaOperationRequestAggregatedCustomsDocumentFromJSON(json: any): KvintaOperationRequestAggregatedCustomsDocument {
    return KvintaOperationRequestAggregatedCustomsDocumentFromJSONTyped(json, false);
}

export function KvintaOperationRequestAggregatedCustomsDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestAggregatedCustomsDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'input': !exists(json, 'input') ? undefined : KvintaAggregatedCustomsDocumentFromJSON(json['input']),
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
    };
}

export function KvintaOperationRequestAggregatedCustomsDocumentToJSON(value?: KvintaOperationRequestAggregatedCustomsDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'input': KvintaAggregatedCustomsDocumentToJSON(value.input),
        'context': KvintaExecutionContextToJSON(value.context),
    };
}


