/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaInlineObject,
    KvintaInlineObjectFromJSON,
    KvintaInlineObjectToJSON,
    KvintaOperationRequestAggregatedCustomsDocumentExternalNumber,
    KvintaOperationRequestAggregatedCustomsDocumentExternalNumberFromJSON,
    KvintaOperationRequestAggregatedCustomsDocumentExternalNumberToJSON,
    KvintaOperationRequestAggregatedCustomsDocumentStatus,
    KvintaOperationRequestAggregatedCustomsDocumentStatusFromJSON,
    KvintaOperationRequestAggregatedCustomsDocumentStatusToJSON,
    KvintaOperationRequestBatchFindChildTextDocumentsRecordsRequest,
    KvintaOperationRequestBatchFindChildTextDocumentsRecordsRequestFromJSON,
    KvintaOperationRequestBatchFindChildTextDocumentsRecordsRequestToJSON,
    KvintaOperationRequestCreateDeliveryItemRequest,
    KvintaOperationRequestCreateDeliveryItemRequestFromJSON,
    KvintaOperationRequestCreateDeliveryItemRequestToJSON,
    KvintaOperationRequestCreateDeliveryNoteRequest,
    KvintaOperationRequestCreateDeliveryNoteRequestFromJSON,
    KvintaOperationRequestCreateDeliveryNoteRequestToJSON,
    KvintaOperationRequestCreateSerialNumberGenerationProfileRequest,
    KvintaOperationRequestCreateSerialNumberGenerationProfileRequestFromJSON,
    KvintaOperationRequestCreateSerialNumberGenerationProfileRequestToJSON,
    KvintaOperationRequestCreateSerialNumberOrderItemRequest,
    KvintaOperationRequestCreateSerialNumberOrderItemRequestFromJSON,
    KvintaOperationRequestCreateSerialNumberOrderItemRequestToJSON,
    KvintaOperationRequestCreateSerialNumberOrderRequest,
    KvintaOperationRequestCreateSerialNumberOrderRequestFromJSON,
    KvintaOperationRequestCreateSerialNumberOrderRequestToJSON,
    KvintaOperationRequestCreateSerialNumberRequest,
    KvintaOperationRequestCreateSerialNumberRequestFromJSON,
    KvintaOperationRequestCreateSerialNumberRequestToJSON,
    KvintaOperationRequestDeliveryItem,
    KvintaOperationRequestDeliveryItemFromJSON,
    KvintaOperationRequestDeliveryItemToJSON,
    KvintaOperationRequestFindChildTextDocumentsRecordsRequest,
    KvintaOperationRequestFindChildTextDocumentsRecordsRequestFromJSON,
    KvintaOperationRequestFindChildTextDocumentsRecordsRequestToJSON,
    KvintaOperationRequestFindSgtinListRequest,
    KvintaOperationRequestFindSgtinListRequestFromJSON,
    KvintaOperationRequestFindSgtinListRequestToJSON,
    KvintaOperationRequestInsertSerialNumbersRequest,
    KvintaOperationRequestInsertSerialNumbersRequestFromJSON,
    KvintaOperationRequestInsertSerialNumbersRequestToJSON,
    KvintaOperationRequestListAggregatedCustomsDocumentsRequest,
    KvintaOperationRequestListAggregatedCustomsDocumentsRequestFromJSON,
    KvintaOperationRequestListAggregatedCustomsDocumentsRequestToJSON,
    KvintaOperationRequestListDeliveryItemsRequest,
    KvintaOperationRequestListDeliveryItemsRequestFromJSON,
    KvintaOperationRequestListDeliveryItemsRequestToJSON,
    KvintaOperationRequestListDeliveryNotesRequest,
    KvintaOperationRequestListDeliveryNotesRequestFromJSON,
    KvintaOperationRequestListDeliveryNotesRequestToJSON,
    KvintaOperationRequestListIssuerSystemSerialNumber,
    KvintaOperationRequestListIssuerSystemSerialNumberFromJSON,
    KvintaOperationRequestListIssuerSystemSerialNumberToJSON,
    KvintaOperationRequestListSerialNumberGenerationProfilesRequest,
    KvintaOperationRequestListSerialNumberGenerationProfilesRequestFromJSON,
    KvintaOperationRequestListSerialNumberGenerationProfilesRequestToJSON,
    KvintaOperationRequestListSerialNumberOrderItemsRequest,
    KvintaOperationRequestListSerialNumberOrderItemsRequestFromJSON,
    KvintaOperationRequestListSerialNumberOrderItemsRequestToJSON,
    KvintaOperationRequestListSerialNumberOrdersRequest,
    KvintaOperationRequestListSerialNumberOrdersRequestFromJSON,
    KvintaOperationRequestListSerialNumberOrdersRequestToJSON,
    KvintaOperationRequestListSerialNumbersRequest,
    KvintaOperationRequestListSerialNumbersRequestFromJSON,
    KvintaOperationRequestListSerialNumbersRequestToJSON,
    KvintaOperationRequestListString,
    KvintaOperationRequestListStringFromJSON,
    KvintaOperationRequestListStringToJSON,
    KvintaOperationRequestListTextDocumentsRequest,
    KvintaOperationRequestListTextDocumentsRequestFromJSON,
    KvintaOperationRequestListTextDocumentsRequestToJSON,
    KvintaOperationRequestListUsedSerialNumbersRequest,
    KvintaOperationRequestListUsedSerialNumbersRequestFromJSON,
    KvintaOperationRequestListUsedSerialNumbersRequestToJSON,
    KvintaOperationRequestReadTextDocumentsExternalNumbersRequest,
    KvintaOperationRequestReadTextDocumentsExternalNumbersRequestFromJSON,
    KvintaOperationRequestReadTextDocumentsExternalNumbersRequestToJSON,
    KvintaOperationRequestReleaseSerialNumberOrderRequest,
    KvintaOperationRequestReleaseSerialNumberOrderRequestFromJSON,
    KvintaOperationRequestReleaseSerialNumberOrderRequestToJSON,
    KvintaOperationRequestRemoveCryptoTailBatchRequest,
    KvintaOperationRequestRemoveCryptoTailBatchRequestFromJSON,
    KvintaOperationRequestRemoveCryptoTailBatchRequestToJSON,
    KvintaOperationRequestResource,
    KvintaOperationRequestResourceFromJSON,
    KvintaOperationRequestResourceToJSON,
    KvintaOperationRequestSerialNumberOrder,
    KvintaOperationRequestSerialNumberOrderFromJSON,
    KvintaOperationRequestSerialNumberOrderToJSON,
    KvintaOperationRequestSerialNumberOrderItem,
    KvintaOperationRequestSerialNumberOrderItemFromJSON,
    KvintaOperationRequestSerialNumberOrderItemToJSON,
    KvintaOperationRequestSerialNumberOrderStatus,
    KvintaOperationRequestSerialNumberOrderStatusFromJSON,
    KvintaOperationRequestSerialNumberOrderStatusToJSON,
    KvintaOperationRequestString,
    KvintaOperationRequestStringFromJSON,
    KvintaOperationRequestStringToJSON,
    KvintaOperationRequestTextDocumentExternalNumber,
    KvintaOperationRequestTextDocumentExternalNumberFromJSON,
    KvintaOperationRequestTextDocumentExternalNumberToJSON,
    KvintaOperationRequestTypeAndSha256,
    KvintaOperationRequestTypeAndSha256FromJSON,
    KvintaOperationRequestTypeAndSha256ToJSON,
    KvintaOperationRequestUpdateUsedSerialNumbersRequest,
    KvintaOperationRequestUpdateUsedSerialNumbersRequestFromJSON,
    KvintaOperationRequestUpdateUsedSerialNumbersRequestToJSON,
    KvintaOperationRequestWriteTextDocumentRequest,
    KvintaOperationRequestWriteTextDocumentRequestFromJSON,
    KvintaOperationRequestWriteTextDocumentRequestToJSON,
    KvintaOperationResponseAggregatedCustomsDocumentExternalNumber,
    KvintaOperationResponseAggregatedCustomsDocumentExternalNumberFromJSON,
    KvintaOperationResponseAggregatedCustomsDocumentExternalNumberToJSON,
    KvintaOperationResponseAggregatedCustomsDocumentRecord,
    KvintaOperationResponseAggregatedCustomsDocumentRecordFromJSON,
    KvintaOperationResponseAggregatedCustomsDocumentRecordToJSON,
    KvintaOperationResponseAggregatedCustomsDocumentStatus,
    KvintaOperationResponseAggregatedCustomsDocumentStatusFromJSON,
    KvintaOperationResponseAggregatedCustomsDocumentStatusToJSON,
    KvintaOperationResponseChildTextDocumentRecordsByParent,
    KvintaOperationResponseChildTextDocumentRecordsByParentFromJSON,
    KvintaOperationResponseChildTextDocumentRecordsByParentToJSON,
    KvintaOperationResponseDeliveryItem,
    KvintaOperationResponseDeliveryItemFromJSON,
    KvintaOperationResponseDeliveryItemToJSON,
    KvintaOperationResponseDeliveryNote,
    KvintaOperationResponseDeliveryNoteFromJSON,
    KvintaOperationResponseDeliveryNoteToJSON,
    KvintaOperationResponseFindSgtinListResponse,
    KvintaOperationResponseFindSgtinListResponseFromJSON,
    KvintaOperationResponseFindSgtinListResponseToJSON,
    KvintaOperationResponseGetListResultAggregatedCustomsDocumentRecord,
    KvintaOperationResponseGetListResultAggregatedCustomsDocumentRecordFromJSON,
    KvintaOperationResponseGetListResultAggregatedCustomsDocumentRecordToJSON,
    KvintaOperationResponseGetListResultDeliveryItem,
    KvintaOperationResponseGetListResultDeliveryItemFromJSON,
    KvintaOperationResponseGetListResultDeliveryItemToJSON,
    KvintaOperationResponseGetListResultDeliveryNote,
    KvintaOperationResponseGetListResultDeliveryNoteFromJSON,
    KvintaOperationResponseGetListResultDeliveryNoteToJSON,
    KvintaOperationResponseGetListResultSerialNumber,
    KvintaOperationResponseGetListResultSerialNumberFromJSON,
    KvintaOperationResponseGetListResultSerialNumberToJSON,
    KvintaOperationResponseGetListResultSerialNumberGenerationProfile,
    KvintaOperationResponseGetListResultSerialNumberGenerationProfileFromJSON,
    KvintaOperationResponseGetListResultSerialNumberGenerationProfileToJSON,
    KvintaOperationResponseGetListResultSerialNumberOrder,
    KvintaOperationResponseGetListResultSerialNumberOrderFromJSON,
    KvintaOperationResponseGetListResultSerialNumberOrderToJSON,
    KvintaOperationResponseGetListResultSerialNumberOrderItem,
    KvintaOperationResponseGetListResultSerialNumberOrderItemFromJSON,
    KvintaOperationResponseGetListResultSerialNumberOrderItemToJSON,
    KvintaOperationResponseGetListResultTextDocumentRecord,
    KvintaOperationResponseGetListResultTextDocumentRecordFromJSON,
    KvintaOperationResponseGetListResultTextDocumentRecordToJSON,
    KvintaOperationResponseGetListResultUsedSerialNumber,
    KvintaOperationResponseGetListResultUsedSerialNumberFromJSON,
    KvintaOperationResponseGetListResultUsedSerialNumberToJSON,
    KvintaOperationResponseInteger,
    KvintaOperationResponseIntegerFromJSON,
    KvintaOperationResponseIntegerToJSON,
    KvintaOperationResponseListAggregatedCustomsDocumentRecord,
    KvintaOperationResponseListAggregatedCustomsDocumentRecordFromJSON,
    KvintaOperationResponseListAggregatedCustomsDocumentRecordToJSON,
    KvintaOperationResponseListFindUnusedSerialNumbersRow,
    KvintaOperationResponseListFindUnusedSerialNumbersRowFromJSON,
    KvintaOperationResponseListFindUnusedSerialNumbersRowToJSON,
    KvintaOperationResponseListSerialNumber,
    KvintaOperationResponseListSerialNumberFromJSON,
    KvintaOperationResponseListSerialNumberToJSON,
    KvintaOperationResponseListUsedSerialNumber,
    KvintaOperationResponseListUsedSerialNumberFromJSON,
    KvintaOperationResponseListUsedSerialNumberToJSON,
    KvintaOperationResponseLong,
    KvintaOperationResponseLongFromJSON,
    KvintaOperationResponseLongToJSON,
    KvintaOperationResponseMapStringString,
    KvintaOperationResponseMapStringStringFromJSON,
    KvintaOperationResponseMapStringStringToJSON,
    KvintaOperationResponseResource,
    KvintaOperationResponseResourceFromJSON,
    KvintaOperationResponseResourceToJSON,
    KvintaOperationResponseSerialNumber,
    KvintaOperationResponseSerialNumberFromJSON,
    KvintaOperationResponseSerialNumberToJSON,
    KvintaOperationResponseSerialNumberGenerationProfile,
    KvintaOperationResponseSerialNumberGenerationProfileFromJSON,
    KvintaOperationResponseSerialNumberGenerationProfileToJSON,
    KvintaOperationResponseSerialNumberOrder,
    KvintaOperationResponseSerialNumberOrderFromJSON,
    KvintaOperationResponseSerialNumberOrderToJSON,
    KvintaOperationResponseSerialNumberOrderItem,
    KvintaOperationResponseSerialNumberOrderItemFromJSON,
    KvintaOperationResponseSerialNumberOrderItemToJSON,
    KvintaOperationResponseSerialNumberOrderStatus,
    KvintaOperationResponseSerialNumberOrderStatusFromJSON,
    KvintaOperationResponseSerialNumberOrderStatusToJSON,
    KvintaOperationResponseString,
    KvintaOperationResponseStringFromJSON,
    KvintaOperationResponseStringToJSON,
    KvintaOperationResponseTextDocument,
    KvintaOperationResponseTextDocumentFromJSON,
    KvintaOperationResponseTextDocumentToJSON,
    KvintaOperationResponseTextDocumentExternalNumber,
    KvintaOperationResponseTextDocumentExternalNumberFromJSON,
    KvintaOperationResponseTextDocumentExternalNumberToJSON,
    KvintaOperationResponseTextDocumentRecord,
    KvintaOperationResponseTextDocumentRecordFromJSON,
    KvintaOperationResponseTextDocumentRecordToJSON,
    KvintaStreamedFile,
    KvintaStreamedFileFromJSON,
    KvintaStreamedFileToJSON,
} from '../models';

export interface BatchFindChildTextDocumentsRecordsRequest {
    kvintaOperationRequestBatchFindChildTextDocumentsRecordsRequest: KvintaOperationRequestBatchFindChildTextDocumentsRecordsRequest;
}

export interface CountSerialNumbersByOrderIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface CreateAggregatedCustomsDocumentStatusRequest {
    kvintaOperationRequestAggregatedCustomsDocumentStatus: KvintaOperationRequestAggregatedCustomsDocumentStatus;
}

export interface CreateDeliveryItemRequest {
    kvintaOperationRequestCreateDeliveryItemRequest: KvintaOperationRequestCreateDeliveryItemRequest;
}

export interface CreateDeliveryNoteRequest {
    kvintaOperationRequestCreateDeliveryNoteRequest: KvintaOperationRequestCreateDeliveryNoteRequest;
}

export interface CreateSerialNumberGenerationProfileRequest {
    kvintaOperationRequestCreateSerialNumberGenerationProfileRequest: KvintaOperationRequestCreateSerialNumberGenerationProfileRequest;
}

export interface CreateSerialNumberOrderRequest {
    kvintaOperationRequestCreateSerialNumberOrderRequest: KvintaOperationRequestCreateSerialNumberOrderRequest;
}

export interface CreateSerialNumberOrderItemRequest {
    kvintaOperationRequestCreateSerialNumberOrderItemRequest: KvintaOperationRequestCreateSerialNumberOrderItemRequest;
}

export interface CreateSerialNumberOrderStatusRequest {
    kvintaOperationRequestSerialNumberOrderStatus: KvintaOperationRequestSerialNumberOrderStatus;
}

export interface DeleteDeliveryItemRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface DeleteDeliveryNoteRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface DeleteSerialNumberRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface DeleteSerialNumberGenerationProfileRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface DeleteSerialNumberOrderRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface DeleteSerialNumberOrderItemRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface DownloadSerialNumbersCsvRequest {
    orderId: string;
}

export interface DownloadSerialNumbersCsvZipRequest {
    orderId: string;
}

export interface DownloadSerialNumbersForDirectPrintingRequest {
    orderId: string;
}

export interface FindChildTextDocumentsRecordsRequest {
    kvintaOperationRequestFindChildTextDocumentsRecordsRequest: KvintaOperationRequestFindChildTextDocumentsRecordsRequest;
}

export interface FindUnusedSgtinsRequest {
    kvintaOperationRequestFindSgtinListRequest: KvintaOperationRequestFindSgtinListRequest;
}

export interface FindUnusedSgtinsAndMarkUsedRequest {
    kvintaOperationRequestFindSgtinListRequest: KvintaOperationRequestFindSgtinListRequest;
}

export interface ListAggregatedCustomsDocumentsRequest {
    kvintaOperationRequestListAggregatedCustomsDocumentsRequest: KvintaOperationRequestListAggregatedCustomsDocumentsRequest;
}

export interface ListAggregatedCustomsDocumentsByEventIdRequest {
    kvintaOperationRequestListString: KvintaOperationRequestListString;
}

export interface ListDeliveryItemsRequest {
    kvintaOperationRequestListDeliveryItemsRequest: KvintaOperationRequestListDeliveryItemsRequest;
}

export interface ListDeliveryNotesRequest {
    kvintaOperationRequestListDeliveryNotesRequest: KvintaOperationRequestListDeliveryNotesRequest;
}

export interface ListSerialNumberGenerationProfilesRequest {
    kvintaOperationRequestListSerialNumberGenerationProfilesRequest: KvintaOperationRequestListSerialNumberGenerationProfilesRequest;
}

export interface ListSerialNumberOrderItemsRequest {
    kvintaOperationRequestListSerialNumberOrderItemsRequest: KvintaOperationRequestListSerialNumberOrderItemsRequest;
}

export interface ListSerialNumberOrdersRequest {
    kvintaOperationRequestListSerialNumberOrdersRequest: KvintaOperationRequestListSerialNumberOrdersRequest;
}

export interface ListSerialNumbersRequest {
    kvintaOperationRequestListSerialNumbersRequest: KvintaOperationRequestListSerialNumbersRequest;
}

export interface ListTextDocumentsRequest {
    kvintaOperationRequestListTextDocumentsRequest: KvintaOperationRequestListTextDocumentsRequest;
}

export interface ListUsedSerialNumbersRequest {
    kvintaOperationRequestListUsedSerialNumbersRequest: KvintaOperationRequestListUsedSerialNumbersRequest;
}

export interface ReadAggregatedCustomsDocumentRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadDeliveryItemByIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadDeliveryNoteByIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadResourceRequest {
    kvintaOperationRequestResource: KvintaOperationRequestResource;
}

export interface ReadSerialNumberByIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadSerialNumberGenerationProfileByIdRequest {
    kvintaInlineObject: KvintaInlineObject;
}

export interface ReadSerialNumberOrderByIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadSerialNumberOrderItemRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadTextDocumentRequest {
    kvintaOperationRequestResource: KvintaOperationRequestResource;
}

export interface ReadTextDocumentExternalNumberRequest {
    kvintaOperationRequestResource: KvintaOperationRequestResource;
}

export interface ReadTextDocumentRecordRequest {
    kvintaOperationRequestResource: KvintaOperationRequestResource;
}

export interface ReadTextDocumentRecordBySha256Request {
    kvintaOperationRequestTypeAndSha256: KvintaOperationRequestTypeAndSha256;
}

export interface ReadTextDocumentWithStatusesRequest {
    kvintaOperationRequestResource: KvintaOperationRequestResource;
}

export interface ReadTextDocumentsExternalNumbersRequest {
    kvintaOperationRequestReadTextDocumentsExternalNumbersRequest: KvintaOperationRequestReadTextDocumentsExternalNumbersRequest;
}

export interface ReleaseSerialNumberOrderRequest {
    kvintaOperationRequestReleaseSerialNumberOrderRequest: KvintaOperationRequestReleaseSerialNumberOrderRequest;
}

export interface ReleaseTextDocumentRequest {
    kvintaOperationRequestResource: KvintaOperationRequestResource;
}

export interface RemoveCryptoTailsRequest {
    kvintaOperationRequestRemoveCryptoTailBatchRequest: KvintaOperationRequestRemoveCryptoTailBatchRequest;
}

export interface RemoveCryptoTailsRRequest {
    kvintaOperationRequestResource: KvintaOperationRequestResource;
}

export interface UpdateDeliveryItemRequest {
    kvintaOperationRequestDeliveryItem: KvintaOperationRequestDeliveryItem;
}

export interface UpdateSerialNumberOrderRequest {
    kvintaOperationRequestSerialNumberOrder: KvintaOperationRequestSerialNumberOrder;
}

export interface UpdateSerialNumberOrderItemRequest {
    kvintaOperationRequestSerialNumberOrderItem: KvintaOperationRequestSerialNumberOrderItem;
}

export interface UpdateUsedSerialNumbersRequest {
    kvintaOperationRequestUpdateUsedSerialNumbersRequest: KvintaOperationRequestUpdateUsedSerialNumbersRequest;
}

export interface UploadRequest {
    orderId: string;
    file: Blob;
}

export interface WriteAggregatedCustomsDocumentExternalNumberRequest {
    kvintaOperationRequestAggregatedCustomsDocumentExternalNumber: KvintaOperationRequestAggregatedCustomsDocumentExternalNumber;
}

export interface WriteIssuerSystemSerialNumbersRequest {
    kvintaOperationRequestListIssuerSystemSerialNumber: KvintaOperationRequestListIssuerSystemSerialNumber;
}

export interface WriteSerialNumberRequest {
    kvintaOperationRequestCreateSerialNumberRequest: KvintaOperationRequestCreateSerialNumberRequest;
}

export interface WriteSerialNumbersRequest {
    kvintaOperationRequestInsertSerialNumbersRequest: KvintaOperationRequestInsertSerialNumbersRequest;
}

export interface WriteSerialNumbersRRequest {
    kvintaOperationRequestResource: KvintaOperationRequestResource;
}

export interface WriteTextDocumentRequest {
    kvintaOperationRequestWriteTextDocumentRequest: KvintaOperationRequestWriteTextDocumentRequest;
}

export interface WriteTextDocumentExternalNumberRequest {
    kvintaOperationRequestTextDocumentExternalNumber: KvintaOperationRequestTextDocumentExternalNumber;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async batchFindChildTextDocumentsRecordsRaw(requestParameters: BatchFindChildTextDocumentsRecordsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseChildTextDocumentRecordsByParent>> {
        if (requestParameters.kvintaOperationRequestBatchFindChildTextDocumentsRecordsRequest === null || requestParameters.kvintaOperationRequestBatchFindChildTextDocumentsRecordsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestBatchFindChildTextDocumentsRecordsRequest','Required parameter requestParameters.kvintaOperationRequestBatchFindChildTextDocumentsRecordsRequest was null or undefined when calling batchFindChildTextDocumentsRecords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/batchFindChildTextDocumentsRecords`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestBatchFindChildTextDocumentsRecordsRequestToJSON(requestParameters.kvintaOperationRequestBatchFindChildTextDocumentsRecordsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseChildTextDocumentRecordsByParentFromJSON(jsonValue));
    }

    /**
     */
    async batchFindChildTextDocumentsRecords(requestParameters: BatchFindChildTextDocumentsRecordsRequest): Promise<KvintaOperationResponseChildTextDocumentRecordsByParent> {
        const response = await this.batchFindChildTextDocumentsRecordsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async countSerialNumbersByOrderIdRaw(requestParameters: CountSerialNumbersByOrderIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseLong>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling countSerialNumbersByOrderId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/countSerialNumbersByOrderId`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseLongFromJSON(jsonValue));
    }

    /**
     */
    async countSerialNumbersByOrderId(requestParameters: CountSerialNumbersByOrderIdRequest): Promise<KvintaOperationResponseLong> {
        const response = await this.countSerialNumbersByOrderIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createAggregatedCustomsDocumentStatusRaw(requestParameters: CreateAggregatedCustomsDocumentStatusRequest): Promise<runtime.ApiResponse<KvintaOperationResponseAggregatedCustomsDocumentStatus>> {
        if (requestParameters.kvintaOperationRequestAggregatedCustomsDocumentStatus === null || requestParameters.kvintaOperationRequestAggregatedCustomsDocumentStatus === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestAggregatedCustomsDocumentStatus','Required parameter requestParameters.kvintaOperationRequestAggregatedCustomsDocumentStatus was null or undefined when calling createAggregatedCustomsDocumentStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/createAggregatedCustomsDocumentStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestAggregatedCustomsDocumentStatusToJSON(requestParameters.kvintaOperationRequestAggregatedCustomsDocumentStatus),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseAggregatedCustomsDocumentStatusFromJSON(jsonValue));
    }

    /**
     */
    async createAggregatedCustomsDocumentStatus(requestParameters: CreateAggregatedCustomsDocumentStatusRequest): Promise<KvintaOperationResponseAggregatedCustomsDocumentStatus> {
        const response = await this.createAggregatedCustomsDocumentStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createDeliveryItemRaw(requestParameters: CreateDeliveryItemRequest): Promise<runtime.ApiResponse<KvintaOperationResponseDeliveryItem>> {
        if (requestParameters.kvintaOperationRequestCreateDeliveryItemRequest === null || requestParameters.kvintaOperationRequestCreateDeliveryItemRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCreateDeliveryItemRequest','Required parameter requestParameters.kvintaOperationRequestCreateDeliveryItemRequest was null or undefined when calling createDeliveryItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/createDeliveryItem`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCreateDeliveryItemRequestToJSON(requestParameters.kvintaOperationRequestCreateDeliveryItemRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseDeliveryItemFromJSON(jsonValue));
    }

    /**
     */
    async createDeliveryItem(requestParameters: CreateDeliveryItemRequest): Promise<KvintaOperationResponseDeliveryItem> {
        const response = await this.createDeliveryItemRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createDeliveryNoteRaw(requestParameters: CreateDeliveryNoteRequest): Promise<runtime.ApiResponse<KvintaOperationResponseDeliveryNote>> {
        if (requestParameters.kvintaOperationRequestCreateDeliveryNoteRequest === null || requestParameters.kvintaOperationRequestCreateDeliveryNoteRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCreateDeliveryNoteRequest','Required parameter requestParameters.kvintaOperationRequestCreateDeliveryNoteRequest was null or undefined when calling createDeliveryNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/createDeliveryNote`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCreateDeliveryNoteRequestToJSON(requestParameters.kvintaOperationRequestCreateDeliveryNoteRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseDeliveryNoteFromJSON(jsonValue));
    }

    /**
     */
    async createDeliveryNote(requestParameters: CreateDeliveryNoteRequest): Promise<KvintaOperationResponseDeliveryNote> {
        const response = await this.createDeliveryNoteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createSerialNumberGenerationProfileRaw(requestParameters: CreateSerialNumberGenerationProfileRequest): Promise<runtime.ApiResponse<KvintaOperationResponseSerialNumberGenerationProfile>> {
        if (requestParameters.kvintaOperationRequestCreateSerialNumberGenerationProfileRequest === null || requestParameters.kvintaOperationRequestCreateSerialNumberGenerationProfileRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCreateSerialNumberGenerationProfileRequest','Required parameter requestParameters.kvintaOperationRequestCreateSerialNumberGenerationProfileRequest was null or undefined when calling createSerialNumberGenerationProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/createSerialNumberGenerationProfile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCreateSerialNumberGenerationProfileRequestToJSON(requestParameters.kvintaOperationRequestCreateSerialNumberGenerationProfileRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseSerialNumberGenerationProfileFromJSON(jsonValue));
    }

    /**
     */
    async createSerialNumberGenerationProfile(requestParameters: CreateSerialNumberGenerationProfileRequest): Promise<KvintaOperationResponseSerialNumberGenerationProfile> {
        const response = await this.createSerialNumberGenerationProfileRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createSerialNumberOrderRaw(requestParameters: CreateSerialNumberOrderRequest): Promise<runtime.ApiResponse<KvintaOperationResponseSerialNumberOrder>> {
        if (requestParameters.kvintaOperationRequestCreateSerialNumberOrderRequest === null || requestParameters.kvintaOperationRequestCreateSerialNumberOrderRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCreateSerialNumberOrderRequest','Required parameter requestParameters.kvintaOperationRequestCreateSerialNumberOrderRequest was null or undefined when calling createSerialNumberOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/createSerialNumberOrder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCreateSerialNumberOrderRequestToJSON(requestParameters.kvintaOperationRequestCreateSerialNumberOrderRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseSerialNumberOrderFromJSON(jsonValue));
    }

    /**
     */
    async createSerialNumberOrder(requestParameters: CreateSerialNumberOrderRequest): Promise<KvintaOperationResponseSerialNumberOrder> {
        const response = await this.createSerialNumberOrderRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createSerialNumberOrderItemRaw(requestParameters: CreateSerialNumberOrderItemRequest): Promise<runtime.ApiResponse<KvintaOperationResponseSerialNumberOrderItem>> {
        if (requestParameters.kvintaOperationRequestCreateSerialNumberOrderItemRequest === null || requestParameters.kvintaOperationRequestCreateSerialNumberOrderItemRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCreateSerialNumberOrderItemRequest','Required parameter requestParameters.kvintaOperationRequestCreateSerialNumberOrderItemRequest was null or undefined when calling createSerialNumberOrderItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/createSerialNumberOrderItem`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCreateSerialNumberOrderItemRequestToJSON(requestParameters.kvintaOperationRequestCreateSerialNumberOrderItemRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseSerialNumberOrderItemFromJSON(jsonValue));
    }

    /**
     */
    async createSerialNumberOrderItem(requestParameters: CreateSerialNumberOrderItemRequest): Promise<KvintaOperationResponseSerialNumberOrderItem> {
        const response = await this.createSerialNumberOrderItemRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createSerialNumberOrderStatusRaw(requestParameters: CreateSerialNumberOrderStatusRequest): Promise<runtime.ApiResponse<KvintaOperationResponseSerialNumberOrderStatus>> {
        if (requestParameters.kvintaOperationRequestSerialNumberOrderStatus === null || requestParameters.kvintaOperationRequestSerialNumberOrderStatus === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestSerialNumberOrderStatus','Required parameter requestParameters.kvintaOperationRequestSerialNumberOrderStatus was null or undefined when calling createSerialNumberOrderStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/createSerialNumberOrderStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestSerialNumberOrderStatusToJSON(requestParameters.kvintaOperationRequestSerialNumberOrderStatus),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseSerialNumberOrderStatusFromJSON(jsonValue));
    }

    /**
     */
    async createSerialNumberOrderStatus(requestParameters: CreateSerialNumberOrderStatusRequest): Promise<KvintaOperationResponseSerialNumberOrderStatus> {
        const response = await this.createSerialNumberOrderStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteDeliveryItemRaw(requestParameters: DeleteDeliveryItemRequest): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling deleteDeliveryItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deleteDeliveryItem`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     */
    async deleteDeliveryItem(requestParameters: DeleteDeliveryItemRequest): Promise<KvintaOperationResponseInteger> {
        const response = await this.deleteDeliveryItemRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteDeliveryNoteRaw(requestParameters: DeleteDeliveryNoteRequest): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling deleteDeliveryNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deleteDeliveryNote`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     */
    async deleteDeliveryNote(requestParameters: DeleteDeliveryNoteRequest): Promise<KvintaOperationResponseInteger> {
        const response = await this.deleteDeliveryNoteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteSerialNumberRaw(requestParameters: DeleteSerialNumberRequest): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling deleteSerialNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deleteSerialNumber`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     */
    async deleteSerialNumber(requestParameters: DeleteSerialNumberRequest): Promise<KvintaOperationResponseInteger> {
        const response = await this.deleteSerialNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteSerialNumberGenerationProfileRaw(requestParameters: DeleteSerialNumberGenerationProfileRequest): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling deleteSerialNumberGenerationProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deleteSerialNumberGenerationProfile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     */
    async deleteSerialNumberGenerationProfile(requestParameters: DeleteSerialNumberGenerationProfileRequest): Promise<KvintaOperationResponseInteger> {
        const response = await this.deleteSerialNumberGenerationProfileRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteSerialNumberOrderRaw(requestParameters: DeleteSerialNumberOrderRequest): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling deleteSerialNumberOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deleteSerialNumberOrder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     */
    async deleteSerialNumberOrder(requestParameters: DeleteSerialNumberOrderRequest): Promise<KvintaOperationResponseInteger> {
        const response = await this.deleteSerialNumberOrderRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteSerialNumberOrderItemRaw(requestParameters: DeleteSerialNumberOrderItemRequest): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling deleteSerialNumberOrderItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deleteSerialNumberOrderItem`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     */
    async deleteSerialNumberOrderItem(requestParameters: DeleteSerialNumberOrderItemRequest): Promise<KvintaOperationResponseInteger> {
        const response = await this.deleteSerialNumberOrderItemRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async downloadSerialNumbersCsvRaw(requestParameters: DownloadSerialNumbersCsvRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling downloadSerialNumbersCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/serial-numbers/download/for-order/{orderId}/csv`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async downloadSerialNumbersCsv(requestParameters: DownloadSerialNumbersCsvRequest): Promise<string> {
        const response = await this.downloadSerialNumbersCsvRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async downloadSerialNumbersCsvZipRaw(requestParameters: DownloadSerialNumbersCsvZipRequest): Promise<runtime.ApiResponse<KvintaStreamedFile>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling downloadSerialNumbersCsvZip.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/serial-numbers/download/for-order/{orderId}/csv/zip`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaStreamedFileFromJSON(jsonValue));
    }

    /**
     */
    async downloadSerialNumbersCsvZip(requestParameters: DownloadSerialNumbersCsvZipRequest): Promise<KvintaStreamedFile> {
        const response = await this.downloadSerialNumbersCsvZipRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async downloadSerialNumbersForDirectPrintingRaw(requestParameters: DownloadSerialNumbersForDirectPrintingRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling downloadSerialNumbersForDirectPrinting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/serial-numbers/download/for-direct-printing/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async downloadSerialNumbersForDirectPrinting(requestParameters: DownloadSerialNumbersForDirectPrintingRequest): Promise<string> {
        const response = await this.downloadSerialNumbersForDirectPrintingRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async findChildTextDocumentsRecordsRaw(requestParameters: FindChildTextDocumentsRecordsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultTextDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestFindChildTextDocumentsRecordsRequest === null || requestParameters.kvintaOperationRequestFindChildTextDocumentsRecordsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestFindChildTextDocumentsRecordsRequest','Required parameter requestParameters.kvintaOperationRequestFindChildTextDocumentsRecordsRequest was null or undefined when calling findChildTextDocumentsRecords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/findChildTextDocumentsRecords`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestFindChildTextDocumentsRecordsRequestToJSON(requestParameters.kvintaOperationRequestFindChildTextDocumentsRecordsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultTextDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async findChildTextDocumentsRecords(requestParameters: FindChildTextDocumentsRecordsRequest): Promise<KvintaOperationResponseGetListResultTextDocumentRecord> {
        const response = await this.findChildTextDocumentsRecordsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async findUnusedSgtinsRaw(requestParameters: FindUnusedSgtinsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListFindUnusedSerialNumbersRow>> {
        if (requestParameters.kvintaOperationRequestFindSgtinListRequest === null || requestParameters.kvintaOperationRequestFindSgtinListRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestFindSgtinListRequest','Required parameter requestParameters.kvintaOperationRequestFindSgtinListRequest was null or undefined when calling findUnusedSgtins.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/findUnusedSgtins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestFindSgtinListRequestToJSON(requestParameters.kvintaOperationRequestFindSgtinListRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListFindUnusedSerialNumbersRowFromJSON(jsonValue));
    }

    /**
     */
    async findUnusedSgtins(requestParameters: FindUnusedSgtinsRequest): Promise<KvintaOperationResponseListFindUnusedSerialNumbersRow> {
        const response = await this.findUnusedSgtinsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async findUnusedSgtinsAndMarkUsedRaw(requestParameters: FindUnusedSgtinsAndMarkUsedRequest): Promise<runtime.ApiResponse<KvintaOperationResponseFindSgtinListResponse>> {
        if (requestParameters.kvintaOperationRequestFindSgtinListRequest === null || requestParameters.kvintaOperationRequestFindSgtinListRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestFindSgtinListRequest','Required parameter requestParameters.kvintaOperationRequestFindSgtinListRequest was null or undefined when calling findUnusedSgtinsAndMarkUsed.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/findUnusedSgtinsAndMarkUsed`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestFindSgtinListRequestToJSON(requestParameters.kvintaOperationRequestFindSgtinListRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseFindSgtinListResponseFromJSON(jsonValue));
    }

    /**
     */
    async findUnusedSgtinsAndMarkUsed(requestParameters: FindUnusedSgtinsAndMarkUsedRequest): Promise<KvintaOperationResponseFindSgtinListResponse> {
        const response = await this.findUnusedSgtinsAndMarkUsedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listAggregatedCustomsDocumentsRaw(requestParameters: ListAggregatedCustomsDocumentsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultAggregatedCustomsDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestListAggregatedCustomsDocumentsRequest === null || requestParameters.kvintaOperationRequestListAggregatedCustomsDocumentsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListAggregatedCustomsDocumentsRequest','Required parameter requestParameters.kvintaOperationRequestListAggregatedCustomsDocumentsRequest was null or undefined when calling listAggregatedCustomsDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listAggregatedCustomsDocuments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListAggregatedCustomsDocumentsRequestToJSON(requestParameters.kvintaOperationRequestListAggregatedCustomsDocumentsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultAggregatedCustomsDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async listAggregatedCustomsDocuments(requestParameters: ListAggregatedCustomsDocumentsRequest): Promise<KvintaOperationResponseGetListResultAggregatedCustomsDocumentRecord> {
        const response = await this.listAggregatedCustomsDocumentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listAggregatedCustomsDocumentsByEventIdRaw(requestParameters: ListAggregatedCustomsDocumentsByEventIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListAggregatedCustomsDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestListString === null || requestParameters.kvintaOperationRequestListString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListString','Required parameter requestParameters.kvintaOperationRequestListString was null or undefined when calling listAggregatedCustomsDocumentsByEventId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listAggregatedCustomsDocumentsByEventId`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListStringToJSON(requestParameters.kvintaOperationRequestListString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListAggregatedCustomsDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async listAggregatedCustomsDocumentsByEventId(requestParameters: ListAggregatedCustomsDocumentsByEventIdRequest): Promise<KvintaOperationResponseListAggregatedCustomsDocumentRecord> {
        const response = await this.listAggregatedCustomsDocumentsByEventIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listDeliveryItemsRaw(requestParameters: ListDeliveryItemsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultDeliveryItem>> {
        if (requestParameters.kvintaOperationRequestListDeliveryItemsRequest === null || requestParameters.kvintaOperationRequestListDeliveryItemsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListDeliveryItemsRequest','Required parameter requestParameters.kvintaOperationRequestListDeliveryItemsRequest was null or undefined when calling listDeliveryItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listDeliveryItems`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListDeliveryItemsRequestToJSON(requestParameters.kvintaOperationRequestListDeliveryItemsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultDeliveryItemFromJSON(jsonValue));
    }

    /**
     */
    async listDeliveryItems(requestParameters: ListDeliveryItemsRequest): Promise<KvintaOperationResponseGetListResultDeliveryItem> {
        const response = await this.listDeliveryItemsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listDeliveryNotesRaw(requestParameters: ListDeliveryNotesRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultDeliveryNote>> {
        if (requestParameters.kvintaOperationRequestListDeliveryNotesRequest === null || requestParameters.kvintaOperationRequestListDeliveryNotesRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListDeliveryNotesRequest','Required parameter requestParameters.kvintaOperationRequestListDeliveryNotesRequest was null or undefined when calling listDeliveryNotes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listDeliveryNotes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListDeliveryNotesRequestToJSON(requestParameters.kvintaOperationRequestListDeliveryNotesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultDeliveryNoteFromJSON(jsonValue));
    }

    /**
     */
    async listDeliveryNotes(requestParameters: ListDeliveryNotesRequest): Promise<KvintaOperationResponseGetListResultDeliveryNote> {
        const response = await this.listDeliveryNotesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listSerialNumberGenerationProfilesRaw(requestParameters: ListSerialNumberGenerationProfilesRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultSerialNumberGenerationProfile>> {
        if (requestParameters.kvintaOperationRequestListSerialNumberGenerationProfilesRequest === null || requestParameters.kvintaOperationRequestListSerialNumberGenerationProfilesRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListSerialNumberGenerationProfilesRequest','Required parameter requestParameters.kvintaOperationRequestListSerialNumberGenerationProfilesRequest was null or undefined when calling listSerialNumberGenerationProfiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listSerialNumberGenerationProfiles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListSerialNumberGenerationProfilesRequestToJSON(requestParameters.kvintaOperationRequestListSerialNumberGenerationProfilesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultSerialNumberGenerationProfileFromJSON(jsonValue));
    }

    /**
     */
    async listSerialNumberGenerationProfiles(requestParameters: ListSerialNumberGenerationProfilesRequest): Promise<KvintaOperationResponseGetListResultSerialNumberGenerationProfile> {
        const response = await this.listSerialNumberGenerationProfilesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listSerialNumberOrderItemsRaw(requestParameters: ListSerialNumberOrderItemsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultSerialNumberOrderItem>> {
        if (requestParameters.kvintaOperationRequestListSerialNumberOrderItemsRequest === null || requestParameters.kvintaOperationRequestListSerialNumberOrderItemsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListSerialNumberOrderItemsRequest','Required parameter requestParameters.kvintaOperationRequestListSerialNumberOrderItemsRequest was null or undefined when calling listSerialNumberOrderItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listSerialNumberOrderItems`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListSerialNumberOrderItemsRequestToJSON(requestParameters.kvintaOperationRequestListSerialNumberOrderItemsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultSerialNumberOrderItemFromJSON(jsonValue));
    }

    /**
     */
    async listSerialNumberOrderItems(requestParameters: ListSerialNumberOrderItemsRequest): Promise<KvintaOperationResponseGetListResultSerialNumberOrderItem> {
        const response = await this.listSerialNumberOrderItemsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listSerialNumberOrdersRaw(requestParameters: ListSerialNumberOrdersRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultSerialNumberOrder>> {
        if (requestParameters.kvintaOperationRequestListSerialNumberOrdersRequest === null || requestParameters.kvintaOperationRequestListSerialNumberOrdersRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListSerialNumberOrdersRequest','Required parameter requestParameters.kvintaOperationRequestListSerialNumberOrdersRequest was null or undefined when calling listSerialNumberOrders.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listSerialNumberOrders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListSerialNumberOrdersRequestToJSON(requestParameters.kvintaOperationRequestListSerialNumberOrdersRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultSerialNumberOrderFromJSON(jsonValue));
    }

    /**
     */
    async listSerialNumberOrders(requestParameters: ListSerialNumberOrdersRequest): Promise<KvintaOperationResponseGetListResultSerialNumberOrder> {
        const response = await this.listSerialNumberOrdersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listSerialNumbersRaw(requestParameters: ListSerialNumbersRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultSerialNumber>> {
        if (requestParameters.kvintaOperationRequestListSerialNumbersRequest === null || requestParameters.kvintaOperationRequestListSerialNumbersRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListSerialNumbersRequest','Required parameter requestParameters.kvintaOperationRequestListSerialNumbersRequest was null or undefined when calling listSerialNumbers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listSerialNumbers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListSerialNumbersRequestToJSON(requestParameters.kvintaOperationRequestListSerialNumbersRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultSerialNumberFromJSON(jsonValue));
    }

    /**
     */
    async listSerialNumbers(requestParameters: ListSerialNumbersRequest): Promise<KvintaOperationResponseGetListResultSerialNumber> {
        const response = await this.listSerialNumbersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listTextDocumentsRaw(requestParameters: ListTextDocumentsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultTextDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestListTextDocumentsRequest === null || requestParameters.kvintaOperationRequestListTextDocumentsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListTextDocumentsRequest','Required parameter requestParameters.kvintaOperationRequestListTextDocumentsRequest was null or undefined when calling listTextDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listTextDocuments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListTextDocumentsRequestToJSON(requestParameters.kvintaOperationRequestListTextDocumentsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultTextDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async listTextDocuments(requestParameters: ListTextDocumentsRequest): Promise<KvintaOperationResponseGetListResultTextDocumentRecord> {
        const response = await this.listTextDocumentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listUsedSerialNumbersRaw(requestParameters: ListUsedSerialNumbersRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultUsedSerialNumber>> {
        if (requestParameters.kvintaOperationRequestListUsedSerialNumbersRequest === null || requestParameters.kvintaOperationRequestListUsedSerialNumbersRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListUsedSerialNumbersRequest','Required parameter requestParameters.kvintaOperationRequestListUsedSerialNumbersRequest was null or undefined when calling listUsedSerialNumbers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listUsedSerialNumbers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListUsedSerialNumbersRequestToJSON(requestParameters.kvintaOperationRequestListUsedSerialNumbersRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultUsedSerialNumberFromJSON(jsonValue));
    }

    /**
     */
    async listUsedSerialNumbers(requestParameters: ListUsedSerialNumbersRequest): Promise<KvintaOperationResponseGetListResultUsedSerialNumber> {
        const response = await this.listUsedSerialNumbersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readAggregatedCustomsDocumentRaw(requestParameters: ReadAggregatedCustomsDocumentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseAggregatedCustomsDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readAggregatedCustomsDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readAggregatedCustomsDocument`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseAggregatedCustomsDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async readAggregatedCustomsDocument(requestParameters: ReadAggregatedCustomsDocumentRequest): Promise<KvintaOperationResponseAggregatedCustomsDocumentRecord> {
        const response = await this.readAggregatedCustomsDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readDeliveryItemByIdRaw(requestParameters: ReadDeliveryItemByIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseDeliveryItem>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readDeliveryItemById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readDeliveryItemById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseDeliveryItemFromJSON(jsonValue));
    }

    /**
     */
    async readDeliveryItemById(requestParameters: ReadDeliveryItemByIdRequest): Promise<KvintaOperationResponseDeliveryItem> {
        const response = await this.readDeliveryItemByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readDeliveryNoteByIdRaw(requestParameters: ReadDeliveryNoteByIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseDeliveryNote>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readDeliveryNoteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readDeliveryNoteById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseDeliveryNoteFromJSON(jsonValue));
    }

    /**
     */
    async readDeliveryNoteById(requestParameters: ReadDeliveryNoteByIdRequest): Promise<KvintaOperationResponseDeliveryNote> {
        const response = await this.readDeliveryNoteByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readResourceRaw(requestParameters: ReadResourceRequest): Promise<runtime.ApiResponse<KvintaOperationResponseString>> {
        if (requestParameters.kvintaOperationRequestResource === null || requestParameters.kvintaOperationRequestResource === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestResource','Required parameter requestParameters.kvintaOperationRequestResource was null or undefined when calling readResource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readResource`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestResourceToJSON(requestParameters.kvintaOperationRequestResource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseStringFromJSON(jsonValue));
    }

    /**
     */
    async readResource(requestParameters: ReadResourceRequest): Promise<KvintaOperationResponseString> {
        const response = await this.readResourceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readSerialNumberByIdRaw(requestParameters: ReadSerialNumberByIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseSerialNumber>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readSerialNumberById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readSerialNumberById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseSerialNumberFromJSON(jsonValue));
    }

    /**
     */
    async readSerialNumberById(requestParameters: ReadSerialNumberByIdRequest): Promise<KvintaOperationResponseSerialNumber> {
        const response = await this.readSerialNumberByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readSerialNumberGenerationProfileByIdRaw(requestParameters: ReadSerialNumberGenerationProfileByIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseSerialNumberGenerationProfile>> {
        if (requestParameters.kvintaInlineObject === null || requestParameters.kvintaInlineObject === undefined) {
            throw new runtime.RequiredError('kvintaInlineObject','Required parameter requestParameters.kvintaInlineObject was null or undefined when calling readSerialNumberGenerationProfileById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readSerialNumberGenerationProfileById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaInlineObjectToJSON(requestParameters.kvintaInlineObject),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseSerialNumberGenerationProfileFromJSON(jsonValue));
    }

    /**
     */
    async readSerialNumberGenerationProfileById(requestParameters: ReadSerialNumberGenerationProfileByIdRequest): Promise<KvintaOperationResponseSerialNumberGenerationProfile> {
        const response = await this.readSerialNumberGenerationProfileByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readSerialNumberOrderByIdRaw(requestParameters: ReadSerialNumberOrderByIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseSerialNumberOrder>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readSerialNumberOrderById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readSerialNumberOrderById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseSerialNumberOrderFromJSON(jsonValue));
    }

    /**
     */
    async readSerialNumberOrderById(requestParameters: ReadSerialNumberOrderByIdRequest): Promise<KvintaOperationResponseSerialNumberOrder> {
        const response = await this.readSerialNumberOrderByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readSerialNumberOrderItemRaw(requestParameters: ReadSerialNumberOrderItemRequest): Promise<runtime.ApiResponse<KvintaOperationResponseSerialNumberOrderItem>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readSerialNumberOrderItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readSerialNumberOrderItemById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseSerialNumberOrderItemFromJSON(jsonValue));
    }

    /**
     */
    async readSerialNumberOrderItem(requestParameters: ReadSerialNumberOrderItemRequest): Promise<KvintaOperationResponseSerialNumberOrderItem> {
        const response = await this.readSerialNumberOrderItemRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readTextDocumentRaw(requestParameters: ReadTextDocumentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTextDocument>> {
        if (requestParameters.kvintaOperationRequestResource === null || requestParameters.kvintaOperationRequestResource === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestResource','Required parameter requestParameters.kvintaOperationRequestResource was null or undefined when calling readTextDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readTextDocument`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestResourceToJSON(requestParameters.kvintaOperationRequestResource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTextDocumentFromJSON(jsonValue));
    }

    /**
     */
    async readTextDocument(requestParameters: ReadTextDocumentRequest): Promise<KvintaOperationResponseTextDocument> {
        const response = await this.readTextDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readTextDocumentExternalNumberRaw(requestParameters: ReadTextDocumentExternalNumberRequest): Promise<runtime.ApiResponse<KvintaOperationResponseString>> {
        if (requestParameters.kvintaOperationRequestResource === null || requestParameters.kvintaOperationRequestResource === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestResource','Required parameter requestParameters.kvintaOperationRequestResource was null or undefined when calling readTextDocumentExternalNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readTextDocumentExternalNumber`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestResourceToJSON(requestParameters.kvintaOperationRequestResource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseStringFromJSON(jsonValue));
    }

    /**
     */
    async readTextDocumentExternalNumber(requestParameters: ReadTextDocumentExternalNumberRequest): Promise<KvintaOperationResponseString> {
        const response = await this.readTextDocumentExternalNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readTextDocumentRecordRaw(requestParameters: ReadTextDocumentRecordRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTextDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestResource === null || requestParameters.kvintaOperationRequestResource === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestResource','Required parameter requestParameters.kvintaOperationRequestResource was null or undefined when calling readTextDocumentRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readTextDocumentRecord`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestResourceToJSON(requestParameters.kvintaOperationRequestResource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTextDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async readTextDocumentRecord(requestParameters: ReadTextDocumentRecordRequest): Promise<KvintaOperationResponseTextDocumentRecord> {
        const response = await this.readTextDocumentRecordRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readTextDocumentRecordBySha256Raw(requestParameters: ReadTextDocumentRecordBySha256Request): Promise<runtime.ApiResponse<KvintaOperationResponseTextDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestTypeAndSha256 === null || requestParameters.kvintaOperationRequestTypeAndSha256 === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestTypeAndSha256','Required parameter requestParameters.kvintaOperationRequestTypeAndSha256 was null or undefined when calling readTextDocumentRecordBySha256.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readTextDocumentRecordBySha256`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestTypeAndSha256ToJSON(requestParameters.kvintaOperationRequestTypeAndSha256),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTextDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async readTextDocumentRecordBySha256(requestParameters: ReadTextDocumentRecordBySha256Request): Promise<KvintaOperationResponseTextDocumentRecord> {
        const response = await this.readTextDocumentRecordBySha256Raw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readTextDocumentWithStatusesRaw(requestParameters: ReadTextDocumentWithStatusesRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTextDocument>> {
        if (requestParameters.kvintaOperationRequestResource === null || requestParameters.kvintaOperationRequestResource === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestResource','Required parameter requestParameters.kvintaOperationRequestResource was null or undefined when calling readTextDocumentWithStatuses.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readTextDocumentWithStatuses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestResourceToJSON(requestParameters.kvintaOperationRequestResource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTextDocumentFromJSON(jsonValue));
    }

    /**
     */
    async readTextDocumentWithStatuses(requestParameters: ReadTextDocumentWithStatusesRequest): Promise<KvintaOperationResponseTextDocument> {
        const response = await this.readTextDocumentWithStatusesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readTextDocumentsExternalNumbersRaw(requestParameters: ReadTextDocumentsExternalNumbersRequest): Promise<runtime.ApiResponse<KvintaOperationResponseMapStringString>> {
        if (requestParameters.kvintaOperationRequestReadTextDocumentsExternalNumbersRequest === null || requestParameters.kvintaOperationRequestReadTextDocumentsExternalNumbersRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestReadTextDocumentsExternalNumbersRequest','Required parameter requestParameters.kvintaOperationRequestReadTextDocumentsExternalNumbersRequest was null or undefined when calling readTextDocumentsExternalNumbers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readTextDocumentsExternalNumbers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestReadTextDocumentsExternalNumbersRequestToJSON(requestParameters.kvintaOperationRequestReadTextDocumentsExternalNumbersRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseMapStringStringFromJSON(jsonValue));
    }

    /**
     */
    async readTextDocumentsExternalNumbers(requestParameters: ReadTextDocumentsExternalNumbersRequest): Promise<KvintaOperationResponseMapStringString> {
        const response = await this.readTextDocumentsExternalNumbersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async releaseSerialNumberOrderRaw(requestParameters: ReleaseSerialNumberOrderRequest): Promise<runtime.ApiResponse<KvintaOperationResponseString>> {
        if (requestParameters.kvintaOperationRequestReleaseSerialNumberOrderRequest === null || requestParameters.kvintaOperationRequestReleaseSerialNumberOrderRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestReleaseSerialNumberOrderRequest','Required parameter requestParameters.kvintaOperationRequestReleaseSerialNumberOrderRequest was null or undefined when calling releaseSerialNumberOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/releaseSerialNumberOrder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestReleaseSerialNumberOrderRequestToJSON(requestParameters.kvintaOperationRequestReleaseSerialNumberOrderRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseStringFromJSON(jsonValue));
    }

    /**
     */
    async releaseSerialNumberOrder(requestParameters: ReleaseSerialNumberOrderRequest): Promise<KvintaOperationResponseString> {
        const response = await this.releaseSerialNumberOrderRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async releaseTextDocumentRaw(requestParameters: ReleaseTextDocumentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseResource>> {
        if (requestParameters.kvintaOperationRequestResource === null || requestParameters.kvintaOperationRequestResource === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestResource','Required parameter requestParameters.kvintaOperationRequestResource was null or undefined when calling releaseTextDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/releaseTextDocument`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestResourceToJSON(requestParameters.kvintaOperationRequestResource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseResourceFromJSON(jsonValue));
    }

    /**
     */
    async releaseTextDocument(requestParameters: ReleaseTextDocumentRequest): Promise<KvintaOperationResponseResource> {
        const response = await this.releaseTextDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async removeCryptoTailsRaw(requestParameters: RemoveCryptoTailsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaOperationRequestRemoveCryptoTailBatchRequest === null || requestParameters.kvintaOperationRequestRemoveCryptoTailBatchRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestRemoveCryptoTailBatchRequest','Required parameter requestParameters.kvintaOperationRequestRemoveCryptoTailBatchRequest was null or undefined when calling removeCryptoTails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/removeCryptoTails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestRemoveCryptoTailBatchRequestToJSON(requestParameters.kvintaOperationRequestRemoveCryptoTailBatchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     */
    async removeCryptoTails(requestParameters: RemoveCryptoTailsRequest): Promise<KvintaOperationResponseInteger> {
        const response = await this.removeCryptoTailsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async removeCryptoTailsRRaw(requestParameters: RemoveCryptoTailsRRequest): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaOperationRequestResource === null || requestParameters.kvintaOperationRequestResource === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestResource','Required parameter requestParameters.kvintaOperationRequestResource was null or undefined when calling removeCryptoTailsR.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/removeCryptoTailsR`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestResourceToJSON(requestParameters.kvintaOperationRequestResource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     */
    async removeCryptoTailsR(requestParameters: RemoveCryptoTailsRRequest): Promise<KvintaOperationResponseInteger> {
        const response = await this.removeCryptoTailsRRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateDeliveryItemRaw(requestParameters: UpdateDeliveryItemRequest): Promise<runtime.ApiResponse<KvintaOperationResponseDeliveryItem>> {
        if (requestParameters.kvintaOperationRequestDeliveryItem === null || requestParameters.kvintaOperationRequestDeliveryItem === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestDeliveryItem','Required parameter requestParameters.kvintaOperationRequestDeliveryItem was null or undefined when calling updateDeliveryItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/updateDeliveryItem`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestDeliveryItemToJSON(requestParameters.kvintaOperationRequestDeliveryItem),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseDeliveryItemFromJSON(jsonValue));
    }

    /**
     */
    async updateDeliveryItem(requestParameters: UpdateDeliveryItemRequest): Promise<KvintaOperationResponseDeliveryItem> {
        const response = await this.updateDeliveryItemRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateSerialNumberOrderRaw(requestParameters: UpdateSerialNumberOrderRequest): Promise<runtime.ApiResponse<KvintaOperationResponseSerialNumberOrder>> {
        if (requestParameters.kvintaOperationRequestSerialNumberOrder === null || requestParameters.kvintaOperationRequestSerialNumberOrder === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestSerialNumberOrder','Required parameter requestParameters.kvintaOperationRequestSerialNumberOrder was null or undefined when calling updateSerialNumberOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/updateSerialNumberOrder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestSerialNumberOrderToJSON(requestParameters.kvintaOperationRequestSerialNumberOrder),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseSerialNumberOrderFromJSON(jsonValue));
    }

    /**
     */
    async updateSerialNumberOrder(requestParameters: UpdateSerialNumberOrderRequest): Promise<KvintaOperationResponseSerialNumberOrder> {
        const response = await this.updateSerialNumberOrderRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateSerialNumberOrderItemRaw(requestParameters: UpdateSerialNumberOrderItemRequest): Promise<runtime.ApiResponse<KvintaOperationResponseSerialNumberOrderItem>> {
        if (requestParameters.kvintaOperationRequestSerialNumberOrderItem === null || requestParameters.kvintaOperationRequestSerialNumberOrderItem === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestSerialNumberOrderItem','Required parameter requestParameters.kvintaOperationRequestSerialNumberOrderItem was null or undefined when calling updateSerialNumberOrderItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/updateSerialNumberOrderItem`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestSerialNumberOrderItemToJSON(requestParameters.kvintaOperationRequestSerialNumberOrderItem),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseSerialNumberOrderItemFromJSON(jsonValue));
    }

    /**
     */
    async updateSerialNumberOrderItem(requestParameters: UpdateSerialNumberOrderItemRequest): Promise<KvintaOperationResponseSerialNumberOrderItem> {
        const response = await this.updateSerialNumberOrderItemRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateUsedSerialNumbersRaw(requestParameters: UpdateUsedSerialNumbersRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListUsedSerialNumber>> {
        if (requestParameters.kvintaOperationRequestUpdateUsedSerialNumbersRequest === null || requestParameters.kvintaOperationRequestUpdateUsedSerialNumbersRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestUpdateUsedSerialNumbersRequest','Required parameter requestParameters.kvintaOperationRequestUpdateUsedSerialNumbersRequest was null or undefined when calling updateUsedSerialNumbers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/updateUsedSerialNumbers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestUpdateUsedSerialNumbersRequestToJSON(requestParameters.kvintaOperationRequestUpdateUsedSerialNumbersRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListUsedSerialNumberFromJSON(jsonValue));
    }

    /**
     */
    async updateUsedSerialNumbers(requestParameters: UpdateUsedSerialNumbersRequest): Promise<KvintaOperationResponseListUsedSerialNumber> {
        const response = await this.updateUsedSerialNumbersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async uploadRaw(requestParameters: UploadRequest): Promise<runtime.ApiResponse<KvintaOperationResponseLong>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling upload.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling upload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/serial-numbers/upload/for-order/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseLongFromJSON(jsonValue));
    }

    /**
     */
    async upload(requestParameters: UploadRequest): Promise<KvintaOperationResponseLong> {
        const response = await this.uploadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeAggregatedCustomsDocumentExternalNumberRaw(requestParameters: WriteAggregatedCustomsDocumentExternalNumberRequest): Promise<runtime.ApiResponse<KvintaOperationResponseAggregatedCustomsDocumentExternalNumber>> {
        if (requestParameters.kvintaOperationRequestAggregatedCustomsDocumentExternalNumber === null || requestParameters.kvintaOperationRequestAggregatedCustomsDocumentExternalNumber === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestAggregatedCustomsDocumentExternalNumber','Required parameter requestParameters.kvintaOperationRequestAggregatedCustomsDocumentExternalNumber was null or undefined when calling writeAggregatedCustomsDocumentExternalNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeAggregatedCustomsDocumentExternalNumber`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestAggregatedCustomsDocumentExternalNumberToJSON(requestParameters.kvintaOperationRequestAggregatedCustomsDocumentExternalNumber),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseAggregatedCustomsDocumentExternalNumberFromJSON(jsonValue));
    }

    /**
     */
    async writeAggregatedCustomsDocumentExternalNumber(requestParameters: WriteAggregatedCustomsDocumentExternalNumberRequest): Promise<KvintaOperationResponseAggregatedCustomsDocumentExternalNumber> {
        const response = await this.writeAggregatedCustomsDocumentExternalNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeIssuerSystemSerialNumbersRaw(requestParameters: WriteIssuerSystemSerialNumbersRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListSerialNumber>> {
        if (requestParameters.kvintaOperationRequestListIssuerSystemSerialNumber === null || requestParameters.kvintaOperationRequestListIssuerSystemSerialNumber === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListIssuerSystemSerialNumber','Required parameter requestParameters.kvintaOperationRequestListIssuerSystemSerialNumber was null or undefined when calling writeIssuerSystemSerialNumbers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeIssuerSystemSerialNumbers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListIssuerSystemSerialNumberToJSON(requestParameters.kvintaOperationRequestListIssuerSystemSerialNumber),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListSerialNumberFromJSON(jsonValue));
    }

    /**
     */
    async writeIssuerSystemSerialNumbers(requestParameters: WriteIssuerSystemSerialNumbersRequest): Promise<KvintaOperationResponseListSerialNumber> {
        const response = await this.writeIssuerSystemSerialNumbersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeSerialNumberRaw(requestParameters: WriteSerialNumberRequest): Promise<runtime.ApiResponse<KvintaOperationResponseSerialNumber>> {
        if (requestParameters.kvintaOperationRequestCreateSerialNumberRequest === null || requestParameters.kvintaOperationRequestCreateSerialNumberRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCreateSerialNumberRequest','Required parameter requestParameters.kvintaOperationRequestCreateSerialNumberRequest was null or undefined when calling writeSerialNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeSerialNumber`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCreateSerialNumberRequestToJSON(requestParameters.kvintaOperationRequestCreateSerialNumberRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseSerialNumberFromJSON(jsonValue));
    }

    /**
     */
    async writeSerialNumber(requestParameters: WriteSerialNumberRequest): Promise<KvintaOperationResponseSerialNumber> {
        const response = await this.writeSerialNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeSerialNumbersRaw(requestParameters: WriteSerialNumbersRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListSerialNumber>> {
        if (requestParameters.kvintaOperationRequestInsertSerialNumbersRequest === null || requestParameters.kvintaOperationRequestInsertSerialNumbersRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestInsertSerialNumbersRequest','Required parameter requestParameters.kvintaOperationRequestInsertSerialNumbersRequest was null or undefined when calling writeSerialNumbers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeSerialNumbers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestInsertSerialNumbersRequestToJSON(requestParameters.kvintaOperationRequestInsertSerialNumbersRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListSerialNumberFromJSON(jsonValue));
    }

    /**
     */
    async writeSerialNumbers(requestParameters: WriteSerialNumbersRequest): Promise<KvintaOperationResponseListSerialNumber> {
        const response = await this.writeSerialNumbersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeSerialNumbersRRaw(requestParameters: WriteSerialNumbersRRequest): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaOperationRequestResource === null || requestParameters.kvintaOperationRequestResource === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestResource','Required parameter requestParameters.kvintaOperationRequestResource was null or undefined when calling writeSerialNumbersR.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeSerialNumbersR`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestResourceToJSON(requestParameters.kvintaOperationRequestResource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     */
    async writeSerialNumbersR(requestParameters: WriteSerialNumbersRRequest): Promise<KvintaOperationResponseInteger> {
        const response = await this.writeSerialNumbersRRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeTextDocumentRaw(requestParameters: WriteTextDocumentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTextDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestWriteTextDocumentRequest === null || requestParameters.kvintaOperationRequestWriteTextDocumentRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestWriteTextDocumentRequest','Required parameter requestParameters.kvintaOperationRequestWriteTextDocumentRequest was null or undefined when calling writeTextDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeTextDocument`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestWriteTextDocumentRequestToJSON(requestParameters.kvintaOperationRequestWriteTextDocumentRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTextDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async writeTextDocument(requestParameters: WriteTextDocumentRequest): Promise<KvintaOperationResponseTextDocumentRecord> {
        const response = await this.writeTextDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeTextDocumentExternalNumberRaw(requestParameters: WriteTextDocumentExternalNumberRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTextDocumentExternalNumber>> {
        if (requestParameters.kvintaOperationRequestTextDocumentExternalNumber === null || requestParameters.kvintaOperationRequestTextDocumentExternalNumber === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestTextDocumentExternalNumber','Required parameter requestParameters.kvintaOperationRequestTextDocumentExternalNumber was null or undefined when calling writeTextDocumentExternalNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeTextDocumentExternalNumber`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestTextDocumentExternalNumberToJSON(requestParameters.kvintaOperationRequestTextDocumentExternalNumber),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTextDocumentExternalNumberFromJSON(jsonValue));
    }

    /**
     */
    async writeTextDocumentExternalNumber(requestParameters: WriteTextDocumentExternalNumberRequest): Promise<KvintaOperationResponseTextDocumentExternalNumber> {
        const response = await this.writeTextDocumentExternalNumberRaw(requestParameters);
        return await response.value();
    }

}
