/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaSequenceStatus {
    Created = 'STATUS_CREATED',
    Done = 'STATUS_DONE',
    Error = 'STATUS_ERROR',
    Initial = 'STATUS_INITIAL'
}

export function KvintaSequenceStatusFromJSON(json: any): KvintaSequenceStatus {
    return KvintaSequenceStatusFromJSONTyped(json, false);
}

export function KvintaSequenceStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSequenceStatus {
    return json as KvintaSequenceStatus;
}

export function KvintaSequenceStatusToJSON(value?: KvintaSequenceStatus | null): any {
    return value as any;
}

