/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaNotificationType,
    KvintaNotificationTypeFromJSON,
    KvintaNotificationTypeFromJSONTyped,
    KvintaNotificationTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaNotificationGroupBatchCreateRequest
 */
export interface KvintaNotificationGroupBatchCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaNotificationGroupBatchCreateRequest
     */
    groupName?: string;
    /**
     * 
     * @type {Array<KvintaNotificationType>}
     * @memberof KvintaNotificationGroupBatchCreateRequest
     */
    notificationTypes?: Array<KvintaNotificationType> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaNotificationGroupBatchCreateRequest
     */
    notificationRecipients?: Array<string> | null;
}

export function KvintaNotificationGroupBatchCreateRequestFromJSON(json: any): KvintaNotificationGroupBatchCreateRequest {
    return KvintaNotificationGroupBatchCreateRequestFromJSONTyped(json, false);
}

export function KvintaNotificationGroupBatchCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaNotificationGroupBatchCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupName': !exists(json, 'groupName') ? undefined : json['groupName'],
        'notificationTypes': !exists(json, 'notificationTypes') ? undefined : (json['notificationTypes'] === null ? null : (json['notificationTypes'] as Array<any>).map(KvintaNotificationTypeFromJSON)),
        'notificationRecipients': !exists(json, 'notificationRecipients') ? undefined : json['notificationRecipients'],
    };
}

export function KvintaNotificationGroupBatchCreateRequestToJSON(value?: KvintaNotificationGroupBatchCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groupName': value.groupName,
        'notificationTypes': value.notificationTypes === undefined ? undefined : (value.notificationTypes === null ? null : (value.notificationTypes as Array<any>).map(KvintaNotificationTypeToJSON)),
        'notificationRecipients': value.notificationRecipients,
    };
}


