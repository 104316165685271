/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaRegulatorReportingSystem,
    KvintaRegulatorReportingSystemFromJSON,
    KvintaRegulatorReportingSystemFromJSONTyped,
    KvintaRegulatorReportingSystemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaUpdateLocationReportingSystemsRequest
 */
export interface KvintaUpdateLocationReportingSystemsRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaUpdateLocationReportingSystemsRequest
     */
    gln13: string;
    /**
     * 
     * @type {Array<KvintaRegulatorReportingSystem>}
     * @memberof KvintaUpdateLocationReportingSystemsRequest
     */
    reportingSystems: Array<KvintaRegulatorReportingSystem>;
}

export function KvintaUpdateLocationReportingSystemsRequestFromJSON(json: any): KvintaUpdateLocationReportingSystemsRequest {
    return KvintaUpdateLocationReportingSystemsRequestFromJSONTyped(json, false);
}

export function KvintaUpdateLocationReportingSystemsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaUpdateLocationReportingSystemsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gln13': json['gln13'],
        'reportingSystems': ((json['reportingSystems'] as Array<any>).map(KvintaRegulatorReportingSystemFromJSON)),
    };
}

export function KvintaUpdateLocationReportingSystemsRequestToJSON(value?: KvintaUpdateLocationReportingSystemsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gln13': value.gln13,
        'reportingSystems': ((value.reportingSystems as Array<any>).map(KvintaRegulatorReportingSystemToJSON)),
    };
}


