/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaGismtSerialNumberInfo,
    KvintaGismtSerialNumberInfoFromJSON,
    KvintaGismtSerialNumberInfoFromJSONTyped,
    KvintaGismtSerialNumberInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGismtGetSerialNumberInfoResponse
 */
export interface KvintaGismtGetSerialNumberInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof KvintaGismtGetSerialNumberInfoResponse
     */
    total?: number;
    /**
     * 
     * @type {Array<KvintaGismtSerialNumberInfo>}
     * @memberof KvintaGismtGetSerialNumberInfoResponse
     */
    results?: Array<KvintaGismtSerialNumberInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaGismtGetSerialNumberInfoResponse
     */
    code?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtGetSerialNumberInfoResponse
     */
    error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtGetSerialNumberInfoResponse
     */
    errorMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtGetSerialNumberInfoResponse
     */
    errorDescription?: string | null;
}

export function KvintaGismtGetSerialNumberInfoResponseFromJSON(json: any): KvintaGismtGetSerialNumberInfoResponse {
    return KvintaGismtGetSerialNumberInfoResponseFromJSONTyped(json, false);
}

export function KvintaGismtGetSerialNumberInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGismtGetSerialNumberInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(KvintaGismtSerialNumberInfoFromJSON)),
        'code': !exists(json, 'code') ? undefined : json['code'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'errorDescription': !exists(json, 'errorDescription') ? undefined : json['errorDescription'],
    };
}

export function KvintaGismtGetSerialNumberInfoResponseToJSON(value?: KvintaGismtGetSerialNumberInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(KvintaGismtSerialNumberInfoToJSON)),
        'code': value.code,
        'error': value.error,
        'errorMessage': value.errorMessage,
        'errorDescription': value.errorDescription,
    };
}


