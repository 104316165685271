/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaTemplateType {
    Info = 'INFO',
    Success = 'SUCCESS',
    Error = 'ERROR'
}

export function KvintaTemplateTypeFromJSON(json: any): KvintaTemplateType {
    return KvintaTemplateTypeFromJSONTyped(json, false);
}

export function KvintaTemplateTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTemplateType {
    return json as KvintaTemplateType;
}

export function KvintaTemplateTypeToJSON(value?: KvintaTemplateType | null): any {
    return value as any;
}

