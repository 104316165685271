import MaterialTable, { MTableToolbar } from 'material-table';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  FilterBar,
  PageContentStore,
  tableIcons,
  PAGE_CONTENT_STORE_ID,
  KSidebarStore,
  SIDEBAR_STORE_ID,
} from 'kvinta/components';
import { CirculationReportsStore, CIRCULATION_REPORTS_STORE_ID } from './CirculationReportsStore';
import { linkCirculationReportSummaryPath, navigateCirculationReportSummaryPath } from './paths';
import { Link } from '@material-ui/core';

interface ViewListCirculationReportsProps {
  circulationReportsStore?: CirculationReportsStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
}

class ViewListCirculationReportsImpl extends Component<ViewListCirculationReportsProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ViewListCirculationReportsProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.circulationReportsStore.fetchData();
  }

  navigateCirculationReportsSummary = (id: string) => {
    navigateCirculationReportSummaryPath(this.props.history, id);
  };

  render() {
    const intl = this.props.intl;
    const { listData, isLoading, page, totalCount, pageSize, filter } = this.props.circulationReportsStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <MaterialTable
                icons={tableIcons}
                tableRef={this.tableRef}
                // title={intl.formatMessage({ id: 'menu.circulation-reports' })}
                columns={[
                  {
                    title: intl.formatMessage({ id: 'atk-list.header.time' }),
                    field: 'timestamp', // should be timestamp but currently crashes for some reason
                    defaultSort: 'desc',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '25%',
                    },
                    render: (data) => {
                      return (
                        <Link
                          href={linkCirculationReportSummaryPath(history, data.resource.id)}
                          onClick={preventDefault}
                        >
                          {data.timestamp.epochMillis ? new Date(data.timestamp.epochMillis).toISOString() : ''}
                        </Link>
                      );
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'atk-list.header.user' }),
                    field: 'context.userId',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '25%',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'atk-list.header.last-status' }),
                    field: 'lastStatus',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                      width: '25%',
                    },
                  },
                ]}
                data={listData}
                page={page}
                totalCount={totalCount}
                onChangePage={(page: number) => {
                  this.props.circulationReportsStore.fetchPage(page);
                }}
                onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                  this.props.circulationReportsStore.changeOrder(orderBy, orderDirection);
                }}
                isLoading={isLoading}
                options={{
                  actionsColumnIndex: 0,
                  paging: true,
                  sorting: true,
                  draggable: false,
                  search: false,
                  pageSize: pageSize,
                  pageSizeOptions: [pageSize],
                  minBodyHeight: bodyHeight,
                  maxBodyHeight: bodyHeight,
                  showTitle: false,
                  thirdSortClick: false,
                  emptyRowsWhenPaging: false,
                }}
                localization={{
                  header: {
                    actions: '',
                  },
                }}
                onRowClick={(event, rowData) => {
                  if (!(event.target instanceof HTMLInputElement)) {
                    this.navigateCirculationReportsSummary(rowData.resource.id);
                  }
                }}
                components={{
                  Toolbar: (props) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: '20px',
                        marginRight: '20px',
                        marginLeft: '11px',
                      }}
                    >
                      <FilterBar filter={filter} />
                    </div>
                  ),
                }}
              />
            </div>
          </div>
        )}
      />
    );
  }
}
export const ViewListCirculationReports = injectIntl(
  inject(
    CIRCULATION_REPORTS_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(ViewListCirculationReportsImpl)),
);
