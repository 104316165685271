import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import {
  FilterBar,
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  tableIcons,
} from '../../components';
import React, { Component } from 'react';
import { NOTIFICATION_STORE_ID, NotificationStore } from './NotificationStore';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Route } from 'react-router-dom';
import { Button, Menu, MenuItem, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { navigateToNotificationGroupRecipients } from './paths';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { KvintaCompany } from '../../apis/kvinta-masterdata-service';

type NotificationGroupListViewProps = {
  notificationStore?: NotificationStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history: any;
};

type NotificationGroupListViewState = {
  modal: DeleteModalState;
  newGroupForm: GroupFormState;
  isMenuOpen: boolean;
  anchorEl: null | HTMLElement;
  rowData: any;
};

type DeleteModalState = { isOpen: true; id: string; groupName: string } | { isOpen: false };

type GroupFormState = { isOpen: true; value: string; submitButtonActive: boolean; error: boolean } | { isOpen: false };

type DeleteModalProps = {
  data: DeleteModalState;
  closeModal: () => void;
  handleDelete: () => void;
  intl: any;
};

type NewGroupFormProps = {
  data: GroupFormState;
  closeModal: () => void;
  handleSubmit: () => void;
  handleBlur: () => void;
  handleFocus: () => void;
  handleChange: (newValue: string) => void;
  intl: any;
};

const DeleteRequestModal: React.FunctionComponent<DeleteModalProps> = ({ data, handleDelete, closeModal, intl }) => {
  const isOpen = data.isOpen;
  const groupName = data.isOpen && data.groupName;

  return (
    <Dialog open={isOpen} onClose={closeModal} fullWidth={true} maxWidth="sm">
      <DialogTitle id="alert-dialog-title">
        {intl.formatMessage({ id: 'notification-recipients-view.group-list.remove-group' }, { groupName })}
      </DialogTitle>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          {intl.formatMessage({ id: 'button.cancel' })}
        </Button>
        <Button onClick={handleDelete} color="primary" autoFocus>
          {intl.formatMessage({ id: 'button.delete' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const NewGroupForm: React.FunctionComponent<NewGroupFormProps> = ({
  closeModal,
  handleSubmit,
  handleChange,
  handleBlur,
  handleFocus,
  intl,
  data,
}) => {
  const isOpen = data.isOpen;
  const value = data.isOpen && data.value;
  const submitButtonActive = data.isOpen && data.submitButtonActive;
  const error = data.isOpen && data.error;

  return (
    <Dialog open={isOpen} onClose={closeModal} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
      <DialogTitle id="form-dialog-title">
        {intl.formatMessage({ id: 'notification-recipients-view.group-list.add-group-title' })}
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          autoFocus
          margin="dense"
          id="emailAddress"
          label={intl.formatMessage({ id: 'notification-recipients-view.group-list.add-group-form.group-name' })}
          fullWidth
          onChange={(e) => handleChange(e.target.value)}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
          error={error}
        />
      </DialogContent>
      <DialogActions style={{ padding: '0 24px 16px' }}>
        <Button
          style={{ color: 'white' }}
          onClick={handleSubmit}
          color="secondary"
          variant="contained"
          disabled={!submitButtonActive}
        >
          {intl.formatMessage({ id: 'button.submit' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

class NotificationGroupListViewBase extends Component<NotificationGroupListViewProps, NotificationGroupListViewState> {
  tableRef: React.RefObject<unknown>;

  constructor(props: NotificationGroupListViewProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      modal: {
        isOpen: false,
      },
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
      newGroupForm: {
        isOpen: false,
      },
    } as const;
  }

  componentDidMount() {
    this.props.notificationStore.listNotificationGroups();
    this.props.sidebarStore.clearMainTitle();
  }

  navigateToNotificationGroupRecipients = (id: string) => {
    navigateToNotificationGroupRecipients(this.props.history, id);
  };

  handleDeleteRequest(id: string, groupName: string) {
    this.setState({
      modal: { isOpen: true, id, groupName },
    });
  }

  handleDelete = () => {
    this.setState({
      modal: { isOpen: false },
    });
    this.state.modal.isOpen &&
      this.props.notificationStore.deleteGroup(this.state.modal.id, this.state.modal.groupName);
  };

  closeModal = () => {
    this.setState({
      modal: { isOpen: false },
    });
  };

  openNewEmailForm() {
    this.setState({
      newGroupForm: {
        isOpen: true,
        submitButtonActive: false,
        value: '',
        error: false,
      },
    });
  }

  isValidGroupName = (value: string) => {
    return value.trim().length > 0 && value.trim().length < 100;
  };

  handleBlur = () => {
    if (this.state.newGroupForm.isOpen) {
      const trimmedValue = this.state.newGroupForm.value.trim();
      const valueIsValidEmail = this.isValidGroupName(trimmedValue);
      const error = !valueIsValidEmail && trimmedValue !== '';

      this.setState({
        newGroupForm: {
          ...this.state.newGroupForm,
          value: trimmedValue,
          submitButtonActive: valueIsValidEmail,
          error,
        },
      });
    }
  };

  handleFocus = () => {
    if (this.state.newGroupForm.isOpen) {
      this.setState({
        newGroupForm: {
          ...this.state.newGroupForm,
          error: false,
        },
      });
    }
  };

  handleFormChange = (newValue: string) => {
    if (this.state.newGroupForm.isOpen) {
      const trimmedValue = newValue.trim();
      const valueIsValidEmail = this.isValidGroupName(trimmedValue);

      this.setState({
        newGroupForm: {
          ...this.state.newGroupForm,
          value: newValue,
          submitButtonActive: valueIsValidEmail,
        },
      });
    }
  };

  handleSubmit = () => {
    this.state.newGroupForm.isOpen && this.props.notificationStore.addGroup(this.state.newGroupForm.value);
    this.setState({
      newGroupForm: { isOpen: false },
    });
  };

  closeNewEmailForm = () => {
    this.setState({
      newGroupForm: { isOpen: false },
    });
  };

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  render() {
    const intl = this.props.intl;
    const { listData, isLoading } = this.props.notificationStore;
    const bodyHeight = this.props.pageContentStore.height;
    return (
      <Route
        render={({ history }) => (
          <>
            <MaterialTable
              icons={tableIcons}
              tableRef={this.tableRef}
              // title={intl.formatMessage({ id: 'menu.events' })}
              columns={[
                {
                  title: intl.formatMessage({ id: 'notification-group-list-view.field.group-name' }),
                  field: 'groupName',
                  cellStyle: {
                    whiteSpace: 'nowrap',
                  },
                },
              ]}
              actions={[
                {
                  icon: MoreVertIcon,
                  tooltip: 'Actions',
                  isFreeAction: false,
                  onClick: (event, rowData) => {
                    this.handleActionsMenuOpen(event, rowData);
                  },
                },
              ]}
              data={listData}
              isLoading={isLoading}
              options={{
                actionsColumnIndex: 0,
                paging: false,
                sorting: false,
                draggable: false,
                search: false,
                minBodyHeight: bodyHeight,
                maxBodyHeight: bodyHeight,
                showTitle: false,
              }}
              onRowClick={(event, rowData) => {
                this.navigateToNotificationGroupRecipients(rowData.id);
              }}
              components={{
                Toolbar: (props) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      marginTop: '20px',
                      marginRight: '20px',
                    }}
                  >
                    <div style={{ marginLeft: '5px' }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon color="secondary" />}
                        onClick={() => this.openNewEmailForm()}
                      >
                        {intl.formatMessage({ id: 'button.create' })}
                      </Button>
                    </div>
                  </div>
                ),
              }}
            />
            <Menu
              id="row-action-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={this.state.isMenuOpen}
              onClose={this.onMenuClose}
            >
              <MenuItem
                onClick={(event) => {
                  console.log('rowData', this.state.rowData);
                  this.handleDeleteRequest(this.state.rowData.id, this.state.rowData.groupName);
                  this.onMenuClose();
                }}
              >
                {intl.formatMessage({ id: 'button.delete' })}
              </MenuItem>
            </Menu>
            {this.state && (
              <DeleteRequestModal
                data={this.state.modal}
                handleDelete={this.handleDelete}
                closeModal={this.closeModal}
                intl={intl}
              />
            )}
            {this.state && this.state.newGroupForm && (
              <NewGroupForm
                data={this.state.newGroupForm}
                closeModal={this.closeNewEmailForm}
                handleSubmit={this.handleSubmit}
                handleChange={this.handleFormChange}
                handleBlur={this.handleBlur}
                handleFocus={this.handleFocus}
                intl={intl}
              />
            )}
          </>
        )}
      />
    );
  }
}

export const NotificationGroupListView = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, NOTIFICATION_STORE_ID, SIDEBAR_STORE_ID)(observer(NotificationGroupListViewBase)),
);
