import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { tabs } from './tabs';

import React, { Component } from 'react';
import { EpcisEventsStore, EPCIS_EVENTS_STORE_ID } from './EpcisEventsStore';
import { navigateEpcisEventJsonPath, navigateEpcisEventsPath } from './paths';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Paper, Grid, Box, FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import { navigateExecutionStatusPath } from 'kvinta/modules/paths';
import { IconButton, InputAdornment } from '@material-ui/core';
import { reaction } from 'mobx';

interface ViewEpcisEventSummaryProps {
  intl: any;
  history?: any;
  sidebarStore?: KSidebarStore;
  epcisEventsStore: EpcisEventsStore;
  id: string;
}
class ViewEpcisEventSummaryImpl extends Component<ViewEpcisEventSummaryProps> {
  disposer: any;
  componentDidMount() {
    this.props.epcisEventsStore.loadEvent(this.props.id);
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.epcisEventsStore.currentEvent,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.id, () => {
            navigateEpcisEventsPath(this.props.history);
          });
        }
      },
    );
  }
  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const intl = this.props.intl;
    const currentEvent = this.props.epcisEventsStore.currentEvent;
    if (!currentEvent) {
      return null;
    }
    const executionId = this.props.epcisEventsStore.currentExecutionId;
    return (
      <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '2') {
              navigateEpcisEventJsonPath(this.props.history, this.props.id);
            }
          }}
          tabId={'1'}
          tabs={tabs}
        />
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          <Grid container>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="epc">
                    {intl.formatMessage({ id: 'epcis-event.view.record-date' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    label=" "
                    id="epc"
                    defaultValue={
                      currentEvent.recordTime !== undefined ? new Date(currentEvent.recordTime).toISOString() : ''
                    }
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="timestamp">
                    {intl.formatMessage({ id: 'epcis-event.view.id' })}
                  </InputLabel>
                  <OutlinedInput readOnly={true} label=" " id="timestamp" defaultValue={currentEvent.id} />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="disposition">
                    {intl.formatMessage({ id: 'epcis-event.view.location' })}
                  </InputLabel>
                  <OutlinedInput readOnly={true} label=" " id="disposition" defaultValue={currentEvent.bizLocation} />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="errordeclaration">
                    {intl.formatMessage({ id: 'epcis-event.view.biz-step' })}
                  </InputLabel>
                  <OutlinedInput readOnly={true} label=" " id="errordeclaration" defaultValue={currentEvent.bizStep} />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="readpoint">
                    {intl.formatMessage({ id: 'epcis-event.view.read-point' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    label=" "
                    id="errordeclaration"
                    defaultValue={currentEvent.readPoint}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="eventid">
                    {intl.formatMessage({ id: 'epcis-event.view.executionId' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    label=" "
                    id="eventid"
                    defaultValue={executionId}
                    endAdornment={
                      executionId && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              navigateExecutionStatusPath(this.props.history, executionId);
                            }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="userId">
                    {intl.formatMessage({ id: 'epcis-event.user-id' })}
                  </InputLabel>
                  <OutlinedInput readOnly={true} label=" " id="userId" defaultValue={(currentEvent as any).userId} />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="parentID">
                    {intl.formatMessage({ id: 'epcis-event.parent-id' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    label=" "
                    id="parentID"
                    defaultValue={(currentEvent as any).parentID}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="epcCount">
                    {intl.formatMessage({ id: 'epcis-event.epc-count' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    label=" "
                    id="epcCount"
                    defaultValue={
                      currentEvent.childEPCs !== undefined
                        ? currentEvent.childEPCs.length
                        : currentEvent.epcList !== undefined
                        ? currentEvent.epcList.length
                        : ''
                    }
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="sampleCode">
                    {intl.formatMessage({ id: 'epcis-event.sample-code' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    label=" "
                    id="sampleCode"
                    defaultValue={(currentEvent as any).parentId}
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="bizTransaction">
                    {intl.formatMessage({ id: 'epcis-event.biz-transaction' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    label=" "
                    id="bizTransaction"
                    defaultValue={
                      currentEvent.bizTransactionList !== undefined
                        ? currentEvent.bizTransactionList.map((bizTrans) => {
                            return bizTrans.bizTransaction;
                          })
                        : ''
                    }
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="prodBatch">
                    {intl.formatMessage({ id: 'epcis-event.prod-batch' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    label=" "
                    id="prodBatch"
                    defaultValue={(currentEvent as any).parentId}
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="expiredDate">
                    {intl.formatMessage({ id: 'epcis-event.expired-date' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    label=" "
                    id="expiredDate"
                    defaultValue={(currentEvent as any).parentId}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Paper>
    );
  }
}
export const ViewEpcisEventSummary = injectIntl(
  inject(EPCIS_EVENTS_STORE_ID, SIDEBAR_STORE_ID)(observer(ViewEpcisEventSummaryImpl)),
);
