import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import React from 'react';
import { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { KvintaCompany, KvintaLocation } from 'kvinta/apis/kvinta-masterdata-service';
import { Route } from 'react-router-dom';
import { countries } from 'kvinta/common/countries';
import { navigateCompanyPath } from '../companies';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export interface LocationComponentProps {
  location?: KvintaLocation;
  companyData?: KvintaCompany;
  intl: any;
  history: any;
  isEditing: boolean;
  companies: any;
  locationData: any;
}
class ViewLocationCompImpl extends Component<LocationComponentProps> {
  render() {
    const formData = this.props.location;
    if (!formData) {
      return null;
    }
    const companyData = this.props.companyData;
    if (!companyData) {
      return null;
    }
    const companies = this.props.companies;
    const country = countries.find((country) => {
      return country.shortCode === formData.country;
    }).countryName;
    const disabled = !this.props.isEditing;
    const intl = this.props.intl;
    const newFormData = this.props.locationData;
    return (
      <Route
        render={({ history }) => (
          <Grid container>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="company">
                    {intl.formatMessage({ id: 'location-view.field.company' })}
                  </InputLabel>
                  {this.props.isEditing ? (
                    <Select
                      id="company"
                      value={formData.companyId}
                      onChange={(e) => {
                        newFormData.company = e.target.value;
                      }}
                      label={intl.formatMessage({ id: 'location-create-form.field.company' })}
                      endAdornment={
                        this.props.isEditing
                          ? null
                          : formData.companyId && (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={(e) => {
                                    navigateCompanyPath(this.props.history, formData.companyId);
                                  }}
                                >
                                  <OpenInNewIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                      }
                    >
                      {companies.map((company) => (
                        <MenuItem key={company.id} value={company.id}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <OutlinedInput
                      readOnly={disabled}
                      disabled={!this.props.isEditing && !Boolean(formData.name)}
                      id="company"
                      defaultValue={companyData.name}
                      label={intl.formatMessage({ id: 'location-create-form.field.company' })}
                      endAdornment={
                        formData.companyId && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(e) => {
                                navigateCompanyPath(this.props.history, formData.companyId);
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    />
                  )}
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="gln13">
                    {intl.formatMessage({ id: 'location-view.field.gln13' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!this.props.isEditing && !Boolean(formData.gln13)}
                    id="gln13"
                    defaultValue={formData.gln13}
                    onChange={(e) => {
                      newFormData.gln13 = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'location-view.field.gln13' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="latitude">
                    {intl.formatMessage({ id: 'location-view.field.latitude' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!this.props.isEditing && !Boolean(formData.latitude)}
                    id="latitude"
                    defaultValue={formData.latitude}
                    onChange={(e) => {
                      newFormData.latitude = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'location-view.field.latitude' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="description">
                    {intl.formatMessage({ id: 'location-view.field.description' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!this.props.isEditing && !Boolean(formData.description)}
                    id="description"
                    defaultValue={formData.description}
                    onChange={(e) => {
                      newFormData.description = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'location-view.field.description' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="city">
                    {intl.formatMessage({ id: 'location-view.field.city' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!this.props.isEditing && !Boolean(formData.city)}
                    id="city"
                    defaultValue={formData.city}
                    onChange={(e) => {
                      newFormData.city = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'location-view.field.city' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="address1">
                    {intl.formatMessage({ id: 'location-view.field.address1' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!this.props.isEditing && !Boolean(formData.address1)}
                    id="address1"
                    defaultValue={formData.address1}
                    onChange={(e) => {
                      newFormData.address1 = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'location-view.field.address1' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="address3">
                    {intl.formatMessage({ id: 'location-view.field.address3' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!this.props.isEditing && !Boolean(formData.address3)}
                    id="address3"
                    defaultValue={formData.address3}
                    onChange={(e) => {
                      newFormData.address3 = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'location-view.field.address3' })}
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="name">
                    {intl.formatMessage({ id: 'location-view.field.name' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!this.props.isEditing && !Boolean(formData.name)}
                    id="name"
                    defaultValue={formData.name}
                    onChange={(e) => {
                      newFormData.name = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'location-view.field.name' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="sgln">
                    {intl.formatMessage({ id: 'location-view.field.sgln' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!this.props.isEditing && !Boolean(formData.sgln)}
                    id="sgln"
                    defaultValue={formData.sgln}
                    onChange={(e) => {
                      newFormData.sgln = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'location-view.field.sgln' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="longitude">
                    {intl.formatMessage({ id: 'location-view.field.longitude' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!this.props.isEditing && !Boolean(formData.longitude)}
                    id="longitude"
                    defaultValue={formData.longitude}
                    onChange={(e) => {
                      newFormData.longitude = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'location-view.field.longitude' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="country">
                    {intl.formatMessage({ id: 'location-view.field.country' })}
                  </InputLabel>
                  {this.props.isEditing ? (
                    <Select
                      id="country"
                      value={formData.country}
                      onChange={(e) => {
                        newFormData.country = e.target.value;
                      }}
                      label={intl.formatMessage({ id: 'location-create-form.field.country' })}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.shortCode} value={country.shortCode}>
                          <FormattedMessage id={country.countryName} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <OutlinedInput
                      readOnly={disabled}
                      disabled={!this.props.isEditing && !Boolean(formData.country)}
                      id="country"
                      defaultValue={intl.formatMessage({ id: country })}
                      onChange={(e) => {
                        newFormData.country = e.target.value;
                      }}
                      label={intl.formatMessage({ id: 'location-create-form.field.country' })}
                    />
                  )}
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="postalcode">
                    {intl.formatMessage({ id: 'location-view.field.postalcode' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!this.props.isEditing && !Boolean(formData.postalCode)}
                    id="postalcode"
                    defaultValue={formData.postalCode}
                    onChange={(e) => {
                      newFormData.postalCode = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'location-view.field.postalcode' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="address2">
                    {intl.formatMessage({ id: 'location-view.field.address2' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!this.props.isEditing && !Boolean(formData.address2)}
                    id="address2"
                    defaultValue={formData.address2}
                    onChange={(e) => {
                      newFormData.address2 = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'location-view.field.address2' })}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        )}
      />
    );
  }
}

export const ViewLocationComp = injectIntl(ViewLocationCompImpl);
