import { Box, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { navigateEpcisEventPath, navigateEpcMessagePath, navigateExecutionStatusPath } from 'kvinta/modules/paths';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { ExtKvintaSequenceStatusRecord, statusesList } from './SequenceStatusStore';

export interface SequenceStatusComponentProps {
  intl: any;
  sequenceStatusData: ExtKvintaSequenceStatusRecord;
  history: any;
}
class SequenceStatusCompViewImpl extends Component<SequenceStatusComponentProps> {
  render() {
    const formData = this.props.sequenceStatusData;
    if (!formData) {
      return null;
    }
    const intl = this.props.intl;

    let status = '';
    statusesList.map((value, index) => {
      if (value === formData.status) {
        status = value;
      }
    });
    const formatedCreateDate = new Date(formData.updateTimestamp).toISOString();
    const formatedUpdateDate = new Date(formData.updateTimestamp).toISOString();
    return (
      <Route
        render={({ history }) => (
          <Grid container>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="eventId">
                    {intl.formatMessage({ id: 'sequence-status-list.eventId' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    disabled={!Boolean(formData.eventId)}
                    id="eventId"
                    defaultValue={formData.eventId}
                    label={intl.formatMessage({ id: 'sequence-status-list.eventId' })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={(e) => {
                            navigateEpcisEventPath(this.props.history, formData.eventId);
                          }}
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="rootExecutionId">
                    {intl.formatMessage({ id: 'sequence-status-list.rootExecutionId' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    disabled={!Boolean(formData.rootExecutionId)}
                    id="rootExecutionId"
                    defaultValue={formData.rootExecutionId}
                    label={intl.formatMessage({ id: 'sequence-status-list.rootExecutionId' })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={(e) => {
                            navigateExecutionStatusPath(this.props.history, formData.rootExecutionId);
                          }}
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="epc">
                    {intl.formatMessage({ id: 'sequence-status-list.epc' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    disabled={!Boolean(formData.epc)}
                    id="epc"
                    defaultValue={formData.epc}
                    label={intl.formatMessage({ id: 'sequence-status-list.epc' })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={(e) => {
                            navigateEpcMessagePath(this.props.history, formData.epc, 0);
                          }}
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="bizStep">
                    {intl.formatMessage({ id: 'sequence-status-list.bizStep' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    disabled={!Boolean(formData.bizStep)}
                    id="bizStep"
                    defaultValue={formData.bizStep}
                    label={intl.formatMessage({ id: 'sequence-status-list.bizStep' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="tenantId">
                    {intl.formatMessage({ id: 'sequence-status-list.tenantId' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    disabled={!Boolean(formData.tenantId)}
                    id="tenantId"
                    defaultValue={formData.tenantId}
                    label={intl.formatMessage({ id: 'sequence-status-list.tenantId' })}
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="status">
                    {intl.formatMessage({ id: 'sequence-status-list.status' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    disabled={!Boolean(formData.status)}
                    id="status"
                    defaultValue={status}
                    label={intl.formatMessage({ id: 'sequence-status-list.status' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="createTimestamp">
                    {intl.formatMessage({ id: 'sequence-status-list.createTimestamp' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    disabled={!Boolean(formData.createTimestamp)}
                    id="createTimestamp"
                    defaultValue={formatedCreateDate}
                    label={intl.formatMessage({ id: 'sequence-status-list.createTimestamp' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="updateTimestamp">
                    {intl.formatMessage({ id: 'sequence-status-list.updateTimestamp' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={true}
                    disabled={!Boolean(formData.updateTimestamp)}
                    id="updateTimestamp"
                    defaultValue={formatedUpdateDate}
                    label={intl.formatMessage({ id: 'sequence-status-list.updateTimestamp' })}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        )}
      />
    );
  }
}

export const SequenceStatusCompView = injectIntl(SequenceStatusCompViewImpl);
