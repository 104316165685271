import * as React from 'react';
import { Map as OpenlayersMap, control, style, Feature, format, source, layer, View } from 'openlayers';

import mapMarker from 'assets/img/mapMarker.svg';
import activeMapMarker from 'assets/img/mapMarkerActive.svg';
import { TPoint } from './types';

export interface IKvMapProps {
  delay?: number;
  zoom?: number;
  maxZoom?: number;
  minZoom?: number;
  size?: { height: string; width: string };
  targetId: string;
  style?: string;
  points: TPoint[];
  activePointId: string;
  setActiveMapPointId: (id: string) => void;
}

export class MapMultiplePoints extends React.Component<IKvMapProps, any> {
  private mapMarkerTimeout: number | undefined = undefined;
  private _map: OpenlayersMap | null = null;
  private _vectorLayers: { [key: string]: layer.Vector } = {};

  componentDidMount() {
    this.mapMarkerTimeout = window.setTimeout(() => {
      this.setUpMap(this.props);
      this.setMarkers();
      if (this._map !== null) {
        this._map.setTarget(this.props.targetId);
      }
    }, this.props.delay || 0);
  }

  componentDidUpdate(prevProps: Readonly<IKvMapProps>, prevState: Readonly<any>, snapshot?: any) {
    if (prevProps.activePointId !== this.props.activePointId) {
      Object.values(this._vectorLayers).forEach((layer) => {
        this._map && this._map.removeLayer(layer);
      });
      this.setMarkers();
    }
  }

  componentWillUnmount() {
    try {
      clearTimeout(this.mapMarkerTimeout);
      this._map = null;
    } catch (e) {
      // setTarget may not always be available.
      console.error('map was not able to set target');
    }
  }

  setMarkers() {
    const activePointIndex = this.props.points.findIndex((point) => {
      return (point as any).features[0].properties.id === this.props.activePointId;
    });

    const iconStyle = (feature) => {
      const featureId = feature.get('id');
      const isActiveMarker = featureId === this.props.activePointId;
      return new style.Style({
        image: new style.Icon({
          anchor: [0.5, 1],
          anchorOrigin: 'bottom-right',
          scale: isActiveMarker ? 0.36 : 0.25,
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          opacity: 1,
          color: '#ffffff',
          src: isActiveMarker ? activeMapMarker : mapMarker,
        }),
        text: new style.Text({
          font: 'bold 12px Roboto, sans-serif',
          fill: new style.Fill({ color: '#ffffff' }),
          offsetY: isActiveMarker ? -35 : -25,
          text: feature.get('number'),
        }),
      });
    };

    const geoJsonPointFeatures = this.props.points.map((point) => {
      return new format.GeoJSON({
        featureProjection: 'EPSG:3857',
        dataProjection: 'EPSG:4326',
        geometryName: 'point',
      } as any).readFeatures(point);
    });

    const pointsVectorSources = geoJsonPointFeatures.map((feature) => {
      return new source.Vector({ features: feature });
    });

    const extent = pointsVectorSources[0].getExtent();

    pointsVectorSources.forEach((source) => {
      const id = source.getFeatures()[0].get('id');
      this._vectorLayers[id] = new layer.Vector({
        source: source,
        style: iconStyle,
      });
    });

    if (this._map !== null) {
      Object.values(this._vectorLayers).forEach((layer, i) => {
        if (i !== activePointIndex) {
          this._map && this._map.addLayer(layer);
        }
      });
      Object.values(this._vectorLayers).forEach((layer, i) => {
        if (i === activePointIndex) {
          this._map && this._map.addLayer(layer);
        }
      });
      this._map.getView().fit(extent, undefined);
    }
  }

  setUpMap(props: IKvMapProps) {
    this._map = new OpenlayersMap({
      layers: [new layer.Tile({ source: new source.OSM() })],
      view: new View({
        // center: proj.fromLonLat(this.props.center as Coordinate),
        zoom: props.zoom || 5,
        maxZoom: props.maxZoom || 12,
        minZoom: props.minZoom || 1,
      }),
      controls: [new control.Zoom()],
      target: this.props.targetId,
    });

    this._map.on('click', (e) => this.clickHandler(this._map, e) as any);
  }

  clickHandler(map: any, event: any) {
    if (map === null) {
      return;
    }

    const feature = map.getFeaturesAtPixel(event.pixel, { hitTolerance: 4 })?.find((f: Feature) => {
      return f.getGeometryName() === 'point';
    });

    if (feature) {
      this.props.setActiveMapPointId(feature.get('id'));
    }
  }

  render() {
    return (
      <div
        className={this.props.style}
        id={this.props.targetId}
        style={this.props.size ? this.props.size : { height: '150px', width: '150px' }}
      />
    );
  }
}
