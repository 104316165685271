import * as React from 'react';
import { Group } from '@visx/group';
import { HierarchyPointNode } from '@visx/hierarchy/lib/types';
import { background, removePrefix } from './utils';
import { TTaskNode } from './types';

type HierarchyNodeType = HierarchyPointNode<TTaskNode>;

type BasicNodeProps = {
  id: string;
  fontSize: number;
  x: number;
  y: number;
  height: number;
  width: number;
  centerY: number;
  centerX: number;
  text: string;
  textSmall: string;
  serviceText: string;
  functionText: string;
  color: string;
  background: string;
};

function BasicNode({
  id,
  height,
  width,
  x,
  y,
  centerY,
  centerX,
  text,
  textSmall,
  serviceText,
  color,
  background,
  fontSize,
}: BasicNodeProps) {
  return (
    <Group top={x} left={y}>
      <rect
        data-node-id={id}
        height={height * 2 + 14}
        width={width + 30}
        y={centerY - 10}
        x={centerX}
        fill={background}
        stroke={color}
        strokeWidth={1}
        // strokeOpacity={0.6}
        rx={3}
      />
      <text
        dy="1px"
        dx="16px"
        fontSize={fontSize - 2}
        fontFamily="Arial"
        textAnchor="middle"
        fill={'#AAABAD'}
        style={{ pointerEvents: 'none' }}
      >
        {serviceText}
      </text>
      <text
        dy={`${fontSize + 1}px`}
        dx="16px"
        fontSize={fontSize}
        fontFamily="Arial"
        textAnchor="middle"
        fill={'#333382'}
        style={{ pointerEvents: 'none' }}
      >
        {text}
      </text>
      <text
        dy={`${fontSize * 2 + 1}px`}
        dx="16px"
        fontSize={fontSize - 2}
        fontFamily="Arial"
        textAnchor="middle"
        fill={'#AAABAD'}
        style={{ pointerEvents: 'none' }}
      >
        {textSmall}
      </text>
    </Group>
  );
}

function getColorNode(status): string {
  switch (status) {
    case 'SUCCESS':
      return '#00c3a0';
    case 'ERROR':
      return '#CD0843';
    default:
      return '#737D8F';
  }
}

export function ExecutionHierarchyNode({ node }: { node: HierarchyNodeType }) {
  const mainText = node.data.root.function;
  const serviceNameNoPrefix = removePrefix(node.data.root.service, 'kvinta-');

  const fontSize = 11;
  const textDimensions = { width: 0, height: 0 };
  const textMargin = 2;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.font = `${fontSize}px Arial`;
    const mainTextParams = ctx.measureText(mainText);

    ctx.font = `${fontSize - 2}px Arial`;
    const smallTextParams = ctx.measureText(serviceNameNoPrefix);

    const maxTextWidth = mainTextParams.width > smallTextParams.width ? mainTextParams.width : smallTextParams.width;
    const { actualBoundingBoxAscent, actualBoundingBoxDescent } = mainTextParams;

    textDimensions.width = Math.floor(maxTextWidth) + textMargin;
    textDimensions.height = Math.floor(actualBoundingBoxAscent + actualBoundingBoxDescent) * 2 + textMargin;
  }

  const width = textDimensions.width;
  const height = textDimensions.height;
  const centerX = -width / 2;
  const centerY = -height / 2;
  const color = getColorNode(node.data.lastStatus?.status);

  return (
    <BasicNode
      id={node.data.root.id}
      height={height}
      width={width}
      centerY={centerY}
      centerX={centerX}
      text={mainText}
      textSmall={node.data.root.label}
      serviceText={serviceNameNoPrefix}
      functionText={node.data.root.function}
      x={node.x}
      y={node.y}
      color={color}
      background={background}
      fontSize={fontSize}
    />
  );
}
