/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaNotificationType {
    AggregatedCustomsDocument = 'AGGREGATED_CUSTOMS_DOCUMENT',
    SerialNumberOrder = 'SERIAL_NUMBER_ORDER',
    UtilisationReport = 'UTILISATION_REPORT',
    ShippingEventVerificationResult = 'SHIPPING_EVENT_VERIFICATION_RESULT',
    EventVerificationResult = 'EVENT_VERIFICATION_RESULT'
}

export function KvintaNotificationTypeFromJSON(json: any): KvintaNotificationType {
    return KvintaNotificationTypeFromJSONTyped(json, false);
}

export function KvintaNotificationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaNotificationType {
    return json as KvintaNotificationType;
}

export function KvintaNotificationTypeToJSON(value?: KvintaNotificationType | null): any {
    return value as any;
}

