export const tzInts = [
  { label: '(GMT-12:00)', value: '-12:00' },
  { label: '(GMT-11:00)', value: '-11:00' },
  { label: '(GMT-10:00)', value: '-10:00' },
  { label: '(GMT-09:00)', value: '-09:00' },
  { label: '(GMT-08:00)', value: '-08:00' },
  { label: '(GMT-07:00)', value: '-07:00' },
  { label: '(GMT-06:00)', value: '-06:00' },
  { label: '(GMT-05:00)', value: '-05:00' },
  { label: '(GMT-04:00)', value: '-04:00' },
  { label: '(GMT-03:30)', value: '-03:30' },
  { label: '(GMT-03:00)', value: '-03:00' },
  { label: '(GMT-02:00)', value: '-02:00' },
  { label: '(GMT-01:00)', value: '-01:00' },
  { label: '(GMT+00:00)', value: '00:00' },
  { label: '(GMT+01:00)', value: '01:00' },
  { label: '(GMT+02:00)', value: '02:00' },
  { label: '(GMT+03:00)', value: '03:00' },
  { label: '(GMT+03:30)', value: '03:30' },
  { label: '(GMT+04:00)', value: '04:00' },
  { label: '(GMT+04:30)', value: '04:30' },
  { label: '(GMT+05:00)', value: '05:00' },
  { label: '(GMT+05:30)', value: '05:30' },
  { label: '(GMT+05:45)', value: '05.45' },
  { label: '(GMT+06:00)', value: '06:00' },
  { label: '(GMT+06:30)', value: '06:30' },
  { label: '(GMT+07:00)', value: '07:00' },
  { label: '(GMT+08:00)', value: '08:00' },
  { label: '(GMT+09:00)', value: '09:00' },
  { label: '(GMT+09:30)', value: '09:30' },
  { label: '(GMT+10:00)', value: '10:00' },
  { label: '(GMT+11:00)', value: '11:00' },
  { label: '(GMT+12:00)', value: '12:00' },
  { label: '(GMT+13:00)', value: '13:00' },
];
