import { action, makeObservable, observable, runInAction } from 'mobx';
import { TAppOptionsConfig } from 'kvinta/common/Interfaces';
import { AuthService, EKeycloakEventTypes } from 'kvinta/service/auth';
import { NotificationManager } from './NotificationManager';

export interface TUserInfo {
  email?: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  lang?: string;
  logoutUrl?: string;
}

export class UserStore {
  private _authService: AuthService;
  private _config: TAppOptionsConfig;
  private _notificationManager: NotificationManager;

  userInfo: TUserInfo;

  constructor(config: TAppOptionsConfig, authS: AuthService, notificationManager: NotificationManager) {
    makeObservable(this, {
      userInfo: observable,
      updateInfo: action,
    });
    this._config = config;
    this._authService = authS;
    this._notificationManager = notificationManager;
    this.updateInfo();
    authS.on(EKeycloakEventTypes.TOKEN_EXPIRED, () => {
      this._authService.updateToken();
    });
    authS.on(EKeycloakEventTypes.TOKEN_UPDATED, () => {
      this.updateInfo();
    });
    // authS.on(EKeycloakEventTypes.ERROR_UPDATING_TOKEN, () => {});
  }

  updateInfo = () => {
    this._authService
      .loadUserProfile()
      .then((user) => {
        const usr = user as any;
        const lang =
          (this._config.availableLanguages || []).find(
            (lang) => lang === (Array.isArray(usr.attributes?.lang) && usr.attributes.lang[0]),
          ) || 'en';
        const newUserInfo = {
          lang: lang,
          firstName: user.firstName,
          lastName: user.lastName,
          username: user.username,
          email: user.email,
        };
        runInAction(() => {
          this.userInfo = { ...newUserInfo };
        });
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(err.toString());
      });
  };

  logout = () => {
    this._authService.logout();
  };
}

export const STORE_ID = 'userStore';
