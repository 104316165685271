/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Query Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaRelevantLocationChange,
    KvintaRelevantLocationChangeFromJSON,
    KvintaRelevantLocationChangeFromJSONTyped,
    KvintaRelevantLocationChangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetRelevantChangesLocationsResponse
 */
export interface KvintaGetRelevantChangesLocationsResponse {
    /**
     * 
     * @type {Array<KvintaRelevantLocationChange>}
     * @memberof KvintaGetRelevantChangesLocationsResponse
     */
    relevantChanges?: Array<KvintaRelevantLocationChange> | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetRelevantChangesLocationsResponse
     */
    lastKnownLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetRelevantChangesLocationsResponse
     */
    lastKnownDisposition?: string | null;
}

export function KvintaGetRelevantChangesLocationsResponseFromJSON(json: any): KvintaGetRelevantChangesLocationsResponse {
    return KvintaGetRelevantChangesLocationsResponseFromJSONTyped(json, false);
}

export function KvintaGetRelevantChangesLocationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetRelevantChangesLocationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'relevantChanges': !exists(json, 'relevantChanges') ? undefined : (json['relevantChanges'] === null ? null : (json['relevantChanges'] as Array<any>).map(KvintaRelevantLocationChangeFromJSON)),
        'lastKnownLocation': !exists(json, 'lastKnownLocation') ? undefined : json['lastKnownLocation'],
        'lastKnownDisposition': !exists(json, 'lastKnownDisposition') ? undefined : json['lastKnownDisposition'],
    };
}

export function KvintaGetRelevantChangesLocationsResponseToJSON(value?: KvintaGetRelevantChangesLocationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'relevantChanges': value.relevantChanges === undefined ? undefined : (value.relevantChanges === null ? null : (value.relevantChanges as Array<any>).map(KvintaRelevantLocationChangeToJSON)),
        'lastKnownLocation': value.lastKnownLocation,
        'lastKnownDisposition': value.lastKnownDisposition,
    };
}


