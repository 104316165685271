/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSequenceSummary,
    KvintaSequenceSummaryFromJSON,
    KvintaSequenceSummaryFromJSONTyped,
    KvintaSequenceSummaryToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetListResultSequenceSummary
 */
export interface KvintaGetListResultSequenceSummary {
    /**
     * 
     * @type {Array<KvintaSequenceSummary>}
     * @memberof KvintaGetListResultSequenceSummary
     */
    list?: Array<KvintaSequenceSummary> | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaGetListResultSequenceSummary
     */
    total?: number | null;
}

export function KvintaGetListResultSequenceSummaryFromJSON(json: any): KvintaGetListResultSequenceSummary {
    return KvintaGetListResultSequenceSummaryFromJSONTyped(json, false);
}

export function KvintaGetListResultSequenceSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetListResultSequenceSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': !exists(json, 'list') ? undefined : (json['list'] === null ? null : (json['list'] as Array<any>).map(KvintaSequenceSummaryFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function KvintaGetListResultSequenceSummaryToJSON(value?: KvintaGetListResultSequenceSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': value.list === undefined ? undefined : (value.list === null ? null : (value.list as Array<any>).map(KvintaSequenceSummaryToJSON)),
        'total': value.total,
    };
}


