/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSequenceStatusDependencyRecord,
    KvintaSequenceStatusDependencyRecordFromJSON,
    KvintaSequenceStatusDependencyRecordFromJSONTyped,
    KvintaSequenceStatusDependencyRecordToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetListResultSequenceStatusDependencyRecord
 */
export interface KvintaGetListResultSequenceStatusDependencyRecord {
    /**
     * 
     * @type {Array<KvintaSequenceStatusDependencyRecord>}
     * @memberof KvintaGetListResultSequenceStatusDependencyRecord
     */
    list?: Array<KvintaSequenceStatusDependencyRecord> | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaGetListResultSequenceStatusDependencyRecord
     */
    total?: number | null;
}

export function KvintaGetListResultSequenceStatusDependencyRecordFromJSON(json: any): KvintaGetListResultSequenceStatusDependencyRecord {
    return KvintaGetListResultSequenceStatusDependencyRecordFromJSONTyped(json, false);
}

export function KvintaGetListResultSequenceStatusDependencyRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetListResultSequenceStatusDependencyRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': !exists(json, 'list') ? undefined : (json['list'] === null ? null : (json['list'] as Array<any>).map(KvintaSequenceStatusDependencyRecordFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function KvintaGetListResultSequenceStatusDependencyRecordToJSON(value?: KvintaGetListResultSequenceStatusDependencyRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': value.list === undefined ? undefined : (value.list === null ? null : (value.list as Array<any>).map(KvintaSequenceStatusDependencyRecordToJSON)),
        'total': value.total,
    };
}


