import { action, makeObservable, observable, runInAction } from 'mobx';
import { TAppOptionsConfig } from 'kvinta/common/Interfaces';
import {
  DefaultApi as EDocumentApi,
  KvintaEpcisDocument,
  KvintaEpcisDocumentFilters,
  KvintaEpcisDocumentRecord,
  KvintaSortDirection,
  KvintaSortExpressions,
} from 'kvinta/apis/kvinta-epcis-document-store';
import { DefaultApi as MDDocumentApi, KvintaLocation } from 'kvinta/apis/kvinta-masterdata-service';
import { DefaultApi as ArlaDocumentApi, KvintaInelMessageBizStep } from 'kvinta/apis/kvinta-arla-extensions';
import { NotificationManager } from 'kvinta/modules/main';
import { KvintaOperationStatus } from 'kvinta/apis/kvinta-document-store/models';
import JSZip from 'jszip';
import { IFilter } from 'kvinta/components';

export interface VEPCISMessageRow {
  location: string;
  createDate: string;
  recordDate: string;
  earliestEventDate: string;
  latestEventDate: string;
  eventNumber: number;
  id: string;
}

export interface UploadFormDialogData {
  location: string;
  timezone: string;
  timestamp: string;
  bizStep: KvintaInelMessageBizStep;
  selectedFile: any;
  showError: boolean;
}

export class EpcisMessagesStore {
  private _config: TAppOptionsConfig;
  private _epcisDocumentApi: EDocumentApi;
  private _mdApi: MDDocumentApi;
  private _arlaApi: ArlaDocumentApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  listData: VEPCISMessageRow[];
  page: number;
  totalCount: number;
  pageSize: number;
  currentSort: KvintaSortExpressions;
  csvFormOpen: boolean;

  filter: IFilter;

  csvUploadData?: UploadFormDialogData;
  locations: KvintaLocation[];
  currentMessage: KvintaEpcisDocument;

  //summary
  jsonPayload?: string;

  constructor(
    config: TAppOptionsConfig,
    notificationManager: NotificationManager,
    epcisDocumentApi: EDocumentApi,
    mdApi: MDDocumentApi,
    arlaApi: ArlaDocumentApi,
  ) {
    makeObservable(this, {
      fetchPage: action.bound,
      openUploadCSVForm: action,
      closeUploadCSVForm: action,
      onChangeCSVField: action,
      isLoading: observable,
      listData: observable,
      page: observable,
      pageSize: observable,
      csvFormOpen: observable,
      csvUploadData: observable,
      filter: observable,
      jsonPayload: observable,
      currentMessage: observable,

      submitUploadCSVForm: action.bound,
    });

    this._config = config;
    this._epcisDocumentApi = epcisDocumentApi;
    this._mdApi = mdApi;
    this._arlaApi = arlaApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.currentSort = {
      expressions: [
        {
          direction: KvintaSortDirection.Desc,
          property: 'createDate',
        },
      ],
    };
    this.filter = new IFilter(
      [
        {
          field: 'id',
          label: 'epcis-document.id',
          isActive: true,
          value: '',
        },
        {
          field: 'location',
          label: 'epcis-document.location',
          isActive: false,
          value: '',
        },
      ],
      this.doFilter,
    );
    this.csvFormOpen = false;
  }

  doFilter = async () => {
    runInAction(() => {
      this.isLoading = true;
      this.page = 0;
    });
    this.fetchData();
  };

  async fetchPage(page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
    });
    this.fetchData();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    runInAction(() => {
      this.isLoading = true;
      const dir = getDirection(orderDirection);
      const field = getField(orderBy);
      this.currentSort = {
        expressions: [
          {
            direction: dir,
            property: field,
          },
        ],
      };
    });
    this.fetchData();
  }

  async fetchData() {
    this.isLoading = true;
    let filters = {} as KvintaEpcisDocumentFilters;

    for (const filter of this.filter.visibleFilters) {
      if (filter.field === 'id' && filter.value !== '') {
        filters = {
          ...filters,
          idLike: filter.value,
        };
      }
      if (filter.field === 'location' && filter.value !== '') {
        filters = {
          ...filters,
          locationLike: filter.value,
        };
      }
    }

    this._epcisDocumentApi
      .listEpcisDocuments({
        kvintaOperationRequestListEpcisDocumentsRequest: {
          input: { paging: { page: this.page, size: this.pageSize, sort: this.currentSort }, filters: filters },
        },
      })
      .then((result) => {
        runInAction(() => {
          this.isLoading = false;
          if (result.status === KvintaOperationStatus.Error) {
            this.listData = [];
          } else {
            this.listData = kepcDocRecToView(result.data.list || []);
            this.totalCount = result.data.total || 0;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async openUploadCSVForm() {
    this.isLoading = true;
    this._mdApi
      .getLocationList({
        kvintaGetListRequest: { pagination: { page: 0, perPage: 10000 } },
      })
      .then((result) => {
        runInAction(() => {
          runInAction(() => {
            if (result.operationStatus === KvintaOperationStatus.Error) {
              this.locations = [];
            } else {
              this.locations = result.data;
            }
            this.csvUploadData = {
              location: '',
              bizStep: KvintaInelMessageBizStep.Commissioning,
              selectedFile: undefined,
              timestamp: new Date().toISOString().slice(0, 16),
              timezone: '00:00',
              showError: false,
            };
            this.isLoading = false;
            this.csvFormOpen = true;
          });
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  closeUploadCSVForm() {
    runInAction(() => {
      this.csvFormOpen = false;
    });
  }

  onChangeCSVField = (id: string, value: any) => {
    this.csvUploadData[id] = value;
    this.csvUploadData.showError =
      this.csvUploadData.location !== '' &&
      this.csvUploadData.selectedFile !== undefined &&
      this.csvUploadData.timestamp !== '' &&
      this.csvUploadData.timezone !== '';
  };

  onLoadJSON = (id: string) => {
    this.currentMessage = undefined;
    this._epcisDocumentApi
      .readFrontendEpcisDocumentById({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        runInAction(() => {
          if (result.data) {
            this.currentMessage = result.data;

            this.jsonPayload = JSON.stringify(result.data, null, 2);
          } else {
            this.jsonPayload = 'No data available';
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(err);
      });
  };

  submitUploadCSVForm = () => {
    const zip = new JSZip();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    let hasError = false;
    for (const field of requiredFields) {
      if (errorRequired(field, this.csvUploadData[field])) {
        hasError = true;
        break;
      }
    }
    this.csvUploadData.showError = hasError;
    if (!hasError) {
      runInAction(() => {
        newLocal.csvFormOpen = false;
        newLocal.isLoading = true;
      });
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const comp = this;
      zip.file(this.csvUploadData.selectedFile.name, this.csvUploadData.selectedFile);
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        blobToBase64(content).then((res) => {
          newLocal._arlaApi
            .uploadCsvInelMessage({
              kvintaOperationRequestUploadInelMessageRequest: {
                input: {
                  messageFields: {
                    location: newLocal.csvUploadData.location,
                    timestamp: new Date(newLocal.csvUploadData.timestamp).getTime(),
                    timezone: newLocal.csvUploadData.timezone,
                    bizStep: newLocal.csvUploadData.bizStep,
                  },
                  encodedFile: res as string,
                },
              },
            })
            .then((res) => {
              runInAction(() => {
                newLocal.isLoading = false;
              });
              if (res.status !== KvintaOperationStatus.Ok) {
                comp._notificationManager.sendError(res.error);
              } else {
                comp._notificationManager.sendSuccess(res.data.id);
              }
              newLocal.fetchData();
            })
            .catch((err) => {
              comp._notificationManager.sendError(JSON.stringify(err));
            });
        });
      });
    }
  };
}

function kepcDocRecToView(list: KvintaEpcisDocumentRecord[]): VEPCISMessageRow[] {
  const viewItems = list.map<VEPCISMessageRow>((doc) => {
    return {
      location: doc.location,
      createDate: new Date(doc.createDate.epochMillis).toISOString(),
      recordDate: new Date(doc.recordDate).toISOString(),
      earliestEventDate: new Date(doc.earliestEventDate.epochMillis).toISOString(),
      latestEventDate: new Date(doc.latestEventDate.epochMillis).toISOString(),
      eventNumber: doc.eventsCount,
      id: doc.id,
    };
  });
  return viewItems;
}

export const EPCIS_MESSAGES_STORE_ID = 'epcisMessagesStore';

function getDirection(orderDirection: string): KvintaSortDirection {
  if (orderDirection === 'asc') {
    return KvintaSortDirection.Asc;
  } else {
    return KvintaSortDirection.Desc;
  }
}

function getField(orderBy: number): string {
  switch (orderBy) {
    case 1:
      return 'location';
    case 2:
      return 'createDate';
    case 3:
      return 'recordDate';
    case 4:
      return 'earliestEventDate';
    case 5:
      return 'latestEventDate';
    case 6:
      return 'eventsCount';
    case 7:
      return 'id';
    default:
      return 'createDate';
  }
}

const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const requiredFields = ['location', 'bizStep', 'selectedFile'];

export function errorRequired(id: string, value: any): boolean {
  if (requiredFields.includes(id) && (value === undefined || value === '')) {
    return true;
  }
  return false;
}
