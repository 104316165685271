import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ISelectableRow, SelectableStore, TAppOptionsConfig } from 'kvinta/common';
import {
  DefaultApi as ArlaExtStoreApi,
  KvintaLgtinProduct,
  KvintaOperationResponseListEpcVerificationResult,
  KvintaResource,
  KvintaResourceType,
  KvintaShipmentAggregatedCustomsDocument,
  KvintaSoftwareSystem,
  KvintaSortDirection,
  KvintaSortExpressions,
} from 'kvinta/apis/kvinta-arla-extensions';
import {
  DefaultApi as MDDocumentApi,
  KvintaLocation,
  KvintaOperationStatus,
} from 'kvinta/apis/kvinta-masterdata-service';

import { NotificationManager } from 'kvinta/modules/main';
import { IFilter, IFilterColumn, PageContentStore } from 'kvinta/components';
import { addHours, format, parse, startOfDay } from 'date-fns';
import { downloadFile } from '../../../service/fileExport';

export interface ListRowRecord {
  id: string;
  eventTime: string;
  recordTime: string;
  bizLocation: string;
  bizTransaction?: string;
  aggregatedCustomsDocument: string | undefined;
}

interface ShippingProductListRowRecord {
  id: string;
  sku?: string;
  productName?: string;
  gtin?: string;
  tnved?: string;
  prodBatch?: string;
  prodDate?: number;
  topQuantity?: number;
  sgtinQuantity?: number;
}

export interface IICItems {
  vsdNumber?: string;
  productionDate?: number;
  prodBatch?: string;
  gtin?: string;

  vsdNumberError?: string;
  productionDateError?: number;
  prodBatchError?: string;
  gtinError?: string;
}

export interface IntroduceIntoCirculationData {
  participantInn?: string;
  gtd?: string;
  gtdDate?: Date;
  productsList: IICItems[];

  participantInnError?: string;
  gtdError?: string;
  gtdDateError?: string;
}

interface IShippingProductRow extends ShippingProductListRowRecord, ISelectableRow {}

// class ProductsShippingStore extends SelectableStore<IShippingProductRow> {

export class ShippingStore extends SelectableStore<IShippingProductRow> {
  private _config: TAppOptionsConfig;
  private _arlaApi: ArlaExtStoreApi;
  private _mdApi: MDDocumentApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  shippingsData: ListRowRecord[];
  currentVerificationResult: KvintaOperationResponseListEpcVerificationResult;
  page: number;
  totalCount: number;
  pageSize: number;
  currentSort: KvintaSortExpressions;

  filter: IFilter;

  // json
  currentShipping?: KvintaShipmentAggregatedCustomsDocument;
  currentExecutionId?: string;

  pageContentStore: PageContentStore;
  createATKDialogOpen: boolean;
  taxNumber: string;

  introduceIntoCirculationDialogOpen: boolean;

  iicData: IntroduceIntoCirculationData;

  constructor(
    config: TAppOptionsConfig,
    notificationManager: NotificationManager,
    arlaApi: ArlaExtStoreApi,
    mdApi: MDDocumentApi,
    pageContentStore: PageContentStore,
  ) {
    super();
    makeObservable(this, {
      fetchPage: action.bound,
      loadShippingEvent: action.bound,
      currentVerificationResult: observable,
      setFilter: action,

      isLoading: observable,
      shippingsData: observable,
      page: observable,
      pageSize: observable,
      filter: observable,

      currentShipping: observable,
      currentExecutionId: observable,

      hasCreateATKButton: computed,

      createATKDialogOpen: observable,
      openCreateATKDialog: action,
      submitATKDialog: action.bound,
      closeATKDialog: action.bound,

      introduceIntoCirculationDialogOpen: observable,
      openIntroduceIntoCirculationDialog: action,
      closeIntroduceIntoCirculationDialog: action,
      submitIntroduceIntoCirculationDialog: action.bound,
      iicData: observable,
      changeGtdDate: action,
    });

    this._config = config;
    this._arlaApi = arlaApi;
    this._mdApi = mdApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.pageContentStore = pageContentStore;
    this.taxNumber = this._config.defaultTaxNumber;
    this.createATKDialogOpen = false;
    this.isLoading = true;

    // TODO: Initial sort and order
    this.currentSort = {
      expressions: [
        {
          direction: KvintaSortDirection.Desc,
          property: 'recordTime',
        },
      ],
    };
  }

  doFilter = async () => {
    runInAction(() => {
      this.isLoading = true;
      this.page = undefined;
      this.shippingsData = [];
    });
    this.fetchData();
  };

  setFilter(columns: IFilterColumn[]) {
    this.filter = new IFilter(columns, this.doFilter, true);
  }

  async fetchPage(page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
    });
    this.fetchData();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    runInAction(() => {
      const dir = getDirection(orderDirection);
      const fielistData = getFielistData(orderBy);
      this.currentSort = {
        expressions: [
          {
            direction: dir,
            property: fielistData,
          },
        ],
      };
    });
    this.fetchData();
  }

  fetchData = async () => {
    runInAction(() => {
      this.shippingsData = [];
      this.isLoading = true;
      this.totalCount = 0;
    });
    let location = undefined;
    if (this.filter) {
      for (const filter of this.filter.visibleFilters) {
        if (filter.field === 'locationSGln') {
          location = filter.value;
        }
      }
    }
    try {
      const result = await this._arlaApi.listShipmentEventsWithAggregatedCustomsDocuments({
        kvintaOperationRequestListShipmentAggregatedCustomsDocumentsRequest: {
          input: {
            paging: { page: this.page, size: this.pageSize, sort: this.currentSort },
            locationGln: location, // TODO
            dateTimeRange: undefined, // TODO
          },
        },
      });
      runInAction(() => {
        this.isLoading = false;
        if (result.status === KvintaOperationStatus.Error) {
          this.shippingsData = [];
        } else {
          this.shippingsData = kShippingRecToView(result.data.list || []);
          this.totalCount = result.data.total || 0;
        }
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;

        this.shippingsData = [];
        this.totalCount = 0;
        this._notificationManager.sendError(JSON.stringify(err));
      });
    }
  };

  formatDateTime(timestamp: number): string {
    return format(new Date(timestamp), this._config.defaultDateTimeFormatting);
  }

  async loadShippingEvent(id: string) {
    this.currentShipping = undefined;
    this.currentExecutionId = undefined;
    this._arlaApi
      .readShipmentEventWithAggregatedCustomsDocuments({
        kvintaOperationRequestReadShipmentAggregatedCustomsDocumentsRequest: {
          input: {
            eventId: id,
          },
        },
      })
      .then((result) => {
        runInAction(() => {
          if (result.status === KvintaOperationStatus.Ok) {
            this.currentShipping = result.data;
            this.currentExecutionId = result.executionId;
          } else {
            this._notificationManager.sendError(result.error);
          }
        });
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(err.toString());
      });
  }

  fetchLocations = async (): Promise<KvintaLocation[]> => {
    const locationsReq = await this._mdApi.getLocationList({
      kvintaGetListRequest: {
        pagination: { page: 0, perPage: 10000 },
      },
    });
    return locationsReq.data;
  };

  openCreateATKDialog() {
    this.createATKDialogOpen = true;
  }

  get hasCreateATKButton() {
    return !(
      this.currentShipping &&
      this.currentShipping.aggregatedCustomsDocumentRecord &&
      this.currentShipping.aggregatedCustomsDocumentRecord.resource &&
      this.currentShipping.aggregatedCustomsDocumentRecord.resource.id
    );
  }

  async submitATKDialog() {
    this.isLoading = true;
    const eventId = this.currentShipping.epcisEventRecord.eventID;

    try {
      const createResult = await this._arlaApi.createATKForShipmentEvent({
        kvintaOperationRequestCreateATKForShipmentEventRequest: {
          input: {
            eventId,
            taxNumber: this.taxNumber,
          },
        },
      });
      if (createResult.status === KvintaOperationStatus.Ok) {
        this.loadShippingEvent(this.currentShipping.epcisEventRecord.id);
        runInAction(() => {
          this.createATKDialogOpen = false;
        });
      } else {
        runInAction(() => {
          this.isLoading = true;
          this._notificationManager.sendError(createResult.error);
          this.createATKDialogOpen = false;
        });
      }
    } catch (err) {
      runInAction(() => {
        this.isLoading = true;
        this._notificationManager.sendError(JSON.stringify(err));
        this.createATKDialogOpen = false;
      });
    }
  }

  closeATKDialog(): void {
    this.createATKDialogOpen = false;
  }

  fetchShippingProductListData = async (id: string) => {
    runInAction(() => {
      this.listData = [];
      this.isLoading = true;
      this.totalCount = 0;
    });

    try {
      await this.loadShippingEvent(id);
      const result = await this._arlaApi.readShipmentEventProducts({
        kvintaOperationRequestReadEpcisEventWithProductsRequest: {
          input: {
            reportLevel: 1,
            eventId: id,
          },
        },
      });
      runInAction(() => {
        if (result.status === KvintaOperationStatus.Error) {
          this.listData = [];
          this.isLoading = false;
          this._notificationManager.sendError(JSON.stringify(result.error));
        }
        if (!result.data.products) {
          this.listData = [];
          this.isLoading = false;
        } else {
          this.listData = result.data.products.map((product) => ({
            id: Math.random().toString(),
            ...product,
            isSelected: false,
          }));

          this.totalCount = result.data.products.length || 0;
          this.isLoading = false;
        }
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;

        this.listData = [];
        this._notificationManager.sendError(JSON.stringify(err));
      });
    }
  };

  exportProducts = async (eventId: string, fileName) => {
    await this._arlaApi
      .exportEventProductsListToCsv({
        kvintaOperationRequestReadEpcisEventWithProductsRequest: {
          input: {
            eventId,
            reportLevel: 1,
          },
        },
      })
      .then((result) => {
        downloadFile(fileName, 'text/csv', result);
      })
      .catch((e) => {
        this._notificationManager.sendError(e.message);
      });
  };

  // ------------------------- Introduce into circulation
  openIntroduceIntoCirculationDialog = () => {
    const selectedProducts = new Array<IICItems>();
    const selectedRows = this.listChecked;
    for (const row of selectedRows) {
      selectedProducts.push({
        gtin: row.gtin,
        prodBatch: row.prodBatch,
        productionDate: row.prodDate && setDateToNoon(row.prodDate).getTime(),
      } as IICItems);
    }
    runInAction(() => {
      this.iicData = {
        participantInn: this.taxNumber,
        gtdDate: setDateToNoon(new Date()),
        productsList: selectedProducts,
        gtd: '',
      } as IntroduceIntoCirculationData;
      this.introduceIntoCirculationDialogOpen = true;
    });
  };

  closeIntroduceIntoCirculationDialog(): void {
    this.introduceIntoCirculationDialogOpen = false;
  }

  changeGtdOrder = (value: any) => {
    runInAction(() => {
      this.iicData = { ...this.iicData, gtd: value };

      const gtdDate = extractDateFromGtd(value);
      if (gtdDate !== undefined) {
        this.iicData = { ...this.iicData, gtdDate };
      }
    });
  };

  onChangeItemProdDate(rowData: IICItems, value: any) {
    const pList = this.iicData.productsList;
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].gtin === rowData.gtin && pList[i].prodBatch == rowData.prodBatch) {
        pList[i].productionDate = value;
        break;
      }
    }
    runInAction(() => {
      this.iicData = { ...this.iicData, productsList: pList };
    });
  }

  onChangeItemVSDNumber(rowData: IICItems, value: string): void {
    const pList = this.iicData.productsList;
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].gtin === rowData.gtin && pList[i].prodBatch == rowData.prodBatch) {
        pList[i].vsdNumber = value;
        break;
      }
    }
    runInAction(() => {
      this.iicData = { ...this.iicData, productsList: pList };
    });
  }

  changeGtdDate(value: string) {
    const parsedDate = parse(value, 'yyyy-MM-dd', new Date());
    if (parsedDate && format(parsedDate, 'yyyy-MM-dd') === value) {
      this.iicData = { ...this.iicData, gtdDate: setDateToNoon(parsedDate) };
    }
  }

  submitIntroduceIntoCirculationDialog = async () => {
    runInAction(() => {
      this.isLoading = true;
    });
    // Validate
    const pList = this.iicData.productsList;
    let hasError = false;
    const v4RE = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].vsdNumber && pList[i].vsdNumber != '' && !pList[i].vsdNumber.match(v4RE)) {
        pList[i].vsdNumberError = 'Invalid UUID';
        hasError = true;
      } else {
        pList[i].vsdNumberError = null;
      }
    }
    const gtdRE = new RegExp(/^([0-9]{8})\/([0-9]{6})\/([0-9]{7})$/i);
    const matchRes = this.iicData.gtd.match(gtdRE);
    if (!matchRes) {
      runInAction(() => {
        this.iicData = { ...this.iicData, productsList: pList, gtdError: 'Invalid format' };
      });
      hasError = true;
    } else {
      const dtIn = matchRes[2];
      try {
        const dt = parse(dtIn, 'ddMMyy', new Date());
        if (format(dt, 'ddMMyy') !== dtIn) {
          runInAction(() => {
            this.isLoading = false;
            this.iicData = { ...this.iicData, productsList: pList, gtdError: 'Invalid format' };
          });
          hasError = true;
        } else {
          runInAction(() => {
            this.iicData = { ...this.iicData, productsList: pList, gtdError: null };
          });
        }
      } catch (err) {
        runInAction(() => {
          this.isLoading = false;
          this.iicData = { ...this.iicData, productsList: pList, gtdError: 'Invalid format' };
        });
        hasError = true;
      }
    }

    if (hasError) {
      return;
    }
    if (!hasError) {
      const prods = new Array<KvintaLgtinProduct>();
      for (const p of this.iicData.productsList) {
        prods.push({
          gtin: p.gtin,
          prodBatch: p.prodBatch,
          productionDate: p.productionDate,
          vsdNumber: p.vsdNumber !== undefined && p.vsdNumber !== null ? p.vsdNumber : '',
        } as KvintaLgtinProduct);
      }
      try {
        const fields = {
          gtd: this.iicData.gtd,
          participantInn: this.iicData.participantInn,
          gtdDate: new Date(this.iicData.gtdDate).getTime(),
          productsList: prods,
        };
        const eventId = this.currentShipping.epcisEventRecord.eventID;
        const newResource = {
          system: KvintaSoftwareSystem.Kvinta,
          type: KvintaResourceType.EventEpcMetadataList, // "EVENT_EPC_METADATA_LIST",
          id: `${eventId}-level-1`,
          parentResourceId: eventId,
        } as KvintaResource;
        const res = await this._arlaApi.transformEpcMetadataListToCirculationReport({
          kvintaOperationRequestCreateCirculationReportForEpcMetadataListRequest: {
            input: {
              fields: fields,
              resource: newResource,
            },
          },
        });
        if (res.status !== KvintaOperationStatus.Ok) {
          this._notificationManager.sendError(res.error);
        } else {
          this._notificationManager.sendInformation('Document ' + res.data.resource.id + ' introduced in circulation');
        }
      } catch (err) {
        this._notificationManager.sendError(JSON.stringify(err));
      }
    }

    runInAction(() => {
      this.isLoading = false;
      this.introduceIntoCirculationDialogOpen = false;
    });
  };

  async loadVerificationResults(id: string) {
    runInAction(() => {
      this.isLoading = true;
      this.totalCount = 0;
      this.currentVerificationResult = null;
    });

    this._arlaApi
      .readVerificationEventResult({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        runInAction(() => {
          if (result.data) {
            this.currentVerificationResult = result;
            this.totalCount = result.data.length;
          } else {
            this.currentVerificationResult = {
              data: [
                {
                  valid: true,
                },
              ],
            };
          }
          this.isLoading = false;
        });
      });
  }
}

function kShippingRecToView(list: KvintaShipmentAggregatedCustomsDocument[]): ListRowRecord[] {
  const viewItems: ListRowRecord[] = [];
  for (const record of list) {
    viewItems.push({
      id: record.epcisEventRecord.id,
      bizLocation: record.epcisEventRecord.bizLocation,
      bizTransaction: record.epcisEventRecord.bizTransaction,
      eventTime: new Date(record.epcisEventRecord.eventTime.epochMillis).toISOString(),
      recordTime: new Date(record.epcisEventRecord.recordTime).toISOString(),
      aggregatedCustomsDocument:
        record.aggregatedCustomsDocumentRecord && record.aggregatedCustomsDocumentRecord.resource
          ? record.aggregatedCustomsDocumentRecord.resource.id
          : '',
    });
  }
  return viewItems;
}

export const SHIPPING_STORE_ID = 'shippingStore';

function getDirection(orderDirection: string): KvintaSortDirection {
  if (orderDirection === 'asc') {
    return KvintaSortDirection.Asc;
  } else {
    return KvintaSortDirection.Desc;
  }
}

function getFielistData(orderBy: number): string {
  switch (orderBy) {
    case 0:
      return 'eventTime';
    case 1:
      return 'recordTime';
    case 3:
      return 'bizTransaction';
    case 4:
      return 'bizLocation';
    case 5:
      return 'aggregatedCustomsDocument';
    default:
      return 'eventTime';
  }
}

function extractDateFromGtd(maybeGtd: string): Date | undefined {
  const gtdRegexp = new RegExp(/^([0-9]{8})\/([0-9]{6})\/([0-9]{7})$/i);
  const matchRes = maybeGtd.match(gtdRegexp);
  const gtdDate = matchRes && matchRes[2];

  const parsedDate = gtdDate && parse(gtdDate, 'ddMMyy', new Date());
  if (parsedDate && format(parsedDate, 'ddMMyy') === gtdDate) {
    return setDateToNoon(parsedDate);
  } else {
    return undefined;
  }
}

export function setDateToNoon(date: Date | number) {
  return addHours(startOfDay(date), 12);
}
