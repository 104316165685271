export const EpcisEventsPath = '/epcis-events';
export const EpcisEventPath = '/epcis-event/:id';
export const EpcisEventJsonPath = '/epcis-event/:id/json';

export function navigateEpcisEventsPath(history: any) {
  history.push(EpcisEventsPath);
}

export function navigateEpcisEventPath(history: any, msgId: string) {
  history.push(EpcisEventPath.replaceAll(':id', msgId));
}

export function linkEpcisEventPath(history: any, msgId: string): string {
  return EpcisEventPath.replaceAll(':id', msgId);
}

export function navigateEpcisEventJsonPath(history: any, msgId: string) {
  history.push(EpcisEventJsonPath.replaceAll(':id', msgId));
}
