/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSerialNumberOrderStatusType,
    KvintaSerialNumberOrderStatusTypeFromJSON,
    KvintaSerialNumberOrderStatusTypeFromJSONTyped,
    KvintaSerialNumberOrderStatusTypeToJSON,
    KvintaTimestamp,
    KvintaTimestampFromJSON,
    KvintaTimestampFromJSONTyped,
    KvintaTimestampToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaSerialNumberOrderStatus
 */
export interface KvintaSerialNumberOrderStatus {
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrderStatus
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrderStatus
     */
    orderId?: string;
    /**
     * 
     * @type {KvintaTimestamp}
     * @memberof KvintaSerialNumberOrderStatus
     */
    timestamp?: KvintaTimestamp;
    /**
     * 
     * @type {KvintaSerialNumberOrderStatusType}
     * @memberof KvintaSerialNumberOrderStatus
     */
    status?: KvintaSerialNumberOrderStatusType;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrderStatus
     */
    error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSerialNumberOrderStatus
     */
    message?: string | null;
}

export function KvintaSerialNumberOrderStatusFromJSON(json: any): KvintaSerialNumberOrderStatus {
    return KvintaSerialNumberOrderStatusFromJSONTyped(json, false);
}

export function KvintaSerialNumberOrderStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSerialNumberOrderStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'orderId': !exists(json, 'orderId') ? undefined : json['orderId'],
        'timestamp': !exists(json, 'timestamp') ? undefined : KvintaTimestampFromJSON(json['timestamp']),
        'status': !exists(json, 'status') ? undefined : KvintaSerialNumberOrderStatusTypeFromJSON(json['status']),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function KvintaSerialNumberOrderStatusToJSON(value?: KvintaSerialNumberOrderStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'orderId': value.orderId,
        'timestamp': KvintaTimestampToJSON(value.timestamp),
        'status': KvintaSerialNumberOrderStatusTypeToJSON(value.status),
        'error': value.error,
        'message': value.message,
    };
}


