/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaGetTradeItemsByProductRequest
 */
export interface KvintaGetTradeItemsByProductRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaGetTradeItemsByProductRequest
     */
    productId: string;
}

export function KvintaGetTradeItemsByProductRequestFromJSON(json: any): KvintaGetTradeItemsByProductRequest {
    return KvintaGetTradeItemsByProductRequestFromJSONTyped(json, false);
}

export function KvintaGetTradeItemsByProductRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetTradeItemsByProductRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['productId'],
    };
}

export function KvintaGetTradeItemsByProductRequestToJSON(value?: KvintaGetTradeItemsByProductRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
    };
}


