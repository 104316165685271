/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcReportingStatusType,
    KvintaEpcReportingStatusTypeFromJSON,
    KvintaEpcReportingStatusTypeFromJSONTyped,
    KvintaEpcReportingStatusTypeToJSON,
    KvintaEpcisBizStep,
    KvintaEpcisBizStepFromJSON,
    KvintaEpcisBizStepFromJSONTyped,
    KvintaEpcisBizStepToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcisEventFilters
 */
export interface KvintaEpcisEventFilters {
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventFilters
     */
    bizLocation?: string | null;
    /**
     * 
     * @type {KvintaEpcisBizStep}
     * @memberof KvintaEpcisEventFilters
     */
    bizStep?: KvintaEpcisBizStep;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventFilters
     */
    idLike?: string | null;
    /**
     * 
     * @type {KvintaEpcReportingStatusType}
     * @memberof KvintaEpcisEventFilters
     */
    reportingStatus?: KvintaEpcReportingStatusType;
}

export function KvintaEpcisEventFiltersFromJSON(json: any): KvintaEpcisEventFilters {
    return KvintaEpcisEventFiltersFromJSONTyped(json, false);
}

export function KvintaEpcisEventFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisEventFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bizLocation': !exists(json, 'bizLocation') ? undefined : json['bizLocation'],
        'bizStep': !exists(json, 'bizStep') ? undefined : KvintaEpcisBizStepFromJSON(json['bizStep']),
        'idLike': !exists(json, 'idLike') ? undefined : json['idLike'],
        'reportingStatus': !exists(json, 'reportingStatus') ? undefined : KvintaEpcReportingStatusTypeFromJSON(json['reportingStatus']),
    };
}

export function KvintaEpcisEventFiltersToJSON(value?: KvintaEpcisEventFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bizLocation': value.bizLocation,
        'bizStep': KvintaEpcisBizStepToJSON(value.bizStep),
        'idLike': value.idLike,
        'reportingStatus': KvintaEpcReportingStatusTypeToJSON(value.reportingStatus),
    };
}


