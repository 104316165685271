import { format } from 'date-fns';
import {
  DefaultApi as ArlaApi,
  KvintaSortExpressions,
  KvintaSortDirection,
  KvintaOperationStatus,
  KvintaSequenceStatusFromJSON,
  KvintaSequenceSummary,
  KvintaSequenceStatusDependencyRecord,
  KvintaGismtSerialNumberInfo,
} from 'kvinta/apis/kvinta-arla-extensions';
import { TAppOptionsConfig } from 'kvinta/common';
import { IFilter, PageContentStore, IFilterColumn } from 'kvinta/components';
import { NotificationManager } from 'kvinta/modules/main';
import { makeObservable, action, observable, runInAction } from 'mobx';
import { KvintaSequenceStatusRecord } from 'kvinta/apis/kvinta-arla-extensions/models/KvintaSequenceStatusRecord';
import { KvintaSequenceStatusesFilter } from 'kvinta/apis/kvinta-arla-extensions/models/KvintaSequenceStatusesFilter';
import { KvintaEpcisBizStepFromJSON, DefaultApi as EDocStoreStoreApi } from 'kvinta/apis/kvinta-epcis-document-store';

export interface EpcName {
  epc: string;
}
export interface ExtKvintaSequenceStatusRecord extends KvintaSequenceStatusRecord {
  dependantEPCObjects?: EpcName[];
  rootExecutionId?: string;
}

export interface CisChild {
  cis: string;
}
export interface ExtKvintaGismtSerialNumberInfo extends KvintaGismtSerialNumberInfo {
  children?: CisChild[];
}
export interface StinsDialogFormData {
  stins: string;
}

export const statusesList = ['STATUS_CREATED', 'STATUS_DONE', 'STATUS_ERROR', 'STATUS_INITIAL'];

export class SequenceStatusStore {
  private _config: TAppOptionsConfig;
  private _arlaApi: ArlaApi;
  private _epcisDocumentStoreApi: EDocStoreStoreApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  listData: ExtKvintaSequenceStatusRecord[];
  summaryData: KvintaSequenceSummary[];
  stinsList: string[];
  stinsData: ExtKvintaGismtSerialNumberInfo[];
  page: number;
  totalCount: number;
  pageSize: number;
  currentSort: KvintaSortExpressions;
  filter: IFilter;

  pageContentStore: PageContentStore;
  width: number | undefined;
  height: number | undefined;

  currentSequenceStatus?: ExtKvintaSequenceStatusRecord;
  currentRepStatusId?: number;

  navigationFilter?: IFilterColumn[];
  extendedDependencies?: Array<KvintaSequenceStatusDependencyRecord>;

  stinsDialogFormOpen: boolean;
  stinsDialogFormData?: StinsDialogFormData;

  constructor(
    config: TAppOptionsConfig,
    notificationManager: NotificationManager,
    arlaApi: ArlaApi,
    pageContentStore: PageContentStore,
    epcisDocumentStore: EDocStoreStoreApi,
  ) {
    makeObservable(this, {
      setFilter: action,
      isLoading: observable,
      listData: observable,
      summaryData: observable,
      page: observable,
      pageSize: observable,
      filter: observable,
      width: observable,
      height: observable,
      currentSequenceStatus: observable,
      currentRepStatusId: observable,
      extendedDependencies: observable,

      stinsDialogFormData: observable,
      stinsDialogFormOpen: observable,
      onChangeStinsDialogFormData: action.bound,
    });

    this._config = config;
    this._arlaApi = arlaApi;
    this._epcisDocumentStoreApi = epcisDocumentStore;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.pageContentStore = pageContentStore;

    // TODO: Initial sort and order
    this.currentSort = {
      expressions: [
        {
          direction: KvintaSortDirection.Desc,
          property: 'repStatusId',
        },
      ],
    };
  }

  doFilter = async () => {
    runInAction(() => {
      this.isLoading = true;
      this.page = 0;
    });
    this.fetchData();
  };

  setFilter(columns: IFilterColumn[]) {
    this.filter = new IFilter(columns, this.doFilter);
  }

  updateFilterStatusBizStep = (status: number, bizStep: string) => {
    this.navigationFilter = [
      { field: 'status', value: '' + status } as IFilterColumn,
      { field: 'bizStep', value: bizStep } as IFilterColumn,
    ];
  };

  updateFilterEpc = (epc: string) => {
    this.navigationFilter = [{ field: 'dependsOnEpc', value: epc } as IFilterColumn];
  };

  updateFilterEpcEvent = (epc: string, eventId: string) => {
    this.navigationFilter = [
      { field: 'epc', value: epc } as IFilterColumn,
      { field: 'eventId', value: eventId } as IFilterColumn,
    ];
  };

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    runInAction(() => {
      this.isLoading = true;
      const dir = getDirection(orderDirection);
      const field = getField(orderBy);
      this.currentSort = {
        expressions: [
          {
            direction: dir,
            property: field,
          },
        ],
      };
    });
    this.fetchData();
  }

  async fetchPage(page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
    });
    this.fetchData();
  }

  async fetchData() {
    runInAction(() => {
      this.isLoading = true;
      this.listData = [];
    });
    let filters = {} as KvintaSequenceStatusesFilter;

    for (const filter of this.filter.visibleFilters) {
      if (filter.field === 'repStatusId' && filter.value !== '') {
        filters = {
          ...filters,
          eventId: filter.value,
        };
      }
      if (filter.field === 'eventId' && filter.value !== '') {
        filters = {
          ...filters,
          eventId: filter.value,
        };
      }
      if (filter.field === 'epc' && filter.value !== '') {
        filters = {
          ...filters,
          epc: filter.value,
        };
      }
      if (filter.field === 'bizStep' && filter.value !== '') {
        filters = {
          ...filters,
          bizStep: KvintaEpcisBizStepFromJSON(filter.value),
        };
      }
      if (filter.field === 'status' && filter.value !== '') {
        filters = {
          ...filters,
          status: KvintaSequenceStatusFromJSON(filter.value),
        };
      }
      if (filter.field === 'dependsOnEpc' && filter.value !== '') {
        filters = {
          ...filters,
          dependsOnEpc: filter.value,
        };
      }
    }
    this._arlaApi
      .listSequenceStatuses({
        kvintaOperationRequestListSequenceStatusesRequest: {
          input: {
            paging: { page: this.page, size: this.pageSize, sort: this.currentSort },
            filter: filters,
          },
        },
      })
      .then((result) => {
        runInAction(() => {
          this.isLoading = false;
          if (result.status === KvintaOperationStatus.Error) {
            this.listData = [];
            this._notificationManager.sendError(result.error);
          } else {
            this.listData = (result.data.list || []).map((comp) => {
              return { ...comp } as KvintaSequenceStatusRecord;
            });
            this.totalCount = result.data.total || 0;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
    runInAction(() => {
      this.isLoading = false;
    });
  }

  async fetchSequenceSummary() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    runInAction(() => {
      this.isLoading = true;
      this.summaryData = [];
    });
    this._arlaApi
      .sequenceStatusSummary({
        kvintaOperationRequestObject: {
          // input: {
          //   paging: { page: this.page, size: this.pageSize, sort: this.currentSort },
          //   filter: filters,
          // },
        },
      })
      .then((result) => {
        runInAction(() => {
          this.isLoading = false;
          if (result.status === KvintaOperationStatus.Error) {
            this.summaryData = [];
            this._notificationManager.sendError(result.error);
          } else {
            this.summaryData = (result.data.list || []).map((comp) => {
              return { ...comp } as KvintaSequenceSummary;
            });
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
    runInAction(() => {
      this.isLoading = false;
    });
  }

  formatDateTime(timestamp: number): string {
    return format(new Date(timestamp), this._config.defaultDateTimeFormatting);
  }

  fetchSequenceStatus = async (repStatusId: number) => {
    runInAction(() => {
      this.isLoading = true;
      this.currentSequenceStatus = null;
      this.extendedDependencies = null;
    });
    const filters = { repStatusId: repStatusId } as KvintaSequenceStatusesFilter;
    try {
      const result = await this._arlaApi.listSequenceStatuses({
        kvintaOperationRequestListSequenceStatusesRequest: {
          input: {
            paging: { page: this.page, size: this.pageSize, sort: this.currentSort },
            filter: filters,
          },
        },
      });
      if (result.status === KvintaOperationStatus.Error) {
        runInAction(() => {
          this.isLoading = false;
          this.currentSequenceStatus = null;
        });
        this._notificationManager.sendError(result.error);
      } else {
        if (result.data.list) {
          const sequenceStatusRec = result.data.list[0] as KvintaSequenceStatusRecord;
          const rootRecResult = await this._epcisDocumentStoreApi.readEpcisEventRecordById({
            kvintaOperationRequestString: {
              input: sequenceStatusRec.eventId,
            },
          });
          const epcObjects = sequenceStatusRec.dependencies
            ? sequenceStatusRec.dependencies.map((value) => {
                return { epc: value } as EpcName;
              })
            : [];
          const extenderDepStatusResp = await this._arlaApi.getSequenceStatusDependencies({
            kvintaOperationRequestLong: {
              input: repStatusId,
            },
          });
          if (extenderDepStatusResp.status === KvintaOperationStatus.Error) {
            runInAction(() => {
              this.isLoading = false;
              this.currentSequenceStatus = null;
            });
            this._notificationManager.sendError(result.error);
          } else {
            runInAction(() => {
              this.isLoading = false;
              this.currentSequenceStatus = {
                ...sequenceStatusRec,
                rootExecutionId: rootRecResult.data.createdByRootExecutionId,
                dependantEPCObjects: epcObjects,
              } as ExtKvintaSequenceStatusRecord;
              this.extendedDependencies = extenderDepStatusResp.data != null ? extenderDepStatusResp.data.list : [];
            });
          }
        } else {
          runInAction(() => {
            this.isLoading = false;
            this.listData = [];
          });
        }
      }
    } catch (err) {
      this._notificationManager.sendError(JSON.stringify(err));
    }

    runInAction(() => {
      this.isLoading = false;
    });
  };

  async openStinsDialogForm() {
    runInAction(() => {
      this.isLoading = true;
      this.stinsDialogFormData = {
        stins: '',
      };
      this.stinsDialogFormOpen = true;
      this.isLoading = false;
    });
  }

  closeStinsDialogForm() {
    runInAction(() => {
      this.stinsDialogFormOpen = false;
    });
  }

  onChangeStinsDialogFormData = (value: any) => {
    runInAction(() => {
      this.stinsDialogFormData = {
        stins: value,
      };
    });
  };

  async fetchSTINsData() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    runInAction(() => {
      this.isLoading = true;
      this.stinsList = [];
      this.stinsDialogFormOpen = false;
    });
    const stinsArray = this.stinsDialogFormData.stins.split('\n');
    stinsArray.forEach((entry) => {
      if (entry != '') {
        this.stinsList = [...this.stinsList, entry.trim()];
      }
    });
    this._arlaApi
      .getSerialsNumbersInfo({
        kvintaOperationRequestListString: {
          input: this.stinsList,
        },
      })
      .then((result) => {
        runInAction(() => {
          if (result.status === KvintaOperationStatus.Error) {
            runInAction(() => {
              this.isLoading = false;
              this.stinsList = [];
            });
            this._notificationManager.sendError(result.error);
          } else {
            const list = [];
            for (const entry of result.data) {
              for (const stinResult of entry.results) {
                const children = stinResult.cisChildren
                  ? stinResult.cisChildren.map((value) => {
                      return { cis: value } as CisChild;
                    })
                  : [];
                list.push({ ...stinResult, children: children } as ExtKvintaGismtSerialNumberInfo);
              }
            }
            runInAction(() => {
              this.stinsData = list;
              this.isLoading = false;
            });
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }
}

export const SEQUENCE_STATUS_STORE_ID = 'sequenceStatusStore';

interface IResponseError {
  error_message: string;
}

function getErrorMessage(err?: string) {
  if (err && err.startsWith('{')) {
    const errObj = JSON.parse(err) as IResponseError;
    return errObj.error_message;
  }
  if (err) {
    return err;
  }
  return '';
}

function getDirection(orderDirection: string): KvintaSortDirection {
  if (orderDirection === 'asc') {
    return KvintaSortDirection.Asc;
  } else {
    return KvintaSortDirection.Desc;
  }
}

function getField(orderBy: number): string {
  switch (orderBy) {
    case 1:
      return 'status';
    case 2:
      return 'eventId';
    case 3:
      return 'epc';
    case 4:
      return 'bizStep';
    case 5:
      return 'createTimestamp';
    case 6:
      return 'updateTimestamp';
    default:
      return 'repStatusId';
  }
}
