import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

import React, { Component } from 'react';
import { PageContentStore, PAGE_CONTENT_STORE_ID, TabBar } from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { tabBarHeight } from 'kvinta/common';
import { ATKListStore, STORE_ID } from '../ATKListStore';
import {
  navigateATKCirculationReportsPath,
  navigateATKJsonPath,
  navigateATKListPath,
  navigateATKSerialNumbersPath,
  navigateATKStatusesPath,
  navigateATKSummaryPath,
} from '../paths';
import { tabsSummary } from './tabs';
import { Paper } from '@material-ui/core';

interface ATKJsonViewProps {
  intl: any;
  history?: any;
  atkListStore: ATKListStore;
  pageContentStore: PageContentStore;
  id: string;
}
class ATKJsonViewImpl extends Component<ATKJsonViewProps> {
  componentDidMount() {
    this.props.atkListStore.onLoadJSON(this.props.id);
  }

  render() {
    const { jsonPayload } = this.props.atkListStore;
    if (!jsonPayload) {
      return null;
    }
    const { width, height } = this.props.pageContentStore;
    const newHeight = height - tabBarHeight;
    return (
      <div>
        <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          <TabBar
            onChange={(newValue: any) => {
              if (newValue === '0') {
                navigateATKListPath(this.props.history);
              }
              if (newValue === '1') {
                navigateATKSummaryPath(this.props.history, this.props.id);
              }
              if (newValue === '2') {
                navigateATKSerialNumbersPath(this.props.history, this.props.id);
              }
              if (newValue === '3') {
                navigateATKJsonPath(this.props.history, this.props.id);
              }
              if (newValue === '4') {
                navigateATKStatusesPath(this.props.history, this.props.id);
              }
              if (newValue === '5') {
                navigateATKCirculationReportsPath(this.props.history, this.props.id);
              }
            }}
            tabId={'3'}
            tabs={tabsSummary}
          />
        </Paper>
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          <AceEditor
            mode="json"
            theme="github"
            readOnly={true}
            style={{ width, height: newHeight }}
            setOptions={{ useWorker: false }}
            editorProps={{
              $blockScrolling: false,
              $highlightPending: false,
            }}
            enableBasicAutocompletion={false}
            enableLiveAutocompletion={false}
            value={jsonPayload}
            onChange={() => {
              // Nothing to do here
            }}
          />
        </Paper>
      </div>
    );
  }
}
export const ATKJsonView = injectIntl(inject(STORE_ID, PAGE_CONTENT_STORE_ID)(observer(ATKJsonViewImpl)));
