/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaGismtPackType,
    KvintaGismtPackTypeFromJSON,
    KvintaGismtPackTypeFromJSONTyped,
    KvintaGismtPackTypeToJSON,
    KvintaGismtSerialNumberEmissionStatusType,
    KvintaGismtSerialNumberEmissionStatusTypeFromJSON,
    KvintaGismtSerialNumberEmissionStatusTypeFromJSONTyped,
    KvintaGismtSerialNumberEmissionStatusTypeToJSON,
    KvintaGismtSerialNumberStatusType,
    KvintaGismtSerialNumberStatusTypeFromJSON,
    KvintaGismtSerialNumberStatusTypeFromJSONTyped,
    KvintaGismtSerialNumberStatusTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGismtSerialNumberInfo
 */
export interface KvintaGismtSerialNumberInfo {
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtSerialNumberInfo
     */
    cis?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaGismtSerialNumberInfo
     */
    countChildren?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaGismtSerialNumberInfo
     */
    cisChildren?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtSerialNumberInfo
     */
    emissionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtSerialNumberInfo
     */
    gtin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtSerialNumberInfo
     */
    ownerInn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtSerialNumberInfo
     */
    ownerName?: string | null;
    /**
     * 
     * @type {KvintaGismtPackType}
     * @memberof KvintaGismtSerialNumberInfo
     */
    packType?: KvintaGismtPackType;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtSerialNumberInfo
     */
    productName?: string | null;
    /**
     * 
     * @type {KvintaGismtSerialNumberStatusType}
     * @memberof KvintaGismtSerialNumberInfo
     */
    status?: KvintaGismtSerialNumberStatusType;
    /**
     * 
     * @type {KvintaGismtSerialNumberEmissionStatusType}
     * @memberof KvintaGismtSerialNumberInfo
     */
    emissionType?: KvintaGismtSerialNumberEmissionStatusType;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaGismtSerialNumberInfo
     */
    prevCises?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtSerialNumberInfo
     */
    productGroup?: string | null;
}

export function KvintaGismtSerialNumberInfoFromJSON(json: any): KvintaGismtSerialNumberInfo {
    return KvintaGismtSerialNumberInfoFromJSONTyped(json, false);
}

export function KvintaGismtSerialNumberInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGismtSerialNumberInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cis': !exists(json, 'cis') ? undefined : json['cis'],
        'countChildren': !exists(json, 'countChildren') ? undefined : json['countChildren'],
        'cisChildren': !exists(json, 'cisChildren') ? undefined : json['cisChildren'],
        'emissionDate': !exists(json, 'emissionDate') ? undefined : json['emissionDate'],
        'gtin': !exists(json, 'gtin') ? undefined : json['gtin'],
        'ownerInn': !exists(json, 'ownerInn') ? undefined : json['ownerInn'],
        'ownerName': !exists(json, 'ownerName') ? undefined : json['ownerName'],
        'packType': !exists(json, 'packType') ? undefined : KvintaGismtPackTypeFromJSON(json['packType']),
        'productName': !exists(json, 'productName') ? undefined : json['productName'],
        'status': !exists(json, 'status') ? undefined : KvintaGismtSerialNumberStatusTypeFromJSON(json['status']),
        'emissionType': !exists(json, 'emissionType') ? undefined : KvintaGismtSerialNumberEmissionStatusTypeFromJSON(json['emissionType']),
        'prevCises': !exists(json, 'prevCises') ? undefined : json['prevCises'],
        'productGroup': !exists(json, 'productGroup') ? undefined : json['productGroup'],
    };
}

export function KvintaGismtSerialNumberInfoToJSON(value?: KvintaGismtSerialNumberInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cis': value.cis,
        'countChildren': value.countChildren,
        'cisChildren': value.cisChildren,
        'emissionDate': value.emissionDate,
        'gtin': value.gtin,
        'ownerInn': value.ownerInn,
        'ownerName': value.ownerName,
        'packType': KvintaGismtPackTypeToJSON(value.packType),
        'productName': value.productName,
        'status': KvintaGismtSerialNumberStatusTypeToJSON(value.status),
        'emissionType': KvintaGismtSerialNumberEmissionStatusTypeToJSON(value.emissionType),
        'prevCises': value.prevCises,
        'productGroup': value.productGroup,
    };
}


