/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaFindSgtinListResponse
 */
export interface KvintaFindSgtinListResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaFindSgtinListResponse
     */
    sgtins?: Array<string> | null;
}

export function KvintaFindSgtinListResponseFromJSON(json: any): KvintaFindSgtinListResponse {
    return KvintaFindSgtinListResponseFromJSONTyped(json, false);
}

export function KvintaFindSgtinListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaFindSgtinListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sgtins': !exists(json, 'sgtins') ? undefined : json['sgtins'],
    };
}

export function KvintaFindSgtinListResponseToJSON(value?: KvintaFindSgtinListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sgtins': value.sgtins,
    };
}


