/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaGtinDescriptionSku
 */
export interface KvintaGtinDescriptionSku {
    /**
     * 
     * @type {string}
     * @memberof KvintaGtinDescriptionSku
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaGtinDescriptionSku
     */
    gtin: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaGtinDescriptionSku
     */
    sku: string;
}

export function KvintaGtinDescriptionSkuFromJSON(json: any): KvintaGtinDescriptionSku {
    return KvintaGtinDescriptionSkuFromJSONTyped(json, false);
}

export function KvintaGtinDescriptionSkuFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGtinDescriptionSku {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'gtin': json['gtin'],
        'sku': json['sku'],
    };
}

export function KvintaGtinDescriptionSkuToJSON(value?: KvintaGtinDescriptionSku | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'gtin': value.gtin,
        'sku': value.sku,
    };
}


