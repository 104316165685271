/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaEpcisEventProduct
 */
export interface KvintaEpcisEventProduct {
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventProduct
     */
    sku?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventProduct
     */
    productName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventProduct
     */
    gtin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventProduct
     */
    tnved?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventProduct
     */
    prodBatch?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcisEventProduct
     */
    prodDate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcisEventProduct
     */
    topQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcisEventProduct
     */
    sgtinQuantity?: number;
}

export function KvintaEpcisEventProductFromJSON(json: any): KvintaEpcisEventProduct {
    return KvintaEpcisEventProductFromJSONTyped(json, false);
}

export function KvintaEpcisEventProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisEventProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sku': !exists(json, 'sku') ? undefined : json['sku'],
        'productName': !exists(json, 'productName') ? undefined : json['productName'],
        'gtin': !exists(json, 'gtin') ? undefined : json['gtin'],
        'tnved': !exists(json, 'tnved') ? undefined : json['tnved'],
        'prodBatch': !exists(json, 'prodBatch') ? undefined : json['prodBatch'],
        'prodDate': !exists(json, 'prodDate') ? undefined : json['prodDate'],
        'topQuantity': !exists(json, 'topQuantity') ? undefined : json['topQuantity'],
        'sgtinQuantity': !exists(json, 'sgtinQuantity') ? undefined : json['sgtinQuantity'],
    };
}

export function KvintaEpcisEventProductToJSON(value?: KvintaEpcisEventProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sku': value.sku,
        'productName': value.productName,
        'gtin': value.gtin,
        'tnved': value.tnved,
        'prodBatch': value.prodBatch,
        'prodDate': value.prodDate,
        'topQuantity': value.topQuantity,
        'sgtinQuantity': value.sgtinQuantity,
    };
}


