/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaGtinInfo
 */
export interface KvintaGtinInfo {
    /**
     * 
     * @type {number}
     * @memberof KvintaGtinInfo
     */
    baseUomDenominator?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaGtinInfo
     */
    baseUomNominator?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGtinInfo
     */
    gtin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGtinInfo
     */
    sku?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGtinInfo
     */
    uom?: string | null;
}

export function KvintaGtinInfoFromJSON(json: any): KvintaGtinInfo {
    return KvintaGtinInfoFromJSONTyped(json, false);
}

export function KvintaGtinInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGtinInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'baseUomDenominator': !exists(json, 'baseUomDenominator') ? undefined : json['baseUomDenominator'],
        'baseUomNominator': !exists(json, 'baseUomNominator') ? undefined : json['baseUomNominator'],
        'gtin': !exists(json, 'gtin') ? undefined : json['gtin'],
        'sku': !exists(json, 'sku') ? undefined : json['sku'],
        'uom': !exists(json, 'uom') ? undefined : json['uom'],
    };
}

export function KvintaGtinInfoToJSON(value?: KvintaGtinInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'baseUomDenominator': value.baseUomDenominator,
        'baseUomNominator': value.baseUomNominator,
        'gtin': value.gtin,
        'sku': value.sku,
        'uom': value.uom,
    };
}


