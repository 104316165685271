/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSerialNumberOrderItem,
    KvintaSerialNumberOrderItemFromJSON,
    KvintaSerialNumberOrderItemFromJSONTyped,
    KvintaSerialNumberOrderItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetListResultSerialNumberOrderItem
 */
export interface KvintaGetListResultSerialNumberOrderItem {
    /**
     * 
     * @type {Array<KvintaSerialNumberOrderItem>}
     * @memberof KvintaGetListResultSerialNumberOrderItem
     */
    list?: Array<KvintaSerialNumberOrderItem> | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaGetListResultSerialNumberOrderItem
     */
    total?: number | null;
}

export function KvintaGetListResultSerialNumberOrderItemFromJSON(json: any): KvintaGetListResultSerialNumberOrderItem {
    return KvintaGetListResultSerialNumberOrderItemFromJSONTyped(json, false);
}

export function KvintaGetListResultSerialNumberOrderItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetListResultSerialNumberOrderItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': !exists(json, 'list') ? undefined : (json['list'] === null ? null : (json['list'] as Array<any>).map(KvintaSerialNumberOrderItemFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function KvintaGetListResultSerialNumberOrderItemToJSON(value?: KvintaGetListResultSerialNumberOrderItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': value.list === undefined ? undefined : (value.list === null ? null : (value.list as Array<any>).map(KvintaSerialNumberOrderItemToJSON)),
        'total': value.total,
    };
}


