/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { countries } from 'kvinta/common/countries';
import { errorRequired, DevicesStore, DEVICES_STORE_ID, requiredFields } from './DevicesStore';
import { KDialogTitle } from 'kvinta/components/KDialog';

interface DialogCreateDeviceProps {
  devicesStore?: DevicesStore;
  intl: any;
}

class DialogCreateDeviceImpl extends Component<DialogCreateDeviceProps> {
  render() {
    const isOpen = this.props.devicesStore.createDeviceFormOpen;
    const onChangeValue = this.props.devicesStore.onChangeCreateDeviceField;
    const formData = this.props.devicesStore.createDeviceData;
    const intl = this.props.intl;
    const showError = formData.showError;

    if (!isOpen) {
      return null;
    }
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={isOpen}
        onClose={() => this.props.devicesStore.closeCreateDeviceForm()}
      >
        <KDialogTitle id="customized-dialog-title" onClose={() => this.props.devicesStore.closeCreateDeviceForm()}>
          {intl.formatMessage({ id: 'device-create-form.create' })}
        </KDialogTitle>
        <DialogContent>
          <Grid container justify="flex-start">
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="email"
                    required
                    helperText={
                      showError && errorRequired('email', formData.email, requiredFields)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('email', formData.email, requiredFields)}
                    value={formData.email}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('email', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'device-create-form.email' })}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="locationGln13"
                    required
                    helperText={
                      showError && errorRequired('locationGln13', formData.locationGln13, requiredFields)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('locationGln13', formData.locationGln13, requiredFields)}
                    value={formData.locationGln13}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('locationGln13', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'device-create-form.locationGln13' })}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="serialNumber"
                    required
                    helperText={
                      showError && errorRequired('serialNumber', formData.serialNumber, requiredFields)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('serialNumber', formData.serialNumber, requiredFields)}
                    value={formData.serialNumber}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('serialNumber', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'device-create-form.serialNumber' })}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.devicesStore.closeCreateDeviceForm()} color="primary" variant="outlined">
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <Button
            onClick={() => {
              this.props.devicesStore.submitCreateDeviceForm();
            }}
            color="primary"
            variant="contained"
            // disabled={!formData.isValid}
            autoFocus
          >
            {intl.formatMessage({ id: 'button.submit' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const DialogCreateDevice = injectIntl(inject(DEVICES_STORE_ID)(observer(DialogCreateDeviceImpl)));
