import React, { Component } from 'react';
import { VerificationStore, VERIFICATION_STORE_ID } from './VerificationStore';
import {
  KSidebarStore,
  PageContentStore,
  PAGE_CONTENT_STORE_ID,
  SIDEBAR_STORE_ID,
  TabBar,
  tableIcons,
} from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { Paper } from '@material-ui/core';
import { tabs } from './tabs';
import { navigateVerificationListPath, navigateVerificationPath, navigateVerificationResultsPath } from './paths';
import { reaction } from 'mobx';
import MaterialTable from 'material-table';

interface ViewVerificationProductsProps {
  intl: any;
  history?: any;
  sidebarStore?: KSidebarStore;
  pageContentStore?: PageContentStore;
  verificationStore: VerificationStore;
  id: string;
}

class ViewVerificationProductsImpl extends Component<ViewVerificationProductsProps> {
  disposer: any;
  tableRef: React.RefObject<unknown>;

  constructor(props: ViewVerificationProductsProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.verificationStore.currentVerificationProducts,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(this.props.id, () => {
            navigateVerificationListPath(this.props.history);
          });
        }
      },
    );
    this.props.verificationStore.loadVerificationProducts(this.props.id);
  }
  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const intl = this.props.intl;
    const { currentVerificationProducts, isLoading, page, totalCount, pageSize } = this.props.verificationStore;
    // const bodyHeight = 550;
    const bodyHeight = this.props.pageContentStore.tableContentHeightThin;

    // const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
    if (!currentVerificationProducts) {
      return null;
    }
    let emptyDataMessageWithLoading = intl.formatMessage({ id: 'verification.product-list.empty' });
    if (isLoading) {
      emptyDataMessageWithLoading = intl.formatMessage({ id: 'verification.product-list.loading' });
    }
    return (
      <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              navigateVerificationListPath(this.props.history);
            }
            if (newValue === '1') {
              navigateVerificationPath(this.props.history, this.props.id);
            }
            if (newValue === '3') {
              navigateVerificationResultsPath(this.props.history, this.props.id);
            }
          }}
          tabId={'2'}
          tabs={tabs}
        />
        <div>
          <MaterialTable
            icons={tableIcons}
            tableRef={this.tableRef}
            columns={[
              {
                title: intl.formatMessage({ id: 'verification-list.products.gtin' }),
                field: 'gtin',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'verification-list.products.productName' }),
                field: 'productName',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'verification-list.products.prodBatch' }),
                field: 'prodBatch',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'verification-list.products.prodDate' }),
                field: 'prodDate',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
                render: (rowData) => {
                  return <span>{new Date(rowData.prodDate).toISOString()}</span>;
                },
              },
              {
                title: intl.formatMessage({ id: 'verification-list.products.sgtinQuantity' }),
                field: 'sgtinQuantity',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: intl.formatMessage({ id: 'verification-list.products.sku' }),
                field: 'sku',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
            ]}
            data={currentVerificationProducts}
            page={page}
            totalCount={totalCount}
            onChangePage={(page: number) => {
              this.props.verificationStore.fetchPage(page);
            }}
            onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
              this.props.verificationStore.changeOrder(orderBy, orderDirection);
            }}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: -1,
              paging: true,
              sorting: true,
              draggable: false,
              search: false,
              pageSize: pageSize,
              pageSizeOptions: [pageSize],
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
              rowStyle: (rowData) => ({
                backgroundColor: rowData.index == 0 ? '#EEE' : '#FFF',
              }),
              showTitle: false,
            }}
            localization={{
              header: {
                actions: '',
              },
              body: {
                emptyDataSourceMessage: emptyDataMessageWithLoading,
              },
            }}
            // onRowClick={(event, rowData) => {
            //   this.navigateVerificationProducts(history, rowData.eventID);
            // }}
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                    marginRight: '20px',
                  }}
                ></div>
              ),
            }}
          />
        </div>
      </Paper>
    );
  }
}
export const ViewVerificationProducts = injectIntl(
  inject(VERIFICATION_STORE_ID, PAGE_CONTENT_STORE_ID, SIDEBAR_STORE_ID)(observer(ViewVerificationProductsImpl)),
);
