/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaAggregatedCustomsDocument
 */
export interface KvintaAggregatedCustomsDocument {
    /**
     * 
     * @type {string}
     * @memberof KvintaAggregatedCustomsDocument
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaAggregatedCustomsDocument
     */
    taxNumber?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaAggregatedCustomsDocument
     */
    serialNumbers?: Array<string>;
}

export function KvintaAggregatedCustomsDocumentFromJSON(json: any): KvintaAggregatedCustomsDocument {
    return KvintaAggregatedCustomsDocumentFromJSONTyped(json, false);
}

export function KvintaAggregatedCustomsDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaAggregatedCustomsDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'taxNumber': !exists(json, 'taxNumber') ? undefined : json['taxNumber'],
        'serialNumbers': !exists(json, 'serialNumbers') ? undefined : json['serialNumbers'],
    };
}

export function KvintaAggregatedCustomsDocumentToJSON(value?: KvintaAggregatedCustomsDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'taxNumber': value.taxNumber,
        'serialNumbers': value.serialNumbers,
    };
}


