/* eslint-disable @typescript-eslint/ban-types */
import { Button, Paper } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { SerialNumberOrdersStore, STORE_ID } from '../SerialNumberOrdersStore';
import EditIcon from '@material-ui/icons/Edit';
import { tabsSerialNumberOrderItemsView } from './tabs';
import {
  navigateSerialNumberOrderDocumentStatusesPath,
  navigateSerialNumberOrderItemsPath,
  navigateSerialNumberOrderPath,
  navigateSerialNumberOrderSerialNumbersPath,
  navigateSerialNumberOrdersPath,
} from '../paths';
import { TabBar } from 'kvinta/components';
import { SerialNumberOrderItemCompView } from './SerialNumberOrderItemCompView';

interface SerialNumberOrderItemSummaryViewProps {
  intl: any;
  serialNumberOrdersStore?: SerialNumberOrdersStore;
  id: string;
  itemId: string;
  history?: any;
}

class SerialNumberOrderItemSummaryViewImpl extends Component<SerialNumberOrderItemSummaryViewProps> {
  componentDidMount() {
    this.props.serialNumberOrdersStore.fetchSerialNumberOrderItem(this.props.itemId);
  }

  render() {
    if (!this.props.serialNumberOrdersStore) {
      return null;
    }
    const comp = this.props.serialNumberOrdersStore.currentSerialNumberOrderItem;
    if (!comp) {
      return null;
    }
    // const { updateSerialNumberOrderFormOpen } = this.props.serialNumberOrdersStore;

    const intl = this.props.intl;
    const formData = comp;
    return (
      <div>
        <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          <TabBar
            onChange={(newValue: any) => {
              if (newValue === '0') {
                navigateSerialNumberOrdersPath(this.props.history);
              }
              if (newValue === '1') {
                navigateSerialNumberOrderPath(this.props.history, this.props.id);
              }
              if (newValue === '2') {
                navigateSerialNumberOrderItemsPath(this.props.history, this.props.id);
              }
              if (newValue === '3') {
                navigateSerialNumberOrderSerialNumbersPath(this.props.history, this.props.id);
              }
              if (newValue === '4') {
                navigateSerialNumberOrderDocumentStatusesPath(this.props.history, this.props.id);
              }
            }}
            tabId={'5'}
            tabs={tabsSerialNumberOrderItemsView}
          />
        </Paper>
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          <div style={{ marginRight: '15px', marginTop: '15px', float: 'right' }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<EditIcon color="secondary" />}
              // onClick={(e) => {
              //   this.props.serialNumberOrdersStore.openUpdateSerialNumberOrderForm();
              // }}
            >
              {intl.formatMessage({ id: 'button.edit' })}
            </Button>
          </div>
          <SerialNumberOrderItemCompView serialNumberOrderItem={formData} />
          {/* {updateSerialNumberOrderFormOpen && <UpdateSerialNumberOrderDialog />} */}
        </Paper>
      </div>
    );
  }
}

export const SerialNumberOrderItemSummaryView = injectIntl(
  inject(STORE_ID)(observer(SerialNumberOrderItemSummaryViewImpl)),
);
