import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import AddIcon from '@material-ui/icons/Add';
import { ShippingStore, SHIPPING_STORE_ID } from './ShippingStore';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  TabBar,
  tableIcons,
} from 'kvinta/components';
import { reaction } from 'mobx';
import { navigateShippingListPath, navigateShippingPath, navigateShippingProductSummaryPath } from './paths';
import { Paper, Checkbox, Button } from '@material-ui/core';
import { tabs } from './tabs';
import MaterialTable from 'material-table';
import { DialogIntroduceIntoCirculation } from './DialogIntroduceIntoCirculation';
import { navigateShippingResultsPath } from 'kvinta/modules/paths';

interface ShippingProductListProps {
  shippingStore?: ShippingStore;
  intl: any;
  history?: any;
  id: string;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
}

class ViewShippingProductListImpl extends Component<ShippingProductListProps> {
  disposer: any;
  tableRef: React.RefObject<unknown>;

  constructor(props: ShippingProductListProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.shippingStore.fetchShippingProductListData(this.props.id);
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.shippingStore.currentShipping,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.epcisEventRecord.eventID, () => {
            navigateShippingListPath(this.props.history);
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const intl = this.props.intl;

    const { listData, isLoading, checked, introduceIntoCirculationDialogOpen } = this.props.shippingStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightThin;
    const selected =
      listData &&
      listData.reduce((acc, row) => {
        acc[row.id] = row.isSelected;
        return acc;
      }, {});

    return (
      <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              navigateShippingListPath(this.props.history);
            }
            if (newValue === '1') {
              navigateShippingPath(this.props.history, this.props.id);
            }
            if (newValue === '3') {
              navigateShippingResultsPath(this.props.history, this.props.id);
            }
          }}
          tabId={'2'}
          tabs={tabs}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: '20px',
            paddingRight: '20px',
          }}
        >
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              disabled={!(listData && listData.length > 0)}
              onClick={(e) => {
                this.props.shippingStore.exportProducts(this.props.id, `${this.props.id}_products.csv`);
              }}
            >
              {intl.formatMessage({ id: 'shipping.product-list.button.export' })}
            </Button>
            <Button
              style={{ marginLeft: '10px' }}
              variant="contained"
              color="primary"
              disabled={checked === 0}
              startIcon={<AddIcon color="secondary" />}
              onClick={(e) => {
                this.props.shippingStore.openIntroduceIntoCirculationDialog();
              }}
            >
              {intl.formatMessage({ id: 'shipping.product-list.button.introduce' })}
            </Button>
          </div>
        </div>
        <MaterialTable
          icons={tableIcons}
          tableRef={this.tableRef}
          title={intl.formatMessage({ id: 'menu.trade-items' })}
          columns={[
            {
              title: (
                <Checkbox
                  id={'check-all'}
                  checked={checked > 0}
                  onChange={() => {
                    this.props.shippingStore.toggleAll();
                  }}
                  checkedIcon={checked == 2 ? <CheckBoxIcon /> : <IndeterminateCheckBoxIcon />}
                />
              ),
              field: 'isSelected',
              sorting: false,
              width: '10px',
              render: (rowData) => (
                <Checkbox
                  id={rowData.id}
                  checked={selected[rowData.id]}
                  onChange={() => {
                    this.props.shippingStore.toggleRow(rowData.id);
                  }}
                />
              ),
            },
            {
              title: intl.formatMessage({ id: 'shipping.product-list.sku' }),
              field: 'sku',
              cellStyle: {
                whiteSpace: 'nowrap',
                width: '10%',
              },
            },
            {
              title: intl.formatMessage({ id: 'shipping.product-list.title' }),
              field: 'productName',
              cellStyle: {
                whiteSpace: 'nowrap',
                width: '10%',
              },
            },
            {
              title: intl.formatMessage({ id: 'shipping.product-list.gtin' }),
              field: 'gtin',
              cellStyle: {
                whiteSpace: 'nowrap',
                width: '50%',
              },
            },
            {
              title: intl.formatMessage({ id: 'shipping.product-list.batch' }),
              field: 'prodBatch',
              cellStyle: {
                whiteSpace: 'nowrap',
                width: '50%',
              },
            },
            {
              title: intl.formatMessage({ id: 'shipping.product-list.tnved' }),
              field: 'tnved',
              cellStyle: {
                whiteSpace: 'nowrap',
                width: '50%',
              },
            },
            {
              title: intl.formatMessage({ id: 'shipping.product-list.topQuantity' }),
              field: 'topQuantity',
              cellStyle: {
                whiteSpace: 'nowrap',
                width: '50%',
              },
            },
            {
              title: intl.formatMessage({ id: 'shipping.product-list.sgtinQuantity' }),
              field: 'sgtinQuantity',
              cellStyle: {
                whiteSpace: 'nowrap',
                width: '50%',
              },
            },
          ]}
          data={listData}
          onChangePage={(page: number) => {
            this.props.shippingStore.fetchPage(page);
          }}
          onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
            this.props.shippingStore.changeOrder(orderBy, orderDirection);
          }}
          isLoading={isLoading}
          options={{
            actionsColumnIndex: 0,
            draggable: false,
            search: false,
            paging: false,
            minBodyHeight: bodyHeight,
            maxBodyHeight: bodyHeight,
            showTitle: false,
            emptyRowsWhenPaging: false,
            thirdSortClick: false,
          }}
          localization={{
            header: {
              actions: '',
            },
            body: {
              emptyDataSourceMessage: intl.formatMessage({ id: 'shipping.product-list.empty' }),
            },
          }}
          onRowClick={(event, rowData) => {
            if (!(event.target instanceof HTMLInputElement)) {
              navigateShippingProductSummaryPath(this.props.history, this.props.id, rowData.id);
            }
          }}
          components={{ Toolbar: (props) => null }}
        />
        {introduceIntoCirculationDialogOpen && <DialogIntroduceIntoCirculation />}
      </Paper>
    );
  }
}

export const ViewShippingProductList = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, SIDEBAR_STORE_ID, SHIPPING_STORE_ID)(observer(ViewShippingProductListImpl)),
);
