/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaGtinDescriptionSku,
    KvintaGtinDescriptionSkuFromJSON,
    KvintaGtinDescriptionSkuFromJSONTyped,
    KvintaGtinDescriptionSkuToJSON,
    KvintaOperationStatus,
    KvintaOperationStatusFromJSON,
    KvintaOperationStatusFromJSONTyped,
    KvintaOperationStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetListResponseGtinDescriptionSku
 */
export interface KvintaGetListResponseGtinDescriptionSku {
    /**
     * 
     * @type {Array<KvintaGtinDescriptionSku>}
     * @memberof KvintaGetListResponseGtinDescriptionSku
     */
    data: Array<KvintaGtinDescriptionSku>;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetListResponseGtinDescriptionSku
     */
    error?: string | null;
    /**
     * 
     * @type {KvintaOperationStatus}
     * @memberof KvintaGetListResponseGtinDescriptionSku
     */
    operationStatus?: KvintaOperationStatus;
    /**
     * 
     * @type {number}
     * @memberof KvintaGetListResponseGtinDescriptionSku
     */
    total?: number | null;
}

export function KvintaGetListResponseGtinDescriptionSkuFromJSON(json: any): KvintaGetListResponseGtinDescriptionSku {
    return KvintaGetListResponseGtinDescriptionSkuFromJSONTyped(json, false);
}

export function KvintaGetListResponseGtinDescriptionSkuFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetListResponseGtinDescriptionSku {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(KvintaGtinDescriptionSkuFromJSON)),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'operationStatus': !exists(json, 'operationStatus') ? undefined : KvintaOperationStatusFromJSON(json['operationStatus']),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function KvintaGetListResponseGtinDescriptionSkuToJSON(value?: KvintaGetListResponseGtinDescriptionSku | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(KvintaGtinDescriptionSkuToJSON)),
        'error': value.error,
        'operationStatus': KvintaOperationStatusToJSON(value.operationStatus),
        'total': value.total,
    };
}


