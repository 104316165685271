/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSoftwareSystem,
    KvintaSoftwareSystemFromJSON,
    KvintaSoftwareSystemFromJSONTyped,
    KvintaSoftwareSystemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaSystemServiceFunction
 */
export interface KvintaSystemServiceFunction {
    /**
     * 
     * @type {string}
     * @memberof KvintaSystemServiceFunction
     */
    _function: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaSystemServiceFunction
     */
    service: string;
    /**
     * 
     * @type {KvintaSoftwareSystem}
     * @memberof KvintaSystemServiceFunction
     */
    system: KvintaSoftwareSystem;
}

export function KvintaSystemServiceFunctionFromJSON(json: any): KvintaSystemServiceFunction {
    return KvintaSystemServiceFunctionFromJSONTyped(json, false);
}

export function KvintaSystemServiceFunctionFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSystemServiceFunction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_function': json['function'],
        'service': json['service'],
        'system': KvintaSoftwareSystemFromJSON(json['system']),
    };
}

export function KvintaSystemServiceFunctionToJSON(value?: KvintaSystemServiceFunction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'function': value._function,
        'service': value.service,
        'system': KvintaSoftwareSystemToJSON(value.system),
    };
}


