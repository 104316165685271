export const ATKListPath = '/atk-list';
export const ATKSummaryPath = '/atk/:id';
export const ATKJsonPath = '/atk-json/:id';
export const ATKSerialNumbersPath = '/atk/:id/serial-number-list';
export const ATKStatusesPath = '/atk/:id/status-list';
export const ATKCirculationReportsPath = '/atk/:id/linked-document-list';

export function navigateATKListPath(history: any) {
  history.push(ATKListPath);
}

export function navigateATKSummaryPath(history: any, id: string) {
  history.push(ATKSummaryPath.replaceAll(':id', id));
}

export function linkATKSummaryPath(history: any, id: string): string {
  return ATKSummaryPath.replaceAll(':id', id);
}

export function navigateATKSerialNumbersPath(history: any, id: string) {
  history.push(ATKSerialNumbersPath.replaceAll(':id', id));
}

export function navigateATKJsonPath(history: any, id: string) {
  history.push(ATKJsonPath.replaceAll(':id', id));
}

export function navigateATKStatusesPath(history: any, id: string) {
  history.push(ATKStatusesPath.replaceAll(':id', id));
}

export function navigateATKCirculationReportsPath(history: any, id: string) {
  history.push(ATKCirculationReportsPath.replaceAll(':id', id));
}
