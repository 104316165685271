/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingFromJSONTyped,
    KvintaPagingToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaListEmailRecipientRequest
 */
export interface KvintaListEmailRecipientRequest {
    /**
     * 
     * @type {KvintaPaging}
     * @memberof KvintaListEmailRecipientRequest
     */
    paging?: KvintaPaging;
}

export function KvintaListEmailRecipientRequestFromJSON(json: any): KvintaListEmailRecipientRequest {
    return KvintaListEmailRecipientRequestFromJSONTyped(json, false);
}

export function KvintaListEmailRecipientRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaListEmailRecipientRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paging': !exists(json, 'paging') ? undefined : KvintaPagingFromJSON(json['paging']),
    };
}

export function KvintaListEmailRecipientRequestToJSON(value?: KvintaListEmailRecipientRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paging': KvintaPagingToJSON(value.paging),
    };
}


