/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Query Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaDateTimeRange
 */
export interface KvintaDateTimeRange {
    /**
     * 
     * @type {number}
     * @memberof KvintaDateTimeRange
     */
    from?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaDateTimeRange
     */
    to?: number;
}

export function KvintaDateTimeRangeFromJSON(json: any): KvintaDateTimeRange {
    return KvintaDateTimeRangeFromJSONTyped(json, false);
}

export function KvintaDateTimeRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDateTimeRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
    };
}

export function KvintaDateTimeRangeToJSON(value?: KvintaDateTimeRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from,
        'to': value.to,
    };
}


