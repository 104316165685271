import { action, makeObservable, observable, runInAction } from 'mobx';
import { ISelectableRow, SelectableStore, TAppOptionsConfig } from 'kvinta/common';
import { DefaultApi as MDDocumentApi } from 'kvinta/apis/kvinta-masterdata-service';
import { NotificationManager } from 'kvinta/modules/main';
import {
  DefaultApi as DocumentApi,
  KvintaOperationStatus,
  KvintaResourceStatus,
  KvintaResourceType,
  KvintaSoftwareSystem,
  KvintaSortDirection,
  KvintaSortExpressions,
  KvintaTextDocument,
  KvintaTextDocumentRecord,
} from 'kvinta/apis/kvinta-document-store';
import {
  DefaultApi as ArlaExtStoreApi,
  KvintaResourceType as KvintaResourceType_Arla,
} from '../../../apis/kvinta-arla-extensions';

interface IATKRow extends KvintaTextDocumentRecord, ISelectableRow {}

interface ExtKvintaTextDocument extends KvintaTextDocument {
  serialNumbers?: SerialNumber[];
}

interface SerialNumber {
  id: string;
}

interface ContentType {
  id: string;
  serialNumbers: string[];
  taxNumber: string;
}

export class ATKListStore extends SelectableStore<IATKRow> {
  private _config: TAppOptionsConfig;
  private _docApi: DocumentApi;
  private _mdApi: MDDocumentApi;
  private _arlaApi: ArlaExtStoreApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  isReleasing: boolean;
  page: number;
  totalCount: number;
  pageSize: number;
  currentSort: KvintaSortExpressions;
  currentATK?: ExtKvintaTextDocument;
  currentATKSerialNumbers: string;
  jsonPayload?: string;
  currentATKCirculationReports?: KvintaTextDocumentRecord[];

  exportSNActive: boolean;
  exportSNData: SerialNumber[] | undefined;

  exportStatusesActive: boolean;
  exportStatusesData: KvintaResourceStatus[] | undefined;

  constructor(
    config: TAppOptionsConfig,
    notificationManager: NotificationManager,
    mdApi: MDDocumentApi,
    docApi: DocumentApi,
    arlaApi: ArlaExtStoreApi,
  ) {
    super();
    makeObservable(this, {
      fetchPage: action.bound,
      fetchData: action.bound,
      fetchATK: action.bound,
      fetchATKCirculationReports: action.bound,
      onLoadJSON: action.bound,
      isLoading: observable,
      page: observable,
      pageSize: observable,
      currentATK: observable,
      currentATKSerialNumbers: observable,
      totalCount: observable,
      jsonPayload: observable,
      currentATKCirculationReports: observable,

      exportSNActive: observable,
      exportSNAll: action.bound,

      exportStatusesActive: observable,
      exportStatusesAll: action.bound,
    });

    this._config = config;
    this._mdApi = mdApi;
    this._docApi = docApi;
    this._arlaApi = arlaApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.isReleasing = false;
    this.currentSort = {
      expressions: [
        {
          property: 'timestamp',
          direction: KvintaSortDirection.Desc,
        },
      ],
    };
  }

  async fetchPage(page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
    });
    this.fetchData();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    runInAction(() => {
      this.isLoading = true;
      const field = getField(orderBy);
      this.currentSort = {
        expressions: [
          {
            property: field,
            direction: orderDirection === 'asc' ? KvintaSortDirection.Asc : KvintaSortDirection.Desc,
          },
        ],
      };
    });
    this.fetchData();
  }

  doFilter = async () => {
    runInAction(() => {
      this.isLoading = true;
      this.page = 0;
    });
    this.fetchData();
  };

  async fetchData() {
    this.isLoading = true;
    const filters = { type: 'AGGREGATED_CUSTOMS_DOCUMENT' } as { [key: string]: string } | null;
    this._docApi
      .listTextDocuments({
        kvintaOperationRequestListTextDocumentsRequest: {
          input: { paging: { page: this.page, size: this.pageSize, sort: this.currentSort }, filter: filters },
        },
      })
      .then((result) => {
        runInAction(() => {
          this.listData = [];
          this.totalCount = 0;
          this.isLoading = false;
          if (result.status === KvintaOperationStatus.Error) {
            this.listData = [];
            this._notificationManager.sendError(result.error);
          } else {
            this.listData = (result.data.list || []).map((comp) => {
              return { ...comp } as IATKRow;
            });
            this.totalCount = result.data.total || 0;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async fetchATK(id: string) {
    this._docApi
      .readTextDocumentWithStatuses({
        kvintaOperationRequestResource: {
          input: {
            id: id,
            system: KvintaSoftwareSystem.Kvinta, //TODO
            type: KvintaResourceType.AggregatedCustomsDocument,
          },
        },
      })
      .then((result) => {
        runInAction(() => {
          this.currentATK = undefined;
          if (result.status !== KvintaOperationStatus.Ok) {
            this._notificationManager.sendError(result.error);
          } else {
            const currentATK = DocToExtDoc(result.data);
            const currentAtkWithSortedStatuses = {
              ...currentATK,
              record: {
                ...currentATK.record,
                statuses: currentATK.record.statuses ? sortAtkStatuses(currentATK.record.statuses) : [],
              },
            };
            this.currentATK = currentAtkWithSortedStatuses;
            this.totalCount = this.currentATK.serialNumbers.length || 0;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async fetchATKCirculationReports(id: string) {
    this._docApi
      .findChildTextDocumentsRecords({
        kvintaOperationRequestFindChildTextDocumentsRecordsRequest: {
          input: {
            childType: KvintaResourceType.CirculationAtkReport,
            parentResource: {
              id: id,
              system: KvintaSoftwareSystem.Kvinta, //TODO
              type: KvintaResourceType.AggregatedCustomsDocument, //TODO
            },
          },
        },
      })
      .then((result) => {
        runInAction(() => {
          this.currentATK = undefined;
          this.currentATKCirculationReports = [];
          if (result.status !== KvintaOperationStatus.Ok) {
            this._notificationManager.sendError(result.error);
          } else {
            this.currentATKCirculationReports = result.data.list;
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  onLoadJSON = (id: string) => {
    this._docApi
      .readTextDocumentWithStatuses({
        kvintaOperationRequestResource: {
          input: {
            id: id,
            system: KvintaSoftwareSystem.Kvinta,
            type: KvintaResourceType.AggregatedCustomsDocument,
          },
        },
      })
      .then((result) => {
        runInAction(() => {
          this.jsonPayload = undefined;
          if (result.data) {
            const newObj = {
              ...result.data,
              content: JSON.parse(result.data.content),
            };
            this.jsonPayload = JSON.stringify(newObj, null, 2);
          } else {
            this.jsonPayload = 'No data available';
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(err);
      });
  };

  releaseATKDocument(id: string) {
    if (this.isReleasing) return;
    this.isReleasing = true;
    this._docApi
      .releaseTextDocument({
        kvintaOperationRequestResource: {
          input: {
            id: id,
            system: KvintaSoftwareSystem.Kvinta, //TODO
            type: KvintaResourceType.AggregatedCustomsDocument,
          },
        },
      })
      .then((result) => {
        this.isReleasing = false;
        runInAction(() => {
          if (result.status !== KvintaOperationStatus.Ok) {
            this._notificationManager.sendError(result.error);
          } else {
            this.fetchATK(id);
          }
        });
      })
      .catch((err) => {
        this.isReleasing = false;
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  /**
   * Export
   */
  updateSNExported() {
    this.exportSNData = undefined;
  }

  exportSNAll() {
    runInAction(() => {
      this.exportSNActive = false;
      this.exportSNData = undefined;
    });

    runInAction(() => {
      this.exportSNData = this.currentATK.serialNumbers.map((sn) => {
        return {
          id: sn.id,
        } as SerialNumber;
      });
      this.exportSNActive = true;
    });
  }

  updateStatusesExported() {
    this.exportSNData = undefined;
  }

  exportStatusesAll() {
    runInAction(() => {
      this.exportStatusesActive = false;
      this.exportStatusesData = undefined;
    });

    runInAction(() => {
      this.exportStatusesData = this.currentATK.record.statuses;
      this.exportStatusesActive = true;
    });
  }

  async createCirculationReport(data) {
    this._arlaApi
      .transformAtkToCirculationReport({
        kvintaOperationRequestCreateCirculationReportForAtkRequest: {
          input: {
            fields: {
              atk: data.id,
              ...data,
            },
            resource: {
              id: data.id,
              system: KvintaSoftwareSystem.Kvinta,
              type: KvintaResourceType_Arla.AggregatedCustomsDocument,
            },
          },
        },
      })
      .then((response) => {
        if (response.status === KvintaOperationStatus.Error) {
          this._notificationManager.sendError(JSON.stringify(response.error));
        } else {
          this.fetchATKCirculationReports(data.id);
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }
}

function DocToExtDoc(data: KvintaTextDocument): ExtKvintaTextDocument {
  let serialNumbers = [];
  if (data.content) {
    const serialNumbersStrings = (JSON.parse(data.content) as ContentType).serialNumbers;

    serialNumbers = serialNumbersStrings.map((value) => ({ id: value }));
  }
  return {
    ...data,
    serialNumbers,
  };
}

function sortAtkStatuses(statuses: KvintaResourceStatus[]) {
  return statuses.sort((a, b) => {
    if (a.timestamp.epochMillis === b.timestamp.epochMillis) {
      return a.timestamp.sequenceNumber < b.timestamp.sequenceNumber ? 1 : -1;
    } else {
      return a.timestamp.epochMillis < b.timestamp.epochMillis ? 1 : -1;
    }
  });
}

export const STORE_ID = 'atkListStore';

function getField(orderBy: number): string {
  switch (orderBy) {
    case 0:
      return 'timestamp';
    case 1:
      return 'userId';
    case 2:
      return 'lastStatus';
    case 3:
      return 'externalNumber';
    default:
      return 'timestamp';
  }
}
