/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaIssuerSystemSerialNumber,
    KvintaIssuerSystemSerialNumberFromJSON,
    KvintaIssuerSystemSerialNumberFromJSONTyped,
    KvintaIssuerSystemSerialNumberToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestListIssuerSystemSerialNumber
 */
export interface KvintaOperationRequestListIssuerSystemSerialNumber {
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestListIssuerSystemSerialNumber
     */
    context?: KvintaExecutionContext;
    /**
     * 
     * @type {Array<KvintaIssuerSystemSerialNumber>}
     * @memberof KvintaOperationRequestListIssuerSystemSerialNumber
     */
    input?: Array<KvintaIssuerSystemSerialNumber>;
}

export function KvintaOperationRequestListIssuerSystemSerialNumberFromJSON(json: any): KvintaOperationRequestListIssuerSystemSerialNumber {
    return KvintaOperationRequestListIssuerSystemSerialNumberFromJSONTyped(json, false);
}

export function KvintaOperationRequestListIssuerSystemSerialNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestListIssuerSystemSerialNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
        'input': !exists(json, 'input') ? undefined : ((json['input'] as Array<any>).map(KvintaIssuerSystemSerialNumberFromJSON)),
    };
}

export function KvintaOperationRequestListIssuerSystemSerialNumberToJSON(value?: KvintaOperationRequestListIssuerSystemSerialNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': KvintaExecutionContextToJSON(value.context),
        'input': value.input === undefined ? undefined : ((value.input as Array<any>).map(KvintaIssuerSystemSerialNumberToJSON)),
    };
}


