/* eslint-disable @typescript-eslint/ban-types */
import { Button, Paper } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { SerialNumberOrdersStore, STORE_ID } from '../SerialNumberOrdersStore';
import { tabsSummary } from './tabs';
import {
  navigateSerialNumberOrderDocumentStatusesPath,
  navigateSerialNumberOrderItemsPath,
  navigateSerialNumberOrderPath,
  navigateSerialNumberOrderSerialNumbersPath,
} from '../paths';
import { PageContentStore, TabBar, tableIcons, PAGE_CONTENT_STORE_ID } from 'kvinta/components';
import { Route } from 'react-router-dom';
import MaterialTable from 'material-table';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CSVDownload } from 'react-csv';

interface SerialNumberOrderDocumentStatusesViewProps {
  intl: any;
  serialNumberOrdersStore?: SerialNumberOrdersStore;
  pageContentStore?: PageContentStore;
  id: string;
  history?: any;
}

class SerialNumberOrderDocumentStatusesViewImpl extends Component<SerialNumberOrderDocumentStatusesViewProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: SerialNumberOrderDocumentStatusesViewProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.serialNumberOrdersStore.fetchDocumentStatusesData(this.props.id);
  }

  render() {
    if (!this.props.serialNumberOrdersStore) {
      return null;
    }
    if (!this.props.serialNumberOrdersStore.currentSerialNumberDocument) {
      return null;
    }
    // const { updateSerialNumberOrderFormOpen } = this.props.serialNumberOrdersStore;
    const { currentSerialNumberDocument, isLoading, pageSize, exportSNDocStatusesActive, exportSNDocStatusesData } =
      this.props.serialNumberOrdersStore;
    this.props.serialNumberOrdersStore.updateSNDocStatusesExported();
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    const intl = this.props.intl;
    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                <TabBar
                  onChange={(newValue: any) => {
                    if (newValue === '1') {
                      navigateSerialNumberOrderPath(this.props.history, this.props.id);
                    }
                    if (newValue === '2') {
                      navigateSerialNumberOrderItemsPath(this.props.history, this.props.id);
                    }
                    if (newValue === '3') {
                      navigateSerialNumberOrderSerialNumbersPath(this.props.history, this.props.id);
                    }
                    if (newValue === '4') {
                      navigateSerialNumberOrderDocumentStatusesPath(this.props.history, this.props.id);
                    }
                  }}
                  tabId={'4'}
                  tabs={tabsSummary}
                />
              </Paper>
              <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
                <MaterialTable
                  icons={tableIcons}
                  tableRef={this.tableRef}
                  // title={null}
                  columns={[
                    {
                      title: intl.formatMessage({ id: 'document-status.timestamp' }),
                      field: 'timestamp',
                      defaultSort: 'desc',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '35%',
                      },
                      render: (rowData) => {
                        return <span>{new Date(rowData.timestamp.epochMillis).toISOString()}</span>;
                      },
                    },
                    {
                      title: intl.formatMessage({ id: 'document-status.status' }),
                      field: 'status',
                      defaultSort: 'desc',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '35%',
                      },
                    },
                    {
                      title: intl.formatMessage({ id: 'document-status.message' }),
                      field: 'message',
                      defaultSort: 'desc',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '15%',
                      },
                    },
                    {
                      title: intl.formatMessage({ id: 'document-status.error' }),
                      field: 'error',
                      defaultSort: 'desc',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '15%',
                      },
                    },
                  ]}
                  data={currentSerialNumberDocument.statuses}
                  totalCount={currentSerialNumberDocument.statuses.length}
                  isLoading={isLoading}
                  options={{
                    actionsColumnIndex: 0,
                    paging: true,
                    sorting: true,
                    draggable: false,
                    search: false,
                    pageSize: pageSize,
                    pageSizeOptions: [pageSize],
                    minBodyHeight: bodyHeight,
                    maxBodyHeight: bodyHeight,
                    showTitle: false,
                    thirdSortClick: false,
                    emptyRowsWhenPaging: false,
                  }}
                  localization={{
                    header: {
                      actions: '',
                    },
                  }}
                  components={{
                    Toolbar: (props) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          marginTop: '20px',
                          marginRight: '20px',
                        }}
                      >
                        <div style={{ marginLeft: '5px' }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<GetAppIcon color="secondary" />}
                            onClick={(e) => {
                              this.props.serialNumberOrdersStore.exportSNDocStatusesAll();
                            }}
                          >
                            {intl.formatMessage({ id: 'button.export' })}
                          </Button>
                        </div>
                      </div>
                    ),
                  }}
                />
              </Paper>
            </div>
            {exportSNDocStatusesActive && exportSNDocStatusesData !== undefined && (
              <CSVDownload data={exportSNDocStatusesData} target="_blank" />
            )}
          </div>
        )}
      />
    );
  }
}

export const SerialNumberOrderDocumentStatusesView = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, STORE_ID)(observer(SerialNumberOrderDocumentStatusesViewImpl)),
);
