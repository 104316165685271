export const CirculationReportsListPath = '/circulation-reports';
export const CirculationReportSummaryPath = '/circulation-report/:id';
export const CirculationReportPath = '/circulation-report/:id';
export const CirculationReportJsonPath = '/circulation-report/:id/json';
export const CirculationReportStatusesPath = '/circulation-report/:id/statuses';

export function navigateCirculationReportsListPath(history: any) {
  history.push(CirculationReportsListPath);
}

export function navigateCirculationReportSummaryPath(history: any, id: string) {
  history.push(CirculationReportSummaryPath.replaceAll(':id', id));
}

export function linkCirculationReportSummaryPath(history: any, id: string): string {
  return CirculationReportSummaryPath.replaceAll(':id', id);
}

export function navigateCirculationReportJsonPath(history: any, id: string) {
  history.push(CirculationReportJsonPath.replaceAll(':id', id));
}

export function navigateCirculationReportStatusesPath(history: any, id: string) {
  history.push(CirculationReportStatusesPath.replaceAll(':id', id));
}
