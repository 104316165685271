/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, Button, Container, Dialog, DialogActions, DialogContent, FormControl } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KvintaInelMessageBizStep } from 'kvinta/apis/kvinta-arla-extensions';
import { ProcessesStatusStore, PROCESSES_STATUS_STORE_ID } from '../ProcessesStatusStore';
import { KDialogTitle } from 'kvinta/components/KDialog';

interface DialogUploadRawPayloadProps {
  processesStatusStore?: ProcessesStatusStore;
  intl: any;
}

const bizSteps = [
  { label: KvintaInelMessageBizStep.Commissioning, value: KvintaInelMessageBizStep.Commissioning },
  { label: KvintaInelMessageBizStep.Shipping, value: KvintaInelMessageBizStep.Shipping },
];

class DialogUploadRawPayloadImpl extends Component<DialogUploadRawPayloadProps> {
  render() {
    const isOpen = this.props.processesStatusStore.uploadRawResourceFormOpen;
    const onChangeValue = this.props.processesStatusStore.onChangeRawResourceField;
    const formData = this.props.processesStatusStore.uploadRawData;
    const intl = this.props.intl;
    const showError = formData.showError;

    if (!isOpen) {
      return null;
    }
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isOpen}
        onClose={() => this.props.processesStatusStore.closeUploadRawResourceForm()}
      >
        <KDialogTitle
          id="customized-dialog-title"
          onClose={() => this.props.processesStatusStore.closeUploadRawResourceForm()}
        >
          {intl.formatMessage({ id: 'execution.upload.title' })}
        </KDialogTitle>
        <DialogContent>
          <Container>
            <Box m={2}>
              <FormControl variant="outlined" fullWidth>
                <input
                  accept="text/application"
                  id="file"
                  multiple
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => onChangeValue('selectedFile', e.target.files[0])}
                />
                <label htmlFor="file">
                  <Button variant="contained" color="primary" component="span">
                    {intl.formatMessage({ id: 'execution.upload.file' })}
                    {formData.selectedFile ? ' - ' + formData.selectedFile.name : ''}
                  </Button>
                </label>
              </FormControl>
            </Box>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.processesStatusStore.closeUploadRawResourceForm()}
            color="primary"
            variant="outlined"
          >
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <Button
            onClick={() => this.props.processesStatusStore.submitUploadRawResourceForm()}
            color="primary"
            variant="contained"
            // disabled={!formData.isValid}
            autoFocus
          >
            {intl.formatMessage({ id: 'execution.upload.uploadbtn' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const DialogUploadRawPayload = injectIntl(
  inject(PROCESSES_STATUS_STORE_ID)(observer(DialogUploadRawPayloadImpl)),
);
