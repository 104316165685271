import { Paper } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { SequenceStatusStore, SEQUENCE_STATUS_STORE_ID } from './SequenceStatusStore';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { tabsSummary } from './tabs';
import { reaction } from 'mobx';
import { IEditState } from 'kvinta/common';
import { navigateSequenceStatusesPath, navigateSequenceStatusDependsPath } from 'kvinta/modules/paths';
import { SequenceStatusCompView } from './SequenceStatusCompView';

interface SequenceStatusSummaryViewProps {
  intl: any;
  sequenceStatusStore?: SequenceStatusStore;
  sidebarStore?: KSidebarStore;
  repStatusId: number;
  history?: any;
  currentSequenceStatus?: any;
}

class SequenceStatusSummaryViewImpl extends Component<SequenceStatusSummaryViewProps, IEditState> {
  disposer: any;

  constructor(props: SequenceStatusSummaryViewProps) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  async componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.sequenceStatusStore.fetchSequenceStatus(this.props.repStatusId);
    this.disposer = reaction(
      () => this.props.sequenceStatusStore.currentSequenceStatus,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.repStatusId as any, () => {
            navigateSequenceStatusesPath(this.props.history);
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    if (!this.props.sequenceStatusStore) {
      return null;
    }
    const formData = this.props.sequenceStatusStore.currentSequenceStatus;
    if (!formData) {
      return null;
    }
    return (
      <div>
        <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          <TabBar
            onChange={(newValue: any) => {
              if (newValue === '0') {
                navigateSequenceStatusesPath(this.props.history);
              }
              if (newValue === '2') {
                navigateSequenceStatusDependsPath(this.props.history, formData.repStatusId);
              }
            }}
            tabId={'1'}
            tabs={tabsSummary}
          />
        </Paper>
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          <SequenceStatusCompView sequenceStatusData={formData} history={this.props.history} />
        </Paper>
      </div>
    );
  }
}

export const SequenceStatusSummaryView = injectIntl(
  inject(SEQUENCE_STATUS_STORE_ID, SIDEBAR_STORE_ID)(observer(SequenceStatusSummaryViewImpl)),
);
