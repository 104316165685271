import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KvintaProduct, KvintaCompany } from 'kvinta/apis/kvinta-masterdata-service';
import { navigateCompanyPath } from 'kvinta/modules/paths';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Route } from 'react-router-dom';

export interface ProductComponentProps {
  product?: KvintaProduct;
  companyData: KvintaCompany;
  intl: any;
  isEditing: boolean;
  productData: any;
  companies: any;
  history: any;
}
class ProductCompViewImpl extends Component<ProductComponentProps> {
  render() {
    const formData = this.props.product;
    if (!formData) {
      return null;
    }
    const companyData = this.props.companyData;
    if (!companyData) {
      return null;
    }
    const intl = this.props.intl;
    const disabled = !this.props.isEditing;
    const newFormData = this.props.productData;
    const companies = this.props.companies;

    return (
      <Route
        render={({ history }) => (
          <Grid container>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="name">
                    {intl.formatMessage({ id: 'product-form.field.name' })}*
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.name))}
                    required
                    id="name"
                    defaultValue={formData.name}
                    onChange={(e) => {
                      newFormData.name = e.target.value;
                    }}
                    label={`${intl.formatMessage({ id: 'product-form.field.name' })}*`}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="sku">
                    {intl.formatMessage({ id: 'product-form.field.sku' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.sku))}
                    required
                    id="sku"
                    defaultValue={formData.sku}
                    onChange={(e) => {
                      newFormData.sku = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'product-form.field.sku' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="idinsourcesystem">
                    {intl.formatMessage({ id: 'product-form.field.idinsourcesystem' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.idInSourceSystem))}
                    required
                    id="idInSourceSystem"
                    defaultValue={formData.idInSourceSystem}
                    onChange={(e) => {
                      newFormData.idInSourceSystem = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'product-form.field.idinsourcesystem' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="company">
                    {intl.formatMessage({ id: 'product-form.field.company' })}*
                  </InputLabel>
                  {this.props.isEditing ? (
                    <Select
                      required
                      id="company"
                      defaultValue={formData.companyId}
                      onChange={(e) => {
                        newFormData.company = e.target.value;
                      }}
                      label={`${intl.formatMessage({ id: 'product-form.field.company' })}*`}
                      endAdornment={
                        this.props.isEditing
                          ? null
                          : formData.companyId && (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={(e) => {
                                    navigateCompanyPath(this.props.history, formData.companyId);
                                  }}
                                >
                                  <OpenInNewIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                      }
                    >
                      {companies.map((company) => (
                        <MenuItem key={company.id} value={company.id}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <OutlinedInput
                      readOnly={disabled}
                      id="company"
                      defaultValue={companyData.name}
                      label={intl.formatMessage({ id: 'product-form.field.company' })}
                      endAdornment={
                        formData.companyId && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(e) => {
                                navigateCompanyPath(this.props.history, formData.companyId);
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    />
                  )}
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="shelfLife">
                    {intl.formatMessage({ id: 'product-form.field.shelfLife' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.shelfLife))}
                    required
                    id="shelfLife"
                    defaultValue={formData.shelfLife}
                    onChange={(e) => {
                      newFormData.shelfLife = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'product-form.field.shelfLife' })}
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="description">
                    {intl.formatMessage({ id: 'product-form.field.description' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.description))}
                    required
                    id="description"
                    defaultValue={formData.description}
                    onChange={(e) => {
                      newFormData.description = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'product-form.field.description' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="sourcesystem">
                    {intl.formatMessage({ id: 'product-form.field.sourcesystem' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.sourceSystem))}
                    required
                    id="sourceSystem"
                    defaultValue={formData.sourceSystem}
                    onChange={(e) => {
                      newFormData.sourceSystem = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'product-form.field.sourcesystem' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="baseuom">
                    {intl.formatMessage({ id: 'product-form.field.baseuom' })}
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.baseUom))}
                    required
                    id="baseUom"
                    defaultValue={formData.baseUom}
                    onChange={(e) => {
                      newFormData.baseUom = e.target.value;
                    }}
                    label={intl.formatMessage({ id: 'product-form.field.baseuom' })}
                  />
                </FormControl>
              </Box>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="tnved">
                    {intl.formatMessage({ id: 'product-form.field.tnved' })}*
                  </InputLabel>
                  <OutlinedInput
                    readOnly={disabled}
                    disabled={!(this.props.isEditing || Boolean(formData.tnved))}
                    required
                    id="tnved"
                    defaultValue={formData.tnved}
                    onChange={(e) => {
                      newFormData.tnved = e.target.value;
                    }}
                    label={`${intl.formatMessage({ id: 'product-form.field.tnved' })}*`}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        )}
      />
    );
  }
}

export const ProductCompView = injectIntl(ProductCompViewImpl);
