import { AppBar, Divider, Drawer, IconButton, Toolbar } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { IMenuItem, IMenuItemClickable, isSelectedMenu } from './Menus';
import { SidebarItem } from './SidebarItem';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { UserMenu } from 'kvinta/components/Appbar/UserMenuComp';
import { SIDEBAR_STORE_ID, KSidebarStore } from './KSidebarStore';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

interface ISidebarProps {
  sidebarStore?: KSidebarStore;
  intl: any;
  location?: any;
}

class KSidebar extends Component<ISidebarProps> {
  componentDidMount() {
    const menuItems = this.props.sidebarStore.menuItems;
    for (const menuItem of menuItems) {
      if (typeof menuItem === 'object') {
        const mic = menuItem as IMenuItemClickable;
        if (this.menuItemSelected(mic)) {
          break;
        }
      }
    }
  }

  menuItemSelected(mic: IMenuItemClickable): boolean {
    if (!(mic.items && mic.items.length > 0)) {
      if (isSelectedMenu(mic, this.props.location.pathname)) {
        this.props.sidebarStore.updateTitle(this.props.intl.formatMessage({ id: mic.label }));
        return true;
      }
    } else {
      for (const mii of mic.items) {
        if (typeof mii === 'object') {
          const miic = mii as IMenuItemClickable;
          if (this.menuItemSelected(miic)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  getItem(sidebarItem: IMenuItem) {
    if (sidebarItem === 'divider') {
      return <Divider />;
    }
    const item = sidebarItem as IMenuItemClickable;
    return (
      <SidebarItem
        paddingLeft={0}
        isExpanded={false}
        item={item}
        onClick={(path, label) => {
          this.props.sidebarStore.updateTitle(label);
        }}
        selectedItem={this.props.location.pathname}
        isWideDrawer={this.props.sidebarStore.isOpen}
        isExternal={item.isExternal}
      />
    );
  }

  createMenuItems = () => {
    const menuItems = this.props.sidebarStore.menuItems;
    const listItems = menuItems.map((sidebarItem: any, index) => (
      <React.Fragment key={`${sidebarItem.name}${index}`}>{this.getItem(sidebarItem)}</React.Fragment>
    ));
    return listItems;
  };

  render() {
    const listItems = this.createMenuItems();
    const { isOpen, hasNavigation, mainTitle, currentTitle, navigateFunc } = this.props.sidebarStore;
    const appBarStyle = isOpen === false ? 'app-bar-open' : 'app-bar-closed';
    const menuBtnStyle = isOpen === false ? 'app-bar-menu-btn' : 'app-bar-menu-btn-open';
    const drawerStyle = isOpen ? 'drawer drawer-open' : 'drawer'; // drawer-closed';
    const mainTitleAndNav =
      mainTitle !== undefined ? (
        <div className={'section-desktop'}>
          {hasNavigation && navigateFunc !== undefined && (
            <IconButton color="inherit" onClick={() => navigateFunc()} edge="start" className={'app-bar-menu-btn'}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <a className="sidebar-brand" href="./">
            <div className="sidebar-brand-icon">{mainTitle}</div>
          </a>
        </div>
      ) : null;
    const appBarIcon =
      isOpen === false ? (
        <div className={'section-desktop'}>
          <IconButton
            color="inherit"
            onClick={() => this.props.sidebarStore.toggleOpen()}
            edge="start"
            className={menuBtnStyle}
          >
            <MenuIcon />
          </IconButton>
          <a className="sidebar-brand" href="./">
            <div className="sidebar-brand-icon">{currentTitle}</div>
          </a>
        </div>
      ) : null;
    return (
      <div id="sidebar-wrapper">
        <AppBar position="fixed" className={appBarStyle}>
          <Toolbar>
            {appBarIcon}
            {mainTitleAndNav}
            <div className={'grow'} />
            <div className={'section-desktop'}>
              <UserMenu />
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="persistent" className={drawerStyle} open={isOpen}>
          <div className={'toolbar'}>
            <IconButton
              color="inherit"
              onClick={() => this.props.sidebarStore.toggleOpen()}
              style={{ paddingLeft: '16px' }}
            >
              <MenuOpenIcon />
            </IconButton>

            {isOpen && (
              <a className="sidebar-brand" href="./">
                <div className="sidebar-brand-icon">{this.props.sidebarStore.currentTitle}</div>
              </a>
            )}
          </div>
          {listItems}
        </Drawer>
      </div>
    );
  }
}

export default withRouter(injectIntl(inject(SIDEBAR_STORE_ID)(observer(KSidebar))));
