import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { TAppOptionsConfig } from 'kvinta/common';
import {
  DefaultApi as EDocumentApi,
  KvintaEpcisDocumentFilters,
  KvintaEpcisDocumentRecord,
  KvintaSortDirection,
  KvintaSortExpressions,
} from 'kvinta/apis/kvinta-epcis-document-store';
import { DefaultApi as MDDocumentApi, KvintaLocation } from 'kvinta/apis/kvinta-masterdata-service';
import {
  Configuration as EQueryConf,
  DefaultApi as EQueryStoreApi,
  KvintaDateTimeRange,
  KvintaEpcEvent,
  KvintaEventListFilter,
  KvintaGetEpcEventListRequest,
  KvintaOperationType,
} from 'kvinta/apis/kvinta-epcis-query-functions';
import { NotificationManager } from 'kvinta/modules/main';
import { KvintaOperationStatus } from 'kvinta/apis/kvinta-document-store';
import { IFilter } from 'kvinta/components';
import { HierarchyHistoryStore } from './Hierarchy/HierarchyHistoryStore';
import { generateHierarchy, generateTimelinePoints } from './Hierarchy/utils';
import { THierarchyHistoryNodeParams, TSimpleHierarchyHistoryPoint, TTimelinePoint } from './Hierarchy/types';
import { MovementMapStore } from './Map/MovementMapStore';
import { pointsDataToGeoJSON } from './Map/mapUtils';
import { TCoordinates } from './Map/types';

type TEventData = { ts: number; parentId?: string; op: 'U' | 'P' | 'C' };
type TChildEventData = { epc: string; ts: number };

export interface VEpcMessageRow {
  location: string;
  op: string;
  id: string;
  ts: number;
  tsIdx?: number;
  idx: number;
}

export interface ExtKvintaEpcEvent extends KvintaEpcEvent {
  attrId: string;
  id: string;
  timestamp?: string;
  operation: string;
  action?: string;
  disposition?: string;
  errorDeclaration?: string;
  createdByTaskId?: string;
  messageId?: string;
  readPoint?: string;
  recordTime?: string;
}

export class EpcMessagesStore {
  private _config: TAppOptionsConfig;
  private _epcQueryApi: EQueryStoreApi;
  private _mdApi: MDDocumentApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  listData: VEpcMessageRow[];
  page: string | undefined;
  range: KvintaDateTimeRange;
  currentSort: KvintaSortExpressions;

  filter: IFilter;

  locations: KvintaLocation[];

  //summary
  currentEpc?: ExtKvintaEpcEvent;

  // Hierarchy
  hierarchyHistory: TSimpleHierarchyHistoryPoint[];
  // hierarchyHistoryData: any[];

  movementMapStore?: MovementMapStore;

  constructor(
    config: TAppOptionsConfig,
    notificationManager: NotificationManager,
    epcQueryFunctionsApi: EQueryStoreApi,
    mdApi: MDDocumentApi,
  ) {
    makeObservable(this, {
      fetchNextPage: action,
      fetchInitialData: action,
      loadHierarchy: action.bound,
      fetchEpc: action.bound,
      fetchMap: action.bound,

      isLoading: observable,
      listData: observable,
      page: observable,
      filter: observable,
      currentEpc: observable,

      totalCount: computed,

      // Hierarchy
      hierarchyHistory: observable,

      movementMapStore: observable,
    });

    this._config = config;
    this._epcQueryApi = epcQueryFunctionsApi;
    this._mdApi = mdApi;
    this._notificationManager = notificationManager;
    this.page = undefined;
    this.listData = new Array<VEpcMessageRow>();
    this.currentSort = {
      expressions: [
        {
          direction: KvintaSortDirection.Desc,
          property: 'createDate',
        },
      ],
    };
    this.filter = new IFilter(
      [
        {
          field: 'id',
          label: 'epcis-document.id',
          isActive: true,
          value: '',
        },
        {
          field: 'location',
          label: 'epcis-document.location',
          isActive: false,
          value: '',
        },
      ],
      this.doFilter,
    );

    this.hierarchyHistory = undefined;

    this.currentEpc = undefined;
  }

  doFilter = async () => {
    runInAction(() => {
      this.isLoading = true;
      this.page = undefined;
      this.listData = [];
    });
    this.fetchData();
  };

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    runInAction(() => {
      this.isLoading = true;
      const dir = getDirection(orderDirection);
      const field = getField(orderBy);
      this.currentSort = {
        expressions: [
          {
            direction: dir,
            property: field,
          },
        ],
      };
    });
    this.fetchData();
  }

  get totalCount() {
    return this.listData ? this.listData.length : 0;
  }

  async fetchInitialData() {
    this.page = undefined;
    this.isLoading = true;
    this.listData = [];
    this.fetchData();
  }

  async fetchNextPage() {
    this.isLoading = true;
    this.fetchData();
  }

  async fetchData() {
    let filters = {} as KvintaEventListFilter;

    for (const filter of this.filter.visibleFilters) {
      if (filter.field === 'id' && filter.value !== '') {
        filters = {
          ...filters,
          epc: filter.value,
        };
      }
      if (filter.field === 'location' && filter.value !== '') {
        filters = {
          ...filters,
          loc: filter.value,
        };
      }
    }
    const fromDate = new Date();
    fromDate.setFullYear(fromDate.getFullYear() - 1);
    let reqData = {
      range: { from: fromDate.getTime(), to: new Date().getTime() } as KvintaDateTimeRange,
      filter: filters,
    } as KvintaGetEpcEventListRequest;
    if (this.page) {
      reqData = {
        ...reqData,
        page: this.page,
      };
    }
    this._epcQueryApi
      .listEpcEvents({
        kvintaOperationRequestGetEpcEventListRequest: {
          input: {
            ...reqData,
          },
        },
      })
      .then((result) => {
        runInAction(() => {
          this.isLoading = false;
          if (result.status === KvintaOperationStatus.Error) {
            this.listData = [];
          } else {
            if (result.data.events) {
              this.listData = this.listData.concat(kepcApiRowToView(result.data.events, this.totalCount));
              this.page = result.data.nextpage;
            }
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async fetchEpc(id: string, ts: number, idx?: number) {
    this.currentEpc = undefined;
    const filterIndex = idx ? idx : 0;
    const fromDate = new Date();
    fromDate.setFullYear(fromDate.getFullYear() - 1);
    let reqData = {
      range: { from: fromDate.getTime(), to: new Date().getTime() } as KvintaDateTimeRange,
      filter: { epc: id },
    } as KvintaGetEpcEventListRequest;
    if (this.page) {
      reqData = {
        ...reqData,
        page: this.page,
      };
    }
    this._epcQueryApi
      .listEpcEvents({
        kvintaOperationRequestGetEpcEventListRequest: {
          input: {
            ...reqData,
          },
        },
      })
      .then((result) => {
        runInAction(() => {
          this.isLoading = false;
          if (result.status !== KvintaOperationStatus.Error) {
            if (result.data.events) {
              for (const ev of result.data.events) {
                if ((ev.ts == ts || ts == 0) && (ev.idx == filterIndex || (ev.idx === undefined && filterIndex == 0))) {
                  this.currentEpc = kvintaToExtEvent(ev);
                  break;
                }
              }
              if (this.currentEpc === undefined && result.data.events.length > 0) {
                this.currentEpc = kvintaToExtEvent(result.data.events[0]);
              }
            }
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async fetchMap(id: string) {
    this.currentEpc = undefined;
    this.movementMapStore = undefined;
    const fromDate = new Date();
    fromDate.setFullYear(fromDate.getFullYear() - 1);

    this._epcQueryApi
      .readEpcEventById({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        this._epcQueryApi
          .readRelevantChangesLocations({
            kvintaOperationRequestGetRelevantChangesRequest: {
              input: {
                epc: id,
                range: { from: fromDate.getTime(), to: new Date().getTime() } as KvintaDateTimeRange,
              },
            },
          })
          .then((relResult) => {
            runInAction(() => {
              if (result.status === KvintaOperationStatus.Ok) {
                this.currentEpc = kvintaToExtEvent(result.data);
                const locations = relResult.data.relevantChanges.map((change) => {
                  return {
                    id: change.location.id,
                    name: change.location.name,
                    gln13: change.location.gln13,
                    coordinates: [change.location.longitude, change.location.latitude] as TCoordinates,
                    timestamp: new Date(change.ts).toISOString(),
                    description: '',
                    type: '',
                  };
                });

                const points = pointsDataToGeoJSON(locations);
                this.movementMapStore = new MovementMapStore(points, locations, locations[locations.length - 1].id);
              } else {
                this.currentEpc = undefined;
                this._notificationManager.sendError(result.error);
              }
            });
          })
          .catch((err) => {
            this._notificationManager.sendError(JSON.stringify(err));
          });
      })
      .catch((err) => {
        this._notificationManager.sendError(err);
      });
  }

  async loadHierarchy(id: string) {
    this.hierarchyHistory = undefined;

    const hierarchyEvents = await this._epcQueryApi.readHierarchyEvents({
      kvintaOperationRequestGetRelevantChangesRequest: {
        input: {
          epc: id,
          range: {
            to: Date.now(),
          },
        },
      },
    });

    const filteredEvents = hierarchyEvents.data.events
      ? hierarchyEvents.data.events.reduce((acc: { [key: string]: any }, event: { ts?: number }) => {
          if (acc[event.ts]) {
            const packingEvent = [acc[event.ts], event].find((ev) => ev.op === 'P');
            acc[event.ts] = packingEvent;
          } else {
            acc[event.ts] = event;
          }
          return acc;
        }, {})
      : [];

    // Do the fetches for children
    const hierarchyHistoryData = [] as TSimpleHierarchyHistoryPoint[];
    for (const event of Object.values(filteredEvents)) {
      const childEvents = await this.getChildEvents(event, id);
      hierarchyHistoryData.push(childEvents);
    }

    runInAction(() => {
      this.hierarchyHistory = hierarchyHistoryData;
    });
  }

  async getChildEvents({ ts, parentId, op }: TEventData, id: string): Promise<TSimpleHierarchyHistoryPoint> {
    const eventDate = new Date(ts);
    const children = await this._epcQueryApi.readChildren({
      kvintaOperationRequestGetRelativesRequest: { input: { epc: id, ts: ts } },
    });

    return {
      timestamp: eventDate,
      hierarchy: {
        parent: parentId && op !== 'U' ? { id: parentId } : undefined,
        tracked: { id },
        children: (children.data || []).map((child: string) => ({ id: child })),
      },
    };
  }
}

export const EPC_MESSAGES_STORE_ID = 'epcMessagesStore';

function kepcApiRowToView(list: KvintaEpcEvent[], startIndex: number): VEpcMessageRow[] {
  let index = startIndex;
  const viewItems = list.map<VEpcMessageRow>((doc) => {
    return {
      location: doc.location,
      id: doc.epc,
      op: opToOperation(doc.op),
      idx: index++,
      ts: doc.ts,
      tsIdx: doc.idx,
    };
  });
  return viewItems;
}

function getDirection(orderDirection: string): KvintaSortDirection {
  if (orderDirection === 'asc') {
    return KvintaSortDirection.Asc;
  } else {
    return KvintaSortDirection.Desc;
  }
}

function getField(orderBy: number): string {
  switch (orderBy) {
    case 1:
      return 'id';
    case 2:
      return 'location';
    case 3:
      return 'op';
    default:
      return 'id';
  }
}

const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

enum EOperationType {
  COMMISSIONING = 'commissioning',
  PACKING = 'packing',
  UNPACKING = 'unpacking',
  SHIPMENT = 'shipment',
  RECEIVING = 'receiving',
  DECOMMISSIONING = 'decommissioning',
  OTHER = 'other',
}

function opToOperation(op: KvintaOperationType) {
  switch (op) {
    case KvintaOperationType.C:
      return EOperationType.COMMISSIONING;
    case KvintaOperationType.P:
      return EOperationType.PACKING;
    case KvintaOperationType.U:
      return EOperationType.UNPACKING;
    case KvintaOperationType.D:
      return EOperationType.DECOMMISSIONING;
    case KvintaOperationType.O:
      return EOperationType.OTHER;
    case KvintaOperationType.R:
      return EOperationType.RECEIVING;
    case KvintaOperationType.S:
      return EOperationType.SHIPMENT;
  }
}

function kvintaToExtEvent(record: KvintaEpcEvent): ExtKvintaEpcEvent {
  return {
    ...record,
    ...record.attr,
    attrId: record.attr?.id,
    id: record.epc,
    timestamp: record.ts ? new Date(record.ts).toISOString() : undefined,
    operation: record.op && opToOperation(record.op),
  };
}
