import { Button, Paper } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import { PageContentStore, PAGE_CONTENT_STORE_ID, TabBar, tableIcons } from 'kvinta/components';
import { ATKListStore, STORE_ID } from '../ATKListStore';
import {
  navigateATKCirculationReportsPath,
  navigateATKJsonPath,
  navigateATKListPath,
  navigateATKSerialNumbersPath,
  navigateATKStatusesPath,
  navigateATKSummaryPath,
} from '../paths';
import { tabsSummary } from './tabs';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CSVDownload } from 'react-csv';

interface ATKStatusesViewProps {
  atkListStore?: ATKListStore;
  pageContentStore?: PageContentStore;
  intl: any;
  history?: any;
  id: string;
}

class ATKStatusesViewImpl extends Component<ATKStatusesViewProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ATKStatusesViewProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.atkListStore.fetchATK(this.props.id);
  }

  render() {
    const intl = this.props.intl;
    const { currentATK, isLoading, pageSize, exportStatusesActive, exportStatusesData } = this.props.atkListStore;

    const comp = currentATK;

    if (!comp) {
      return null;
    }
    this.props.atkListStore.updateStatusesExported();
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;

    const formData = comp.record.statuses;
    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                <TabBar
                  onChange={(newValue: any) => {
                    if (newValue === '0') {
                      navigateATKListPath(this.props.history);
                    }
                    if (newValue === '1') {
                      navigateATKSummaryPath(this.props.history, this.props.id);
                    }
                    if (newValue === '2') {
                      navigateATKSerialNumbersPath(this.props.history, this.props.id);
                    }
                    if (newValue === '3') {
                      navigateATKJsonPath(this.props.history, this.props.id);
                    }
                    if (newValue === '4') {
                      navigateATKStatusesPath(this.props.history, this.props.id);
                    }
                    if (newValue === '5') {
                      navigateATKCirculationReportsPath(this.props.history, this.props.id);
                    }
                  }}
                  tabId={'4'}
                  tabs={tabsSummary}
                />
              </Paper>
              <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
                <MaterialTable
                  icons={tableIcons}
                  tableRef={this.tableRef}
                  title={null}
                  columns={[
                    {
                      title: intl.formatMessage({ id: 'atk-status-list.header.status' }),
                      field: 'status', // should be timestamp but currently crashes for some reason
                      sorting: false,
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '10%',
                      },
                    },
                    {
                      title: intl.formatMessage({ id: 'atk-status-list.header.timestamp' }),
                      field: 'timestamp', // should be timestamp but currently crashes for some reason
                      sorting: false,
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '20%',
                      },
                      render: (rowData) => {
                        return <span>{new Date(rowData.timestamp.epochMillis).toISOString()}</span>;
                      },
                    },
                    {
                      title: intl.formatMessage({ id: 'atk-status-list.header.executionid' }),
                      field: 'executionId', // should be timestamp but currently crashes for some reason
                      sorting: false,
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '50%',
                      },
                    },
                    {
                      title: intl.formatMessage({ id: 'atk-status-list.header.error' }),
                      field: 'error', // should be timestamp but currently crashes for some reason
                      sorting: false,
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '20%',
                      },
                    },
                  ]}
                  data={formData}
                  onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                    this.props.atkListStore.changeOrder(orderBy, orderDirection);
                  }}
                  isLoading={isLoading}
                  options={{
                    actionsColumnIndex: 0,
                    paging: true,
                    sorting: true,
                    draggable: false,
                    search: false,
                    pageSize: pageSize,
                    pageSizeOptions: [pageSize],
                    minBodyHeight: bodyHeight,
                    maxBodyHeight: bodyHeight,
                    thirdSortClick: false,
                    emptyRowsWhenPaging: false,
                  }}
                  localization={{
                    header: {
                      actions: '',
                    },
                  }}
                  components={{
                    Toolbar: (props) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          marginTop: '20px',
                          marginRight: '20px',
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<GetAppIcon color="primary" />}
                          onClick={(e) => {
                            this.props.atkListStore.exportStatusesAll();
                          }}
                        >
                          {intl.formatMessage({ id: 'button.export' })}
                        </Button>
                      </div>
                    ),
                  }}
                />
              </Paper>
            </div>
            {exportStatusesActive && exportStatusesData !== undefined && (
              <CSVDownload data={exportStatusesData} target="_blank" />
            )}
          </div>
        )}
      />
    );
  }
}

export const ATKStatusesView = injectIntl(inject(PAGE_CONTENT_STORE_ID, STORE_ID)(observer(ATKStatusesViewImpl)));
