/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSerialNumberOrderItemType,
    KvintaSerialNumberOrderItemTypeFromJSON,
    KvintaSerialNumberOrderItemTypeFromJSONTyped,
    KvintaSerialNumberOrderItemTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaCreateSerialNumberOrderItemRequest
 */
export interface KvintaCreateSerialNumberOrderItemRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberOrderItemRequest
     */
    gtin: string;
    /**
     * 
     * @type {KvintaSerialNumberOrderItemType}
     * @memberof KvintaCreateSerialNumberOrderItemRequest
     */
    itemType?: KvintaSerialNumberOrderItemType;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateSerialNumberOrderItemRequest
     */
    orderId: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaCreateSerialNumberOrderItemRequest
     */
    quantity?: number;
}

export function KvintaCreateSerialNumberOrderItemRequestFromJSON(json: any): KvintaCreateSerialNumberOrderItemRequest {
    return KvintaCreateSerialNumberOrderItemRequestFromJSONTyped(json, false);
}

export function KvintaCreateSerialNumberOrderItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateSerialNumberOrderItemRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gtin': json['gtin'],
        'itemType': !exists(json, 'itemType') ? undefined : KvintaSerialNumberOrderItemTypeFromJSON(json['itemType']),
        'orderId': json['orderId'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
    };
}

export function KvintaCreateSerialNumberOrderItemRequestToJSON(value?: KvintaCreateSerialNumberOrderItemRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gtin': value.gtin,
        'itemType': KvintaSerialNumberOrderItemTypeToJSON(value.itemType),
        'orderId': value.orderId,
        'quantity': value.quantity,
    };
}


