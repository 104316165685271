import React from 'react';
import { Provider } from 'mobx-react';
import { createStores } from './stores';
import ReactDOM from 'react-dom';
import { AuthService } from 'kvinta/service/auth';
import { TAppOptionsConfig } from 'kvinta/common';
import * as config from './config';
import { App } from './app';
import { ResizeObserver, ResizeObserverEntry } from '@juggle/resize-observer';

declare const window: any;

if (!('ResizeObserver' in window)) {
  window.ResizeObserver = ResizeObserver;
  // Only use it when you have this trouble: https://github.com/wellyshen/react-cool-dimensions/issues/45
  // window.ResizeObserverEntry = ResizeObserverEntry;
}

function appStarter(config: TAppOptionsConfig, authS: AuthService) {
  const store = createStores(config, authS);
  const wrappedApp = (
    <Provider {...store}>
      <App />
    </Provider>
  );
  ReactDOM.render(wrappedApp, document.getElementById('root'));
}

new AuthService(config, appStarter);
