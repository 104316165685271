import { action, makeObservable, observable, runInAction } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';
import {
  DefaultApi as DevicesApi,
  KvintaAppVersion,
  KvintaDevice,
  KvintaMobileAppAssignment,
} from 'kvinta/apis/kvinta-devices-store';
import { NotificationManager } from 'kvinta/modules/main';
import {
  KvintaOperationStatus,
  KvintaSortDirection,
  KvintaSortExpressions,
} from 'kvinta/apis/kvinta-document-store/models';
import { SelectableStore } from 'kvinta/common';
import { IFilter, IFilterColumn, PageContentStore } from 'kvinta/components';

export interface DeviceFormDialogData {
  email: string;
  locationGln13: string;
  serialNumber: string;
  showError: boolean;
}

export interface AssignmentFormDialogData {
  appName: string;
  appVersion: string;
  email: string;
  deviceId: string;
  showError: boolean;
}
interface IDeviceRow extends KvintaDevice, ISelectableRow {
  id: string;
}

export class DevicesStore extends SelectableStore<IDeviceRow> {
  private _config: TAppOptionsConfig;
  private _devicesApi: DevicesApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  page: number;
  totalCount: number;
  pageSize: number;
  searchValue: string;
  filter: IFilter;
  currentSort: KvintaSortExpressions;

  createDeviceData?: DeviceFormDialogData;
  createDeviceFormOpen: boolean;
  updateDeviceData?: DeviceFormDialogData;
  updateDeviceFormOpen: boolean;

  createAssignmentData?: AssignmentFormDialogData;
  createAssignmentFromOpen: boolean;
  updateAssignmentData?: AssignmentFormDialogData;
  updateAssignmentFromOpen: boolean;

  currentDevice?: KvintaDevice | null = null;
  currentDeviceAssignments?: KvintaMobileAppAssignment[];
  currentAssignment?: KvintaMobileAppAssignment | null = null;
  apps: KvintaAppVersion[];

  exportActive: boolean;
  exportData: IDeviceRow[] | KvintaDevice[] | undefined;
  autofocusSearchInList: boolean;
  pageContentStore: PageContentStore;

  constructor(
    config: TAppOptionsConfig,
    notificationManager: NotificationManager,
    devicesApi: DevicesApi,
    pageContentStore: PageContentStore,
  ) {
    super();
    makeObservable(this, {
      filter: observable,
      setFilter: action,
      fetchPage: action.bound,
      fetchData: action.bound,
      fetchDevice: action.bound,
      updateSearch: action,
      unfocusSearchField: action,

      updateExported: action,
      exportAll: action.bound,
      exportSelected: action.bound,

      isLoading: observable,
      page: observable,
      pageSize: observable,
      searchValue: observable,

      createDeviceData: observable,
      createDeviceFormOpen: observable,
      updateDeviceData: observable,
      updateDeviceFormOpen: observable,
      createAssignmentData: observable,
      createAssignmentFromOpen: observable,
      updateAssignmentData: observable,
      updateAssignmentFromOpen: observable,

      onChangeUpdateDeviceField: action.bound,
      submitUpdateDevice: action.bound,
      onChangeCreateAssignmentField: action.bound,
      submitUpdateAssignment: action.bound,

      exportActive: observable,
      currentDevice: observable,
      currentDeviceAssignments: observable,
      currentAssignment: observable,
    });

    this._config = config;
    this._devicesApi = devicesApi;
    this._notificationManager = notificationManager;
    this.pageContentStore = pageContentStore;
    this.pageSize = 25;
    this.page = 0;
    this.searchValue = '';
    this.autofocusSearchInList = false;
    this.currentSort = {
      expressions: [
        {
          direction: KvintaSortDirection.Desc,
          property: 'created',
        },
      ],
    };
  }

  doFilter = async () => {
    runInAction(() => {
      this.isLoading = true;
      this.page = 0;
    });
    this.fetchData();
  };

  setFilter(columns: IFilterColumn[]) {
    this.filter = new IFilter(columns, this.doFilter);
  }

  async fetchPage(page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
    });
    this.fetchData();
  }

  unfocusSearchField() {
    this.autofocusSearchInList = false;
  }

  updateSearch(value: string) {
    this.searchValue = value;
    this.isLoading = true;
    this.autofocusSearchInList = true;
    this.fetchData();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    runInAction(() => {
      this.isLoading = true;
      const field = getField(orderBy);
      this.currentSort = {
        expressions: [
          {
            property: field,
            direction: orderDirection === 'asc' ? KvintaSortDirection.Asc : KvintaSortDirection.Desc,
          },
        ],
      };
    });
    this.fetchData();
  }

  async fetchData() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.listData = [];
    newLocal.totalCount = 0;

    // const filterColumns = this.filter.visibleFilters;
    // let filters = {} as { [key: string]: string } | null;
    // for (const filter of filterColumns) {
    //   filters = {
    //     ...filters,
    //     [filter.field]: filter.value,
    //   };
    // }
    newLocal._devicesApi
      .listDevices({
        kvintaOperationRequestListDevicesRequest: {
          input: {
            pagination: { page: newLocal.page, size: newLocal.pageSize },
            // filter: filters,
          },
        },
      })
      .then((result) => {
        runInAction(() => {
          if (result.status === KvintaOperationStatus.Error) {
            newLocal.listData = [];
            newLocal._notificationManager.sendError(result.error);
          } else {
            newLocal.listData = (result.data.list || []).map((comp) => {
              return { ...comp } as IDeviceRow;
            });
            newLocal.totalCount = result.data.total || 0;
          }
        });
      })
      .catch((err) => {
        newLocal._notificationManager.sendError(JSON.stringify(err));
      })
      .finally(() => {
        runInAction(() => {
          newLocal.isLoading = false;
        });
      });
  }

  async openCreateDeviceForm() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.createDeviceData = {
      email: '',
      locationGln13: '',
      serialNumber: '',
      showError: false,
    };
    newLocal.isLoading = false;
    newLocal.createDeviceFormOpen = true;
  }
  closeCreateDeviceForm() {
    runInAction(() => {
      this.createDeviceFormOpen = false;
    });
  }

  onChangeCreateDeviceField = (id: string, value: any) => {
    runInAction(() => {
      this.createDeviceData[id] = value;
    });
  };

  submitCreateDeviceForm = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    let hasError = false;
    for (const field of requiredFields) {
      if (errorRequired(field, this.createDeviceData[field], requiredFields)) {
        hasError = true;
        break;
      }
    }
    this.createDeviceData.showError = hasError;
    if (!hasError) {
      runInAction(() => {
        newLocal.createDeviceFormOpen = false;
        newLocal.isLoading = true;
      });
      newLocal._devicesApi
        .createDevice({
          kvintaOperationRequestCreateDeviceRequest: {
            input: {
              email: newLocal.createDeviceData.email,
              locationGln13: newLocal.createDeviceData.locationGln13,
              serialNumber: newLocal.createDeviceData.serialNumber,
            },
          },
        })
        .then((res) => {
          newLocal.fetchData();
        })
        .catch((err: Error) => {
          newLocal._notificationManager.sendError(err.toString());
        })
        .finally(() => {
          newLocal.isLoading = false;
        });
    }
  };

  async openUpdateDeviceForm() {
    runInAction(() => {
      console.log('Update Device Data:', this.updateDeviceData);
    });
  }

  closeUpdateDeviceForm() {
    runInAction(() => {
      this.updateDeviceFormOpen = false;
    });
  }

  onChangeUpdateDeviceField = (id: string, value: any) => {
    runInAction(() => {
      this.updateDeviceData[id] = value;
    });
  };

  submitUpdateDevice = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.updateDeviceData.showError = false;
    let hasError = false;
    for (const field of requiredFields) {
      if (errorRequired(field, newLocal.updateDeviceData[field], requiredFields)) {
        hasError = true;
        break;
      }
    }
    if (!hasError) {
      newLocal._devicesApi
        .updateDevice({
          kvintaOperationRequestDevice: {
            input: {
              id: newLocal.currentDevice.id,
              email: newLocal.updateDeviceData.email,
              locationGln13: newLocal.updateDeviceData.locationGln13,
              serialNumber: newLocal.updateDeviceData.serialNumber,
            },
          },
        })
        .then((res) => {
          newLocal._notificationManager.sendSuccess(`Successfully updated ${this.currentDevice.id}`);
          newLocal.fetchData();
        })
        .catch((err: Error) => {
          newLocal._notificationManager.sendError(err.toString());
        })
        .finally(() => {
          newLocal.isLoading = false;
        });
    } else {
      newLocal._notificationManager.sendError('All fields are required!');
      newLocal.updateDeviceData.showError = true;
    }
  };

  /**
   * Export functionallity
   */
  updateExported() {
    this.exportData = undefined;
  }

  async exportSelected() {
    runInAction(() => {
      this.exportActive = false;
      this.exportData = undefined;
    });
    runInAction(() => {
      this.exportActive = true;
      this.exportData = this.listChecked;
    });
  }

  async exportAll() {
    runInAction(() => {
      this.exportActive = false;
      this.exportData = undefined;
    });
    try {
      const deviceListResult = await this._devicesApi.listDevices({
        kvintaOperationRequestListDevicesRequest: {
          input: {
            pagination: { page: this.page, size: this.pageSize },
          },
        },
      });
      runInAction(() => {
        this.exportActive = true;
        this.exportData = deviceListResult.data.list;
      });
    } catch (err) {
      this._notificationManager.sendError(JSON.stringify(err));
    }
  }

  async fetchDevice(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.currentDevice = null;
    const deviceResult = await newLocal._devicesApi.getDevice({
      kvintaOperationRequestString: { input: id },
    });
    if (deviceResult.status !== KvintaOperationStatus.Ok) {
      newLocal._notificationManager.sendError(deviceResult.error);
      newLocal.isLoading = false;
    } else if (!deviceResult.data) {
      newLocal._notificationManager.sendError('device not found');
      newLocal.isLoading = false;
    } else {
      runInAction(() => {
        newLocal.currentDevice = deviceResult.data;
        this.isLoading = true;
        this.updateDeviceData = {
          email: newLocal.currentDevice.email,
          locationGln13: newLocal.currentDevice.locationGln13,
          serialNumber: newLocal.currentDevice.serialNumber,
          showError: false,
        };
        this.isLoading = false;
      });
    }
  }

  async fetchAssignmentsPage(page: number, curDeviceId: string) {
    runInAction(() => {
      this.isLoading = true;
      this.page = page;
    });
    this.fetchDeviceAssignments(curDeviceId);
  }

  async changeDeviceAssignmentsOrder(orderBy: number, orderDirection: 'asc' | 'desc', curDeviceId: string) {
    runInAction(() => {
      this.isLoading = true;
      const field = getAssignmentField(orderBy);
      this.currentSort = {
        expressions: [
          {
            property: field,
            direction: orderDirection === 'asc' ? KvintaSortDirection.Asc : KvintaSortDirection.Desc,
          },
        ],
      };
    });
    this.fetchDeviceAssignments(curDeviceId);
  }

  async fetchDeviceAssignments(curDeviceId: string) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    runInAction(() => {
      newLocal.isLoading = true;
      newLocal.currentDeviceAssignments = [];
    });
    await this.fetchDevice(curDeviceId);
    await newLocal._devicesApi
      .listMobileAppAssignments({
        kvintaOperationRequestListMobileAppAssignmentRequest: {
          input: {
            filter: {
              deviceId: curDeviceId,
            },
            pagination: { page: this.page, size: this.pageSize },
            sort: this.currentSort,
          },
        },
      })
      .then((result) => {
        runInAction(() => {
          if (result.status === KvintaOperationStatus.Error) {
            newLocal.currentDeviceAssignments = [];
            newLocal._notificationManager.sendError(result.error);
          } else {
            newLocal.currentDeviceAssignments = (result.data.list || []).map((comp) => {
              return { ...comp } as KvintaMobileAppAssignment;
            });
            newLocal.totalCount = result.data.total || 0;
          }
        });
      })
      .catch((err) => {
        newLocal._notificationManager.sendError(JSON.stringify(err));
      })
      .finally(() => {
        runInAction(() => {
          newLocal.isLoading = false;
        });
      });
  }
  async openCreateAssignmentForm() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.apps = await (await newLocal._devicesApi.getApplications()).data.applications;
    runInAction(() => {
      newLocal.isLoading = true;
      newLocal.createAssignmentData = {
        appName: '',
        appVersion: '',
        email: '',
        deviceId: '',
        showError: false,
      };
      newLocal.isLoading = false;
      newLocal.createAssignmentFromOpen = true;
    });
  }

  closeCreateAssignmentForm() {
    runInAction(() => {
      this.createAssignmentFromOpen = false;
    });
  }

  onChangeCreateAssignmentField = (id: string, value: any) => {
    runInAction(() => {
      this.createAssignmentData[id] = value;
    });
  };

  submitCreateAssignmentForm = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    let hasError = false;

    for (const field of requiredAssignmentFields) {
      if (errorRequired(field, this.createAssignmentData[field], requiredAssignmentFields)) {
        hasError = true;
        break;
      }
    }
    this.createAssignmentData.showError = hasError;
    if (!hasError) {
      runInAction(() => {
        newLocal.createAssignmentFromOpen = false;
        newLocal.isLoading = true;
      });

      newLocal._devicesApi
        .createMobileAppAssignment({
          kvintaOperationRequestCreateMobileAppAssignmentRequest: {
            input: {
              appName: newLocal.createAssignmentData.appName,
              appVersion: newLocal.createAssignmentData.appVersion,
              deviceId: newLocal.currentDevice.id,
              email: newLocal.createAssignmentData.email,
            },
          },
        })
        .then((res) => {
          runInAction(() => {
            newLocal.isLoading = false;
          });
          newLocal.fetchDeviceAssignments(this.currentDevice.id);
        })
        .catch((err: Error) => {
          this._notificationManager.sendError(err.toString());
        });
    }
  };

  async openUpdateAssignmentForm() {
    runInAction(() => {
      this.updateAssignmentData = { ...this.currentAssignment, showError: false };
    });
  }

  closeUpdateAssignmentForm() {
    runInAction(() => {
      this.updateAssignmentFromOpen = false;
    });
  }

  onChangeUpdateAssignmentField = (id: string, value: any) => {
    runInAction(() => {
      this.updateAssignmentData[id] = value;
    });
  };

  submitUpdateAssignment = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    newLocal.isLoading = true;
    newLocal.updateAssignmentData.showError = false;
    let hasError = false;
    for (const field of requiredAssignmentFields) {
      if (errorRequired(field, newLocal.updateAssignmentData[field], requiredFields)) {
        hasError = true;
        break;
      }
    }
    if (!hasError) {
      newLocal._devicesApi
        .updateMobileAppAssignment({
          kvintaOperationRequestMobileAppAssignment: {
            input: {
              appName: newLocal.updateAssignmentData.appName,
              appVersion: newLocal.updateAssignmentData.appVersion,
              deviceId: newLocal.currentAssignment.deviceId,
              email: newLocal.updateAssignmentData.email,
              id: newLocal.currentAssignment.id,
            },
          },
        })
        .then((res) => {
          newLocal._notificationManager.sendSuccess(`Successfully updated ${this.currentAssignment.id}`);
          newLocal.fetchData();
        })
        .catch((err: Error) => {
          newLocal._notificationManager.sendError(err.toString());
        })
        .finally(() => {
          runInAction(() => {
            newLocal.isLoading = false;
          });
        });
    } else {
      newLocal._notificationManager.sendError('All fields are required!');
      newLocal.updateDeviceData.showError = true;
    }
  };

  async fetchAssignment(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const newLocal = this;
    runInAction(() => {
      newLocal.isLoading = true;
      newLocal.currentAssignment = null;
    });
    newLocal.apps = await (await newLocal._devicesApi.getApplications()).data.applications;

    const assignmentResult = await newLocal._devicesApi.getMobileAppAssignment({
      kvintaOperationRequestString: { input: id },
    });
    if (assignmentResult.status !== KvintaOperationStatus.Ok) {
      newLocal._notificationManager.sendError(assignmentResult.error);
      newLocal.isLoading = false;
    } else if (!assignmentResult.data) {
      newLocal._notificationManager.sendError('Assignment not found');
      newLocal.isLoading = false;
    } else {
      runInAction(() => {
        newLocal.currentAssignment = assignmentResult.data;
        this.isLoading = true;
        this.updateAssignmentData = {
          appName: this.currentAssignment.appName,
          appVersion: this.currentAssignment.appVersion,
          email: this.currentAssignment.email,
          deviceId: this.currentAssignment.deviceId,
          showError: false,
        };
        this.isLoading = false;
      });
    }
  }

  sendInvitation = async (): Promise<void> => {
    try {
      const response = await this._devicesApi.sendDownloadLink({
        kvintaOperationRequestMobileAppAssignment: {
          input: this.currentAssignment,
        },
      });
      if (response.status == KvintaOperationStatus.Ok) {
        this._notificationManager.sendSuccess('Successfuly sent email to ' + this.currentAssignment.email);
      } else {
        this._notificationManager.sendError(response.error);
      }
    } catch (err) {
      this._notificationManager.sendError('Faile to send email ' + JSON.stringify(err));
    }
  };
}

export const DEVICES_STORE_ID = 'devicesStore';

function getField(orderBy: number): string {
  switch (orderBy) {
    case 0:
      return 'id';
    case 1:
      return 'serialNumber';
    case 2:
      return 'email';
    case 3:
      return 'locationGln13';
    default:
      return 'id';
  }
}

function getAssignmentField(orderBy: number): string {
  switch (orderBy) {
    case 0:
      return 'appName';
    case 1:
      return 'appVersion';
    case 2:
      return 'deviceId';
    case 3:
      return 'email';
    default:
      return 'appName';
  }
}

const searchedColumns = ['id']; //, 'sgln', 'gln13', 'latitude', 'longitude', 'description']; // TODO: API is not adopted to search multiple columns

export const requiredFields = ['email', 'locationGln13', 'serialNumber'];
export const requiredAssignmentFields = ['appName', 'appVersion', 'email'];

export function errorRequired(id: string, value: any, fields: string[]): boolean {
  if (fields.includes(id) && (value === undefined || value === '')) {
    return true;
  }
  return false;
}
