/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSortDirection,
    KvintaSortDirectionFromJSON,
    KvintaSortDirectionFromJSONTyped,
    KvintaSortDirectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaSortExpression
 */
export interface KvintaSortExpression {
    /**
     * 
     * @type {KvintaSortDirection}
     * @memberof KvintaSortExpression
     */
    direction: KvintaSortDirection;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaSortExpression
     */
    ignoreCase?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KvintaSortExpression
     */
    property: string;
}

export function KvintaSortExpressionFromJSON(json: any): KvintaSortExpression {
    return KvintaSortExpressionFromJSONTyped(json, false);
}

export function KvintaSortExpressionFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSortExpression {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'direction': KvintaSortDirectionFromJSON(json['direction']),
        'ignoreCase': !exists(json, 'ignoreCase') ? undefined : json['ignoreCase'],
        'property': json['property'],
    };
}

export function KvintaSortExpressionToJSON(value?: KvintaSortExpression | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'direction': KvintaSortDirectionToJSON(value.direction),
        'ignoreCase': value.ignoreCase,
        'property': value.property,
    };
}


