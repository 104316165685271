/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionStatusType,
    KvintaExecutionStatusTypeFromJSON,
    KvintaExecutionStatusTypeFromJSONTyped,
    KvintaExecutionStatusTypeToJSON,
    KvintaResource,
    KvintaResourceFromJSON,
    KvintaResourceFromJSONTyped,
    KvintaResourceToJSON,
    KvintaTimestamp,
    KvintaTimestampFromJSON,
    KvintaTimestampFromJSONTyped,
    KvintaTimestampToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaExecutionStatus
 */
export interface KvintaExecutionStatus {
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatus
     */
    error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatus
     */
    message?: string | null;
    /**
     * 
     * @type {KvintaResource}
     * @memberof KvintaExecutionStatus
     */
    resource?: KvintaResource;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionStatus
     */
    retryExecutionId?: string | null;
    /**
     * 
     * @type {KvintaExecutionStatusType}
     * @memberof KvintaExecutionStatus
     */
    status: KvintaExecutionStatusType;
    /**
     * 
     * @type {KvintaTimestamp}
     * @memberof KvintaExecutionStatus
     */
    timestamp: KvintaTimestamp;
}

export function KvintaExecutionStatusFromJSON(json: any): KvintaExecutionStatus {
    return KvintaExecutionStatusFromJSONTyped(json, false);
}

export function KvintaExecutionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaExecutionStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'resource': !exists(json, 'resource') ? undefined : KvintaResourceFromJSON(json['resource']),
        'retryExecutionId': !exists(json, 'retryExecutionId') ? undefined : json['retryExecutionId'],
        'status': KvintaExecutionStatusTypeFromJSON(json['status']),
        'timestamp': KvintaTimestampFromJSON(json['timestamp']),
    };
}

export function KvintaExecutionStatusToJSON(value?: KvintaExecutionStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'message': value.message,
        'resource': KvintaResourceToJSON(value.resource),
        'retryExecutionId': value.retryExecutionId,
        'status': KvintaExecutionStatusTypeToJSON(value.status),
        'timestamp': KvintaTimestampToJSON(value.timestamp),
    };
}


