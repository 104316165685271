/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaListTextDocumentsRequest,
    KvintaListTextDocumentsRequestFromJSON,
    KvintaListTextDocumentsRequestFromJSONTyped,
    KvintaListTextDocumentsRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestListTextDocumentsRequest
 */
export interface KvintaOperationRequestListTextDocumentsRequest {
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestListTextDocumentsRequest
     */
    context?: KvintaExecutionContext;
    /**
     * 
     * @type {KvintaListTextDocumentsRequest}
     * @memberof KvintaOperationRequestListTextDocumentsRequest
     */
    input?: KvintaListTextDocumentsRequest;
}

export function KvintaOperationRequestListTextDocumentsRequestFromJSON(json: any): KvintaOperationRequestListTextDocumentsRequest {
    return KvintaOperationRequestListTextDocumentsRequestFromJSONTyped(json, false);
}

export function KvintaOperationRequestListTextDocumentsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestListTextDocumentsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
        'input': !exists(json, 'input') ? undefined : KvintaListTextDocumentsRequestFromJSON(json['input']),
    };
}

export function KvintaOperationRequestListTextDocumentsRequestToJSON(value?: KvintaOperationRequestListTextDocumentsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': KvintaExecutionContextToJSON(value.context),
        'input': KvintaListTextDocumentsRequestToJSON(value.input),
    };
}


