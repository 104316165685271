/* eslint-disable react/display-name */
import { Home, MainPath, NotFoundPath } from 'kvinta/modules/main';
import {
  EpcisMessagePath,
  EpcisMessagesPath,
  EpcMessageHierarchyPath,
  EpcMessageMapPath,
  EpcMessagePath,
  EpcMessagesPath,
  ViewEpcHierarchy,
  ViewEpcisMessageSummary,
  ViewEpcSummary,
  ViewListEpcisMessages,
  ViewListEpcMessages,
} from 'kvinta/modules/epcis';
import { CompaniesPath, CompaniesView, CompanyPath, CompanySummaryView } from 'kvinta/modules/master-data/companies';
import {
  LocationMapPath,
  LocationPath,
  LocationReportingPath,
  LocationsPath,
  ViewLocationMap,
  ViewLocations,
  ViewLocationSummary,
} from 'kvinta/modules/master-data/locations';
import {
  ProductPath,
  ProductsPath,
  ProductSummaryView,
  ProductsView,
  ProductTradeItemPath,
  ProductTradeItemsPath,
  ProductTradeItemSummaryView,
  ProductTradeItemsView,
} from 'kvinta/modules/master-data/products';
import {
  TradeItemPath,
  TradeItemsPath,
  TradeItemSummaryView,
  TradeItemsView,
} from 'kvinta/modules/master-data/trade-items';
import React from 'react';
import {
  SerialNumberOrderDocumentStatusesPath,
  SerialNumberOrderDocumentStatusesView,
  SerialNumberOrderItemPath,
  SerialNumberOrderItemsPath,
  SerialNumberOrderItemSummaryView,
  SerialNumberOrderItemsView,
  SerialNumberOrderPath,
  SerialNumberOrderSerialNumberPath,
  SerialNumberOrderSerialNumbersPath,
  SerialNumberOrderSerialNumberSummaryView,
  SerialNumberOrderSerialNumbersView,
  SerialNumberOrdersPath,
  SerialNumberOrderSummaryView,
  SerialNumberOrdersView,
} from 'kvinta/modules/documents/serial-number-orders';
import {
  ATKCirculationReportsView,
  ATKJsonView,
  ATKListPath,
  ATKListView,
  ATKStatusesView,
  ATKSummaryView,
} from 'kvinta/modules/documents/atk';
import { ViewEpcMap } from 'kvinta/modules/epcis/epcs/ViewEpcMap';
import {
  EpcisEventJsonPath,
  EpcisEventPath,
  EpcisEventsPath,
  ViewEpcisEventJson,
  ViewEpcisEventSummary,
  ViewListEpcisEvents,
} from 'kvinta/modules/epcis/events';
import {
  CirculationReportJsonPath,
  CirculationReportPath,
  CirculationReportsListPath,
  CirculationReportStatusesPath,
  ViewCirculationReportJson,
  ViewCirculationReportStatuses,
  ViewCirculationReportSummmary,
  ViewListCirculationReports,
} from 'kvinta/modules/documents/circulation-reports';
import {
  ATKCirculationReportsPath,
  ATKJsonPath,
  ATKSerialNumbersPath,
  ATKStatusesPath,
  ATKSummaryPath,
  DeviceAssignmentsPath,
  DeviceAssignmentSummaryPath,
  DevicesPath,
  DeviceSummaryPath,
  ExecutionStatusesPath,
  ExecutionStatusHierarchyPath,
  ExecutionStatusPath,
  SequenceStatusesPath,
  SequenceStatusPath,
  SequenceSummaryDependsPath,
  SequenceSummaryPath,
  SerialNumbersInfoPath,
  ShippingListPath,
  ShippingPath,
  ShippingProductListPath,
  ShippingResultsPath,
  VerificationPath,
  VerificationProductsPath,
  VerificationResultsPath,
} from 'kvinta/modules/paths';
import {
  VerificationListPath,
  ViewShippingList,
  ViewShippingListSummary,
  ViewShippingResults,
  ViewVerificationList,
  ViewVerificationProducts,
  ViewVerificationResults,
  ViewVerificationSummary,
} from 'kvinta/modules/shipping';
import { ATKSerialNumbersView } from 'kvinta/modules/documents/atk/views/ATKSerialNumbersView';
import { ViewLocationReporting } from './kvinta/modules/master-data/locations/ViewLocationReporting';
import {
  NotificationGroupListPath,
  NotificationRecipientsPath,
  NotificationTypesPath,
} from './kvinta/modules/notification-settings/paths';
import { NotificationGroupListView } from './kvinta/modules/notification-settings/NotificationGroupList';
import { NotificationGroupRecipientsList } from './kvinta/modules/notification-settings/NotificationGroupRecipientsList';
import { NotificationGroupNotificationTypesList } from './kvinta/modules/notification-settings/NotificationGroupNotificationTypesList';
import { TechDocDownloadPath, ViewDownloadTechDoc } from 'kvinta/modules/techdoc';
import {
  ViewListProcesses,
  ViewProcessStatusJson,
  ViewProcessHierarchy,
} from 'kvinta/modules/processes/execution-statuses';
import { ListSequenceStatusesView } from 'kvinta/modules/processes/sequence-summary/ListSequenceStatusesView';
import { SequenceSummaryView } from 'kvinta/modules/processes/sequence-summary/SequenceSummaryView';
import { SequenceStatusSummaryView } from 'kvinta/modules/processes/sequence-summary/SequenceStatusSummaryView';
import { SequenceStatusSummaryDependsView } from 'kvinta/modules/processes/sequence-summary/SequenceStatusSummaryDependsView';
import { ViewShippingProductList } from './kvinta/modules/shipping/shipping-list/ViewShippingProductList';
import { ListSerialNumbersInfoView } from 'kvinta/modules/processes/serial-number-info/ListSerialNumbersInfoView';
import { ListDevicesView } from 'kvinta/modules/devices/devices/ListDevicesView';
import { AssignmentSummaryView } from 'kvinta/modules/devices/devices/AssignmentSummaryView';
import { DeviceAssignmentsView } from 'kvinta/modules/devices/devices/DeviceAssignmentsView';
import { DeviceSummaryView } from 'kvinta/modules/devices/devices/DeviceSummaryView';

const NotFound = React.lazy(() => import('./kvinta/modules/main/views/NotFound'));

export const routes = [
  {
    path: EpcisMessagesPath,
    comp: (props) => <ViewListEpcisMessages {...props} />,
  },
  {
    path: EpcisMessagePath,
    comp: (props) => <ViewEpcisMessageSummary {...props} />,
  },
  {
    path: EpcMessagesPath,
    comp: (props) => <ViewListEpcMessages {...props} />,
  },
  {
    path: EpcMessagePath,
    comp: (props) => <ViewEpcSummary {...props} />,
  },
  {
    path: EpcMessageHierarchyPath,
    comp: (props) => <ViewEpcHierarchy {...props} />,
  },
  {
    path: EpcMessageMapPath,
    comp: (props) => <ViewEpcMap {...props} />,
  },
  {
    path: EpcisEventsPath,
    comp: (props) => <ViewListEpcisEvents {...props} />,
  },
  {
    path: EpcisEventPath,
    comp: (props) => <ViewEpcisEventSummary {...props} />,
  },
  {
    path: EpcisEventJsonPath,
    comp: (props) => <ViewEpcisEventJson {...props} />,
  },
  {
    path: ExecutionStatusesPath,
    comp: (props) => <ViewListProcesses {...props} />,
  },
  {
    path: ExecutionStatusPath,
    comp: (props) => <ViewProcessStatusJson {...props} />,
  },
  {
    path: ExecutionStatusHierarchyPath,
    comp: (props) => <ViewProcessHierarchy {...props} />,
  },
  {
    path: SequenceStatusesPath,
    comp: (props) => <ListSequenceStatusesView {...props} />,
  },
  {
    path: SequenceStatusPath,
    comp: (props) => <SequenceStatusSummaryView {...props} />,
  },
  {
    path: SequenceSummaryPath,
    comp: (props) => <SequenceSummaryView {...props} />,
  },
  {
    path: SequenceSummaryDependsPath,
    comp: (props) => <SequenceStatusSummaryDependsView {...props} />,
  },
  {
    path: SequenceSummaryDependsPath,
    comp: (props) => <SequenceStatusSummaryDependsView {...props} />,
  },
  {
    path: SerialNumbersInfoPath,
    comp: (props) => <ListSerialNumbersInfoView {...props} />,
  },
  {
    path: CompaniesPath,
    comp: (props) => <CompaniesView {...props} />,
  },
  {
    path: CompanyPath,
    comp: (props) => <CompanySummaryView {...props} />,
  },
  {
    path: LocationsPath,
    comp: (props) => <ViewLocations {...props} />,
  },
  {
    path: LocationPath,
    comp: (props) => <ViewLocationSummary {...props} />,
  },
  {
    path: LocationReportingPath,
    comp: (props) => <ViewLocationReporting {...props} />,
  },
  {
    path: LocationMapPath,
    comp: (props) => <ViewLocationMap {...props} />,
  },
  {
    path: ProductsPath,
    comp: (props) => <ProductsView {...props} />,
  },
  {
    path: ProductPath,
    comp: (props) => <ProductSummaryView {...props} />,
  },
  {
    path: ProductTradeItemsPath,
    comp: (props) => <ProductTradeItemsView {...props} />,
  },
  {
    path: ProductTradeItemPath,
    comp: (props) => <ProductTradeItemSummaryView {...props} />,
  },
  {
    path: TradeItemsPath,
    comp: (props) => <TradeItemsView {...props} />,
  },
  {
    path: TradeItemPath,
    comp: (props) => <TradeItemSummaryView {...props} />,
  },
  {
    path: SerialNumberOrdersPath,
    comp: (props) => <SerialNumberOrdersView {...props} />,
  },
  {
    path: SerialNumberOrderPath,
    comp: (props) => <SerialNumberOrderSummaryView {...props} />,
  },
  {
    path: SerialNumberOrderItemsPath,
    comp: (props) => <SerialNumberOrderItemsView {...props} />,
  },
  {
    path: SerialNumberOrderItemPath,
    comp: (props) => <SerialNumberOrderItemSummaryView {...props} />,
  },
  {
    path: SerialNumberOrderSerialNumbersPath,
    comp: (props) => <SerialNumberOrderSerialNumbersView {...props} />,
  },
  {
    path: SerialNumberOrderSerialNumberPath,
    comp: (props) => <SerialNumberOrderSerialNumberSummaryView {...props} />,
  },
  {
    path: SerialNumberOrderDocumentStatusesPath,
    comp: (props) => <SerialNumberOrderDocumentStatusesView {...props} />,
  },
  {
    path: ATKListPath,
    comp: (props) => <ATKListView {...props} />,
  },
  {
    path: ATKSummaryPath,
    comp: (props) => <ATKSummaryView {...props} />,
  },
  {
    path: ATKSerialNumbersPath,
    comp: (props) => <ATKSerialNumbersView {...props} />,
  },
  {
    path: ATKJsonPath,
    comp: (props) => <ATKJsonView {...props} />,
  },
  {
    path: ATKStatusesPath,
    comp: (props) => <ATKStatusesView {...props} />,
  },
  {
    path: ATKCirculationReportsPath,
    comp: (props) => <ATKCirculationReportsView {...props} />,
  },
  {
    path: CirculationReportsListPath,
    comp: (props) => <ViewListCirculationReports {...props} />,
  },
  {
    path: CirculationReportPath,
    comp: (props) => <ViewCirculationReportSummmary {...props} />,
  },
  {
    path: CirculationReportJsonPath,
    comp: (props) => <ViewCirculationReportJson {...props} />,
  },
  {
    path: CirculationReportStatusesPath,
    comp: (props) => <ViewCirculationReportStatuses {...props} />,
  },
  // {
  //   path: SerialNumberOrderPath,
  //   comp: (props) => <SerialNumberOrderSummaryView {...props} />,
  // },
  {
    path: ShippingListPath,
    comp: (props) => <ViewShippingList {...props} />,
  },
  {
    path: ShippingPath,
    comp: (props) => <ViewShippingListSummary {...props} />,
  },
  {
    path: ShippingProductListPath,
    comp: (props) => <ViewShippingProductList {...props} />,
  },
  {
    path: ShippingResultsPath,
    comp: (props) => <ViewShippingResults {...props} />,
  },
  {
    path: VerificationListPath,
    comp: (props) => <ViewVerificationList {...props} />,
  },
  {
    path: VerificationPath,
    comp: (props) => <ViewVerificationSummary {...props} />,
  },
  {
    path: VerificationProductsPath,
    comp: (props) => <ViewVerificationProducts {...props} />,
  },
  {
    path: VerificationResultsPath,
    comp: (props) => <ViewVerificationResults {...props} />,
  },

  {
    path: MainPath,
    comp: (props) => <Home {...props} />,
  },
  {
    path: NotificationGroupListPath,
    comp: (props) => <NotificationGroupListView {...props} />,
  },
  {
    path: NotificationRecipientsPath,
    comp: (props) => <NotificationGroupRecipientsList {...props} />,
  },
  {
    path: NotificationTypesPath,
    comp: (props) => <NotificationGroupNotificationTypesList {...props} />,
  },
  {
    path: TechDocDownloadPath,
    comp: (props) => <ViewDownloadTechDoc {...props} />,
  },
  {
    path: DevicesPath,
    comp: (props) => <ListDevicesView {...props} />,
  },
  {
    path: DeviceSummaryPath,
    comp: (props) => <DeviceSummaryView {...props} />,
  },
  {
    path: DeviceAssignmentsPath,
    comp: (props) => <DeviceAssignmentsView {...props} />,
  },
  {
    path: DeviceAssignmentSummaryPath,
    comp: (props) => <AssignmentSummaryView {...props} />,
  },
  {
    path: NotFoundPath,
    comp: (props) => <Home {...props} />,
  },
];
