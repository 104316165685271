/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaGismtSerialNumberEmissionStatusType {
    Local = 'LOCAL',
    Foreign = 'FOREIGN',
    Remains = 'REMAINS',
    Crossborder = 'CROSSBORDER',
    Remark = 'REMARK',
    Comission = 'COMISSION'
}

export function KvintaGismtSerialNumberEmissionStatusTypeFromJSON(json: any): KvintaGismtSerialNumberEmissionStatusType {
    return KvintaGismtSerialNumberEmissionStatusTypeFromJSONTyped(json, false);
}

export function KvintaGismtSerialNumberEmissionStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGismtSerialNumberEmissionStatusType {
    return json as KvintaGismtSerialNumberEmissionStatusType;
}

export function KvintaGismtSerialNumberEmissionStatusTypeToJSON(value?: KvintaGismtSerialNumberEmissionStatusType | null): any {
    return value as any;
}

