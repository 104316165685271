/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Query Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaMediaType,
    KvintaMediaTypeFromJSON,
    KvintaMediaTypeFromJSONTyped,
    KvintaMediaTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaStreamedFile
 */
export interface KvintaStreamedFile {
    /**
     * 
     * @type {number}
     * @memberof KvintaStreamedFile
     */
    lastModified?: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaStreamedFile
     */
    length?: number;
    /**
     * 
     * @type {KvintaMediaType}
     * @memberof KvintaStreamedFile
     */
    mediaType?: KvintaMediaType;
    /**
     * 
     * @type {object}
     * @memberof KvintaStreamedFile
     */
    inputStream?: object;
}

export function KvintaStreamedFileFromJSON(json: any): KvintaStreamedFile {
    return KvintaStreamedFileFromJSONTyped(json, false);
}

export function KvintaStreamedFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaStreamedFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModified': !exists(json, 'lastModified') ? undefined : json['lastModified'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'mediaType': !exists(json, 'mediaType') ? undefined : KvintaMediaTypeFromJSON(json['mediaType']),
        'inputStream': !exists(json, 'inputStream') ? undefined : json['inputStream'],
    };
}

export function KvintaStreamedFileToJSON(value?: KvintaStreamedFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModified': value.lastModified,
        'length': value.length,
        'mediaType': KvintaMediaTypeToJSON(value.mediaType),
        'inputStream': value.inputStream,
    };
}


