/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaOmsMarkingCodeType,
    KvintaOmsMarkingCodeTypeFromJSON,
    KvintaOmsMarkingCodeTypeFromJSONTyped,
    KvintaOmsMarkingCodeTypeToJSON,
    KvintaOmsUsageType,
    KvintaOmsUsageTypeFromJSON,
    KvintaOmsUsageTypeFromJSONTyped,
    KvintaOmsUsageTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOmsUtilisationReportRequest
 */
export interface KvintaOmsUtilisationReportRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaOmsUtilisationReportRequest
     */
    sntins?: Array<string> | null;
    /**
     * 
     * @type {KvintaOmsUsageType}
     * @memberof KvintaOmsUtilisationReportRequest
     */
    usageType?: KvintaOmsUsageType;
    /**
     * 
     * @type {KvintaOmsMarkingCodeType}
     * @memberof KvintaOmsUtilisationReportRequest
     */
    cisType?: KvintaOmsMarkingCodeType;
    /**
     * 
     * @type {string}
     * @memberof KvintaOmsUtilisationReportRequest
     */
    expDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaOmsUtilisationReportRequest
     */
    expDate72?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaOmsUtilisationReportRequest
     */
    capacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaOmsUtilisationReportRequest
     */
    usedInProduction?: number | null;
}

export function KvintaOmsUtilisationReportRequestFromJSON(json: any): KvintaOmsUtilisationReportRequest {
    return KvintaOmsUtilisationReportRequestFromJSONTyped(json, false);
}

export function KvintaOmsUtilisationReportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOmsUtilisationReportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sntins': !exists(json, 'sntins') ? undefined : json['sntins'],
        'usageType': !exists(json, 'usageType') ? undefined : KvintaOmsUsageTypeFromJSON(json['usageType']),
        'cisType': !exists(json, 'cisType') ? undefined : KvintaOmsMarkingCodeTypeFromJSON(json['cisType']),
        'expDate': !exists(json, 'expDate') ? undefined : json['expDate'],
        'expDate72': !exists(json, 'expDate72') ? undefined : json['expDate72'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'usedInProduction': !exists(json, 'usedInProduction') ? undefined : json['usedInProduction'],
    };
}

export function KvintaOmsUtilisationReportRequestToJSON(value?: KvintaOmsUtilisationReportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sntins': value.sntins,
        'usageType': KvintaOmsUsageTypeToJSON(value.usageType),
        'cisType': KvintaOmsMarkingCodeTypeToJSON(value.cisType),
        'expDate': value.expDate,
        'expDate72': value.expDate72,
        'capacity': value.capacity,
        'usedInProduction': value.usedInProduction,
    };
}


