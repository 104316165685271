/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaInelMessageBizStep {
    Commissioning = 'COMMISSIONING',
    Shipping = 'SHIPPING'
}

export function KvintaInelMessageBizStepFromJSON(json: any): KvintaInelMessageBizStep {
    return KvintaInelMessageBizStepFromJSONTyped(json, false);
}

export function KvintaInelMessageBizStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaInelMessageBizStep {
    return json as KvintaInelMessageBizStep;
}

export function KvintaInelMessageBizStepToJSON(value?: KvintaInelMessageBizStep | null): any {
    return value as any;
}

