/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { KDialogTitle } from 'kvinta/components/KDialog';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  errorRequired,
  requiredFieldsSerialNumberOrderItem,
  SerialNumberItemType,
  SerialNumberItemTypeWater,
  SerialNumberOrdersStore,
  STORE_ID,
} from '../SerialNumberOrdersStore';
import { productGroupIsBio } from '../../../../../config';

interface CreateSerialNumberOrderItemDialogProps {
  serialNumberOrdersStore?: SerialNumberOrdersStore;
  intl: any;
}

const serialNumberItemTypeList = Object.values(productGroupIsBio ? SerialNumberItemTypeWater : SerialNumberItemType);

class CreateSerialNumberOrderItemDialogImpl extends Component<CreateSerialNumberOrderItemDialogProps> {
  render() {
    const isOpen = this.props.serialNumberOrdersStore.serialNumberOrderItemFormOpen;
    const onChangeValue = this.props.serialNumberOrdersStore.onChangeCreateSerialNumberOrderItemField;
    const formData = this.props.serialNumberOrdersStore.serialNumberOrderItemFormData;
    const gtins = this.props.serialNumberOrdersStore.gtins;
    const intl = this.props.intl;
    const showError = formData.showError;

    if (!isOpen) {
      return null;
    }
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={isOpen}
        onClose={() => this.props.serialNumberOrdersStore.closeCreateSerialNumberOrderForm()}
      >
        <KDialogTitle
          id="customized-dialog-title"
          onClose={() => this.props.serialNumberOrdersStore.closeCreateSerialNumberOrderForm()}
        >
          {intl.formatMessage({ id: 'serial-number-order-item-form.field.create' })}
        </KDialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  {/*Background color used to fix an overlap bug between title and border */}
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="gtin">
                    {intl.formatMessage({ id: 'serial-number-order-item-form.field.gtin' })}
                  </InputLabel>
                  <Select
                    id="gtin"
                    value={formData.gtin}
                    required
                    error={showError && errorRequired('gtin', requiredFieldsSerialNumberOrderItem, formData.gtin)}
                    onChange={(e) => {
                      onChangeValue('gtin', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'serial-number-order-item-form.field.gtin' })}
                  >
                    <MenuItem key={'empty'} value={''}>
                      <FormattedMessage id={'serial-number-order-item-form.field.gtin'} />
                    </MenuItem>
                    {gtins.map((itm) => (
                      <MenuItem key={itm.value} value={itm.value}>
                        {itm.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="itemType">
                    {intl.formatMessage({ id: 'serial-number-order-item-form.field.itemtype' })}
                  </InputLabel>
                  <Select
                    id="itemType"
                    value={formData.itemType}
                    error={
                      showError && errorRequired('itemType', requiredFieldsSerialNumberOrderItem, formData.itemType)
                    }
                    onChange={(e) => {
                      onChangeValue('itemType', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'serial-number-order-item-form.field.itemtype' })}
                  >
                    {serialNumberItemTypeList.map((itemType) => (
                      <MenuItem key={itemType} value={itemType}>
                        {itemType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="quantity"
                    required
                    helperText={
                      showError && errorRequired('quantity', requiredFieldsSerialNumberOrderItem, formData.quantity)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={
                      showError && errorRequired('quantity', requiredFieldsSerialNumberOrderItem, formData.quantity)
                    }
                    value={formData.quantity}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('quantity', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'serial-number-order-item-form.field.quantity' })}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.serialNumberOrdersStore.closeCreateSerialNumberOrderItemForm()}
            color="primary"
            variant="outlined"
          >
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <Button
            onClick={() => this.props.serialNumberOrdersStore.submitCreateSerialNumberOrderItemForm()}
            color="primary"
            variant="contained"
            // disabled={!formData.isValid}
            autoFocus
          >
            {intl.formatMessage({ id: 'button.submit' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const CreateSerialNumberOrderItemDialog = injectIntl(
  inject(STORE_ID)(observer(CreateSerialNumberOrderItemDialogImpl)),
);
