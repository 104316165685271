/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaInlineObject,
    KvintaInlineObjectFromJSON,
    KvintaInlineObjectToJSON,
    KvintaInlineObject1,
    KvintaInlineObject1FromJSON,
    KvintaInlineObject1ToJSON,
    KvintaOperationRequestCreateEmailRecipientRequest,
    KvintaOperationRequestCreateEmailRecipientRequestFromJSON,
    KvintaOperationRequestCreateEmailRecipientRequestToJSON,
    KvintaOperationRequestCreateEmailTemplateRequest,
    KvintaOperationRequestCreateEmailTemplateRequestFromJSON,
    KvintaOperationRequestCreateEmailTemplateRequestToJSON,
    KvintaOperationRequestCreateNotificationGroupRecipientRequest,
    KvintaOperationRequestCreateNotificationGroupRecipientRequestFromJSON,
    KvintaOperationRequestCreateNotificationGroupRecipientRequestToJSON,
    KvintaOperationRequestCreateNotificationGroupRequest,
    KvintaOperationRequestCreateNotificationGroupRequestFromJSON,
    KvintaOperationRequestCreateNotificationGroupRequestToJSON,
    KvintaOperationRequestCreateNotificationGroupTypeRequest,
    KvintaOperationRequestCreateNotificationGroupTypeRequestFromJSON,
    KvintaOperationRequestCreateNotificationGroupTypeRequestToJSON,
    KvintaOperationRequestEmailRecipient,
    KvintaOperationRequestEmailRecipientFromJSON,
    KvintaOperationRequestEmailRecipientToJSON,
    KvintaOperationRequestEmailTemplate,
    KvintaOperationRequestEmailTemplateFromJSON,
    KvintaOperationRequestEmailTemplateToJSON,
    KvintaOperationRequestEpcisEventRecord,
    KvintaOperationRequestEpcisEventRecordFromJSON,
    KvintaOperationRequestEpcisEventRecordToJSON,
    KvintaOperationRequestListEmailRecipientRequest,
    KvintaOperationRequestListEmailRecipientRequestFromJSON,
    KvintaOperationRequestListEmailRecipientRequestToJSON,
    KvintaOperationRequestListEmailTemplateRequest,
    KvintaOperationRequestListEmailTemplateRequestFromJSON,
    KvintaOperationRequestListEmailTemplateRequestToJSON,
    KvintaOperationRequestListNotificationGroupRecipientRequest,
    KvintaOperationRequestListNotificationGroupRecipientRequestFromJSON,
    KvintaOperationRequestListNotificationGroupRecipientRequestToJSON,
    KvintaOperationRequestListNotificationGroupRequest,
    KvintaOperationRequestListNotificationGroupRequestFromJSON,
    KvintaOperationRequestListNotificationGroupRequestToJSON,
    KvintaOperationRequestListNotificationGroupTypeRequest,
    KvintaOperationRequestListNotificationGroupTypeRequestFromJSON,
    KvintaOperationRequestListNotificationGroupTypeRequestToJSON,
    KvintaOperationRequestNotificationData,
    KvintaOperationRequestNotificationDataFromJSON,
    KvintaOperationRequestNotificationDataToJSON,
    KvintaOperationRequestNotificationGroup,
    KvintaOperationRequestNotificationGroupFromJSON,
    KvintaOperationRequestNotificationGroupToJSON,
    KvintaOperationRequestNotificationGroupBatchCreateRequest,
    KvintaOperationRequestNotificationGroupBatchCreateRequestFromJSON,
    KvintaOperationRequestNotificationGroupBatchCreateRequestToJSON,
    KvintaOperationRequestNotificationGroupBatchUpdateRequest,
    KvintaOperationRequestNotificationGroupBatchUpdateRequestFromJSON,
    KvintaOperationRequestNotificationGroupBatchUpdateRequestToJSON,
    KvintaOperationRequestNotificationGroupRecipient,
    KvintaOperationRequestNotificationGroupRecipientFromJSON,
    KvintaOperationRequestNotificationGroupRecipientToJSON,
    KvintaOperationRequestNotificationGroupType,
    KvintaOperationRequestNotificationGroupTypeFromJSON,
    KvintaOperationRequestNotificationGroupTypeToJSON,
    KvintaOperationRequestNotificationType,
    KvintaOperationRequestNotificationTypeFromJSON,
    KvintaOperationRequestNotificationTypeToJSON,
    KvintaOperationRequestResourceStatusRecordFlat,
    KvintaOperationRequestResourceStatusRecordFlatFromJSON,
    KvintaOperationRequestResourceStatusRecordFlatToJSON,
    KvintaOperationRequestSerialNumberOrderStatus,
    KvintaOperationRequestSerialNumberOrderStatusFromJSON,
    KvintaOperationRequestSerialNumberOrderStatusToJSON,
    KvintaOperationRequestString,
    KvintaOperationRequestStringFromJSON,
    KvintaOperationRequestStringToJSON,
    KvintaOperationResponseEmailRecipient,
    KvintaOperationResponseEmailRecipientFromJSON,
    KvintaOperationResponseEmailRecipientToJSON,
    KvintaOperationResponseEmailTemplate,
    KvintaOperationResponseEmailTemplateFromJSON,
    KvintaOperationResponseEmailTemplateToJSON,
    KvintaOperationResponseGetListResultEmailRecipient,
    KvintaOperationResponseGetListResultEmailRecipientFromJSON,
    KvintaOperationResponseGetListResultEmailRecipientToJSON,
    KvintaOperationResponseGetListResultEmailTemplate,
    KvintaOperationResponseGetListResultEmailTemplateFromJSON,
    KvintaOperationResponseGetListResultEmailTemplateToJSON,
    KvintaOperationResponseGetListResultNotificationGroup,
    KvintaOperationResponseGetListResultNotificationGroupFromJSON,
    KvintaOperationResponseGetListResultNotificationGroupToJSON,
    KvintaOperationResponseGetListResultNotificationGroupRecipient,
    KvintaOperationResponseGetListResultNotificationGroupRecipientFromJSON,
    KvintaOperationResponseGetListResultNotificationGroupRecipientToJSON,
    KvintaOperationResponseGetListResultNotificationGroupType,
    KvintaOperationResponseGetListResultNotificationGroupTypeFromJSON,
    KvintaOperationResponseGetListResultNotificationGroupTypeToJSON,
    KvintaOperationResponseInteger,
    KvintaOperationResponseIntegerFromJSON,
    KvintaOperationResponseIntegerToJSON,
    KvintaOperationResponseListNotificationType,
    KvintaOperationResponseListNotificationTypeFromJSON,
    KvintaOperationResponseListNotificationTypeToJSON,
    KvintaOperationResponseNotificationData,
    KvintaOperationResponseNotificationDataFromJSON,
    KvintaOperationResponseNotificationDataToJSON,
    KvintaOperationResponseNotificationGroup,
    KvintaOperationResponseNotificationGroupFromJSON,
    KvintaOperationResponseNotificationGroupToJSON,
    KvintaOperationResponseNotificationGroupRecipient,
    KvintaOperationResponseNotificationGroupRecipientFromJSON,
    KvintaOperationResponseNotificationGroupRecipientToJSON,
    KvintaOperationResponseNotificationGroupType,
    KvintaOperationResponseNotificationGroupTypeFromJSON,
    KvintaOperationResponseNotificationGroupTypeToJSON,
    KvintaOperationResponseString,
    KvintaOperationResponseStringFromJSON,
    KvintaOperationResponseStringToJSON,
} from '../models';

export interface BatchCreateNotificationGroupRequest {
    kvintaOperationRequestNotificationGroupBatchCreateRequest: KvintaOperationRequestNotificationGroupBatchCreateRequest;
}

export interface BatchUpdateNotificationGroupRequest {
    kvintaOperationRequestNotificationGroupBatchUpdateRequest: KvintaOperationRequestNotificationGroupBatchUpdateRequest;
}

export interface CreateEmailRecipientRequest {
    kvintaOperationRequestCreateEmailRecipientRequest: KvintaOperationRequestCreateEmailRecipientRequest;
}

export interface CreateEmailTemplateRequest {
    kvintaOperationRequestCreateEmailTemplateRequest: KvintaOperationRequestCreateEmailTemplateRequest;
}

export interface CreateNotificationGroupRequest {
    kvintaOperationRequestCreateNotificationGroupRequest: KvintaOperationRequestCreateNotificationGroupRequest;
}

export interface CreateNotificationGroupRecipientRequest {
    kvintaOperationRequestCreateNotificationGroupRecipientRequest: KvintaOperationRequestCreateNotificationGroupRecipientRequest;
}

export interface CreateNotificationGroupTypeRequest {
    kvintaOperationRequestCreateNotificationGroupTypeRequest: KvintaOperationRequestCreateNotificationGroupTypeRequest;
}

export interface DeleteEmailRecipientRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface DeleteEmailTemplateRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface DeleteNotificationGroupRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface DeleteNotificationGroupRecipientRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface DeleteNotificationGroupTypeRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface FindEmailTemplateByIdRequest {
    kvintaInlineObject: KvintaInlineObject;
}

export interface FindEmailTemplateByNotificationTypeAndTemplateTypeRequest {
    kvintaInlineObject1: KvintaInlineObject1;
}

export interface GetNotificationGroupRecipientsByGroupIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface GetNotificationGroupTypesByGroupIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ListEmailRecipientsRequest {
    kvintaOperationRequestListEmailRecipientRequest: KvintaOperationRequestListEmailRecipientRequest;
}

export interface ListEmailRecipientsByNotificationTypeRequest {
    kvintaOperationRequestNotificationType: KvintaOperationRequestNotificationType;
}

export interface ListEmailTemplatesRequest {
    kvintaOperationRequestListEmailTemplateRequest: KvintaOperationRequestListEmailTemplateRequest;
}

export interface ListNotificationGroupRecipientsRequest {
    kvintaOperationRequestListNotificationGroupRecipientRequest: KvintaOperationRequestListNotificationGroupRecipientRequest;
}

export interface ListNotificationGroupTypesRequest {
    kvintaOperationRequestListNotificationGroupTypeRequest: KvintaOperationRequestListNotificationGroupTypeRequest;
}

export interface ListNotificationGroupsRequest {
    kvintaOperationRequestListNotificationGroupRequest: KvintaOperationRequestListNotificationGroupRequest;
}

export interface RouteResourceForNotificationsRequest {
    kvintaOperationRequestResourceStatusRecordFlat: KvintaOperationRequestResourceStatusRecordFlat;
}

export interface RouteSerialNumberOrderForReadinessNotificationRequest {
    kvintaOperationRequestSerialNumberOrderStatus: KvintaOperationRequestSerialNumberOrderStatus;
}

export interface SendEmailNotificationRequest {
    kvintaOperationRequestNotificationData: KvintaOperationRequestNotificationData;
}

export interface SendEpcisEventForCommissioningReportingNotificationRequest {
    kvintaOperationRequestEpcisEventRecord: KvintaOperationRequestEpcisEventRecord;
}

export interface SendResourceForATKNotificationRequest {
    kvintaOperationRequestResourceStatusRecordFlat: KvintaOperationRequestResourceStatusRecordFlat;
}

export interface SendResourceForVerificationNotificationRequest {
    kvintaOperationRequestResourceStatusRecordFlat: KvintaOperationRequestResourceStatusRecordFlat;
}

export interface SendSerialNumberOrderForReadinessNotificationRequest {
    kvintaOperationRequestSerialNumberOrderStatus: KvintaOperationRequestSerialNumberOrderStatus;
}

export interface UpdateEmailRecipientRequest {
    kvintaOperationRequestEmailRecipient: KvintaOperationRequestEmailRecipient;
}

export interface UpdateEmailTemplateRequest {
    kvintaOperationRequestEmailTemplate: KvintaOperationRequestEmailTemplate;
}

export interface UpdateNotificationGroupRequest {
    kvintaOperationRequestNotificationGroup: KvintaOperationRequestNotificationGroup;
}

export interface UpdateNotificationGroupRecipientRequest {
    kvintaOperationRequestNotificationGroupRecipient: KvintaOperationRequestNotificationGroupRecipient;
}

export interface UpdateNotificationGroupTypeRequest {
    kvintaOperationRequestNotificationGroupType: KvintaOperationRequestNotificationGroupType;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async batchCreateNotificationGroupRaw(requestParameters: BatchCreateNotificationGroupRequest): Promise<runtime.ApiResponse<KvintaOperationResponseString>> {
        if (requestParameters.kvintaOperationRequestNotificationGroupBatchCreateRequest === null || requestParameters.kvintaOperationRequestNotificationGroupBatchCreateRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestNotificationGroupBatchCreateRequest','Required parameter requestParameters.kvintaOperationRequestNotificationGroupBatchCreateRequest was null or undefined when calling batchCreateNotificationGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/batchCreateNotificationGroup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestNotificationGroupBatchCreateRequestToJSON(requestParameters.kvintaOperationRequestNotificationGroupBatchCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseStringFromJSON(jsonValue));
    }

    /**
     */
    async batchCreateNotificationGroup(requestParameters: BatchCreateNotificationGroupRequest): Promise<KvintaOperationResponseString> {
        const response = await this.batchCreateNotificationGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async batchUpdateNotificationGroupRaw(requestParameters: BatchUpdateNotificationGroupRequest): Promise<runtime.ApiResponse<KvintaOperationResponseString>> {
        if (requestParameters.kvintaOperationRequestNotificationGroupBatchUpdateRequest === null || requestParameters.kvintaOperationRequestNotificationGroupBatchUpdateRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestNotificationGroupBatchUpdateRequest','Required parameter requestParameters.kvintaOperationRequestNotificationGroupBatchUpdateRequest was null or undefined when calling batchUpdateNotificationGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/batchUpdateNotificationGroup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestNotificationGroupBatchUpdateRequestToJSON(requestParameters.kvintaOperationRequestNotificationGroupBatchUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseStringFromJSON(jsonValue));
    }

    /**
     */
    async batchUpdateNotificationGroup(requestParameters: BatchUpdateNotificationGroupRequest): Promise<KvintaOperationResponseString> {
        const response = await this.batchUpdateNotificationGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createEmailRecipientRaw(requestParameters: CreateEmailRecipientRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEmailRecipient>> {
        if (requestParameters.kvintaOperationRequestCreateEmailRecipientRequest === null || requestParameters.kvintaOperationRequestCreateEmailRecipientRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCreateEmailRecipientRequest','Required parameter requestParameters.kvintaOperationRequestCreateEmailRecipientRequest was null or undefined when calling createEmailRecipient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/createEmailRecipient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCreateEmailRecipientRequestToJSON(requestParameters.kvintaOperationRequestCreateEmailRecipientRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEmailRecipientFromJSON(jsonValue));
    }

    /**
     */
    async createEmailRecipient(requestParameters: CreateEmailRecipientRequest): Promise<KvintaOperationResponseEmailRecipient> {
        const response = await this.createEmailRecipientRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createEmailTemplateRaw(requestParameters: CreateEmailTemplateRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEmailTemplate>> {
        if (requestParameters.kvintaOperationRequestCreateEmailTemplateRequest === null || requestParameters.kvintaOperationRequestCreateEmailTemplateRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCreateEmailTemplateRequest','Required parameter requestParameters.kvintaOperationRequestCreateEmailTemplateRequest was null or undefined when calling createEmailTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/createEmailTemplate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCreateEmailTemplateRequestToJSON(requestParameters.kvintaOperationRequestCreateEmailTemplateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEmailTemplateFromJSON(jsonValue));
    }

    /**
     */
    async createEmailTemplate(requestParameters: CreateEmailTemplateRequest): Promise<KvintaOperationResponseEmailTemplate> {
        const response = await this.createEmailTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createNotificationGroupRaw(requestParameters: CreateNotificationGroupRequest): Promise<runtime.ApiResponse<KvintaOperationResponseNotificationGroup>> {
        if (requestParameters.kvintaOperationRequestCreateNotificationGroupRequest === null || requestParameters.kvintaOperationRequestCreateNotificationGroupRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCreateNotificationGroupRequest','Required parameter requestParameters.kvintaOperationRequestCreateNotificationGroupRequest was null or undefined when calling createNotificationGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/createNotificationGroup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCreateNotificationGroupRequestToJSON(requestParameters.kvintaOperationRequestCreateNotificationGroupRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseNotificationGroupFromJSON(jsonValue));
    }

    /**
     */
    async createNotificationGroup(requestParameters: CreateNotificationGroupRequest): Promise<KvintaOperationResponseNotificationGroup> {
        const response = await this.createNotificationGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createNotificationGroupRecipientRaw(requestParameters: CreateNotificationGroupRecipientRequest): Promise<runtime.ApiResponse<KvintaOperationResponseNotificationGroupRecipient>> {
        if (requestParameters.kvintaOperationRequestCreateNotificationGroupRecipientRequest === null || requestParameters.kvintaOperationRequestCreateNotificationGroupRecipientRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCreateNotificationGroupRecipientRequest','Required parameter requestParameters.kvintaOperationRequestCreateNotificationGroupRecipientRequest was null or undefined when calling createNotificationGroupRecipient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/createNotificationGroupRecipient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCreateNotificationGroupRecipientRequestToJSON(requestParameters.kvintaOperationRequestCreateNotificationGroupRecipientRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseNotificationGroupRecipientFromJSON(jsonValue));
    }

    /**
     */
    async createNotificationGroupRecipient(requestParameters: CreateNotificationGroupRecipientRequest): Promise<KvintaOperationResponseNotificationGroupRecipient> {
        const response = await this.createNotificationGroupRecipientRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createNotificationGroupTypeRaw(requestParameters: CreateNotificationGroupTypeRequest): Promise<runtime.ApiResponse<KvintaOperationResponseNotificationGroupType>> {
        if (requestParameters.kvintaOperationRequestCreateNotificationGroupTypeRequest === null || requestParameters.kvintaOperationRequestCreateNotificationGroupTypeRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCreateNotificationGroupTypeRequest','Required parameter requestParameters.kvintaOperationRequestCreateNotificationGroupTypeRequest was null or undefined when calling createNotificationGroupType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/createNotificationGroupType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCreateNotificationGroupTypeRequestToJSON(requestParameters.kvintaOperationRequestCreateNotificationGroupTypeRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseNotificationGroupTypeFromJSON(jsonValue));
    }

    /**
     */
    async createNotificationGroupType(requestParameters: CreateNotificationGroupTypeRequest): Promise<KvintaOperationResponseNotificationGroupType> {
        const response = await this.createNotificationGroupTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteEmailRecipientRaw(requestParameters: DeleteEmailRecipientRequest): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling deleteEmailRecipient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deleteEmailRecipient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     */
    async deleteEmailRecipient(requestParameters: DeleteEmailRecipientRequest): Promise<KvintaOperationResponseInteger> {
        const response = await this.deleteEmailRecipientRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteEmailTemplateRaw(requestParameters: DeleteEmailTemplateRequest): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling deleteEmailTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deleteEmailTemplate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     */
    async deleteEmailTemplate(requestParameters: DeleteEmailTemplateRequest): Promise<KvintaOperationResponseInteger> {
        const response = await this.deleteEmailTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteNotificationGroupRaw(requestParameters: DeleteNotificationGroupRequest): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling deleteNotificationGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deleteNotificationGroup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     */
    async deleteNotificationGroup(requestParameters: DeleteNotificationGroupRequest): Promise<KvintaOperationResponseInteger> {
        const response = await this.deleteNotificationGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteNotificationGroupRecipientRaw(requestParameters: DeleteNotificationGroupRecipientRequest): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling deleteNotificationGroupRecipient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deleteNotificationGroupRecipient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     */
    async deleteNotificationGroupRecipient(requestParameters: DeleteNotificationGroupRecipientRequest): Promise<KvintaOperationResponseInteger> {
        const response = await this.deleteNotificationGroupRecipientRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteNotificationGroupTypeRaw(requestParameters: DeleteNotificationGroupTypeRequest): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling deleteNotificationGroupType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deleteNotificationGroupType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     */
    async deleteNotificationGroupType(requestParameters: DeleteNotificationGroupTypeRequest): Promise<KvintaOperationResponseInteger> {
        const response = await this.deleteNotificationGroupTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async findEmailTemplateByIdRaw(requestParameters: FindEmailTemplateByIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEmailTemplate>> {
        if (requestParameters.kvintaInlineObject === null || requestParameters.kvintaInlineObject === undefined) {
            throw new runtime.RequiredError('kvintaInlineObject','Required parameter requestParameters.kvintaInlineObject was null or undefined when calling findEmailTemplateById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/findEmailTemplateById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaInlineObjectToJSON(requestParameters.kvintaInlineObject),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEmailTemplateFromJSON(jsonValue));
    }

    /**
     */
    async findEmailTemplateById(requestParameters: FindEmailTemplateByIdRequest): Promise<KvintaOperationResponseEmailTemplate> {
        const response = await this.findEmailTemplateByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async findEmailTemplateByNotificationTypeAndTemplateTypeRaw(requestParameters: FindEmailTemplateByNotificationTypeAndTemplateTypeRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEmailTemplate>> {
        if (requestParameters.kvintaInlineObject1 === null || requestParameters.kvintaInlineObject1 === undefined) {
            throw new runtime.RequiredError('kvintaInlineObject1','Required parameter requestParameters.kvintaInlineObject1 was null or undefined when calling findEmailTemplateByNotificationTypeAndTemplateType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/findEmailTemplateByNotificationTypeAndTemplateType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaInlineObject1ToJSON(requestParameters.kvintaInlineObject1),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEmailTemplateFromJSON(jsonValue));
    }

    /**
     */
    async findEmailTemplateByNotificationTypeAndTemplateType(requestParameters: FindEmailTemplateByNotificationTypeAndTemplateTypeRequest): Promise<KvintaOperationResponseEmailTemplate> {
        const response = await this.findEmailTemplateByNotificationTypeAndTemplateTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getNotificationGroupRecipientsByGroupIdRaw(requestParameters: GetNotificationGroupRecipientsByGroupIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultEmailRecipient>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling getNotificationGroupRecipientsByGroupId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/getNotificationGroupRecipientsByGroupId`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultEmailRecipientFromJSON(jsonValue));
    }

    /**
     */
    async getNotificationGroupRecipientsByGroupId(requestParameters: GetNotificationGroupRecipientsByGroupIdRequest): Promise<KvintaOperationResponseGetListResultEmailRecipient> {
        const response = await this.getNotificationGroupRecipientsByGroupIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getNotificationGroupTypesByGroupIdRaw(requestParameters: GetNotificationGroupTypesByGroupIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListNotificationType>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling getNotificationGroupTypesByGroupId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/getNotificationGroupTypesByGroupId`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListNotificationTypeFromJSON(jsonValue));
    }

    /**
     */
    async getNotificationGroupTypesByGroupId(requestParameters: GetNotificationGroupTypesByGroupIdRequest): Promise<KvintaOperationResponseListNotificationType> {
        const response = await this.getNotificationGroupTypesByGroupIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listEmailRecipientsRaw(requestParameters: ListEmailRecipientsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultEmailRecipient>> {
        if (requestParameters.kvintaOperationRequestListEmailRecipientRequest === null || requestParameters.kvintaOperationRequestListEmailRecipientRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListEmailRecipientRequest','Required parameter requestParameters.kvintaOperationRequestListEmailRecipientRequest was null or undefined when calling listEmailRecipients.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listEmailRecipients`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListEmailRecipientRequestToJSON(requestParameters.kvintaOperationRequestListEmailRecipientRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultEmailRecipientFromJSON(jsonValue));
    }

    /**
     */
    async listEmailRecipients(requestParameters: ListEmailRecipientsRequest): Promise<KvintaOperationResponseGetListResultEmailRecipient> {
        const response = await this.listEmailRecipientsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listEmailRecipientsByNotificationTypeRaw(requestParameters: ListEmailRecipientsByNotificationTypeRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultEmailRecipient>> {
        if (requestParameters.kvintaOperationRequestNotificationType === null || requestParameters.kvintaOperationRequestNotificationType === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestNotificationType','Required parameter requestParameters.kvintaOperationRequestNotificationType was null or undefined when calling listEmailRecipientsByNotificationType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listEmailRecipientsByNotificationType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestNotificationTypeToJSON(requestParameters.kvintaOperationRequestNotificationType),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultEmailRecipientFromJSON(jsonValue));
    }

    /**
     */
    async listEmailRecipientsByNotificationType(requestParameters: ListEmailRecipientsByNotificationTypeRequest): Promise<KvintaOperationResponseGetListResultEmailRecipient> {
        const response = await this.listEmailRecipientsByNotificationTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listEmailTemplatesRaw(requestParameters: ListEmailTemplatesRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultEmailTemplate>> {
        if (requestParameters.kvintaOperationRequestListEmailTemplateRequest === null || requestParameters.kvintaOperationRequestListEmailTemplateRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListEmailTemplateRequest','Required parameter requestParameters.kvintaOperationRequestListEmailTemplateRequest was null or undefined when calling listEmailTemplates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listEmailTemplates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListEmailTemplateRequestToJSON(requestParameters.kvintaOperationRequestListEmailTemplateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultEmailTemplateFromJSON(jsonValue));
    }

    /**
     */
    async listEmailTemplates(requestParameters: ListEmailTemplatesRequest): Promise<KvintaOperationResponseGetListResultEmailTemplate> {
        const response = await this.listEmailTemplatesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listNotificationGroupRecipientsRaw(requestParameters: ListNotificationGroupRecipientsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultNotificationGroupRecipient>> {
        if (requestParameters.kvintaOperationRequestListNotificationGroupRecipientRequest === null || requestParameters.kvintaOperationRequestListNotificationGroupRecipientRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListNotificationGroupRecipientRequest','Required parameter requestParameters.kvintaOperationRequestListNotificationGroupRecipientRequest was null or undefined when calling listNotificationGroupRecipients.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listNotificationGroupRecipients`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListNotificationGroupRecipientRequestToJSON(requestParameters.kvintaOperationRequestListNotificationGroupRecipientRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultNotificationGroupRecipientFromJSON(jsonValue));
    }

    /**
     */
    async listNotificationGroupRecipients(requestParameters: ListNotificationGroupRecipientsRequest): Promise<KvintaOperationResponseGetListResultNotificationGroupRecipient> {
        const response = await this.listNotificationGroupRecipientsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listNotificationGroupTypesRaw(requestParameters: ListNotificationGroupTypesRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultNotificationGroupType>> {
        if (requestParameters.kvintaOperationRequestListNotificationGroupTypeRequest === null || requestParameters.kvintaOperationRequestListNotificationGroupTypeRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListNotificationGroupTypeRequest','Required parameter requestParameters.kvintaOperationRequestListNotificationGroupTypeRequest was null or undefined when calling listNotificationGroupTypes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listNotificationGroupTypes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListNotificationGroupTypeRequestToJSON(requestParameters.kvintaOperationRequestListNotificationGroupTypeRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultNotificationGroupTypeFromJSON(jsonValue));
    }

    /**
     */
    async listNotificationGroupTypes(requestParameters: ListNotificationGroupTypesRequest): Promise<KvintaOperationResponseGetListResultNotificationGroupType> {
        const response = await this.listNotificationGroupTypesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listNotificationGroupsRaw(requestParameters: ListNotificationGroupsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultNotificationGroup>> {
        if (requestParameters.kvintaOperationRequestListNotificationGroupRequest === null || requestParameters.kvintaOperationRequestListNotificationGroupRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListNotificationGroupRequest','Required parameter requestParameters.kvintaOperationRequestListNotificationGroupRequest was null or undefined when calling listNotificationGroups.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listNotificationGroups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListNotificationGroupRequestToJSON(requestParameters.kvintaOperationRequestListNotificationGroupRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultNotificationGroupFromJSON(jsonValue));
    }

    /**
     */
    async listNotificationGroups(requestParameters: ListNotificationGroupsRequest): Promise<KvintaOperationResponseGetListResultNotificationGroup> {
        const response = await this.listNotificationGroupsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async routeResourceForNotificationsRaw(requestParameters: RouteResourceForNotificationsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseString>> {
        if (requestParameters.kvintaOperationRequestResourceStatusRecordFlat === null || requestParameters.kvintaOperationRequestResourceStatusRecordFlat === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestResourceStatusRecordFlat','Required parameter requestParameters.kvintaOperationRequestResourceStatusRecordFlat was null or undefined when calling routeResourceForNotifications.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/routeResourceForNotifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestResourceStatusRecordFlatToJSON(requestParameters.kvintaOperationRequestResourceStatusRecordFlat),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseStringFromJSON(jsonValue));
    }

    /**
     */
    async routeResourceForNotifications(requestParameters: RouteResourceForNotificationsRequest): Promise<KvintaOperationResponseString> {
        const response = await this.routeResourceForNotificationsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async routeSerialNumberOrderForReadinessNotificationRaw(requestParameters: RouteSerialNumberOrderForReadinessNotificationRequest): Promise<runtime.ApiResponse<KvintaOperationResponseString>> {
        if (requestParameters.kvintaOperationRequestSerialNumberOrderStatus === null || requestParameters.kvintaOperationRequestSerialNumberOrderStatus === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestSerialNumberOrderStatus','Required parameter requestParameters.kvintaOperationRequestSerialNumberOrderStatus was null or undefined when calling routeSerialNumberOrderForReadinessNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/routeSerialNumberOrderForReadinessNotification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestSerialNumberOrderStatusToJSON(requestParameters.kvintaOperationRequestSerialNumberOrderStatus),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseStringFromJSON(jsonValue));
    }

    /**
     */
    async routeSerialNumberOrderForReadinessNotification(requestParameters: RouteSerialNumberOrderForReadinessNotificationRequest): Promise<KvintaOperationResponseString> {
        const response = await this.routeSerialNumberOrderForReadinessNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async sendEmailNotificationRaw(requestParameters: SendEmailNotificationRequest): Promise<runtime.ApiResponse<KvintaOperationResponseString>> {
        if (requestParameters.kvintaOperationRequestNotificationData === null || requestParameters.kvintaOperationRequestNotificationData === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestNotificationData','Required parameter requestParameters.kvintaOperationRequestNotificationData was null or undefined when calling sendEmailNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sendEmailNotification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestNotificationDataToJSON(requestParameters.kvintaOperationRequestNotificationData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseStringFromJSON(jsonValue));
    }

    /**
     */
    async sendEmailNotification(requestParameters: SendEmailNotificationRequest): Promise<KvintaOperationResponseString> {
        const response = await this.sendEmailNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async sendEpcisEventForCommissioningReportingNotificationRaw(requestParameters: SendEpcisEventForCommissioningReportingNotificationRequest): Promise<runtime.ApiResponse<KvintaOperationResponseNotificationData>> {
        if (requestParameters.kvintaOperationRequestEpcisEventRecord === null || requestParameters.kvintaOperationRequestEpcisEventRecord === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestEpcisEventRecord','Required parameter requestParameters.kvintaOperationRequestEpcisEventRecord was null or undefined when calling sendEpcisEventForCommissioningReportingNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sendEpcisEventForCommissioningReportingNotification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestEpcisEventRecordToJSON(requestParameters.kvintaOperationRequestEpcisEventRecord),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseNotificationDataFromJSON(jsonValue));
    }

    /**
     */
    async sendEpcisEventForCommissioningReportingNotification(requestParameters: SendEpcisEventForCommissioningReportingNotificationRequest): Promise<KvintaOperationResponseNotificationData> {
        const response = await this.sendEpcisEventForCommissioningReportingNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async sendResourceForATKNotificationRaw(requestParameters: SendResourceForATKNotificationRequest): Promise<runtime.ApiResponse<KvintaOperationResponseNotificationData>> {
        if (requestParameters.kvintaOperationRequestResourceStatusRecordFlat === null || requestParameters.kvintaOperationRequestResourceStatusRecordFlat === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestResourceStatusRecordFlat','Required parameter requestParameters.kvintaOperationRequestResourceStatusRecordFlat was null or undefined when calling sendResourceForATKNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sendResourceForATKNotification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestResourceStatusRecordFlatToJSON(requestParameters.kvintaOperationRequestResourceStatusRecordFlat),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseNotificationDataFromJSON(jsonValue));
    }

    /**
     */
    async sendResourceForATKNotification(requestParameters: SendResourceForATKNotificationRequest): Promise<KvintaOperationResponseNotificationData> {
        const response = await this.sendResourceForATKNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async sendResourceForVerificationNotificationRaw(requestParameters: SendResourceForVerificationNotificationRequest): Promise<runtime.ApiResponse<KvintaOperationResponseNotificationData>> {
        if (requestParameters.kvintaOperationRequestResourceStatusRecordFlat === null || requestParameters.kvintaOperationRequestResourceStatusRecordFlat === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestResourceStatusRecordFlat','Required parameter requestParameters.kvintaOperationRequestResourceStatusRecordFlat was null or undefined when calling sendResourceForVerificationNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sendResourceForVerificationNotification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestResourceStatusRecordFlatToJSON(requestParameters.kvintaOperationRequestResourceStatusRecordFlat),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseNotificationDataFromJSON(jsonValue));
    }

    /**
     */
    async sendResourceForVerificationNotification(requestParameters: SendResourceForVerificationNotificationRequest): Promise<KvintaOperationResponseNotificationData> {
        const response = await this.sendResourceForVerificationNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async sendSerialNumberOrderForReadinessNotificationRaw(requestParameters: SendSerialNumberOrderForReadinessNotificationRequest): Promise<runtime.ApiResponse<KvintaOperationResponseNotificationData>> {
        if (requestParameters.kvintaOperationRequestSerialNumberOrderStatus === null || requestParameters.kvintaOperationRequestSerialNumberOrderStatus === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestSerialNumberOrderStatus','Required parameter requestParameters.kvintaOperationRequestSerialNumberOrderStatus was null or undefined when calling sendSerialNumberOrderForReadinessNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sendSerialNumberOrderForReadinessNotification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestSerialNumberOrderStatusToJSON(requestParameters.kvintaOperationRequestSerialNumberOrderStatus),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseNotificationDataFromJSON(jsonValue));
    }

    /**
     */
    async sendSerialNumberOrderForReadinessNotification(requestParameters: SendSerialNumberOrderForReadinessNotificationRequest): Promise<KvintaOperationResponseNotificationData> {
        const response = await this.sendSerialNumberOrderForReadinessNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateEmailRecipientRaw(requestParameters: UpdateEmailRecipientRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEmailRecipient>> {
        if (requestParameters.kvintaOperationRequestEmailRecipient === null || requestParameters.kvintaOperationRequestEmailRecipient === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestEmailRecipient','Required parameter requestParameters.kvintaOperationRequestEmailRecipient was null or undefined when calling updateEmailRecipient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/updateEmailRecipient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestEmailRecipientToJSON(requestParameters.kvintaOperationRequestEmailRecipient),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEmailRecipientFromJSON(jsonValue));
    }

    /**
     */
    async updateEmailRecipient(requestParameters: UpdateEmailRecipientRequest): Promise<KvintaOperationResponseEmailRecipient> {
        const response = await this.updateEmailRecipientRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateEmailTemplateRaw(requestParameters: UpdateEmailTemplateRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEmailTemplate>> {
        if (requestParameters.kvintaOperationRequestEmailTemplate === null || requestParameters.kvintaOperationRequestEmailTemplate === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestEmailTemplate','Required parameter requestParameters.kvintaOperationRequestEmailTemplate was null or undefined when calling updateEmailTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/updateEmailTemplate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestEmailTemplateToJSON(requestParameters.kvintaOperationRequestEmailTemplate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEmailTemplateFromJSON(jsonValue));
    }

    /**
     */
    async updateEmailTemplate(requestParameters: UpdateEmailTemplateRequest): Promise<KvintaOperationResponseEmailTemplate> {
        const response = await this.updateEmailTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateNotificationGroupRaw(requestParameters: UpdateNotificationGroupRequest): Promise<runtime.ApiResponse<KvintaOperationResponseNotificationGroup>> {
        if (requestParameters.kvintaOperationRequestNotificationGroup === null || requestParameters.kvintaOperationRequestNotificationGroup === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestNotificationGroup','Required parameter requestParameters.kvintaOperationRequestNotificationGroup was null or undefined when calling updateNotificationGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/updateNotificationGroup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestNotificationGroupToJSON(requestParameters.kvintaOperationRequestNotificationGroup),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseNotificationGroupFromJSON(jsonValue));
    }

    /**
     */
    async updateNotificationGroup(requestParameters: UpdateNotificationGroupRequest): Promise<KvintaOperationResponseNotificationGroup> {
        const response = await this.updateNotificationGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateNotificationGroupRecipientRaw(requestParameters: UpdateNotificationGroupRecipientRequest): Promise<runtime.ApiResponse<KvintaOperationResponseNotificationGroupRecipient>> {
        if (requestParameters.kvintaOperationRequestNotificationGroupRecipient === null || requestParameters.kvintaOperationRequestNotificationGroupRecipient === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestNotificationGroupRecipient','Required parameter requestParameters.kvintaOperationRequestNotificationGroupRecipient was null or undefined when calling updateNotificationGroupRecipient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/updateNotificationGroupRecipient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestNotificationGroupRecipientToJSON(requestParameters.kvintaOperationRequestNotificationGroupRecipient),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseNotificationGroupRecipientFromJSON(jsonValue));
    }

    /**
     */
    async updateNotificationGroupRecipient(requestParameters: UpdateNotificationGroupRecipientRequest): Promise<KvintaOperationResponseNotificationGroupRecipient> {
        const response = await this.updateNotificationGroupRecipientRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateNotificationGroupTypeRaw(requestParameters: UpdateNotificationGroupTypeRequest): Promise<runtime.ApiResponse<KvintaOperationResponseNotificationGroupType>> {
        if (requestParameters.kvintaOperationRequestNotificationGroupType === null || requestParameters.kvintaOperationRequestNotificationGroupType === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestNotificationGroupType','Required parameter requestParameters.kvintaOperationRequestNotificationGroupType was null or undefined when calling updateNotificationGroupType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/updateNotificationGroupType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestNotificationGroupTypeToJSON(requestParameters.kvintaOperationRequestNotificationGroupType),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseNotificationGroupTypeFromJSON(jsonValue));
    }

    /**
     */
    async updateNotificationGroupType(requestParameters: UpdateNotificationGroupTypeRequest): Promise<KvintaOperationResponseNotificationGroupType> {
        const response = await this.updateNotificationGroupTypeRaw(requestParameters);
        return await response.value();
    }

}
