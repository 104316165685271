import { SvgIconComponent } from '@material-ui/icons';
import { matchPath } from 'react-router-dom';

export interface IMenuItemClickable {
  path?: string;
  label: string;
  Icon: SvgIconComponent | string;
  isClickable: boolean;
  items?: IMenuItem[];
  isExternal?: boolean;
  selectOnPaths?: string[];
}

export type IMenuItem = string | IMenuItemClickable;

export function isSelectedMenu(i: IMenuItemClickable, selectedItem: string): boolean {
  if ((i.path === '/' && selectedItem === i.path) || (i.path !== '/' && matchPath(selectedItem, i.path))) {
    return true;
  }
  if (i.selectOnPaths) {
    for (const p of i.selectOnPaths) {
      if (matchPath(selectedItem, p)) {
        return true;
      }
    }
  }
  return false;
}
