/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaOperationRequestString,
    KvintaOperationRequestStringFromJSON,
    KvintaOperationRequestStringFromJSONTyped,
    KvintaOperationRequestStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaInlineObject
 */
export interface KvintaInlineObject {
    /**
     * 
     * @type {KvintaOperationRequestString}
     * @memberof KvintaInlineObject
     */
    request?: KvintaOperationRequestString;
}

export function KvintaInlineObjectFromJSON(json: any): KvintaInlineObject {
    return KvintaInlineObjectFromJSONTyped(json, false);
}

export function KvintaInlineObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaInlineObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request': !exists(json, 'request') ? undefined : KvintaOperationRequestStringFromJSON(json['request']),
    };
}

export function KvintaInlineObjectToJSON(value?: KvintaInlineObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request': KvintaOperationRequestStringToJSON(value.request),
    };
}


