/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingFromJSONTyped,
    KvintaPagingToJSON,
    KvintaResourceStatusType,
    KvintaResourceStatusTypeFromJSON,
    KvintaResourceStatusTypeFromJSONTyped,
    KvintaResourceStatusTypeToJSON,
    KvintaResourceType,
    KvintaResourceTypeFromJSON,
    KvintaResourceTypeFromJSONTyped,
    KvintaResourceTypeToJSON,
    KvintaSoftwareSystem,
    KvintaSoftwareSystemFromJSON,
    KvintaSoftwareSystemFromJSONTyped,
    KvintaSoftwareSystemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaListResourceStatusRecordsRequest
 */
export interface KvintaListResourceStatusRecordsRequest {
    /**
     * 
     * @type {KvintaResourceStatusType}
     * @memberof KvintaListResourceStatusRecordsRequest
     */
    lastStatus: KvintaResourceStatusType;
    /**
     * 
     * @type {KvintaPaging}
     * @memberof KvintaListResourceStatusRecordsRequest
     */
    paging: KvintaPaging;
    /**
     * 
     * @type {KvintaResourceType}
     * @memberof KvintaListResourceStatusRecordsRequest
     */
    resourceType: KvintaResourceType;
    /**
     * 
     * @type {KvintaSoftwareSystem}
     * @memberof KvintaListResourceStatusRecordsRequest
     */
    system: KvintaSoftwareSystem;
}

export function KvintaListResourceStatusRecordsRequestFromJSON(json: any): KvintaListResourceStatusRecordsRequest {
    return KvintaListResourceStatusRecordsRequestFromJSONTyped(json, false);
}

export function KvintaListResourceStatusRecordsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaListResourceStatusRecordsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastStatus': KvintaResourceStatusTypeFromJSON(json['lastStatus']),
        'paging': KvintaPagingFromJSON(json['paging']),
        'resourceType': KvintaResourceTypeFromJSON(json['resourceType']),
        'system': KvintaSoftwareSystemFromJSON(json['system']),
    };
}

export function KvintaListResourceStatusRecordsRequestToJSON(value?: KvintaListResourceStatusRecordsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastStatus': KvintaResourceStatusTypeToJSON(value.lastStatus),
        'paging': KvintaPagingToJSON(value.paging),
        'resourceType': KvintaResourceTypeToJSON(value.resourceType),
        'system': KvintaSoftwareSystemToJSON(value.system),
    };
}


