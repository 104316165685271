/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaListDeliveryNotesRequest,
    KvintaListDeliveryNotesRequestFromJSON,
    KvintaListDeliveryNotesRequestFromJSONTyped,
    KvintaListDeliveryNotesRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestListDeliveryNotesRequest
 */
export interface KvintaOperationRequestListDeliveryNotesRequest {
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestListDeliveryNotesRequest
     */
    context?: KvintaExecutionContext;
    /**
     * 
     * @type {KvintaListDeliveryNotesRequest}
     * @memberof KvintaOperationRequestListDeliveryNotesRequest
     */
    input?: KvintaListDeliveryNotesRequest;
}

export function KvintaOperationRequestListDeliveryNotesRequestFromJSON(json: any): KvintaOperationRequestListDeliveryNotesRequest {
    return KvintaOperationRequestListDeliveryNotesRequestFromJSONTyped(json, false);
}

export function KvintaOperationRequestListDeliveryNotesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestListDeliveryNotesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
        'input': !exists(json, 'input') ? undefined : KvintaListDeliveryNotesRequestFromJSON(json['input']),
    };
}

export function KvintaOperationRequestListDeliveryNotesRequestToJSON(value?: KvintaOperationRequestListDeliveryNotesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': KvintaExecutionContextToJSON(value.context),
        'input': KvintaListDeliveryNotesRequestToJSON(value.input),
    };
}


