import { Button, Link } from '@material-ui/core';
import MaterialTable from 'material-table';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import {
  KSidebarStore,
  PageContentStore,
  PAGE_CONTENT_STORE_ID,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { EpcisMessagesStore, EPCIS_MESSAGES_STORE_ID } from './EpcisMessagesStore';
import { DialogUploadCSV } from './DialogUploadCSV';
import { FilterBar } from 'kvinta/components';
import { navigateEpcisMessagePath, linkEpcisMessagePath } from 'kvinta/modules/paths';

interface ViewListEpcisMessagesProps {
  epcisMessagesStore?: EpcisMessagesStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
}

class ViewListEpcisMessagesImpl extends Component<ViewListEpcisMessagesProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ViewListEpcisMessagesProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.epcisMessagesStore.fetchData();
  }

  navigateMessageSummary = (history: any, id: string) => {
    navigateEpcisMessagePath(history, id);
  };

  render() {
    const intl = this.props.intl;
    const { listData, isLoading, page, totalCount, pageSize, csvFormOpen, filter } = this.props.epcisMessagesStore;
    const { height } = this.props.pageContentStore;
    if (!height || height === NaN || height === 0) {
      return null;
    }
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    return (
      <Route
        render={({ history }) => (
          <div>
            <div>
              <MaterialTable
                icons={tableIcons}
                tableRef={this.tableRef}
                // title={intl.formatMessage({ id: 'menu.messages' })}
                columns={[
                  {
                    title: intl.formatMessage({ id: 'epcis-document.record-date' }),
                    field: 'recordDate',
                    defaultSort: 'desc',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'epcis-document.createdate' }),
                    field: 'createDate',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  { title: intl.formatMessage({ id: 'epcis-document.location' }), field: 'location' },
                  {
                    title: intl.formatMessage({ id: 'epcis-document.earliesteventdate' }),
                    field: 'earliestEventDate',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'epcis-document.latesteventdate' }),
                    field: 'latestEventDate',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'epcis-document.eventsnumber' }),
                    field: 'eventNumber',
                    width: '5%',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: intl.formatMessage({ id: 'epcis-document.id' }),
                    field: 'id',
                    width: '25%',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                    render: (data) => {
                      return (
                        <Link href={linkEpcisMessagePath(history, data.id)} onClick={preventDefault}>
                          {data.id}
                        </Link>
                      );
                    },
                  },
                ]}
                data={listData}
                page={page}
                totalCount={totalCount}
                onChangePage={(page: number) => {
                  this.props.epcisMessagesStore.fetchPage(page);
                }}
                onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
                  this.props.epcisMessagesStore.changeOrder(orderBy, orderDirection);
                }}
                isLoading={isLoading}
                options={{
                  actionsColumnIndex: -1,
                  paging: true,
                  sorting: true,
                  draggable: false,
                  search: false,
                  pageSize: pageSize,
                  pageSizeOptions: [pageSize],
                  minBodyHeight: bodyHeight,
                  maxBodyHeight: bodyHeight,
                  showTitle: false,
                  emptyRowsWhenPaging: false,
                  thirdSortClick: false,
                }}
                onRowClick={(event, rowData) => {
                  this.navigateMessageSummary(history, rowData.id);
                }}
                components={{
                  Toolbar: (props) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: '20px',
                        marginRight: '20px',
                        marginLeft: '11px',
                      }}
                    >
                      <FilterBar filter={filter} />
                      <div style={{ marginLeft: '5px' }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<CloudUploadIcon color="secondary" />}
                          onClick={() => {
                            this.props.epcisMessagesStore.openUploadCSVForm();
                          }}
                        >
                          {intl.formatMessage({ id: 'button.uploadCSV' })}
                        </Button>
                      </div>
                    </div>
                  ),
                }}
              />
            </div>
            {csvFormOpen && <DialogUploadCSV />}
          </div>
        )}
      />
    );
  }
}
export const ViewListEpcisMessages = injectIntl(
  inject(PAGE_CONTENT_STORE_ID, EPCIS_MESSAGES_STORE_ID, SIDEBAR_STORE_ID)(observer(ViewListEpcisMessagesImpl)),
);
