import { NotificationManager } from '../main';
import { makeObservable, observable, runInAction } from 'mobx';
import { KvintaOperationStatus } from '../../apis/kvinta-document-store';
import {
  DefaultApi as NotificationFunctionsApi,
  KvintaEmailRecipient,
  KvintaNotificationType,
} from '../../apis/kvinta-notification-functions';
import { TAppOptionsConfig } from '../../common';

export class NotificationStore {
  private _config: TAppOptionsConfig;
  private _api: NotificationFunctionsApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  listData: any[];
  currentGroupRecipients: KvintaEmailRecipient[] | undefined;
  currentGroupNotificationTypes: { type: string; isActive: boolean }[] | undefined;

  constructor(config: TAppOptionsConfig, notificationManager: NotificationManager, api: NotificationFunctionsApi) {
    makeObservable(this, {
      isLoading: observable,
      listData: observable,
      currentGroupRecipients: observable,
      currentGroupNotificationTypes: observable,
    });

    this._config = config;
    this._notificationManager = notificationManager;
    this._api = api;
  }

  async listNotificationGroups() {
    this.isLoading = true;
    this._api
      .listNotificationGroups({
        kvintaOperationRequestListNotificationGroupRequest: {
          input: {
            paging: {
              page: 0,
              size: 25,
            },
          },
        },
      })
      .then((result) => {
        runInAction(() => {
          this.isLoading = false;
          if (result.status === KvintaOperationStatus.Error) {
            this.listData = [];
            this._notificationManager.sendError(JSON.stringify(result.error));
          } else {
            this.listData = result.data.list || [];
          }
        });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async addGroup(groupName: string) {
    this._api
      .batchCreateNotificationGroup({
        kvintaOperationRequestNotificationGroupBatchCreateRequest: {
          input: {
            groupName,
          },
        },
      })
      .then((result) => {
        if (result.status === KvintaOperationStatus.Ok) {
          this._notificationManager.sendSuccess(`Successfully added ${groupName} to the list`);
          this.listNotificationGroups();
        } else {
          this._notificationManager.sendError(result.error);
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async deleteGroup(groupId: string, name: string) {
    this._api
      .deleteNotificationGroup({
        kvintaOperationRequestString: {
          input: groupId,
        },
      })
      .then((result) => {
        if (result.status === KvintaOperationStatus.Ok) {
          this._notificationManager.sendInformation(`Successfully deleted ${name}`);
          this.listNotificationGroups();
        } else {
          this._notificationManager.sendError(result.error);
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async getNotificationGroupRecipientsData(id: string) {
    this.isLoading = true;
    this._api
      .getNotificationGroupRecipientsByGroupId({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        runInAction(() => {
          if (result.status === KvintaOperationStatus.Error) {
            this._notificationManager.sendError(JSON.stringify(result.error));
          } else {
            this.currentGroupRecipients = result.data.list || [];
          }
        });
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  cleanupCurrentNotificationGroupRecipientsData() {
    this.currentGroupRecipients = undefined;
  }

  async deleteEmail(id: string, emailAddressToDelete: string) {
    this._api
      .batchUpdateNotificationGroup({
        kvintaOperationRequestNotificationGroupBatchUpdateRequest: {
          input: {
            id,
            notificationRecipients: this.currentGroupRecipients
              .filter((recipient) => recipient.emailAddress !== emailAddressToDelete)
              .map((recipient) => recipient.emailAddress),
          },
        },
      })
      .then((result) => {
        if (result.status === KvintaOperationStatus.Ok) {
          this._notificationManager.sendInformation(`Successfully removed ${emailAddressToDelete} from list`);
          this.getNotificationGroupRecipientsData(id);
        } else {
          this._notificationManager.sendError(result.error);
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async addEmail(id: string, newEmailAddress: string) {
    const emails = this.currentGroupRecipients
      ? [...this.currentGroupRecipients.map((recipient) => recipient.emailAddress), newEmailAddress]
      : [newEmailAddress];

    this._api
      .batchUpdateNotificationGroup({
        kvintaOperationRequestNotificationGroupBatchUpdateRequest: {
          input: {
            id,
            notificationRecipients: emails,
          },
        },
      })
      .then((result) => {
        if (result.status === KvintaOperationStatus.Ok) {
          this._notificationManager.sendSuccess(`Successfully added ${newEmailAddress} to the list`);
          this.getNotificationGroupRecipientsData(id);
        } else {
          this._notificationManager.sendError(result.error);
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async getGroupsNotificationTypesData(id: string) {
    const activeNotificationTypesResult = await this._api
      .getNotificationGroupTypesByGroupId({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        if (result.status === KvintaOperationStatus.Error) {
          this._notificationManager.sendError(result.error);
        } else {
          const activeNotificationTypes = result.data || [];

          this.currentGroupNotificationTypes = Object.keys(KvintaNotificationType).map((notificationType) => ({
            type: notificationType,
            isActive: compareNotificationTypes(activeNotificationTypes, notificationType),
          }));
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async submitNotificationTypes(id: string, newNotificationTypes: { type: string; isActive: boolean }[]) {
    this._api
      .batchUpdateNotificationGroup({
        kvintaOperationRequestNotificationGroupBatchUpdateRequest: {
          input: {
            id: id,
            notificationTypes: newNotificationTypes
              .filter(({ isActive }) => isActive === true)
              .map(({ type }) => toScreamingCase(type)) as KvintaNotificationType[],
          },
        },
      })
      .then((result) => {
        if (result.status === KvintaOperationStatus.Ok) {
          this._notificationManager.sendSuccess('Successfully saved new notification types');
          this.getGroupsNotificationTypesData(id);
        } else {
          this._notificationManager.sendError(result.error);
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  cleanupGroupsNotificationTypesData() {
    this.currentGroupNotificationTypes = null;
  }
}

function compareNotificationTypes(activeNotificationTypes: string[], notificationType: string): boolean {
  //getNotificationGroupTypesByGroupId returns notifications in "SCREAMING_CASE", KvintaNotificationType enum lists them in "CamelCase".

  function flattenString(str) {
    return str.replace(/_/g, '').toLowerCase();
  }

  return activeNotificationTypes
    .map((activeNotificationType) => flattenString(activeNotificationType))
    .includes(flattenString(notificationType));
}

function toScreamingCase(str: string): string {
  const segmentRegex = new RegExp(/[A-Z][a-z]+/g);
  const segments = str.match(segmentRegex);

  return segments ? segments.map((segment) => segment.toUpperCase()).join('_') : str;
}

export const NOTIFICATION_STORE_ID = 'notificationStore';
