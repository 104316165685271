/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import { KDialogTitle } from 'kvinta/components/KDialog';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TradeItemsStore, STORE_ID, errorRequired } from '../TradeItemsStore';

interface CreateTradeItemDialogProps {
  tradeItemsStore?: TradeItemsStore;
  intl: any;
}

class CreateTradeItemDialogImpl extends Component<CreateTradeItemDialogProps> {
  render() {
    const isOpen = this.props.tradeItemsStore.tradeItemFormOpen;
    const onChangeValue = this.props.tradeItemsStore.onChangeCreateTradeItemField;
    const formData = this.props.tradeItemsStore.createTradeItemData;
    const intl = this.props.intl;
    const showError = formData.showError;

    if (!isOpen) {
      return null;
    }
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={isOpen}
        onClose={() => this.props.tradeItemsStore.closeCreateTradeItemForm()}
      >
        <KDialogTitle
          id="customized-dialog-title"
          onClose={() => this.props.tradeItemsStore.closeCreateTradeItemForm()}
        >
          {intl.formatMessage({ id: 'trade-item-create-form.field.create' })}
        </KDialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="gtin"
                    required
                    helperText={
                      showError && errorRequired('gtin', formData.gtin)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('gtin', formData.gtin)}
                    value={formData.gtin}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('gtin', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'trade-item-list.header.gtin' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="baseUomNominator"
                    required
                    helperText={
                      showError && errorRequired('baseUomNominator', formData.baseUomNominator)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('baseUomNominator', formData.baseUomNominator)}
                    value={formData.baseUomNominator}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('baseUomNominator', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'trade-item-list.header.baseuomnominator' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="companyPrefix"
                    required
                    helperText={
                      showError && errorRequired('companyPrefix', formData.companyPrefix)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('companyPrefix', formData.companyPrefix)}
                    value={formData.companyPrefix}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('companyPrefix', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'trade-item-list.header.companyprefix' })}
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="uom"
                    required
                    helperText={
                      showError && errorRequired('uom', formData.uom)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('uom', formData.uom)}
                    value={formData.uom}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('uom', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'trade-item-list.header.uom' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="baseUomDenominator"
                    required
                    helperText={
                      showError && errorRequired('baseUomDenominator', formData.baseUomDenominator)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('baseUomDenominator', formData.baseUomDenominator)}
                    value={formData.baseUomDenominator}
                    variant="outlined"
                    onChange={(e) => {
                      onChangeValue('baseUomDenominator', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'trade-item-list.header.baseuomdenominator' })}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.tradeItemsStore.closeCreateTradeItemForm()}
            color="primary"
            variant="outlined"
          >
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <Button
            onClick={() => this.props.tradeItemsStore.submitCreateTradeItemForm()}
            color="primary"
            variant="contained"
            // disabled={!formData.isValid}
            autoFocus
          >
            {intl.formatMessage({ id: 'button.submit' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const CreateTradeItemDialog = injectIntl(inject(STORE_ID)(observer(CreateTradeItemDialogImpl)));
