/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaPagination
 */
export interface KvintaPagination {
    /**
     * 
     * @type {number}
     * @memberof KvintaPagination
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaPagination
     */
    perPage?: number;
}

export function KvintaPaginationFromJSON(json: any): KvintaPagination {
    return KvintaPaginationFromJSONTyped(json, false);
}

export function KvintaPaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaPagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'perPage': !exists(json, 'perPage') ? undefined : json['perPage'],
    };
}

export function KvintaPaginationToJSON(value?: KvintaPagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'perPage': value.perPage,
    };
}


