/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDateTimeOpenRange,
    KvintaDateTimeOpenRangeFromJSON,
    KvintaDateTimeOpenRangeFromJSONTyped,
    KvintaDateTimeOpenRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaDeliveryNoteFilter
 */
export interface KvintaDeliveryNoteFilter {
    /**
     * 
     * @type {KvintaDateTimeOpenRange}
     * @memberof KvintaDeliveryNoteFilter
     */
    created?: KvintaDateTimeOpenRange;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryNoteFilter
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryNoteFilter
     */
    locationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryNoteFilter
     */
    locationIdLike?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryNoteFilter
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryNoteFilter
     */
    nameLike?: string | null;
}

export function KvintaDeliveryNoteFilterFromJSON(json: any): KvintaDeliveryNoteFilter {
    return KvintaDeliveryNoteFilterFromJSONTyped(json, false);
}

export function KvintaDeliveryNoteFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDeliveryNoteFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': !exists(json, 'created') ? undefined : KvintaDateTimeOpenRangeFromJSON(json['created']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'locationIdLike': !exists(json, 'locationIdLike') ? undefined : json['locationIdLike'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nameLike': !exists(json, 'nameLike') ? undefined : json['nameLike'],
    };
}

export function KvintaDeliveryNoteFilterToJSON(value?: KvintaDeliveryNoteFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': KvintaDateTimeOpenRangeToJSON(value.created),
        'id': value.id,
        'locationId': value.locationId,
        'locationIdLike': value.locationIdLike,
        'name': value.name,
        'nameLike': value.nameLike,
    };
}


