import { TabSpec } from 'kvinta/components';

export const tabs = [
  {
    label: 'common.summary',
    value: '1',
  },
  {
    label: 'execution.hierarchy',
    value: '2',
  },
] as TabSpec[];
