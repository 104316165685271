import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { countries } from 'kvinta/common/countries';
import { CompaniesStore, errorRequired, STORE_ID } from './CompaniesStore';
import { KDialogTitle } from 'kvinta/components/KDialog';

interface DialogCreateCompanyProps {
  companiesStore?: CompaniesStore;
  intl: any;
}

class DialogCreateCompanyImpl extends Component<DialogCreateCompanyProps> {
  render() {
    const isOpen = this.props.companiesStore.companyFormOpen;
    const onChangeValue = this.props.companiesStore.onChangeCreateCompanyField;
    const formData = this.props.companiesStore.createCompanyData;
    const intl = this.props.intl;

    const showError = formData.showError;

    if (!isOpen) {
      return null;
    }
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={isOpen}
        onClose={() => this.props.companiesStore.closeCreateCompanyForm()}
      >
        <KDialogTitle id="customized-dialog-title" onClose={() => this.props.companiesStore.closeCreateCompanyForm()}>
          {intl.formatMessage({ id: 'company-create-form.field.create' })}
        </KDialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="name"
                    required
                    helperText={
                      showError && errorRequired('name', formData.name)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('name', formData.name)}
                    variant="outlined"
                    value={formData.name}
                    onChange={(e) => {
                      onChangeValue('name', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'company-create-form.field.name' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="longitude"
                    variant="outlined"
                    value={formData.longitude}
                    required
                    helperText={
                      showError && errorRequired('name', formData.name)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('longitude', formData.longitude)}
                    onChange={(e) => {
                      onChangeValue('longitude', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'company-create-form.field.longitude' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="address2"
                    variant="outlined"
                    value={formData.address2}
                    onChange={(e) => {
                      onChangeValue('address2', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'company-create-form.field.address2' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="city"
                    variant="outlined"
                    required
                    helperText={
                      showError && errorRequired('name', formData.name)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('city', formData.city)}
                    value={formData.city}
                    onChange={(e) => {
                      onChangeValue('city', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'company-create-form.field.city' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="description"
                    variant="outlined"
                    value={formData.description}
                    onChange={(e) => {
                      onChangeValue('description', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'company-create-form.field.description' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="postalCode"
                    variant="outlined"
                    required
                    helperText={
                      showError && errorRequired('name', formData.name)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('postalCode', formData.postalCode)}
                    value={formData.postalCode}
                    onChange={(e) => {
                      onChangeValue('postalCode', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'company-create-form.field.postalcode' })}
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="latitude"
                    variant="outlined"
                    required
                    helperText={
                      showError && errorRequired('name', formData.name)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('latitude', formData.latitude)}
                    value={formData.latitude}
                    onChange={(e) => {
                      onChangeValue('latitude', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'company-create-form.field.latitude' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="address1"
                    variant="outlined"
                    required
                    helperText={
                      showError && errorRequired('name', formData.name)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('address1', formData.address1)}
                    value={formData.address1}
                    onChange={(e) => {
                      onChangeValue('address1', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'company-create-form.field.address1' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="address3"
                    variant="outlined"
                    value={formData.address3}
                    onChange={(e) => {
                      onChangeValue('address3', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'company-create-form.field.address3' })}
                  />
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ backgroundColor: 'white' }} htmlFor="country">
                    {intl.formatMessage({ id: 'company-create-form.field.country' })}
                  </InputLabel>
                  <Select
                    id="country"
                    required
                    error={showError && errorRequired('country', formData.country)}
                    value={formData.country}
                    onChange={(e) => {
                      onChangeValue('country', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'company-create-form.field.country' })}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.shortCode} value={country.shortCode}>
                        <FormattedMessage id={country.countryName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box m={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="gln13"
                    variant="outlined"
                    required
                    helperText={
                      showError && errorRequired('name', formData.name)
                        ? intl.formatMessage({ id: 'validation.error.required' })
                        : null
                    }
                    error={showError && errorRequired('gln13', formData.gln13)}
                    value={formData.gln13}
                    onChange={(e) => {
                      onChangeValue('gln13', e.target.value);
                    }}
                    label={intl.formatMessage({ id: 'company-create-form.field.gln13' })}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.companiesStore.closeCreateCompanyForm()} color="primary" variant="outlined">
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <Button
            onClick={() => this.props.companiesStore.submitCreateCompanyForm()}
            color="primary"
            variant="contained"
            autoFocus
          >
            {intl.formatMessage({ id: 'button.submit' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const DialogCreateCompany = injectIntl(inject(STORE_ID)(observer(DialogCreateCompanyImpl)));
