/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisEventProduct,
    KvintaEpcisEventProductFromJSON,
    KvintaEpcisEventProductFromJSONTyped,
    KvintaEpcisEventProductToJSON,
    KvintaEpcisEventRecord,
    KvintaEpcisEventRecordFromJSON,
    KvintaEpcisEventRecordFromJSONTyped,
    KvintaEpcisEventRecordToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcisEventWithProducts
 */
export interface KvintaEpcisEventWithProducts {
    /**
     * 
     * @type {KvintaEpcisEventRecord}
     * @memberof KvintaEpcisEventWithProducts
     */
    epcisEventRecord?: KvintaEpcisEventRecord;
    /**
     * 
     * @type {Array<KvintaEpcisEventProduct>}
     * @memberof KvintaEpcisEventWithProducts
     */
    products?: Array<KvintaEpcisEventProduct>;
}

export function KvintaEpcisEventWithProductsFromJSON(json: any): KvintaEpcisEventWithProducts {
    return KvintaEpcisEventWithProductsFromJSONTyped(json, false);
}

export function KvintaEpcisEventWithProductsFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisEventWithProducts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epcisEventRecord': !exists(json, 'epcisEventRecord') ? undefined : KvintaEpcisEventRecordFromJSON(json['epcisEventRecord']),
        'products': !exists(json, 'products') ? undefined : ((json['products'] as Array<any>).map(KvintaEpcisEventProductFromJSON)),
    };
}

export function KvintaEpcisEventWithProductsToJSON(value?: KvintaEpcisEventWithProducts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epcisEventRecord': KvintaEpcisEventRecordToJSON(value.epcisEventRecord),
        'products': value.products === undefined ? undefined : ((value.products as Array<any>).map(KvintaEpcisEventProductToJSON)),
    };
}


