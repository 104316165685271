/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaGismtCisInfo,
    KvintaGismtCisInfoFromJSON,
    KvintaGismtCisInfoFromJSONTyped,
    KvintaGismtCisInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGismtCisesInfoResult
 */
export interface KvintaGismtCisesInfoResult {
    /**
     * 
     * @type {KvintaGismtCisInfo}
     * @memberof KvintaGismtCisesInfoResult
     */
    cisInfo?: KvintaGismtCisInfo;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisesInfoResult
     */
    errorMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGismtCisesInfoResult
     */
    errorCode?: string | null;
}

export function KvintaGismtCisesInfoResultFromJSON(json: any): KvintaGismtCisesInfoResult {
    return KvintaGismtCisesInfoResultFromJSONTyped(json, false);
}

export function KvintaGismtCisesInfoResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGismtCisesInfoResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cisInfo': !exists(json, 'cisInfo') ? undefined : KvintaGismtCisInfoFromJSON(json['cisInfo']),
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'errorCode': !exists(json, 'errorCode') ? undefined : json['errorCode'],
    };
}

export function KvintaGismtCisesInfoResultToJSON(value?: KvintaGismtCisesInfoResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cisInfo': KvintaGismtCisInfoToJSON(value.cisInfo),
        'errorMessage': value.errorMessage,
        'errorCode': value.errorCode,
    };
}


