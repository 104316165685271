export const ProductsPath = '/products';
export const ProductPath = '/product/:id';
export const ProductTradeItemsPath = '/product/:id/trade-items';
export const ProductTradeItemPath = '/product/:id/trade-items/:tradeId';

export function navigateProductsPath(history: any) {
  history.push(ProductsPath);
}

export function navigateProductPath(history: any, id: string) {
  history.push(ProductPath.replaceAll(':id', id));
}

export function navigateProductTradeItemsPath(history: any, id: string) {
  history.push(ProductTradeItemsPath.replaceAll(':id', id));
}

export function navigateProductTradeItemPath(history: any, id: string, tradeItemId: string) {
  history.push(ProductTradeItemPath.replaceAll(':id', id).replaceAll(':tradeId', tradeItemId));
}
