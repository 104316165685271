/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Query Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaOperationType,
    KvintaOperationTypeFromJSON,
    KvintaOperationTypeFromJSONTyped,
    KvintaOperationTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaHierarchyEvent
 */
export interface KvintaHierarchyEvent {
    /**
     * 
     * @type {number}
     * @memberof KvintaHierarchyEvent
     */
    ts?: number;
    /**
     * 
     * @type {KvintaOperationType}
     * @memberof KvintaHierarchyEvent
     */
    op?: KvintaOperationType;
    /**
     * 
     * @type {string}
     * @memberof KvintaHierarchyEvent
     */
    parentId?: string | null;
}

export function KvintaHierarchyEventFromJSON(json: any): KvintaHierarchyEvent {
    return KvintaHierarchyEventFromJSONTyped(json, false);
}

export function KvintaHierarchyEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaHierarchyEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ts': !exists(json, 'ts') ? undefined : json['ts'],
        'op': !exists(json, 'op') ? undefined : KvintaOperationTypeFromJSON(json['op']),
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
    };
}

export function KvintaHierarchyEventToJSON(value?: KvintaHierarchyEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ts': value.ts,
        'op': KvintaOperationTypeToJSON(value.op),
        'parentId': value.parentId,
    };
}


