/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Query Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaCharset,
    KvintaCharsetFromJSON,
    KvintaCharsetFromJSONTyped,
    KvintaCharsetToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMediaType
 */
export interface KvintaMediaType {
    /**
     * 
     * @type {string}
     * @memberof KvintaMediaType
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaMediaType
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaMediaType
     */
    subtype?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaMediaType
     */
    extension?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaMediaType
     */
    parameters?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KvintaMediaType
     */
    quality?: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaMediaType
     */
    qualityAsNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaMediaType
     */
    version?: string;
    /**
     * 
     * @type {KvintaCharset}
     * @memberof KvintaMediaType
     */
    charset?: KvintaCharset;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaMediaType
     */
    textBased?: boolean;
}

export function KvintaMediaTypeFromJSON(json: any): KvintaMediaType {
    return KvintaMediaTypeFromJSONTyped(json, false);
}

export function KvintaMediaTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMediaType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'subtype': !exists(json, 'subtype') ? undefined : json['subtype'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'quality': !exists(json, 'quality') ? undefined : json['quality'],
        'qualityAsNumber': !exists(json, 'qualityAsNumber') ? undefined : json['qualityAsNumber'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'charset': !exists(json, 'charset') ? undefined : KvintaCharsetFromJSON(json['charset']),
        'textBased': !exists(json, 'textBased') ? undefined : json['textBased'],
    };
}

export function KvintaMediaTypeToJSON(value?: KvintaMediaType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'subtype': value.subtype,
        'extension': value.extension,
        'parameters': value.parameters,
        'quality': value.quality,
        'qualityAsNumber': value.qualityAsNumber,
        'version': value.version,
        'charset': KvintaCharsetToJSON(value.charset),
        'textBased': value.textBased,
    };
}


