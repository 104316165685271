/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Query Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaLastState
 */
export interface KvintaLastState {
    /**
     * 
     * @type {string}
     * @memberof KvintaLastState
     */
    epc?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KvintaLastState
     */
    attr?: { [key: string]: string; };
}

export function KvintaLastStateFromJSON(json: any): KvintaLastState {
    return KvintaLastStateFromJSONTyped(json, false);
}

export function KvintaLastStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaLastState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epc': !exists(json, 'epc') ? undefined : json['epc'],
        'attr': !exists(json, 'attr') ? undefined : json['attr'],
    };
}

export function KvintaLastStateToJSON(value?: KvintaLastState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epc': value.epc,
        'attr': value.attr,
    };
}


