/* eslint-disable react/display-name */
import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

export interface KConfirmationDialogProps {
  /**
   * The title of the dialog.
   */
  title?: string; //'Warning';

  text?: string; //'Are you sure you want to perform this operation?';

  confirmLabel?: string; //'confirm';

  cancelLabel?: string; //'cancel';

  isOpen: boolean;

  onCancel: any;

  onConfirm: any;
}

export default function KConfirmationDialog(props: KConfirmationDialogProps) {
  // Set default values
  const title = props.title !== undefined ? props.title : 'Warning';
  const text = props.text !== undefined ? props.text : 'Are you sure you want to perform this operation?';
  const confirmLabel = props.confirmLabel !== undefined ? props.confirmLabel : 'Confirm';
  const cancelLabel = props.cancelLabel !== undefined ? props.cancelLabel : 'Cancel';

  return (
    <Dialog open={props.isOpen} onClose={props.onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="secondary" autoFocus>
          {cancelLabel}
        </Button>
        <Button onClick={props.onConfirm} color="secondary" autoFocus>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
