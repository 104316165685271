import {
  KvintaExecutionStatus,
  KvintaExecutionTree,
  KvintaExecutionWithStatuses,
} from 'kvinta/apis/kvinta-status-store';
import { TTask, TTaskLastStatus } from './types';

export const peach = '#fd9b93';
export const red = '#ff0101';
export const black = '#000000';
export const pink = '#fe6e9e';
export const blue = '#007e91';
export const green = '#038166';
export const plum = '#71248e';
export const lightPurple = '#374469';
export const white = '#ffffff';
export const lightBlue = '#caf0f3';
export const background = white;
export const selected = red;

export function generateTaskLabel(id: string) {
  return `${id.split('-').slice(0, 2).join('-')}...`;
}

export type TTaskData = {
  root: TTask & { label: string };
  lastStatus: TTaskLastStatus;
  children: any[];
  statuses: KvintaExecutionStatus[];
};

export function resultToData({ rootExecution, followUps }: KvintaExecutionTree): TTaskData {
  const id = rootExecution.execution.context.executionId;
  const { execution, statuses } = rootExecution;
  const executionHierarchyData = {
    id,
    function: execution._function._function,
    service: execution._function.service,
    rootTaskId: execution.context.rootExecutionId,
    parentTaskId: execution.context.parentExecutionId,
    created: new Date(statuses[statuses.length - 1].timestamp.epochMillis),
    contextJSON: JSON.stringify(execution.context),
  };
  const lastStatus = {
    id,
    taskId: id,
    timestamp: new Date(statuses[0].timestamp.epochMillis),
    status: statuses[0].status,
    followUps: Boolean(followUps?.length),
    error: statuses[0].error,
    message: statuses[0].message,
  };

  return {
    root: {
      ...executionHierarchyData,
      label: generateTaskLabel(executionHierarchyData.id),
    },
    lastStatus,
    statuses: statuses,
    children: followUps ? followUps.map((child) => resultToData(child)) : [],
  };
}

export function flattenHierarchy(data: TTaskData[], flattened) {
  return data.reduce((acc, nodeData) => {
    acc[nodeData.root.id] = {
      ...nodeData.root,
      ...nodeData.lastStatus,
      statuses: nodeData.statuses,
    };

    return flattenHierarchy(nodeData.children, acc);
  }, flattened);
}

export function removePrefix(input, prefix) {
  const hasPrefix = input.indexOf(prefix) === 0;
  return hasPrefix ? input.substr(prefix.length) : input.toString();
}
