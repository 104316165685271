/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Status Store
 * Swagger for Kvinta Execution Status Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaRetryExecutionWithNewPayloadRequest,
    KvintaRetryExecutionWithNewPayloadRequestFromJSON,
    KvintaRetryExecutionWithNewPayloadRequestFromJSONTyped,
    KvintaRetryExecutionWithNewPayloadRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestRetryExecutionWithNewPayloadRequest
 */
export interface KvintaOperationRequestRetryExecutionWithNewPayloadRequest {
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestRetryExecutionWithNewPayloadRequest
     */
    context?: KvintaExecutionContext;
    /**
     * 
     * @type {KvintaRetryExecutionWithNewPayloadRequest}
     * @memberof KvintaOperationRequestRetryExecutionWithNewPayloadRequest
     */
    input?: KvintaRetryExecutionWithNewPayloadRequest;
}

export function KvintaOperationRequestRetryExecutionWithNewPayloadRequestFromJSON(json: any): KvintaOperationRequestRetryExecutionWithNewPayloadRequest {
    return KvintaOperationRequestRetryExecutionWithNewPayloadRequestFromJSONTyped(json, false);
}

export function KvintaOperationRequestRetryExecutionWithNewPayloadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestRetryExecutionWithNewPayloadRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
        'input': !exists(json, 'input') ? undefined : KvintaRetryExecutionWithNewPayloadRequestFromJSON(json['input']),
    };
}

export function KvintaOperationRequestRetryExecutionWithNewPayloadRequestToJSON(value?: KvintaOperationRequestRetryExecutionWithNewPayloadRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': KvintaExecutionContextToJSON(value.context),
        'input': KvintaRetryExecutionWithNewPayloadRequestToJSON(value.input),
    };
}


