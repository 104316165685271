/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaNotificationType,
    KvintaNotificationTypeFromJSON,
    KvintaNotificationTypeFromJSONTyped,
    KvintaNotificationTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestNotificationType
 */
export interface KvintaOperationRequestNotificationType {
    /**
     * 
     * @type {KvintaNotificationType}
     * @memberof KvintaOperationRequestNotificationType
     */
    input?: KvintaNotificationType;
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestNotificationType
     */
    context?: KvintaExecutionContext;
}

export function KvintaOperationRequestNotificationTypeFromJSON(json: any): KvintaOperationRequestNotificationType {
    return KvintaOperationRequestNotificationTypeFromJSONTyped(json, false);
}

export function KvintaOperationRequestNotificationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestNotificationType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'input': !exists(json, 'input') ? undefined : KvintaNotificationTypeFromJSON(json['input']),
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
    };
}

export function KvintaOperationRequestNotificationTypeToJSON(value?: KvintaOperationRequestNotificationType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'input': KvintaNotificationTypeToJSON(value.input),
        'context': KvintaExecutionContextToJSON(value.context),
    };
}


