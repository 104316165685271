/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaProduct,
    KvintaProductFromJSON,
    KvintaProductFromJSONTyped,
    KvintaProductToJSON,
    KvintaTradeItem,
    KvintaTradeItemFromJSON,
    KvintaTradeItemFromJSONTyped,
    KvintaTradeItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaTradeItemWithProduct
 */
export interface KvintaTradeItemWithProduct {
    /**
     * 
     * @type {KvintaProduct}
     * @memberof KvintaTradeItemWithProduct
     */
    product?: KvintaProduct;
    /**
     * 
     * @type {KvintaTradeItem}
     * @memberof KvintaTradeItemWithProduct
     */
    tradeItem?: KvintaTradeItem;
}

export function KvintaTradeItemWithProductFromJSON(json: any): KvintaTradeItemWithProduct {
    return KvintaTradeItemWithProductFromJSONTyped(json, false);
}

export function KvintaTradeItemWithProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTradeItemWithProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'product': !exists(json, 'product') ? undefined : KvintaProductFromJSON(json['product']),
        'tradeItem': !exists(json, 'tradeItem') ? undefined : KvintaTradeItemFromJSON(json['tradeItem']),
    };
}

export function KvintaTradeItemWithProductToJSON(value?: KvintaTradeItemWithProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product': KvintaProductToJSON(value.product),
        'tradeItem': KvintaTradeItemToJSON(value.tradeItem),
    };
}


