/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Capture Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaValidationResult
 */
export interface KvintaValidationResult {
    /**
     * 
     * @type {boolean}
     * @memberof KvintaValidationResult
     */
    valid?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaValidationResult
     */
    errors?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaValidationResult
     */
    retryable?: boolean;
}

export function KvintaValidationResultFromJSON(json: any): KvintaValidationResult {
    return KvintaValidationResultFromJSONTyped(json, false);
}

export function KvintaValidationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaValidationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valid': !exists(json, 'valid') ? undefined : json['valid'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'retryable': !exists(json, 'retryable') ? undefined : json['retryable'],
    };
}

export function KvintaValidationResultToJSON(value?: KvintaValidationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valid': value.valid,
        'errors': value.errors,
        'retryable': value.retryable,
    };
}


