/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Query Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCharset
 */
export interface KvintaCharset {
    /**
     * 
     * @type {boolean}
     * @memberof KvintaCharset
     */
    registered?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KvintaCharset
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaCharset
     */
    aliases?: Array<string>;
}

export function KvintaCharsetFromJSON(json: any): KvintaCharset {
    return KvintaCharsetFromJSONTyped(json, false);
}

export function KvintaCharsetFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCharset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registered': !exists(json, 'registered') ? undefined : json['registered'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'aliases': !exists(json, 'aliases') ? undefined : json['aliases'],
    };
}

export function KvintaCharsetToJSON(value?: KvintaCharset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registered': value.registered,
        'name': value.name,
        'aliases': value.aliases,
    };
}


