/* eslint-disable @typescript-eslint/ban-types */
import { Paper, Tab, Tabs } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { navigateLocationMapPath, navigateLocationPath, navigateLocationsPath, navigateReportingPath } from '../paths';
import { LocationsStore, STORE_ID } from './LocationsStore';

import { PageContentStore, PAGE_CONTENT_STORE_ID, Map, KSidebarStore, SIDEBAR_STORE_ID } from 'kvinta/components';
import { reaction } from 'mobx';

interface ViewLocationMapProps {
  intl: any;
  locationsStore?: LocationsStore;
  pageContentStore: PageContentStore;
  sidebarStore?: KSidebarStore;
  id: string;
  history?: any;
}

class ViewLocationMapImpl extends Component<ViewLocationMapProps> {
  disposer: any;
  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.locationsStore.fetchLocation(this.props.id);
    this.disposer = reaction(
      () => this.props.locationsStore.currentLocation,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            navigateLocationsPath(this.props.history);
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    if (!this.props.locationsStore.currentLocation) {
      return null;
    }
    const formData = this.props.locationsStore.currentLocation;
    const { updateLocationFormOpen } = this.props.locationsStore;

    const height = this.props.pageContentStore.height;
    if (!height) {
      return null;
    }
    const lat = formData.latitude;
    const long = formData.longitude;

    const intl = this.props.intl;
    return (
      <div>
        <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          <Tabs
            value={'3'}
            indicatorColor="secondary"
            textColor="primary"
            onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
              if (newValue == 0) {
                navigateLocationsPath(this.props.history);
              } else if (newValue == 1) {
                navigateLocationPath(this.props.history, this.props.id);
              } else if (newValue == 2) {
                navigateReportingPath(this.props.history, this.props.id);
              }
            }}
            aria-label="simple tabs example"
          >
            <Tab label={intl.formatMessage({ id: 'common.summary' })} value={'1'} id={'1'} />
            <Tab label={intl.formatMessage({ id: 'common.reporting' })} value={'2'} id={'2'} />
            <Tab label={intl.formatMessage({ id: 'common.map' })} value={'3'} id={'3'} />
          </Tabs>
        </Paper>
        <Paper square style={{ height: '200vh', boxShadow: 'none' }}>
          <Map
            size={{ height: this.props.pageContentStore.height + 'px', width: '100%' }}
            targetId={formData.gln13}
            markerLocation={[long, lat]}
          />
        </Paper>
      </div>
    );
  }
}

export const ViewLocationMap = injectIntl(
  inject(STORE_ID, PAGE_CONTENT_STORE_ID, SIDEBAR_STORE_ID)(observer(ViewLocationMapImpl)),
);
