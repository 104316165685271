/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Arla Extensions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaCreateCirculationReportForEpcMetadataListFields,
    KvintaCreateCirculationReportForEpcMetadataListFieldsFromJSON,
    KvintaCreateCirculationReportForEpcMetadataListFieldsFromJSONTyped,
    KvintaCreateCirculationReportForEpcMetadataListFieldsToJSON,
    KvintaResource,
    KvintaResourceFromJSON,
    KvintaResourceFromJSONTyped,
    KvintaResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaCreateCirculationReportForEpcMetadataListRequest
 */
export interface KvintaCreateCirculationReportForEpcMetadataListRequest {
    /**
     * 
     * @type {KvintaResource}
     * @memberof KvintaCreateCirculationReportForEpcMetadataListRequest
     */
    resource?: KvintaResource;
    /**
     * 
     * @type {KvintaCreateCirculationReportForEpcMetadataListFields}
     * @memberof KvintaCreateCirculationReportForEpcMetadataListRequest
     */
    fields?: KvintaCreateCirculationReportForEpcMetadataListFields;
}

export function KvintaCreateCirculationReportForEpcMetadataListRequestFromJSON(json: any): KvintaCreateCirculationReportForEpcMetadataListRequest {
    return KvintaCreateCirculationReportForEpcMetadataListRequestFromJSONTyped(json, false);
}

export function KvintaCreateCirculationReportForEpcMetadataListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateCirculationReportForEpcMetadataListRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resource': !exists(json, 'resource') ? undefined : KvintaResourceFromJSON(json['resource']),
        'fields': !exists(json, 'fields') ? undefined : KvintaCreateCirculationReportForEpcMetadataListFieldsFromJSON(json['fields']),
    };
}

export function KvintaCreateCirculationReportForEpcMetadataListRequestToJSON(value?: KvintaCreateCirculationReportForEpcMetadataListRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resource': KvintaResourceToJSON(value.resource),
        'fields': KvintaCreateCirculationReportForEpcMetadataListFieldsToJSON(value.fields),
    };
}


