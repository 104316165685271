/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Notification Functions
 * Swagger for Kvinta Notification Functions
 *
 * The version of the OpenAPI document: 0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEmailRecipient,
    KvintaEmailRecipientFromJSON,
    KvintaEmailRecipientFromJSONTyped,
    KvintaEmailRecipientToJSON,
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestEmailRecipient
 */
export interface KvintaOperationRequestEmailRecipient {
    /**
     * 
     * @type {KvintaEmailRecipient}
     * @memberof KvintaOperationRequestEmailRecipient
     */
    input?: KvintaEmailRecipient;
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestEmailRecipient
     */
    context?: KvintaExecutionContext;
}

export function KvintaOperationRequestEmailRecipientFromJSON(json: any): KvintaOperationRequestEmailRecipient {
    return KvintaOperationRequestEmailRecipientFromJSONTyped(json, false);
}

export function KvintaOperationRequestEmailRecipientFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestEmailRecipient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'input': !exists(json, 'input') ? undefined : KvintaEmailRecipientFromJSON(json['input']),
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
    };
}

export function KvintaOperationRequestEmailRecipientToJSON(value?: KvintaOperationRequestEmailRecipient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'input': KvintaEmailRecipientToJSON(value.input),
        'context': KvintaExecutionContextToJSON(value.context),
    };
}


