/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Query Functions
 * Swagger for EPCIS Capture Functions
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaResourceType,
    KvintaResourceTypeFromJSON,
    KvintaResourceTypeFromJSONTyped,
    KvintaResourceTypeToJSON,
    KvintaSoftwareSystem,
    KvintaSoftwareSystemFromJSON,
    KvintaSoftwareSystemFromJSONTyped,
    KvintaSoftwareSystemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaResource
 */
export interface KvintaResource {
    /**
     * 
     * @type {KvintaSoftwareSystem}
     * @memberof KvintaResource
     */
    system?: KvintaSoftwareSystem;
    /**
     * 
     * @type {KvintaResourceType}
     * @memberof KvintaResource
     */
    type?: KvintaResourceType;
    /**
     * 
     * @type {string}
     * @memberof KvintaResource
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaResource
     */
    parentResourceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaResource
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaResource
     */
    sgln?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaResource
     */
    userId?: string | null;
}

export function KvintaResourceFromJSON(json: any): KvintaResource {
    return KvintaResourceFromJSONTyped(json, false);
}

export function KvintaResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'system': !exists(json, 'system') ? undefined : KvintaSoftwareSystemFromJSON(json['system']),
        'type': !exists(json, 'type') ? undefined : KvintaResourceTypeFromJSON(json['type']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'parentResourceId': !exists(json, 'parentResourceId') ? undefined : json['parentResourceId'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'sgln': !exists(json, 'sgln') ? undefined : json['sgln'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function KvintaResourceToJSON(value?: KvintaResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'system': KvintaSoftwareSystemToJSON(value.system),
        'type': KvintaResourceTypeToJSON(value.type),
        'id': value.id,
        'parentResourceId': value.parentResourceId,
        'tenantId': value.tenantId,
        'sgln': value.sgln,
        'userId': value.userId,
    };
}


