/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaRegulatorReportingSystem {
    EuTobacco = 'EU_TOBACCO',
    Sap = 'SAP',
    RuGismt = 'RU_GISMT',
    RuOms = 'RU_OMS',
    NoReporting = 'NO_REPORTING'
}

export function KvintaRegulatorReportingSystemFromJSON(json: any): KvintaRegulatorReportingSystem {
    return KvintaRegulatorReportingSystemFromJSONTyped(json, false);
}

export function KvintaRegulatorReportingSystemFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaRegulatorReportingSystem {
    return json as KvintaRegulatorReportingSystem;
}

export function KvintaRegulatorReportingSystemToJSON(value?: KvintaRegulatorReportingSystem | null): any {
    return value as any;
}

