/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaAggregatedCustomsDocumentStatusType {
    Created = 'CREATED',
    Releasing = 'RELEASING',
    Released = 'RELEASED',
    AtkNumberReceivedFromGismt = 'ATK_NUMBER_RECEIVED_FROM_GISMT',
    AtkNumberSentByEmail = 'ATK_NUMBER_SENT_BY_EMAIL',
    Error = 'ERROR'
}

export function KvintaAggregatedCustomsDocumentStatusTypeFromJSON(json: any): KvintaAggregatedCustomsDocumentStatusType {
    return KvintaAggregatedCustomsDocumentStatusTypeFromJSONTyped(json, false);
}

export function KvintaAggregatedCustomsDocumentStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaAggregatedCustomsDocumentStatusType {
    return json as KvintaAggregatedCustomsDocumentStatusType;
}

export function KvintaAggregatedCustomsDocumentStatusTypeToJSON(value?: KvintaAggregatedCustomsDocumentStatusType | null): any {
    return value as any;
}

