/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisAction,
    KvintaEpcisActionFromJSON,
    KvintaEpcisActionFromJSONTyped,
    KvintaEpcisActionToJSON,
    KvintaEpcisBizStep,
    KvintaEpcisBizStepFromJSON,
    KvintaEpcisBizStepFromJSONTyped,
    KvintaEpcisBizStepToJSON,
    KvintaTimestamp,
    KvintaTimestampFromJSON,
    KvintaTimestampFromJSONTyped,
    KvintaTimestampToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcisEventRecord
 */
export interface KvintaEpcisEventRecord {
    /**
     * 
     * @type {KvintaEpcisAction}
     * @memberof KvintaEpcisEventRecord
     */
    action: KvintaEpcisAction;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    bizLocation?: string | null;
    /**
     * 
     * @type {KvintaEpcisBizStep}
     * @memberof KvintaEpcisEventRecord
     */
    bizStep: KvintaEpcisBizStep;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    bizTransaction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    createdByExecutionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    createdByRootExecutionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    disposition: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    documentId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcisEventRecord
     */
    epcCount?: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    eventID?: string | null;
    /**
     * 
     * @type {KvintaTimestamp}
     * @memberof KvintaEpcisEventRecord
     */
    eventTime: KvintaTimestamp;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    eventTimeZoneOffset: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    expiredDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    parentID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    persistentDisposition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    prodBatch?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    readPoint: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcisEventRecord
     */
    recordTime?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    sampleCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    sha256: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    transformationID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    userId?: string | null;
}

export function KvintaEpcisEventRecordFromJSON(json: any): KvintaEpcisEventRecord {
    return KvintaEpcisEventRecordFromJSONTyped(json, false);
}

export function KvintaEpcisEventRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisEventRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': KvintaEpcisActionFromJSON(json['action']),
        'bizLocation': !exists(json, 'bizLocation') ? undefined : json['bizLocation'],
        'bizStep': KvintaEpcisBizStepFromJSON(json['bizStep']),
        'bizTransaction': !exists(json, 'bizTransaction') ? undefined : json['bizTransaction'],
        'createdByExecutionId': !exists(json, 'createdByExecutionId') ? undefined : json['createdByExecutionId'],
        'createdByRootExecutionId': !exists(json, 'createdByRootExecutionId') ? undefined : json['createdByRootExecutionId'],
        'disposition': json['disposition'],
        'documentId': !exists(json, 'documentId') ? undefined : json['documentId'],
        'epcCount': !exists(json, 'epcCount') ? undefined : json['epcCount'],
        'eventID': !exists(json, 'eventID') ? undefined : json['eventID'],
        'eventTime': KvintaTimestampFromJSON(json['eventTime']),
        'eventTimeZoneOffset': json['eventTimeZoneOffset'],
        'expiredDate': !exists(json, 'expiredDate') ? undefined : json['expiredDate'],
        'id': json['id'],
        'parentID': !exists(json, 'parentID') ? undefined : json['parentID'],
        'persistentDisposition': !exists(json, 'persistentDisposition') ? undefined : json['persistentDisposition'],
        'prodBatch': !exists(json, 'prodBatch') ? undefined : json['prodBatch'],
        'readPoint': json['readPoint'],
        'recordTime': !exists(json, 'recordTime') ? undefined : json['recordTime'],
        'sampleCode': !exists(json, 'sampleCode') ? undefined : json['sampleCode'],
        'sha256': json['sha256'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'transformationID': !exists(json, 'transformationID') ? undefined : json['transformationID'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function KvintaEpcisEventRecordToJSON(value?: KvintaEpcisEventRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': KvintaEpcisActionToJSON(value.action),
        'bizLocation': value.bizLocation,
        'bizStep': KvintaEpcisBizStepToJSON(value.bizStep),
        'bizTransaction': value.bizTransaction,
        'createdByExecutionId': value.createdByExecutionId,
        'createdByRootExecutionId': value.createdByRootExecutionId,
        'disposition': value.disposition,
        'documentId': value.documentId,
        'epcCount': value.epcCount,
        'eventID': value.eventID,
        'eventTime': KvintaTimestampToJSON(value.eventTime),
        'eventTimeZoneOffset': value.eventTimeZoneOffset,
        'expiredDate': value.expiredDate,
        'id': value.id,
        'parentID': value.parentID,
        'persistentDisposition': value.persistentDisposition,
        'prodBatch': value.prodBatch,
        'readPoint': value.readPoint,
        'recordTime': value.recordTime,
        'sampleCode': value.sampleCode,
        'sha256': value.sha256,
        'tenantId': value.tenantId,
        'transformationID': value.transformationID,
        'userId': value.userId,
    };
}


