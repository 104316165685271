/* eslint-disable @typescript-eslint/ban-types */
import { Button, Paper, Tab, Tabs } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { navigateLocationMapPath, navigateLocationPath, navigateLocationsPath, navigateReportingPath } from '../paths';
import { LocationsStore, STORE_ID } from './LocationsStore';
import { KSidebarStore, SIDEBAR_STORE_ID } from 'kvinta/components';
import { reaction } from 'mobx';
import { ViewLocationReportingComp } from './ViewLocationReportingComp';

interface ViewLocationSummaryProps {
  intl: any;
  locationsStore?: LocationsStore;
  sidebarStore?: KSidebarStore;
  id: string;
  history?: any;
}

class ViewLocationReportingImpl extends Component<ViewLocationSummaryProps> {
  disposer: any;

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.locationsStore.fetchLocation(this.props.id);
    this.disposer = reaction(
      () => this.props.locationsStore.currentLocation,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            navigateLocationsPath(this.props.history);
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    if (!this.props.locationsStore.currentLocation) {
      return null;
    }
    const formData = this.props.locationsStore.currentLocation;
    const { updateLocationFormOpen } = this.props.locationsStore;

    const intl = this.props.intl;
    return (
      <div>
        <Paper square style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          <Tabs
            value={'2'}
            indicatorColor="secondary"
            textColor="primary"
            onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
              if (newValue == 0) {
                navigateLocationsPath(this.props.history);
              } else if (newValue == 1) {
                navigateLocationPath(this.props.history, this.props.id);
              } else if (newValue == 3) {
                navigateLocationMapPath(this.props.history, this.props.id);
              }
            }}
            aria-label="simple tabs example"
          >
            <Tab label={intl.formatMessage({ id: 'common.summary' })} value={'1'} id={'1'} />
            <Tab label={intl.formatMessage({ id: 'common.reporting' })} value={'2'} id={'2'} />
            <Tab label={intl.formatMessage({ id: 'common.map' })} value={'3'} id={'3'} />
          </Tabs>
        </Paper>
        <Paper square style={{ height: '100vh', boxShadow: 'none' }}>
          <ViewLocationReportingComp id={this.props.id} history={this.props.history} />
        </Paper>
      </div>
    );
  }
}

export const ViewLocationReporting = injectIntl(
  inject(STORE_ID, SIDEBAR_STORE_ID)(observer(ViewLocationReportingImpl)),
);
