/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Document Store
 * Swagger for Kvinta Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCreateDeliveryItemRequest
 */
export interface KvintaCreateDeliveryItemRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateDeliveryItemRequest
     */
    deliveryNoteId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateDeliveryItemRequest
     */
    sku?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateDeliveryItemRequest
     */
    uom?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaCreateDeliveryItemRequest
     */
    value?: number | null;
}

export function KvintaCreateDeliveryItemRequestFromJSON(json: any): KvintaCreateDeliveryItemRequest {
    return KvintaCreateDeliveryItemRequestFromJSONTyped(json, false);
}

export function KvintaCreateDeliveryItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateDeliveryItemRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryNoteId': json['deliveryNoteId'],
        'sku': !exists(json, 'sku') ? undefined : json['sku'],
        'uom': !exists(json, 'uom') ? undefined : json['uom'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function KvintaCreateDeliveryItemRequestToJSON(value?: KvintaCreateDeliveryItemRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliveryNoteId': value.deliveryNoteId,
        'sku': value.sku,
        'uom': value.uom,
        'value': value.value,
    };
}


