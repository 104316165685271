/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Masterdata Service
 * Swagger for Kvinta Masterdata Service
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaGtinInfo,
    KvintaGtinInfoFromJSON,
    KvintaGtinInfoFromJSONTyped,
    KvintaGtinInfoToJSON,
    KvintaOperationStatus,
    KvintaOperationStatusFromJSON,
    KvintaOperationStatusFromJSONTyped,
    KvintaOperationStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetListResponseGtinInfo
 */
export interface KvintaGetListResponseGtinInfo {
    /**
     * 
     * @type {Array<KvintaGtinInfo>}
     * @memberof KvintaGetListResponseGtinInfo
     */
    data: Array<KvintaGtinInfo>;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetListResponseGtinInfo
     */
    error?: string | null;
    /**
     * 
     * @type {KvintaOperationStatus}
     * @memberof KvintaGetListResponseGtinInfo
     */
    operationStatus?: KvintaOperationStatus;
    /**
     * 
     * @type {number}
     * @memberof KvintaGetListResponseGtinInfo
     */
    total?: number | null;
}

export function KvintaGetListResponseGtinInfoFromJSON(json: any): KvintaGetListResponseGtinInfo {
    return KvintaGetListResponseGtinInfoFromJSONTyped(json, false);
}

export function KvintaGetListResponseGtinInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetListResponseGtinInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(KvintaGtinInfoFromJSON)),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'operationStatus': !exists(json, 'operationStatus') ? undefined : KvintaOperationStatusFromJSON(json['operationStatus']),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function KvintaGetListResponseGtinInfoToJSON(value?: KvintaGetListResponseGtinInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(KvintaGtinInfoToJSON)),
        'error': value.error,
        'operationStatus': KvintaOperationStatusToJSON(value.operationStatus),
        'total': value.total,
    };
}


